import { CloudEvent } from "@smartdevis/server/src/cloudEvents"
import { ApiRoute } from "@smartdevis/server/src/api"
import { EventResult, mkEventResult } from "@smartdevis/utils/src/actions"
import { isFetchError, FinishedAsync, mkFetchError } from "@smartdevis/utils/src/async"
import { mkErr } from "@smartdevis/utils/src/result"
import { resultValidator, Validators } from "@smartdevis/utils/src/validators"
import { mkRequest } from "./http"
import { SMap } from "@smartdevis/utils/src/map"
import { Authentication } from "../store/authState"

const cloudActionRequest = <T extends CloudEvent>(data: T, token?: string) => {
    return mkRequest<EventResult>(
        {
            method: "POST",
            path: "/action/:actionId",
            basePath: "api_url",
            token,
            validators: [resultValidator]
        },
        { data }
    )
}

export const callCloudAction = async (token: string, action: CloudEvent): Promise<EventResult> => {
    const res = await cloudActionRequest(action, token)
    if (isFetchError(res)) return mkEventResult(mkErr(res.value), action.meta)
    return res.value
}

export async function requestData<T>(
    type: "public",
    details: {
        path: ApiRoute
        validators: Validators<T>
        urlParams?: SMap<string>
        isV2?: boolean
        reqMethod?: "GET" | "POST" | "PUT" | "DELETE"
        body?: any
    }
): Promise<FinishedAsync<T>>
export async function requestData<T>(
    type: "authorized",
    details: {
        auth: Authentication
        path: ApiRoute
        validators: Validators<T>
        urlParams?: SMap<string>
        isV2?: boolean
        reqMethod?: "GET" | "POST" | "PUT" | "DELETE"
        body?: any
    }
): Promise<FinishedAsync<T>>
export async function requestData<T>(
    type: "public" | "authorized",
    details: {
        auth?: Authentication
        path: ApiRoute
        validators: Validators<T>
        urlParams?: SMap<string>
        isV2?: boolean
        reqMethod?: "GET" | "POST" | "PUT" | "DELETE"
        body?: any
    }
): Promise<FinishedAsync<T>> {
    if (type === "public")
        return mkRequest<T>({
            basePath: "api_url",
            method: details.reqMethod ? details.reqMethod : "GET",
            ...details,
            path: details.isV2 ? details.path : `/data${details.path}`
        })
    if (details.auth?.type !== "Authenticated") return mkFetchError("Not authenticated")
    return mkRequest<T>({
        basePath: "api_url",
        method: details.reqMethod ? details.reqMethod : "GET",
        token: details.auth.value.token,
        ...details,
        path: details.isV2 ? details.path : `/data${details.path}`
    })
}
