import * as React from "react"
import { i18n } from "@smartdevis/client/src/services/translations"
import { CheckboxButton, RadioButton } from "@smartdevis/ui/src/Button"
import { FlexColumn, FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Select } from "@smartdevis/ui/src/Selects"
import { SettersOf } from "@smartdevis/utils/src/types"
import { projectPersonas } from "@smartdevis/server/src/models/projectDetails"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { getProjectPersonaTranslation } from "../ProjectEdit.helpers"
import { Label } from "@smartdevis/ui/src/Typography"
import { isDefined } from "@smartdevis/utils/src/misc"
import { Domain } from "@smartdevis/server/src/domain"

export const useOfferMeta = () => {
    const [autoRejectOffers, setAutoRejectOffers] = React.useState(true)
    const [clientsRepresentative, setClientsRepresentative] = React.useState<Domain.ProjectPersona>("planner")
    const [contractType, setContractType] = React.useState<Domain.ContractType>("unit-prices")

    return {
        meta: { autoRejectOffers, clientsRepresentative, contractType },
        setters: {
            autoRejectOffers: setAutoRejectOffers,
            clientsRepresentative: setClientsRepresentative,
            contractType: setContractType
        }
    }
}

export const OfferSettings = (p: {
    meta: Domain.OfferMeta
    setters: SettersOf<Domain.OfferMeta>
    project: Domain.ProjectDetails
}) => {
    const rejectInfo = i18n("I want to reject all other offers automatically when the contractor accepts the contract.")

    return (
        <FlexColumn>
            <CheckboxButton
                checked={p.meta.autoRejectOffers}
                onChange={p.setters.autoRejectOffers}
                label={rejectInfo}
            />
            <VerticalSpace base="16px" />
            <Label>{i18n("Choose client's representative contact information source")}</Label>
            <Select
                mode="select"
                options={projectPersonas
                    .filter(pp => isDefined(p.project[pp]))
                    .map(pp => mkDropdownOption(getProjectPersonaTranslation(pp), pp))}
                value={p.meta.clientsRepresentative}
                fullWidth
                onChange={p.setters.clientsRepresentative}
            />
            <VerticalSpace base="16px" />
            <Label>{i18n("Flat-rate or at unit prices?")}</Label>
            <FlexRow>
                <RadioButton
                    checked={p.meta.contractType === "unit-prices"}
                    onChange={() => p.setters.contractType("unit-prices")}
                    label={i18n("At unit prices")}
                />
                <HorizontalSpace base="32px" />
                <RadioButton
                    checked={p.meta.contractType === "flat-rate"}
                    onChange={() => p.setters.contractType("flat-rate")}
                    label={i18n("Flat rate")}
                />
            </FlexRow>
        </FlexColumn>
    )
}
