import styled, { css } from "styled-components"
import { identity } from "@smartdevis/utils/src/misc"
import { F1, Casted } from "@smartdevis/utils/src/types"
import { isFunction } from "@smartdevis/utils/src/validators"
import { themeColor, ThemeColors, themeConfig, themeMedia } from "./theme"
import { Breakpoints } from "./media"

export const absoluteCenter = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
`

export const sticky = css`
    position: sticky;
    top: ${themeConfig("headerHeight")};
    padding: 2px 0 40px 0;
    z-index: 4;
`

export const StickyContainer = styled.div<{ top: number; padding?: string }>`
    ${sticky}
    top: ${p => `${p.top}px`};
    padding: ${p => p.padding || "0"};
`

type CSSLike = string | ReturnType<typeof css>
export const styleFromProp = (flag: any, style: CSSLike = "", def: CSSLike = "") => (flag ? style : def)

export const styleIfProp =
    <T, K extends keyof T>(key: K, style: CSSLike | F1<Required<T>[K], CSSLike>, def: CSSLike = "") =>
    (props: T) =>
        props[key] ? (isFunction(style) ? style(props[key]) : style) : def

export const Flex = styled.div`
    display: flex;
`

export const InlineFlex = styled.span`
    display: inline-flex;
`

export const Center = styled(Flex)<{ fullHeight?: boolean }>`
    width: 100%;
    height: ${p => (p.fullHeight ? "100%" : "auto")};
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Margin = styled.div<{ values: string }>`
    margin: ${p => p.values};
`
export const Padding = styled.div<{ values: string }>`
    padding: ${p => p.values};
`

export const Right = styled(Flex)`
    width: 100%;
    justify-content: flex-end;
`

export const Left = styled(Flex)`
    width: 100%;
    justify-content: flex-start;
`

const Child = styled.div<{ dynamicHeight?: boolean }>`
    flex: 1;
    margin-top: 25px;
    height: ${p => (p.dynamicHeight ? "auto" : "calc(100vh - 120px)")};
    display: flex;
    flex-direction: column;
    overflow: scroll;
`
export const LeftCol = styled(Child)`
    margin-right: 25px;
    padding-right: 10px;
`

export const RightCol = styled(Child)`
    margin-left: 25px;
    padding-left: 10px;
`

export const FlexRow = styled.div<{
    spaceBetween?: boolean
    alignCenter?: boolean
    justifyEnd?: boolean
    justifyCenter?: boolean
    noShrink?: boolean
    wrap?: boolean
}>`
    display: flex;
    flex-direction: row;
    ${styleIfProp("noShrink", "flex-shrink: 0;")}
    ${styleIfProp("spaceBetween", "justify-content: space-between;")}
    ${styleIfProp("wrap", "flex-wrap: wrap;")}
    ${styleIfProp("alignCenter", "align-items: center;")}
    ${styleIfProp("justifyEnd", "justify-content: flex-end;")}
    ${styleIfProp("justifyCenter", "justify-content: center;")}
`

export const FlexColumn = styled.div<{
    spaceBetween?: boolean
    justifyCenter?: boolean
    alignCenter?: boolean
    noShrink?: boolean
}>`
    display: flex;
    flex-direction: column;
    ${styleIfProp("noShrink", "flex-shrink: 0;")}
    ${styleIfProp("justifyCenter", "justify-content: center;")}
    ${styleIfProp(
        "spaceBetween",
        css`
            justify-content: space-between;
            height: 100%;
        `
    )}
    ${styleIfProp("alignCenter", "align-items: center;")}
`

export const GridContainer = styled.div<{
    columnsGrid?: number[]
    rowsGrid?: number[]
    gap?: string
    height?: string
    width?: string
}>`
    display: grid;
    ${styleIfProp("height", h => `height: ${h};`)}
    ${styleIfProp("width", h => `width: ${h};`)}
    grid-gap: ${styleIfProp("gap", identity, "0px")};
    ${styleIfProp(
        "columnsGrid",
        cg => css`
            grid-template-columns: ${cg.map(c => `minmax(0, ${c}fr)`).join(" ")};
        `
    )}
    ${styleIfProp(
        "rowsGrid",
        rg => css`
            grid-template-rows: ${rg.map(c => `minmax(0, ${c}fr)`).join(" ")};
        `
    )};
`

export const FlexItem = styled.div<{
    direction?: "row" | "column"
    xAlign?: "flex-start" | "flex-end" | "center" | "space-between"
    yAlign?: "flex-start" | "flex-end" | "center" | "space-between"
    overflow?: "scroll" | "hidden" | "auto"
    overflowX?: "scroll" | "hidden" | "auto"
    overflowY?: "scroll" | "hidden" | "auto"
    flex?: string
}>`
    display: flex;
    flex-direction: ${styleIfProp("direction", identity, "row")};
    ${styleIfProp("overflow", o => `overflow: ${o};`)}
    ${styleIfProp("overflowX", o => `overflow-x: ${o};`)}
    ${styleIfProp("overflowY", o => `overflow-y: ${o};`)}
    ${p =>
        styleFromProp(
            p.xAlign,
            (p.direction || "row") === "row" ? `justify-content: ${p.xAlign};` : `align-items: ${p.xAlign};`
        )}
    ${p =>
        styleFromProp(
            p.yAlign,
            (p.direction || "row") === "row" ? `align-items: ${p.yAlign};` : `justify-content: ${p.yAlign};`
        )}
    flex: ${styleIfProp("flex", identity, "1")};
`

export const VerticalSpace = styled.div<Partial<Casted<Breakpoints, string> & ({ base: string } | { h: number })>>`
    min-width: 1px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    ${p => `height: ${(p as any).h ? (p as any).h + "px" : (p as any).base};`}
    ${p => `
        ${styleFromProp(p.xxl, `${themeMedia("max", "xxl")(p)} { height: ${p.xxl}; } `)}
        ${styleFromProp(p.xl, `${themeMedia("max", "xl")(p)} { height: ${p.xl}; } `)}
        ${styleFromProp(p.lg, `${themeMedia("max", "lg")(p)} { height: ${p.lg}; }`)}
        ${styleFromProp(p.md, `${themeMedia("max", "sm")(p)} { height: ${p.md}; }`)}
        ${styleFromProp(p.xs, `${themeMedia("max", "xs")(p)} { height: ${p.xs}; }`)}
    `}
`

export const HorizontalSpace = styled.div<Partial<Casted<Breakpoints, string> & { base: string }>>`
    min-height: 1px;
    flex-shrink: 0;
    flex-grow: 0;
    ${p => `width: ${p.base};`}
    ${p => `
      ${styleFromProp(p.xxl, `${themeMedia("max", "xxl")(p)} { width: ${p.xxl}; } `)}
      ${styleFromProp(p.xl, `${themeMedia("max", "xl")(p)} { width: ${p.xl}; } `)}
      ${styleFromProp(p.lg, `${themeMedia("max", "lg")(p)} { width: ${p.lg}; }`)}
      ${styleFromProp(p.md, `${themeMedia("max", "sm")(p)} { width: ${p.md}; }`)}
      ${styleFromProp(p.xs, `${themeMedia("max", "xs")(p)} { width: ${p.xs}; }`)}
    `}
`

export const Spacer = styled.div<{ color?: keyof ThemeColors; type?: "vertical" | "horizontal" }>`
    background-color: ${p => styleIfProp("color", color => themeColor(color)(p), "transparent")};
    /* flex: 1 1 auto; */
    ${p => (p.type === "vertical" ? "min-width" : "min-height")}: 1px;
`
