import * as React from "react"
import { BaseModalProps, ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"

import { asyncConnect } from "../../resolvers"
import { useProjectLevelMutationManagement, getStepExitWarningText } from "@smartdevis/client/src/utils/devisHelpers"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { i18n } from "@smartdevis/client/src/services/translations"
import { FlexRow, Spacer, VerticalSpace } from "@smartdevis/ui/src/utils/common"

import { mkNewSection } from "./DevisSection"
import { Domain } from "@smartdevis/server/src/domain"
import { DeductionsList } from "./lists/DeductionsList"
import { PageHeader } from "../../components/layouts/Header"
import { Button } from "@smartdevis/ui/src/Button"
import { useUnsavedStatusAsParent } from "../UnsavedStatusProvider"
import { usleep } from "@smartdevis/utils/src/async"

export const ProjectDeductionsModal = asyncConnect({
    stateResolvers: ["projectAttachments", "projectPredefinedCollections", "projectDetails", "uploadingFiles"],
    actions: ["mutate"],
    renderError: () => <>{null}</>,
    renderLoading: () => <>{null}</>
})<BaseModalProps>(p => {
    const { deductions, sections } = p.projectPredefinedCollections
    const [confirmCloseModalOpen, setConfirmCloseModalOpen] = React.useState(false)

    const deductionMutations = useProjectLevelMutationManagement("deductions", deductions, p)
    const sectionMutations = useProjectLevelMutationManagement("sections", sections, p)
    const unsaved = useUnsavedStatusAsParent([p.visible])

    const close = async () => {
        await usleep(300)
        if (unsaved.check()) setConfirmCloseModalOpen(true)
        else p.onClose?.()
    }
    return (
        <Modal
            visible={p.visible}
            onClose={close}
            size="xl"
            height="95vh"
            headless
            footer={
                <FlexRow justifyEnd>
                    <Button onClick={close} key="save">
                        {i18n("Save and close")}
                    </Button>
                </FlexRow>
            }>
            <PageHeader
                noTopMargin
                title={i18n("Deductions")}
                subTitle="Erfassen Sie die Abzüge in Ihrem Devis und definieren Sie, ob sie vom Unternehmer (z.B. Rabatte) oder von Ihnen (z.B. Bauabzüge) bestimmt werden."
            />
            <Spacer color="grey50" type="horizontal" />
            <VerticalSpace base="16px" />
            <DeductionsList
                isReadonly={false}
                itemMutations={deductionMutations}
                items={deductions}
                sectionMutations={sectionMutations}
                sections={sections}
                mkItem={(sectionId, order) => mkDeduction(p.projectDetails.projectId, sectionId, order)}
                mkSection={order => mkNewSection(p.projectDetails.projectId, "deduction", order)}
            />
            <ConfirmationModal
                cancelText={i18n("Cancel")}
                submitText={i18n("Proceed")}
                header={i18n("Warning")}
                onClose={() => setConfirmCloseModalOpen(false)}
                onSubmit={() => {
                    setConfirmCloseModalOpen(false)
                    p.onClose?.()
                }}
                visible={confirmCloseModalOpen}
                contentText={getStepExitWarningText()}
            />
        </Modal>
    )
})

const mkDeduction = (projectId: string, sectionId: string, order: number): Domain.Deduction => {
    const deductionId = genTemporaryId()
    const createdTs = getTs()
    const sign = "negative"
    return { name: "", deductionId, createdTs, projectId, sectionId, sign, valueType: "percent", value: 0, order }
}
