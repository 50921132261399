import * as React from "react"
import { Domain } from "@smartdevis/server/src/domain"
import { SubmitButton } from ".."
import { i18n } from "../../services/translations"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { asyncConnect } from "../../resolvers"
import { Button } from "@smartdevis/ui/src/Button"
import { devisPaths } from "../../paths"
import { useCloudAction } from "../../hooks/useCloudAction"
import { prepareMutation } from "../../utils/mutations"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"

import { InfoBox } from "@smartdevis/ui/src/InfoBox"

import { DevisForm, DevisFormPayload } from "./DevisForm"
import { Result, mkErr, mkOk, isErr, isOk } from "@smartdevis/utils/src/result"
import { ValueState } from "@smartdevis/utils/src/types"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { useNotifications } from "../../views/NotificationsProvider"

type MutateDevisState = ValueState<"create", Pick<Domain.Devis, "projectId">> | ValueState<"update", Domain.Devis>

export const MutateDevisModal = asyncConnect({
    stateResolvers: ["results"],
    actions: ["mutate", "navigate"]
})<BaseModalProps & { editState: MutateDevisState; modalTitle: string; saveButtonTitle: string; isMutable: boolean }>(
    p => {
        const [devisId] = React.useState(p.editState.type === "update" ? p.editState.value.devisId : genTemporaryId())
        const [devisState, setDevisState] = React.useState<Result<DevisFormPayload>>(
            p.editState.type === "create" ? mkErr("Not ready", {}) : mkOk(p.editState.value)
        )
        const { pushNotification } = useNotifications()
        const { projectId } = p.editState.value
        const devisMutation = prepareMutation("devis", p.mutate, { type: "devis", projectId, devisId })

        const { actionState, onSubmit } = useCloudAction(
            actionId => {
                if (isErr(devisState)) return

                if (p.editState.type === "create") {
                    devisMutation.create(
                        {
                            ...devisState.value,
                            positionsFormat: { type: "not-selected" },
                            contractorIds: [],
                            devisId,
                            createdTs: getTs(),
                            projectId
                        },
                        actionId
                    )
                } else devisMutation.update(p.editState.value, actionId)(devisState.value)
                if (p.editState.type === "update") p.onClose?.()
            },
            p.results,
            result =>
                isOk(result)
                    ? p.navigate(devisPaths.submitters, { projectId, devisId: result.value })
                    : pushNotification(i18n("Something went wrong, try again later"))
        )

        const onClose = actionState.type === "Processing" ? undefined : p.onClose
        return (
            <Modal
                size={p.isMutable ? "m" : "s"}
                header={p.modalTitle}
                onClose={onClose}
                visible={!!p.visible}
                footer={
                    <>
                        {onClose && (
                            <Button data-cy="devis-cancel" btnType="secondary" onClick={p.onClose}>
                                {i18n("cancel")}
                            </Button>
                        )}

                        <SubmitButton
                            data-cy="devis-submit"
                            onClick={onSubmit}
                            loading={actionState.type === "Processing"}
                            disabled={isErr(devisState) || actionState.type === "Processing" || !p.isMutable}>
                            {i18n(p.saveButtonTitle)}
                        </SubmitButton>
                    </>
                }>
                {!p.isMutable && (
                    <>
                        <VerticalSpace h={32} />
                        <InfoBox type="warning">
                            {i18n("You are not allowed to edit the devis after a contractor has been invited.")}
                        </InfoBox>
                    </>
                )}
                <DevisForm
                    devis={p.editState.type === "create" ? {} : p.editState.value}
                    onResultChange={setDevisState}
                    isMutable={p.isMutable}
                />
            </Modal>
        )
    }
)
