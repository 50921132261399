import { SMap, values } from "@smartdevis/utils/src/map"
import { Domain } from "@smartdevis/server/src/domain"
import { getFullName } from "@smartdevis/server/src/models/user"
import {
    CalculatedDevis,
    displayDeductionPercentage,
    displayDeductionPriceFromCalculation,
    displayTax
} from "@smartdevis/server/src/utils/money"
import { alignRight, bold, br, col, Content, h2, h2wSubtext, lineSeparator, pageBreaker, row } from "../../utils/pdf"
import { getUnitTranslation, i18n } from "../translations"
import { mkDocCompanyDetails, mkDocCompanyNames, mkDocContractorDetails } from "./docUtils"
import { formatSwiss, displayNumber, mulSafeNumbers } from "@smartdevis/utils/src/numbers"
import { isDefined } from "@smartdevis/utils/src/misc"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { formatDeductionValue } from "../../views/architect/lists/DeductionsList"

export const mkDocRow = (
    sizes: (string | number)[],
    columns: Content[],
    margin?: [number, number, number, number],
    fontSize?: number
): Content =>
    row(
        columns.map((c, i) => col(c, { width: sizes[i] })),
        { margin, fontSize }
    )

export const mkDocSubmitterRow = (contractor: Domain.Contractor): Content =>
    mkDocRow(
        ["25%", "20%", "20%", "35%"],
        [mkDocCompanyDetails(contractor), getFullName(contractor), contractor.phoneNumber ?? "", contractor.email],
        [0, 10, 0, 6]
    )

export const mkDocConditionHeader = () =>
    mkDocRow(["7%", "23%", "70%"], [i18n("ID"), i18n("Name"), i18n("Description")])

export const mkDocConditionRow = (condition: Domain.Condition, index: number): Content =>
    mkDocRow(["7%", "23%", "70%"], [`0.${index + 1}`, condition.name, condition.description], [0, 6, 0, 6])

const commonMargin: [number, number, number, number] = [0, 6, 0, 3]

export const mkDocSectionRow = (
    section: Domain.Section,
    sectionNumber: string = section.number || "",
    numberWidth: "10%" | "7%" = "10%"
) => mkDocRow([numberWidth, "*"], [bold(sectionNumber), bold(section.name)], [0, 6, 0, 3])

const positionColumnSizes = ["10%", "40%", "*", "*", "*", "*"]

export const mkDocPositionHeader = () =>
    mkDocRow(
        positionColumnSizes,
        [
            i18n("{Number}No").trim(),
            i18n("Name"),
            alignRight(i18n("Amount")),
            alignRight(i18n("Unit")),
            alignRight(i18n("Unit price")),
            alignRight(i18n("Sum"))
        ],
        commonMargin
    )

export const mkDocPositionRow = (position: Domain.Position, calculation?: CalculatedDevis) => {
    const value = calculation?.offerValues[position.positionId]
    return mkDocRow(
        positionColumnSizes,
        [
            position.number ?? "",
            [pageBreaker(bold(position.name)), br("medium"), position.description],
            alignRight(position.type === "per" ? i18n("PER position") : formatSwiss(position.amount)),
            alignRight(getUnitTranslation(position.unit)),
            alignRight(value ? displayNumber(value) : "-"),
            alignRight(value ? displayNumber(mulSafeNumbers(value, position.amount)) : "-")
        ],
        commonMargin
    )
}

export const mkDocPositionAttachment = (
    a: Domain.Attachment,
    p: { number?: string },
    numberWidth: "10%" | "5%" = "10%"
) => mkDocRow([numberWidth, "*"], [p.number ?? "", a.name])

const deductionColumnSizes = ["10%", "30%", "15%", "10%", "15%", "20%"]

export const mkDocDeductionHeader = () =>
    mkDocRow(
        deductionColumnSizes,
        [
            i18n("ID"),
            i18n("Name"),
            i18n("Type"),
            i18n("Unit"),
            alignRight(i18n("Filled out by")),
            alignRight(i18n("Value"))
        ],
        commonMargin
    )

export const mkDocDeductionRow = (deduction: Domain.Deduction, id: string) =>
    mkDocRow(
        deductionColumnSizes,
        [
            id,
            deduction.name,
            deduction.sign === "positive" ? i18n("positive") : i18n("negative"),
            deduction.valueType === "percent" ? i18n("percent") : i18n("number"),
            alignRight(deduction.value === null ? i18n("Contractor") : i18n("Architect")),
            alignRight(deduction.value === null ? "-" : formatDeductionValue(deduction))
        ],
        commonMargin
    )

export const mkDocComparisonTitle = (submitters: Domain.Contractor[], title: string) =>
    mkDocRow(
        ["40%", ...submitters.map(() => "*")],
        [title, ...submitters.map(s => alignRight(s.companyName || " "))].map(bold)
    )

export const mkDocComparisonSubtotal = (
    submitters: Domain.Contractor[],
    calculations: SMap<CalculatedDevis>,
    sectionId: string
) =>
    mkDocRow(
        ["40%", ...submitters.map(() => "*")],
        [
            i18n("Subtotal"),
            ...submitters.map(s =>
                alignRight(displayNumber(calculations[s.contractorId].subTotalsBySection[sectionId]))
            )
        ].map(bold)
    )

export const mkDocComparisonGross = (submitters: Domain.Contractor[], calculations: SMap<CalculatedDevis>) =>
    mkDocRow(
        ["40%", ...submitters.map(() => "*")],
        [i18n("Gross"), ...submitters.map(s => alignRight(displayNumber(calculations[s.contractorId].gross)))].map(bold)
    )

export const mkDocComparisonNet = (submitters: Domain.Contractor[], calculations: SMap<CalculatedDevis>) =>
    mkDocRow(
        ["40%", ...submitters.map(() => "*")],
        [i18n("Net"), ...submitters.map(s => alignRight(displayNumber(calculations[s.contractorId].net)))].map(bold)
    )

export const mkDocComparisonTax = (submitters: Domain.Contractor[], calculations: SMap<CalculatedDevis>) =>
    mkDocRow(
        ["40%", ...submitters.map(() => "*")],
        [i18n("Tax"), ...submitters.map(s => alignRight(displayTax({}, calculations[s.contractorId].tax, true)))]
    )

export const mkDocComparisonNetInclTax = (submitters: Domain.Contractor[], calculations: SMap<CalculatedDevis>) =>
    mkDocRow(
        ["40%", ...submitters.map(() => "*")],
        [
            i18n("Net incl. tax"),
            ...submitters.map(s => alignRight(displayNumber(calculations[s.contractorId].netincltax)))
        ].map(bold)
    )

export const mkDocComparisonDeduction =
    (submitters: Domain.Contractor[], calculations: SMap<CalculatedDevis>) => (deduction: Domain.Deduction) =>
        mkDocRow(
            ["40%", ...submitters.map(() => "*")],
            [
                deduction.name,
                ...submitters.map(s =>
                    alignRight(displayDeductionPriceFromCalculation(deduction, calculations[s.contractorId]))
                )
            ]
        )

export const mkDocComparisonPositionHeader = (submitters: Domain.Contractor[]) =>
    mkDocRow(
        ["7%", "17%", "8%", "8%", ...submitters.map(() => ["*", "*"]).flat()],
        [
            i18n("{Number}No").trim(),
            i18n("Name"),
            alignRight(i18n("Amount")),
            alignRight(i18n("Unit")),
            ...submitters.map(() => [alignRight(i18n("Unit price\n(CHF)")), alignRight(i18n("Sum\n(CHF)"))]).flat()
        ],
        undefined,
        8
    )

export const mkDocComparisonPosition = (
    position: Domain.Position,
    submitters: Domain.Contractor[],
    calculations: SMap<CalculatedDevis>
) => {
    const vals = submitters.map(s => calculations[s.contractorId].offerValues[position.positionId])
    return mkDocRow(
        ["7%", "17%", "8%", "8%", ...submitters.map(() => ["*", "*"]).flat()],
        [
            position.number || "",
            position.name,
            alignRight(position.type === "per" ? i18n("PER position") : displayNumber(position.amount)),
            alignRight(position.unit),
            ...submitters
                .map((_, i) =>
                    [vals[i] ? displayNumber(vals[i]) : "0", displayNumber(mulSafeNumbers(position.amount, vals[i]))]
                        .map(alignRight)
                        .map(c => col(c, { width: "*" }))
                )
                .flat()
        ]
    )
}

export const mkDocComparisonAdditionalInfo = (
    ai: Domain.AdditionalInformation,
    submitters: Domain.Contractor[],
    calculations: SMap<CalculatedDevis>
) =>
    mkDocRow(
        ["30%", ...submitters.map(() => "*")],
        [
            [pageBreaker(bold(ai.title)), br("medium"), ai.description || ""],
            ...submitters.map(s =>
                alignRight(ai.value ?? calculations[s.contractorId].offerInformation[ai.informationId])
            )
        ]
    )

export const mkDocRecommendedContractor = (contractor: Domain.Contractor) =>
    mkDocRow(
        ["30%", "20%", "20%", "30%"],
        [
            mkDocCompanyDetails(contractor),
            alignRight(getFullName(contractor)),
            alignRight(contractor.phoneNumber ?? ""),
            alignRight(contractor.email)
        ]
    )

export const mkDocRecommendedPrice = (calculations: CalculatedDevis) =>
    mkDocRow(["30%", "70%"], [bold(i18n("Net incl. tax")), alignRight(displayNumber(calculations.netincltax))])

export const mkDocDevisCostEstimate = (devis: Domain.Devis) =>
    mkDocRow(
        ["30%", "70%"],
        [bold(i18n("Cost estimate (revised)")), alignRight(displayNumber(devis.costEstimateRevised))]
    )
export const mkDocRecommendationComment = (comment: string) =>
    mkDocRow(["30%", "70%"], [bold(i18n("Comment")), alignRight(comment)])

export const mkDocContractorPreviewRow = (
    contractor: Domain.Contractor,
    deductionSections: Domain.Section[],
    deductionsBySection: SMap<Domain.Deduction[]>,
    calc: CalculatedDevis
) =>
    mkDocRow(
        ["45%", "25%", "30%"],
        [
            mkDocContractorDetails(contractor),
            [
                bold(i18n("Gross")),
                ...deductionSections
                    .map((section, i) => [
                        ...deductionsBySection[section.sectionId].map(d => d.name),
                        i === deductionSections.length - 1 ? bold(i18n("Net")) : bold(i18n("Subtotal"))
                    ])
                    .flat(),
                i18n("Tax")
            ],
            [
                displayNumber(calc.gross),
                ...deductionSections
                    .map(({ sectionId }, i) => [
                        ...deductionsBySection[sectionId].map(d => displayDeductionPriceFromCalculation(d, calc)),
                        i === deductionSections.length - 1
                            ? bold(displayNumber(calc.net))
                            : bold(displayNumber(calc.subTotalsBySection[sectionId]))
                    ])
                    .flat(),
                displayTax({}, calc.tax, true)
            ].map(alignRight)
        ]
    )

export const mkDocRecommendationOfferSummary = (desc: string, price: number) =>
    mkDocRow(["45%", "25%", "30%"], [desc, bold(i18n("Net incl. tax")), alignRight(bold(displayNumber(price)))])

export const mkDocContractDeductionsRow = (
    deductionSections: Domain.Section[],
    deductionsBySection: SMap<Domain.Deduction[]>,
    calc: CalculatedDevis
) =>
    mkDocRow(
        ["50%", "20%", "*"],
        [
            [
                bold(i18n("Gross")),
                ...deductionSections
                    .filter(s => deductionsBySection[s.sectionId]) //TODO: @Mick verify - solves the problem with deduction sections without any children
                    .map((section, i) => [
                        ...deductionsBySection[section.sectionId].map(d => d.name),
                        i === deductionSections.length - 1 ? bold(i18n("Net")) : bold(i18n("Subtotal"))
                    ])
                    .flat(),
                i18n("Tax"),
                bold(i18n("Net incl. tax"))
            ],
            [
                " ",
                ...deductionSections
                    .filter(s => deductionsBySection[s.sectionId]) //TODO: @Mick verify - solves the problem with deduction sections without any children
                    .map(({ sectionId }) => [
                        ...deductionsBySection[sectionId].map(d => displayDeductionPercentage(d, calc) || " "),
                        " "
                    ])
                    .flat(),
                displayTax(),
                " "
            ],
            [
                displayNumber(calc.gross),
                ...deductionSections
                    .filter(s => deductionsBySection[s.sectionId]) //TODO: @Mick verify - solves the problem with deduction sections without any children
                    .map(({ sectionId }, i) => [
                        ...deductionsBySection[sectionId].map(d =>
                            displayNumber(calc.pricesByDeduction[d.deductionId], { sign: d.sign })
                        ),
                        i === deductionSections.length - 1
                            ? bold(displayNumber(calc.net))
                            : bold(displayNumber(calc.subTotalsBySection[sectionId]))
                    ])
                    .flat(),
                displayNumber(calc.tax, { showPositiveSign: true }),
                displayNumber(calc.netincltax)
            ].map(alignRight)
        ]
    )

export const mkDocContractSignaturesRow = (
    client?: Domain.AddressInfo,
    planner?: Domain.AddressInfo,
    contractor?: Domain.User
) =>
    mkDocRow(
        ["*", "10%", "*", "10%", "*"],
        [
            [
                bold(i18n("Client")),
                br("medium"),
                "Ort, Datum _______________",
                br("medium"),
                br(),
                "__________________________",
                br("medium"),
                mkDocCompanyNames(client),
                client?.companyName ? getFullName(client) : ""
            ],
            [""],
            [
                bold("Vertreter/Bauleitung"),
                br("medium"),
                "Ort, Datum _______________",
                br("medium"),
                br(),
                "__________________________",
                br("medium"),
                mkDocCompanyNames(planner),
                planner?.companyName ? getFullName(planner) : ""
            ],
            [""],
            [
                bold(i18n("Contractor")),
                br("medium"),
                "Ort, Datum _______________",
                br("medium"),
                br(),
                "__________________________",
                br("medium"),
                mkDocCompanyNames(contractor),
                contractor?.companyName ? getFullName(contractor) : ""
            ]
        ],
        [0, 20, 0, 0]
    )

export const mkDocContractInformationRow = (
    ai: Domain.AdditionalInformation,
    calculation: CalculatedDevis
): Content => [h2(ai.title), ai.description || "", ai.value ?? calculation.offerInformation[ai.informationId] ?? ""]

export const mkDocContractAttachments = (
    attachments: Domain.Attachment[],
    positions: SMap<Domain.Sublevel[]>,
    conditions: Domain.Condition[]
): Content => {
    const flattenedPositions = values(positions).reduce<Domain.Position[]>(
        (acc, sls) => [...acc, ...(sls.filter(sl => sl.basedOn === "position") as Domain.Position[])],
        []
    )
    const offerAttachments = attachments.filter(a => a.type === "offer").map(a => a.name)
    const generalAttachments = attachments.filter(a => a.type === "general").map(a => a.name)
    const finalAttachments = attachments.filter(a => a.type === "final").map(a => a.name)
    const conditionAttachments = conditions
        .map((c, i) => {
            const a = attachments.find(at => at.refId === c.conditionId)
            return a ? mkDocPositionAttachment(a, { number: `0.${i + 1}` }, "5%") : null
        })
        .filter(isDefined)
    const positionsAttachments = flattenedPositions
        .map(fp => {
            const a = attachments.find(at => at.refId === fp.positionId)
            return a ? mkDocPositionAttachment(a, fp, "5%") : null
        })
        .filter(isDefined)
    const content = [br()]
    if (!isEmpty(generalAttachments))
        content.push([
            h2wSubtext(i18n("Attachments"), i18n("General attachments")),
            lineSeparator(1, [0, 0, 0, 6]),
            ...generalAttachments,
            br()
        ])
    if (!isEmpty(conditionAttachments))
        content.push([
            h2wSubtext(i18n("Attachments"), i18n("Conditions")),
            lineSeparator(1, [0, 0, 0, 6]),
            ...conditionAttachments,
            br()
        ])
    if (!isEmpty(positionsAttachments))
        content.push([
            h2wSubtext(i18n("Attachments"), i18n("Positions")),
            lineSeparator(1, [0, 0, 0, 6]),
            ...positionsAttachments,
            br()
        ])
    if (!isEmpty(offerAttachments))
        content.push([
            h2wSubtext(i18n("Attachments"), i18n("Contractor attachments")),
            lineSeparator(1, [0, 0, 0, 6]),
            ...offerAttachments,
            br()
        ])
    if (!isEmpty(finalAttachments))
        content.push([
            h2wSubtext(i18n("Attachments"), i18n("Final attachments")),
            lineSeparator(1, [0, 0, 0, 6]),
            ...finalAttachments
        ])
    return content
}
