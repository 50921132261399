import * as React from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import { getFullName } from "@smartdevis/server/src/models/user"
import { Asset } from "@smartdevis/ui/src/Asset"
import { H3, H4, Label, Pre, P } from "@smartdevis/ui/src/Typography"
import {
    VerticalSpace,
    FlexRow,
    HorizontalSpace,
    Center,
    FlexColumn,
    GridContainer
} from "@smartdevis/ui/src/utils/common"
import { selectText } from "@smartdevis/ui/src/utils/dom"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { i18n } from "@smartdevis/client/src/services/translations"
import { useNotifications } from "../../NotificationsProvider"
import { useCloudAction } from "../../../hooks/useCloudAction"
import { AsyncConnectResults } from "../../../resolvers"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"
import { Button } from "@smartdevis/ui/src/Button"
import { ContractViewer } from "../../../components/export/ContractViewer"
import { ExportContract } from "../../../components/export/ExportButtons"
import { BaseModalProps, ConfirmationCheckModal } from "@smartdevis/ui/src/Modal"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { useOfferMeta, OfferSettings } from "./DevisFinalization.settings"
import { AttachmentsManagement, AttachmentsView } from "../../../components/Attachments"
import { Domain } from "@smartdevis/server/src/domain"
import { dateFormat } from "@smartdevis/server/src/constants"

export const useContract = (
    p: AsyncConnectResults<"projectDetails" | "devis" | "results", "updateRequestsState"> & {
        offerMeta?: Domain.OfferMeta
    },
    onDone: F0
) => {
    const { onSubmit, actionState } = useCloudAction<string>(
        (actionId, offerId) =>
            p.updateRequestsState({
                actionId,
                devisId: p.devis.devisId,
                projectId: p.projectDetails.projectId,
                offersIds: [offerId],
                state: { type: "final-proposal" },
                offerMeta: p.offerMeta
            }),
        p.results,
        onDone
    )
    return { onContract: onSubmit, contractingState: actionState }
}

export const SendContractRequestModal: React.FC<
    BaseModalProps & { submitter: Domain.Contractor; onSubmit: F0; loading: boolean }
> = p => {
    const warning = i18n("You will not be able to edit the devis nor send the contract to other contractors.")
    return (
        <ConfirmationCheckModal
            onSubmit={p.onSubmit}
            onClose={p.onClose}
            visible={p.visible}
            submitButtonProps={{ loading: p.loading }}
            submitText={i18n("Send for approval")}
            cancelText={i18n("Cancel")}
            confirmationText={i18n("I confirm that I want to send this to contractor for approval")}>
            <Label>{i18n("You are about to send contract for approval to:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.submitter.companyName || getFullName(p.submitter) || p.submitter.email}</Pre>
            <VerticalSpace base="8px" />
            <InfoBox type="warning">{warning}</InfoBox>
            <VerticalSpace base="8px" />
        </ConfirmationCheckModal>
    )
}

export const FinalContractView: React.FC<{ request: Domain.ArchitectOfferRequest; submitter: Domain.Contractor }> =
    p => {
        const { pushNotification } = useNotifications()
        if (!p.request.contractorUser) return null
        const selectAndCopy = (id: string) => () => {
            selectText(id)
            document.execCommand("copy")
            pushNotification(i18n("Copied!"))
        }
        const submitterName = p.submitter.companyName ?? getFullName(p.submitter)
        return (
            <Container>
                <Asset name="ContractOk" size="auto" />
                <H3>{i18n("Your contract with $1 is ready to download", submitterName)}</H3>

                <VerticalSpace base="16px" />

                <ExportContract
                    projectId={p.request.projectId}
                    devisId={p.request.devisId}
                    offerId={p.request.offerId}
                />
                <VerticalSpace base="32px" />

                <Label>{i18n("Phone number")}</Label>
                <FlexRow>
                    <CopiableText id="contractor-phone" onClick={selectAndCopy("contractor-phone")}>
                        {p.request.contractorUser.phoneNumber}
                    </CopiableText>
                    <HorizontalSpace base="16px" />
                    <Asset name="Copy" size="icon" onClick={selectAndCopy("contractor-phone")} />
                </FlexRow>
                <VerticalSpace base="32px" />

                <Label>{i18n("Email address")}</Label>
                <FlexRow>
                    <CopiableText id="contractor-email" onClick={selectAndCopy("contractor-email")}>
                        {p.request.contractorUser.email}
                    </CopiableText>
                    <HorizontalSpace base="16px" />
                    <Asset name="Copy" size="icon" onClick={selectAndCopy("contractor-email")} />
                </FlexRow>
            </Container>
        )
    }

export const FinalProposalView: React.FC<{ request: Domain.ArchitectOfferRequest; submitter: Domain.Contractor }> =
    p => {
        if (!p.request.contractorUser) return null

        return (
            <Container>
                <FlexRow alignCenter spaceBetween>
                    <FlexColumn>
                        <H3>
                            {i18n("Adjusted prices sent to $1", p.submitter.companyName || getFullName(p.submitter))}
                        </H3>
                        <P>{i18n("Wait for the client to make a finall call")}</P>
                    </FlexColumn>
                    <HorizontalSpace base="48px" />
                    <P>
                        {dayjs(p.request.statesHistory[p.request.statesHistory.length - 1].createdTs).format(
                            dateFormat
                        )}
                    </P>
                </FlexRow>
            </Container>
        )
    }

export const PrefinalContractView: React.FC<{
    project: Domain.ProjectDetails
    request: Domain.ArchitectOfferRequest
    attachments: AttachmentsManagement
    submitter: Domain.Contractor
    onContract: F1<Domain.OfferMeta>
}> = p => {
    const info1 = i18n("Before the contract is created, the contractor must approve the price adjustments you made.")
    const info1alt = i18n("{alt}Before the contract is created, the contractor must approve the final version.")
    const info2 = i18n("This initiates finalization. Once prices are accepted by the contractor contract is generated.")

    const { meta, setters: metaSetters } = useOfferMeta()
    const contractButton = <Button onClick={() => p.onContract(meta)}>{i18n("Send request for approval")}</Button>

    const submitterDisplay = p.submitter.companyName
        ? `${getFullName(p.submitter)} (${p.submitter.companyName})`
        : getFullName(p.submitter)

    const contractPreview = (
        <RightContainer>
            <H4>{i18n("Contract preview:")}</H4>
            <VerticalSpace base="8px" />
            <PreviewContainer>
                <ContractViewer
                    projectId={p.request.projectId}
                    devisId={p.request.devisId}
                    offerMeta={meta}
                    offerId={p.request.offerId}
                />
            </PreviewContainer>
        </RightContainer>
    )

    if (p.request.state.type === "negotiation") {
        return (
            <GridContainer gap="24px" columnsGrid={[1, 1]}>
                <LeftContainer>
                    <H4>
                        {i18n("You're about to create contract with")} {submitterDisplay}
                    </H4>
                    <VerticalSpace base="16px" />
                    <P>{i18n("Attachment, e.g protocol or final document:")}</P>
                    <VerticalSpace base="8px" />
                    <AttachmentsView refId={p.request.devisId} {...p.attachments} />
                    <VerticalSpace base="16px" />
                    <InfoBox type="info">
                        {info1}
                        <br />
                        {info2}
                    </InfoBox>
                    <LineBreak />
                    <OfferSettings meta={meta} setters={metaSetters} project={p.project} />
                    <VerticalSpace base="16px" />
                    {contractButton}
                </LeftContainer>
                {contractPreview}
            </GridContainer>
        )
    }
    return (
        <GridContainer gap="24px" columnsGrid={[1, 1]}>
            <LeftContainer>
                <H4>
                    {i18n("You're about to create contract with")} {submitterDisplay}
                </H4>
                <VerticalSpace base="16px" />
                <InfoBox type="info">
                    {info1alt}
                    <br />
                    {info2}
                </InfoBox>
                <LineBreak />
                <OfferSettings meta={meta} setters={metaSetters} project={p.project} />
                <VerticalSpace base="16px" />
                {contractButton}
            </LeftContainer>
            {contractPreview}
        </GridContainer>
    )
}

const Container = styled(Center)`
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 244px);
    background-color: ${themeColor("grey35")};
    padding: 128px;
`

const LeftContainer = styled(Center)`
    height: 100%;
    min-height: calc(100vh - 244px);
    background-color: ${themeColor("grey35")};
    padding: 128px;
`
const RightContainer = styled.div`
    height: 100%;
    position: relative;
    min-height: calc(100vh - 244px);
    background-color: ${themeColor("grey35")};
    padding: 12px;
`
const PreviewContainer = styled.div`
    height: calc(100% - 1.2rem - 16px);
    width: 100%;
`

const CopiableText = styled(P)`
    cursor: text;
`

const LineBreak = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${themeColor("grey50")};
    margin: 20px 0;
`
