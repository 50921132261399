import * as React from "react"
import { Domain } from "@smartdevis/server/src/domain"
import { F0, F1, TypedOmit } from "@smartdevis/utils/src/types"
import { AsyncConnectResults } from "../../resolvers"
import { prepareMutation } from "../../utils/mutations"
import { useCloudAction } from "../../hooks/useCloudAction"
import { _noop } from "@smartdevis/utils/src/misc"
import { useFormHook } from "@smartdevis/forms/src"
import { useKeyPress } from "@smartdevis/ui/src/hooks/useKeyPress"
import { Modal } from "@smartdevis/ui/src/Modal"
import { i18n } from "../../services/translations"
import { Button } from "@smartdevis/ui/src/Button"
import { isErr } from "@smartdevis/utils/src/result"
import { StyledForm } from "../forms"
import { renderMaps } from "../forms/formRenderers"
import { mkEmailSchema, mkFormSchema, mkTextSchema } from "../forms/formSchemas"
import { contractorValidation } from "@smartdevis/server/src/models/contractor"
import { mkMandatory } from "../../views/architect/ProjectEdit.helpers"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { useRenderedEffect } from "@smartdevis/ui/src/hooks/common"

type Payload = TypedOmit<Domain.Contractor, "updatedTs" | "contractorId" | "createdTs">

const mkContractorSchema = () =>
    mkFormSchema<Required<Payload>>(contractorValidation, {
        companyName: mkTextSchema(mkMandatory(i18n("Company name"))),
        name: mkTextSchema("First name"),
        surname: mkTextSchema("Surname"),
        email: mkEmailSchema(mkMandatory(i18n("Email"))),
        postalCode: mkTextSchema("Postal code"),
        city: mkTextSchema("City"),
        street: mkTextSchema("Street"),
        phoneNumber: mkTextSchema("Phone number"),
        number: mkTextSchema("Number"),
        workCategory: mkTextSchema("Work category")
    })

export const ContractorForm: React.FC<
    {
        contractor?: Domain.Contractor
        onBack?: F0
        onToggleId?: F1<string>
        visible?: boolean
        headerTitle?: string
    } & AsyncConnectResults<"results", "mutate">
> = p => {
    const [contractorId, resetId] = React.useState<string>(p.contractor?.contractorId || genTemporaryId())
    const { update, create } = prepareMutation("contractors", p.mutate)
    const { actionState, onSubmit } = useCloudAction<Payload>(
        (actionId, res) => {
            if (!p.contractor) create({ ...res, contractorId, createdTs: getTs() }, actionId)
            else update(p.contractor, actionId)(res)
        },
        p.results,
        () => {
            p.contractor ? _noop : p.onToggleId?.(contractorId)
            resetId(genTemporaryId())
            p.onBack?.()
        }
    )
    const { formViewProps, handleSubmit, result, resetState } = useFormHook({
        schema: mkContractorSchema(),
        initialValue: p.contractor,
        onSubmit
    })

    useRenderedEffect(resetState, [p.visible])
    useRenderedEffect(() => resetId(p.contractor?.contractorId ?? genTemporaryId()), [p.contractor?.contractorId])

    useKeyPress(() => handleSubmit(), ["enter"])

    return (
        <Modal
            header={i18n(p.headerTitle ? p.headerTitle : "Manage contractors")}
            onClose={p.onBack}
            visible={p.visible}
            size="m"
            height="600px"
            footer={
                <>
                    <Button type="button" btnType="secondary" onClick={p.onBack}>
                        {i18n("{Cancel adding/editing contractor}Cancel")}
                    </Button>
                    <Button
                        type="submit"
                        btnType="primary"
                        onClick={handleSubmit}
                        disabled={isErr(result) || actionState.type === "Processing"}
                        loading={actionState.type === "Processing"}>
                        {p.contractor ? i18n("Save") : i18n("Add")}
                    </Button>
                </>
            }>
            <StyledForm
                styledSchema={[
                    "companyName",
                    { type: "Row", value: ["name", "surname"] },
                    "email",
                    "phoneNumber",
                    "street",
                    { type: "Row", value: ["postalCode", "city"] },
                    { type: "Row", value: ["number", "workCategory"] }
                ]}
                {...formViewProps}
                {...renderMaps}
            />
        </Modal>
    )
}
