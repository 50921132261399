import * as React from "react"
import styled, { css } from "styled-components"
import { rem } from "./utils"
import { themeColor } from "./utils/theme"
import { Asset } from "./Asset"

export type InfoBoxType = "info" | "warning" | "alert"

export type InfoBoxProps = {
    type?: InfoBoxType
}

export const InfoBox: React.FC<InfoBoxProps> = ({ type = "info", children }) => {
    return (
        <InfoboxWrapper type={type}>
            <InfoboxIcon>
                {type !== "alert" && (
                    <Asset
                        size="icon"
                        name={type === "warning" ? "Warning" : "Info"}
                        color={type === "warning" ? "blueGrey" : "infoboxInfoIconColor"}
                    />
                )}
            </InfoboxIcon>
            <InfoboxBody>{children}</InfoboxBody>
        </InfoboxWrapper>
    )
}

const InfoboxWrapper = styled.div<{ type: InfoBoxType }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: pre-wrap;
    padding: 16px;
    ${({ type }) =>
        type === "warning"
            ? css`
                  background-color: ${themeColor("infoboxWarningBgColor")};
              `
            : css`
                  background-color: ${themeColor("infoboxInfoBgColor")};
              `}
`

const InfoboxIcon = styled.div`
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
`

const InfoboxBody = styled.div`
    flex: 1;
    font-size: ${rem(14)};
    color: ${themeColor("primaryGrey")};
`
