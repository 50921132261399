import * as models from "@prisma/client"
import { validateAttachment } from "../models/attachment"
import { OMap, pickObject, SMap, toMap } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { Result, isErr } from "@smartdevis/utils/src/result"
import { F1 } from "@smartdevis/utils/src/types"
import { Validator, validateCollection } from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"

export type UserWithAddress = models.user & { address: models.address_info }

export type Updateable<T> = Omit<T, "id" | `${any}_at` | `${any}delta_type` | `${any}_id` | "delta_of">
export type UpdateableLight<T> = Omit<T, "id" | `${any}_at` | `${any}delta_type`>

export const DA_MESSAGES = {
    USER_NOT_FOUND: "User not found",
    NOT_FOUND: "Not found",
    ACCESS_DENIED: "Access denied",
    DONE: "Done"
}

export const optimistic = async <T>(result: Result<T> | Promise<Result<T>>): Promise<T> => {
    const readyResult = await result
    if (isErr(readyResult)) throw new Error(readyResult.value.toString())
    return readyResult.value
}

export const toMapById = <T>(vs: T[], getId: F1<T, string>) => toMap(vs, getId, identity)

export const decoupleValidateAttachments = <T>(
    vs: (T & { attachments: SMap<Domain.Attachment> })[],
    validator: Validator<T>,
    getId: F1<T, string>
): [SMap<T>, SMap<Domain.Attachment>] => {
    const ts = toMap(vs, getId, ({ attachments: _, ...v }) => v)
    const as = vs.reduce<SMap<Domain.Attachment>>((acc, { attachments }) => {
        return { ...acc, ...attachments }
    }, {})
    const { valid: collection } = validateCollection(ts, validator)
    const { valid: attachments } = validateCollection(as, validateAttachment)
    return [collection, attachments]
}

export const decoupleAttachments = <T>(
    vs: (T & { attachments: SMap<Domain.Attachment> })[],
    getId: F1<T, string>
): [SMap<T>, SMap<Domain.Attachment>] => {
    const ts: SMap<T> = toMap(vs, getId, ({ attachments: _, ...v }) => v) as any
    const as = vs.reduce<SMap<Domain.Attachment>>((acc, { attachments }) => {
        return { ...acc, ...attachments }
    }, {})
    return [ts, as]
}

export const projectToPersonasMap = (
    p: Domain.ProjectDetails
): OMap<Domain.ProjectPersona, Domain.AddressInfo | null> => {
    return pickObject(p, [
        "client",
        "billing",
        "planner",
        "clientsRepresentative",
        "constructionManagement",
        "owner",
        "other",
        "ownersRepresentative",
        "specialistPlanner",
        "specialistPlanner2",
        "specialistPlanner3",
        "specialistPlanner4",
        "specialistPlanner5"
    ])
}

export const toDeltaEnum = (delta: Domain.RoundDelta): models.delta_type => {
    return delta.type.toUpperCase() as models.delta_type
}

export const fromDeltaEnum = (deltaEnum: models.delta_type): Domain.RoundDelta["type"] => {
    return deltaEnum.toLowerCase() as Domain.RoundDelta["type"]
}

export const toStakeholderEnum = (persona: Domain.ProjectPersona): models.project_persona => {
    switch (persona) {
        case "billing":
            return "BILLING"
        case "client":
            return "CLIENT"
        case "clientsRepresentative":
            return "CLIENTS_REP"
        case "constructionManagement":
            return "CONSTRUCTION_MANAGEMENT"
        case "other":
            return "OTHER"
        case "owner":
            return "OWNER"
        case "ownersRepresentative":
            return "OWNERS_REP"
        case "planner":
            return "PLANNER"
        case "specialistPlanner":
            return "SPEC_PLANNER"
        case "specialistPlanner2":
            return "SPEC_PLANNER_2"
        case "specialistPlanner3":
            return "SPEC_PLANNER_3"
        case "specialistPlanner4":
            return "SPEC_PLANNER_4"
        case "specialistPlanner5":
            return "SPEC_PLANNER_5"
    }
}

export const fromStakeholderEnum = (deltaEnum: models.project_persona): Domain.ProjectPersona => {
    switch (deltaEnum) {
        case "BILLING":
            return "billing"
        case "CLIENT":
            return "client"
        case "CLIENTS_REP":
            return "clientsRepresentative"
        case "CONSTRUCTION_MANAGEMENT":
            return "constructionManagement"
        case "OTHER":
            return "other"
        case "OWNER":
            return "owner"
        case "OWNERS_REP":
            return "ownersRepresentative"
        case "PLANNER":
            return "planner"
        case "SPEC_PLANNER":
            return "specialistPlanner"
        case "SPEC_PLANNER_2":
            return "specialistPlanner2"
        case "SPEC_PLANNER_3":
            return "specialistPlanner3"
        case "SPEC_PLANNER_4":
            return "specialistPlanner4"
        case "SPEC_PLANNER_5":
            return "specialistPlanner5"
    }
}

export const s = (v: any) => (v === undefined || v === null ? undefined : `${v}`)
export const ntos = (v: number | bigint) => `${v}`
export const ston = (v: string) => parseInt(v, 10)
const nraw = (v: any) => (Number.isNaN(parseInt(v, 10)) ? undefined : parseInt(v, 10))
export const n = (v: any) => (typeof v === "string" ? (new RegExp(/^\d+$/).test(v) ? nraw(v) : undefined) : nraw(v))
