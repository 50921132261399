// TODO: this should ideally be shared between server & client
// (copied over from /client/src/view-models/DeductionTable.ts)

import { keys, SMap, values } from "@smartdevis/utils/src/map"
import { StateType } from "@smartdevis/utils/src/types"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"

export const getRoundId = (o: Pick<Domain.ArchitectOfferRequest, "state">) =>
    o.state.type === "next-round" || o.state.type === "round-submitted" ? o.state.roundId : undefined

export const mkLatestOfferValues = (
    r: Pick<Domain.ArchitectOfferRequest, "finalOffer" | "initialOffer" | "roundOffers">
): Domain.OfferValues => {
    if (!isEmpty(r.finalOffer)) return r.finalOffer ?? {}
    if (r.roundOffers && keys(r.roundOffers).length > 0) {
        return r.roundOffers[keys(r.roundOffers).sort()[0]] // our ids are sortable by timestamp
    }
    return r.initialOffer ?? {}
}
export const mkLatestOfferInformation = (
    r: Pick<Domain.ArchitectOfferRequest, "initialInformation" | "roundInformation" | "finalInformation">
): Domain.OfferInformation => {
    if (!isEmpty(r.finalInformation)) return r.finalInformation ?? {}
    if (r.roundInformation && keys(r.roundInformation).length > 0) {
        return r.roundInformation[keys(r.roundInformation).sort()[0]] // our ids are sortable by timestamp
    }
    return r.initialInformation ?? {}
}

export const mkLatestOfferAttachments = (
    r: Pick<Domain.ArchitectOfferRequest, "offerAttachments" | "roundOfferAttachments">
): SMap<Domain.Attachment> => {
    if (r.roundOfferAttachments && keys(r.roundOfferAttachments).length > 0) {
        return r.roundOfferAttachments[keys(r.roundOfferAttachments).sort()[0]] // our ids are sortable by timestamp
    }
    return r.offerAttachments ?? {}
}

export const mkOfferValuesByTime = (
    rounds: SMap<Domain.Round>,
    request: Pick<
        Domain.ArchitectOfferRequest,
        "finalOffer" | "initialOffer" | "roundOffers" | "createdTs" | "updatedTs"
    >
): SMap<Domain.OfferValues> => {
    const result: SMap<Domain.OfferValues> = {}
    result[request.createdTs] = request.initialOffer ?? {}
    if (!isEmpty(rounds))
        values(rounds).forEach(round => {
            if (!isEmpty(request.roundOffers?.[round.roundId]))
                result[round.createdTs] = request.roundOffers?.[round.roundId] ?? {}
        })
    if (!isEmpty(request.finalOffer) && !!request.updatedTs) result[request.updatedTs] = request.finalOffer ?? {}
    return result
}
export const mkOfferInformationByTime = (
    rounds: SMap<Domain.Round>,
    request: Pick<
        Domain.ArchitectOfferRequest,
        "finalInformation" | "initialInformation" | "roundInformation" | "createdTs" | "updatedTs"
    >
): SMap<Domain.OfferInformation> => {
    const result: SMap<Domain.OfferInformation> = {}
    result[request.createdTs] = request.initialInformation ?? {}
    if (!isEmpty(rounds))
        values(rounds).forEach(round => {
            if (!isEmpty(request.roundInformation?.[round.roundId]))
                result[round.createdTs] = request.roundInformation?.[round.roundId] ?? {}
        })
    if (!isEmpty(request.finalInformation) && !!request.updatedTs)
        result[request.updatedTs] = request.finalInformation ?? {}
    return result
}

export const isOfferInState = (
    r?: Pick<Domain.ArchitectOfferRequest, "state">,
    acceptableStates: StateType<Domain.OfferState>[] = []
) => (r ? acceptableStates.includes(r.state.type) : false)

export const getChangedOffersInNegotiation = (
    req: Pick<Domain.ArchitectOfferRequest, "initialOffer" | "roundOffers" | "finalOffer">
) => {
    const oldOfferValues = mkLatestOfferValues({ ...req, finalOffer: {} })
    const currentOfferValues = mkLatestOfferValues(req)
    return keys(currentOfferValues).reduce<string[]>(
        (acc, id) => (oldOfferValues[id] !== currentOfferValues[id] ? acc.concat(id) : acc),
        []
    )
}
