import React, { useState } from "react"
import styled, { css } from "styled-components"
import { PageHeader } from "../../components/layouts/Header"
import { Input } from "@smartdevis/ui/src/Inputs"
import { Center, FlexColumn, FlexRow, HorizontalSpace, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { DropdownMenuOption, Dropdown } from "@smartdevis/ui/src/Dropdown"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { Button, CheckboxButton } from "@smartdevis/ui/src/Button"
import { Domain } from "@smartdevis/server/src/domain"
import { i18n } from "../../services/translations"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { SubContent } from "../layouts/Content"
import { H5, Label } from "@smartdevis/ui/src/Typography"
import { F1 } from "@smartdevis/utils/src/types"
import { Asset } from "@smartdevis/ui/src/Asset"
import { _noop } from "@smartdevis/utils/src/misc"

export type ExistingDevisModalProps = BaseModalProps & {
    items: Domain.DevisTemplate[]
    loading: boolean
    onSubmit: F1<string>
    disabled: boolean
}

export const ExistingDevisModal: React.FC<ExistingDevisModalProps> = p => {
    const [searchText, setSearchText] = useState("")
    const [selectedId, setSelectedId] = useState<string>("")

    const filteredItems = p.items
        .sort((a: any, b: any) => a.workCategory.localeCompare(b.workCategory))
        .filter(d => (searchText ? d.workCategory.includes(searchText) : true))
        .map(d => ({
            id: d.templateId,
            title: d.workCategory,
            descriptionItems: [d.positionsFormat?.type === "positions-based" ? "FreeText" : "NPK"]
        }))

    return (
        <Modal
            visible
            size="xs"
            height="60vh"
            onClose={p.onClose}
            footer={
                <>
                    <Button onClick={p.onClose} btnType="secondary">
                        {i18n("cancel")}
                    </Button>
                    <Button
                        loading={p.loading}
                        disabled={!selectedId || p.disabled}
                        onClick={() => p.onSubmit(selectedId)}>
                        {i18n("Use selected")}
                    </Button>
                </>
            }>
            <SubContent>
                <PageHeader title={i18n("Use existing devis")} noTopMargin noBottomMargin />
                <Input placeholder={i18n("Search...")} onChange={v => setSearchText(v.target.value)} />
                <Margin values="48px 0 0 0">
                    <ItemsContainer
                        items={filteredItems}
                        selectedDevisId={selectedId}
                        onChangedSelectedDevisId={newId => newId !== selectedId && setSelectedId(newId)}
                        emptyListText={i18n("No matching templates found")}
                    />
                </Margin>
            </SubContent>
        </Modal>
    )
}

type ListItem = {
    id: string
    title: string
    descriptionItems: string[]
}

type ItemsContainerProps = {
    selectedDevisId: string
    onChangedSelectedDevisId: F1<string>
    items: ListItem[]
    emptyListText?: string
    options?: ListItemOption[]
}

type ListItemOption = DropdownMenuOption

export const ItemsContainer: React.FC<ItemsContainerProps> = p => {
    return p.items.length > 0 ? (
        <ListContainer>
            <Join items={p.items}>
                {({ id, title, descriptionItems }) => (
                    <ListItem onClick={() => p.onChangedSelectedDevisId(id)}>
                        <FlexRow spaceBetween>
                            <H5 style={{ overflowWrap: "anywhere" }}>{title}</H5>
                            <CheckboxButton checked={p.selectedDevisId === id} onChange={_noop} />
                        </FlexRow>
                        <VerticalSpace base="8px" />
                        <FlexRow spaceBetween alignCenter>
                            <FlexRow alignCenter>
                                <Join items={descriptionItems} renderJoining={() => <HorizontalSpace base="32px" />}>
                                    {item => <DescriptionItem key={item}>{item}</DescriptionItem>}
                                </Join>
                            </FlexRow>
                            {p.options ? (
                                <>
                                    <HorizontalSpace base="16px" />
                                    <FlexRow style={{ alignItems: "flex-end" }}>
                                        <Dropdown options={p.options}>
                                            <Asset name="DotsFilled" size="icon" />
                                        </Dropdown>
                                    </FlexRow>
                                </>
                            ) : null}
                        </FlexRow>
                    </ListItem>
                )}
            </Join>
        </ListContainer>
    ) : (
        <Center fullHeight>
            <Label color="grey70">{p.emptyListText ? p.emptyListText : i18n("Empty List")}</Label>
        </Center>
    )
}

const ListContainer = styled(FlexColumn)`
    border: 1px solid ${themeColor("grey50")};
    border-radius: 8px;
`

const horizontalVerticalLine = css`
    border-bottom: 1px solid ${themeColor("grey50")};
`

const ListItem = styled(FlexColumn)`
    padding: 16px;
    cursor: pointer;
    ${horizontalVerticalLine};
    :last-of-type {
        border: 0;
    }
`

const DescriptionItem = styled(Label)`
    color: ${themeColor("grey70")};
    font-size: 16px;
`
