import { combineResolversWithProps, resolvePathParam, resolveFromMap, mkResolver, wrapAsync } from "./resolverUtils"
import { PathParams } from "../paths"
import { isFetched, isNotFetched, mkFetched, mkFetching } from "@smartdevis/utils/src/async"
import { clientActions } from "../store"
import { filterObject, remap } from "@smartdevis/utils/src/map"
import { hasRequests, hasSecondRound, hasRoundRequests, hasContract, hasNegotiations } from "../utils/devisHelpers"
import { identity } from "@smartdevis/utils/src/misc"
import { values } from "lodash"

type ProjectProps = Partial<Pick<PathParams, "projectId">>
type DevisProps = Partial<Pick<PathParams, "projectId" | "devisId">>

export const resolveDevis = combineResolversWithProps<DevisProps>()(resolvePathParam("devisId"), devisId =>
    mkResolver(
        s => (isFetched(s.data.devis) ? resolveFromMap(s.data.devis.value, devisId) : mkFetching()),
        s => (isNotFetched(s.data.devis) ? [clientActions.fetchAllDevis()] : [])
    )
)

export const resolveAllProjectDevis = combineResolversWithProps<ProjectProps>()(
    resolvePathParam("projectId"),
    projectId =>
        mkResolver(
            s =>
                isFetched(s.data.devis)
                    ? mkFetched(filterObject(s.data.devis.value, (_, d) => d.projectId === projectId))
                    : mkFetching(),
            s => (!isFetched(s.data.devis) ? [clientActions.fetchAllDevis()] : [])
        )
)

export const resolveDevisCollections = combineResolversWithProps<DevisProps>()(
    resolvePathParam("projectId"),
    resolvePathParam("devisId"),
    (projectId, devisId) =>
        mkResolver(
            s => resolveFromMap(s.data.devisCollections, devisId, mkFetching),
            s =>
                isNotFetched(s.data.devisCollections[devisId])
                    ? [clientActions.fetchDevisCollections(projectId, devisId)]
                    : []
        )
)

export const resolveAllDevis = mkResolver(
    s => s.data.devis,
    s => (isNotFetched(s.data.devis) ? [clientActions.fetchAllDevis()] : [])
)

export const resolveProjectDevisCollections = combineResolversWithProps<DevisProps>()(
    resolvePathParam("projectId"),
    resolveAllProjectDevis,
    (projectId, projectDevis) =>
        mkResolver(
            s => {
                const collections = remap(projectDevis, identity, (_, devisId) => s.data.devisCollections[devisId])
                if (values(collections).some(v => !isFetched(v))) return mkFetching()
                return wrapAsync(collections)
            },
            s =>
                values(
                    remap(projectDevis, identity, (_, devisId) =>
                        isFetched(s.data.devisCollections[devisId])
                            ? []
                            : [clientActions.fetchDevisCollections(projectId, devisId)]
                    )
                ).flat()
        )
)

export const resolveIsDevisReadonly = combineResolversWithProps<DevisProps>()(
    resolveDevisCollections,
    resolvePathParam("devisId"),
    (data, devisId) =>
        mkResolver(() =>
            mkFetched(
                (hasRequests(data, devisId) && !hasSecondRound(data, devisId)) ||
                    (hasSecondRound(data, devisId) && hasRoundRequests(data, devisId)) ||
                    hasContract(data, devisId) ||
                    hasNegotiations(data, devisId)
            )
        )
)

export const resolveIsDevisInSecondRound = combineResolversWithProps<DevisProps>()(
    resolveDevisCollections,
    resolvePathParam("devisId"),
    (data, devisId) => mkResolver(() => mkFetched(hasSecondRound(data, devisId)))
)

// hasRequests - readonly
// hasRequests and hasSecondRound - not readonly
// hasRequests, hasSecondRound and hasRoundRequests - readonly
