import { ColorTuple } from "hsluv"
import { format } from "@smartdevis/utils/src/text"

export const rem = (sizeInPx: number) => `${Math.round((sizeInPx / 16) * 1000) / 1000}rem`

export const hexToRgb = (hex: string): ColorTuple => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0]
}

export const dataCy = (label: string, arg1 = "") => format(label, arg1)
