import * as React from "react"
import { TileOption } from "@smartdevis/ui/src/Tiles"
import { LongTile } from "@smartdevis/ui/src/LongTile"
import { formatDateISO } from "@smartdevis/utils/src/date"
import { i18n } from "../../services/translations"
import { F0 } from "@smartdevis/utils/src/types"
import { Domain } from "@smartdevis/server/src/domain"
import { isEmpty } from "lodash"

type DevisTileProps = {
    tileId: string
    createdTs: number
    loading: boolean
    format: Domain.PositionsFormat
    titleLabel?: string
    onClick?: F0
    devisId?: string
    projectId?: string
    onOpenAttachments?: F0
    onRemovePositionsFormat?: F0 | null
}

export const DevisTile: React.FC<DevisTileProps> = p => {
    const title = p.format.type === "file-based" ? p.format.fileAttachment.name : i18n("Free Text")
    const titleLabel = p.titleLabel ?? (p.format.type === "file-based" ? i18n("File name") : i18n("Type"))

    const descriptionItems = []
    if (p.format.type === "file-based") descriptionItems.push({ key: i18n("Type"), value: i18n("File") })
    descriptionItems.push({ key: i18n("Creation date"), value: formatDateISO(p.createdTs) })

    const options: TileOption[] = []
    if (p.onOpenAttachments)
        options.push({
            label: i18n("Manage additional attachments"),
            type: "basic",
            action: p.onOpenAttachments,
            value: "attachments"
        })
    if (p.onRemovePositionsFormat)
        options.push({ label: i18n("Remove"), type: "basic", action: p.onRemovePositionsFormat, value: "remove" })

    return (
        <LongTile
            id={p.tileId}
            title={title}
            titleLabel={titleLabel}
            descriptionItems={descriptionItems}
            icon={p.format.type === "file-based" ? "FileTextPdf" : "FileText"}
            loading={p.loading}
            buttonTitle={p.format.type === "file-based" ? i18n("Download") : i18n("Open")}
            onClick={p.onClick}
            options={isEmpty(options) ? undefined : options}
        />
    )
}
