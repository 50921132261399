import { Domain } from "../domain"
import { ProjectData } from "../events/duplicateProject"

export const getSystemTemplateDetails = (): Domain.ProjectDetails[] => [templateProject01.details]

export const getSystemTemplates = () => [templateProject01]

export const templateProject01: ProjectData = {
    details: {
        projectId: "00000000000000000000template0001",
        number: "13",
        abbreviation: "13A",
        name: "Demoprojekt Bülach",
        street: "-",
        postalCode: "-",
        city: "-",
        createdTs: 1620990939432,
        updatedTs: 0,
        isTemplate: true,
        version: 1,
        client: {
            companyName: "",
            companyName2: "",
            name: "Hans",
            surname: "Ritter",
            email: "hans.ritter@bauherr.ch",
            phoneNumber: "079 234 56 78",
            street: "Bergstrasse 14",
            postalCode: "5745",
            city: "Safenwil",
            createdTs: 0
        },
        billing: {
            companyName: "",
            companyName2: "",
            name: "Hans",
            surname: "Ritter",
            email: "hans.ritter@bauherr.ch",
            phoneNumber: "079 234 56 78",
            street: "Bergstrasse 14",
            postalCode: "5745",
            city: "Safenwil",
            createdTs: 0
        },
        planner: {
            companyName: "Beispiel Architektur AG",
            companyName2: "",
            name: "Peter",
            surname: "Haller",
            email: "Peter@architekturag.ch",
            phoneNumber: "079 123 45 67",
            street: "Klosterstrasse 12",
            postalCode: "8045",
            city: "Zürich",
            createdTs: 0
        },
        owner: null,
        ownersRepresentative: null,
        clientsRepresentative: null,
        specialistPlanner: null,
        specialistPlanner2: null,
        specialistPlanner3: null,
        specialistPlanner4: null,
        specialistPlanner5: null,
        constructionManagement: null,
        other: null,
        projectType: "new"
    },
    devis: {
        "8469001896249jVvfJLTNNFeLrKG16cKxR1": {
            createdTs: 1620991213802,
            updatedTs: 1620994092873,
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            projectId: "00000000000000000000template0001",
            number: "1.2",
            workCategory: "Maler innen",
            entryTs: 1625781600000,
            workStartTs: 1635941996991,
            workEndTs: 1645100396991,
            costEstimateOriginal: 250000000,
            costEstimateRevised: 270000000,
            positionsFormat: { type: "positions-based" },
            includeVat: false,
            remarks: "",
            contractorIds: []
        },
        "846900189625284cy9SK0sZvVAsWQzNKtd3": {
            createdTs: 1620990989184,
            updatedTs: 1620993340716,
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            projectId: "00000000000000000000template0001",
            number: "1.1",
            workCategory: "Baumeister",
            entryTs: 1621461600000,
            workStartTs: 1627902971191,
            workEndTs: 1633086971191,
            costEstimateOriginal: 1350000000,
            costEstimateRevised: 1320000000,
            positionsFormat: { type: "positions-based" },
            includeVat: false,
            remarks: "",
            contractorIds: []
        }
    },
    sections: {
        "84690018962366ho45NsUhBSTrJYaidfH1y": {
            createdTs: 1620993939092,
            updatedTs: 1620993942277,
            order: 2,
            type: "position",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "84690018962366ho45NsUhBSTrJYaidfH1y",
            number: "3",
            name: "Umlegung der bestehenden\nVerbands-Abwasserleitung."
        },
        "8469001896236EksA7x0XPwuxvwjsJ4Tx3": {
            createdTs: 1620995498909,
            updatedTs: 1620995514513,
            order: 1,
            type: "position",
            refId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "8469001896236EksA7x0XPwuxvwjsJ4Tx3",
            number: "134",
            name: " Schützen von Bauwerks- und Bauteilen mit Schutzvlies."
        },
        "84690018962375pRS8iiLB8Tn9uYj5L9Cex": {
            createdTs: 1620993985379,
            updatedTs: 1620993990171,
            order: 3,
            type: "position",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "84690018962375pRS8iiLB8Tn9uYj5L9Cex",
            number: "4",
            name: "Beton für Fundamente, Boden- und Schleppplatten"
        },
        "846900189623817LYZTAauYeudBR36mo6JL": {
            createdTs: 1620995371832,
            updatedTs: 1620995384600,
            order: 0,
            type: "position",
            refId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "846900189623817LYZTAauYeudBR36mo6JL",
            number: "126",
            name: "Rollgerüste Innenraum"
        },
        "8469001896238bTiTBAtxdQJ1z86rfHsddr": {
            createdTs: 1620993681530,
            updatedTs: 1620993771483,
            order: 0,
            type: "position",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "8469001896238bTiTBAtxdQJ1z86rfHsddr",
            number: "1",
            name: "Aushubarbeiten"
        },
        "84690018962391Jz0Jx63dGocvMqKmoby7P": {
            createdTs: 1620993864170,
            updatedTs: 1620993867979,
            order: 1,
            type: "position",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "84690018962391Jz0Jx63dGocvMqKmoby7P",
            number: "2",
            name: "Kabelschutzrohre, Formstücke\nund Zubehör; nur Lieferung"
        },
        "846900189624529T9RKSnZbgido2mHyomkT": {
            createdTs: 1620991216634,
            updatedTs: 0,
            order: 1,
            type: "deduction",
            refId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "846900189624529T9RKSnZbgido2mHyomkT",
            number: "",
            name: "Skonto"
        },
        "8469001896245iChaZxQXkwKo2FsSJV2rc3": {
            createdTs: 1620991216635,
            updatedTs: 0,
            order: 3,
            type: "deduction",
            refId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "8469001896245iChaZxQXkwKo2FsSJV2rc3",
            number: "",
            name: "Pauschalabzüge"
        },
        "846900189624616isMP2baFc8mw5GyrJNne": {
            createdTs: 1620990992780,
            updatedTs: 0,
            order: 2,
            type: "deduction",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "846900189624616isMP2baFc8mw5GyrJNne",
            number: "",
            name: "Bauabzüge"
        },
        "8469001896246hnqVrxR0tFuYNX0o8ZJtuo": {
            createdTs: 1620991216634,
            updatedTs: 0,
            order: 0,
            type: "deduction",
            refId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "8469001896246hnqVrxR0tFuYNX0o8ZJtuo",
            number: "",
            name: "Rabatte"
        },
        "8469001896246kHWmhaPkgsiPdBZHe5wvR4": {
            createdTs: 1620991216634,
            updatedTs: 0,
            order: 2,
            type: "deduction",
            refId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "8469001896246kHWmhaPkgsiPdBZHe5wvR4",
            number: "",
            name: "Bauabzüge"
        },
        "846900189624729sFGF1AuZ3KqdvuGuvSKu": {
            createdTs: 1620990992781,
            updatedTs: 0,
            order: 3,
            type: "deduction",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "846900189624729sFGF1AuZ3KqdvuGuvSKu",
            number: "",
            name: "Pauschalabzüge"
        },
        "8469001896247a5PdVAkgR1fPsCrXFjtPPG": {
            createdTs: 1620990992779,
            updatedTs: 0,
            order: 1,
            type: "deduction",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "8469001896247a5PdVAkgR1fPsCrXFjtPPG",
            number: "",
            name: "Skonto"
        },
        "8469001896248939tj17nzchg6JZ14EZfDu": {
            createdTs: 1620990992779,
            updatedTs: 0,
            order: 0,
            type: "deduction",
            refId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "8469001896248939tj17nzchg6JZ14EZfDu",
            number: "",
            name: "Rabatte"
        }
    },
    positions: {
        "84690018961472SQ36WgS9dYjqftKxp7jFn": {
            createdTs: 1620995724545,
            updatedTs: 1620995752369,
            order: 1,
            positionId: "84690018961472SQ36WgS9dYjqftKxp7jFn",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "8469001896236EksA7x0XPwuxvwjsJ4Tx3",
            name: "Vorbereitungen auf mineralischem untergrund",
            description:
                "Beton-Wände und Beton-Decken.\nIn Technik- und Putzräumli (ca. 18 St) vom EG bis 6.OG.\nArbeitshöhe 3,01 bis 4,00. Inkl. Gerüste/Arbeits-Ebenen.\nLE = m2",
            number: "",
            amount: 7000000,
            unit: "le"
        },
        "8469001896147d1LwZeQHk0VVhj5njnHgmr": {
            createdTs: 1620995514543,
            updatedTs: 1620995653414,
            order: 0,
            positionId: "8469001896147d1LwZeQHk0VVhj5njnHgmr",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "8469001896236EksA7x0XPwuxvwjsJ4Tx3",
            name: "Anschlüsse und Stösse vollständig verkleben, inkl.\nEntfernen und Abtransportieren nach Arbeitsbeendigung.",
            description: "Boden hart",
            number: "",
            amount: 2000000,
            unit: "m2"
        },
        "8469001896147gJWPpVHLqypziv31yzPzPY": {
            createdTs: 1620995435171,
            updatedTs: 1620995487081,
            order: 2,
            positionId: "8469001896147gJWPpVHLqypziv31yzPzPY",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "846900189623817LYZTAauYeudBR36mo6JL",
            name: "Demontieren und Wiedermontieren des Rollgerüsts auf\ndemselben Bauplatz.",
            description: " Zu Pos. 126.110ff",
            number: "",
            amount: 20000,
            unit: "st"
        },
        "84690018961481iGUKp25kNyJrAdivjTpo6": {
            createdTs: 1620995414557,
            updatedTs: 1620995461892,
            order: 1,
            positionId: "84690018961481iGUKp25kNyJrAdivjTpo6",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "846900189623817LYZTAauYeudBR36mo6JL",
            name: " Mit Doppelgeländer, Aufgang und allfälligen Auslegerstützen.",
            description: " Arbeitshöhe bis m 6,00.",
            number: "126.11",
            amount: 20000,
            unit: "st"
        },
        "8469001896148725LmAqUpBLh8A7HTaUtCk": {
            createdTs: 1620995384625,
            updatedTs: 1620995446182,
            order: 0,
            positionId: "8469001896148725LmAqUpBLh8A7HTaUtCk",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            sectionId: "846900189623817LYZTAauYeudBR36mo6JL",
            name: " Mit Doppelgeländer, Aufgang und allfälligen Auslegerstützen.",
            description: "Arbeitshöhe bis m 4,00",
            number: "126.1",
            amount: 90000,
            unit: "st"
        },
        "8469001896149b4uexkYAJHxiGybUT62Hb1": {
            createdTs: 1620993942298,
            updatedTs: 1620993964024,
            order: 0,
            positionId: "8469001896149b4uexkYAJHxiGybUT62Hb1",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "84690018962366ho45NsUhBSTrJYaidfH1y",
            name: "Polypropylen-Sickerrohre ",
            description:
                "Polypropylen-Sickerrohre PP-S,\nPolypropylen-Versickerungsrohre PP-VS und flexible Drainagerohre aus PP liefern und\nverlegen.",
            number: "",
            amount: 400000,
            unit: "m"
        },
        "8469001896149eQ9pnMHod6AeWMKwpDK7dD": {
            createdTs: 1620993869304,
            updatedTs: 1620993920179,
            order: 0,
            positionId: "8469001896149eQ9pnMHod6AeWMKwpDK7dD",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "84690018962391Jz0Jx63dGocvMqKmoby7P",
            name: "Kabelschutzrohre aus PE-HD\nliefern, C+S. DIN/ID ",
            description: "Mit STM, inkl. Dichtungen.",
            number: "",
            amount: 100000,
            unit: "m"
        },
        "8469001896149ikbhTUgBAWurUEcJmuDruD": {
            createdTs: 1620993990198,
            updatedTs: 1620994033861,
            order: 0,
            positionId: "8469001896149ikbhTUgBAWurUEcJmuDruD",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "84690018962375pRS8iiLB8Tn9uYj5L9Cex",
            name: "Beton für Bodenplatten,\nSchleppplatten und Pfahlkopfplatten mit konstanter Dicke,\nliefern,",
            description:
                "Oberfläche horizontal oder\neinseitig geneigt bis % 5,0.\n Plattendicke m 0,25\nBodenplatte Überlauf- und\nEntleerungsschacht und UG.",
            number: "",
            amount: 80000,
            unit: "m3"
        },
        "84690018961507uD2zqHVu0zmvN2tENy2NV": {
            createdTs: 1620993808981,
            updatedTs: 1620993864140,
            order: 1,
            positionId: "84690018961507uD2zqHVu0zmvN2tENy2NV",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "8469001896238bTiTBAtxdQJ1z86rfHsddr",
            name: "Erschwerter Aushub und Behinderungen",
            description: "Erschwerter Aushub maschinell,\nals Mehrleistung zu Graben- und Grubenaushub.",
            number: "102",
            amount: 100000,
            unit: "m3"
        },
        "84690018961511UASp2SkdDYj1AdeYQ0nzM": {
            createdTs: 1620993771503,
            updatedTs: 1620993823315,
            order: 0,
            positionId: "84690018961511UASp2SkdDYj1AdeYQ0nzM",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            sectionId: "8469001896238bTiTBAtxdQJ1z86rfHsddr",
            name: "Oberboden abtragen",
            description: "Maschinell. Ausmass: Volumen\nfest.",
            number: "101",
            amount: 1300000,
            unit: "m3"
        }
    },
    conditions: {
        "84690018961524YWgU7m723KqH7dkyE2dWv": {
            createdTs: 1620994158534,
            updatedTs: 1620994182904,
            order: 0,
            conditionId: "84690018961524YWgU7m723KqH7dkyE2dWv",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            name: "Bauvorhaben im Bau-Perimeter. ",
            description:
                "Auf dem Bau-Perimeter wird das bestehende Gewerbe-Gebäude vollumfänglich saniert\nund erweitert. Zusätzlich wird ein Dienstleistungsgebäude (Neubau) ostseitig angebaut\nund nordseitig eine Tiefgarage realisiert.\nIn der 1.Etappe wird das Dienstleistungsgebäude und die Tiefgarage erstellt. Während\ndieser Zeit wird das Gewebe-Gebäude mit der heutigen Nutzung (Büros, Ausbildungsstätte, Beherbergungen) weiterhin benutzt. Nach Inbetriebnahme des DienstleistungsGebäude wird in einer 2.Etappe das Gewerbe-Gebäude totalsaniert und erweitert.\nDie Umgebungsarbeiten werden in mehreren Etappen realisiert.\nDer Bahnweg (Fussweg) nordseitig entlang der Grenzen wird während den Bauarbeiten\naufgehoben und dann wieder erstellt.\nPlanerisch, organisatorisch und finanziell werden die beiden Bauvorhaben konsequent\ngetrennt geführt; dies wird auch in den Leistungs-Verzeichnisse so dargelegt. "
        },
        "8469001896152hFnUAguKUEGWNZjW2YNVbP": {
            createdTs: 1620994182930,
            updatedTs: 1620994194498,
            order: 1,
            conditionId: "8469001896152hFnUAguKUEGWNZjW2YNVbP",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            name: "Spezielles zu SBB-Linien S8/S14/S19:",
            description:
                "Der Bau-Perimeter grenzt nordseitig an die Gleis-Bereiche der SBB-Linien von\nOerlikon nach Wallisellen.\nDie Schutzmassnahmen für den Betrieb von Geräte/Maschinen/Transporte aller\nArt in der Nähe dieser Bahnanlagen sind gemäss SBB-Formular 4838 zwingend\nin Art und Vorgehensweisen einzuhalten und, wenn zutreffend, in Angebote bzw.\nin den Einheitspreisen einzurechnen."
        },
        "84690018961542tG1BzMQoRLYFyozzWLSdJ": {
            createdTs: 1620993579034,
            updatedTs: 1620993586422,
            order: 3,
            conditionId: "84690018961542tG1BzMQoRLYFyozzWLSdJ",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Regieansätze",
            description:
                "Es gelten die im Abschnitt 200\nangebotenen Ansätze für die\nganze Dauer der Leistungen des\nUnternehmers.\nDie Preisänderungsberechnungen\nfür die Regiearbeiten erfolgen\nanalog zu jenen der\nAkkordleistunen."
        },
        "8469001896154gxPQeTeuV9BoRaj9uLA2wJ": {
            createdTs: 1620993547024,
            updatedTs: 1620993579007,
            order: 2,
            conditionId: "8469001896154gxPQeTeuV9BoRaj9uLA2wJ",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Preisänderungen",
            description: "Betreffend Begriffsdefinitionen gelten die Bedingungen in\nPos. 000.200."
        },
        "84690018961573dVV2am2e1EBSfrA3rWsZy": {
            createdTs: 1620993527922,
            updatedTs: 1620993546998,
            order: 1,
            conditionId: "84690018961573dVV2am2e1EBSfrA3rWsZy",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Landesmaltelvertrag LMV",
            description:
                "Landesmantelvertrag für das\nBauhauptgewerbe.\nEs gilt der Landesmantelvertrag für das schweizerische\nBauhauptgewerbe\n(LMV 2016-2018)."
        },
        "846900189623559v9FDrjg9FE3MEv8t11ue": {
            createdTs: 1620993496732,
            updatedTs: 1620993532710,
            order: 0,
            conditionId: "846900189623559v9FDrjg9FE3MEv8t11ue",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Stichtag",
            description: "Als Stichtag gilt der\nEingabetag."
        }
    },
    generalInformation: {
        "8469005760305bXGhcPo3qc7ZcYBqGkRGmS": {
            createdTs: 1620994239696,
            updatedTs: 1620994241150,
            order: 2,
            value: "Ausstellung der Rechnungen\nDie Rechnungen sind an den Bauherrn zu adressieren und jeweils in 1-facher Ausfertigung an die Werubau AG, Dorfstrasse 38,\n8706 Meilen zur Kontrolle zuzustellen.\nAlle Rechnungen des Unternehmers müssen MWST konform (gem. Art. 37 MWStG) erstellt werden:\n- Firmenname und vollständige Adresse\n- MWST-Nr.\n- Adresse des Bauherrn\n- Datum der Leistung, resp. der Lieferung\n- Art resp. Gegenstand der Leistung resp. Lieferung\n- Rechnungsbetrag inkl. Angabe über MWST (MWST-Satz in % und/oder in CHF)\nRechnungen, welche nicht den obigen Bestimmungen entsprechen, werden an den Unternehmer zurückgesandt.",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            informationId: "8469005760305bXGhcPo3qc7ZcYBqGkRGmS"
        },
        "84690058500319ZUEFjvHrvXysRnRqLVr7L": {
            createdTs: 1620994149969,
            updatedTs: 1620994158516,
            order: 1,
            value: "Bauökologie\nEs sind grundsätzlich Premiumprodukte zu verwenden. Nach Möglichkeit sind Baumaterialien aus erneuerbaren Ressourcen zu\nverwenden. Petrochemische Erzeugnisse sind zu vermeiden. Schadstofffreie Produkte sind zu bevorzugen. Auf schwermetallhaltige\nProdukte und lösungsmittelhaltige Farben, Lacke und Klebstoffe sowie auf FCKW- und FKW-haltige Dämmstoffe ist zu verzichten. Es\ndürfen nur giftklassenfreie Kleber verwendet werden.",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            informationId: "84690058500319ZUEFjvHrvXysRnRqLVr7L"
        },
        "8469005858293bFXk5pkJU1tsYEK59beFgp": {
            createdTs: 1620994141708,
            updatedTs: 1620994149953,
            order: 0,
            value: "Fristen\nFür die Ausführung der Arbeiten, resp. Lieferungen gelten die Fristen gem. Werkvertrag. Können die Fristen aus irgendeinem Grunde\nnicht zum Voraus festgelegt oder müssen sie während des Baues geändert werden, so hat die Bauleitung die fehlenden oder neuen\nFristen für Arbeitsbeginn und Arbeitsvollendung festzusetzen. Den von der Bauleitung festgesetzten Terminen kommt die gleiche\nRechtswirkung zu wie den Fristen, welche gemäss Abs. 1 vereinbart worden sind.\nDie Bauherrschaft behält sich das Recht vor, während der Bauzeit Teilleistungen nach Erfordernis vor- oder nachzuverschieben,\nohne dass der Unternehmer daraus ein Recht auf Mehrkosten ableiten kann. Änderungen im Programm werden rechtzeitig\nbekanntgegeben.",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            informationId: "8469005858293bFXk5pkJU1tsYEK59beFgp"
        },
        "8469006516577hXUTmuKsBbRmCsYT39GfEN": {
            createdTs: 1620993483423,
            updatedTs: 1620993491422,
            order: 4,
            value: "25.4 Überzüge\nFür die Toleranzen gilt SIA 414. Böden mit Abläufen dürfen aber keine Taschen oder Gegengefälle aufweisen,\nin denen Wasser liegen bleibt. ",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            informationId: "8469006516577hXUTmuKsBbRmCsYT39GfEN"
        },
        "8469006540592juZuVbXUKgfxbtCLPvTQ79": {
            createdTs: 1620993459408,
            updatedTs: 1620993483408,
            order: 3,
            value: "25.3 Nachbehandlung des Betons\nDer Beton ist gegen Witterungseinflüsse (z.B. Sonne, Regen, Frost) und vor Erschütterung zu schützen.\nInsbesondere ist zu rascher Austrocknung des Beton durch geeignete Massnahmen zu verhindern. ",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            informationId: "8469006540592juZuVbXUKgfxbtCLPvTQ79"
        },
        "8469006550360cLg89XAVyb3Rx39RWYUA1r": {
            createdTs: 1620993449640,
            updatedTs: 1620993459390,
            order: 2,
            value: "25.2 Bewehrung\nBindedrähte sind unbedingt zum Betoninnern zurück zu biegen (Rostschäden).\nDie Bewehrung darf keinen Kontakt zu Einlageteilen aus Edelstahl haben. Wo nötig sind entsprechende\nSchutzvorrichtungen vorzusehen. ",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            informationId: "8469006550360cLg89XAVyb3Rx39RWYUA1r"
        },
        "8469006582125dRCKvtXk1BKfcZF3TKkJJS": {
            createdTs: 1620993417875,
            updatedTs: 1621001088205,
            order: 1,
            value: "25.1 Schalung\nDer Behälterboden ist in einer einzigen Etappe zu betonieren.\nFür den Behälter ist eine Rundschalung vorgesehen. Die Behälterwände sind in einer einzigen Etappe zu\nbetonieren (keine Arbeitsfugen!). Falls Binder erforderlich sind, müssen diese eine metallische Sperre\naufweisen. Zurückbleibende Öffnungen sind mindestens auf der Innenseite nachträglich auszuinjziieren.\nIn alle Arbeitsfugen wird ein Injektionsschlauch eingelegt.\nBei Verwendung von glatten Elementschalungen sind bei zu beschichtenden Flächen entsprechende\ntrinkwasserkonforme Massnahmen für einwandfreie Haftung des Verputzes einzurechnen.\nDie Behälterdecke ist in einer einzigen Etappe zu betonieren.\nVor dem Betonieren sind sämtliche Schalungen gründlich zu reinigen und zu benetzen. ",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            informationId: "8469006582125dRCKvtXk1BKfcZF3TKkJJS"
        },
        "8469006590695jZnS4fiBZ0jnUX0N3DezDv": {
            createdTs: 1620993409306,
            updatedTs: 1620993449626,
            order: 0,
            value: "25 Betonarbeiten\nBetonüberdeckung\nDiese muss bei den wasserseitigen Flächen des Behälters an jeder Stelle 4 cm betragen. Es wird besondere\nSorgfalt verlangt, damit dies überall eingehalten ist. Nicht zurückgebogene Bindedrähte, nicht entfernte Drähte,\nEisenreste oder andere Fremdkörper stellen ebenso eine Verletzung dieser Bestimmungen dar. Die\nÜberdeckung muss wasserseitig durch Distanzhalter, die durch einwandfreie Verbindung mit dem Beton (z.B.\nZementklötze) eine dichte Oberfläche ermöglichen, sichergestellt werden.\nDer Behälter wird gem. den Richtlinien des SVGW in porenarmem Beton ohne Beschichtung so ausgeführt,\ndass eine einfache und einwandfreie Reinigung im Betrieb gewährleistet ist. Das erfordert höchste Sorgfalt bei\nder Schalung, Bewehrung, beim herstellen, einbringen, verdichten und nachbehandeln des Betons. Bei\nungenügenden Ergebnissen (Rostflecken, Überzähne, Furchen, Poren, Kiesnester, etc.) müssen die\nbetroffenen Flächen gesamthaft, das heisst die gesamten Wände eines Behälters oder die gesamte Decke, auf\nKosten des Vertragspartners für die Beton- und Bewehrungsarbeiten nachträglich behandelt und mit einer\ngeeigneten Beschichtung versehen werden.\nSchächte und Fundamente für Elektrizität und Kommunikation sind nach den Plangrundlagen und Angaben des\nentsprechenden Werkeigentümers auszuführen. ",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            informationId: "8469006590695jZnS4fiBZ0jnUX0N3DezDv"
        }
    },
    additionalInformation: {
        "84690056888075HY3ZrESVXrbQYZsfMX7ed": {
            createdTs: 1620994311193,
            updatedTs: 1620995322218,
            order: 2,
            title: "Versicherungssumme",
            description: "Wasser",
            value: null,
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            informationId: "84690056888075HY3ZrESVXrbQYZsfMX7ed"
        },
        "8469005699963fAKSQPgNbsob4frC8mjK5B": {
            createdTs: 1620994300037,
            updatedTs: 1620994308002,
            order: 1,
            title: "Versicherungssumme",
            description: "Feuer",
            value: null,
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            informationId: "8469005699963fAKSQPgNbsob4frC8mjK5B"
        },
        "84690057104601brj8BEKnuZgvLfBW57vzD": {
            createdTs: 1620994289540,
            updatedTs: 1620994300017,
            order: 0,
            title: "Versicherung",
            description: "Haftpflicht",
            value: null,
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            informationId: "84690057104601brj8BEKnuZgvLfBW57vzD"
        },
        "8469006369127283UYtuNapLxNXs5kSLSi9": {
            createdTs: 1620993630873,
            updatedTs: 1620993677498,
            order: 0,
            title: "Haftpflichtversicherung",
            description: "Versicherung des Unternehmers",
            value: null,
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            informationId: "8469006369127283UYtuNapLxNXs5kSLSi9"
        }
    },
    deductions: {
        "8469001896053irtxjCKnjGcCEcfRZo5VEN": {
            createdTs: 1620991216635,
            updatedTs: 0,
            order: 0,
            deductionId: "8469001896053irtxjCKnjGcCEcfRZo5VEN",
            sectionId: "8469001896245iChaZxQXkwKo2FsSJV2rc3",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            name: "Pauschalabzug",
            value: null,
            sign: "negative",
            valueType: "number"
        },
        "846900189605843gJAX5zLWh65mrQpo2ms0": {
            createdTs: 1620991216635,
            updatedTs: 0,
            order: 0,
            deductionId: "846900189605843gJAX5zLWh65mrQpo2ms0",
            sectionId: "8469001896246kHWmhaPkgsiPdBZHe5wvR4",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            name: "Bauabzug",
            value: 0,
            sign: "negative",
            valueType: "percent"
        },
        "84690018960584er8CsKuUHFaErgpxj4dXK": {
            createdTs: 1620991216635,
            updatedTs: 0,
            order: 0,
            deductionId: "84690018960584er8CsKuUHFaErgpxj4dXK",
            sectionId: "8469001896246hnqVrxR0tFuYNX0o8ZJtuo",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            name: "Rabatt",
            value: null,
            sign: "negative",
            valueType: "percent"
        },
        "8469001896059iX0CAYVhoHEcghXYTGKq7h": {
            createdTs: 1620991216635,
            updatedTs: 0,
            order: 0,
            deductionId: "8469001896059iX0CAYVhoHEcghXYTGKq7h",
            sectionId: "846900189624529T9RKSnZbgido2mHyomkT",
            devisId: "8469001896249jVvfJLTNNFeLrKG16cKxR1",
            name: "Skonto",
            value: null,
            sign: "negative",
            valueType: "percent"
        },
        "8469001896145g4PjrRTHHxekM7zeiRacnw": {
            createdTs: 1620990992784,
            updatedTs: 0,
            order: 0,
            deductionId: "8469001896145g4PjrRTHHxekM7zeiRacnw",
            sectionId: "846900189624729sFGF1AuZ3KqdvuGuvSKu",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Pauschalabzug",
            value: null,
            sign: "negative",
            valueType: "number"
        },
        "84690018961467Yus2ofgyJ4UgbY8RAzZ3B": {
            createdTs: 1620990992783,
            updatedTs: 0,
            order: 0,
            deductionId: "84690018961467Yus2ofgyJ4UgbY8RAzZ3B",
            sectionId: "8469001896248939tj17nzchg6JZ14EZfDu",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Rabatt",
            value: null,
            sign: "negative",
            valueType: "percent"
        },
        "84690018961469gubnRHUH09e7mbfT00jyH": {
            createdTs: 1620990992783,
            updatedTs: 0,
            order: 0,
            deductionId: "84690018961469gubnRHUH09e7mbfT00jyH",
            sectionId: "8469001896247a5PdVAkgR1fPsCrXFjtPPG",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Skonto",
            value: null,
            sign: "negative",
            valueType: "percent"
        },
        "8469001896146mFGbTAgAYWAfkwWnCDaXWN": {
            createdTs: 1620990992783,
            updatedTs: 1620994053525,
            order: 0,
            deductionId: "8469001896146mFGbTAgAYWAfkwWnCDaXWN",
            sectionId: "846900189624616isMP2baFc8mw5GyrJNne",
            devisId: "846900189625284cy9SK0sZvVAsWQzNKtd3",
            name: "Endreinigung",
            value: 2000,
            sign: "negative",
            valueType: "percent"
        }
    },
    attachments: {}
}
