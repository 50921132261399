import styled from "styled-components"
import { IconPosition } from "."
import { rem } from "../utils"
import { themeColor } from "../utils/theme"

export const Container = styled.div``

export const PanelWrapper = styled.div`
    cursor: pointer;
    user-select: none;
`

export const PanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 5px;
    transition: background-color 0.1s ease;
    border-top: 1px solid ${themeColor("grey60")};
    &:hover {
        background-color: ${themeColor("grey35")};
    }
`

export const PanelIcon = styled.div<{ iconPosition: IconPosition }>`
    flex: none;
    line-height: 1;
    margin-right: ${p => (p.iconPosition === "left" ? 10 : 0)}px;
`

export const PanelHeader = styled.div`
    flex: 1;
    min-width: 0;
    font-size: ${rem(22)};
    line-height: 1.25;
    word-wrap: break-word;
    color: ${themeColor("primaryGrey")};
`
