import * as React from "react"
import { asyncConnect } from "../../resolvers"
import { ContractorModal } from "../../components/modals/ContractorModal"
import { useNotifications } from "../NotificationsProvider"
import { useCloudAction } from "../../hooks/useCloudAction"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { i18n } from "@smartdevis/client/src/services/translations"
import { BaseModalProps } from "@smartdevis/ui/src/Modal"
import { useArrayState } from "@smartdevis/ui/src/hooks/common"
import { values } from "@smartdevis/utils/src/map"
import { isEqual } from "lodash"

export const ProjectSubmittersModal = asyncConnect({
    stateResolvers: ["projectPredefinedCollections", "projectDetails", "contractors", "results", "projectDevis"],
    actions: ["mutate"],
    renderError: () => <>{null}</>,
    renderLoading: () => <>{null}</>
})<BaseModalProps>(p => {
    const { pushNotification } = useNotifications()
    const { items, setItems } = useArrayState(
        Array.from(
            new Set([
                ...values(p.projectDevis).flatMap(pDevis => pDevis.contractorIds),
                ...(p.projectDetails.contractorIds || [])
            ])
        )
    )

    const { update } = prepareMutation("projects", p.mutate)
    const { onSubmit, actionState } = useCloudAction(
        (actionId: string) => update(p.projectDetails, actionId)({ contractorIds: items }),
        p.results,
        () => {
            pushNotification(i18n("Submitters updated"))
        }
    )

    return (
        <ContractorModal
            disabledIds={values(p.projectDevis).flatMap(d => d.contractorIds)}
            setSelectedIds={setItems}
            selectedIds={items}
            level="project"
            loading={actionState.type === "Processing"}
            onClose={() => {
                if (!isEqual(items, p.projectDetails.contractorIds)) {
                    onSubmit()
                }
                p.onClose?.()
            }}
            visible={p.visible}
        />
    )
})
