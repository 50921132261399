import { creatableValidation } from "./common"
import { Domain, Utils } from "../domain"
import { filterObject, SMap } from "@smartdevis/utils/src/map"
import {
    validString,
    validateMemberOf,
    mkMapValidator,
    ValidationMap,
    validId,
    validUInt,
    optionalString,
    Validators
} from "@smartdevis/utils/src/validators"
import { StateType } from "@smartdevis/utils/src/types"

type CollectionKey = keyof (Domain.ProjectCollections & Domain.BaseCollections & Domain.ContractorCollections)
export const getFilePath = (
    userId: string,
    collection: CollectionKey,
    collectionId: string,
    attachmentId: string,
    extension: string
): Utils.FilePath => `${userId}/${collection}/${collectionId}/${attachmentId}.${extension}` as Utils.FilePath

export const getAttachmentExtension = (attachment: Pick<Domain.Attachment, "name">) =>
    attachment.name.split(".").pop() ?? ""

export const getCollectionForAttachmentType = (attachment: Pick<Domain.Attachment, "type">): CollectionKey => {
    switch (attachment.type) {
        case "position":
            return "positions"
        case "offer":
            return "offers"
        case "condition":
            return "conditions"
        default:
            return "devis"
    }
}

export const filterAttachments = (
    attachments: SMap<Domain.Attachment>,
    params: Partial<{
        devisId: string
        offerId: string
        positionIds: string[]
        conditionIds: string[]
    }>
): SMap<Domain.Attachment> => {
    return filterObject(attachments, (_, value) => {
        switch (value.type) {
            case "final":
            case "general":
            case "devis":
                return value.refId === params.devisId
            case "offer":
                return value.refId === params.offerId
            case "condition":
                return params.conditionIds?.includes(value.refId) ?? false
            case "position":
                return params.positionIds?.includes(value.refId) ?? false
            default:
                return false
        }
    })
}

export const validFilePath = validString as Validators<Utils.FilePath>

export const attachmentDataValidation: ValidationMap<Domain.AttachmentData> = {
    ...creatableValidation,
    attachmentId: validId,
    refId: validId,
    name: validString,
    path: validFilePath,
    size: validUInt,
    contentType: optionalString
}

export const attachmentValidation: ValidationMap<Domain.Attachment> = {
    ...attachmentDataValidation,
    type: [
        validateMemberOf<StateType<Domain.Attachment>>(["devis", "position", "general", "final", "offer", "condition"])
    ]
}

export const validateAttachment = mkMapValidator<Domain.Attachment>(attachmentValidation)
export const validateAttachmentData = mkMapValidator<Domain.AttachmentData>(attachmentDataValidation)
