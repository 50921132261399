import * as React from "react"
import { EventPayloads } from "@smartdevis/server/src/cloudEvents"
import { Button, IconButton } from "@smartdevis/ui/src/Button"
import { actionValidations } from "@smartdevis/server/src/validations"
import { useCloudAction } from "../../hooks/useCloudAction"
import { SubmitButton } from "../../components"
import { mkTextSchema, mkHiddenSchema, mkFormSchema } from "../forms/formSchemas"
import { i18n } from "../../services/translations"
import { asyncConnect } from "../../resolvers"
import { StyledForm } from "../forms"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { Label, P } from "@smartdevis/ui/src/Typography"
import { FlexRow, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { useFormHook, StyledFormSchema } from "@smartdevis/forms/src"
import { useAuth } from "../../views/AuthProvider"
import { Domain } from "@smartdevis/server/src/domain"
import { LogoState, UploadLogo } from "../UploadLogo"

const getStyledSchema = (userType: Domain.UserType): StyledFormSchema<EventPayloads["updateUser"]> =>
    userType === "contractor"
        ? [
              { type: "Title", value: i18n("User info") },
              { type: "Row", value: ["name", "surname"] },
              "phoneNumber",
              { type: "Title", value: i18n("Company Details") },
              "companyName",
              "street",
              { type: "Row", value: ["postalCode", "city"] }
              //   "cantons",
              //   "workCategory"
              // "isListed"
          ]
        : [
              { type: "Title", value: i18n("User info") },
              { type: "Row", value: ["name", "surname"] },
              "phoneNumber",
              { type: "Title", value: i18n("Company Details") },
              "companyName",
              "street",
              { type: "Row", value: ["postalCode", "city"] }
          ]

const getSchema = (userType: Domain.UserType) => {
    if (userType === "contractor")
        return mkFormSchema<EventPayloads["updateUser"]>(actionValidations.register, {
            companyName: mkTextSchema("Company name"),
            name: mkTextSchema("Name"),
            surname: mkTextSchema("Surname"),
            phoneNumber: mkTextSchema("Phone number"),
            city: mkTextSchema("City"),
            postalCode: mkTextSchema("Postal Code"),
            street: mkTextSchema("Street"),
            // TODO: to be changed when contractor directory is live
            // cantons: mkMultiselectSchema(
            //     "Cantons",
            //     [
            //         ["Zurich", "Zurich"],
            //         ["Bern", "Bern"]
            //     ],
            //     { placeholder: i18n("Please select canton") }
            // ),
            // workCategory: mkMultiselectSchema("Work category", [], {
            //     creatable: true,
            //     placeholder: i18n("Please add your work categories")
            // }),
            cantons: mkHiddenSchema(""),
            workCategory: mkHiddenSchema(""),
            email: mkHiddenSchema(""),
            isListed: mkHiddenSchema("")
            // isListed: mkRadioSchema("Contractor directory listing", [
            //     ["I want to be listed", true],
            //     ["I don't want to be listed", undefined]
            // ]) as any // TODO: fix types
        })
    else
        return mkFormSchema<EventPayloads["updateUser"]>(actionValidations.register, {
            companyName: mkTextSchema("Company name"),
            name: mkTextSchema("Name"),
            surname: mkTextSchema("Surname"),
            phoneNumber: mkTextSchema("Phone number"),
            city: mkTextSchema("City"),
            postalCode: mkTextSchema("Postal Code"),
            street: mkTextSchema("Street"),
            cantons: mkHiddenSchema(""),
            workCategory: mkHiddenSchema(""),
            isListed: mkHiddenSchema(""),
            email: mkHiddenSchema("")
        })
}

export const EditUserModal = asyncConnect({
    stateResolvers: ["results", "user"],
    actions: ["updateUser", "initializeEmailUpdate", "confirmEmailUpdate", "mutateUserWithUpload"],
    renderLoading: () => <>{null}</>
})<BaseModalProps>(p => {
    const auth = useAuth()
    const [userLogo, setUserLogo] = React.useState<LogoState>(
        p.user.logoUrl ? { type: "url", url: p.user.logoUrl } : { type: "none" }
    )
    const { actionState, onSubmit } = useCloudAction<EventPayloads["updateUser"] & { logo?: File }>(
        (actionId, res) =>
            p.mutateUserWithUpload({
                actionId,
                ...res,
                logo: userLogo.type === "file" ? userLogo.file : null,
                logoUrl: userLogo.type === "url" ? userLogo.url : undefined
            }),
        p.results,
        p.onClose
    )

    const { formViewProps, handleSubmit, submitted, result } = useFormHook({
        schema: getSchema(p.user.type),
        onSubmit: onSubmit,
        initialValue: p.user
    })

    const copy = i18n(actionState.type !== "Processing" ? "Save" : "Saving")

    return (
        <>
            <Modal
                size="m"
                header={i18n("Edit account")}
                visible={!!p.visible}
                onClose={p.onClose}
                footer={
                    <>
                        <Button onClick={p.onClose} btnType="secondary">
                            {i18n("cancel")}
                        </Button>
                        <SubmitButton
                            disabled={(submitted && result.type === "Err") || actionState.type === "Processing"}
                            loading={actionState.type === "Processing"}
                            onClick={handleSubmit}>
                            {i18n(copy)}
                        </SubmitButton>
                    </>
                }>
                <StyledForm {...formViewProps} styledSchema={getStyledSchema(p.user.type)} />
                <Label>{i18n("Email")}</Label>
                <FlexRow spaceBetween>
                    <P>{p.user.email}</P>
                    <IconButton icon="EditWhite" onClick={auth.manageCredentials}>
                        {i18n("Manage credentials")}
                    </IconButton>
                </FlexRow>
                <VerticalSpace h={16} />
                <FlexRow justifyEnd>
                    <UploadLogo
                        onChange={file => setUserLogo({ type: "file", file })}
                        onDelete={() => setUserLogo({ type: "none" })}
                        logoState={userLogo}
                    />
                </FlexRow>
            </Modal>
        </>
    )
})
