import * as React from "react"
import { Route, RouteProps, Redirect } from "react-router-dom"
import { isAccessible, Path, allPaths } from "../paths"
import { ErrorView } from "./Error"
import { AppLayout } from "../components/layouts/AppLayout"
import { asyncConnect } from "../resolvers"
import { Domain } from "@smartdevis/server/src/domain"

export type SecureRouteProps = RouteProps & { View: React.ComponentType<any>; pathMeta: Path }

// TODO write tests

export const SecureRoute = asyncConnect({
    stateResolvers: ["auth"]
})<SecureRouteProps>(({ View, pathMeta, ...p }) => {
    const { accessLevel: routeAccessLevel } = pathMeta
    return (
        <Route
            {...p}
            render={cp => {
                if (routeAccessLevel === "public" && p.auth.type === "Authenticated")
                    <Redirect path="/" to={allPaths.home.path} />

                if (
                    p.auth.type === "Authenticated" &&
                    (p.auth.value.type === "CONTRACTOR" || p.auth.value.type === "contractor") &&
                    window.location.pathname === allPaths.home.path
                )
                    return <Redirect path="/" to={allPaths.contractorOffers.path} />

                if (routeAccessLevel === "public")
                    return (
                        <AppLayout>
                            <View {...cp} />
                        </AppLayout>
                    )
                if (p.auth.type === "Authenticated")
                    return isAccessible(routeAccessLevel)(p.auth.value.type.toLowerCase() as Domain.UserType) ? (
                        <AppLayout>
                            <View {...cp} />
                        </AppLayout>
                    ) : (
                        <ErrorView type="access-denied" />
                    )

                return <ErrorView type="total" />
            }}
        />
    )
})

export const DefaultRoute = asyncConnect({
    stateResolvers: ["auth"]
})(p => {
    if (p.auth.type === "Authenticated" && (p.auth.value.type === "CONTRACTOR" || p.auth.value.type === "contractor"))
        return <Redirect path="/" to={allPaths.contractorOffers.path} />
    return <Redirect path="/" to={allPaths.home.path} />
})
