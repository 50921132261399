import { Domain } from "../domain"
import {
    validString,
    mkMapValidator,
    optionalNumber,
    optionalString,
    ValidationMap,
    validEmail,
    Validator,
    validateMemberOf,
    optionalBoolean,
    optionalStringArray,
    optional,
    validateString
} from "@smartdevis/utils/src/validators"
import { mkOk } from "@smartdevis/utils/src/result"

export const getFullName = (u?: { name?: string; surname?: string }) =>
    u?.surname ? `${u.name ?? ""} ${u.surname}` : u?.name ?? ""

export const validateUserType: Validator<Domain.UserType> = validateMemberOf([
    "architect",
    "contractor",
    "admin",
    "WD_MODERATOR",
    "CD_MODERATOR"
])

export const userValidation: ValidationMap<Required<Domain.User>> = {
    userId: validString,
    email: validEmail,
    phoneNumber: optionalString,
    name: validString,
    surname: validString,
    cantons: optionalStringArray,
    workCategory: optionalStringArray,
    isListed: optionalBoolean,
    type: [validateUserType],
    companyName: optionalString,
    taxNumber: optionalString, //TODO: We should place here optionalTaxId but it affects current users with the wrong format
    street: optionalString,
    streetNumber: [optional("")(validateString)],
    lastVisitVersion: optionalNumber,
    postalCode: optionalString,
    city: optionalString,
    agreedOn: validString,
    logoUrl: optionalString
}

export const userWithBusinessValidation: ValidationMap<Domain.User> = {
    userId: validString,
    email: validEmail,
    phoneNumber: validString,
    name: validString,
    surname: validString,
    companyName: validString,
    cantons: optionalStringArray,
    workCategory: optionalStringArray,
    isListed: optionalBoolean,
    type: [validateUserType],
    taxNumber: optionalString,
    street: validString,
    postalCode: validString,
    city: validString,
    agreedOn: validString
}

export const validateUser = mkMapValidator(userValidation)
export const _deprecatedValidateContractorUser = mkMapValidator({
    ...userValidation,
    type: [() => mkOk("contractor")]
}) as typeof validateUser
export const validateUserWithBusiness = mkMapValidator(userWithBusinessValidation)
