import * as React from "react"
import styled from "styled-components"
import { themeColor } from "./utils/theme"

export const Badge: React.FC<{ value: string | number; space?: number }> = p => (
    <BadgeWrapper>
        {p.children}
        <StyledBadge space={p.space}>{p.value}</StyledBadge>
    </BadgeWrapper>
)
const BadgeWrapper = styled.div`
    position: relative;
    display: inline-block;
`

const StyledBadge = styled.div<{ space?: number }>`
    background: ${themeColor("secondaryGrey")};
    color: ${themeColor("white")};
    border-radius: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    font-size: 10px;
    line-height: 1;
    padding: 3px 6px;
    top: 0;
    right: ${p => `${p.space || 0}px`};
    transform: translate(50%, -50%);
`
