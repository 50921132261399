import styled, { css } from "styled-components"
import { themeColor, themeConfig } from "@smartdevis/ui/src/utils/theme"
import { BaseInput, BaseTextarea } from "@smartdevis/ui/src/Inputs"
import { SelectContainer } from "@smartdevis/ui/src/Selects"
import { TMap } from "@smartdevis/utils/src/map"

export type CellVisual =
    | "alignRight"
    | "title"
    | "green"
    | "red"
    | "big"
    | "yellowBg"
    | "italic"
    | "bold"
    | "greyText"
    | "dragCursor"
    | "spaceBetween"
    | "centerHorizontally"
    | "fixedHeight100"
    | "noPadding"
    | "openIndicator"
    | "borderRight"
    | "header"

export const applyCellVisuals = (p: { visuals?: CellVisual[] }) => p.visuals?.map(v => cellVisualsMap[v])

const cellVisualsMap: TMap<CellVisual, ReturnType<typeof css> | string> = {
    alignRight: css`
        justify-content: flex-end;
        text-align: right;
        input {
            text-align: right;
        }
    `,
    bold: css`
        font-weight: 500;
    `,
    big: css`
        font-size: 1.2rem;
    `,
    header: css`
        font-size: ${themeConfig("fontSizeSmall")};
        color: ${themeColor("grey70")};
    `,
    centerHorizontally: css`
        justify-content: center;
        text-align: center;
    `,
    dragCursor: css`
        cursor: move;
    `,
    fixedHeight100: css`
        height: 100px;
    `,
    green: css`
        color: ${themeColor("rank1Color")};
        svg {
            color: ${themeColor("rank1Color")};
        }
    `,
    italic: css`
        font-style: italic;
    `,
    greyText: css`
        color: ${themeColor("grey70")};
    `,
    noPadding: css`
        padding: 0;
    `,
    red: css`
        color: ${themeColor("rank5Color")};
    `,

    yellowBg: css`
        background-color: ${themeColor("rank3ColorLight")};
    `,
    spaceBetween: css`
        justify-content: space-between;
    `,
    borderRight: css`
        border-right: 1px solid ${themeColor("grey50")};
    `,
    title: css`
        font-weight: bold;
    `,
    openIndicator: ""
}

export const TableBaseCell = styled.div<{ visuals?: CellVisual[] }>`
    min-height: calc(2.6rem - 2px);
    display: flex;
    justify-content: flex-start;
    padding: 3px 10px;
    overflow: hidden;
    white-space: pre-wrap;
    &:last-child {
        border-right: none;
    }

    ${applyCellVisuals}
`

export const CellOpenIndicatorWrapper = styled.div`
    position: absolute;
    pointer-events: none;
    display: none;
    top: 0;
    right: 0;
    border-radius: 0 4px 0 4px;
    background-color: ${themeColor("grey40")};
`

const OpenCellIndicatorStyles = css`
    &:hover {
        ${CellOpenIndicatorWrapper} {
            display: block;
        }
    }
`

export const CellFiller = styled.div`
    width: 100%;
    height: 100%;
`

export const TableCellStaticWrapper = styled.div`
    border-radius: 4px;
    padding: 8px 0 3px;
    min-height: calc(1rem + 12px);
    position: relative;
    width: 100%;
    ${OpenCellIndicatorStyles}
`

export const TableCellEditableWrapper = styled.div`
    border-radius: 4px;
    padding: 3px 0;
    min-height: calc(1rem + 12px);
    position: relative;
    width: 100%;
    ${OpenCellIndicatorStyles}
`

export const TableCellDescription = styled.div`
    font-size: 11px;
`

export const TableCellTagWrapper = styled.div`
    display: flex;
    img {
        margin-right: 8px;
    }
`

export const TableInputCell = styled(TableBaseCell)`
    padding: 0px 4px;
    display: flex;

    ${BaseInput}, ${BaseTextarea}, ${SelectContainer} {
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: ${themeColor("grey35")};
        margin: 4px;
        border-radius: 0;
        padding: 6px 2px 4px;
        min-height: 37px;
    }

    ${SelectContainer} {
        padding: 0 4px;
    }

    svg {
        fill: ${themeColor("grey60")};
    }
`

export const TableInputCellWrapper = styled.div`
    &:last-child > div {
        border-right: 0;
    }
`
