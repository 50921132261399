import dayjs from "dayjs"
import { Domain } from "@smartdevis/server/src/domain"
import { dateFormat } from "@smartdevis/server/src/constants"
import { br, Content, h2, h2wSubtext, ul } from "../../utils/pdf"

export const mkDocRawInfo = (devis: Pick<Domain.Devis, "workStartTs" | "workEndTs">): Content => [
    h2wSubtext("Bestandteile des Vertrags", "Als Bestandteile dieses Vertrags gelten neben der vorliegenden Urkunde:"),
    br("medium"),
    ul([
        "Das Angebot des Unternehmers gemäss diesem Werkvertrag.",
        "Durch das Bauobjekt bedingte besondere Bestimmungen gemäss diesem Werkvertrag.",
        "Das Leistungsverzeichnis gemäss diesem Werkvertrag.",
        "Diesem Werkvertrag angehängte Pläne.",
        "Beilagen.",
        "Nicht durch das Bauobjekt bedingte, allgemeine Bestimmungen",
        ul([
            "Vorspann gemäss diesem Werkvertrag.",
            "Norm SIA 118 «Allgemeine Bedingungen für Bauarbeiten» (2013).",
            "Übrige einschlägige Normendes SIA",
            "Übrige einschlägige anderer Fachverbände"
        ])
    ]),
    "Widersprechen sich einzelne Vertragsbestandteile, so bestimmt sich die Rangordnung nach Art. 21 Abs. 1 der Norm SIA 118, im Falle eines Gegenangebotes nach Art. 22 Abs. 4 der Norm SIA 118.",
    br("large"),
    h2("Vergütung"),
    ul([
        "Die Vergütung richtet sich nach dem Angebot des Unternehmers zu den Preisen und Mengen des Leistungsverzeichnisses gemäss diesem Werkvertrag.",
        "Der Unternehmer gewährt auf den Bruttoendpreis die Konditionen gemäss diesem Werkvertrag."
    ]),
    br("large"),
    h2("Termine / Fristen"),
    `Arbeitsbeginn: ${dayjs(devis.workStartTs).format(dateFormat)}`,
    `Arbeitsende: ${dayjs(devis.workEndTs).format(dateFormat)}`
]
