import * as React from "react"
import styled from "styled-components"
import { i18n } from "../../services/translations"
import { asyncConnect } from "../../resolvers"
import { Select } from "@smartdevis/ui/src/Selects"
import { FlexColumn, FlexRow, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { keys, values } from "@smartdevis/utils/src/map"
import { _noop } from "@smartdevis/utils/src/misc"
import { Accordion, AccordionPanel, useAccordion } from "@smartdevis/ui/src/Accordion"
import { H3, Label } from "@smartdevis/ui/src/Typography"
import { ArchitectContent, SubContent } from "../../components/layouts/Content"
import { List, ListRowRenderer, WindowScroller } from "react-virtualized"
import { TenderRow, getStatus } from "../../components/RecentTenders"

const DEFAULT_ROW_HEIGHT = 70
const DEFAULT_OPEN_ROW_HEIGHT = 200

type DevisOverviewSortBy = "entry-date" | "status" | "latest-action"

export const Overview = asyncConnect({
    stateResolvers: ["devisOverview"]
})(p => {
    const [sortBy, setSortBy] = React.useState<DevisOverviewSortBy>("entry-date")
    const [selected, onChange] = useAccordion([], { multiple: true })

    const sortedDevis = values(p.devisOverview)
        .filter(d => d.lastActionTs !== 0)
        .sort((a, b) => {
            switch (sortBy) {
                case "entry-date":
                    return b.devis.entryTs - a.devis.entryTs
                case "status":
                    return getStatus(a.requests, a.rounds).name.localeCompare(getStatus(b.requests, b.rounds).name)
                case "latest-action":
                    return b.lastActionTs - a.lastActionTs
            }
        })

    const getDynamicRowHeight = ({ index }: { index: number }) =>
        selected.includes(sortedDevis[index].devis.devisId)
            ? keys(sortedDevis[index].contractors).length * 10 + DEFAULT_OPEN_ROW_HEIGHT
            : DEFAULT_ROW_HEIGHT

    const listRef = React.useRef<List>()
    React.useEffect(() => {
        listRef?.current?.recomputeRowHeights()
    }, [selected, sortBy])

    const rowRenderer = React.useCallback<ListRowRenderer>(
        ({ key, index, style }) => {
            return (
                <div key={key} style={{ ...style }}>
                    <Accordion selected={selected} onChange={onChange} animationMs={0}>
                        <AccordionPanel
                            key={sortedDevis[index].devis.devisId}
                            header={<TenderRow.Header tender={sortedDevis[index]} />}
                            iconPosition="right"
                            openedIcon="ArrowUp"
                            closedIcon="ArrowDown"
                            styles={{
                                padding: "15px 5px"
                            }}>
                            <TenderRow.Body tender={sortedDevis[index]} />
                        </AccordionPanel>
                    </Accordion>
                </div>
            )
        },
        [sortedDevis]
    )

    return (
        <ArchitectContent title="Admin">
            <SubContent>
                <FlexColumn>
                    <Margin values="24px 0 0 0">
                        <FlexRow spaceBetween>
                            <H3>{i18n("All devis preview")}</H3>
                            <SelectContainer>
                                <Label>{i18n("Sort by")}</Label>
                                <Select
                                    mode="select"
                                    options={[
                                        mkDropdownOption(i18n("Entry date"), "entry-date"),
                                        mkDropdownOption(i18n("Status"), "status"),
                                        mkDropdownOption(i18n("Latest action"), "latest-action")
                                    ]}
                                    placeholder={i18n("Sort by")}
                                    value={sortBy}
                                    onChange={setSortBy}
                                />
                            </SelectContainer>
                        </FlexRow>
                    </Margin>
                    <VerticalSpace base="16px" />
                    <WindowScroller>
                        {({ width, height, isScrolling, onChildScroll, scrollTop }) => (
                            <List
                                ref={listRef as any}
                                autoHeight
                                autoWidth
                                width={width}
                                height={height}
                                rowHeight={getDynamicRowHeight}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowCount={sortedDevis.length}
                                rowRenderer={rowRenderer}
                            />
                        )}
                    </WindowScroller>
                </FlexColumn>
            </SubContent>
        </ArchitectContent>
    )
})

const SelectContainer = styled(FlexColumn)`
    min-width: 120px;
`
