import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { FlexRow, HorizontalSpace, styleIfProp, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import {
    getClientHeight,
    getClientWidth,
    getOnScreenPosition,
    noPropagationCallback
} from "@smartdevis/ui/src/utils/dom"
import { themeColor, themeConfig } from "@smartdevis/ui/src/utils/theme"
import { Portal } from "./Portal"
import { EnterDown } from "./utils/animations"
import { F0 } from "@smartdevis/utils/src/types"
import { ImageAsset } from "./Asset"
import { IconButton } from "./Button"
import { isDefined } from "@smartdevis/utils/src/misc"
import { useGlobalClick, useNonInputScrollEvent } from "./hooks/common"

type VerticalDirection = "top" | "bottom"
type HorizontalDirection = "left" | "right"

type PopconfirmDirection =
    | VerticalDirection
    | HorizontalDirection
    | `${VerticalDirection}-${HorizontalDirection}`
    | `${HorizontalDirection}-${VerticalDirection}`

type PopconfirmBaseProps = {
    closing: boolean
    direction: PopconfirmDirection
    open: boolean
    rect?: DOMRect
    ownRect?: DOMRect
}

const stylesForContainer = (p: PopconfirmBaseProps) => {
    if (!p.rect || !p.ownRect)
        return css`
            opacity: 0;
        `

    const ch = getClientHeight()
    const cw = getClientWidth()
    const spacer = 4
    const additionalMargin = 10
    const bottom = ch - p.rect.bottom - spacer - p.ownRect.height - additionalMargin
    const top = p.rect.top - spacer - p.ownRect.height - additionalMargin
    const vmidt = p.rect.top + p.rect.height / 2 - p.ownRect.height / 2 // vertical middle from top: ;

    const left = p.rect.left - spacer - p.ownRect.width - additionalMargin
    const right = cw - p.rect.right - 2 * spacer - p.ownRect.width - additionalMargin
    const hmidl = p.rect.left + p.rect.width / 2 - p.ownRect.width / 2 - additionalMargin //horizontal middle from left
    const hlmidl = p.rect.left - p.ownRect.width / 2 - additionalMargin // horizontal left middle from left (bottom left & top left placement)
    const hlmidr = cw - p.rect.right + p.rect.width - p.ownRect.width / 2 - additionalMargin // horizontal left middle from right (bottom left & top left placement)

    switch (p.direction) {
        case "top-left": {
            return `top: ${top}px; left: ${hlmidr}px`
        }
        case "top": {
            return `top: ${top}px; left: ${hmidl}px`
        }
        case "top-right": {
            return `top: ${top}px; left: ${hlmidl}px`
        }
        case "bottom-left": {
            return `bottom: ${bottom}px; left: ${hlmidr}px`
        }
        case "bottom": {
            return `bottom: ${bottom}px; left: ${hmidl}px`
        }
        case "bottom-right": {
            return `bottom: ${bottom}px; left: ${hlmidl}px`
        }
        case "left-top": {
            return `left: ${left}px; top: ${vmidt - additionalMargin}px`
        }
        case "left": {
            return `left: ${left}px; top: ${vmidt}px`
        }
        case "left-bottom": {
            return `left: ${left}px; top: ${vmidt + additionalMargin}px`
        }
        case "right-top": {
            return `right: ${right}px; top: ${vmidt - additionalMargin}px`
        }
        case "right": {
            return `right: ${right}px; top: ${vmidt}px`
        }
        case "right-bottom": {
            return `right: ${right}px; top: ${vmidt + additionalMargin}px`
        }
        default: {
            return `top: 0; left: 0`
        }
    }
}

const PopconfirmContainerBase = styled.div<PopconfirmBaseProps>`
    position: fixed;
    background: ${themeColor("white")};
    border: 1px solid ${themeColor("grey50")};
    border-radius: 4px;
    white-space: pre-wrap;
    padding: 12px;
    z-index: ${themeConfig("zIndexDropdown")};

    ${styleIfProp(
        "open",
        css`
            animation: ${EnterDown} 100ms ease-out;
        `
    )}

    ${stylesForContainer}
`

type PopconfirmContainerProps = {
    cancelIcon: ImageAsset
    closing: boolean
    direction: PopconfirmDirection
    okIcon: ImageAsset
    onClose: F0
    onConfirm: F0
    title: ReactNode | (() => ReactNode) | string
    rect?: DOMRect
}

const PopconfirmContainer: React.FC<PopconfirmContainerProps> = ({
    closing,
    cancelIcon,
    direction,
    okIcon,
    onClose,
    onConfirm,
    title,
    rect
}) => {
    const popconfirmRef = React.useRef<HTMLDivElement | null>(null)
    const [ownRect, setOwnRect] = React.useState<DOMRect | undefined>(undefined)

    React.useLayoutEffect(() => {
        let shouldUpdate = true
        const checkSizes = () => {
            if (popconfirmRef.current && !ownRect) {
                const newRect = getOnScreenPosition(popconfirmRef.current)
                if (newRect?.width ?? 0 > 0) {
                    setOwnRect(newRect)
                    shouldUpdate = false
                }
            }
            if (shouldUpdate) window.requestAnimationFrame(checkSizes)
        }
        checkSizes()
        return () => {
            shouldUpdate = false
        }
    }, [])

    return (
        <PopconfirmContainerBase
            ref={popconfirmRef}
            rect={rect}
            ownRect={ownRect}
            open={isDefined(ownRect)}
            direction={direction}
            closing={closing}>
            <FlexRow justifyCenter alignCenter>
                {title}
            </FlexRow>
            <VerticalSpace base="8px" />
            <FlexRow justifyEnd alignCenter>
                <IconButton icon={okIcon} onClick={onConfirm} />
                <HorizontalSpace base="8px" />
                <IconButton icon={cancelIcon} onClick={onClose} />
            </FlexRow>
        </PopconfirmContainerBase>
    )
}

export interface PopconfirmProps {
    cancelIcon?: ImageAsset
    direction: PopconfirmDirection
    disabled?: boolean
    okIcon?: ImageAsset
    title: ReactNode | (() => ReactNode) | string
    onCancel?: F0
    onConfirm: F0
}

export const Popconfirm: React.FC<PopconfirmProps> = ({
    cancelIcon = "Close",
    direction,
    disabled,
    okIcon = "Check",
    title,
    onCancel,
    onConfirm,
    children
}) => {
    const triggerRef = React.useRef<HTMLDivElement | null>(null)

    const [visible, setVisible] = React.useState(false)
    const [closing, setClosing] = React.useState(false)

    const show = () => {
        if (disabled) return

        setClosing(false)
        setTimeout(() => setVisible(true), 100) as any
    }

    const hide = (delay: number) => {
        setTimeout(() => {
            setClosing(true)
            setTimeout(() => setVisible(false), 100)
        }, delay) as any
    }

    const handleOnClose = () => {
        onCancel?.()
        hide(100)
    }

    const handleOnConfirm = () => {
        onConfirm()
        hide(100)
    }

    useGlobalClick(() => (visible ? hide(0) : null))
    useNonInputScrollEvent(() => hide(0))

    const portal = visible ? (
        <Portal>
            <PopconfirmContainer
                cancelIcon={cancelIcon}
                closing={closing}
                direction={direction}
                okIcon={okIcon}
                onClose={handleOnClose}
                onConfirm={handleOnConfirm}
                title={title}
                rect={getOnScreenPosition(triggerRef.current)}
            />
        </Portal>
    ) : null

    return (
        <>
            <div
                className="portal"
                style={disabled ? { pointerEvents: "none" } : {}}
                onClick={noPropagationCallback(show)}
                ref={triggerRef}>
                {children}
            </div>
            {portal}
        </>
    )
}
