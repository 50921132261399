import * as React from "react"
import Flatpickr, { DateTimePickerProps } from "react-flatpickr"
import "flatpickr/dist/themes/airbnb.css"
import { German } from "flatpickr/dist/l10n/de.js"
import { F1 } from "@smartdevis/utils/src/types"

export const DatePicker: React.FC<Omit<DateTimePickerProps, "onChange"> & { onChange?: F1<number> }> = p => (
    <Flatpickr
        {...p}
        onChange={(...props) => p.onChange && p.onChange(props[0][0].valueOf() || 0)}
        options={{
            ...(p.options || {}),
            altInputClass: "styled-date-picker",
            monthSelectorType: "static",
            altInput: true,
            altFormat: "d.m.Y",
            locale: German
        }}
    />
)
