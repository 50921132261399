import * as React from "react"
import {
    StyledTableRow,
    GRID_COLUMNS_SUM,
    RowVisual,
    TableRowContainer,
    TableFormActionPanel,
    TableFormInfoPanel
} from "./TableRow.styles"
import {
    Cell,
    TableNumberInput,
    TableTextInput,
    TableTextAreaInput,
    TableSelectInput,
    TableCustomBoxInput,
    mkCell,
    TableCell
} from "./TableCell"
import { FlexRow, Padding } from "@smartdevis/ui/src/utils/common"
import { i18n } from "../../services/translations"
import { useOutsideClick, useRefFunction } from "../../hooks/utilityHooks"
import { TableInputCellWrapper } from "./TableCell.styles"
import { StyledForm } from "../forms"
import { InlineSpinner } from "@smartdevis/ui/src/Spinner"
import { useAnimationToggle, useDeepEffect, useHovered } from "@smartdevis/ui/src/hooks/common"
import { useKeyPress } from "@smartdevis/ui/src/hooks/useKeyPress"
import { Asset } from "@smartdevis/ui/src/Asset"
import { Tag } from "@smartdevis/ui/src/Tag"
import { IconButton } from "@smartdevis/ui/src/Button"
import { mkStyledCustom } from "../forms/formSchemas"
import { Popover } from "@smartdevis/ui/src/Popover"
import { StyledCell, FormSchema, StyledInputsRenderMap, useFormHook } from "@smartdevis/forms/src"
import { validateForm } from "@smartdevis/forms/src/formUtils"
import { omitObject, keys, pickObject } from "@smartdevis/utils/src/map"
import { isDefined, _noop } from "@smartdevis/utils/src/misc"
import { Result, isOk, Err, isErr } from "@smartdevis/utils/src/result"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { labelize } from "@smartdevis/utils/src/text"
import { DropdownMenuOption, Dropdown, mkDropdownOption } from "@smartdevis/ui/src/Dropdown"

type ReactRenderable = string | React.ReactElement | null
export type TableRowDecorators = {
    grid?: number[]
    visuals?: RowVisual[]
    onClick?: F0
    onRevert?: F0
    hovered?: boolean
    onStaticCellClick?: F1<string>
}

export const TableRowRaw: React.FC<{ value: ReactRenderable[]; rowId?: string } & TableRowDecorators> = p => (
    <StyledTableRow
        data-cy="table-static-row"
        {...omitObject(p, ["grid", "value"])}
        grid={defaultizeGrid(p, p.value.length)}>
        {p.value}
    </StyledTableRow>
)

const defaultizeGrid = (g: { grid?: number[] }, cellsLength: number) =>
    g.grid || Array(cellsLength).fill(GRID_COLUMNS_SUM / cellsLength)

const getCellId = (rowId: string, index: number) => `${rowId}-${index}`
export const getCellIndexFromId = (rowId: string) => rowId.split("-").pop()
export const mkStaticTableRow = (
    rowId: string,
    cells: Cell[],
    grid?: number[],
    visuals?: RowVisual[]
): StaticRowProps => ({ rowId, cells, grid, visuals: visuals })

export type StaticRowProps<T = any> = { rowId: string; cells: Cell<T>[] } & TableRowDecorators

const renderTag = (visuals: RowVisual[] = []) => {
    if (!visuals.includes("parent")) return null
    if (visuals.includes("removed") && (visuals.includes("added") || visuals.includes("edited"))) return null
    if (visuals.includes("removed")) return <Tag type="removed">{i18n("{TAG}REMOVED")}</Tag>
    if (visuals.includes("added")) return <Tag type="added">{i18n("{TAG}ADDED")}</Tag>
    if (visuals.includes("edited")) return <Tag type="edited">{i18n("{TAG}EDITED")}</Tag>
    return null
}

export const StaticTableRow: React.FC<StaticRowProps> = p => (
    <TableRowContainer visuals={p.visuals}>
        <TableFormInfoPanel animate={false} show={(p.visuals ?? []).includes("loading")}>
            <InlineSpinner color="grey70" />
        </TableFormInfoPanel>
        {p.visuals?.includes("parent") ? (
            <FlexRow justifyEnd>
                <Padding values="8px">{renderTag(p.visuals)}</Padding>
            </FlexRow>
        ) : null}
        <TableRowRaw
            {...omitObject(p, ["onStaticCellClick", "onRevert"])}
            value={p.cells.map((cell, i) => (
                <TableCell
                    key={i}
                    onClick={p.onStaticCellClick ? () => p.onStaticCellClick?.(getCellId(p.rowId || "", i)) : undefined}
                    value={cell}
                />
            ))}
        />
        {isDefined(p.onRevert) && (
            <TableFormActionPanel show>
                <IconButton onClick={p.onRevert} icon="Revert" />
            </TableFormActionPanel>
        )}
    </TableRowContainer>
)

export const TableRowRenderer =
    (config: TableRowDecorators = {}) =>
    (p: { value: ReactRenderable[] }) =>
        <TableRowRaw {...config} {...p} />

const getErrors = <T extends any>(res: Result<T, T>) => {
    if (isOk(res)) return null
    return keys(res.value)
        .map(k => {
            const err = res.value[k] as any as Err<string>
            return `${i18n(labelize(`${k}`))}: ${i18n(err.toString())}`
        })
        .join("\n")
}

export const LoadingRow: React.FC = () => (
    <StaticTableRow rowId="loading" cells={[mkCell(<InlineSpinner />, ["centerHorizontally"])]} />
)

export const ErrorRow: React.FC<{ message?: string }> = p => (
    <StaticTableRow
        rowId="error"
        cells={[mkCell(p.message || i18n("We encountered a problem"), ["centerHorizontally"])]}
    />
)

type CellsStyledSchema<T> = Array<StyledCell<T, Cell>>
const mkStyledFromCells = <T extends any>(cells: Cell<T>[]): CellsStyledSchema<T> =>
    cells.map(cell => {
        switch (cell.editMode) {
            case "custom":
                return mkStyledCustom(cell.editValue)
            case "formless":
                return cell.field
            case "static":
            default:
                return mkStyledCustom(cell)
        }
    })

const addIdsToSchema = <T extends any>(
    rowId: string,
    formSchema: FormSchema<T>,
    styledSchema: CellsStyledSchema<T>
): FormSchema<T> => {
    if (!rowId) return formSchema
    keys(formSchema).forEach(key => {
        const index = styledSchema.indexOf(key)
        if (index === -1) return
        formSchema[key].id = getCellId(rowId, index)
    })
    return formSchema
}

export type FormRowProps<T> = StaticRowProps<T> & {
    formValue: T
    formSchema: FormSchema<T>

    initialFocusId?: string
    showActions?: boolean
    resetOnUpdateInitial?: boolean
    actionOnBlur?: "submit" | "cancel"
    actionOnEnter?: "submit"

    onPressEnter?: F0
    onChange?: F1<Result<T>>
    onSubmit?: F1<T>
    getRowOptions?: F1<T, DropdownMenuOption[]>
    onCancel?: F0
    onDelete?: F0
}
export const FormRow = <T extends any>(p: FormRowProps<T>): React.ReactElement => {
    const rowRenderer = useRefFunction(TableRowRenderer(pickObject(p, ["visuals", "grid"])), [
        JSON.stringify(p.visuals),
        JSON.stringify(p.grid)
    ])
    const rowRef = React.useRef(null)
    const isFirstRender = React.useRef(true)

    const { hovered, hoverListeners: listeners } = useHovered(p.showActions ?? false)
    const { animated, animate } = useAnimationToggle(300)

    const styledInputsRenderMap: Partial<StyledInputsRenderMap<Cell>> = {
        Custom: TableCell,
        Row: rowRenderer.current
    }

    const styledSchema = React.useMemo(() => mkStyledFromCells(p.cells), [p.cells])
    const formSchema = React.useMemo(
        () => addIdsToSchema(p.rowId, p.formSchema, styledSchema),
        [p.rowId, p.formSchema, styledSchema]
    )

    const {
        formViewProps: fp,
        handleSubmit,
        result,
        active,
        submitted,
        resetState
    } = useFormHook({
        onSubmit: p.onSubmit,
        schema: formSchema,
        initialValue: p.formValue
    })

    useDeepEffect(p.formSchema, () => fp.setState(validateForm(fp.schema, fp.state)))
    useDeepEffect(p.formValue, () => {
        if (p.resetOnUpdateInitial) resetState()
    })

    const animatedSubmit = () => {
        if (isErr(result)) animate()
        handleSubmit()
    }

    useKeyPress(
        () => {
            if (active) p.onPressEnter?.()
            if (p.actionOnEnter) animatedSubmit()
        },
        ["enter"],
        { allowShift: false }
    )

    useOutsideClick("table-row", rowRef.current, p.actionOnBlur && !hovered ? animatedSubmit : _noop)

    useDeepEffect(result, () => {
        if (isFirstRender.current || !active) return
        p.onChange?.(result as Result<T>)
    })

    React.useEffect(() => {
        isFirstRender.current = false
    })
    React.useLayoutEffect(() => {
        if (p.initialFocusId) document.getElementById(p.initialFocusId)?.focus()
    }, [])

    return (
        <TableRowContainer
            ref={rowRef}
            className="table-row"
            data-cy="table-editable-row"
            visuals={(p.visuals ?? []).concat("editable")}
            {...listeners}>
            {p.visuals?.includes("parent") ? (
                <FlexRow justifyEnd>
                    <Padding values="8px">{renderTag(p.visuals)}</Padding>
                </FlexRow>
            ) : null}
            <TableFormInfoPanel animate={animated} show={isErr(result) && submitted}>
                {isErr(result) && (
                    <Popover direction="bottom" content={[i18n("Errors") + ":", getErrors(result)].join("\n")}>
                        <Asset name="Exclamation" data-cy="table-row-error" size="icon" color="error" />
                    </Popover>
                )}
            </TableFormInfoPanel>
            <StyledForm
                {...fp}
                styledSchema={[{ type: "Row", value: styledSchema }]}
                inputsRenderMap={{
                    number: TableNumberInput,
                    text: TableTextInput,
                    textarea: TableTextAreaInput,
                    select: TableSelectInput,
                    customBox: TableCustomBoxInput
                }}
                elementsRenderMap={{ ItemWrapper: TableInputCellWrapper }}
                styledInputsRenderMap={styledInputsRenderMap as any}
            />
            <TableFormActionPanel show>
                {p.onSubmit && (
                    <IconButton
                        data-cy="table-button-submit"
                        disabled={isErr(result)}
                        onClick={animatedSubmit}
                        icon="Check"
                    />
                )}
                {(isDefined(p.getRowOptions) || p.onDelete) && (
                    <Dropdown
                        options={[
                            ...(isDefined(p.getRowOptions)
                                ? p.getRowOptions(result.value).map(option => ({
                                      ...option,
                                      disabled: isErr(result)
                                  }))
                                : []),
                            p.onDelete ? mkDropdownOption(i18n("Remove"), "remove", p.onDelete) : null
                        ].filter(isDefined)}
                        xDirection="left">
                        <IconButton icon="Dots" />
                    </Dropdown>
                )}
            </TableFormActionPanel>
        </TableRowContainer>
    )
}

// TODO:  Try to get rid of that
export type EditableRowProps<T> = FormRowProps<T> & {
    isEdited?: boolean
    onEditToggle?: F1<boolean>
    readonly?: boolean
    readonlyClickMessage?: string
}
export const EditableRow = <T extends any>(p: EditableRowProps<T>): React.ReactElement => {
    const [initialFocusId, setInitialFocusId] = React.useState<string | undefined>(undefined)
    const [isEdited, setEdited] = React.useState(p.isEdited || false)
    React.useEffect(() => {
        if (p.isEdited !== undefined) setEdited(p.isEdited)
    }, [p.isEdited])

    React.useEffect(() => {
        p.onEditToggle?.(isEdited)
    }, [p.onEditToggle, isEdited])

    const onSubmit = p.onSubmit
        ? (v: T) => {
              p.onSubmit?.(v)
              setEdited(false)
          }
        : undefined
    if (isEdited)
        return (
            <FormRow
                {...p}
                initialFocusId={initialFocusId}
                onSubmit={onSubmit}
                onDelete={() => {
                    setEdited(false)
                    p.onDelete?.()
                }}
                onCancel={() => setEdited(false)}
                showActions
            />
        )
    const staticView = (
        <StaticTableRow
            {...pickObject(p, ["cells", "grid", "rowId", "onRevert", "hovered"])}
            visuals={(p.readonly ? [] : ["clickable"]).concat(p.visuals || []) as RowVisual[]}
            onStaticCellClick={id => {
                setInitialFocusId(id)
                if (p.onClick) p.onClick()
                else p.onStaticCellClick?.(id)
                setEdited(!p.readonly)
            }}
        />
    )
    if (p.readonly && p.readonlyClickMessage)
        return (
            <Popover trigger="click" direction="bottom" content={p.readonlyClickMessage}>
                {staticView}
            </Popover>
        )

    return staticView
}
