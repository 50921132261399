import * as React from "react"
import AnimateHeight from "react-animate-height"
import { F1 } from "@smartdevis/utils/src/types"
import { Asset, ImageAsset } from "../Asset"
import { assertIsPanel } from "./utils"
import { Container, PanelWrapper, PanelContainer, PanelIcon, PanelHeader } from "./styles"

export { useAccordion } from "./hooks"

export type AccordionProps = {
    selected: string[]
    onChange: F1<string>
    animationMs?: number
}

export const Accordion: React.FC<AccordionProps> = ({ selected, onChange, animationMs = 200, children }) => (
    <Container>
        {React.Children.map(children, child => {
            assertIsPanel(child)
            const { children: panelChildren, ...rest } = child.props
            const active = selected.includes(child.key as string)

            return (
                <>
                    <PanelWrapper onClick={() => onChange(child.key as string)}>
                        <child.type {...rest} active={active} />
                    </PanelWrapper>
                    <AnimateHeight height={active ? "auto" : 0} duration={animationMs}>
                        {panelChildren}
                    </AnimateHeight>
                </>
            )
        })}
    </Container>
)

export type IconPosition = "left" | "right"

export type AccordionPanelProps = {
    key: string
    header: string | React.ReactNode
    iconPosition?: IconPosition
    openedIcon?: ImageAsset
    closedIcon?: ImageAsset
    active?: boolean
    styles?: React.CSSProperties
}

export const AccordionPanel: React.FC<AccordionPanelProps> = ({
    header,
    iconPosition = "left",
    openedIcon = "Minus",
    closedIcon = "Plus",
    active,
    styles
}) => {
    return (
        <PanelContainer
            style={{
                flexDirection: iconPosition === "left" ? "row" : "row-reverse",
                ...styles
            }}>
            <PanelIcon iconPosition={iconPosition}>
                <Asset size="icon" name={active ? openedIcon : closedIcon} color="grey70" />
            </PanelIcon>
            <PanelHeader>{header}</PanelHeader>
        </PanelContainer>
    )
}
