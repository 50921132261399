import { mkValidator, validateMap, validNumber, optionalString, ValidationMap } from "@smartdevis/utils/src/validators"
import { TMap } from "@smartdevis/utils/src/map"
import { Domain, Utils } from "../domain"

export const versionToNumber = (v: string) =>
    v.split(".").reduce((acc, sv, i) => {
        const value = parseInt(sv, 10) * Math.pow(1000, 2 - i)
        return acc + value
    }, 0)

export const ignorePatches = (version: string) => {
    const separated = version.split(".")
    if (separated.length !== 3) return version
    separated[2] = "0"
    return separated.join(".")
}

export const isValidVersion = (v: string) => /\d+\.\d+\.\d+/g.test(v)

const contentValidation: ValidationMap<TMap<Utils.Language, string>> = {
    en: optionalString,
    de: optionalString
}
export const changelogValidation: ValidationMap<Required<Domain.Changelog>> = {
    contentMap: [mkValidator(contentValidation)],
    version: validNumber,
    versionTs: validNumber
}

export const validateChangelog = mkValidator(changelogValidation)
export const validateAppChangelog = validateMap<Domain.Changelog>([validateChangelog])
