import { Cmd } from "redux-loop"
import { cmdList } from "../utils/store"
import { Domain, Utils } from "@smartdevis/server/src/domain"
import { getTranslationsService } from "../services/translations"
import { mkReducer } from "./utils"
import { LOCATION_CHANGE } from "connected-react-router"
import { appcuesCmd } from "./integrations"
import { pickCloudActions } from "../utils/cloudActions"
import { cloudCmd } from "../utils/commands"
import { Async, mkNotFetched } from "@smartdevis/utils/src/async"
import { TMap } from "@smartdevis/utils/src/map"
import { createAction } from "@smartdevis/utils/src/actions"

const LANGUAGE_KEY = "LANGUAGE_KEY"

export type MetaState = {
    language: Utils.Language
    translationsFetched: boolean
    changelog: Async<TMap<number, Domain.Changelog>>
}

export const initialState: MetaState = {
    language: "de",
    translationsFetched: false,
    changelog: mkNotFetched()
}

export const actions = {
    ...pickCloudActions(["mutateChangelog"]),
    _setMeta: (p: Partial<MetaState>) => createAction("_setMeta", p),
    initLanguage: () => createAction("initLanguage"),
    setLanguage: (v: Utils.Language) => createAction("setLanguage", v)
}

export const reducer = mkReducer<MetaState>((state, action, context) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return appcuesCmd(action, context.auth, state.language)
        case "_setMeta":
            return action.payload
        case "initLanguage":
            return cmdList([detectLanguageCmd, initTranslationsServiceCmd])
        case "setLanguage":
            return [{ language: action.payload }, storeLanguageCmd(action.payload)]
        case "mutateChangelog": {
            if (context.auth.type !== "Authenticated") return {}
            return cloudCmd(context.auth.value.token, action)
        }
    }
})

const initTranslationsServiceCmd = () =>
    Cmd.run(getTranslationsService().init, {
        successActionCreator: () => actions._setMeta({ translationsFetched: true })
    })

export const detectLanguageCmd = () =>
    Cmd.run(
        () => {
            const lang: Utils.Language | null = localStorage.getItem(LANGUAGE_KEY) as Utils.Language
            const tService = getTranslationsService()
            if (lang && tService.getLanguages().includes(lang)) return lang
            return tService.detectLanguage()
        },
        { successActionCreator: actions.setLanguage }
    )

export const storeLanguageCmd = (l: Utils.Language) => Cmd.run(() => localStorage.setItem(LANGUAGE_KEY, l))
