import { Cmd } from "redux-loop"
import { LocationChangeAction } from "connected-react-router"

import { Utils } from "@smartdevis/server/src/domain"
import { version } from "../version"
import { SMap } from "@smartdevis/utils/src/map"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { Authentication } from "./authState"

type AppcuesWindow = {
    Appcues: {
        page: () => void
        identify: (userId: string, obj: SMap<string | number>) => void
    }
}

const appcuesWindow = window as any as AppcuesWindow
export const hasAppcues = () => appcuesWindow.Appcues !== undefined

const _prevPath = ""
const cb = ({ payload }: LocationChangeAction, user: Authentication, language: Utils.Language) => {
    if (!hasAppcues()) return
    const userId = localStorage.getItem("appcues") || genTemporaryId()
    localStorage.setItem("appcues", userId)
    if (payload.location.pathname === _prevPath) return
    appcuesWindow.Appcues.page?.()
    appcuesWindow.Appcues.identify?.(userId, {
        ...(user.type === "Authenticated" ? { email: user.value.email } : {}),
        language,
        version
    })
}
export const appcuesCmd = (action: LocationChangeAction, user: Authentication, language: Utils.Language) =>
    Cmd.run(() => {
        if (!hasAppcues()) setTimeout(() => cb(action, user, language), 1000)
        else cb(action, user, language)
    })
