import { EmptyObject } from "@smartdevis/utils/src/types"
import { getAnalytics } from "./firebase"

type GAEvent<T extends string, T2 = EmptyObject> = {
    name: T
    payload?: T2
}

// Events
export type GAEvents =
    | GAEvent<"login">
    | GAEvent<"register_user">
    | GAEvent<"create_project">
    | GAEvent<"create_devis">
    | GAEvent<"create_offer", { invited: number }>
    | GAEvent<"create_offer_request">
    | GAEvent<"submit_offer_request">
    | GAEvent<"select_offer">

export const logEvent = (e: GAEvents): void => {
    const analytics = getAnalytics()
    if (!analytics) return
    analytics.logEvent(`sd_${e.name}`, e.payload)
}
