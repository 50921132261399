import * as React from "react"

import { SMap } from "@smartdevis/utils/src/map"
import { mkErr } from "@smartdevis/utils/src/result"
import { F0, F1, F2, F3 } from "@smartdevis/utils/src/types"
import { isDefined } from "@smartdevis/utils/src/misc"
import { AsyncEvent, EventResult } from "@smartdevis/utils/src/actions"
import { genTemporaryId } from "@smartdevis/utils/src/id"

type UseCloudActionBase = {
    actionState: AsyncEvent<EventResult>
    reset: F0
}
type UseCloudAction0 = UseCloudActionBase & {
    onSubmit: F0
}
type UseCloudAction1<T1> = UseCloudActionBase & {
    onSubmit: F1<T1>
}
type UseCloudAction2<T1, T2> = UseCloudActionBase & {
    onSubmit: F2<T1, T2>
}

export function useCloudAction(
    onSend: F1<string>,
    results: SMap<EventResult>,
    onDone?: F1<EventResult>
): UseCloudAction0
export function useCloudAction<T1>(
    onSend: F2<string, T1>,
    results: SMap<EventResult>,
    onDone?: F1<EventResult>
): UseCloudAction1<T1>
export function useCloudAction<T1, T2>(
    onSend: F3<string, T1, T2>,
    results: SMap<EventResult>,
    onDone?: F1<EventResult>
): UseCloudAction2<T1, T2>
// eslint-disable-next-line @typescript-eslint/ban-types
export function useCloudAction(onSend: Function, results: SMap<EventResult>, onDone?: F1<EventResult>) {
    const [processingActionId, setProcessingActionId] = React.useState("")
    const [, setActionId] = React.useState(genTemporaryId())
    const onSubmit = (v?: any, v2?: any) => {
        const id = genTemporaryId()
        setActionId(id)
        setProcessingActionId(id)
        onSend(id, v, v2)
    }

    React.useEffect(() => {
        if (processingActionId && results[processingActionId]) onDone?.(results[processingActionId])
    }, [isDefined(results[processingActionId || ""]), processingActionId])

    const getActionStateRaw = (): AsyncEvent<EventResult> => {
        if (!processingActionId) return { type: "NotStarted" }
        const res = results[processingActionId]
        if (!res) return { type: "Processing" }
        if (res.type === "Err") return mkErr(res?.obj?.value || res.value)
        return { type: "Done", value: results[processingActionId] }
    }

    return { actionState: getActionStateRaw(), onSubmit, reset: () => setProcessingActionId("") }
}
