import * as React from "react"
import { ArchitectContent, SubContent } from "../../components/layouts/Content"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect } from "../../resolvers"
import { PageHeader } from "../../components/layouts/Header"
import { Domain } from "@smartdevis/server/src/domain"
import { values } from "@smartdevis/utils/src/map"
import { Row } from "../../components/table/Table"
import { mkCell, mkCellEmpty } from "../../components/table/TableCell"
import { DropdownMenuOption, Dropdown, mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Asset } from "@smartdevis/ui/src/Asset"
import { Button, IconButton } from "@smartdevis/ui/src/Button"
import { ContractorForm } from "../../components/modals/ContractorForm"
import {
    FlexColumn,
    FlexRow,
    GridContainer,
    HorizontalSpace,
    Margin,
    VerticalSpace
} from "@smartdevis/ui/src/utils/common"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { BaseModalProps, ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"
import { isDefined } from "@smartdevis/utils/src/misc"
import { Label, Pre, P } from "@smartdevis/ui/src/Typography"
import { useCloudAction } from "../../hooks/useCloudAction"
import { isErr } from "@smartdevis/utils/src/result"
import { useNotifications } from "../NotificationsProvider"
import { FilesUploadModal } from "../../components/modals/FilesUploadModal"
import { UploadingFile } from "@smartdevis/ui/src/Upload"
import { BigButton } from "./devis-positions/DevisPositions"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { downloadBlob } from "@smartdevis/client/src/services/downloader"
import { generateCSVFile, readCSVFile } from "@smartdevis/client/src/services/csv"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import {
    ContractorValidationError,
    extractValidatedContractors,
    removeDuplicateImportContractors
} from "@smartdevis/client/src/services/import"
import { Popover } from "@smartdevis/ui/src/Popover"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"
import { cutString } from "@smartdevis/utils/src/text"
import { List, ListRowRenderer, WindowScroller } from "react-virtualized"
import { StaticRowProps, StaticTableRow } from "../../components/table/TableRow"
import { ContactorSortingDropdown, useContractorSorting } from "../../components/ContractorSortingDropdown"

const mkContractorRow = (c: Domain.Contractor, options: DropdownMenuOption[]): Row<"contractor"> & StaticRowProps => ({
    type: "contractor",
    grid: [6, 5, 7, 5, 1],
    mode: "static",
    rowId: c.contractorId,
    cells: [
        mkCell(c.companyName ?? ""),
        mkCell(`${c.name} ${c.surname}`),
        mkCell(c.email),
        mkCell(`${c.postalCode} ${c.city}`),
        mkCell(
            <Dropdown options={options}>
                <Asset name="DotsFilled" size="icon" />
            </Dropdown>
        )
    ]
})

const headerRow = (): Row<"header"> & StaticRowProps => ({
    type: "header",
    grid: [6, 5, 7, 5, 1],
    mode: "static",
    rowId: "header",
    visuals: ["header"],
    cells: [
        mkCell(i18n("Company name")),
        mkCell(i18n("Name")),
        mkCell(i18n("Email")),
        mkCell(i18n("Address")),
        mkCellEmpty()
    ]
})

export type ImportContractorsTemplate = Required<
    Pick<Domain.Contractor, "companyName" | "email" | "name" | "surname">
> &
    Partial<Pick<Domain.Contractor, "phoneNumber" | "street" | "postalCode" | "city" | "number" | "workCategory">>

const getImportContractorsCSVHeader = () => [
    i18n("Company name"),
    i18n("Email"),
    i18n("First name"),
    i18n("Last name"),
    i18n("Phone number"),
    i18n("Street"),
    i18n("Postal code"),
    i18n("City"),
    i18n("Number"),
    i18n("Work category")
]

export const ContactsView = asyncConnect({
    stateResolvers: ["results", "contractors"],
    actions: ["mutate", "bulkMutate"]
})(p => {
    const listRef = React.useRef(null)
    const [isFormOpen, setIsFormOpen] = React.useState(false)
    const [contractorToRemove, setContractorToRemove] = React.useState<Domain.Contractor | null>(null)
    const [contractorId, setContractorId] = React.useState<string | undefined>()
    const { remove } = prepareMutation("contractors", p.mutate)
    const { pushNotification } = useNotifications()
    const { onSubmit, actionState: removalState } = useCloudAction<Domain.Contractor>(
        (actionId, c) => remove(c, actionId)(),
        p.results,
        res => {
            setContractorToRemove(null)
            if (isErr(res)) pushNotification(res.value.toString())
        }
    )

    const [isImportModalOpen, setIsImportModalOpen] = React.useState(false)
    const [uploadFileModalVisible, setUploadFileModalVisible] = React.useState(false)
    const [noValidContactsFound, setNoValidContactsFound] = React.useState(false)
    const [foundDuplicateContacts, setFoundDuplicateContacts] = React.useState(false)
    const [contractorsToImport, setContractorsToImport] = React.useState<ImportContractorsTemplate[]>([])
    const [validationErrors, setValidationErrors] = React.useState<ContractorValidationError[]>([])

    const downloadContactsTemplate = async () => {
        const blob = await generateCSVFile([getImportContractorsCSVHeader()])
        downloadBlob(i18n("contacts-template"), "csv")(blob)
    }

    const onFileUpload = async (file: UploadingFile) => {
        const existingContractorsEmails = values(p.contractors).map(c => c.email)
        const fileContent = await readCSVFile(file.file)
        const extractedContractors = extractValidatedContractors(fileContent)
        const uniqueContractors = removeDuplicateImportContractors(
            extractedContractors.valid,
            existingContractorsEmails
        )

        setValidationErrors([])
        setNoValidContactsFound(false)
        setFoundDuplicateContacts(false)
        if (extractedContractors.invalid.length) setValidationErrors(extractedContractors.invalid)
        if (!uniqueContractors.length) setNoValidContactsFound(true)
        if (extractedContractors.valid.length !== uniqueContractors.length) setFoundDuplicateContacts(true)
        setContractorsToImport(uniqueContractors)
        setUploadFileModalVisible(false)
    }

    const onImportModalClose = () => {
        setIsImportModalOpen(false)
        setNoValidContactsFound(false)
        setFoundDuplicateContacts(false)
    }

    const { onSubmit: onContractorsImportConfirm, actionState: contractorsImportActionState } = useCloudAction<
        ImportContractorsTemplate[]
    >(
        async (actionId, contractors) => {
            p.bulkMutate({
                actionId,
                collection: "contractors",
                mutationType: "create",
                items: contractors.map(c => ({
                    ...c,
                    contractorId: genTemporaryId(),
                    createdTs: getTs()
                }))
            })
        },
        p.results,
        () => {
            setContractorsToImport([])
            onImportModalClose()
        }
    )

    const { sortedContractors, ...dropdownProps } = useContractorSorting(values(p.contractors))
    const rowRenderer = React.useCallback<ListRowRenderer>(
        ({ key, index, style }) => {
            return (
                <div key={key} style={style}>
                    <StaticTableRow
                        {...mkContractorRow(sortedContractors[index], [
                            mkDropdownOption(i18n("Edit"), "edit", () => {
                                setContractorId(sortedContractors[index].contractorId)
                                setIsFormOpen(true)
                            }),
                            mkDropdownOption(i18n("Remove"), "remove", () =>
                                setContractorToRemove(sortedContractors[index])
                            )
                        ])}
                    />
                </div>
            )
        },
        [sortedContractors]
    )

    return (
        <>
            <ArchitectContent>
                <SubContent>
                    <PageHeader
                        title={i18n("Contacts")}
                        actionButtons={[<ContactorSortingDropdown key="sorting" {...dropdownProps} />]}
                    />
                    <StaticTableRow {...headerRow()} />
                    <WindowScroller>
                        {({ width, height, isScrolling, onChildScroll, scrollTop }) => (
                            <List
                                ref={listRef as any}
                                autoHeight
                                autoWidth
                                width={width}
                                height={height}
                                rowHeight={40}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowCount={values(p.contractors).length}
                                rowRenderer={rowRenderer}
                            />
                        )}
                    </WindowScroller>
                    <ContractorForm
                        results={p.results}
                        mutate={p.mutate}
                        visible={isFormOpen}
                        contractor={contractorId ? p.contractors[contractorId] : undefined}
                        headerTitle="New contact"
                        onBack={() => {
                            setIsFormOpen(false)
                            setContractorId(undefined)
                        }}
                    />
                    <VerticalSpace base="8px" />
                    <IconButton icon="CrossWhite" onClick={() => setIsFormOpen(true)}>
                        {i18n("Add contact")}
                    </IconButton>
                    <Margin values="16px 0 0 0">
                        <IconButton icon="CrossWhite" onClick={() => setIsImportModalOpen(true)}>
                            {i18n("Import contacts")}
                        </IconButton>
                    </Margin>
                </SubContent>
            </ArchitectContent>
            {contractorToRemove && (
                <ConfirmationModal
                    onSubmit={() => onSubmit(contractorToRemove)}
                    cancelText={i18n("Cancel")}
                    header={i18n("Remove contact")}
                    loading={removalState.type === "Processing"}
                    contentText={i18n("Are you sure you want to delete this contact?")}
                    onClose={() => setContractorToRemove(null)}
                    submitText={i18n("Confirm")}
                    visible={isDefined(contractorToRemove)}>
                    <VerticalSpace base="8px" />
                    <Pre>{`${contractorToRemove.companyName}\n${contractorToRemove.email}`}</Pre>
                </ConfirmationModal>
            )}
            {isImportModalOpen && (
                <ImportContractorsModal
                    header={i18n("Import contacts")}
                    visible={isImportModalOpen}
                    onClose={onImportModalClose}
                    cancelText={i18n("Close")}>
                    <VerticalSpace base="8px" />
                    <P color="grey70">
                        {[
                            i18n("To import your existing contacts,"),
                            i18n("download and fill out the contacts template (.csv format) available below."),
                            i18n("Later you can upload the file and have the contacts available in Smart Devis."),
                            i18n("The required fields are company name and email.")
                        ].join(" ")}
                    </P>
                    <Margin values="32px 0 0 0">
                        <FlexRow justifyCenter style={{ width: "100%", minHeight: "150px" }}>
                            <BigButton
                                name={i18n("Download contacts template")}
                                icon="IconDevisImport"
                                onClick={downloadContactsTemplate}
                            />
                            <HorizontalSpace base="16px" />
                            <BigButton
                                name={i18n("Upload filled contacts file")}
                                icon="IconDevisUpload"
                                onClick={() => setUploadFileModalVisible(true)}
                            />
                        </FlexRow>
                    </Margin>
                    {(noValidContactsFound || foundDuplicateContacts) && (
                        <>
                            <VerticalSpace base="8px" />
                            <InfoBox type="warning">
                                {[
                                    foundDuplicateContacts
                                        ? i18n("Detected duplicate contacts.")
                                        : i18n("Could not detect any valid contacts."),
                                    i18n("Please check your uploaded file and try again.")
                                ].join(" ")}
                            </InfoBox>
                        </>
                    )}
                </ImportContractorsModal>
            )}
            {uploadFileModalVisible && (
                <FilesUploadModal
                    visible={uploadFileModalVisible}
                    onFileUpload={onFileUpload}
                    title={i18n("Drop your contacts file here")}
                    onClose={() => setUploadFileModalVisible(false)}
                    uploadStatus={"Done"}
                    acceptableExtensions={[".csv"]}
                />
            )}
            {contractorsToImport.length > 0 && (
                <ConfirmationModal
                    onSubmit={() => onContractorsImportConfirm(contractorsToImport)}
                    cancelText={i18n("Cancel")}
                    header={i18n("Confirm contacts import")}
                    loading={contractorsImportActionState.type === "Processing"}
                    onClose={() => setContractorsToImport([])}
                    submitText={i18n("Confirm")}
                    visible={contractorsToImport.length > 0}>
                    {validationErrors.length > 0 && (
                        <>
                            <InfoBox type="warning">
                                <FlexColumn>
                                    {i18n("Invalid data will be ignored: ")}
                                    <Label>
                                        {validationErrors
                                            .map(err => i18n("Row $1 - Column $2", err.row, err.column))
                                            .join(", ")}
                                    </Label>
                                </FlexColumn>
                            </InfoBox>
                            <VerticalSpace base="16px" />
                        </>
                    )}
                    {foundDuplicateContacts && (
                        <>
                            <InfoBox type="warning">
                                {i18n("Some contacts cannot be imported due to the email duplication.")}
                            </InfoBox>
                            <VerticalSpace base="16px" />
                        </>
                    )}
                    {i18n("Are you sure you want to import all valid contacts available below?")}
                    <VerticalSpace base="16px" />
                    <FlexColumn>
                        <Margin values="16px 16px 8px">
                            <GridContainer gap="16px" columnsGrid={[1, 1, 1, 1, 1]} rowsGrid={[1]}>
                                <Join items={values(getImportContractorsCSVHeader()).slice(0, 4)}>
                                    {h => <Label>{h}</Label>}
                                </Join>
                                <Label>{i18n("Contact details")}</Label>
                            </GridContainer>
                        </Margin>
                        <Join items={contractorsToImport}>
                            {contractor => (
                                <Pre>
                                    <GridContainer gap="16px" columnsGrid={[1, 1, 1, 1, 1]} rowsGrid={[1]}>
                                        <Join items={values(contractor).slice(0, 4)}>
                                            {c => <Label>{cutString(c || "-", 16)}</Label>}
                                        </Join>
                                        <Popover
                                            content={
                                                <FlexColumn>
                                                    <Join items={values(contractor).slice(4)}>
                                                        {(c, i) => (
                                                            <FlexRow spaceBetween>
                                                                <Label>
                                                                    {getImportContractorsCSVHeader().slice(4)[i]}
                                                                </Label>
                                                                <HorizontalSpace base="16px" />
                                                                <Label>{c ? c : "-"}</Label>
                                                            </FlexRow>
                                                        )}
                                                    </Join>
                                                </FlexColumn>
                                            }
                                            direction="right">
                                            <FlexRow justifyCenter alignCenter>
                                                <Asset name="DotsFilled" size="icon" />
                                            </FlexRow>
                                        </Popover>
                                    </GridContainer>
                                </Pre>
                            )}
                        </Join>
                    </FlexColumn>
                </ConfirmationModal>
            )}
        </>
    )
})

type ImportContractorsModalProps = Pick<BaseModalProps, "onClose" | "header" | "visible"> & {
    contentText?: string
    cancelText?: string | React.ReactElement
    submitButtonProps?: Partial<React.ComponentProps<typeof Button>>
    loading?: boolean
}

const ImportContractorsModal: React.FC<ImportContractorsModalProps> = p => {
    return (
        <Modal
            {...p}
            size="s"
            footer={
                <>
                    {p.onClose && (
                        <Button btnType="secondary" onClick={p.onClose}>
                            {p.cancelText}
                        </Button>
                    )}
                </>
            }>
            {p.contentText}
            {p.children}
        </Modal>
    )
}
