import * as React from "react"
import styled from "styled-components"
import { H5, Label } from "./Typography"

export const EstimateBlock: React.FC<{
    price: string
    label: string
    sublabel: string
    style?: React.CSSProperties
}> = p => (
    <Container style={p.style}>
        <UpperLabel color="grey70">{p.label}</UpperLabel>
        <H5>{p.price}</H5>
        <UpperLabel color="grey70">{p.sublabel}</UpperLabel>
    </Container>
)

const UpperLabel = styled(Label)`
    text-transform: uppercase;
`

const Container = styled.div`
    /* margin: 0 128px 0 0; */
`
