import * as React from "react"
import { i18n } from "../services/translations"
import { NotificationsRenderer, Notification } from "@smartdevis/ui/src/Notifications"
import { _noop } from "@smartdevis/utils/src/misc"
import { F1 } from "@smartdevis/utils/src/types"
import { genTemporaryId } from "@smartdevis/utils/src/id"

type NotificationsState = {
    pushNotification: F1<string>
}
const NotificationsContext = React.createContext<NotificationsState>({
    pushNotification: _noop
})

export const useNotifications = () => {
    const ctx = React.useContext(NotificationsContext)
    return { pushNotification: ctx.pushNotification }
}

export const NotificationsProvider: React.FC = p => {
    const [ns, setNotifications] = React.useState<Notification[]>([])
    const pushNotification = React.useCallback(
        (newNotification: string) => setNotifications(v => v.concat({ value: newNotification, id: genTemporaryId() })),
        [setNotifications]
    )
    return (
        <NotificationsContext.Provider value={{ pushNotification }}>
            {p.children}
            <NotificationsRenderer
                values={ns}
                onClose={id => setNotifications(vs => vs.filter(v => v.id !== id))}
                closeText={i18n("Close")}
            />
        </NotificationsContext.Provider>
    )
}
