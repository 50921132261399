import * as React from "react"
import { IconButton } from "@smartdevis/ui/src/Button"
import { Margin, FlexRow } from "@smartdevis/ui/src/utils/common"
import { Row, Table } from "../../../components/table/Table"
import { getRowVisualFromDelta, MutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import { i18n } from "@smartdevis/client/src/services/translations"
import { useItemsMap } from "../../../hooks/utilityHooks"
import { sortSortables, calculateOrder } from "@smartdevis/utils/src/comparable"
import { remap, values, SMap } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { additionalInformationValidation } from "@smartdevis/server/src/models/position"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Select } from "@smartdevis/ui/src/Selects"
import { F0, F1, F2 } from "@smartdevis/utils/src/types"
import { mkFormSchema, mkTextareaSchema } from "../../../components/forms/formSchemas"
import { mkCell } from "../../../components/table/TableCell"
import { HWFillsSelectContainer } from "./ListUtils"
import { Domain } from "@smartdevis/server/src/domain"

type AdditionalInformationListProps = {
    isReadonly: boolean
    deltas?: SMap<Domain.RoundDelta>
    mutations: MutationManagement<"additionalInformation", Domain.AdditionalInformation>
    items: SMap<Domain.AdditionalInformation>
    mkItem: (order: number) => Domain.AdditionalInformation
}
export const AdditionalInformationList: React.FC<AdditionalInformationListProps> = p => {
    const { items: hwFillsMap, setItem: setHwFills } = useItemsMap<boolean>(
        remap(p.items, identity, ai => (ai.value === null ? true : false))
    )

    const sortedItems = sortSortables(values(p.mutations.allItems))
    const rows = sortedItems.map(ai =>
        mkInformationRow(
            ai,
            {
                readonly: p.isReadonly,
                onDelete: p.mutations.onItemRemove(ai),
                onSubmit: p.mutations.onItemSubmit,
                isEdited: p.mutations.isUnsaved(ai.informationId),
                onHwFills: setHwFills,
                hwFills: hwFillsMap[ai.informationId],
                onRevert: p.mutations.onRevertDelta?.(p.deltas?.[ai.informationId]?.deltaId)
            },
            p.deltas?.[ai.informationId]
        )
    )

    return (
        <>
            {isEmpty(p.mutations.allItems) ? null : (
                <Table<"information" | "header">
                    rowHierarchy={["information"]}
                    onDrag={(_, index, ids) => {
                        const id = ids.draggedId
                        const order = calculateOrder(sortedItems, sortedItems.indexOf(p.mutations.allItems[id]), index)
                        if (p.mutations.isUnsaved(id))
                            return p.mutations.setUnsavedItem({ ...p.mutations.allItems[id], order })
                        return p.mutations.onItemSubmit(p.mutations.allItems[id])({ order })
                    }}
                    draggable={!p.isReadonly}
                    rows={[mkHeaderRow(), ...rows]}
                />
            )}
            {!p.isReadonly && (
                <Margin values="16px 0">
                    <FlexRow alignCenter>
                        <IconButton
                            icon="CrossWhite"
                            onClick={() => p.mutations.setUnsavedItem(p.mkItem(values(p.mutations.allItems).length))}>
                            {i18n("New additional information")}
                        </IconButton>
                    </FlexRow>
                </Margin>
            )}
        </>
    )
}

const grid = [4, 4, 6, 10]

type AdditionalInformationPayload = Pick<Domain.AdditionalInformation, "title" | "description" | "value">

export const mkAdditionalInfoSchema = () =>
    mkFormSchema<AdditionalInformationPayload>(additionalInformationValidation, {
        title: mkTextareaSchema(i18n("Title")),
        description: mkTextareaSchema(i18n("Description")),
        value: mkTextareaSchema(i18n("Content"))
    })

const mkHeaderRow = (): Row<"header", AdditionalInformationPayload> => ({
    mode: "static",
    type: "header",
    rowId: "header",
    visuals: ["header"],
    noDrag: true,
    grid,
    cells: [
        mkCell(i18n("Title")),
        mkCell(i18n("Filled by")),
        mkCell(i18n("Desciption/subtitle")),
        mkCell(i18n("Content"))
    ]
})

const mkInformationRow = (
    ai: Domain.AdditionalInformation,
    rowOptions: {
        readonly: boolean
        onDelete: F0
        onSubmit: F1<Domain.AdditionalInformation, F1<any>>
        isEdited?: boolean
        onClick?: F0
        onRevert?: F0
        hwFills?: boolean
        onHwFills: F2<boolean, string>
    },
    delta?: Domain.RoundDelta
): Row<"information", AdditionalInformationPayload> => ({
    mode: "editable",
    type: "information",
    formSchema: mkAdditionalInfoSchema(),
    formValue: ai,
    rowId: ai.informationId,
    actionOnBlur: "submit",
    grid,
    visuals: ["noBorder", ...getRowVisualFromDelta(delta)],
    cells: [
        mkCell(ai.title, [], { editMode: "formless", field: "title" }),
        mkCell(
            <HWFillsSelectContainer>
                <Select
                    mode="select"
                    options={[mkDropdownOption(i18n("Contractor"), true), mkDropdownOption(i18n("Architect"), false)]}
                    value={rowOptions.hwFills ?? ai.value === null}
                    onChange={v => rowOptions.onHwFills(v, ai.informationId)}
                    disabled={rowOptions.readonly}
                />
            </HWFillsSelectContainer>
        ),
        mkCell(ai.description, [], { editMode: "formless", field: "description" }),
        mkCell(
            rowOptions.hwFills ?? ai.value === null ? i18n("Contractor fills") : ai.value,
            rowOptions.hwFills ?? ai.value === null ? ["italic"] : [],
            rowOptions.hwFills
                ? { editMode: "custom", editValue: mkCell(i18n("Contractor fills")) }
                : { editMode: "formless", field: "value" }
        )
    ],
    ...rowOptions,
    onSubmit: v => rowOptions.onSubmit(ai)({ ...v, value: rowOptions.hwFills ? null : v.value })
})
