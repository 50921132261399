import * as React from "react"
import styled from "styled-components"
import { FlexRow } from "@smartdevis/ui/src/utils/common"
import { ItemWrapper, renderMaps } from "./formRenderers"
import { rem } from "@smartdevis/ui/src/utils"
import { H4 } from "@smartdevis/ui/src/Typography"
import { StyledFormView, StyledFormViewProps } from "@smartdevis/forms/src/components/StyledFormView"
import { themeColor } from "@smartdevis/ui/src/utils/theme"

const TitleWrapper = styled(H4)`
    margin-bottom: ${rem(22)};
    padding-bottom: ${rem(16)};
    border-bottom: 1px solid ${themeColor("black")};
`

const RowWrapper = styled(FlexRow)`
    & > * {
        margin: 0 ${rem(4)};
    }
    margin: 0 -${rem(4)};
    ${ItemWrapper} {
        flex: 1;
    }
`

const Title: React.FC<{ value: string }> = p => <TitleWrapper>{p.value}</TitleWrapper>

const Row: React.FC<{ value: any }> = p => <RowWrapper alignCenter>{p.value}</RowWrapper>

export const StyledForm: <T extends any, T2 extends any>(p: StyledFormViewProps<T, T2>) => React.ReactElement = p => (
    <StyledFormView {...renderMaps} {...p} styledInputsRenderMap={{ Title, Row, ...p.styledInputsRenderMap }} />
)
