import * as React from "react"
import styled from "styled-components"

import { asyncConnect } from "../../resolvers"
import { generatePdf } from "../../services/pdf"
import { mkContractDoc } from "../../services/doc/docCreators"
import { ContractDocData } from "../../resolvers/docResolvers"
import { Domain } from "@smartdevis/server/src/domain"

export type ContractViewerProps = {
    projectId: string
    devisId: string
    offerId: string
    offerMeta?: Partial<Domain.OfferMeta>
    isDraft?: boolean
}

const Preview = styled.iframe`
    width: 100%;
    height: 100%;
    min-height: 800px;
    border: 0;
`

export const ContractViewer = asyncConnect({ stateResolvers: ["contractDoc"] })<ContractViewerProps>(p => {
    const [url, setUrl] = React.useState("")

    const generatePreview = React.useCallback(
        async (data: ContractDocData) => {
            const blob = await generatePdf(await mkContractDoc(data, "Werkvertrag"), { isDraft: p.isDraft })
            const reader = new FileReader()
            reader.onload = () => setUrl(`${reader.result}`)
            reader.readAsDataURL(blob)
        },
        [setUrl, p.isDraft]
    )

    React.useEffect(() => {
        generatePreview({ ...p.contractDoc, meta: p.offerMeta || p.contractDoc.meta })
    }, [generatePreview, p.contractDoc, p.offerMeta])

    return <Preview src={url} id="pdfPreview" />
})

export const ContractorContractViewer = asyncConnect({
    stateResolvers: ["contractorContractDoc"]
})(p => {
    const [url, setUrl] = React.useState("")

    const generatePreview = React.useCallback(
        async (data: ContractDocData) => {
            const blob = await generatePdf(await mkContractDoc(data, "Werkvertrag"), { isDraft: true })
            const reader = new FileReader()
            reader.onload = () => setUrl(`${reader.result}`)
            reader.readAsDataURL(blob)
        },
        [setUrl, p.isDraft]
    )

    React.useEffect(() => {
        generatePreview(p.contractorContractDoc)
    }, [generatePreview, p.contractorContractDoc])

    return <Preview src={url} id="pdfPreview" />
})
