export const format = (res: string, ...args: string[]) =>
    args
        .reverse()
        .reduce(
            (acc, arg, i) => acc.replace(new RegExp("\\$" + (args.length - i), "g"), `${arg}`),
            res.replace(/({[^"]*})/g, "")
        )

export const capitalize = (s: string) => s.charAt(0).toLocaleUpperCase() + s.slice(1)

export const labelize = (s: string) => capitalize(camelCaseToWords(`${s || ""}`).toLocaleLowerCase())

export const camelCased = (str: string): string => str.replace(/_(.)/g, (_, g) => g.toUpperCase())

export const camelCaseToWords = (str: string): string =>
    str.replace(/([A-Z])([a-z])/g, (_, g, g2) => " " + g + g2).trim()

export const snakeCaseToCapitalized = (str: string): string =>
    capitalize(str.replace(/_(.)/g, (_, g) => ` ${g.toUpperCase()}`))

export const cutString = (v: string, len: number, endString = "..."): string =>
    v.length < len ? v : v.slice(0, len - endString.length) + endString

export const normalizeEmail = (email: string) => email.replace(/\./g, "==")
export const parseEmail = (emailId: string) => emailId.replace(/\==/g, ".")

export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i
export const getEmailDomain = (email: string) => email.split("@")[1]

export const formatBlanks = (v: string) => v.replace(/\s+/g, " ")
