import _isEqual from "lodash/isEqual"
import { F0, F1 } from "./types"

export type Option<T = string> = { label: string; value: T }
export const toOption = <T, _>(label: string, value: T): Option<T> => ({ label, value })

export type Serializable = string | number | boolean | null | { [property: string]: Serializable } | Serializable[]
export const serialize = (value: Serializable) => JSON.stringify(value)

export type Writable<T> = { -readonly [P in keyof T]: T[P] }
export const writable = <T>(value: T): Writable<T> => value

export const isEqual = <T extends any>(a: T, b: T) => _isEqual(a, b)
export const isDefined = <T>(i: T | undefined | null): i is T => Boolean(i)

export const identity = <T>(i: T): T => i

export const _noop = (): any => undefined
export const _anything: any = {}
export const _nocall = (): any => {
    throw Error("Should not be called")
}

export function range(from: number, to: number): number[]
export function range(to: number): number[]
export function range(n1: number, n2?: number) {
    return n2 !== undefined ? Array.from(new Array(n2), (_, i) => i + n1) : Array.from(new Array(n1), (_, i) => i)
}
export const repeat = <T>(times: number, what: T): T[] => range(times).map(() => what)

export const partialMatchPattern: <T extends string>(
    test: T
) => <R>(cases: { [key in T]?: F1<key, R> }, defaultFn: F0<R>) => R = matchPattern

export function matchPattern<T extends string>(test: T): <R>(cases: { [key in T]: F1<key, R> }) => R
export function matchPattern<T extends string>(
    test: T | undefined
): <R>(cases: { [key in T]?: F1<key, R> }, defaultFn: F0<R>) => R
export function matchPattern<T extends string>(test?: T) {
    return <R>(cases: { [key in T]?: F1<key, R> }, defaultFn?: F0<R>): R => {
        if (!test || !cases[test])
            if (defaultFn) return defaultFn()
            else throw new Error("Impossible state")
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return cases[test]!(test)
    }
}
