import { FileType, toFileName } from "@smartdevis/server/src/utils/fs"
import { formatDateTime } from "@smartdevis/utils/src/date"

const appendTime = (v: string) => `${v} ${formatDateTime(new Date())}`.replace(/:/g, "꞉")

export const downloadBlob = (name: string, fileType: FileType) => (blob: Blob) => {
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)

    link.setAttribute("href", url)
    link.setAttribute("download", toFileName(appendTime(name), fileType))
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
