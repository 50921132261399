import * as React from "react"
import { RouteParams } from "../../paths"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect } from "../../resolvers"
import { Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { DevisWrapper } from "./Devis"
import { Row, Table } from "../../components/table/Table"
import { mkCell } from "../../components/table/TableCell"
import { Domain } from "@smartdevis/server/src/domain"
import { IconButton } from "@smartdevis/ui/src/Button"
import { useCloudAction } from "../../hooks/useCloudAction"
import { ContractorModal } from "../../components/modals/ContractorModal"
import { useNotifications } from "../NotificationsProvider"
import { SubContent } from "../../components/layouts/Content"
import { hasContract, hasSecondRound } from "@smartdevis/client/src/utils/devisHelpers"
import { useArrayState } from "@smartdevis/ui/src/hooks/common"
import { PageHeader } from "../../components/layouts/Header"
import { ExportSubmitters } from "../../components/export/ExportButtons"
import { remap, values, filterObject } from "@smartdevis/utils/src/map"
import { identity, isEqual } from "@smartdevis/utils/src/misc"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"

const mkContractorRow = (c: Domain.Contractor): Row<"contractor"> => ({
    type: "contractor",
    grid: [6, 6, 12],
    mode: "static",
    rowId: c.contractorId,
    cells: [mkCell(c.companyName ?? ""), mkCell(`${c.name} ${c.surname}`), mkCell(c.email)]
})

export const DevisSubmittersView = asyncConnect({
    stateResolvers: ["results", "devisCollections", "projectDetails", "user", "devis", "contractors"],
    actions: ["mutate"]
})<RouteParams>(p => {
    const [manageContractorsVisible, setManageContractorsVisible] = React.useState(false)

    const { pushNotification } = useNotifications()
    const { items, setItems } = useArrayState(p.devis.contractorIds)

    const devisMutation = prepareMutation("devis", p.mutate, {
        type: "devis",
        projectId: p.devis.projectId,
        devisId: p.devis.devisId
    })

    const { onSubmit, actionState } = useCloudAction<string[]>(
        (actionId, contractorIds) => {
            devisMutation.update(p.devis, actionId)({ contractorIds })
        },
        p.results,
        () => {
            setManageContractorsVisible(false)
            pushNotification(i18n("Submitters updated"))
        }
    )
    const headerRow: Row<"header"> = {
        type: "header",
        grid: [6, 6, 12],
        mode: "static",
        rowId: "header",
        visuals: ["header"],
        cells: [mkCell(i18n("Company name")), mkCell(i18n("Name")), mkCell(i18n("Email"))]
    }

    const requests = filterObject(p.devisCollections.requests, (_, r) => r.devisId === p.devis.devisId)
    const requestsBySubmitter = React.useMemo(() => remap(requests, (_, r) => r.submitterId, identity), [requests])
    const isReadonly =
        hasSecondRound(p.devisCollections, p.devis.devisId) || hasContract(p.devisCollections, p.devis.devisId)
    return (
        <>
            <DevisWrapper current="submitters" {...p}>
                <SubContent>
                    <PageHeader
                        title={i18n("Submitters")}
                        subTitle="Erfassen Sie alle Submittenten, die in diesem Devis zur Erstellung einer Offerte eingeladen werden sollen."
                        actionButtons={[
                            <ExportSubmitters
                                key="export"
                                projectId={p.projectDetails.projectId}
                                devisId={p.devis.devisId}
                            />
                        ]}
                    />
                    <Margin values="8px 0px 16px 0px">
                        {p.devis.contractorIds.length ? (
                            <Table<"contractor" | "header">
                                rows={[
                                    headerRow,
                                    ...values(p.contractors)
                                        .filter(c => p.devis.contractorIds.includes(c.contractorId))
                                        .map(mkContractorRow)
                                ]}
                                rowHierarchy={["contractor"]}
                            />
                        ) : null}
                        <VerticalSpace base="16px" />
                        {!isReadonly && (
                            <IconButton icon="CrossWhite" onClick={() => setManageContractorsVisible(true)}>
                                {i18n("Manage submitters")}
                            </IconButton>
                        )}
                    </Margin>
                </SubContent>
            </DevisWrapper>
            <ContractorModal
                disabledIds={items.filter(s => requestsBySubmitter[s])}
                setSelectedIds={setItems}
                selectedIds={items}
                level="devis"
                loading={actionState.type === "Processing"}
                onClose={() => {
                    if (!isEqual(p.devis.contractorIds, items)) onSubmit(items)
                    else setManageContractorsVisible(false)
                }}
                visible={manageContractorsVisible}
            />
        </>
    )
})
