import * as React from "react"

export const Revert: React.FC = p => (
    <svg width="14" height="14" viewBox="-1 -1 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
        <path
            d="M7 14C6.88444 13.9985 6.77403 13.9519 6.69231 13.8702C6.61059 13.7885 6.56401 13.6781 6.5625 13.5625C6.5625 13.3216 6.75908 13.125 7 13.125H7.00408C10.3792 13.125 13.1267 10.3787 13.1291 7.0035C13.1308 3.626 10.3851 0.87675 7.00758 0.875C5.84746 0.871902 4.71047 1.19948 3.72983 1.81935C2.74918 2.43923 1.9655 3.32573 1.47058 4.375H4.8125C4.92853 4.375 5.03981 4.42109 5.12186 4.50314C5.20391 4.58519 5.25 4.69647 5.25 4.8125C5.25 4.92853 5.20391 5.03981 5.12186 5.12186C5.03981 5.20391 4.92853 5.25 4.8125 5.25H0.4375C0.321468 5.25 0.210188 5.20391 0.128141 5.12186C0.0460936 5.03981 0 4.92853 0 4.8125L0 0.4375C0 0.321468 0.0460936 0.210188 0.128141 0.128141C0.210188 0.0460936 0.321468 0 0.4375 0C0.553532 0 0.664812 0.0460936 0.746859 0.128141C0.828906 0.210188 0.875 0.321468 0.875 0.4375V3.62308C2.093 1.42275 4.44733 0 7.00408 0C10.8681 0.00233333 14.0064 3.14417 14.0041 7.00408C14.0017 10.8617 10.8617 14 7.00408 14H7Z"
            fill="currentColor"
        />
    </svg>
)
