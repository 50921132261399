import * as React from "react"
import styled from "styled-components"

export const statusTagColors = {
    black: { background: "#ffffff", text: "#000000" },
    yellow: { background: "#fffdf5", text: "#ffcc00" },
    green: { background: "#fcfffb", text: "#6abb45" },
    red: { background: "#fff3f3", text: "#e94b4e" },
    blue: { background: "#f5feff", text: "#2cb7ca" },
    grey: { background: "#e2e2e2", text: "#485156" }
}

export type StatusTagColor = keyof typeof statusTagColors

export const StatusTag: React.FC<{ color: StatusTagColor }> = p => (
    <Wrapper background={statusTagColors[p.color].background} text={statusTagColors[p.color].text}>
        {p.children}
    </Wrapper>
)

const Wrapper = styled.div<{ background: string; text: string }>`
    background-color: ${p => p.background};
    color: ${p => p.text};
    border-color: ${p => p.text};
    line-height: 24px;
    font-size: 12px;
    height: auto;
    padding: 0 7px;
    white-space: nowrap;
    display: inline-block;
    border: 1px solid;
    border-radius: 4px;
`
