import { F0 } from "@smartdevis/utils/src/types"

export const getClientWidth = () =>
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

export const getClientHeight = () =>
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

export const hasSpaceHorizontally = (direction: "left" | "right", rect?: DOMRect, length = 120) => {
    if (!rect) return true
    if (direction === "left") return rect.right - length > 0
    else return rect.left + length < getClientWidth()
}

export const hasSpaceVertically = (direction: "up" | "down", rect?: DOMRect, length = 40) => {
    if (!rect) return true
    if (direction === "up") return rect.top - length > 0
    else return rect.bottom + length < getClientHeight()
}

export const getOppositeHorizontalDirection = (dir: "right" | "left") => (dir === "right" ? "left" : "right")
export const getOppositeVerticalDirection = (dir: "up" | "down") => (dir === "up" ? "down" : "up")

export const noPropagationCallback = (cb: F0) => (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    cb()
}

export const selectCreateDiv = (id: string) => {
    let d = document.getElementById(id)
    if (d) return d
    d = document.createElement("div")
    document.body.appendChild(d)
    d.setAttribute("id", id)
    return d
}

export const getOnScreenPosition = (ref: HTMLElement | null): DOMRect | undefined => {
    if (!ref) return undefined
    return ref.getBoundingClientRect()
}

export const scrollToY = (y: number, anchor?: HTMLElement, scrollDuration = 1000) => {
    const base = anchor || window
    const getPos = () => {
        if (anchor) return anchor.scrollTop
        return window.scrollY
    }
    const from = getPos()
    const to = y
    const distance = to - from
    let timestamp = 0
    let progress = 0
    const ease = (p: number) => (p < 0.5 ? 2 * p * p : 1 + 16 * --p * p * p * p * p)

    function step(newTimestamp: number) {
        if (!timestamp) timestamp = newTimestamp
        progress = (newTimestamp - timestamp) / scrollDuration
        if (progress > 1 || getPos() === to) return
        base.scrollTo(0, Math.round(from + distance * ease(progress)))
        window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
}

export function selectText(id: string) {
    const el = document.getElementById(id)
    if (!el) return
    if (window.getSelection) {
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(el)
        selection?.removeAllRanges()
        selection?.addRange(range)
    } else {
        // eslint-disable-next-line no-console
        console.warn("Could not select text in node: Unsupported browser.")
    }
}
