import * as React from "react"
import { F0 } from "@smartdevis/utils/src/types"
import { useDebounce } from "./useDebounce"
import { isEqual } from "@smartdevis/utils/src/misc"

export const useAnimationToggle = (duration = 100) => {
    const [animated, setAnimated] = React.useState(false)
    const animate = React.useCallback(() => {
        setAnimated(true)
        setTimeout(() => setAnimated(false), duration)
    }, [setAnimated, duration])
    return { animated, animate }
}

export const useArrayState = <T extends any>(initial: T[] = []) => {
    const [items, setItemsRaw] = React.useState(initial)
    return {
        items,
        setItems: setItemsRaw,
        toggle: (v: T) => setItemsRaw(vs => (vs.includes(v) ? vs.filter(value => value !== v) : vs.concat(v)))
    }
}

export const useDeepEffect = <T extends any>(v: T, onChanged: F0) => {
    const previousDependencies = React.useRef<T | null>(null)

    React.useEffect(() => {
        if (!previousDependencies.current) {
            previousDependencies.current = v
            return
        }
        if (isEqual(v, previousDependencies.current)) return
        previousDependencies.current = v
        onChanged()
    }, [v])
}

export const useRenderedEffect = (...args: Parameters<typeof React.useEffect>) => {
    const isFirstRender = React.useRef(true)
    React.useEffect(() => {
        if (!isFirstRender.current) args[0]()
    }, args[1])
    React.useEffect(() => {
        isFirstRender.current = false
    })
}
export const useHovered = (initial = false) => {
    const [hovered, setHovered] = React.useState(initial)
    return {
        hoverListeners: {
            onMouseEnter: () => setHovered(true),
            onPointerEnter: () => setHovered(true),
            onMouseLeave: () => setHovered(false),
            onPointerLeave: () => setHovered(false),
            onMouseOver: () => setHovered(true),
            onMouseOut: () => setHovered(false)
        },
        hovered
    }
}

export const useHostEvents = (cb: F0, wait = 100) => {
    const handleEvent = useDebounce(cb, wait)

    React.useEffect(() => {
        window.addEventListener("scroll", handleEvent.run)
        window.addEventListener("resize", handleEvent.run)
        window.addEventListener("orientationchange", handleEvent.run)
        return () => {
            window.removeEventListener("scroll", handleEvent.run)
            window.removeEventListener("resize", handleEvent.run)
            window.removeEventListener("orientationchange", handleEvent.run)
            handleEvent.cancel()
        }
    }, [handleEvent])
}

export const useNonInputScrollEvent = (cb: F0) => {
    React.useEffect(() => {
        const mycb = (e: Event) => {
            // console.log(e)
            if ((e.target as HTMLElement).tagName !== "INPUT") cb()
        }
        window.addEventListener("scroll", mycb, true)
        return () => {
            window.removeEventListener("scroll", mycb, true)
        }
    }, [cb])
}

export const useGlobalClick = (onClick: F0) => {
    React.useEffect(() => {
        const body = document.querySelector("body")
        if (!body) return
        const handler = () => onClick()
        body.addEventListener("click", handler)
        return () => body.removeEventListener("click", handler)
    }, [onClick])
}
