import * as React from "react"
import styled from "styled-components"
import { EventPayloads } from "@smartdevis/server/src/cloudEvents"
import { actionValidations } from "@smartdevis/server/src/validations"
import { mkFormSchema, mkTextSchema, mkHiddenSchema, mkMultiselectSchema } from "../components/forms/formSchemas"
import { asyncConnect } from "../resolvers"
import { i18n } from "../services/translations"
import { StyledFormSchema, useFormHook } from "@smartdevis/forms/src"
import { ErrorLabel, H2, P } from "@smartdevis/ui/src/Typography"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { SubmitButton } from "../components"
import { StyledForm } from "../components/forms"
import { ErrorView } from "./Error"
import { useCloudAction } from "../hooks/useCloudAction"
import { versionToNumber } from "@smartdevis/server/src/models/changelog"
import { version } from "../version"
import { AppLayout } from "../components/layouts/AppLayout"
import { Domain } from "@smartdevis/server/src/domain"
import { KeycloakUser } from "../store/authState"
import { omitObject } from "@smartdevis/utils/src/map"
import { useAuth } from "../views/AuthProvider"

type RegistrationFormPayload = Omit<EventPayloads["register"], "type" | "userId" | "email" | "agreedOn">

const getStyledSchema = (userType: Domain.UserType): StyledFormSchema<RegistrationFormPayload> => {
    if (userType === "contractor") {
        return [
            "companyName",
            { type: "Row", value: ["name", "surname"] },
            "phoneNumber",
            "street",
            { type: "Row", value: ["postalCode", "city"] },
            // "cantons",
            // "workCategory",
            "isListed"
        ]
    }
    return [
        "companyName",
        { type: "Row", value: ["name", "surname"] },
        "phoneNumber",
        "street",
        { type: "Row", value: ["postalCode", "city"] }
    ]
}
const getSchema = (userType: Domain.UserType) => {
    if (userType === "contractor")
        return mkFormSchema<RegistrationFormPayload>(actionValidations.register, {
            companyName: mkTextSchema("Company name"),
            name: mkTextSchema("Name"),
            surname: mkTextSchema("Surname"),
            phoneNumber: mkTextSchema("Phone number"),
            city: mkTextSchema("City"),
            postalCode: mkTextSchema("Postal Code"),
            street: mkTextSchema("Street"),
            // TODO: to be changed when contractor directory is live
            // cantons: mkMultiselectSchema(
            //     "Cantons",
            //     [
            //         ["Zurich", "Zurich"],
            //         ["Bern", "Bern"]
            //     ],
            //     { placeholder: i18n("Please select canton") }
            // ),
            // workCategory: mkMultiselectSchema("Work category", [], {
            //     creatable: true,
            //     placeholder: i18n("Please add your work categories")
            // }),
            cantons: mkHiddenSchema(""),
            workCategory: mkHiddenSchema(""),
            isListed: mkHiddenSchema(""), // TODO: to be changed when contractor directory is live
            // isListed: mkRadioSchema("Contractor directory listing", [
            //     ["I want to be listed", true],
            //     ["I don't want to be listed", false]
            // ]) as any, // TODO: fix types
            lastVisitVersion: mkHiddenSchema("")
        })
    else
        return mkFormSchema<RegistrationFormPayload>(actionValidations.register, {
            companyName: mkTextSchema("Company name"),
            name: mkTextSchema("Name"),
            surname: mkTextSchema("Surname"),
            phoneNumber: mkTextSchema("Phone number"),
            city: mkTextSchema("City"),
            postalCode: mkTextSchema("Postal Code"),
            street: mkTextSchema("Street"),
            cantons: mkHiddenSchema(""),
            workCategory: mkHiddenSchema(""),
            lastVisitVersion: mkHiddenSchema(""),
            isListed: mkHiddenSchema("")
        })
}

const getInitialValue = (auth: KeycloakUser, publicOffer?: Domain.PublicOffer): Partial<RegistrationFormPayload> => {
    if (!publicOffer || !(auth.type === "CONTRACTOR" || auth.type === "contractor")) return {}
    return omitObject(publicOffer.contractor, ["email"])
}

export const RegisterView = asyncConnect({
    stateResolvers: ["auth", "results", "optionalPublicOffer"],
    actions: ["register", "setupSignupRole"]
})(p => {
    if (p.auth.type !== "Authenticated") return <ErrorView type="access-denied" />

    const { actionState, onSubmit } = useCloudAction(
        actionId =>
            result.type === "Ok" && p.auth.type === "Authenticated"
                ? p.register({
                      ...result.value,
                      email: p.auth.value.email,
                      type: p.auth.value.type.toLowerCase() as Domain.UserType,
                      agreedOn: "1",
                      lastVisitVersion: versionToNumber(version),
                      actionId
                  })
                : null,
        p.results,
        () => {
            p.setupSignupRole()
        }
    )
    const { formViewProps, handleSubmit, submitted, result } = useFormHook({
        schema: getSchema(p.auth.value.type.toLowerCase() as Domain.UserType),
        onSubmit,
        initialValue: getInitialValue(p.auth.value, p.optionalPublicOffer)
    })
    const submitDisabled = (result.type === "Err" && !submitted) || actionState.type === "Processing"
    const submitCopy = actionState.type !== "Processing" ? i18n("Save") : i18n("Saving")
    return (
        <AppLayout>
            <FormWrapper>
                <form onSubmit={handleSubmit}>
                    <H2>{i18n("Complete the creation of your account")}</H2>
                    <P>{i18n("Complete your data to be able to use Smart Devis without restrictions.")}</P>

                    <VerticalSpace base="8px" />
                    <StyledForm
                        {...formViewProps}
                        styledSchema={getStyledSchema(p.auth.value.type.toLowerCase() as Domain.UserType)}
                    />
                    <SubmitButton disabled={submitDisabled} loading={actionState.type === "Processing"}>
                        {submitCopy}
                    </SubmitButton>
                    {actionState.type !== "Err" ? null : <ErrorLabel>{i18n(actionState.value)}</ErrorLabel>}
                    <VerticalSpace base="8px" />
                </form>
            </FormWrapper>
        </AppLayout>
    )
})

export const FormWrapper = styled.div`
    height: 628px;
    .input-wrapper {
        min-width: 150px;
    }
`
