import * as React from "react"
import styled from "styled-components"
import { i18n } from "../../services/translations"
import { rem } from "@smartdevis/ui/src/utils"
import { SMap, values } from "@smartdevis/utils/src/map"
import { F0 } from "@smartdevis/utils/src/types"
import { Asset } from "@smartdevis/ui/src/Asset"
import { H4, Label } from "@smartdevis/ui/src/Typography"
import { FlexColumn, FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { TileLinkWrapper } from "@smartdevis/ui/src/Tiles"
import { themeColor } from "@smartdevis/ui/src/utils/theme"

const DEFAULT_MAX_VISIBLE_ITEMS = 3
const TILE_HEIGHT = rem(180)
const TILE_WIDTH = rem(300)
const TILE_MIN_WIDTH = "250px"

export type ProjectTileProps = {
    title: string
    addedItems: SMap<string>[]
    displayItems: boolean
    hasFixedSize: boolean
    maxVisibleItems?: number
    onClick: F0
}

export const ProjectTile: React.FC<ProjectTileProps> = ({ maxVisibleItems = DEFAULT_MAX_VISIBLE_ITEMS, ...p }) => {
    const hasAddedItems = p.addedItems.length > 0
    const Icon = (
        <Asset name={hasAddedItems ? "Edit" : "Plus"} color={hasAddedItems ? "grey70" : "action"} size="icon" />
    )

    return (
        <TileLinkWrapper
            style={{
                width: p.hasFixedSize ? TILE_WIDTH : "100%",
                minWidth: p.hasFixedSize ? "auto" : TILE_MIN_WIDTH,
                height: p.displayItems ? "auto" : TILE_HEIGHT,
                minHeight: p.displayItems ? TILE_HEIGHT : "auto"
            }}
            clickable
            onClick={p.onClick}>
            <FlexColumn spaceBetween style={{ height: "100%", width: "100%" }}>
                <FlexRow spaceBetween alignCenter>
                    <H4 style={{ maxWidth: "90%" }}>{p.title}</H4>
                    <IconContainer justifyCenter alignCenter>
                        <IconWrapper justifyCenter alignCenter>
                            {hasAddedItems ? (
                                <Asset name="Check" color="black" size="small-icon" />
                            ) : (
                                <IconPlaceholder />
                            )}
                        </IconWrapper>
                    </IconContainer>
                </FlexRow>
                <FlexRow spaceBetween alignCenter>
                    {p.displayItems ? (
                        <FlexColumn style={{ width: "100%", marginTop: "8px" }}>
                            <FlexRow>
                                <FlexColumn style={{ width: "100%" }}>
                                    <Join items={p.addedItems.slice(0, maxVisibleItems)}>
                                        {(item, index) => (
                                            <FlexRow spaceBetween alignCenter key={index}>
                                                <Join
                                                    items={values(item)}
                                                    renderJoining={() => <HorizontalSpace base="64px" />}>
                                                    {element => <HoverableLabel>{element}</HoverableLabel>}
                                                </Join>
                                            </FlexRow>
                                        )}
                                    </Join>
                                </FlexColumn>
                                <HorizontalSpace base="64px" />
                            </FlexRow>
                            <FlexRow spaceBetween alignCenter>
                                <HoverableLabel color={hasAddedItems ? "grey70" : "action"}>
                                    {hasAddedItems
                                        ? p.addedItems.length > maxVisibleItems
                                            ? `+ ${p.addedItems.length - maxVisibleItems} ${i18n("more")}`
                                            : ""
                                        : i18n("Add $1", p.title)}
                                </HoverableLabel>
                                {Icon}
                            </FlexRow>
                        </FlexColumn>
                    ) : (
                        <>
                            <HoverableLabel color={hasAddedItems ? "grey70" : "action"}>
                                {hasAddedItems ? `${p.addedItems.length} ${i18n("applied")}` : i18n("Add $1", p.title)}
                            </HoverableLabel>
                            {Icon}
                        </>
                    )}
                </FlexRow>
            </FlexColumn>
        </TileLinkWrapper>
    )
}

const HoverableLabel = styled(Label)`
    cursor: pointer;
`

const IconContainer = styled(FlexRow)`
    width: 20px;
    height: 20px;
`

const IconPlaceholder = styled.div`
    width: 14px;
    height: 14px;
`

const IconWrapper = styled(FlexRow)`
    padding: 6px;
    border-radius: 50%;
    background-color: ${themeColor("grey35")};
`
