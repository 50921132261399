import { remap, pickObject } from "@smartdevis/utils/src/map"
import { CloudEventName, CloudEventPayload, cloudEvents } from "@smartdevis/server/src/cloudEvents"
import { Action, EventAction, EventActionMeta } from "@smartdevis/utils/src/actions"

const isAction = (a: any): a is Action => a.type
export const isCloudAction = (a: any): a is EventAction =>
    isAction(a) &&
    (a as any).meta &&
    typeof (a as any).meta.actionId === "string" &&
    (a as any).meta.actionId.length > 0

type OPayload<P> = P extends EventActionMeta ? Omit<P, keyof EventActionMeta> : P

export const flattenCloudAction =
    <A extends string, P>(creator: (p: OPayload<P>) => (actionId: string) => EventAction<A, OPayload<P>>) =>
    ({ actionId, ...p }: P & EventActionMeta) =>
        creator(p as any)(actionId)

export const pickCloudActions = <TNames extends CloudEventName>(
    keys: TNames[]
): {
    [TName in TNames]: (p: CloudEventPayload<TName> & EventActionMeta) => EventAction<TName, CloudEventPayload<TName>>
} =>
    remap(
        pickObject(cloudEvents, keys),
        k => k,
        v => flattenCloudAction(v as any)
    ) as any
