import { Domain } from "../domain"
import { mkLatestOfferAttachments, mkLatestOfferInformation, mkLatestOfferValues } from "../utils/offer"
import { calculateDevis, sumPositionsPrices } from "../utils/money"
import { applyDeltas, ApplyDeltasOptions } from "../utils/deltas"
import { sortSortables, sortCreatable } from "@smartdevis/utils/src/comparable"
import { SMap, filterObject, values, remap, keys, mapObject, groupBy, toMap } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { F1 } from "@smartdevis/utils/src/types"

export const mkConditions = (
    data: Pick<Domain.DevisCollections, "conditions">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    if (deltasByRef) return sortSortables(values(applyDeltas("conditions", data.conditions, deltasByRef, deltaOptions)))

    return sortSortables(values(data.conditions))
}

export const mkGeneralInformation = (data: Pick<Domain.DevisCollections, "generalInformation">) => {
    return sortSortables(values(data.generalInformation))
}

export const mkPositionSections = (
    data: Pick<Domain.DevisCollections, "sections">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    const valuesToSort = values(
        deltasByRef ? applyDeltas("sections", data.sections, deltasByRef, deltaOptions) : data.sections
    ).filter(s => s.type === "position")
    return sortSortables(valuesToSort.filter(s => !s.parentId))
}

export const mkAllDevisAttachments = (
    attachments: SMap<Domain.Attachment>,
    data: Pick<Domain.DevisCollections, "positions" | "conditions">,
    offer: Pick<Domain.ArchitectOfferRequest, "offerAttachments" | "roundOfferAttachments">,
    devisId: string,
    offerId?: string
) => {
    const offerAttachments = mkLatestOfferAttachments(offer)
    const positionIds = keys(data.positions).filter(pid => data.positions[pid].devisId === devisId)
    const conditionIds = keys(data.conditions).filter(cid => data.conditions[cid].devisId === devisId)
    return values({ ...attachments, ...offerAttachments }).filter(a => {
        if ((a.type === "devis" || a.type === "general" || a.type === "final") && a.refId === devisId) return true
        if (a.type === "position" && positionIds.includes(a.refId)) return true
        if (a.type === "condition" && conditionIds.includes(a.refId)) return true
        if (a.type === "offer" && a.refId === offerId) return true
        return false
    })
}

export const mkPositionSubsections = (
    data: Pick<Domain.DevisCollections, "sections">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    return values(deltasByRef ? applyDeltas("sections", data.sections, deltasByRef, deltaOptions) : data.sections)
        .filter(s => s.type === "position")
        .filter(s => !!s.parentId)
}

export const mkDeductionSections = (
    data: Pick<Domain.DevisCollections, "sections">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    const valuesToSort = values(
        deltasByRef ? applyDeltas("sections", data.sections, deltasByRef, deltaOptions) : data.sections
    ).filter(s => s.type === "deduction")
    return sortSortables(valuesToSort)
}

export const mkLastRound = (p: Pick<Domain.DevisCollections, "rounds">): Domain.Round | undefined =>
    values(p.rounds).sort(sortCreatable("asc"))[0]

export const mkDeltasByRef = (
    p: Pick<Domain.DevisCollections, "rounds" | "deltas">
): SMap<Domain.RoundDelta> | undefined => {
    const lastRound = mkLastRound(p)
    if (!lastRound) return undefined
    return remap(
        filterObject(p.deltas, (_, d) => d.roundId === lastRound.roundId),
        (_, d) => d.refId,
        identity
    )
}

export const mkRequestDeltasByRef = ({
    state,
    deltas,
    roundOffers = {}
}: Pick<Domain.ContractorOffer, "deltas" | "state" | "roundOffers">): SMap<Domain.RoundDelta> | undefined => {
    if (!deltas) return undefined
    const lastRoundId =
        state.type === "next-round" || state.type === "round-submitted" ? state.roundId : keys(roundOffers).sort()[0]
    return remap(
        filterObject(deltas, (_, d) => d.roundId === lastRoundId),
        (_, d) => d.refId,
        identity
    )
}

export const mkPositions = (
    data: Pick<Domain.DevisCollections, "positions" | "sections">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    const sections = values(
        deltasByRef ? applyDeltas("sections", data.sections, deltasByRef, deltaOptions) : data.sections
    ).filter(s => s.type === "position")

    const sectionIds = sections.map(s => s.sectionId)

    const positions = deltasByRef ? applyDeltas("positions", data.positions, deltasByRef, deltaOptions) : data.positions
    return filterObject(positions, (_, p) => sectionIds.includes(p.sectionId))
}

export const mkRequestsBySubmitter = (
    data: Pick<Domain.DevisCollections, "requests">,
    predicate: F1<Domain.ArchitectOfferRequest, boolean> = () => true
) => {
    const requests = filterObject(data.requests, (_, r) => predicate(r))
    return remap(requests, (_, r) => r.submitterId, identity)
}

export const mkDeductions = (
    data: Pick<Domain.DevisCollections, "deductions" | "sections">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    const sections = mkDeductionSections(data, deltasByRef, deltaOptions)
    const sectionIds = sections.map(s => s.sectionId)
    const deductions = deltasByRef
        ? applyDeltas("deductions", data.deductions, deltasByRef, deltaOptions)
        : data.deductions
    return filterObject(deductions, (_, p) => sectionIds.includes(p.sectionId))
}

export const mkDevisAdditionalInformation = (
    data: Pick<Domain.DevisCollections, "additionalInformation">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    if (deltasByRef)
        return sortSortables(
            values(applyDeltas("additionalInformation", data.additionalInformation, deltasByRef, deltaOptions))
        )
    return sortSortables(values(data.additionalInformation))
}

export const mkSublevels = (
    data: Pick<Domain.DevisCollections, "positions" | "sections">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
): SMap<Domain.Sublevel[]> => {
    const positions = mkPositions(data, deltasByRef, deltaOptions)
    const sections = mkPositionSubsections(data, deltasByRef, deltaOptions)

    const results: SMap<Domain.Sublevel[]> = {}
    values(positions)
        .map(value => ({ basedOn: "position" as const, ...value }))
        .forEach(sublevel => {
            if (!results[sublevel.sectionId]) results[sublevel.sectionId] = []
            results[sublevel.sectionId].push(sublevel)
        })
    sections
        .map(value => ({ basedOn: "section" as const, ...value }))
        .forEach(sublevel => {
            if (!sublevel.parentId) return
            if (!results[sublevel.parentId]) results[sublevel.parentId] = []
            results[sublevel.parentId].push(sublevel)
        })
    return mapObject(results, (_, val) => sortSortables(val))
}

export const filterPERSublevels = (base: SMap<Domain.Sublevel[]>): SMap<Domain.Sublevel[]> => {
    const result: SMap<Domain.Sublevel[]> = {}
    keys(base).forEach(k => {
        result[k] = base[k].filter(sl => sl.basedOn === "section" || sl.type !== "per")
    })
    return result
}

export const mkDeductionsBySection = (
    data: Pick<Domain.DevisCollections, "deductions" | "sections">,
    deltasByRef?: SMap<Domain.RoundDelta>,
    deltaOptions: ApplyDeltasOptions = { keepDeleted: true }
) => {
    const deductions = mkDeductions(data, deltasByRef, deltaOptions)
    return mapObject(
        groupBy(values(deductions), v => v.sectionId, identity),
        (_, val) => sortSortables(val)
    ) as SMap<Domain.Deduction[]>
}

export const mkContractors = (
    data: Domain.DevisCollections,
    contractors: SMap<Domain.Contractor>,
    offersIds: string[]
) => {
    const requests = offersIds.map(id => data.requests[id])
    const cs = requests.map(r => contractors[r.submitterId])
    return cs
}

export const mkOfferValues = (data: Domain.DevisCollections, offersIds: string[]) => {
    const requests = offersIds.map(id => data.requests[id])
    return toMap(requests, r => r.submitterId, mkLatestOfferValues)
}
export const mkOfferInformation = (data: Domain.DevisCollections, offersIds: string[]) => {
    const requests = offersIds.map(id => data.requests[id])
    return toMap(requests, r => r.submitterId, mkLatestOfferInformation)
}

export const mkCalculations = (data: Domain.DevisCollections, submitters: Domain.Contractor[], offersIds: string[]) => {
    const deltas = mkDeltasByRef(data)
    const dBySection = mkDeductionsBySection(data, deltas, { keepDeleted: false })
    const dSections = mkDeductionSections(data, deltas, { keepDeleted: false })
    const ov = mkOfferValues(data, offersIds)
    const oi = mkOfferInformation(data, offersIds)
    const ps = mkPositions(data, deltas, { keepDeleted: false })
    return toMap(
        submitters,
        s => s.contractorId,
        s =>
            calculateDevis(
                dSections,
                dBySection,
                ov[s.contractorId],
                oi[s.contractorId],
                sumPositionsPrices(ps, ov[s.contractorId])
            )
    )
}
