import * as React from "react"
import styled from "styled-components"
import { mkCell, Cell } from "../../components/table/TableCell"
import { FormRow, EditableRow, TableRowDecorators, StaticTableRow } from "../../components/table/TableRow"
import { useDebounce } from "@smartdevis/ui/src/hooks/useDebounce"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { useNotifications } from "../NotificationsProvider"
import { H2, H3 } from "@smartdevis/ui/src/Typography"
import { FormSchema } from "@smartdevis/forms/src"
import { SMap, toMap, keys, omitObject } from "@smartdevis/utils/src/map"
import { identity, range } from "@smartdevis/utils/src/misc"
import { Result, isOk } from "@smartdevis/utils/src/result"
import { validString, validNumber } from "@smartdevis/utils/src/validators"

const Container = styled.div`
    max-width: 800px;
`
const DemoSection: React.FC<{ title: string }> = p => (
    <>
        <VerticalSpace base="20px" />
        <H3>{p.title}</H3>
        <VerticalSpace base="10px" />
        {p.children}
    </>
)

type User = { name: string; email: string; age: number }
const getCellsForUser = (u: User): Cell<User>[] => [
    mkCell(u.name, [], { editMode: "formless", field: "name" }),
    mkCell(u.email, [], { editMode: "formless", field: "email" }),
    mkCell(u.age, [], { editMode: "formless", field: "age" })
]
const usersHeaderRow: Cell[] = [mkCell("Name"), mkCell("Email"), mkCell("age", ["alignRight"])]
const userMikolaj = { name: "Mikolaj", email: "mikolaj.speichert@tooploox.com", age: 22 }
const userTomek = { name: "Tomek", email: "tomasz.kalinowski@tooploox.com", age: 27 }
const userGrzegorz = { name: "Grzegorz", email: "grzegorz.moskal@tooploox.com", age: 80 }
const users = [userMikolaj, userTomek, userGrzegorz]
const totalAge = users.reduce((age, user) => age + user.age, 0)

const userSchema: FormSchema<User> = {
    name: { type: "text", name: "Name", validators: validString },
    email: { type: "text", name: "Email", validators: validString },
    age: { type: "number", name: "Age", validators: validNumber }
}

const BasicTableExample: React.FC<Pick<TableRowDecorators, "grid">> = p => (
    <>
        <StaticTableRow rowId="header" grid={p.grid} cells={usersHeaderRow} visuals={["header"]} />
        {users.map(user => (
            <StaticTableRow rowId={user.email} grid={p.grid} key={user.name} cells={getCellsForUser(user)} />
        ))}
    </>
)

const TitleTableExample: React.FC = () => (
    <>
        <StaticTableRow rowId="header" cells={usersHeaderRow} visuals={["header"]} />
        <StaticTableRow
            rowId="total"
            grid={[18, 6]}
            cells={[mkCell("Total value", ["title"]), mkCell(totalAge, ["title"])]}
        />
        {users.map(user => (
            <StaticTableRow rowId={user.email} key={user.name} cells={getCellsForUser(user)} />
        ))}
    </>
)

const ColoredTableExample: React.FC = () => (
    <>
        <StaticTableRow rowId="header" cells={usersHeaderRow} visuals={["header"]} />
        {users.map(user => (
            <StaticTableRow
                rowId={user.email}
                key={user.name}
                cells={[mkCell(user.name), mkCell(user.email), mkCell(user.age, user.age < 25 ? ["green"] : ["red"])]}
            />
        ))}
    </>
)

const PartiallyEditableTable: React.FC = () => {
    const { pushNotification } = useNotifications()

    const onChangeItem = useDebounce(
        (u: Result<User>) => isOk(u) && pushNotification(`New age of ${u.value.name}: ${u.value.age}`),
        500
    )

    return (
        <>
            <StaticTableRow rowId="header" cells={usersHeaderRow} visuals={["header"]} />
            {users.map(user => (
                <FormRow
                    rowId={user.email}
                    key={user.name}
                    cells={[mkCell<User>(user.name), mkCell<User>(user.email), getCellsForUser(user)[2]]}
                    formSchema={userSchema}
                    formValue={user}
                    onChange={onChangeItem.run}
                />
            ))}
        </>
    )
}

const EditableTable: React.FC = () => {
    const { pushNotification } = useNotifications()

    const onChangeBigItem = useDebounce(
        (i: Result<User>) => isOk(i) && pushNotification(`${i.value.name} ${i.value.email} ${i.value.age}`),
        500
    )
    return (
        <>
            <StaticTableRow rowId="header" cells={usersHeaderRow} visuals={["header"]} />
            {users.map(user => (
                <FormRow
                    rowId={user.email}
                    key={user.name}
                    cells={getCellsForUser(user)}
                    formSchema={userSchema}
                    formValue={user}
                    onChange={onChangeBigItem.run}
                />
            ))}
        </>
    )
}
const EditableVeryfiableTable = () => {
    const getSchema = (id: string): FormSchema<User> => ({ ...userSchema, name: { ...userSchema.name, id } })

    const [editableRows, setRows] = React.useState<SMap<User>>(toMap(users, u => u.name, identity))

    const [editedRowKey, setEditedRowKey] = React.useState<string | null>(null)

    return (
        <>
            <StaticTableRow rowId="header" cells={usersHeaderRow} visuals={["header"]} />
            {keys(editableRows).map((key, i) => (
                <EditableRow
                    rowId={key}
                    key={key}
                    cells={getCellsForUser(editableRows[key])}
                    formSchema={getSchema(key)}
                    formValue={editableRows[key]}
                    actionOnBlur="submit"
                    actionOnEnter="submit"
                    isEdited={editedRowKey ? key === editedRowKey : undefined}
                    onPressEnter={() => {
                        if (!keys(editableRows)[i + 1]) return
                        setEditedRowKey(keys(editableRows)[i + 1])
                        setTimeout(() => document.getElementById(keys(editableRows)[i + 1])?.focus(), 10)
                    }}
                    onEditToggle={isEdited => !isEdited && editedRowKey === key && setEditedRowKey(null)}
                    onSubmit={v => setRows(rs => ({ ...rs, [key]: v }))}
                    onDelete={() => setRows(rs => omitObject(rs, [key]))}
                />
            ))}
        </>
    )
}

export const BasicTable: React.FC = () => {
    return (
        <Container>
            <H2>Basic tables</H2>

            <DemoSection title="Simplest table">
                <BasicTableExample />
            </DemoSection>

            <DemoSection title="Unequal columns table">
                <BasicTableExample grid={[7, 13, 4]} />
            </DemoSection>

            <DemoSection title="Sectioned table">
                <TitleTableExample />
            </DemoSection>

            <DemoSection title="Colored table">
                <ColoredTableExample />
            </DemoSection>
        </Container>
    )
}

export const EditableTables: React.FC = () => {
    return (
        <Container>
            <H2>Editable Tables</H2>

            <DemoSection title="Partially editable table">
                <PartiallyEditableTable />
            </DemoSection>

            <DemoSection title="Editable table">
                <EditableTable />
            </DemoSection>

            <DemoSection title="Excel-ish table">
                <EditableVeryfiableTable />
            </DemoSection>
        </Container>
    )
}

export const ManyTables = () => (
    <Container>
        <H2>Big tables</H2>

        <DemoSection title="Big table">
            <>
                <StaticTableRow rowId="header" cells={usersHeaderRow} visuals={["header", "sticky"]} />
                {range(1000).map(i =>
                    i % 10 ? (
                        <StaticTableRow rowId={i.toString()} key={i} cells={getCellsForUser(users[i % 3])} />
                    ) : (
                        <StaticTableRow
                            key={i}
                            rowId={i.toString()}
                            grid={[18, 6]}
                            cells={[mkCell("Title", ["title"]), mkCell("Title 2", ["title", "alignRight"])]}
                        />
                    )
                )}
            </>
        </DemoSection>
    </Container>
)
