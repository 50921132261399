import { EventPayloads } from "@smartdevis/server/src/cloudEvents"
import { Domain } from "@smartdevis/server/src/domain"
import { UploadingFile } from "@smartdevis/ui/src/Upload"
import { createAction } from "@smartdevis/utils/src/actions"
import { filterObject, SMap } from "@smartdevis/utils/src/map"
import {
    AsyncAttachment,
    AsyncNewAttachment,
    createAttachmentCmd,
    removeAttachmentCmd,
    updateOfferFileCmd,
    updateDevisCmd,
    updateUserCmd,
    updatePartnerCmd,
    addContractorCmd,
    editContractorCmd
} from "./attachmentsCmds"
import { mkReducer } from "./utils"

import { ContractorOperationPayload } from "../components/modals/ContractorOperationModal"

export type AttachmentsState = {
    uploadingFiles: SMap<AsyncAttachment>
}

export const initialState: AttachmentsState = {
    uploadingFiles: {}
}

export type UpdateDevisPayload = {
    file: UploadingFile
    devisId: string
    projectId: string
    userId: string
    actionId: string
}

export type UpdateOfferPayload = {
    file: UploadingFile
    offerId: string
    userId: string
    actionId: string
}

export type UploadSource = "template" | "project" | undefined

export const actions = {
    _setUploadingFiles: (p: AsyncAttachment) => createAction("_setUploadingFiles", p),

    endUploading: (attachmentsIds: string[]) => createAction("endUploading", { attachmentsIds }),
    uploadFile: (file: AsyncNewAttachment, userId: string, projectId: string, source?: UploadSource) =>
        createAction("uploadFile", { file, projectId, source, userId }),
    removeAttachment: (file: Domain.Attachment, projectId: string, soruce?: UploadSource) =>
        createAction("removeAttachment", { file, projectId, soruce }),
    mutateOfferWithUpload: (p: UpdateOfferPayload) => createAction("mutateOfferWithUpload", p),
    mutateDevisWithUpload: (p: UpdateDevisPayload) => createAction("mutateDevisWithUpload", p),
    mutateUserWithUpload: (p: EventPayloads["updateUser"] & { logo: File | null; actionId: string }) =>
        createAction("mutateUserWithUpload", p),
    mutatePartnerWithUpload: (p: EventPayloads["mutatePartners"] & { logo: File | null; actionId: string }) =>
        createAction("mutatePartnerWithUpload", p),
    addContractorWithUpload: (p: ContractorOperationPayload & { logoUrl: File | null; logoLink: string }) =>
        createAction("addContractorWithUpload", p),
    editContractorWithUpload: (
        p: ContractorOperationPayload & { logoUrl: File | null; logoLink: string } & { id: string | undefined }
    ) => createAction("editContractorWithUpload", p)
}

export const reducer = mkReducer<AttachmentsState>((state, action, context) => {
    if (context.auth.type !== "Authenticated") return

    switch (action.type) {
        case "_setUploadingFiles":
            return {
                uploadingFiles: { ...state.uploadingFiles, [action.payload.attachment.attachmentId]: action.payload }
            }

        case "uploadFile": {
            const { file, projectId, source, userId } = action.payload
            return [
                { uploadingFiles: { ...state.uploadingFiles, [file.attachment.attachmentId]: file } },
                createAttachmentCmd(file, projectId, userId, source)
            ]
        }
        case "endUploading": {
            return {
                uploadingFiles: filterObject(state.uploadingFiles, id => !action.payload.attachmentsIds.includes(id))
            }
        }
        case "mutateOfferWithUpload":
            return updateOfferFileCmd(action.payload)
        case "mutateDevisWithUpload":
            return updateDevisCmd(action.payload)

        case "mutateUserWithUpload":
            return updateUserCmd(action.payload)

        case "mutatePartnerWithUpload":
            return updatePartnerCmd(action.payload)

        case "removeAttachment": {
            const { file, projectId, soruce } = action.payload
            return removeAttachmentCmd(file, projectId, soruce)
        }

        case "addContractorWithUpload":
            return addContractorCmd(action.payload)

        case "editContractorWithUpload":
            return editContractorCmd(action.payload)
    }
})
