import { values, SMap, keys } from "@smartdevis/utils/src/map"
import { ValueOf } from "@smartdevis/utils/src/types"
import { parse, unparse } from "papaparse"

export const MIME = "text/csv;charset=utf-8"

export const template = (body: string) => `data:${MIME},${body}`

export type Exportable = SMap<string | number | boolean | null | undefined>

const formatCell = (value: ValueOf<Exportable>) => (value !== "" ? JSON.stringify(value) : value)

export const fromCollection = <T extends Exportable>(col: T[]) =>
    col.length
        ? [keys<Exportable>(col[0]).map(formatCell), ...col.map(values).map(row => row.map(formatCell))]
              .map(row => row.join())
              .join("\n")
        : ""

export const toBlob = (content: string) => new Blob([content], { type: `${MIME};` })

export type CSVParseResultData = string[]

export const readCSVFile = async (file: File): Promise<CSVParseResultData[]> =>
    new Promise(resolve =>
        parse<CSVParseResultData>(file, {
            worker: true,
            complete: result => resolve(result.data)
        })
    )

export const generateCSVFile = async (fileContent: CSVParseResultData[]) =>
    new Promise<Blob>(resolve => resolve(toBlob(unparse(fileContent))))
