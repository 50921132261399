import { Cmd, RunCmd } from "redux-loop"
import { clientActions, ClientActions, getStore } from "../store"
import { CloudEventName, CloudEvent } from "@smartdevis/server/src/cloudEvents"
import { getStoreLanguage } from "../services/translations"
import { callCloudAction } from "../services/server"
import { SMap } from "@smartdevis/utils/src/map"
import { F1, F2 } from "@smartdevis/utils/src/types"
import { Action, EventResult } from "@smartdevis/utils/src/actions"
import { Dispatch } from "./store"

export type CmdCreator = ReturnType<typeof Cmd.list | typeof Cmd.run>
export type Opt = Pick<RunCmd<Action>, "successActionCreator" | "failActionCreator">

type ExtOpt<T, T2 = any> = {
    successActionCreator: F1<T, Array<Action>>
    failActionCreator: F1<T2, Array<Action>>
}

export const extRun = <T, T2>(cb: () => Promise<T>, options: ExtOpt<T, T2>) =>
    Cmd.run(async () => {
        const { dispatch } = getStore()
        try {
            const v = await cb()
            options.successActionCreator(v).map(dispatch)
        } catch (err) {
            options.failActionCreator(err).map(dispatch)
        }
    })

type Func = (...args: any) => any

const _cacheDebounceIds: SMap<ReturnType<typeof setTimeout>> = {}
export const cacheDebounce =
    <F extends Func>(id: string, f: F, ms = 500) =>
    (...args: Parameters<F>) => {
        if (_cacheDebounceIds[id]) {
            clearTimeout(_cacheDebounceIds[id])
            _cacheDebounceIds[id] = undefined as any
        }
        _cacheDebounceIds[id] = setTimeout(() => f(...args), ms)
    }

const DEBOUNCE_THRESHOLD = 1000
export const debouncedDispatch = (dispatch: Dispatch, actions: Action[]) => {
    actions.forEach(a => cacheDebounce(JSON.stringify(a), dispatch, DEBOUNCE_THRESHOLD)(a))
}

export const cloudCmd = <N extends CloudEventName>(
    token: string,
    action: CloudEvent<N>,
    cb: F2<CloudEvent<N>, EventResult, ClientActions[]> = () => [],
    nonDebouncable: string[] = []
) =>
    Cmd.run(async () => {
        const { dispatch } = getStore()
        action.meta.lang = getStoreLanguage()
        const result = await callCloudAction(token, action)
        const reactionsToDispatch = cb(action, result)
        debouncedDispatch(
            dispatch,
            reactionsToDispatch.filter(t => !nonDebouncable.includes(t.type))
        )
        reactionsToDispatch.filter(t => nonDebouncable.includes(t.type)).map(dispatch)
        dispatch(clientActions._setResult(result))
    })

export const fmap =
    <TRes>(f: F1<TRes, any>) =>
    <T2>(transform: F1<T2, Array<TRes>>) =>
    (res: T2) =>
        transform(res).forEach(f)
