import * as React from "react"

export const Download: React.FC = p => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
        <g stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round">
            <path d="M8.33333 15.3332C12.3834 15.3332 15.6667 12.0499 15.6667 7.99984C15.6667 3.94975 12.3834 0.666504 8.33333 0.666504C4.28324 0.666504 1 3.94975 1 7.99984C1 12.0499 4.28324 15.3332 8.33333 15.3332Z" />
            <path d="M8.33301 11.3332V4.6665" />
            <path d="M10.9993 9.6665L8.33268 11.6665L5.66602 9.6665" />
        </g>
    </svg>
)
