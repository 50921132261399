import * as React from "react"
import { Domain } from "@smartdevis/server/src/domain"
import { getFullName } from "@smartdevis/server/src/models/user"
import { BaseModalProps, ConfirmationModal } from "@smartdevis/ui/src/Modal"
import { i18n } from "@smartdevis/client/src/services/translations"
import { Label, Pre } from "@smartdevis/ui/src/Typography"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { TextareaInput } from "@smartdevis/ui/src/Inputs"
import { RadioButton } from "@smartdevis/ui/src/Button"
import { asyncConnect } from "../../../resolvers"
import { useExportNegotiation } from "./DevisNegotiation.helpers"

const ExportComment = asyncConnect({ stateResolvers: "negotiationDoc" })<
    BaseModalProps & { submitters: Domain.Contractor[]; offersIds: string[] }
>(p => {
    const [message, setMessage] = React.useState("")
    const [chosenSubmitterId, setChosenSubmitterId] = React.useState<string | null>(null)
    const { handleExport } = useExportNegotiation(p)
    return (
        <>
            <ConfirmationModal
                onSubmit={() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    handleExport(chosenSubmitterId!, message)
                    p.onClose?.()
                }}
                submitButtonProps={{ disabled: chosenSubmitterId === null }}
                onClose={p.onClose}
                header={i18n("Export to PDF")}
                visible={p.visible}
                submitText={i18n("Export")}
                cancelText={i18n("Cancel")}>
                <Label>
                    {i18n("Select contractor you want to highlight in the document as a preferable to work with:")}
                </Label>
                <VerticalSpace base="8px" />
                <Pre>
                    {p.submitters.map(s => (
                        <RadioButton
                            label={s.companyName || getFullName(s)}
                            onChange={() => setChosenSubmitterId(s.contractorId)}
                            checked={chosenSubmitterId === s.contractorId}
                            key={s.contractorId}
                        />
                    ))}
                </Pre>
                <VerticalSpace base="8px" />
                <Label htmlFor="message">{i18n("Leave comment in the document (optional):")}</Label>
                <VerticalSpace base="8px" />
                <TextareaInput id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} />
            </ConfirmationModal>
        </>
    )
})

export const NegotiationModals = {
    ExportComment
}
