import * as React from "react"
import * as ReactDOM from "react-dom"
import { selectCreateDiv } from "./utils/dom"

export const Portal: React.FC = ({ children }) => {
    const [container] = React.useState(document.createElement("div"))

    React.useLayoutEffect(() => {
        selectCreateDiv("portal-root").appendChild(container)
        return () => {
            selectCreateDiv("portal-root").removeChild(container)
        }
    }, [container])

    return ReactDOM.createPortal(children, container)
}
