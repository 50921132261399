export const formatDate = (ts: number) => {
    const d = new Date(ts)
    return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(c => `${c}`.padStart(2, "0")).join(".")
}

const makeTwoPlaces = (n: number) => (n < 10 ? `0${n}` : n)

export const formatDateISO = (ts: number) => {
    const d = new Date(ts)
    return `${makeTwoPlaces(d.getDate())}.${makeTwoPlaces(d.getMonth() + 1)}.${d.getFullYear()}`
}

const zeroPad = (val: number | string, maxLength = 2) => `${val}`.padStart(maxLength, "0")

export const formatDateTime = (d: Date) =>
    [
        `${d.getFullYear()}-${zeroPad(d.getMonth() + 1)}-${zeroPad(d.getDate())}`,
        `${zeroPad(d.getHours())}:${zeroPad(d.getMinutes())}`
    ].join(" ")
