import * as React from "react"
import styled from "styled-components"
import { applyRowVisual, getInheritableVisuals, RowVisual } from "./TableRow.styles"
import {
    StaticRowProps,
    EditableRowProps,
    FormRowProps,
    StaticTableRow,
    EditableRow,
    FormRow,
    TableRowDecorators
} from "./TableRow"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { useHovered } from "@smartdevis/ui/src/hooks/common"
import { TMap, pickObject } from "@smartdevis/utils/src/map"

export type StaticRow = Row & { mode: "static" }
export type Row<T = string, O = any> = {
    type: T
    rowId: string

    visuals?: RowVisual[]
    grid?: number[]
    noDrag?: boolean

    children?: Row[]
} & (
    | ({ mode: "static" } & StaticRowProps)
    | ({ mode: "editable" } & EditableRowProps<O>)
    | ({ mode: "form" } & FormRowProps<O>)
    | ({ mode: "custom"; component: React.ReactElement } & TableRowDecorators)
)

type TableRowCallbackParams = {
    hovered: boolean
    rowId: string
}

type CallbackRender = (params: TableRowCallbackParams) => React.ReactNode

type TableProps<T extends string> = {
    rowHierarchy?: T[] //top to bottom
    rows: Row<T>[]

    draggable?: boolean
    onDrag?: (type: T, index: number, ids: { parentId: string | null; draggedId: string }) => void

    renderAfterRowMap?: Partial<TMap<T, CallbackRender>>
}

export const getCoreRow = <T, O>(row: Row<T, O>): React.ReactElement => {
    switch (row.mode) {
        case "static":
            return <StaticTableRow key={row.rowId} {...row} />

        case "editable":
            return <EditableRow key={row.rowId} {...row} />

        case "form":
            return <FormRow key={row.rowId} {...row} />

        case "custom":
            return row.component
    }
}

const DraggableContainer: React.FC<{ index: number; id: string; draggable?: boolean }> = p =>
    p.draggable ? (
        <Draggable key={p.id} draggableId={p.id} index={p.index}>
            {provided => (
                <DragHelperZone ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {p.children}
                </DragHelperZone>
            )}
        </Draggable>
    ) : (
        <>{p.children}</>
    )

const DroppableContainer: React.FC<{ id: string; type: string; draggable?: boolean }> = p =>
    p.draggable ? (
        <Droppable droppableId={p.id} type={p.type} direction="vertical">
            {provided => (
                <DragHelperZone ref={provided.innerRef} {...provided.droppableProps}>
                    {p.children}
                    {provided.placeholder}
                </DragHelperZone>
            )}
        </Droppable>
    ) : (
        <>{p.children}</>
    )

const DropWrapper: React.FC<{ draggable?: boolean; rowId: string; rowType?: string }> = p =>
    p.rowType ? (
        <DroppableContainer draggable={p.draggable} id={p.rowId} type={p.rowType}>
            {p.children}
        </DroppableContainer>
    ) : (
        <>{p.children}</>
    )

const mapRowsToRender = <T extends string>(
    { rows, ...rest }: Pick<TableProps<T>, "rows" | "draggable" | "renderAfterRowMap" | "rowHierarchy">,
    visualsToInherit: RowVisual[] = []
) => {
    let skipIndexCounter = 0
    return rows.map((row, i) => {
        if (row.noDrag) skipIndexCounter++
        return (
            <RowRenderer
                key={row.rowId}
                row={{ ...row, visuals: (row.visuals ?? []).concat(visualsToInherit) }}
                index={row.noDrag ? -1 : i - skipIndexCounter}
                {...rest}
            />
        )
    })
}

const RowRenderer = <T extends string, O>(
    p: {
        row: Row<T, O>
        index: number
    } & Pick<TableProps<T>, "renderAfterRowMap" | "draggable" | "rowHierarchy">
): React.ReactElement => {
    const { hovered, hoverListeners: listeners } = useHovered()
    const children = mapRowsToRender(
        {
            ...pickObject(p, ["draggable", "renderAfterRowMap", "rowHierarchy"]),
            rows: (p.row.children as Row<T>[]) || []
        },
        getInheritableVisuals(p.row.visuals)
    )

    const childrenTypeIndex = p.rowHierarchy?.indexOf(p.row.type) ?? -1
    const childrenType = childrenTypeIndex === -1 ? undefined : p.rowHierarchy?.[childrenTypeIndex + 1]
    const renderAfter = p.renderAfterRowMap?.[p.row.type]
    return (
        <RowContainer visuals={p.row.visuals} {...listeners}>
            <DraggableContainer id={p.row.rowId} index={p.index} draggable={p.draggable && !p.row.noDrag}>
                {getCoreRow({ ...p.row, hovered })}
                <DropWrapper draggable={p.draggable && !p.row.noDrag} rowId={p.row.rowId} rowType={childrenType}>
                    {children ?? null}
                </DropWrapper>
                {renderAfter ? renderAfter({ hovered, rowId: p.row.rowId }) : null}
            </DraggableContainer>
        </RowContainer>
    )
}

export const Table = <T extends string>(p: TableProps<T>): React.ReactElement => {
    const dragCallback = (res: DropResult) => {
        if (!res.destination) return
        const {
            draggableId: draggedId,
            destination: { droppableId: parentId }
        } = res
        p.onDrag?.(res.type as T, res.destination.index, {
            parentId: parentId === "table" ? null : parentId,
            draggedId
        })
    }
    const rows = mapRowsToRender(pickObject(p, ["rows", "draggable", "renderAfterRowMap", "rowHierarchy"]))
    return p.draggable ? (
        <DragDropContext onDragEnd={dragCallback}>
            <DroppableContainer draggable id="table" type={p.rowHierarchy?.[0] ?? "top"}>
                {rows}
            </DroppableContainer>
        </DragDropContext>
    ) : (
        <>{rows}</>
    )
}

const DragHelperZone = styled.div`
    min-height: 1px;
`
const RowContainer = styled.div<{ visuals?: RowVisual[] }>`
    ${applyRowVisual("hoverable")}
`
