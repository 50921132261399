import * as React from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import { SubmitButton } from "../../components"
import { asyncConnect } from "../../resolvers"
import * as vservice from "../../version"
import { FlexItem, FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { StyledForm } from "../../components/forms"
import { Utils } from "@smartdevis/server/src/domain"
import { dateFormat } from "@smartdevis/server/src/constants"
import { mkFormSchema, mkTextareaSchema } from "../../components/forms/formSchemas"
import { useCloudAction } from "../../hooks/useCloudAction"
import { EventPayloads } from "@smartdevis/server/src/cloudEvents"
import { i18n } from "../../services/translations"
import { AdminContent } from "./Dashboard"
import { InputWrapper } from "../../components/forms/formRenderers"
import { H3, H4, Label, P } from "@smartdevis/ui/src/Typography"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { Button, IconButton } from "@smartdevis/ui/src/Button"
import { DatePicker } from "@smartdevis/ui/src/DatePicker"
import { isValidVersion, versionToNumber } from "@smartdevis/server/src/models/changelog"
import { Input } from "@smartdevis/ui/src/Inputs"
import { useNotifications } from "../NotificationsProvider"
import { useFormHook } from "@smartdevis/forms/src"
import { TMap, keys } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { validString } from "@smartdevis/utils/src/validators"

export const ChangelogDisplay = styled.div`
    white-space: pre-wrap;
`

type ContentMap = TMap<Utils.Language, string>

export const ChangelogDashboard = asyncConnect({
    stateResolvers: ["changelog", "results", "language"],
    actions: "mutateChangelog"
})(p => {
    const [version, setVersion] = React.useState(versionToNumber(vservice.version))
    const [inputValue, setInputValue] = React.useState("")
    const { pushNotification } = useNotifications()
    const changelogExists = isDefined(p.changelog[version])

    const [versionTs, setVersionTs] = React.useState(p.changelog[version]?.versionTs || 0)

    const { onSubmit, actionState } = useCloudAction<EventPayloads["mutateChangelog"]>(
        (actionId, payload) => p.mutateChangelog({ actionId, ...payload }),
        p.results
    )

    const { formViewProps, handleSubmit, resetState } = useFormHook({
        schema: mkFormSchema<ContentMap>(
            { en: validString, de: validString },
            { en: mkTextareaSchema("EN"), de: mkTextareaSchema("DE") }
        ),
        initialValue: p.changelog[version]?.contentMap || {},
        onSubmit: contentMap => {
            changelogExists
                ? onSubmit({ ...p.changelog[version], contentMap, versionTs, mutationType: "update" })
                : onSubmit({ contentMap, versionTs, mutationType: "create", version })
        }
    })

    React.useEffect(() => {
        resetState()
    }, [version])

    return (
        <>
            <AdminContent
                subTitle={i18n("Changelog editor")}
                actionButtons={[
                    <P key="version">{i18n("Version:")}</P>,
                    <Input key="input-version" value={inputValue} onChange={e => setInputValue(e.target.value)} />,
                    <IconButton
                        key="go"
                        icon="ArrowRight"
                        onClick={() =>
                            isValidVersion(inputValue)
                                ? setVersion(versionToNumber(inputValue))
                                : pushNotification("Invalid version")
                        }
                    />
                ]}>
                <form onSubmit={handleSubmit}>
                    <StyledForm {...formViewProps} styledSchema={["en", "de"]} />
                    <InputWrapper>
                        <Label htmlFor="version-ts">{i18n("Version date")}</Label>
                        <DatePicker
                            id="version-ts"
                            value={versionTs ? versionTs : undefined}
                            placeholder={i18n("Select date")}
                            onChange={setVersionTs}
                        />
                    </InputWrapper>
                    <VerticalSpace base="16px" />
                    <FlexItem direction="row" yAlign="center">
                        <SubmitButton loading={actionState.type === "Processing"}>
                            {changelogExists ? i18n("Update") : i18n("Create")}
                        </SubmitButton>
                        <HorizontalSpace base="16px" />
                        {changelogExists && (
                            <Button
                                onClick={() => onSubmit({ ...p.changelog[version], mutationType: "remove" })}
                                loading={actionState.type === "Processing"}>
                                {i18n("Remove")}
                            </Button>
                        )}
                    </FlexItem>
                </form>
                <VerticalSpace base="32px" />
                <H3>{i18n("All changelogs (change app language in order to view other languages):")}</H3>
                <VerticalSpace base="16px" />
                <ChangelogDisplay>
                    <Join
                        items={keys(p.changelog).sort().reverse()}
                        renderJoining={() => <VerticalSpace base="32px" />}>
                        {cv => {
                            const { en, de } = p.changelog[cv].contentMap
                            const isCurrent = parseInt(cv as any, 10) === version
                            return (
                                <React.Fragment key={cv}>
                                    <FlexRow alignCenter>
                                        <H4 style={{ fontWeight: isCurrent ? "bold" : "normal" }}>{cv}</H4>
                                        {" - "}
                                        <P>{dayjs(p.changelog[cv].versionTs).format(dateFormat)}</P>
                                        <HorizontalSpace base="16px" />
                                        {!isCurrent && (
                                            <Button
                                                btnType="link"
                                                onClick={() =>
                                                    formViewProps.setState({
                                                        de: { value: de, active: false, visited: true },
                                                        en: { value: en, active: false, visited: true }
                                                    })
                                                }>
                                                {i18n("Copy to current")}
                                            </Button>
                                        )}
                                    </FlexRow>
                                    <VerticalSpace base="8px" />
                                    <P>{p.changelog[cv].contentMap[p.language]}</P>
                                </React.Fragment>
                            )
                        }}
                    </Join>
                </ChangelogDisplay>
            </AdminContent>
        </>
    )
})
