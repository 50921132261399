import * as React from "react"
import { IconButton } from "@smartdevis/ui/src/Button"
import { i18n } from "@smartdevis/client/src/services/translations"
import { FlexRow, HorizontalSpace, VerticalSpace, Padding, Margin } from "@smartdevis/ui/src/utils/common"
import { mkPositionRowBuilder, mkPostionHeaderRow } from "../devis-positions/DevisPosition.helpers"
import { Table } from "../../../components/table/Table"
import { getSublevelIndex, mkDevisPositionsRows, MutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import { calculateOrder } from "@smartdevis/utils/src/comparable"
import { SMap, omitObject } from "@smartdevis/utils/src/map"
import { F1 } from "@smartdevis/utils/src/types"
import { asyncConnect } from "../../../resolvers"
import { mkSectionRow } from "../DevisSection"
import { AttachmentsManagement, AttachmentsModal, mkAttachmentCell } from "../../../components/Attachments"
import { last } from "@smartdevis/utils/src/array"
import { Domain } from "@smartdevis/server/src/domain"

export const PositionsList: React.FC<{
    readonly: boolean
    predefinedReadonly: boolean
    onAddPosition: (sectionId: string, delta?: Partial<Domain.Position>) => () => void
    onAddSubsection: (delta?: Partial<Domain.Section>) => () => void
    onAddSection: (delta?: Partial<Domain.Section>) => () => void
    sortedSections: Domain.Section[]
    sortedSublevels: SMap<Domain.Sublevel[]>
    devis: Pick<Domain.Devis, "devisId">
    sm: MutationManagement<"sections", Domain.Section>
    subsm: MutationManagement<"sections", Domain.Section>
    pm: MutationManagement<"positions", Domain.Position>
    pushNotification: F1<string, void>
    deltas: SMap<Domain.RoundDelta> | undefined
    attachments: AttachmentsManagement
}> = asyncConnect({ stateResolvers: [], actions: [] })(p => {
    const [attachmentModalPositionId, setAttachmentPositionId] = React.useState<string | undefined>(undefined)

    const mkPositionRow = React.useCallback(
        mkPositionRowBuilder(
            (pos: Domain.Position) =>
                mkAttachmentCell(
                    p.attachments.getItemsForRef(pos.positionId).length,
                    () => setAttachmentPositionId(pos.positionId),
                    false
                ),
            { readonly: p.readonly, predefinedReadonly: p?.predefinedReadonly, isUnsaved: p.pm.isUnsaved },
            {
                onPositionRemove: p.pm.onItemRemove,
                onPositionSubmit: p.pm.onItemSubmit,
                onDeltaRevert: p.pm.onRevertDelta
            }
        ),
        [p.pm.allItems, p.sm.allItems, p.readonly, p.predefinedReadonly]
    )

    const rows = mkDevisPositionsRows(
        p.sortedSections,
        p.sortedSublevels,
        (type, s, children) =>
            mkSectionRow(
                omitObject(s, ["basedOn"]),
                {
                    type,
                    readonly: p.readonly,
                    predefinedReadonly: p?.predefinedReadonly,
                    onSubmit: (s.parentId ? p.subsm : p.sm).onItemSubmit(s),
                    onDelete: (s.parentId ? p.subsm : p.sm).onItemRemove(s),
                    onRevert: (s.parentId ? p.subsm : p.sm).onRevertDelta?.(p.deltas?.[s.sectionId]?.deltaId),
                    children
                },
                { withNumber: true, withDragIcon: true },
                p.deltas?.[s.sectionId]
            ),
        pos => mkPositionRow(omitObject(pos, ["basedOn"]), p.deltas?.[pos.positionId])
    )

    return (
        <>
            <Table<"section" | "sublevel" | "lastlevel" | "header">
                rows={[mkPostionHeaderRow(p.readonly), ...rows]}
                rowHierarchy={["section", "sublevel", "lastlevel"]}
                draggable={!p.readonly}
                renderAfterRowMap={{
                    section: ({ rowId, hovered }) => {
                        if (!hovered || p.readonly || p.sm.isUnsaved(rowId)) return <VerticalSpace base="40px" />
                        const rowSubsections =
                            p.sortedSublevels[rowId]?.filter(
                                (s): s is Domain.SectionSublevel => s.basedOn === "section"
                            ) ?? []

                        const lastSubsectionNumber = rowSubsections.reduce(
                            (acc, s) => (parseInt(s.number ?? "0", 10) > acc ? parseInt(s.number ?? "0", 10) : acc),
                            0
                        )

                        const lastNumber = Math.max(rowSubsections.length, lastSubsectionNumber)
                        const lastOrder = Math.max(rowSubsections.length, (last(rowSubsections)?.order ?? 0) + 1)
                        return (
                            <Padding values="4px 16px 12px">
                                <FlexRow alignCenter>
                                    <IconButton
                                        onClick={p.onAddSubsection({
                                            name: i18n("Subsection $1", lastNumber + 1),
                                            number: `${lastNumber + 1}`,
                                            parentId: rowId,
                                            order: lastOrder
                                        })}
                                        icon="CrossWhite"
                                        btnType="secondary">
                                        {i18n("New subsection")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    <IconButton onClick={p.onAddPosition(rowId)} icon="CrossWhite">
                                        {i18n("New free text position")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    <IconButton
                                        onClick={p.onAddPosition(rowId, { type: "per", amount: 0 })}
                                        icon="CrossWhite">
                                        {i18n("New PER position")}
                                    </IconButton>
                                </FlexRow>
                            </Padding>
                        )
                    },
                    sublevel: ({ rowId, hovered }) => {
                        if (p.pm.allItems[rowId]) return // that means its not a section
                        if (!hovered || p.readonly || p.subsm.isUnsaved(rowId)) return <VerticalSpace base="32px" />
                        return (
                            <Padding values="4px 16px 4px">
                                <FlexRow alignCenter>
                                    <HorizontalSpace base="32px" />
                                    <IconButton onClick={p.onAddPosition(rowId)} icon="CrossWhite">
                                        {i18n("New free text position")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    <IconButton
                                        onClick={p.onAddPosition(rowId, { type: "per", amount: 0 })}
                                        icon="CrossWhite">
                                        {i18n("New PER position")}
                                    </IconButton>
                                </FlexRow>
                            </Padding>
                        )
                    }
                }}
                onDrag={(type, index, ids) => {
                    switch (type) {
                        case "lastlevel":
                        case "sublevel": {
                            if (!ids.parentId) return
                            if (p.pm.allItems[ids.parentId])
                                return p.pushNotification(i18n("This position can only be moved to a subsection"))
                            const sls = p.sortedSublevels[ids.parentId]

                            const order = calculateOrder(sls, getSublevelIndex(sls, ids.draggedId), index)
                            if (p.subsm.allItems[ids.draggedId])
                                p.subsm.onItemSubmit(p.subsm.allItems[ids.draggedId])({
                                    order,
                                    parentId: ids.parentId
                                })
                            else p.pm.onItemSubmit(p.pm.allItems[ids.draggedId])({ sectionId: ids.parentId, order })
                            break
                        }
                        case "section": {
                            const order = calculateOrder(
                                p.sortedSections,
                                p.sortedSections.indexOf(p.sm.allItems[ids.draggedId]),
                                index
                            )
                            p.sm.onItemSubmit(p.sm.allItems[ids.draggedId])({ order })
                        }
                    }
                }}
            />
            <Margin values="46px 0">
                {p.readonly ? null : (
                    <IconButton
                        icon="CrossWhite"
                        data-cy="positions-add-section"
                        btnType="secondary"
                        onClick={() => {
                            const lastSubsectionNumber = p.sortedSections.reduce(
                                (acc, s) => (parseInt(s.number ?? "0", 10) > acc ? parseInt(s.number ?? "0", 10) : acc),
                                0
                            )

                            const lastNumber = Math.max(p.sortedSections.length, lastSubsectionNumber)
                            const lastOrder = Math.max(
                                p.sortedSections.length,
                                (last(p.sortedSections)?.order ?? 0) + 1
                            )
                            p.onAddSection({
                                name: i18n("SECTION $1", lastNumber + 1),
                                number: `${lastNumber + 1}`,
                                order: lastOrder
                            })()
                        }}>
                        {i18n("New section title")}
                    </IconButton>
                )}
            </Margin>
            <AttachmentsModal
                visible={!!attachmentModalPositionId}
                isReadonly={p.readonly}
                refId={attachmentModalPositionId}
                onClose={() => setAttachmentPositionId(undefined)}
                {...p.attachments}
            />
        </>
    )
})
