import * as React from "react"
import styled from "styled-components"
import { ArchitectContent, SubContent } from "../../components/layouts/Content"
import { asyncConnect } from "../../resolvers"
import { Flex, FlexColumn, FlexRow, GridContainer, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { i18n } from "@smartdevis/client/src/services/translations"
import { PageHeader } from "../../components/layouts/Header"
import { keys, values } from "@smartdevis/utils/src/map"
import { intersection, joinArrays } from "@smartdevis/utils/src/array"
import { Select } from "@smartdevis/ui/src/Selects"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { ExternalLink, P } from "@smartdevis/ui/src/Typography"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { Domain } from "@smartdevis/server/src/domain"
import { displayCompanyName } from "@smartdevis/server/src/models/projectDetails"
import { getFullName } from "@smartdevis/server/src/models/user"
import { ExternalAsset } from "@smartdevis/ui/src/Asset"
import { sortSortables } from "@smartdevis/utils/src/comparable"

export const PartnersView = asyncConnect({
    stateResolvers: ["results", "partners"],
    actions: []
})(p => {
    const allGroups = values(p.partners).reduce<string[]>((groups, partner) => joinArrays(groups, partner.groups), [])
    const allCategories = values(p.partners).reduce<string[]>(
        (categories, partner) => joinArrays(categories, partner.categories),
        []
    )
    const [selectedGroups, setSelectedGroups] = React.useState<string[]>([])
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([])

    return (
        <>
            <ArchitectContent>
                <SubContent>
                    <PageHeader
                        title={i18n("Partners")}
                        subTitle={i18n(
                            "Thanks to the support of ConReal Swiss AG and these partner companies, the use of Smart Devis is free of charge for you."
                        )}
                    />
                    <FlexRow>
                        <Left20>
                            {allGroups.length > 0 && (
                                <Margin values="12px 0">
                                    <P>{i18n("Product groups")}</P>
                                    <Select
                                        mode="combobox"
                                        options={allGroups.map(group => mkDropdownOption(group, group))}
                                        fullWidth
                                        placeholder={i18n("Select")}
                                        searchable
                                        value={selectedGroups}
                                        onChange={setSelectedGroups}
                                    />
                                </Margin>
                            )}
                            {allCategories.length > 0 && (
                                <Margin values="12px 0">
                                    <P>{i18n("Categories")}</P>
                                    <Select
                                        mode="combobox"
                                        options={allCategories.map(cat => mkDropdownOption(cat, cat))}
                                        fullWidth
                                        placeholder={i18n("Select")}
                                        searchable
                                        value={selectedCategories}
                                        onChange={setSelectedCategories}
                                    />
                                </Margin>
                            )}
                        </Left20>
                        <Right80>
                            {(isEmpty(selectedGroups) && isEmpty(selectedCategories)
                                ? sortSortables(values(p.partners))
                                : sortSortables(values(p.partners)).filter(
                                      partner =>
                                          intersection(partner.groups, selectedGroups).length ||
                                          intersection(partner.categories, selectedCategories).length
                                  )
                            ).map(partner => (
                                <Partner key={partner.partnerId} value={partner} />
                            ))}
                        </Right80>
                    </FlexRow>
                </SubContent>
            </ArchitectContent>
        </>
    )
})

const Partner: React.FC<{ value: Domain.Partner }> = p => {
    return (
        <PartnerContainer columnsGrid={[0.5, 1, 1, 0.8]} gap="36px">
            <ExternalAsset size="huge-icon" src={p.value.logoUrl || ""} />
            <FlexColumn>
                <P>{displayCompanyName(p.value)}</P>
                <VerticalSpace base="8px" />
                <P small>{p.value.street}</P>
                <P small>
                    {p.value.postalCode} {p.value.city}
                </P>
            </FlexColumn>
            <FlexColumn>
                <P small style={{ fontWeight: "bold" }}>
                    {i18n("Contact")}
                </P>
                <P small>{getFullName(p.value)}</P>
                <P small>{p.value.phoneNumber}</P>
                <ExternalLink href={`mailto:${p.value.email}`}>{p.value.email}</ExternalLink>
            </FlexColumn>
            <FlexColumn>
                <P small style={{ fontWeight: "bold" }}>
                    {i18n("Products")}
                </P>
                {keys(p.value.products).map(k => (
                    <ExternalLink key={k} href={p.value.products[k]} target="_blank">
                        {k}
                    </ExternalLink>
                ))}
            </FlexColumn>
        </PartnerContainer>
    )
}

const PartnerContainer = styled(GridContainer)`
    border: 1px solid ${themeColor("grey60")};
    border-radius: 2px;
    padding: 18px 24px;
    padding: 18px 24px 24px;
    width: 100%;
    margin: 8px 0;
`

const Right80 = styled(Flex)`
    width: 100%;
    flex-direction: column;
    padding: 24px 0 0 48px;
    flex: 8;
`

const Left20 = styled(Flex)`
    width: 100%;
    flex-direction: column;
    padding: 24px 48px 0 0;
    flex: 2;
`
