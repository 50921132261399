import * as React from "react"
import { Asset, ImageAsset } from "./Asset"
import { P } from "./Typography"
import { FlexRow, HorizontalSpace } from "./utils/common"

export const Status: React.FC<{ icon: ImageAsset; text: string }> = p => {
    return (
        <FlexRow alignCenter>
            <Asset name={p.icon} size="icon" />
            <HorizontalSpace base="6px" />
            <P small>{p.text}</P>
        </FlexRow>
    )
}
