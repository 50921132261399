import * as React from "react"
import styled, { css } from "styled-components"
import TextareaAutosize from "react-textarea-autosize"

import { Asset } from "./Asset"
import { themeColor, themeConfig } from "./utils/theme"
import { F0 } from "@smartdevis/utils/src/types"
import { omitObject } from "@smartdevis/utils/src/map"
import { keycodes, useKeyPress } from "./hooks/useKeyPress"
import { styleIfProp } from "./utils/common"
import { rem } from "./utils"

type RawInputProps<Element extends HTMLElement> = React.DetailedHTMLProps<React.InputHTMLAttributes<Element>, Element>

type InputProps = Pick<
    RawInputProps<HTMLInputElement>,
    "value" | "placeholder" | "onChange" | "id" | "name" | "disabled" | "readOnly" | "onFocus" | "onBlur" | "type"
>

type TextareaProps = Pick<
    RawInputProps<HTMLTextAreaElement>,
    "value" | "placeholder" | "onChange" | "id" | "name" | "disabled" | "readOnly" | "onFocus" | "onBlur"
> & { minRows?: number; allowEnter?: boolean }

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((p, ref) => {
    const [visible, setVisible] = React.useState(false)
    return (
        <InputWrapper>
            <BaseInput ref={ref} {...p} type={visible ? "text" : "password"} />
            <InputIcon onClick={() => setVisible(v => !v)}>
                <Asset size="icon" name={visible ? "Eye" : "EyeCrossed"} />
            </InputIcon>
        </InputWrapper>
    )
})

export const SearchInput: React.FC<InputProps & { onSearch?: F0 }> = p => {
    useKeyPress(() => p.onSearch?.(), ["enter"])
    return (
        <InputWrapper>
            <BaseInput {...p} />
            <InputIcon onClick={p.onSearch}>
                <Asset size="icon" name="Search" />
            </InputIcon>
        </InputWrapper>
    )
}

export const TextareaInput: React.FC<TextareaProps> = p => (
    <BaseTextarea
        {...omitObject(p, ["allowEnter"])}
        minRows={p.minRows || 1}
        onKeyDown={e => {
            if (e.key === keycodes.enter && !e.shiftKey && !p.allowEnter) e.preventDefault()
        }}
    />
)

export const Input = React.forwardRef<HTMLInputElement, InputProps>((p, ref) => {
    if (p.type === "password") return <PasswordInput ref={ref} {...p} />
    return <BaseInput ref={ref} {...p} />
})

const InputIcon = styled.span`
    position: absolute;
    right: ${rem(12)};
    top: calc(50% - 11px);
    ${styleIfProp("onClick", "cursor: pointer;")}
`

const InputWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
`

const InputStyles = css<Pick<InputProps, "disabled">>`
    font-family: "${themeConfig("fontFamily")}", sans-serif;
    color: ${themeColor("textBlack")};
    background: ${p => (p.disabled ? themeColor("grey40") : themeColor("white"))};
    border: 1px solid ${themeColor("grey50")};

    box-sizing: border-box;
    border-radius: 4px;
    color: ${themeColor("primaryGrey")};
    transition: all 0.3s ease-in-out;
    padding: 6px 12px;
    font-size: 0.88rem;
    width: 100%;

    &:focus {
        border-color: ${themeColor("primary")};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type="number"] {
        -moz-appearance: textfield;
    }
`

export const BaseTextarea = styled(TextareaAutosize)`
    ${InputStyles}
`

export const BaseInput = styled.input`
    ${InputStyles}
`
