import * as React from "react"
import { Link as RouterLink, LinkProps } from "react-router-dom"

import { NavigationPayload } from "../paths"
import { omitObject } from "@smartdevis/utils/src/map"
import { materialize } from "../utils/router"

import { Button, ButtonProps } from "@smartdevis/ui/src/Button"

export const renderNothing = () => <React.Fragment />

export const Link = (p: React.PropsWithChildren<Omit<LinkProps<any>, "to">> & NavigationPayload) => (
    <RouterLink {...omitObject(p, ["params"])} to={materialize(p.to.path, p.params)} />
)

export const SubmitButton = (p: ButtonProps) => <Button {...p} btnType="primary" type="submit" />
export const ButtonSecondary = (p: ButtonProps) => <Button {...p} type="submit" btnType="secondary" />
