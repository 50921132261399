import * as React from "react"
import { OfferAdoptionCheck } from "./ContractorAdoption"
import { contractorPaths, RouteParams } from "../../paths"
import { ArchitectContent, ContractorContent } from "../../components/layouts/Content"
import { asyncConnect } from "../../resolvers"
import { Flex, FlexRow, HorizontalSpace, LeftCol, RightCol, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { StyledForm } from "../../components/forms"
import { StaticTextRenderer, StaticNumberRenderer, StaticOptionRenderer } from "../../components/forms/formRenderers"
import {
    getStyledAddressInfoSchema,
    getProjectPersonaTranslation,
    useAddressInfoForm,
    mkAddressInfoStaticSchema
} from "../architect/ProjectEdit.helpers"
import { PageHeader } from "../../components/layouts/Header"
import { getProjectTypeTranslation, i18n } from "@smartdevis/client/src/services/translations"
import { Accordion, AccordionPanel, useAccordion } from "@smartdevis/ui/src/Accordion"
import { getOpenOfferCopy, OfferHistory } from "./ContractorOfferHistory"
import { Button } from "@smartdevis/ui/src/Button"
import { ContractorOfferSteps } from "./ContractorSteps"
import { mkFormSchema, mkSelectSchema, mkTextSchema } from "../../components/forms/formSchemas"
import { devisValidation } from "@smartdevis/server/src/models/devis"
import { projectDetailsValidation } from "@smartdevis/server/src/models/projectDetails"
import { Dropdown } from "@smartdevis/ui/src/Dropdown"
import { Asset } from "@smartdevis/ui/src/Asset"
import { RejectOfferConfirmModal } from "./ContractorOffer.modals"
import { useCloudAction } from "../../hooks/useCloudAction"
import { defaultTheme, themeColor } from "@smartdevis/ui/src/utils/theme"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import { StyledFormSchema, useFormHook, FormHookResult } from "@smartdevis/forms/src"
import { ExportContractorContract } from "../../components/export/ExportButtons"
import { formatDateISO } from "@smartdevis/utils/src/date"
import { pickObject, TMap, keys } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { F0 } from "@smartdevis/utils/src/types"
import { Domain } from "@smartdevis/server/src/domain"
import { ErrorView } from "../Error"
import { useAuth } from "../AuthProvider"
import { getFullName } from "@smartdevis/server/src/models/user"
import { Spinner } from "@smartdevis/ui/src/Spinner"

export const OfferAdoptableView = asyncConnect({
    stateResolvers: ["auth", "publicOffer"],
    renderLoading: () => <Spinner fullscreen />
})<RouteParams>(p => {
    const { register } = useAuth()
    if (
        p.auth.type === "Authenticated" &&
        !(p.auth.value.type === "CONTRACTOR" || p.auth.value.type === "contractor")
    ) {
        return (
            <ArchitectContent>
                <ErrorView subText={i18n("You cannot be logged in as an architect")} />
            </ArchitectContent>
        )
    }
    if (p.auth.type !== "Authenticated" && p.publicOffer.state.type === "cancelled") {
        return (
            <ContractorContent {...p}>
                <ErrorView
                    subText={i18n(
                        "$1 has cancelled this offer request. Thank you very much for your efforts",
                        getFullName(p.publicOffer.architect)
                    )}
                />
            </ContractorContent>
        )
    }
    if (
        p.auth.type !== "Authenticated" &&
        !(
            p.publicOffer.state.type === "requested" ||
            p.publicOffer.state.type === "contracted" ||
            p.publicOffer.state.type === "next-round" ||
            p.publicOffer.state.type === "adopted"
        )
    ) {
        return (
            <ContractorContent {...p}>
                <ErrorView subText={i18n("Offer has already been assigned to another account")} />
            </ContractorContent>
        )
    }

    React.useEffect(() => {
        if (p.auth.type === "NotAuthenticated") register(p.publicOffer.contractor)
    }, [])

    if (p.auth.type !== "Authenticated") {
        return <Spinner fullscreen />
    }
    return (
        <OfferAdoptionCheck {...p}>
            <ContractorContent {...p}>
                <OfferView {...p} />
            </ContractorContent>
        </OfferAdoptionCheck>
    )
})

const staticRenderMap = {
    text: StaticTextRenderer,
    number: StaticNumberRenderer,
    select: StaticOptionRenderer,
    customBox: StaticNumberRenderer
}

const contractorFormKeys: (keyof Domain.ProjectDetailsAddressInfoFields)[] = [
    "client",
    "planner",
    "billing",
    "owner",
    "ownersRepresentative",
    "clientsRepresentative",
    "specialistPlanner",
    "constructionManagement"
]

type DevisFields = Pick<Domain.Devis, "number" | "entryTs" | "workStartTs" | "workEndTs">
type ProjectFields = Pick<Domain.ProjectDetails, "projectType" | "street" | "streetNumber" | "postalCode" | "city">

const mkSimpleDevisSchema = () =>
    mkFormSchema<DevisFields>(pickObject(devisValidation, ["number", "entryTs", "workStartTs", "workEndTs"]), {
        number: mkTextSchema("Devis number"),
        entryTs: mkTextSchema("Entry date", { fromValue: formatDateISO }),
        workStartTs: mkTextSchema("Work start", { fromValue: formatDateISO }),
        workEndTs: mkTextSchema("Work end", { fromValue: formatDateISO })
    })

const getStyledSimpleDevisSchema: F0<StyledFormSchema<DevisFields>> = () => [
    { type: "Row", value: ["number", "entryTs"] },
    { type: "Row", value: ["workStartTs", "workEndTs"] }
]

const mkSimpleProjectSchema = () =>
    mkFormSchema<ProjectFields>(pickObject(projectDetailsValidation, ["projectType", "street", "postalCode", "city"]), {
        projectType: mkSelectSchema<Domain.ProjectType>(i18n("Project Type"), [
            [getProjectTypeTranslation("new"), "new"],
            [getProjectTypeTranslation("renovation"), "renovation"],
            [getProjectTypeTranslation("restoration"), "restoration"]
        ]),
        street: mkTextSchema("Street"),
        streetNumber: mkTextSchema("Street Number"),
        postalCode: mkTextSchema("Zip code"),
        city: mkTextSchema("City")
    })

const getStyledSimpleProjectSchema: F0<StyledFormSchema<ProjectFields>> = () => [
    { type: "Row", value: ["projectType"] },
    { type: "Row", value: ["street", "streetNumber"] },
    { type: "Row", value: ["postalCode", "city"] }
]

const OfferView = asyncConnect({
    stateResolvers: ["contractorOffer", "results"],
    actions: ["navigate", "rejectOffer"],
    renderLoading: () => <Spinner fullscreen />
})<RouteParams>(p => {
    const [rejectModalVisible, setRejectModalVisible] = React.useState(false)
    const { formViewProps: formViewProject } = useFormHook({
        schema: mkSimpleProjectSchema(),
        initialValue: { ...p.contractorOffer.project }
    })

    const { formViewProps: fromViewDevis } = useFormHook({
        schema: mkSimpleDevisSchema(),
        initialValue: { ...p.contractorOffer.devis }
    })
    const { project } = p.contractorOffer
    const schema = mkAddressInfoStaticSchema()
    const forms = contractorFormKeys.reduce((acc, k) => {
        if (Boolean(project?.[k])) {
            ;(acc as any)[k] = useAddressInfoForm(schema, k, project as Domain.ProjectDetails)
        }
        return acc
    }, {}) as TMap<keyof Domain.ProjectDetailsAddressInfoFields, FormHookResult<Domain.AddressInfo>>

    const { actionState: rejectState, onSubmit: onReject } = useCloudAction(
        actionId => {
            p.rejectOffer({ actionId, offerId: p.contractorOffer.offerId })
        },
        p.results,
        res => {
            if (res.type === "Ok") p.navigate(contractorPaths.contractorOffers)
        }
    )

    const [selected, onChange] = useAccordion([], { multiple: true })
    return (
        <>
            <Flex>
                <LeftCol>
                    <PageHeader title={p.contractorOffer.devis?.workCategory} />
                    <StyledForm
                        {...fromViewDevis}
                        inputsRenderMap={staticRenderMap}
                        styledSchema={getStyledSimpleDevisSchema()}
                        styledInputsRenderMap={{
                            Custom: () => <HorizontalSpace base="16px" />
                        }}
                    />
                    <StyledForm
                        {...formViewProject}
                        inputsRenderMap={staticRenderMap}
                        styledSchema={getStyledSimpleProjectSchema()}
                        styledInputsRenderMap={{
                            Custom: () => <HorizontalSpace base="16px" />
                        }}
                    />
                    <Accordion animationMs={200} onChange={onChange} selected={selected}>
                        {keys(forms).map(k => (
                            <AccordionPanel header={getProjectPersonaTranslation(k)} key={k}>
                                <StyledForm
                                    {...forms[k].formViewProps}
                                    inputsRenderMap={{
                                        text: StaticTextRenderer,
                                        number: StaticNumberRenderer,
                                        select: StaticOptionRenderer,
                                        customBox: StaticNumberRenderer,
                                        email: StaticTextRenderer
                                    }}
                                    styledSchema={getStyledAddressInfoSchema("", false)}
                                />
                            </AccordionPanel>
                        ))}
                    </Accordion>
                </LeftCol>
                <RightCol>
                    <FlexRow justifyEnd>
                        {!isOfferInState(p.contractorOffer, ["cancelled", "rejected"]) && (
                            <>
                                {isOfferInState(p.contractorOffer, ["contracted"]) && <ExportContractorContract />}
                                <HorizontalSpace base="16px" />
                                <Button
                                    btnType="primary"
                                    onClick={() =>
                                        p.navigate(contractorPaths.contractorOfferStep, {
                                            offerId: p.contractorOffer.offerId,
                                            offerStep: "conditions"
                                        })
                                    }>
                                    {getOpenOfferCopy(p.contractorOffer)}
                                </Button>
                                {!isOfferInState(p.contractorOffer, ["contracted"]) && (
                                    <>
                                        <HorizontalSpace base="16px" />
                                        <Dropdown
                                            options={[
                                                {
                                                    label: i18n("Reject"),
                                                    value: "Reject",
                                                    type: "basic",
                                                    action: () => {
                                                        setRejectModalVisible(true)
                                                    }
                                                }
                                            ]}>
                                            <Button
                                                btnType="secondary"
                                                style={{
                                                    padding: "0",
                                                    borderColor: themeColor("grey60")({ theme: defaultTheme })
                                                }}>
                                                <Asset name="Dots" size="big-icon" />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            </>
                        )}
                    </FlexRow>
                    <VerticalSpace h={16} />
                    <OfferHistory offer={p.contractorOffer} />
                </RightCol>
                {isDefined(p.match.params.offerStep) && (
                    <ContractorOfferSteps {...pickObject(p, ["history", "location", "match"])} />
                )}
            </Flex>
            <RejectOfferConfirmModal
                show={rejectModalVisible}
                setShow={setRejectModalVisible}
                onConfirm={onReject}
                processing={rejectState.type === "Processing"}
            />
        </>
    )
})
