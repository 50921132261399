import { Middleware } from "../utils/store"
import { logEvent, GAEvents } from "../services/analytics"
import { ClientActions } from "."

const getEvent = (a: ClientActions): GAEvents | null => {
    switch (a.type) {
        case "register":
            return { name: "register_user" }
        case "mutate":
            if (a.payload.mutationType === "create" && a.payload.collection === "devis") return { name: "create_devis" }
            if (a.payload.mutationType === "create" && a.payload.collection === "projects")
                return { name: "create_project" }
            break
    }
    return null
}

export const analyticsMiddleware: Middleware = _ => next => async a => {
    next(a)
    const event = getEvent(a as ClientActions)
    if (event) logEvent(event)
}
