import { Casted, ValueState } from "./types"

export type Errors<T> = Partial<Casted<T, string>>
export type ErrArray<T> = Array<Err<ExtErrors<T>>>
export type ExtErrors<T> = Errors<T> | string
export type OkType = "Ok"
export type Ok<T> = ValueState<OkType, T>
export type ErrType = "Err"
export type Err<E, O = any> = ValueState<ErrType, E> & { obj: O }
export type ResultType = OkType | ErrType
export type Result<T, E = ExtErrors<T>> = Ok<T> | Err<E>

export const mkErr = <T>(error: T, obj: any = {}): Err<T> => ({ type: "Err", value: error, obj })
export const mkOk = <T>(value: T): Ok<T> => ({ type: "Ok", value })

export const isErr = <T>(v: any): v is Err<T> => v && v.type === "Err"
export const isOk = <T>(v: any): v is Ok<T> => v && v.type === "Ok"

export const prepareError = (e: any, msg?: string) => {
    if (isErr(e)) return { message: JSON.stringify(e.value) }
    const result: { message: string; name?: string } = { message: msg ?? "Unknown error" }
    if (e.message) result.message = e.message
    if (e.name) result.name = e.name
    return result
}
