import { Domain, Utils } from "../domain"
import {
    validateNumber,
    optionalNumber,
    optionalString,
    validateMemberOf,
    mkMapValidator,
    optional,
    ValidationMap
} from "@smartdevis/utils/src/validators"
import { Orderable } from "@smartdevis/utils/src/comparable"

const mutationTypes: Domain.MutationType[] = ["create", "update", "remove"]
export const validMutationType = [validateMemberOf(mutationTypes)]

export const creatableValidation: ValidationMap<Required<Utils.Creatable>> = {
    createdTs: optionalNumber,
    updatedTs: optionalNumber
}

export const orderableValidation: ValidationMap<Required<Orderable>> = {
    order: [optional(-1)(validateNumber)]
}

export const mutableValidation: ValidationMap<Domain.Mutable> = { mutationType: validMutationType }
export const mutable = <T>(vmap: ValidationMap<T>) =>
    ({ ...mutableValidation, ...vmap } as ValidationMap<T & Domain.Mutable>)

export const translationValidation: ValidationMap<Domain.TranslationCopy> = {
    en: optionalString,
    de: optionalString
}
export const validateTranslation = mkMapValidator(translationValidation)
