import { keys } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { isErr } from "@smartdevis/utils/src/result"
import {
    optionalString,
    runValidatorsRaw,
    validEmail,
    validateNotEmpty,
    validateString,
    validateTuple,
    Validators
} from "@smartdevis/utils/src/validators"
import { ImportContractorsTemplate } from "../views/architect/Contacts"
import { CSVParseResultData } from "./csv"

const mandatoryContractorFieldsValidators: [Validators<string>, Validators<string>] = [
    [validateString, validateNotEmpty],
    validEmail
]

const optionalContractorFieldsValidators: [
    Validators<string>,
    Validators<string>,
    Validators<string>,
    Validators<string>,
    Validators<string>,
    Validators<string>,
    Validators<string>,
    Validators<string>
] = [
    optionalString,
    optionalString,
    optionalString,
    optionalString,
    optionalString,
    optionalString,
    optionalString,
    optionalString
]

const importContractorsMandatoryLineValidator = validateTuple(mandatoryContractorFieldsValidators)
const importContractorsOptionalLineValidator = validateTuple(optionalContractorFieldsValidators)

export type ContractorValidationError = { row: number; column: number }

export const extractValidatedContractors = (fileContent: CSVParseResultData[]) => {
    let validationErrors: ContractorValidationError[] = []
    const [_, ...content] = fileContent // ignore .csv header
    const validatedContractors = content.map((c, i) => {
        const mandatoryFieldsLength = mandatoryContractorFieldsValidators.length
        const mandatoryContractorFields = c.slice(0, mandatoryFieldsLength)
        const optionalContractorFields = c.slice(mandatoryFieldsLength)
        const validatedMandatoryContractorFields = runValidatorsRaw(
            [importContractorsMandatoryLineValidator],
            mandatoryContractorFields
        )

        if (isErr(validatedMandatoryContractorFields)) {
            if (typeof validatedMandatoryContractorFields.value === "string") return null
            // .csv indices rows and columns starting with 1 instead of 0
            // row: i + 2 -> the 1st row is the omitted header, column: index + 1
            keys(validatedMandatoryContractorFields.value).forEach(index => {
                validationErrors = [...validationErrors, { row: i + 2, column: parseInt(index as string, 10) + 1 }]
            })
            return null
        }
        const validatedOptionalContractorFields = runValidatorsRaw(
            [importContractorsOptionalLineValidator],
            optionalContractorFields
        )
        if (isErr(validatedOptionalContractorFields)) return null

        const [companyName, email, name, surname, phoneNumber, street, postalCode, city, number, workCategory] = [
            ...validatedMandatoryContractorFields.value,
            ...validatedOptionalContractorFields.value
        ]

        return { companyName, email, name, surname, phoneNumber, street, postalCode, city, number, workCategory }
    })

    return { valid: validatedContractors.filter(isDefined), invalid: validationErrors }
}

export const removeDuplicateImportContractors = (
    importContractors: ImportContractorsTemplate[],
    existingContractorsEmails: string[]
) => {
    const newContractors = importContractors.filter(c => !existingContractorsEmails.includes(c.email))
    let emails = [...new Set(newContractors.map(c => c.email))]
    if (emails.length === newContractors.length) return newContractors
    return newContractors.filter(c => {
        if (!emails.includes(c.email)) return false
        emails = emails.filter(e => e !== c.email)
        return true
    })
}
