import { keys } from "@smartdevis/utils/src/map"
import { Casted } from "@smartdevis/utils/src/types"

// currently not used
// might be useful in the future

const breakpoints = {
    xs: "480px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px"
}

export type Breakpoints = typeof breakpoints

export type MediaQuery = { [key in "min" | "max"]: MediaCSS }

export type MediaCSS = Casted<Breakpoints, string>

const mediaFactory = (operator: keyof MediaQuery): MediaCSS =>
    keys(breakpoints).reduce<MediaCSS>((acc, label) => {
        const style = `@media (${operator}-width: ${breakpoints[label]})`
        acc[label] = style
        return acc
    }, {} as any)

export const media: MediaQuery = {
    min: mediaFactory("min"),
    max: mediaFactory("max")
}
