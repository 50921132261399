import * as React from "react"
import styled from "styled-components"
import { Domain } from "@smartdevis/server/src/domain"
import { getStorage } from "../services/firebase"
import { Asset } from "@smartdevis/ui/src/Asset"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { mkCellEmpty, mkCell, Cell } from "./table/TableCell"
import { P } from "@smartdevis/ui/src/Typography"
import { F0, F1, Optionalize, StateType } from "@smartdevis/utils/src/types"
import { compareTs } from "@smartdevis/utils/src/comparable"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { SMap, values } from "@smartdevis/utils/src/map"
import { AsyncConnectResults } from "../resolvers"
import { i18n } from "../services/translations"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { _noop } from "@smartdevis/utils/src/misc"
import { UploadedFile, UploadingFile, Upload, FileList } from "@smartdevis/ui/src/Upload"
import { AsyncAttachment, AsyncNewAttachment } from "../store/attachmentsCmds"
import { useNotifications } from "../views/NotificationsProvider"

export type AttachmentType = StateType<Domain.Attachment>

export type AttachmentsManagement = {
    type: AttachmentType
    items: SMap<Domain.Attachment>
    uploadingItems: SMap<AsyncAttachment>
    onUpload: (file: UploadingFile, refId: string) => void
    getItemsForRef: (id: string) => Domain.Attachment[]
    onRemove: F1<string>
}

export const useAttachmentsManagement = (
    type: AttachmentType,
    p: AsyncConnectResults<
        "projectDetails" | "projectAttachments" | "uploadingFiles" | "user",
        "uploadFile" | "removeAttachment"
    >
): AttachmentsManagement => {
    return {
        type,
        items: p.projectAttachments,
        uploadingItems: p.uploadingFiles,
        getItemsForRef: (refId: string) =>
            values(p.projectAttachments).filter(a => a.refId === refId && a.type === type),
        onUpload: (f: UploadingFile, refId: string) =>
            p.uploadFile(mkAsyncAttachment(f, type, refId), p.user.userId, p.projectDetails.projectId),
        onRemove: (id: string) => p.removeAttachment(p.projectAttachments[id], p.projectDetails.projectId)
    }
}

export const useAttachmentsForDevisTemplate = (
    p: AsyncConnectResults<
        "devisTemplateCollections" | "devisTemplate" | "uploadingFiles" | "user",
        "uploadFile" | "removeAttachment"
    >
): AttachmentsManagement => {
    return {
        type: "position",
        items: p.devisTemplateCollections.attachments,
        uploadingItems: p.uploadingFiles,
        getItemsForRef: (refId: string) =>
            values(p.devisTemplateCollections.attachments).filter(a => a.refId === refId),
        onUpload: (f: UploadingFile, refId: string) =>
            p.uploadFile(
                mkAsyncAttachment(f, "position", refId),
                p.user.userId,
                p.devisTemplate.templateId,
                "template"
            ),
        onRemove: (id: string) =>
            p.removeAttachment(p.devisTemplateCollections.attachments[id], p.devisTemplate.templateId, "template")
    }
}

export const useContractorAttachmentsManagement = (
    type: AttachmentType,
    p: Optionalize<
        AsyncConnectResults<"contractorOffer" | "uploadingFiles" | "user", "uploadFile" | "removeAttachment">,
        "uploadingFiles" | "uploadFile" | "removeAttachment"
    >
): AttachmentsManagement => {
    return {
        type,
        items: p.contractorOffer.attachments,
        uploadingItems: p.uploadingFiles ?? {},
        getItemsForRef: (refId: string) => values(p.contractorOffer.attachments).filter(a => a.refId === refId),
        onUpload: (f: UploadingFile, refId: string) =>
            p.uploadFile?.(mkAsyncAttachment(f, type, refId), p.user.userId, p.contractorOffer.project.projectId),
        onRemove: (id: string) =>
            p.removeAttachment?.(p.contractorOffer.attachments[id], p.contractorOffer.project.projectId)
    }
}

type AttachmentsViewProps = { refId: string; isReadonly?: boolean } & AttachmentsManagement
export const AttachmentsView = (p: AttachmentsViewProps) => {
    const filteredAttachments = values(p.items).filter(v => v.refId === p.refId && v.type === p.type)
    const { pushNotification } = useNotifications()
    return (
        <Upload
            isReadonly={p.isReadonly}
            uploadButtonTitle={i18n("Upload")}
            onUpload={files => files.forEach(f => p.onUpload(f, p.refId))}
            onFileSizeRestricted={() => pushNotification(i18n("File size is too big"))}
            files={[...values(p.uploadingItems), ...mkUploadedFiles(filteredAttachments)]}
            onRemove={!p.isReadonly ? p.onRemove : undefined}
        />
    )
}

export const AttachmentsModal = (p: Optionalize<AttachmentsViewProps, "refId"> & BaseModalProps) => {
    return (
        <Modal header={i18n("Attachments Upload")} visible={p.visible} size="s" onClose={p.onClose}>
            {p.refId && p.visible ? <AttachmentsView {...p} refId={p.refId} /> : <Spinner />}
        </Modal>
    )
}

const mkUploadedFiles = (vs: Domain.Attachment[]): UploadedFile[] =>
    vs.sort(compareTs).map(v => ({
        contentType: v.contentType,
        createdTs: v.createdTs,
        fileSize: v.size,
        name: v.name,
        type: "uploaded",
        uid: v.attachmentId,
        url: getStorage().getFileUrl(v.path)
    }))

export const mkAsyncAttachment = (file: UploadingFile, type: AttachmentType, refId: string): AsyncNewAttachment => ({
    ...file,
    attachment: {
        attachmentId: file.uid || genTemporaryId(),
        refId,
        type
    }
})

export const AttachmentsPreviewList: React.FC<{ attachments: Domain.Attachment[] }> = p => (
    <FileList files={mkUploadedFiles(p.attachments)} />
)

export const mkAttachmentCell = <T extends any>(count: number, onClick: F0, readonly: boolean): Cell<T> => {
    if (readonly && count === 0) return mkCellEmpty()
    return mkCell(
        <AttachmentIconWrapper>
            <Attachments
                onClick={e => {
                    onClick()
                    e.preventDefault()
                    e.stopPropagation()
                }}>
                <Asset name="IconFile" size="icon" />
                {count !== 0 && <P>{count}</P>}
            </Attachments>
        </AttachmentIconWrapper>,
        ["alignRight"]
    )
}

export const mkCommentsCell = <T extends any>(count: number, onClick: F0, readonly: boolean): Cell<T> => {
    if (readonly && count === 0) return mkCellEmpty()
    return mkCell(
        <AttachmentIconWrapper>
            <Attachments
                onClick={e => {
                    onClick()
                    e.preventDefault()
                    e.stopPropagation()
                }}>
                <Asset name="Comment" size="icon" />
                {count !== 0 && <P>{count}</P>}
            </Attachments>
        </AttachmentIconWrapper>,
        ["alignRight"]
    )
}

const AttachmentIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const Attachments = styled.div`
    display: flex;
    align-items: center;
    background-color: ${themeColor("grey35")};
    cursor: pointer;
    padding: 6px;
    border-radius: 100px;
`
