import * as React from "react"
import styled from "styled-components"
import { RouteParams } from "../../paths"
import { asyncConnect } from "../../resolvers"
import { Row, Table } from "../../components/table/Table"
import { Cell, mkCell } from "../../components/table/TableCell"
import { i18n } from "@smartdevis/client/src/services/translations"
import { FlexRow, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { mkConditions, mkRequestDeltasByRef } from "@smartdevis/server/src/models/shared"
import { getRowVisualFromDelta } from "@smartdevis/client/src/utils/devisHelpers"
import { H3, P } from "@smartdevis/ui/src/Typography"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { AttachmentsModal, mkAttachmentCell, useContractorAttachmentsManagement } from "../../components/Attachments"
import { sortSortables } from "@smartdevis/utils/src/comparable"
import { values } from "@smartdevis/utils/src/map"
import { AttachmentsList } from "../architect/lists/AttachmentsList"
import { _noop } from "@smartdevis/utils/src/misc"
import { OfferAdditionalInformation } from "./ContractorInfo"
import { OfferStepProps } from "./ContractorSteps"
import { Domain } from "@smartdevis/server/src/domain"

const mkHeader = (): Row<"header", any> => ({
    mode: "static",
    type: "header",
    visuals: ["header"],
    cells: [mkCell(i18n("ID")), mkCell(i18n("Name")), mkCell(i18n("Description")), mkCell(i18n("Attach."))],
    grid: [2, 8, 12, 2],
    noDrag: true,
    rowId: "header"
})

type ConditionPayload = Pick<Domain.Condition, "name" | "description">
const mkRow = (
    cond: Domain.Condition,
    index: number,
    attachmentsCell: Cell<ConditionPayload>,
    delta?: Domain.RoundDelta
): Row<"condition", ConditionPayload> => ({
    mode: "static",
    type: "condition",
    rowId: cond.conditionId,
    grid: [2, 8, 12, 2],
    visuals: getRowVisualFromDelta(delta).concat("noBorder"),
    cells: [
        mkCell(`0.${index + 1}`),
        mkCell(cond.name, [], { editMode: "formless", field: "name" }),
        mkCell(cond.description, [], { editMode: "formless", field: "description" }),
        attachmentsCell
    ]
})

type SubCategoryTitleProps = {
    title: string
    noBottomMargin?: boolean
    noTopMargin?: boolean
}

const getPageHeaderMargin = (p: Pick<SubCategoryTitleProps, "noTopMargin" | "noBottomMargin">) => {
    const top = p.noTopMargin ? "0" : "16px"
    const bottom = p.noBottomMargin ? "0" : "16px"
    return `${top} 0 ${bottom}`
}

const SubCategoryTitle: React.FC<SubCategoryTitleProps> = p => (
    <Margin values={getPageHeaderMargin(p)}>
        <H3>{p.title}</H3>
    </Margin>
)

export const OfferConditions = asyncConnect({ stateResolvers: ["contractorOffer", "user"] })<
    Pick<OfferStepProps, "information" | "readonly" | "setInformation" | "errorMap" | "mode"> & RouteParams
>(p => {
    const deltas = mkRequestDeltasByRef(p.contractorOffer)
    const [attachmentModalConditionId, setAttachmentConditionId] = React.useState<string | undefined>(undefined)

    const conditions = mkConditions(p.contractorOffer, deltas)
    const generalAttachments = useContractorAttachmentsManagement("general", p)
    const conditionAttachments = useContractorAttachmentsManagement("condition", p)

    const rows = conditions.map((c, i) =>
        mkRow(
            c,
            i,
            mkAttachmentCell(
                conditionAttachments.getItemsForRef(c.conditionId).length,
                () => setAttachmentConditionId(c.conditionId),
                true
            ),
            deltas?.[c.conditionId]
        )
    )

    return (
        <>
            <FlexRow>
                <Left>
                    <SubCategoryTitle noTopMargin title={i18n("Prefix")} />
                    <GeneralInformation>
                        <Join
                            items={sortSortables(values(p.contractorOffer.generalInformation))}
                            renderJoining={() => <VerticalSpace base="16px" />}>
                            {v => (
                                <P small key={v.informationId}>
                                    {v.value}
                                </P>
                            )}
                        </Join>
                    </GeneralInformation>
                    <SubCategoryTitle title={i18n("Special provisions")} />
                    <Table<"condition" | "header"> rowHierarchy={["condition"]} rows={[mkHeader(), ...rows]} />
                    <SubCategoryTitle title={i18n("Additional information")} />
                    <OfferAdditionalInformation
                        mode={p.mode}
                        information={p.information}
                        errorIds={p.errorMap?.information}
                        readonly={p.readonly}
                        setInformation={p.setInformation}
                        contractorOffer={p.contractorOffer}
                    />
                </Left>
                <Right>
                    <SubCategoryTitle noTopMargin title={i18n("Attachments")} />
                    <AttachmentsList
                        isReadonly
                        attachments={generalAttachments}
                        refId={p.contractorOffer.devis.devisId}
                    />
                </Right>
            </FlexRow>
            <AttachmentsModal
                isReadonly
                visible={!!attachmentModalConditionId}
                onClose={() => setAttachmentConditionId(undefined)}
                refId={attachmentModalConditionId}
                {...conditionAttachments}
            />
        </>
    )
})

const GeneralInformation = styled.div`
    padding: 0 10px 16px 10px;
    white-space: pre-wrap;
`

const Right = styled.div`
    width: 312px;
    margin-left: 24px;
`
const Left = styled.div`
    flex: 1;
    margin-right: 24px;
`
