import * as React from "react"
import styled from "styled-components"
import { H1 } from "@smartdevis/ui/src/Typography"
import { FlexColumn, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect } from "../../resolvers"
import { contractorPaths, RouteParams } from "../../paths"
import { useCloudAction } from "../../hooks/useCloudAction"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { values } from "@smartdevis/utils/src/map"
import { Link } from "../../components"
import { Button } from "@smartdevis/ui/src/Button"

export const OfferAdoptionCheck = asyncConnect({
    stateResolvers: ["auth", "results", "contractorOffers"],
    actions: ["adoptOffer"]
})<
    RouteParams & {
        children: React.ReactElement
    }
>(p => {
    const { offerId } = p.match.params
    const offerIds = values(p.contractorOffers).map(o => o.offerId)
    const { actionState, onSubmit } = useCloudAction(actionId => p.adoptOffer({ offerId, actionId }), p.results)
    const isForAdopt =
        p.auth.type === "Authenticated" &&
        (p.auth.value.type === "CONTRACTOR" || p.auth.value.type === "contractor") &&
        !offerIds.includes(offerId)

    React.useEffect(() => {
        if (isForAdopt) onSubmit()
    }, [p.auth.type])

    if (isForAdopt && actionState.type !== "Done")
        return (
            <FlexColumn alignCenter>
                {actionState.type === "Err" ? (
                    <>
                        <H1>{i18n(actionState.value)}</H1>
                        <VerticalSpace h={16} />
                        <Link to={contractorPaths.contractorOffers}>
                            <Button>{i18n("Go back to offers")}</Button>
                        </Link>
                    </>
                ) : (
                    <>
                        <H1>{i18n("Connecting an offer") + "..."}</H1>
                        <VerticalSpace h={32} />
                        {actionState.type === "Processing" && <Spinner />}
                    </>
                )}
            </FlexColumn>
        )
    return p.children
})

export const StyledAuthForm = styled.form`
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f5f5f5;
    border-left: 1px solid #f5f5f5;
    padding: 20px;
`

export const FormWrapper = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #f5f5f5;
    padding: 20px;
    overflow-y: scroll;
`
