import * as React from "react"

export const FileText: React.FC = p => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
        <path d="M7 9.96924H15" stroke="#212121" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 15.9692H17" stroke="#212121" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 21.9692H25" stroke="#212121" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M3 4.03076C2.46957 4.03076 1.96086 4.24148 1.58579 4.61655C1.21071 4.99162 1 5.50033 1 6.03076V25.9694C1 26.4999 1.21071 27.0086 1.58579 27.3836C1.96086 27.7587 2.46957 27.9694 3 27.9694H29C29.5304 27.9694 30.0391 27.7587 30.4142 27.3836C30.7893 27.0086 31 26.4999 31 25.9694V9.96943C30.9999 9.44783 30.7961 8.94689 30.432 8.57343L26.588 4.63343C26.4016 4.44253 26.1788 4.29086 25.9329 4.18736C25.687 4.08386 25.4228 4.03061 25.156 4.03076H3Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
