import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { writable } from "@smartdevis/utils/src/misc"
import { keys } from "@smartdevis/utils/src/map"
import { draftable, TDocumentDefinitions } from "../utils/pdf"

writable(pdfMake).vfs = pdfFonts.pdfMake.vfs

type PDFOptions = {
    isDraft?: boolean
}

const applyOptions = (doc: TDocumentDefinitions, options: PDFOptions) =>
    keys(options).reduce((acc, curr) => {
        switch (curr) {
            case "isDraft":
                return draftable(acc, options[curr])
            default:
                return acc
        }
    }, doc)

export const generatePdf = (doc: TDocumentDefinitions, options: PDFOptions = {}) => {
    return new Promise<Blob>(resolve => pdfMake.createPdf(applyOptions(doc, options)).getBlob(resolve))
}
