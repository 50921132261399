import * as React from "react"
import { OfferStepProps } from "./ContractorSteps"
import { mkDevisAdditionalInformation, mkRequestDeltasByRef } from "@smartdevis/server/src/models/shared"
import { Table } from "../../components/table/Table"
import { mkAdditionalInformationContractorRowBuilder } from "./ContractorInfo.rows"
import { Domain } from "@smartdevis/server/src/domain"

export const OfferAdditionalInformation: React.FC<
    Pick<OfferStepProps, "information" | "readonly" | "setInformation" | "mode"> & {
        contractorOffer: Domain.ContractorOffer
        errorIds?: string[]
    }
> = p => {
    const deltas = mkRequestDeltasByRef(p.contractorOffer)
    const additionalInformation = mkDevisAdditionalInformation(p.contractorOffer, deltas, {
        keepDeleted: p.mode !== "final"
    })
    const mkRow = mkAdditionalInformationContractorRowBuilder(
        p.contractorOffer,
        p.information,
        p.readonly,
        p.setInformation,
        p.errorIds
    )

    const rows = additionalInformation.map(ai => mkRow.mkInformationRow(ai))

    return <Table rows={[mkRow.mkHeaderRow(), ...rows]} rowHierarchy={["information"]} />
}
