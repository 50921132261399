import * as React from "react"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { Dropzone, fitsExtensions } from "@smartdevis/ui/src/Dropzone"
import { F0, F1 } from "@smartdevis/utils/src/types"
import {
    AsyncFile,
    AvailableExtension,
    UploadedFile,
    UploadingFile,
    UploadRestrictions
} from "@smartdevis/ui/src/Upload"
import { i18n } from "../../services/translations"
import { getTs } from "@smartdevis/utils/src/id"

type FilesUploadProps = {
    visible: boolean
    onFileUpload: F1<UploadingFile>
    uploadStatus: "NotStarted" | "Processing" | "Err" | "Done"
    onClose?: F0
    title?: string
    acceptableExtensions?: AvailableExtension[]
    forbiddenExtensions?: AvailableExtension[]
}

export const FilesUploadModal: React.FC<BaseModalProps & FilesUploadProps> = p => {
    const { acceptableExtensions, forbiddenExtensions } = p

    const restrictions: Partial<UploadRestrictions> = {
        maxFileSize: 10_000_000,
        maxFilesCount: 1,
        disallowMultiple: true,
        acceptableExtensions,
        forbiddenExtensions
    }

    const [files, setFiles] = React.useState<AsyncFile[]>([])

    const handleUpload = async (file: UploadingFile): Promise<UploadedFile> => {
        p.onFileUpload(file)

        return new Promise(resolve => {
            resolve({
                type: "uploaded",
                uid: file.uid,
                url: "",
                fileSize: file.file.size,
                name: file.name,
                contentType: file.file.name.split(".").pop() || "",
                createdTs: getTs()
            })
        })
    }

    const onUpload = (fileList: UploadingFile[]) => {
        const { maxFileSize, maxFilesCount, acceptableExtensions: ae } = restrictions

        const uploadSlotsLeft = !!maxFilesCount ? maxFilesCount - files.length : undefined

        const filteredList = fileList
            .filter(f => (!!maxFileSize ? f.file.size <= maxFileSize : true))
            .filter(f => fitsExtensions(f.file, ae))
            .filter(f => (forbiddenExtensions?.length ? !fitsExtensions(f.file, forbiddenExtensions) : true))
            .slice(0, uploadSlotsLeft)

        setFiles(prevList => [...prevList, ...filteredList])

        filteredList.forEach(f => {
            handleUpload(f).then(result =>
                setFiles(prevList => prevList.map(pf => (pf.uid === result.uid ? result : pf)))
            )
        })
    }

    const onRemove = async (fileId: string) => {
        const removeIndex = files.findIndex(file => file.uid === fileId)
        setFiles(prevFiles => [...prevFiles.slice(0, removeIndex), ...prevFiles.slice(removeIndex + 1)])
    }

    return (
        <Modal visible={p.visible} size="l" height="600px" onClose={p.onClose}>
            <Dropzone
                title={p.title ? p.title : i18n("Drop your files here or select multiple documents")}
                buttonType="icon-button"
                restrictions={restrictions}
                files={
                    files.map(f => ({
                        ...f,
                        type: p.uploadStatus === "Processing" ? "uploading" : "uploaded"
                    })) as AsyncFile[]
                }
                onUpload={onUpload}
                onRemove={onRemove}
            />
        </Modal>
    )
}
