import * as React from "react"

export const FileTextPdf: React.FC = p => (
    <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
        <path
            d="M11.7386 15.5835C11.7386 15.2466 11.6493 15.0063 11.4705 14.8628C11.2948 14.7192 11.0472 14.6475 10.7279 14.6475H9.46664V16.5547H10.7279C11.0472 16.5547 11.2948 16.4771 11.4705 16.3218C11.6493 16.1665 11.7386 15.9204 11.7386 15.5835ZM13.0789 15.5747C13.0789 16.3394 12.8856 16.8799 12.4989 17.1963C12.1121 17.5127 11.5599 17.6709 10.8421 17.6709H9.46664V20H8.12191V13.5225H10.9432C11.5936 13.5225 12.1121 13.6895 12.4989 14.0234C12.8856 14.3574 13.0789 14.8745 13.0789 15.5747ZM15.498 14.6475V18.875H16.7461C17.3848 18.875 17.8301 18.5601 18.082 17.9302C18.2197 17.5845 18.2886 17.1729 18.2886 16.6953C18.2886 16.0361 18.1846 15.5308 17.9766 15.1792C17.7715 14.8247 17.3613 14.6475 16.7461 14.6475H15.498ZM16.9746 13.5225C17.376 13.5283 17.71 13.5752 17.9766 13.6631C18.4307 13.8125 18.7983 14.0864 19.0796 14.4849C19.3052 14.8071 19.459 15.1558 19.541 15.5308C19.623 15.9058 19.6641 16.2632 19.6641 16.603C19.6641 17.4644 19.4912 18.1938 19.1455 18.7915C18.6768 19.5972 17.9531 20 16.9746 20H14.1841V13.5225H16.9746ZM20.778 13.5312H25.3703V14.6694H22.1227V16.1592H24.966V17.2842H22.1227V20H20.778V13.5312Z"
            fill="black"
        />
        <path
            d="M3 4.03076C2.46957 4.03076 1.96086 4.24148 1.58579 4.61655C1.21071 4.99162 1 5.50033 1 6.03076V25.9694C1 26.4999 1.21071 27.0086 1.58579 27.3836C1.96086 27.7587 2.46957 27.9694 3 27.9694H29C29.5304 27.9694 30.0391 27.7587 30.4142 27.3836C30.7893 27.0086 31 26.4999 31 25.9694V9.96943C30.9999 9.44783 30.7961 8.94689 30.432 8.57343L26.588 4.63343C26.4016 4.44253 26.1788 4.29086 25.9329 4.18736C25.687 4.08386 25.4228 4.03061 25.156 4.03076H3Z"
            stroke="#959DA1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="31" cy="28" r="7" fill="#FF3C00" />
        <circle cx="31" cy="28" r="6.5" stroke="black" strokeOpacity="0.05" />
        <path d="M31 25.0469V30.9531" stroke="white" />
        <path d="M33.9531 28L28.0469 28" stroke="white" />
    </svg>
)
