import { mkCommentsCell } from "../../components/Attachments"
import { mkFormattedNumberSchema, mkNullableFormattedToValue } from "../../components/forms/formSchemas"
import { Row } from "../../components/table/Table"
import { Cell, fillEmpty, mkCell, mkCellEmpty } from "../../components/table/TableCell"
import { i18n, getUnitTranslation } from "@smartdevis/client/src/services/translations"
import { getRowVisualFromDelta } from "@smartdevis/client/src/utils/devisHelpers"
import { mkRequestDeltasByRef } from "@smartdevis/server/src/models/shared"
import { getOfferRowMode, OfferRowMode } from "./ContractorSteps"
import { getChangedOffersInNegotiation, mkLatestOfferValues } from "@smartdevis/server/src/utils/offer"
import { sumPositionsPrices } from "@smartdevis/server/src/utils/money"
import { TMap, SMap } from "@smartdevis/utils/src/map"
import { isOk } from "@smartdevis/utils/src/result"
import { F2 } from "@smartdevis/utils/src/types"
import { nullableUFloat } from "@smartdevis/utils/src/validators"
import { formatSwiss, displayNumber, CHF, mulSafeNumbers, toSafeNumber } from "@smartdevis/utils/src/numbers"
import { usePositionComments } from "../architect/devis-positions/DevisPosition.helpers"
import { Domain } from "@smartdevis/server/src/domain"

const positionsGrid = [2, 3.7, 6, 2, 1, 3.5, 3.5, 1.3, 1]
const secondRoundGrid = [2, 3.7, 5, 2, 1, 2, 3, 3, 1.3, 1]
const finalRoundGrid = [2, 3, 4.7, 2, 1, 2, 2, 2.5, 2.5, 1.3, 1]

const getForMode = <T extends any>(mode: OfferRowMode, options: TMap<OfferRowMode, T>): T => options[mode]

const mkPostionHeaderRow = (mode: OfferRowMode) => (): Row<"header"> => ({
    mode: "static",
    type: "header",
    grid: getForMode(mode, { basic: positionsGrid, round: secondRoundGrid, final: finalRoundGrid }),
    rowId: "header",
    visuals: ["header"],
    noDrag: true,
    cells: [
        mkCell(i18n("{Number}No")),
        mkCell(i18n("Name")),
        mkCell(i18n("Description")),
        mkCell(i18n("Amount")),
        mkCell(i18n("Unit")),
        mkCell(i18n("Round 1 Unit Price")),
        ...(mode !== "basic" ? [mkCell(i18n("Round 2 Unit Price"))] : []),
        ...(mode === "final" ? [mkCell(i18n("Negotiation Unit Price"))] : []),
        mkCell(i18n("Price")),
        mkCell(i18n("Attach.{attachments on positions view}"))
    ]
})

type ValueForm = { value: number }

export const mkOfferRowBuilder = (
    offer: Domain.ContractorOffer,
    currentValues: Domain.OfferValues,
    readonly: boolean,
    onChange: F2<string, number>,
    comments: Pick<ReturnType<typeof usePositionComments>, "getPositionComments" | "openAttachmentModal">,
    errorIds: string[] = []
) => {
    const mode = getOfferRowMode(offer)

    const deltas = mkRequestDeltasByRef(offer)
    const changedIds = getChangedOffersInNegotiation(offer)
    const roundOffers = mkLatestOfferValues({ ...offer, finalOffer: {} })

    const mkInitialValueCell = (pos: Domain.Position) => {
        const price = offer.initialOffer?.[pos.positionId]
        return mkCell<ValueForm>(price ? formatSwiss(price) : "")
    }

    const mkRoundValueCell = (pos: Domain.Position, deleted: boolean) =>
        mkCell<ValueForm>(deleted ? "" : formatSwiss(roundOffers?.[pos.positionId] ?? 0))

    const mkPositionRow = (
        position: Domain.Position,
        attachmentsCell: Cell<ValueForm>,
        parentDelta?: Domain.RoundDelta
    ): Row<"sublevel", ValueForm> => {
        const delta = deltas?.[position.positionId]
        const deleted = parentDelta?.type === "remove" || delta?.type === "remove"
        return {
            mode: "form",
            type: "sublevel",
            formSchema: {
                value: mkFormattedNumberSchema(i18n("Price"), {
                    validators: nullableUFloat,
                    toValue: mkNullableFormattedToValue
                })
            },
            formValue: { value: currentValues[position.positionId] },
            rowId: position.positionId,
            visuals: [
                "noBorder",
                "whiteBackground",
                ...(mode === "final" ? [] : getRowVisualFromDelta(delta)),
                ...(errorIds.includes(position.positionId) ? (["error"] as const) : [])
            ],
            actionOnEnter: "submit",
            onChange: r => (isOk(r) ? onChange(position.positionId, r.value.value) : null),
            actionOnBlur: "submit",
            grid: getForMode(mode, { basic: positionsGrid, round: secondRoundGrid, final: finalRoundGrid }),
            cells: [
                mkCell(position.number ?? ""),
                mkCell(position.name),
                mkCell(position.description),
                position.type === "per"
                    ? mkCell(i18n("PER position"), ["italic"])
                    : mkCell(formatSwiss(position.amount)),
                mkCell(getUnitTranslation(position.unit)),
                ...(mode !== "basic" ? [mkInitialValueCell(position)] : []),
                ...(mode === "final" ? [mkRoundValueCell(position, deleted)] : []),
                mkCell(
                    deleted ? "" : displayNumber(currentValues[position.positionId]),
                    mode === "final" && !deleted && changedIds.includes(position.positionId) ? ["yellowBg"] : [],
                    readonly || deleted || mode === "final" ? undefined : { editMode: "formless", field: "value" }
                ),
                mkCell(
                    deleted
                        ? ""
                        : CHF(
                              mulSafeNumbers(
                                  currentValues[position.positionId],
                                  position.type === "per" ? toSafeNumber(1) : position.amount
                              )
                          ),
                    position.type === "per" ? ["italic", "greyText"] : []
                ),
                attachmentsCell,
                mkCommentsCell(
                    comments.getPositionComments(position.positionId).length,
                    () => comments.openAttachmentModal(position.positionId),
                    true
                )
            ]
        }
    }

    const mkSumRow = (allPositions: SMap<Domain.Position>): Row<"sum"> => {
        const finalPrice = sumPositionsPrices(allPositions, currentValues)
        return {
            mode: "static",
            type: "sum",
            rowId: "sum",
            visuals: ["paddingTop", "subtitle"],
            grid: getForMode(mode, { basic: positionsGrid, round: secondRoundGrid, final: finalRoundGrid }),
            cells: [
                mkCellEmpty(),
                mkCell(i18n("Sum"), ["bold"]),
                ...fillEmpty(mode === "final" ? 6 : mode === "round" ? 5 : 4),
                mkCell(CHF(finalPrice), ["bold"]),
                mkCellEmpty()
            ]
        }
    }

    return { mkHeader: mkPostionHeaderRow(mode), mkPosition: mkPositionRow, mkSum: mkSumRow }
}
