import * as React from "react"
import styled from "styled-components"
import { rem } from "./utils"
import { themeColor, ThemeColors } from "./utils/theme"

const tagTypes = ["removed", "added", "edited"] as const
export type TagType = typeof tagTypes[number]
const tagTypesColorMap: { [K in TagType]: keyof ThemeColors } = {
    added: "tagAdded",
    edited: "tagEdited",
    removed: "tagRemoved"
}

export type TagProps = { type?: TagType }

export const Tag: React.FC<TagProps> = ({ type = "edited", children }) => <TagBody type={type}>{children}</TagBody>

const TagBody = styled.div<Required<TagProps>>`
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;

    font-size: ${rem(14)};
    color: ${p => themeColor(tagTypesColorMap[p.type])};
`
