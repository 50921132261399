import * as React from "react"
import styled from "styled-components"
import { rem } from "./utils"
import { ArrowRight } from "./icons/arrow-right"
import { Download } from "./icons/download"
import { File } from "./icons/file"
import { FileText } from "./icons/file-text"
import { Plus } from "./icons/plus"
import { Minus } from "./icons/minus"
import { Info } from "./icons/info"
import { EmptyIcon } from "./icons/empty"
import { WarningIcon } from "./icons/warning"
import { Exclamation } from "./icons/exclamation"
import * as AssetsRaw from "./AssetsRaw"
import { themeColor, ThemeColors } from "./utils/theme"
import { ArrowDown } from "./icons/arrow-down"
import { ArrowDownSmall } from "./icons/arrow-down-small"
import { ArrowUp } from "./icons/arrow-up"
import { Eye } from "./icons/eye"
import { Revert } from "./icons/revert"
import { Contract } from "./icons/contract"
import { pickObject } from "@smartdevis/utils/src/map"
import { F1, TypedOmit } from "@smartdevis/utils/src/types"
import { FileTextPdf } from "./icons/file-text-pdf"

export type ImageAsset =
    | "ArrowRight"
    | "ArrowDown"
    | "ArrowDownSmall"
    | "ArrowUp"
    | "Download"
    | "File"
    | "FileText"
    | "FileTextPdf"
    | "Info"
    | "Plus"
    | "Minus"
    | "Eye"
    | "Empty"
    | "Revert"
    | "Contract"
    | "Warning"
    | "Exclamation"
    | keyof typeof AssetsRaw

export type CursorType = "pointer" | "auto" | "move"
type AssetSize = "icon" | "big-icon" | "auto" | "small-icon" | "micro" | "dropdown" | "mid-icon" | "huge-icon"
type AssetProps = {
    name: ImageAsset
    size: AssetSize
    cursor?: CursorType
    onClick?: F1<React.MouseEvent<HTMLDivElement>>
    color?: keyof ThemeColors
}

const getDimensions = (size: AssetSize) => {
    switch (size) {
        case "big-icon":
            return { width: rem(44), height: rem(44) }
        case "small-icon":
            return { width: rem(14), height: rem(14) }
        case "mid-icon":
            return { width: rem(30), height: rem(30) }
        case "huge-icon":
            return { width: rem(96), height: rem(96) }
        case "micro":
            return { width: rem(8), height: rem(8) }
        case "dropdown":
            return { width: rem(128), height: rem(26) }
        case "auto":
            return { width: "auto", height: "auto" }
        case "icon":
        default:
            return { width: rem(20), height: rem(20) }
    }
}

const AssetComponent: React.FC<{ asset: ImageAsset; size: AssetSize }> = p => {
    switch (p.asset) {
        case "ArrowRight":
            return <ArrowRight />
        case "ArrowDown":
            return <ArrowDown />
        case "Contract":
            return <Contract />
        case "Revert":
            return <Revert />
        case "ArrowDownSmall":
            return <ArrowDownSmall />
        case "ArrowUp":
            return <ArrowUp />
        case "Download":
            return <Download />
        case "File":
            return <File />
        case "FileText":
            return <FileText />
        case "FileTextPdf":
            return <FileTextPdf />
        case "Plus":
            return <Plus />
        case "Minus":
            return <Minus />
        case "Empty":
            return <EmptyIcon />
        case "Info":
            return <Info />
        case "Warning":
            return <WarningIcon />
        case "Eye":
            return <Eye />
        case "Exclamation":
            return <Exclamation {...getDimensions(p.size)} />
        default:
            return <img src={`/${AssetsRaw[p.asset]}`} alt={p.asset} />
    }
}

export const ExternalAsset: React.FC<TypedOmit<AssetProps, "name"> & { src: string; alt?: string }> = p => (
    <AssetContainer {...pickObject(p, ["size", "onClick", "color", "cursor"])}>
        <img src={p.src} alt={p.alt} />
    </AssetContainer>
)

export const Asset: React.FC<AssetProps & { style?: React.CSSProperties }> = p => (
    <AssetContainer {...pickObject(p, ["size", "onClick", "color", "cursor", "style"])}>
        <AssetComponent asset={p.name} size={p.size} />
    </AssetContainer>
)

export const AssetContainer = styled.div<Pick<AssetProps, "size" | "onClick" | "color" | "cursor">>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    width: ${p => getDimensions(p.size).width};
    height: ${p => getDimensions(p.size).height};
    transition: color 0.3s;
    color: ${p => themeColor(p.color || "black")};
    ${p => (!p.onClick ? "pointer-events: none;" : "")}
    ${p => (p.onClick || p.cursor ? `cursor: ${p.cursor ?? "pointer"};` : "")};
`

export const AssetStatusWrapper = styled.div<{ maxWidth?: number; listMode?: boolean }>`
    position: ${p => (p.listMode ? "relative" : "absolute")};
    bottom: ${p => (p.listMode ? "0px" : "24px")};
    display: flex;
    align-items: center;
    max-width: ${p => (p.maxWidth ? `${p.maxWidth}px` : "none")};

    ${AssetContainer} {
        flex: none;
        margin: 0 8px 0 -2px;
    }
`
