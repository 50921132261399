import * as React from "react"
import styled, { css } from "styled-components"
import { Domain } from "@smartdevis/server/src/domain"
import { asyncConnect } from "../../resolvers"
import { i18n } from "../../services/translations"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { Button, CheckboxButton, IconButton, TOGGLE_BUTTON_CONTAINER_CLASS } from "@smartdevis/ui/src/Button"
import {
    Flex,
    FlexColumn,
    FlexItem,
    FlexRow,
    GridContainer,
    HorizontalSpace,
    Spacer,
    styleIfProp,
    VerticalSpace
} from "@smartdevis/ui/src/utils/common"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { prepareMutation } from "../../utils/mutations"
import { Popover } from "@smartdevis/ui/src/Popover"
import { Popconfirm } from "@smartdevis/ui/src/Popconfirm"
import { Input } from "@smartdevis/ui/src/Inputs"
import { H2, H4, Label, P } from "@smartdevis/ui/src/Typography"
import { values, pickObject, SMap, filterObject, keys } from "@smartdevis/utils/src/map"
import { F1, F0 } from "@smartdevis/utils/src/types"
import { useArrayState } from "@smartdevis/ui/src/hooks/common"
import { themeColor, themeMedia } from "@smartdevis/ui/src/utils/theme"
import { ContractorForm } from "./ContractorForm"
import { intersection } from "@smartdevis/utils/src/array"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { useContractorSorting, ContactorSortingDropdown } from "../ContractorSortingDropdown"
import { useDebounce } from "@smartdevis/ui/src/hooks/useDebounce"

const StyledInput = styled(Input)`
    max-width: 200px;
    ${themeMedia("min", "xl")} {
        max-width: 300px;
    }
`

const Wrapper = styled.div`
    height: 100%;

    .${TOGGLE_BUTTON_CONTAINER_CLASS} {
        flex: 1;
    }
    ${Label} {
        width: 100%;
    }
`

const ContainerRight = styled(Flex)`
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 18px 0 0 36px;
    flex: 4;
`

const ContainerLeft = styled(Flex)`
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 18px 36px 0 0;
    flex: 6;
`

const flexCenter = css`
    display: flex;
    align-items: center;
`

const CenteredH2 = styled(H2)`
    height: 40px;
    ${flexCenter}
`

const ModalHeaderTitle = styled(H4)`
    ${flexCenter}
`

const CustomSpacer = styled(Spacer)`
    height: calc(100% + 48px);
`

const MAX_VISIBLE_TEXT_LENGTH = 40
const shortenVisibleText = (text: string, maxVisibleTextLength: number = MAX_VISIBLE_TEXT_LENGTH) =>
    text.length > maxVisibleTextLength ? text.slice(0, maxVisibleTextLength).concat("...") : text

type ContractorListProps = {
    selectedIds: string[]
    setSelectedIds: F1<F1<string[], string[]>>
    level: "project" | "devis"
    disabledIds?: string[]
}

export const ContractorModal = asyncConnect({
    stateResolvers: ["results", "contractors", "allDevis", "projectDetails"],
    actions: "mutate"
})<BaseModalProps & { loading: boolean } & ContractorListProps>(p => {
    const [formMode, setFormMode] = React.useState(false)
    const [contractorId, setContractorId] = React.useState<string | undefined>()
    const { remove } = prepareMutation("contractors", p.mutate)
    const allDevis: Domain.Devis[] = values(p.allDevis)

    return formMode ? (
        <ContractorForm
            results={p.results}
            mutate={p.mutate}
            visible={p.visible}
            contractor={contractorId ? p.contractors[contractorId] : undefined}
            onBack={() => {
                setFormMode(false)
                setContractorId(undefined)
            }}
        />
    ) : (
        <Modal
            header={
                <FlexRow>
                    <ModalHeaderTitle>{i18n("Submitters")}</ModalHeaderTitle>
                </FlexRow>
            }
            footer={
                <FlexRow justifyEnd>
                    <Button onClick={p.onClose} loading={p.loading}>
                        {i18n("Save and close")}
                    </Button>
                </FlexRow>
            }
            visible={p.visible}
            size="xl"
            height="800px">
            <Wrapper>
                <FlexRow style={{ height: "100%" }}>
                    <ContainerLeft>
                        <AvailableContractorsList
                            contractors={filterObject(p.contractors, cid => !p.selectedIds.includes(cid))}
                            onAddSubmitters={newIds =>
                                p.setSelectedIds(prev => prev.concat(newIds.filter(id => !prev.includes(id))))
                            }
                            allDevis={allDevis}
                            projectContractorIds={p.level === "devis" ? p.projectDetails.contractorIds || [] : []}
                            selectedIds={p.selectedIds}
                            disabledIds={p.disabledIds}
                            triggerCreate={() => setFormMode(true)}
                            onEdit={id => {
                                setContractorId(id)
                                setFormMode(true)
                            }}
                            onRemove={id => remove(p.contractors[id])()}
                        />
                    </ContainerLeft>
                    <CustomSpacer type="vertical" color="grey50" style={{ marginTop: "-24px" }} />
                    <ContainerRight>
                        <SelectedSubmittersList
                            level={p.level}
                            contractors={p.selectedIds.map(id => p.contractors[id])}
                            onRemoveSubmitters={newIds =>
                                p.setSelectedIds(prev => prev.filter(selectedId => !newIds.includes(selectedId)))
                            }
                            disabledIds={p.disabledIds}
                        />
                    </ContainerRight>
                </FlexRow>
            </Wrapper>
        </Modal>
    )
})

type SelectedSubmittersListProps = {
    contractors: Domain.Contractor[]
    level: "project" | "devis"
    onRemoveSubmitters: F1<string[]>
    disabledIds?: string[]
}

const SelectedSubmittersList: React.FC<SelectedSubmittersListProps> = p => {
    const { items: selectedIds, setItems: setSelectedIds, toggle } = useArrayState<string>([])
    const titleBase = i18n("Selected Submitters")
    return (
        <>
            <CenteredH2>
                {p.level === "devis" ? i18n("$1 in this devis", titleBase) : i18n("$1 in this project", titleBase)}
            </CenteredH2>
            <VerticalSpace base="36px" />
            <FlexItem direction="column" overflowY="scroll">
                <Join items={p.contractors} renderJoining={() => <VerticalSpace base="8px" />}>
                    {c => (
                        <FlexRow>
                            <CheckboxButton
                                key={c.contractorId}
                                label={
                                    <GridContainer gap="16px" columnsGrid={[1, 2]}>
                                        <Label>{shortenVisibleText(c.companyName)}</Label>
                                        <Label>{shortenVisibleText(c.email)}</Label>
                                    </GridContainer>
                                }
                                checked={selectedIds.includes(c.contractorId)}
                                onChange={() => toggle(c.contractorId)}
                                disabled={p.disabledIds?.includes(c.contractorId)}
                            />
                        </FlexRow>
                    )}
                </Join>
            </FlexItem>
            <FlexRow justifyEnd>
                <IconButton
                    icon="DashWhite"
                    onClick={() => {
                        p.onRemoveSubmitters(selectedIds)
                        setSelectedIds([])
                    }}
                    style={{ marginTop: "10px" }}
                    disabled={selectedIds.length <= 0}>
                    {i18n("Remove from submitters")}
                </IconButton>
            </FlexRow>
        </>
    )
}

const EmptySearchResult = styled(FlexRow)`
    overflow: scroll;
`

const AdditionalLabel = styled(Label)`
    display: none;
    ${themeMedia("min", "xl")} {
        display: inline;
    }
`

type SubmitterItemProps = {
    submitter: Domain.Contractor
    allDevis: Domain.Devis[]
    selectedIds: string[]
    checkedIds: string[]
    toggleId: F1<string>
    onEdit: F1<string>
    onRemove: F1<string>
    projectContractorIds: string[]
    disabledIds?: string[]
}

const SubmitterItem: React.FC<SubmitterItemProps> = p => {
    const { contractorId, ...rest } = p.submitter
    return (
        <FlexRow key={contractorId} spaceBetween>
            <CheckboxButton
                label={
                    <GridContainer gap="16px" columnsGrid={[1, 1.5, 1]}>
                        <Label>{shortenVisibleText(p.submitter.companyName, 20)}</Label>
                        <Label>{shortenVisibleText(p.submitter.email, 30)}</Label>
                        <AdditionalLabel>
                            {[rest.number, rest.workCategory, rest.postalCode, rest.city]
                                .filter((v): v is string => !isEmpty(v))
                                .map(item => shortenVisibleText(item))
                                .join(", ")}
                        </AdditionalLabel>
                    </GridContainer>
                }
                checked={p.checkedIds.includes(p.submitter.contractorId)}
                onChange={() => p.toggleId(p.submitter.contractorId)}
                disabled={p.disabledIds?.includes(p.submitter.contractorId) || p.selectedIds.includes(contractorId)}
            />
            <HorizontalSpace base="8px" />
            <FlexRow alignCenter>
                <IconButton icon="Edit" onClick={() => p.onEdit(contractorId)} />
                <HorizontalSpace base="8px" />
                {p.allDevis.some(d => d.contractorIds.includes(contractorId)) ||
                p.projectContractorIds.includes(contractorId) ||
                p.selectedIds.includes(contractorId) ? (
                    <Popover
                        direction="bottom"
                        content={i18n("This contractor is in some offers and cannot be deleted")}>
                        <IconButton disabled icon="Delete" />
                    </Popover>
                ) : (
                    <Popconfirm
                        direction="left"
                        title={i18n("This will remove the contractor from all your projects. Proceed?")}
                        onConfirm={() => p.onRemove(contractorId)}>
                        <IconButton icon="Delete" />
                    </Popconfirm>
                )}
            </FlexRow>
        </FlexRow>
    )
}

const ContactsContainer = styled(FlexColumn)<{ isHighlighted?: boolean }>`
    ${p => styleIfProp("isHighlighted", `background-color: ${themeColor("grey35")(p)};`)}
    ${styleIfProp("isHighlighted", `padding: 10px 12px;`, `padding: 0 12px;`)}
    border-radius: 16px;
`

const AvailableContractorsList: React.FC<{
    contractors: SMap<Domain.Contractor>
    allDevis: Domain.Devis[]
    projectContractorIds: string[]
    onAddSubmitters: F1<string[]>
    selectedIds: string[]
    triggerCreate: F0
    onEdit: F1<string>
    onRemove: F1<string>
    disabledIds?: string[]
}> = p => {
    const { items: checkedIds, setItems: setCheckedIds, toggle: toggleId } = useArrayState<string>([])
    const [searchText, setSearchText] = React.useState("")
    const { run } = useDebounce(setSearchText, 300)
    const submitters = filterObject(p.contractors, (_, c) =>
        searchText
            ? JSON.stringify(pickObject(c, ["name", "surname", "email", "companyName", "workCategory", "number"]))
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            : true
    )
    const notSelectedProjectContractorIds = p.projectContractorIds.filter(id => !p.selectedIds.includes(id))
    const highlightedSubmitters = intersection(keys(submitters), notSelectedProjectContractorIds).map(
        id => p.contractors[id]
    )
    const nonHighlightedSubmitters = keys(submitters)
        .filter(id => !notSelectedProjectContractorIds.includes(id))
        .map(id => p.contractors[id])

    const { sortedContractors: sortedHighlighted, onSelect: onHSelect } = useContractorSorting(highlightedSubmitters)
    const {
        sortedContractors: sortedNonHighlighted,
        onSelect: onNSelect,
        ...dropdownProps
    } = useContractorSorting(nonHighlightedSubmitters)

    return (
        <FlexColumn style={{ height: "100%" }}>
            <GridContainer columnsGrid={[2, 2, 1]} gap="24px">
                <StyledInput placeholder={i18n("Search...")} onChange={v => run(v.target.value)} />
                <ContactorSortingDropdown
                    {...dropdownProps}
                    onSelect={(k, d) => {
                        onHSelect(k, d)
                        onNSelect(k, d)
                    }}
                />
                <Button btnType="secondary" onClick={p.triggerCreate}>
                    {i18n("New contact")}
                </Button>
            </GridContainer>
            <VerticalSpace base="36px" />
            <FlexItem direction="column" overflow="scroll">
                {keys(submitters).length > 0 ? (
                    <>
                        {sortedHighlighted.length > 0 ? (
                            <ContactsContainer isHighlighted={true}>
                                <P small>{i18n("Selected for this project")}</P>
                                <VerticalSpace base="8px" />
                                <Join items={sortedHighlighted} renderJoining={() => <VerticalSpace base="8px" />}>
                                    {submitter => (
                                        <SubmitterItem
                                            submitter={submitter}
                                            allDevis={p.allDevis}
                                            selectedIds={p.selectedIds}
                                            checkedIds={checkedIds}
                                            toggleId={toggleId}
                                            onEdit={p.onEdit}
                                            onRemove={p.onRemove}
                                            projectContractorIds={p.projectContractorIds}
                                            disabledIds={p.disabledIds}
                                        />
                                    )}
                                </Join>
                            </ContactsContainer>
                        ) : null}
                        <ContactsContainer>
                            <Join items={sortedNonHighlighted} renderJoining={() => <VerticalSpace base="8px" />}>
                                {submitter => (
                                    <SubmitterItem
                                        submitter={submitter}
                                        allDevis={p.allDevis}
                                        selectedIds={p.selectedIds}
                                        checkedIds={checkedIds}
                                        toggleId={toggleId}
                                        onEdit={p.onEdit}
                                        onRemove={p.onRemove}
                                        projectContractorIds={p.projectContractorIds}
                                        disabledIds={p.disabledIds}
                                    />
                                )}
                            </Join>
                        </ContactsContainer>
                    </>
                ) : (
                    <EmptySearchResult>
                        <Label>
                            {searchText
                                ? i18n("There are no contacts matching your search")
                                : i18n("Add contacts if you wish to select submitters")}
                        </Label>
                    </EmptySearchResult>
                )}
            </FlexItem>
            <FlexRow justifyEnd>
                <IconButton
                    icon="CrossWhite"
                    onClick={() => {
                        p.onAddSubmitters(checkedIds)
                        setCheckedIds([])
                    }}
                    style={{ marginTop: "10px" }}
                    disabled={checkedIds.length <= 0}>
                    {i18n("Add to submitters")}
                </IconButton>
            </FlexRow>
        </FlexColumn>
    )
}
