import * as React from "react"
import styled from "styled-components"
import { Prompt } from "react-router-dom"
import { AdditionalInformationList } from "./lists/AdditionalInformationList"
import { asyncConnect } from "../../resolvers"
import { AttachmentsList } from "./lists/AttachmentsList"
import { ConditionsList } from "./lists/ConditionsList"
import { DevisWrapper } from "./Devis"
import { ExportConditions } from "../../components/export/ExportButtons"
import { filterObject, pickObject, toMap } from "@smartdevis/utils/src/map"
import { FlexRow, Flex, Margin, Spacer } from "@smartdevis/ui/src/utils/common"
import { GeneralInformationList } from "./lists/GeneralInformationList"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { H4 } from "@smartdevis/ui/src/Typography"
import {
    hasSecondRound,
    useMutationManagement,
    getStepExitWarningText
} from "@smartdevis/client/src/utils/devisHelpers"
import { i18n } from "@smartdevis/client/src/services/translations"
import { identity, _noop } from "@smartdevis/utils/src/misc"
import { mkConditions, mkDeltasByRef, mkDevisAdditionalInformation } from "@smartdevis/server/src/models/shared"
import { PageHeader } from "../../components/layouts/Header"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { RouteParams } from "../../paths"
import { SubContent } from "../../components/layouts/Content"
import { useAttachmentsManagement } from "../../components/Attachments"
import { useUnsavedStatusAsParent } from "../UnsavedStatusProvider"

export const DevisConditions = asyncConnect({
    stateResolvers: [
        "projectDetails",
        "projectAttachments",
        "devisCollections",
        "devis",
        "user",
        "isDevisReadonly",
        "uploadingFiles"
    ],
    actions: ["mutate", "submitRoundDelta", "revertRoundDelta", "uploadFile", "removeAttachment"]
})<RouteParams>(p => {
    const { devis } = p
    const isReadonly = p.isDevisReadonly || hasSecondRound(p.devisCollections, devis.devisId)
    const deltas = mkDeltasByRef(p.devisCollections)

    const conditions = mkConditions(p.devisCollections, deltas)
    const conditionsMap = toMap(conditions, c => c.conditionId, identity)
    const conditionAttachments = useAttachmentsManagement("condition", p)
    const conditionMutations = useMutationManagement("conditions", conditionsMap, p)

    const additionalInformation = mkDevisAdditionalInformation(p.devisCollections, deltas)
    const additionalInformationMap = toMap(additionalInformation, ai => ai.informationId, identity)
    const additionalInformationMutations = useMutationManagement("additionalInformation", additionalInformationMap, p)

    const generalMutations = prepareMutation("generalInformation", p.mutate, {
        type: "devis",
        projectId: p.projectDetails.projectId,
        devisId: p.devis.devisId
    })
    const generalAttachments = useAttachmentsManagement("general", p)

    const unsaved = useUnsavedStatusAsParent()

    return (
        <DevisWrapper current="conditions" {...pickObject(p, ["match"])}>
            <Prompt when={unsaved.check()} message={getStepExitWarningText()} />
            <SubContent>
                <PageHeader
                    title={i18n("Conditions")}
                    subTitle="Erfassen Sie allgemeine und spezifische Bedingungen, die der Unternehmer bestätigen muss. Ausserdem können Sie hier Anhänge (z.B. Pläne) hochladen."
                    actionButtons={[
                        <ExportConditions
                            key="export"
                            projectId={p.projectDetails.projectId}
                            devisId={p.devis.devisId}
                        />
                    ]}
                />
                <Spacer color="grey50" type="horizontal" />
                <FlexRow>
                    <Left70>
                        <Margin values="16px 0">
                            <H4>{i18n("Prefix")}</H4>
                        </Margin>
                        <GeneralInformationList
                            {...generalMutations}
                            isReadonly={isReadonly}
                            items={filterObject(
                                p.devisCollections.generalInformation,
                                (_, c) => c.devisId === p.devis.devisId
                            )}
                            mkItem={order => ({
                                informationId: genTemporaryId(),
                                value: "",
                                createdTs: getTs(),
                                devisId: p.devis.devisId,
                                order
                            })}
                        />
                        <Margin values="48px 0 16px">
                            <H4>{i18n("Special provisions")}</H4>
                        </Margin>
                        <ConditionsList
                            isReadonly={isReadonly}
                            conditions={conditionsMap}
                            mutations={conditionMutations}
                            mkItem={order => ({
                                conditionId: genTemporaryId(),
                                createdTs: getTs(),
                                description: "",
                                devisId: p.devis.devisId,
                                order,
                                name: ""
                            })}
                            attachments={conditionAttachments}
                        />
                        <Margin values="48px 0 16px">
                            <H4>{i18n("Additional information")}</H4>
                        </Margin>
                        <AdditionalInformationList
                            isReadonly={isReadonly}
                            deltas={deltas}
                            items={additionalInformationMap}
                            mutations={additionalInformationMutations}
                            mkItem={order => ({
                                informationId: genTemporaryId(),
                                createdTs: getTs(),
                                description: "",
                                devisId: p.devis.devisId,
                                order,
                                title: "",
                                value: ""
                            })}
                        />
                    </Left70>
                    <Spacer color="grey50" type="vertical" />
                    <Right30>
                        <Margin values="16px 0">
                            <H4>{i18n("Documents upload")}</H4>
                        </Margin>
                        <AttachmentsList
                            attachments={generalAttachments}
                            isReadonly={isReadonly}
                            refId={p.devis.devisId}
                        />
                    </Right30>
                </FlexRow>
            </SubContent>
        </DevisWrapper>
    )
})

export const Right30 = styled(Flex)`
    width: 100%;
    flex-direction: column;
    padding: 24px 0 0 48px;
    flex: 3;
`

export const Left70 = styled(Flex)`
    width: 100%;
    flex-direction: column;
    padding: 24px 48px 0 0;
    flex: 7;
`
