import * as React from "react"
import styled from "styled-components"
import { IconButton } from "@smartdevis/ui/src/Button"
import { ExternalLink } from "@smartdevis/ui/src/Typography"
import { FlexColumn, FlexRow } from "@smartdevis/ui/src/utils/common"
import { getStorage } from "@smartdevis/client/src/services/firebase"
import { i18n } from "@smartdevis/client/src/services/translations"
import { AttachmentsManagement, AttachmentsModal } from "../../../components/Attachments"
import { values } from "@smartdevis/utils/src/map"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { Asset } from "@smartdevis/ui/src/Asset"

export const AttachmentsList: React.FC<{
    attachments: AttachmentsManagement
    refId: string
    isReadonly: boolean
}> = p => {
    const filteredAttachments = values(p.attachments.items).filter(
        v => v.refId === p.refId && v.type.toLowerCase() === p.attachments.type.toLowerCase()
    )
    const [attachmentsModalOpen, setAttachmentsModalOpen] = React.useState(false)

    return (
        <AttachmentsContainer hasItems={filteredAttachments.length > 0}>
            {filteredAttachments.map(a => (
                <ItemContainer key={a.attachmentId}>
                    <ExternalLink href={getStorage().getFileUrl(a.path)} target="_blank" download>
                        <FlexRow spaceBetween alignCenter>
                            {a.name}
                            <Asset name="File" color="action" size="icon" />
                        </FlexRow>
                    </ExternalLink>
                </ItemContainer>
            ))}
            {!p.isReadonly && (
                <IconButton
                    icon="CrossWhite"
                    style={{ marginTop: "16px" }}
                    onClick={() => setAttachmentsModalOpen(true)}>
                    {i18n("Select files")}
                </IconButton>
            )}
            <AttachmentsModal
                visible={attachmentsModalOpen}
                isReadonly={p.isReadonly}
                onClose={() => setAttachmentsModalOpen(false)}
                refId={p.refId}
                {...p.attachments}
            />
        </AttachmentsContainer>
    )
}

const AttachmentsContainer = styled.div<{ hasItems: boolean }>`
    flex: 1;
    background-color: ${p => (p.hasItems ? themeColor("grey35") : "transparent")};
    border-radius: 8px;
    padding: 16px;
`

const ItemContainer = styled(FlexColumn)`
    padding: 16px 0;
    border-bottom: 1px solid ${themeColor("grey50")};

    :first-of-type {
        padding-top: 0;
    }

    :last-of-type {
        padding-bottom: 0;
        border: 0;
    }
`
