import * as React from "react"
import * as Sentry from "@sentry/react"

import { ThemeProvider as StyleComponentsThemeProvider } from "styled-components"
import { ModalProvider } from "@smartdevis/ui/src/Modal"

import { NotificationsProvider } from "./NotificationsProvider"
import { defaultTheme } from "@smartdevis/ui/src/utils/theme"
import { GlobalStyle } from "@smartdevis/ui/src/utils/globalStyle"
import { AppLayoutContainer } from "../components/layouts/AppLayout"
import { ErrorView } from "./Error"
import { getProcessEnv, isLocal, isProd } from "../utils/envHelpers"
import { EditUserProvider } from "./EditUserProvider"
import { EmptyObject } from "@smartdevis/utils/src/types"
import { UnsavedStatusProvider } from "./UnsavedStatusProvider"
import { AuthProvider } from "./AuthProvider"

let sentryInited = false
const init3rdParty = () => {
    try {
        if (!sentryInited && isProd() && getProcessEnv().sentry_url) {
            Sentry.init({ dsn: getProcessEnv().sentry_url })
            sentryInited = true
        }

        if (isLocal()) {
            import("../mocks/browser").then(({ worker }) => {
                worker.start({
                    onUnhandledRequest: "bypass"
                })
            })
        }
    } catch {}
}

class ErrorBoundary extends React.Component<EmptyObject, { error: any | null }> {
    constructor(props: any) {
        super(props)
        this.state = { error: null }
        init3rdParty()
    }

    static getDerivedStateFromError() {
        return { error: { message: "unknown" } }
    }

    componentDidCatch(error: any, errorInfo: any) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo)
        this.setState({ error })
        Sentry.captureException(error, errorInfo)
    }

    render() {
        return this.state.error ? (
            <AppLayoutContainer>
                <ErrorView text={this.state.error.message} />
            </AppLayoutContainer>
        ) : (
            this.props.children
        )
    }
}

export const ViewProvider: React.FC = p => (
    <ModalProvider>
        <StyleComponentsThemeProvider theme={defaultTheme}>
            <ErrorBoundary>
                <AuthProvider>
                    <NotificationsProvider>
                        <UnsavedStatusProvider>
                            <EditUserProvider>{p.children}</EditUserProvider>
                        </UnsavedStatusProvider>
                    </NotificationsProvider>
                </AuthProvider>
                <GlobalStyle />
            </ErrorBoundary>
        </StyleComponentsThemeProvider>
    </ModalProvider>
)
