import { Domain } from "@smartdevis/server/src/domain"
import { Async, isFetched, mkFetched, mkFetching } from "@smartdevis/utils/src/async"
import { remap } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { decodeTranslationKey } from "./translations"
import { translations as hardcodedTranslations } from "../translations"

let _translations: Async<Domain.Translations> = { type: "NotFetched" }

export const getRawTranslations = () =>
    isFetched(_translations) ? mkFetched({ ...hardcodedTranslations, ..._translations.value }) : mkFetching()

export const getTranslations = () =>
    isFetched(_translations) ? { ...hardcodedTranslations, ..._translations.value } : hardcodedTranslations

export const setTranslations = (v: Async<Domain.Translations>) => {
    _translations = isFetched(v) ? mkFetched(remap(v.value, decodeTranslationKey, identity)) : v
}
