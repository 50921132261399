import * as React from "react"
import styled from "styled-components"
import { Link } from "../../components"
import { i18n } from "@smartdevis/client/src/services/translations"
import { ErrorView } from "../Error"
import { projectPaths, appPaths } from "../../paths"
import {
    getStyledAddressInfoSchema,
    getProjectPersonaTranslation,
    useProjectSubforms,
    getStyledDisplayProjectSchema,
    mkProjectStaticSchema,
    sortFormsByCreatedTs
} from "./ProjectEdit.helpers"
import { errors } from "@smartdevis/utils/src/validators"
import { asyncConnect } from "../../resolvers"
import { StyledForm } from "../../components/forms"
import { PageHeader } from "../../components/layouts/Header"
import { Button } from "@smartdevis/ui/src/Button"
import { StaticTextRenderer, StaticNumberRenderer, StaticOptionRenderer } from "../../components/forms/formRenderers"
import { FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Accordion, AccordionPanel, useAccordion } from "@smartdevis/ui/src/Accordion"
import { useFormHook } from "@smartdevis/forms/src"
import { ProjectConditionsModal } from "./ProjectConditions"
import { ProjectDeductionsModal } from "./ProjectDeductions"
import { ProjectSubmittersModal } from "./ProjectSubmitters"
import { _noop } from "@smartdevis/utils/src/misc"
import { values } from "@smartdevis/utils/src/map"
import { ProjectTile } from "../../components/tiles/ProjectTile"
import { ExportProjectSubmitters } from "../../components/export/ExportButtons"

export const TagWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
`

const NoSuchProjectView = () => (
    <ErrorView
        type="not-found"
        text="No such project found"
        subText="Go to the projects list in order to view all available projects:"
        renderAction={() => (
            <Link to={appPaths.home}>
                <Button>{i18n("View projects")}</Button>
            </Link>
        )}
    />
)

export const OverviewProjectView = asyncConnect({
    stateResolvers: ["projectDetails", "projectPredefinedCollections", "contractors", "projectDevis"],
    renderError: err => (err === errors.notFound ? <NoSuchProjectView /> : <ErrorView />)
})(p => {
    const { deductions, conditions } = p.projectPredefinedCollections
    const [selected, onChange] = useAccordion([], { multiple: true })
    const [conditionsOpen, setConditionsOpen] = React.useState(false)
    const [deductionsOpen, setDeductionsOpen] = React.useState(false)
    const [submittersOpen, setSubmittersOpen] = React.useState(false)

    const { formViewProps } = useFormHook({
        schema: mkProjectStaticSchema(),
        initialValue: { ...p.projectDetails }
    })

    const { forms } = useProjectSubforms(p.projectDetails, true)
    return (
        <>
            <PageHeader
                title={p.projectDetails.name}
                actionButtons={[
                    <Link key="2" to={projectPaths.editProject} params={{ projectId: p.projectDetails.projectId }}>
                        <Button btnType="action" data-cy="projects-create-button">
                            {i18n("Edit")}
                        </Button>
                    </Link>,
                    <ExportProjectSubmitters key="export" projectId={p.projectDetails.projectId} />
                ]}
            />
            <StyledForm
                {...formViewProps}
                inputsRenderMap={{
                    text: StaticTextRenderer,
                    number: StaticNumberRenderer,
                    select: StaticOptionRenderer,
                    customBox: StaticNumberRenderer
                }}
                styledSchema={getStyledDisplayProjectSchema()}
                styledInputsRenderMap={{
                    Custom: () => <HorizontalSpace base="16px" />
                }}
            />
            <Accordion animationMs={300} onChange={onChange} selected={selected}>
                {forms.sort(sortFormsByCreatedTs).map(f => (
                    <AccordionPanel header={getProjectPersonaTranslation(f.key)} key={f.key}>
                        <StyledForm
                            {...f.form}
                            inputsRenderMap={{
                                text: StaticTextRenderer,
                                number: StaticNumberRenderer,
                                select: StaticOptionRenderer,
                                customBox: StaticNumberRenderer,
                                email: StaticTextRenderer
                            }}
                            styledSchema={getStyledAddressInfoSchema("", false)}
                        />
                    </AccordionPanel>
                ))}
            </Accordion>
            <ProjectConditionsModal visible={conditionsOpen} onClose={() => setConditionsOpen(false)} />
            <ProjectDeductionsModal visible={deductionsOpen} onClose={() => setDeductionsOpen(false)} />
            <ProjectSubmittersModal visible={submittersOpen} onClose={() => setSubmittersOpen(false)} />
            <FlexRow spaceBetween style={{ width: "100%" }}>
                <ProjectTile
                    title={i18n("Deductions")}
                    addedItems={values(deductions).map(d => ({
                        label: d.name,
                        value: ""
                    }))}
                    displayItems={false}
                    hasFixedSize={false}
                    onClick={() => setDeductionsOpen(true)}
                />
                <HorizontalSpace base="24px" />
                <ProjectTile
                    title={i18n("Conditions")}
                    addedItems={values(conditions).map(c => ({
                        label: c.name,
                        value: ""
                    }))}
                    displayItems={false}
                    hasFixedSize={false}
                    onClick={() => setConditionsOpen(true)}
                />
            </FlexRow>
            <VerticalSpace base="24px" />
            <ProjectTile
                title={i18n("Submitters")}
                addedItems={values(p.contractors)
                    .filter(
                        c =>
                            values(p.projectDevis)
                                .flatMap(pDevis => pDevis.contractorIds)
                                .includes(c.contractorId) || p.projectDetails.contractorIds?.includes(c.contractorId)
                    )
                    .map(c => ({
                        name: c.companyName,
                        email: c.email
                    }))}
                displayItems
                hasFixedSize={false}
                onClick={() => setSubmittersOpen(true)}
            />
        </>
    )
})
