import * as React from "react"
import { Domain } from "@smartdevis/server/src/domain"
import { devisPaths, RouteParams } from "../../../paths"
import { i18n } from "@smartdevis/client/src/services/translations"

import { getFullName, validateUserWithBusiness } from "@smartdevis/server/src/models/user"
import { asyncConnect } from "../../../resolvers"
import { DevisWrapper } from "../Devis"
import { PageHeader } from "../../../components/layouts/Header"
import { Row, Table } from "../../../components/table/Table"
import { fillEmpty, mkCell, mkCellEmpty, mkCheckboxCell } from "../../../components/table/TableCell"
import { Status } from "@smartdevis/ui/src/Status"
import { EstimateBlock } from "@smartdevis/ui/src/EstimateBlock"
import { mkAppUrls } from "@smartdevis/server/src/domainUtils"
import { isProd } from "@smartdevis/client/src/utils/envHelpers"
import { FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { DevisComparison } from "../devis-comparison/DevisComparison"
import { CalculatedDevis } from "@smartdevis/server/src/utils/money"
import { DevisOffersModals } from "./DevisOffers.modals"
import {
    getOfferHeaderButtons,
    getOfferStatusProps,
    useContractorInvitations,
    useOfferReminders,
    useOffersSubmitters,
    useOfferStateUpdate,
    usePreparedOffersData,
    useRoundCreator,
    useRoundSender
} from "./DevisOffers.helpers"
import { hasRequests } from "@smartdevis/client/src/utils/devisHelpers"
import { Button, IconButton } from "@smartdevis/ui/src/Button"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"
import { useUserEdit } from "../../EditUserProvider"
import { SubContent } from "../../../components/layouts/Content"
import { mkOfferSumCell } from "../devis-comparison/DevisComparison.rows"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import { ExportContractPreview, ExportOfferComparison } from "../../../components/export/ExportButtons"
import { Asset } from "@smartdevis/ui/src/Asset"
import { SMap, values } from "@smartdevis/utils/src/map"
import { identity, isDefined } from "@smartdevis/utils/src/misc"
import { isOk } from "@smartdevis/utils/src/result"
import { F0, State, F1 } from "@smartdevis/utils/src/types"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { CHF } from "@smartdevis/utils/src/numbers"
import { inArray } from "@smartdevis/utils/src/array"

const mkUrl = mkAppUrls(window.location.origin)

const getContractorLinks = (submitters: SMap<Domain.Contractor>, requests: SMap<Domain.ArchitectOfferRequest>) => {
    // eslint-disable-next-line no-console
    console.log("===== Contractor links:")
    if (!isProd()) {
        values(requests).forEach(o => {
            // eslint-disable-next-line no-console
            console.log(
                `contractor: ${submitters[o.submitterId].companyName}, url:` + mkUrl.mkContractorOfferRequestUrl(o)
            )
        })
    }
    // eslint-disable-next-line no-console
    console.log("===== Contractor links stop.")
}

const offerGrid = [1, 3.5, 2, 4, 3, 3, 3, 4.5]

type CheckProps = { checked: boolean; onCheck: F0; disabled: boolean }
const getHeader = (hasOffers: boolean, checkProps: CheckProps): Row<"header"> => {
    return {
        type: "header",
        mode: "static",
        visuals: ["header"],
        rowId: "header",
        cells: hasOffers
            ? [
                  mkCheckboxCell(checkProps.checked, checkProps.onCheck, checkProps.disabled),
                  mkCell(i18n("Contractor name")),
                  mkCell(""),
                  mkCell(i18n("Status")),
                  mkCell(i18n("Gross price")),
                  mkCell(i18n("Net price")),
                  mkCell(i18n("Net + VAT")),
                  mkCellEmpty()
              ]
            : [
                  mkCheckboxCell(checkProps.checked, checkProps.onCheck, checkProps.disabled),
                  mkCell(i18n("Contractor name")),
                  mkCell(i18n("Email"))
              ],
        grid: hasOffers ? offerGrid : [1, 11, 12]
    }
}

type RequestRowProps =
    | State<"new" | "not-invited">
    | State<"not-comparable" | "ready", { request: Domain.ArchitectOfferRequest }>

const getRequestRowType = (
    devis: Domain.DevisCollections,
    devisId: string,
    request?: Domain.ArchitectOfferRequest
): RequestRowProps => {
    if (!request) return hasRequests(devis, devisId) ? { type: "not-invited" } : { type: "new" }
    return inArray(request.state.type, ["submitted", "contracted", "next-round", "round-submitted", "final-proposal"])
        ? { type: "ready", request }
        : { type: "not-comparable", request }
}

const getRequestRow = (
    p: RequestRowProps & {
        submitter: Domain.Contractor
        calculationsByTime: SMap<CalculatedDevis>
        checkProps: CheckProps
        contractDisabled: boolean
        contractReady: boolean
        onComment: F1<string>
        onAttachment: F1<string>
        onContract: F0
        onFilePositions: F1<string>
        hasComments: boolean
        hasAttachments: boolean
        hasFilePositions: boolean
    }
): Row<"submitter"> => {
    const base = {
        type: "submitter",
        mode: "static",
        rowId: p.submitter.contractorId,
        grid: offerGrid
    } as const
    const check = mkCheckboxCell(p.checkProps.checked, p.checkProps.onCheck, p.checkProps.disabled)
    const name = mkCell(p.submitter.companyName || getFullName(p.submitter) || p.submitter.email)
    const mkStatus = (sp: ReturnType<typeof getOfferStatusProps>) => mkCell(<Status {...sp} />)
    switch (p.type) {
        case "ready":
            return {
                ...base,
                cells: [
                    check,
                    name,
                    mkCell(
                        <>
                            {p.hasAttachments && (
                                <Asset
                                    name="File"
                                    onClick={() => p.onAttachment(p.submitter.contractorId)}
                                    size="icon"
                                />
                            )}
                            {p.hasComments && (
                                <Asset
                                    style={{ marginLeft: "12px" }}
                                    name="Comment"
                                    onClick={() => p.onComment(p.submitter.contractorId)}
                                    size="icon"
                                />
                            )}
                        </>,
                        ["centerHorizontally"]
                    ),
                    mkStatus(getOfferStatusProps(p.request)),
                    mkOfferSumCell(p.calculationsByTime, { type: "gross" }),
                    mkOfferSumCell(p.calculationsByTime, { type: "net" }),
                    mkOfferSumCell(p.calculationsByTime, { type: "netincltax" }),
                    p.contractReady && p.contractDisabled
                        ? mkCellEmpty()
                        : mkCell(
                              <FlexRow justifyEnd>
                                  {p.hasFilePositions && (
                                      <IconButton
                                          icon="Download"
                                          onClick={() => p.onFilePositions(p.submitter.contractorId)}
                                          btnType="icon-only">
                                          {i18n("Open offer")}
                                      </IconButton>
                                  )}
                                  <HorizontalSpace base="16px" />
                                  <IconButton icon="Contract" disabled={p.contractDisabled} onClick={p.onContract}>
                                      {p.contractReady ? i18n("To contract") : i18n("Contract")}
                                  </IconButton>
                              </FlexRow>,
                              ["alignRight"]
                          )
                ]
            }
        case "not-comparable":
            return {
                ...base,
                cells: [check, name, mkCellEmpty(), mkStatus(getOfferStatusProps(p.request)), ...fillEmpty(4)]
            }
        case "not-invited":
            return { ...base, cells: [check, name, mkCellEmpty(), mkStatus(getOfferStatusProps()), ...fillEmpty(4)] }
        case "new":
            return { ...base, grid: [1, 11, 12], cells: [check, name, mkCell(p.submitter.email)] }
    }
}

const hasAnyComments = (o: Domain.ArchitectOfferRequest | undefined) =>
    o ? [o.comment, ...values(o.roundComments || {})].some(identity) : false

const hasAnyAttachments = (o: Domain.ArchitectOfferRequest | undefined) =>
    o ? [...values(o.offerAttachments), ...values(o.roundOfferAttachments)].some(identity) : false

const hasFilePositions = (o: Domain.ArchitectOfferRequest | undefined) => !!o?.offerProposal

export const DevisOffers = asyncConnect({
    stateResolvers: ["results", "projectDetails", "devisCollections", "user", "devis", "contractors"],
    actions: ["createRequests", "updateRequestsState", "navigate", "createRound", "sendRound", "sendReminders"]
})<RouteParams>(p => {
    const alertText = i18n("Please make sure your business details are filled out before send the offer request")
    const projectId = p.projectDetails.projectId
    const devisId = p.devis.devisId
    const [proposedContractor, setProposedContractor] = React.useState<string | null>(null)
    const [commentSubmitterId, setCommentSubmitterId] = React.useState<string | null>(null)
    const [attachmentsSubmitterId, setAttachmentSubmitterId] = React.useState<string | null>(null)
    const [filePositionsSubmitterId, setFilePositionsSubmitterId] = React.useState<string | null>(null)
    const [instructionsModalOpen, setInstructionsModalOpen] = React.useState(false)

    const data = usePreparedOffersData(p)
    const hasOffers = !isEmpty(values(data.requestsBySubmitter))
    const userBusinessValidated = isOk(validateUserWithBusiness(p.user))

    const submitters = useOffersSubmitters(data, userBusinessValidated)
    const { openEditUserModal } = useUserEdit()
    const { onInvite, invitationState } = useContractorInvitations(p, submitters.close.invitationModal)
    const { onSendReminders, remindersState } = useOfferReminders(p, submitters.close.reminderModal)

    const { onCreateRound, roundCreationState } = useRoundCreator(p, () => {
        submitters.close.secondRoundModal()
        if (p.devis.positionsFormat?.type !== "file-based") setInstructionsModalOpen(true)
    })
    const { onSendRound, roundSendState } = useRoundSender(p, submitters.close.sendRoundModal)

    const { onUpdateOfferState, updateActionState } = useOfferStateUpdate(p, () => {
        if (proposedContractor) {
            setProposedContractor(null)
            p.navigate(devisPaths.finalization, { projectId, devisId })
        }
        if (submitters.isOpen.negotiateModal) p.navigate(devisPaths.negotiation, { projectId, devisId })
        submitters.close.cancelModal()
        submitters.close.negotiateModal()
    })
    // Do not remove, dev purposes
    React.useMemo(() => getContractorLinks(p.contractors, data.requestsBySubmitter), [data.requestsBySubmitter])

    const allSubmittersChecked = submitters.checked.length === data.submitters.length
    const headerRow = getHeader(hasOffers, {
        checked: allSubmittersChecked,
        onCheck: () => submitters.set(allSubmittersChecked ? [] : data.submitters.map(s => s.contractorId)),
        disabled:
            isDefined(data.round) &&
            submitters.checked.length > 0 &&
            submitters.checked.every(s => isOfferInState(data.requestsBySubmitter[s], ["submitted"]))
    })

    const contractRequest = values(data.requestsBySubmitter).find(r =>
        isOfferInState(r, ["contracted", "final-proposal"])
    )
    const submitterRows = data.submitters.map(submitter => {
        const awaitingSecondRound =
            isDefined(data.round) && data.requestsBySubmitter[submitter.contractorId].state.type === "submitted"
        return getRequestRow({
            ...getRequestRowType(p.devisCollections, devisId, data.requestsBySubmitter[submitter.contractorId]),
            submitter,
            calculationsByTime: data.calculationsByTimeBySubmitter[submitter.contractorId],
            checkProps: {
                onCheck: () => submitters.toggle(submitter.contractorId),
                checked: submitters.checked.includes(submitter.contractorId),
                disabled: awaitingSecondRound
            },
            onComment: setCommentSubmitterId,
            onAttachment: setAttachmentSubmitterId,
            onFilePositions: setFilePositionsSubmitterId,
            contractDisabled:
                (isDefined(contractRequest) && contractRequest.submitterId !== submitter.contractorId) ||
                awaitingSecondRound,
            contractReady: isDefined(contractRequest),
            onContract: () =>
                contractRequest
                    ? p.navigate(devisPaths.finalization, { projectId, devisId })
                    : p.navigate(devisPaths.prefinalization, {
                          projectId,
                          devisId,
                          offerId: data.requestsBySubmitter[submitter.contractorId].offerId
                      }),
            hasComments: hasAnyComments(data.requestsBySubmitter[submitter.contractorId]),
            hasAttachments: hasAnyAttachments(data.requestsBySubmitter[submitter.contractorId]),
            hasFilePositions: hasFilePositions(data.requestsBySubmitter[submitter.contractorId])
        })
    })

    const selectedOfferIds = submitters.checked.map(s => (data.requestsBySubmitter[s] || {}).offerId).filter(identity)

    const vatLabel = p.devis.includeVat ? i18n("Incl. VAT") : i18n("Excl. VAT")
    return (
        <DevisWrapper current="offers" {...p}>
            <SubContent>
                <PageHeader
                    title={i18n("Offers")}
                    actionButtons={[
                        p.devis.costEstimateOriginal ? (
                            <EstimateBlock
                                label={i18n("Cost estimate (original)")}
                                price={CHF(p.devis.costEstimateOriginal)}
                                sublabel={vatLabel}
                                style={{ marginRight: "64px" }}
                                key="est-orig"
                            />
                        ) : null,
                        p.devis.costEstimateRevised ? (
                            <EstimateBlock
                                label={i18n("Cost estimate (revised)")}
                                price={CHF(p.devis.costEstimateRevised)}
                                sublabel={vatLabel}
                                style={{ marginRight: "144px" }}
                                key="est-rev"
                            />
                        ) : null,
                        <ExportContractPreview key="preview" projectId={projectId} devisId={devisId} />,
                        submitters.toCompare ? (
                            <ExportOfferComparison
                                key="export"
                                offersIds={submitters.toCompare.map(s => data.requestsBySubmitter[s].offerId)}
                                projectId={projectId}
                                devisId={devisId}
                            />
                        ) : null
                    ]}
                />
                {!userBusinessValidated && (
                    <InfoBox type="alert">
                        <FlexRow alignCenter justifyCenter>
                            {alertText}
                            <Button btnType="link" onClick={openEditUserModal}>
                                {i18n("You can edit your account here")}
                            </Button>
                        </FlexRow>
                    </InfoBox>
                )}

                <Table<"header" | "submitter"> rows={[headerRow, ...submitterRows]} />
                <VerticalSpace base="16px" />
                <FlexRow alignCenter>{getOfferHeaderButtons(submitters.buttons)}</FlexRow>
                <VerticalSpace base="32px" />
                {submitters.toCompare && (
                    <DevisComparison
                        {...data}
                        onClose={submitters.cancelCompare}
                        submitters={submitters.toCompare?.map(s => p.contractors[s])}
                    />
                )}

                <DevisOffersModals.ConfirmInvitation
                    devis={p.devis}
                    submitters={submitters.checked.map(s => p.contractors[s])}
                    onSubmit={(message, entryTs) => onInvite({ submittersIds: submitters.checked, message, entryTs })}
                    loading={invitationState.type === "Processing"}
                    visible={submitters.isOpen.invitationModal}
                    onClose={submitters.close.invitationModal}
                    hasOffers={hasOffers}
                />
                <DevisOffersModals.ConfirmReminder
                    devis={p.devis}
                    submitters={submitters.checked.map(s => p.contractors[s])}
                    onSubmit={msg => onSendReminders(selectedOfferIds, msg)}
                    loading={remindersState.type === "Processing"}
                    visible={submitters.isOpen.reminderModal}
                    onClose={submitters.close.reminderModal}
                />
                <DevisOffersModals.CancelOfferRequest
                    onClose={submitters.close.cancelModal}
                    loading={updateActionState.type === "Processing"}
                    visible={submitters.isOpen.cancelModal}
                    submitters={submitters.checked.map(s => p.contractors[s])}
                    onSubmit={reason =>
                        onUpdateOfferState({
                            offersIds: submitters.checked.map(s => data.requestsBySubmitter[s].offerId),
                            state: { type: "cancelled", reason }
                        })
                    }
                />
                <DevisOffersModals.DisplayComment
                    onClose={() => setCommentSubmitterId(null)}
                    visible={isDefined(commentSubmitterId)}
                    request={commentSubmitterId ? data.requestsBySubmitter[commentSubmitterId] : undefined}
                    submitter={commentSubmitterId ? p.contractors[commentSubmitterId] : undefined}
                    devisCollections={p.devisCollections}
                />
                <DevisOffersModals.DisplayOfferAttachments
                    onClose={() => setAttachmentSubmitterId(null)}
                    visible={isDefined(attachmentsSubmitterId)}
                    request={attachmentsSubmitterId ? data.requestsBySubmitter[attachmentsSubmitterId] : undefined}
                    submitter={attachmentsSubmitterId ? p.contractors[attachmentsSubmitterId] : undefined}
                />
                <DevisOffersModals.DisplayDevisPositionsFiles
                    onClose={() => setFilePositionsSubmitterId(null)}
                    visible={isDefined(filePositionsSubmitterId)}
                    request={filePositionsSubmitterId ? data.requestsBySubmitter[filePositionsSubmitterId] : undefined}
                    submitter={filePositionsSubmitterId ? p.contractors[filePositionsSubmitterId] : undefined}
                />
                <DevisOffersModals.StartSecondRound
                    submitters={submitters.checked.map(s => p.contractors[s])}
                    visible={submitters.isOpen.secondRoundModal}
                    onClose={submitters.close.secondRoundModal}
                    loading={roundCreationState.type === "Processing"}
                    onSubmit={() => onCreateRound(selectedOfferIds)}
                />
                <DevisOffersModals.ConfirmSendingRound
                    visible={submitters.isOpen.sendRoundModal}
                    onClose={submitters.close.sendRoundModal}
                    devisEntryDate={p.devis.entryTs}
                    loading={roundSendState.type === "Processing"}
                    onSubmit={onSendRound}
                    submitters={data.submitters}
                />
                <DevisOffersModals.SecondRoundInstructions
                    visible={instructionsModalOpen}
                    devisName={p.devis.workCategory}
                    onClose={() => {
                        setInstructionsModalOpen(false)
                        p.navigate(devisPaths.positions, { projectId, devisId })
                    }}
                />
                <DevisOffersModals.ConfirmNegotiation
                    submitters={submitters.checked.map(s => p.contractors[s])}
                    visible={submitters.isOpen.negotiateModal}
                    onClose={submitters.close.negotiateModal}
                    loading={updateActionState.type === "Processing"}
                    onSubmit={() =>
                        onUpdateOfferState({
                            offersIds: submitters.checked.map(s => data.requestsBySubmitter[s].offerId),
                            state: { type: "negotiation" }
                        })
                    }
                />
            </SubContent>
        </DevisWrapper>
    )
})
