import * as React from "react"
import styled from "styled-components"
import { devisPaths, DevisPath, RouteParams } from "../../paths"
import { Steps } from "@smartdevis/ui/src/Steps"
import { ArchitectContent } from "../../components/layouts/Content"
import { i18n } from "@smartdevis/client/src/services/translations"
import { FlexColumn, FlexRow, Margin, sticky } from "@smartdevis/ui/src/utils/common"
import { themeColor, themeConfig } from "@smartdevis/ui/src/utils/theme"
import { asyncConnect } from "../../resolvers"
import { hasContract, hasNegotiations, hasSecondRound, isFinalizing } from "@smartdevis/client/src/utils/devisHelpers"
import { getProjectContentProps } from "./Project"
import { Tag } from "@smartdevis/ui/src/Tag"
import { Button } from "@smartdevis/ui/src/Button"
import { TMap, values } from "@smartdevis/utils/src/map"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { Asset } from "@smartdevis/ui/src/Asset"

type StepConfig = {
    path: DevisPath
    templatesNotAllowed?: boolean
}

const mkSchema = (withNegotiations?: boolean): Array<StepConfig> => [
    { path: "submitters" },
    { path: "conditions" },
    { path: "positions" },
    { path: "deductions" },
    { path: "offers", templatesNotAllowed: true },
    ...(withNegotiations ? [{ path: "negotiation", templatesNotAllowed: true } as const] : []),
    { path: "finalization", templatesNotAllowed: true }
]

export const StepsWrapper = styled.div`
    width: calc(100% + 20px);
    margin: 0 -10px;
    overflow: hidden;
    ${sticky};
    z-index: ${themeConfig("zIndexHeader")};
    background-color: ${themeColor("white")};
    padding: ${themeConfig("contentPadding")} 10px;
    top: ${themeConfig("headerHeight")};

    &::after {
        content: "";
        height: 1px;
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 0;
        background: #ededed;
    }
`

const ContentContainer = styled.div`
    position: relative;
`

const DevisContainer = styled.div`
    padding: 23px 0;
    margin-bottom: 64px;
`

const InfoWrapper = styled(StepsWrapper)`
    top: ${themeConfig("devisStepsWithHeaderHeight")};
    padding-top: 10px;
    padding-bottom: 10px;
`

type DevisWrapperProps = { current: DevisPath } & Pick<RouteParams, "match">

export const DevisWrapper = asyncConnect({
    stateResolvers: ["devisCollections", "devis", "projectDetails", "results"],
    actions: "navigate"
})<DevisWrapperProps>(({ devisCollections, devis, projectDetails, ...p }) => {
    const { devisId = "" } = p.match.params

    const devisNameTranslations: TMap<DevisPath, string> = {
        submitters: i18n("Submitters"),
        conditions: i18n("Conditions"),
        positions: i18n("Positions"),
        deductions: i18n("Deductions"),
        offers: i18n("Offers"),
        negotiation: i18n("Negotiation"),
        finalization: i18n("Finalization"),
        prefinalization: i18n("Finalization")
    }
    const negotiations = hasNegotiations(devisCollections, devisId)
    const schema = mkSchema(negotiations)
    const paths = schema.map(s => s.path)
    const currentStepIndex = paths.indexOf(p.current)

    const hasAtLeastOnePositionAndDeduction =
        !isEmpty(devis.contractorIds) &&
        (devis.positionsFormat?.type === "file-based" ||
            values(devisCollections.positions).some(pos => pos.devisId === devisId)) &&
        values(devisCollections.deductions).some(ded => ded.devisId === devisId)

    const isStepDisabled = (config: StepConfig) => {
        if (config.templatesNotAllowed && projectDetails.isTemplate) return false
        switch (config.path) {
            case "offers":
                return !hasAtLeastOnePositionAndDeduction
            case "negotiation":
                return !hasNegotiations(devisCollections, devisId)
            case "finalization":
                return !hasContract(devisCollections, devisId) && !isFinalizing(devisCollections, devisId)
        }
    }

    const negotiationOffers = values(devisCollections.requests).filter(
        r => r.devisId === devisId && r.statesHistory.find(h => h.state.type === "negotiation")
    )

    const negotiationOffer = negotiationOffers.length === 1 ? negotiationOffers[0] : null

    const backPath = currentStepIndex > 0 ? schema[currentStepIndex - 1].path : null
    const nextPath = currentStepIndex < schema.length - 1 ? schema[currentStepIndex + 1].path : null
    const beforeContract = negotiations && currentStepIndex === schema.findIndex(s => s.path === "negotiation")
    const isNextStepDisabled = nextPath ? isStepDisabled(schema[currentStepIndex + 1]) : false

    return (
        <ArchitectContent {...getProjectContentProps(projectDetails)} subTitle={devis.number}>
            <FlexColumn>
                {hasSecondRound(devisCollections, devis.devisId) && !hasNegotiations(devisCollections, devisId) ? (
                    <Margin values="8px auto 0">
                        <Tag>{i18n("Round 2")}</Tag>
                    </Margin>
                ) : null}
                <StepsWrapper>
                    <Steps
                        items={schema
                            .filter(step => !(projectDetails.isTemplate && step.templatesNotAllowed))
                            .map((k, i) => ({
                                current: currentStepIndex === i,
                                disabled: isStepDisabled(k),
                                onClick: () => !isStepDisabled(k) && p.navigate(devisPaths[k.path], p.match.params),
                                title: i18n(devisNameTranslations[k.path])
                            }))}
                    />
                </StepsWrapper>
                {!hasAtLeastOnePositionAndDeduction && (
                    <InfoWrapper>
                        <FlexRow justifyCenter>
                            {i18n(
                                "Offers tab will be accessible after picking at least one submitter and providing positions and deductions"
                            )}
                            <Asset name="Warning" size="icon" color="grey70" style={{ marginLeft: "12px" }} />
                        </FlexRow>
                    </InfoWrapper>
                )}
                <ContentContainer>
                    <DevisContainer>{p.children}</DevisContainer>
                </ContentContainer>
                <BottomContainer>
                    {backPath ? (
                        <Button
                            btnType="secondary"
                            onClick={() => p.navigate(devisPaths[backPath], { devisId, projectId: devis.projectId })}>
                            {i18n("Back")}
                        </Button>
                    ) : (
                        <div />
                    )}
                    {nextPath && !beforeContract && (
                        <Button
                            btnType="secondary"
                            onClick={() => p.navigate(devisPaths[nextPath], { devisId, projectId: devis.projectId })}
                            disabled={
                                projectDetails.isTemplate && p.current === "deductions" ? true : isNextStepDisabled
                            }>
                            {i18n("Next")}
                        </Button>
                    )}
                    {beforeContract && negotiationOffer && (
                        <Button
                            btnType="primary"
                            onClick={() =>
                                p.navigate(devisPaths["prefinalization"], {
                                    devisId,
                                    projectId: devis.projectId,
                                    offerId: negotiationOffer.offerId
                                })
                            }>
                            {i18n("Contract")}
                        </Button>
                    )}
                </BottomContainer>
            </FlexColumn>
        </ArchitectContent>
    )
})

const BottomContainer = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    width: calc(100% - 64px);
    padding: 0 max(calc((100% - 64px - ${themeConfig("maxWidth")}) / 2), 96px);
    justify-content: space-between;
    align-items: center;
    height: 100px;
    border-top: 1px solid #ededed;
    z-index: 100;
    background-color: white;
`
