import * as React from "react"
import { asyncConnect, AsyncConnectResults } from "../../resolvers"
import { RouteParams } from "../../paths"
import { SubContent } from "../../components/layouts/Content"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { SMap, toMap, values } from "@smartdevis/utils/src/map"
import { identity, isDefined } from "@smartdevis/utils/src/misc"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { useItemsMap } from "../../hooks/utilityHooks"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import {
    mkPositionSubsections,
    mkPositions,
    mkPositionSections,
    mkSublevels
} from "@smartdevis/server/src/models/shared"
import { PositionsList } from "./lists/PositionsList"
import { mkIncompletePosition } from "./devis-positions/DevisPosition.helpers"
import { mkNewSection } from "./DevisSection"
import { i18n } from "@smartdevis/client/src/services/translations"
import { PageHeader } from "../../components/layouts/Header"
import { useAttachmentsForDevisTemplate } from "../../components/Attachments"
import { projectCollectionsKeys } from "@smartdevis/server/src/constants"
import { Domain } from "@smartdevis/server/src/domain"
import { useUnsavedStatusAsChild } from "../UnsavedStatusProvider"

export const DevisTemplateView = asyncConnect({
    stateResolvers: [
        "devisTemplate",
        "devisTemplateCollections",
        "uploadingFiles",
        "predefinedWorkDescriptionTemplates",
        "auth",
        "user"
    ],
    actions: ["mutate", "uploadFile", "removeAttachment"]
})<RouteParams>(p => {
    const devisId = p.devisTemplate.templateId
    const sectionsArray = mkPositionSections(p.devisTemplateCollections)
    const sections = toMap(sectionsArray, s => s.sectionId, identity)
    const subsections = toMap(mkPositionSubsections(p.devisTemplateCollections), s => s.sectionId, identity)

    const positions = mkPositions(p.devisTemplateCollections)

    const attachments = useAttachmentsForDevisTemplate(p)

    const pm = useDevisTemplateMutationManagement("positions", positions, p)
    const sm = useDevisTemplateMutationManagement("sections", sections, p)
    const subsm = useDevisTemplateMutationManagement("sections", subsections, p)

    const sortedSections = mkPositionSections({ sections: sm.allItems })
    const sortedSublevels = mkSublevels({ sections: { ...sm.allItems, ...subsm.allItems }, positions: pm.allItems })

    const onAddPosition =
        (sectionId: string, delta: Partial<Domain.Position> = {}) =>
        () =>
            pm.setUnsavedItem({
                ...mkIncompletePosition(sectionId, devisId, sortedSublevels[sectionId]?.length || 0),
                ...delta
            } as Domain.Position)

    const onAddSection =
        (delta: Partial<Domain.Section> = {}) =>
        () =>
            sm.onItemSubmit({ ...mkNewSection(devisId, "position", values(sm.allItems).length) })(delta)

    const onAddSubsection =
        (delta: Partial<Domain.Section> = {}) =>
        () =>
            subsm.onItemSubmit({ ...mkNewSection(devisId, "position", values(subsm.allItems).length) })(delta)

    const isWDTemplateAdmin = p.auth.type === "Authenticated" && p.auth.value.type === "WD_MODERATOR"
    const isPWDTModal = values(p.predefinedWorkDescriptionTemplates).find(
        pt => pt.id.toString() === p.devisTemplate.templateId
    )

    return (
        <SubContent>
            <PageHeader
                title={
                    !isWDTemplateAdmin
                        ? i18n("Devis template") + " " + p.devisTemplate.workCategory
                        : i18n("Smart Devis template") + " " + p.devisTemplate.workCategory
                }
            />
            <PositionsList
                readonly={!isWDTemplateAdmin && isPWDTModal ? true : false}
                predefinedReadonly={!isWDTemplateAdmin && isPWDTModal ? true : false}
                devis={{ devisId: p.devisTemplate.templateId }}
                onAddPosition={onAddPosition}
                onAddSubsection={onAddSubsection}
                onAddSection={onAddSection}
                sortedSections={sortedSections}
                sortedSublevels={sortedSublevels}
                sm={sm}
                subsm={subsm}
                pm={pm}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                pushNotification={() => {}}
                deltas={undefined}
                attachments={attachments}
            />
        </SubContent>
    )
})

const useDevisTemplateMutationManagement = <
    C extends "positions" | "sections",
    T extends Domain.DevisTemplateCollectionItem<C>
>(
    collection: C,
    initialMap: SMap<T>,
    p: AsyncConnectResults<"devisTemplate", "mutate">
) => {
    const idKey = projectCollectionsKeys[collection] as keyof T
    const getId = (t: T) => t[idKey] as unknown as string
    const { items: unsaved, setItemByKey: setUnsavedItem, removeItem: removeUnsaved } = useItemsMap<T>({}, idKey)
    const allItems: SMap<T> = { ...initialMap, ...unsaved }

    const { update, remove, create } = prepareMutation<C, T>(collection, p.mutate, {
        type: "template",
        templateId: p.devisTemplate.templateId
    })

    const onItemRemove =
        (item: T, actionId = genTemporaryId()) =>
        () => {
            if (unsaved[getId(item)]) return removeUnsaved(getId(item))
            remove(item, actionId)()
        }

    const onRevertDelta = (_?: string) => undefined

    const onItemSubmit =
        (item: T, actionId = genTemporaryId()) =>
        (delta: Partial<T>) => {
            const payload: T = { ...item, ...delta }
            unsaved[getId(item)] ? create(payload, actionId) : update(item, actionId)(delta)
            removeUnsaved(getId(item))
        }

    const { setUnsaved } = useUnsavedStatusAsChild(`DevisTemplate${collection}`)
    React.useEffect(() => {
        setUnsaved(!isEmpty(unsaved))
    }, [isEmpty(unsaved)])

    return {
        onItemSubmit,
        onItemRemove,
        onRevertDelta,
        allItems,
        setUnsavedItem,
        isUnsaved: (id: string) => isDefined(unsaved[id]),
        isUnsavedMapEmpty: isEmpty(unsaved)
    }
}
