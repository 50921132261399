import * as React from "react"
import { Center } from "@smartdevis/ui/src/utils/common"
import { Asset } from "./Asset"
import { H3 } from "./Typography"

export const Warning: React.FC<{ title: string }> = p => (
    <Center>
        <Asset name="Warning" size="huge-icon" color="rank3Color" />
        <H3>{p.title}</H3>
    </Center>
)
