import {
    Content as _Content,
    ContentText as _ContentText,
    ContentTable as _ContentTable,
    ContentUnorderedList as _ContentUnorderedList,
    ContentColumns as _ContentColumns,
    ContentStack as _ContentStack,
    ContentCanvas as _ContentCanvas,
    DynamicContent,
    ContentImage as _ContentImage,
    TDocumentDefinitions as _TDocumentDefinitions,
    Column as _Column,
    Style as _Style,
    TableCell as _TableCell,
    Size
} from "pdfmake/interfaces"
import { defaultTheme } from "@smartdevis/ui/src/utils/theme"
import { i18n } from "../services/translations"
import { DataUrl } from "."
import { mkDocFooter } from "../services/doc/docUtils"
import { arrify } from "@smartdevis/utils/src/array"
import { Arrayable, TypedOmit, F0 } from "@smartdevis/utils/src/types"

type StyleName =
    | "fontLarge"
    | "fontMedium"
    | "fontSmall"
    | "verticalSpaceLarge"
    | "verticalSpaceMedium"
    | "alignRight"
    | "bold"
type Style = Arrayable<StyleName>
type Styled<T extends _Content> = T & { style?: Style }

interface ContentArray extends Array<Content> {}
type ContentObject =
    | ContentText
    | ContentTable
    | ContentUnorderedList
    | ContentColumns
    | ContentStack
    | ContentArray
    | ContentImage
    | ContentCanvas
export type Content = string | ContentObject

export type ContentBase = Exclude<Content, string | ContentArray>

type ContentText = Styled<_ContentText>
type ContentTable = Styled<_ContentTable>
type ContentUnorderedList = Styled<_ContentUnorderedList>
type ContentColumns = Styled<_ContentColumns>
type ContentStack = Styled<_ContentStack>
type ContentImage = Styled<_ContentImage>
type ContentCanvas = Styled<_ContentCanvas>

type Column = _Column & Content

export type TableCell = _TableCell & Content

export type TDocumentDefinitions = TypedOmit<_TDocumentDefinitions, "images"> & {
    content: Content
    images: Record<"logo", string>
    footer?: DynamicContent | Content
    header?: DynamicContent | Content
    styles?: Record<StyleName, _Style>
    maxPagesNumber?: number
}

const isContentArray = (content: any): content is ContentArray => Array.isArray(content)

const isContentBase = (content: any): content is ContentBase => !Array.isArray(content) && typeof content === "object"

const style = (content: Content, newStyle: Style = []): ContentObject => {
    if (isContentBase(content)) {
        return {
            ...content,
            style: [...arrify(content.style ?? []), ...arrify(newStyle)] as Style
        }
    }

    if (isContentArray(content)) {
        return content.map(c => style(c, newStyle))
    }

    if (typeof content === "string") {
        return style({ text: content }, newStyle)
    }

    return content
}

const styles: Record<StyleName, _Style> = {
    fontLarge: {
        fontSize: 16
    },
    fontMedium: {
        fontSize: 11
    },
    fontSmall: {
        fontSize: 9
    },
    verticalSpaceLarge: {
        margin: [0, 0, 0, 20]
    },
    verticalSpaceMedium: {
        margin: [0, 0, 0, 10]
    },
    alignRight: {
        alignment: "right"
    },
    bold: {
        bold: true
    }
}

export const doc = (headerContent: F0<Content>, content: Content, logo = ""): TDocumentDefinitions => ({
    content,
    styles,
    images: { logo },
    header: headerContent,
    footer: mkDocFooter(!!logo),
    defaultStyle: { fontSize: 9 },
    pageMargins: [40, 60, 40, 60],
    pageBreakBefore({ headlineLevel }, followingNodesOnPage) {
        const isPageBreaker = !!headlineLevel
        return isPageBreaker && followingNodesOnPage.length < 3
    }
})

export const contractDoc = (headerContent: F0<Content>, content: Content, logo = ""): TDocumentDefinitions => ({
    ...doc(headerContent, content, logo),
    header: currentPage => (currentPage === 1 ? " " : headerContent()),
    footer: (currentPage, pageCount) => (currentPage === 1 ? " " : mkDocFooter(!!logo)(currentPage, pageCount))
})

export const draftable = (d: TDocumentDefinitions, isDraft = false): TDocumentDefinitions =>
    isDraft
        ? {
              ...d,
              watermark: {
                  text: i18n("Draft, not to be used"),
                  color: defaultTheme.colors.error,
                  opacity: 0.3,
                  bold: true
              }
              //   maxPagesNumber: 1
          }
        : d

export const img = (
    src: keyof TDocumentDefinitions["images"] | DataUrl,
    options?: TypedOmit<ContentImage, "image">
) => ({ image: src, ...options })

export const h1 = (text: string) => style({ text }, ["fontLarge", "bold", "verticalSpaceLarge"])

export const h2 = (text: string) => style({ text, headlineLevel: 1 }, ["fontMedium", "bold", "verticalSpaceMedium"])

export const h2wSubtext = (text: string, subText: string): Content => [
    style({ text }, ["fontMedium", "bold"]),
    small(subText)
]

export const p = (content: Content) => style(content, "verticalSpaceMedium")

export const ul = (items: Content[]) => style({ ul: items })

export const small = (text: string) => style({ text }, "fontSmall")

export const br = (type: "medium" | "large" = "large") =>
    style({ text: "" }, type === "medium" ? "verticalSpaceMedium" : "verticalSpaceLarge")

export const pageBreaker = (content: ContentObject): Content => ({ ...content, headlineLevel: 1 })
export const pageBreakBr = (type: "medium" | "large" = "large"): Content => ({ ...br(type), pageBreak: "after" })

export const row = (columns: Column[], options: Partial<ContentColumns> = {}): ContentObject => ({
    columns,
    columnGap: 3,
    ...options
})

export const col = (
    content: Content,
    options?: {
        width: Size
    }
): Column => ({
    width: options?.width,
    stack: arrify(content)
})

export const table = (
    body: TableCell[][],
    options?: {
        widths?: "*" | "auto" | Size[]
        headerRows?: number
    }
) =>
    style(
        {
            table: {
                body,
                headerRows: options?.headerRows ?? 1,
                widths: options?.widths
            },
            layout: "lightHorizontalLines"
        },
        "verticalSpaceLarge"
    )

export const alignRight = (content: Content) => style(content, "alignRight")

export const bold = (content: Content) => style(content, "bold")

export const colSpan =
    (numCols: number) =>
    (content: Content): TableCell => {
        if (isContentBase(content)) {
            return {
                ...content,
                colSpan: numCols
            }
        }

        return colSpan(numCols)(style(content))
    }

export const lineSeparator = (lineWidth: number, margin?: [number, number, number, number]): Content => ({
    margin,
    canvas: [{ type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth }]
})
