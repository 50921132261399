import { F0, F1, State, ValueState } from "./types"

export type AsyncNotFetched = State<"NotFetched">
export type AsyncFetching = State<"Fetching">
export type AsyncFetched<T> = ValueState<"Fetched", T>
export type AsyncFetchError = ValueState<"FetchError", string>
export type Async<T> = AsyncNotFetched | AsyncFetching | AsyncFetched<T> | AsyncFetchError
export type FinishedAsync<T> = AsyncFetched<T> | AsyncFetchError
export type AsyncValue<T> = T extends Async<infer E> ? E : never
export type AsyncAction<T> = State<"NotStarted" | "Processing"> | ValueState<"Done", T>
export type WrappedAsync<T> = { [K in keyof T]: T[K] extends Async<infer V> ? V : T[K] }

export const mkFetched = <T>(value: T): AsyncFetched<T> => ({ type: "Fetched", value })
export const isFetched = <T>(v: Async<T> | undefined): v is AsyncFetched<T> => Boolean(v && v.type === "Fetched")

export const mkNotFetched = (): AsyncNotFetched => ({ type: "NotFetched" })

export const isNotFetched = (v: any): v is AsyncNotFetched => !v || v.type === "NotFetched"

export const mkFetching = (): AsyncFetching => ({ type: "Fetching" })
export const isFetching = (v: any): v is AsyncFetching => Boolean(v && v.type === "Fetching")

export const mkFetchError = (value: string): AsyncFetchError => ({ type: "FetchError", value })

export const isFetchError = (v: any): v is AsyncFetchError => Boolean(v && v.type === "FetchError")

export const getAsyncValue = <T, T2>(async: Async<T>, fallback: T2) =>
    async?.type === "Fetched" ? async.value : fallback

export const setPromiseTimeout = <T>(cb: F0<T>, ms: number): Promise<T> =>
    new Promise(async (res: F1<T>) => setTimeout(() => res(cb()), ms))

export const usleep = (ms = 0) => setPromiseTimeout(() => null, ms)

export const asyncForEach = async <T, S>(vs: T[], cb: (val: T, i: number) => Promise<S>, delay = 0) => {
    for (let i = 0; i < vs.length; i++) {
        await usleep(delay)
        await cb(vs[i], i)
    }
}

export const asyncMap = async <T, S>(vs: T[], cb: (val: T, i: number) => Promise<S>, delay = 0): Promise<S[]> => {
    const results = []
    for (let i = 0; i < vs.length; i++) {
        await usleep(delay)
        results.push(await cb(vs[i], i))
    }

    return results
}

export const asyncReduce = <T, T2>(vs: T[], cb: (acc: T2, v: T) => Promise<T2>, initalValue: T2) =>
    vs.reduce((_acc, v) => _acc.then(acc => cb(acc, v)), Promise.resolve(initalValue))
