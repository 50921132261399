import { Domain } from "./domain"
import { SMap } from "@smartdevis/utils/src/map"
import { TypedOmit, ArrayItem, FArgs, EmptyObject } from "@smartdevis/utils/src/types"
import { createEventAction, EventAction } from "@smartdevis/utils/src/actions"
import { BulkMutation, Mutation } from "./models/mutation"

export type EventPayloads = {
    updateTranslations: { values: Domain.Translations }
    register: Omit<Domain.User, "logoUrl" | "taxNumber" | "userId">
    adoptOffer: { offerId: string }
    updateOfferValues: {
        offerId: string
        values?: Domain.OfferValues
        comment?: string
        additionalInformation?: Domain.OfferInformation
        positionComments?: { positionId: string; comment: string }
        offerProposal?: Domain.AttachmentData
    }
    mutateOfferAttachment: { offerId: string; attachment: Domain.Attachment } & Domain.Mutable
    submitOffer: { offerId: string }
    rejectOffer: { offerId: string }
    rejectFinalProposal: { offerId: string }
    updateOfferState: { offerId: string; state: Domain.OfferState }
    mutate: Mutation
    mutateChangelog: Domain.Changelog & Domain.Mutable
    mutatePartners: Domain.Partner & Domain.Mutable
    bulkMutate: BulkMutation
    updateUser: TypedOmit<Domain.User, "userId" | "agreedOn" | "lastVisitVersion" | "type">
    updateTerms: Pick<Domain.User, "agreedOn">
    updateVisit: Pick<Domain.User, "lastVisitVersion">
    createRequests: Pick<Domain.ArchitectOfferRequest, "createdTs" | "devisId" | "projectId"> & {
        submittersIds: string[]
        message: string
        entryTs?: number
    }
    sendReminders: Pick<Domain.ArchitectOfferRequest, "createdTs" | "devisId" | "projectId"> & {
        message: string
        offersIds: string[]
        entryTs?: number
    }
    updateRequestsState: Pick<Domain.ArchitectOfferRequest, "projectId" | "state" | "devisId"> & {
        offersIds: string[]
        offerMeta?: Partial<Domain.OfferMeta>
    }
    updateFinalOffer: Required<
        Pick<Domain.ArchitectOfferRequest, "projectId" | "devisId"> & { valuesByOffer: SMap<Domain.OfferValues> }
    >
    createRound: Pick<Domain.ArchitectOfferRequest, "projectId"> & { round: Domain.Round }
    sendRound: Pick<Domain.ArchitectOfferRequest, "projectId" | "devisId"> & {
        roundId: string
        message?: string
        ts: number
    }
    submitRoundDelta: Pick<Domain.ArchitectOfferRequest, "projectId" | "devisId"> & { delta: Domain.RoundDelta }
    revertRoundDelta: Pick<Domain.ArchitectOfferRequest, "projectId" | "devisId"> & {
        deltaId: string
        roundId: string
    }
    duplicateProject: Pick<Domain.Project, "name" | "projectId"> & { newProjectId: string; systemTemplate?: boolean }
    duplicateDevis: Pick<Domain.Project, "projectId"> & {
        devisId: string
        newProjectId?: string
        details: TypedOmit<Domain.Devis, "devisId">
        duplicationMap: Domain.DevisDuplicationMap
    }
    saveAsTemplate: Pick<Domain.Project, "name" | "projectId"> & { newProjectId: string; clearValues: boolean }
    createPositionsFromTemplate: { templateId: string; projectId: string; devisId: string }
    removePositionsFormat: { projectId: string; devisId: string }
    removeOfferProposal: { offerId: string }
    setFileForDevis: { file: Domain.AttachmentData; devisId: string; projectId: string }
}
const mkEvent =
    <T extends keyof EventPayloads>(name: T) =>
    (p: EventPayloads[T]) =>
        createEventAction(name, p)

const mkDeprecatedEvent =
    <T extends keyof DeprecatedPayloads>(name: T) =>
    (p: DeprecatedPayloads[T]) =>
        createEventAction(name, p)

const adminEvents = {
    updateTranslations: mkEvent("updateTranslations")
}

const publicEvents = {
    register: mkEvent("register")
}

const contractorEvents = {
    adoptOffer: mkEvent("adoptOffer"),
    updateOfferValues: mkEvent("updateOfferValues"),
    mutateOfferAttachment: mkEvent("mutateOfferAttachment"),
    submitOffer: mkEvent("submitOffer"),
    rejectOffer: mkEvent("rejectOffer"),
    rejectFinalProposal: mkEvent("rejectFinalProposal"),
    updateOfferState: mkEvent("updateOfferState")
}

const deprecatedEvents = {
    generateToken: mkDeprecatedEvent("generateToken"),
    grantAdminAccess: mkDeprecatedEvent("grantAdminAccess"),
    registerUser: mkDeprecatedEvent("registerUser"),
    registerContractor: mkDeprecatedEvent("registerContractor"),
    verifyUser: mkDeprecatedEvent("verifyUser"),
    resendToken: mkDeprecatedEvent("resendToken"),
    mutateItem: mkDeprecatedEvent("mutateItem"),
    mutateProjectItem: mkDeprecatedEvent("mutateProjectItem"),
    createDevis: mkDeprecatedEvent("createDevis"),
    generateActivity: mkDeprecatedEvent("generateActivity"),
    updateUserPassword: mkDeprecatedEvent("updateUserPassword"),
    initializeEmailUpdate: mkDeprecatedEvent("initializeEmailUpdate"),
    confirmEmailUpdate: mkDeprecatedEvent("confirmEmailUpdate"),
    initializePasswordReset: mkDeprecatedEvent("initializePasswordReset"),
    confirmPasswordReset: mkDeprecatedEvent("confirmPasswordReset")
}

export const cloudEvents = {
    ...adminEvents,
    ...publicEvents,
    ...contractorEvents,
    ...deprecatedEvents,
    mutate: mkEvent("mutate"),
    mutateChangelog: mkEvent("mutateChangelog"),
    mutatePartners: mkEvent("mutatePartners"),
    bulkMutate: mkEvent("bulkMutate"),

    updateUser: mkEvent("updateUser"),
    updateTerms: mkEvent("updateTerms"),
    updateVisit: mkEvent("updateVisit"),

    createRequests: mkEvent("createRequests"),
    sendReminders: mkEvent("sendReminders"),
    updateRequestsState: mkEvent("updateRequestsState"),
    updateFinalOffer: mkEvent("updateFinalOffer"),
    createRound: mkEvent("createRound"),
    sendRound: mkEvent("sendRound"),
    submitRoundDelta: mkEvent("submitRoundDelta"),
    revertRoundDelta: mkEvent("revertRoundDelta"),

    duplicateProject: mkEvent("duplicateProject"),
    duplicateDevis: mkEvent("duplicateDevis"),
    saveAsTemplate: mkEvent("saveAsTemplate"),
    createPositionsFromTemplate: mkEvent("createPositionsFromTemplate"),
    removePositionsFormat: mkEvent("removePositionsFormat"),
    removeOfferProposal: mkEvent("removeOfferProposal"),
    setFileForDevis: mkEvent("setFileForDevis")
}

export type CloudEvents = typeof cloudEvents
export type CloudEventName = keyof CloudEvents
export type CloudEventPayload<N extends CloudEventName> = ArrayItem<FArgs<CloudEvents[N]>>
export type CloudEvent<N extends CloudEventName = CloudEventName> = EventAction<N, CloudEventPayload<N>>

export type DeprecatedEvents = keyof typeof deprecatedEvents

export type DeprecatedPayloads = {
    generateToken: { email: string }
    grantAdminAccess: Pick<Domain.User, "email"> & { secret: string }
    registerUser: Pick<Domain.User, "email" | "name" | "surname" | "agreedOn" | "phoneNumber" | "lastVisitVersion"> & {
        password: string
        isContractor: boolean
    }
    registerContractor: TypedOmit<Domain.User, "userId"> & {
        password: string
        isContractor: boolean
        offerId?: string
    }
    verifyUser: { code: string; userId: string }
    resendToken: EmptyObject
    mutateItem: {
        collection: keyof Domain.BaseCollections
    } & Domain.CollectionItem<keyof Domain.BaseCollections> &
        Domain.Mutable
    mutateProjectItem: {
        collection: Domain.ProjectCollection
        projectId: string
    } & Domain.ProjectCollectionItem<Domain.ProjectCollection> &
        Domain.Mutable
    createDevis: Domain.Devis
    generateActivity: EmptyObject
    updateUserPassword: { password: string }
    initializeEmailUpdate: { email: string }
    confirmEmailUpdate: { tokenId: string }
    initializePasswordReset: { email: string }
    confirmPasswordReset: { tokenId: string; password: string }
}
