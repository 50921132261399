import * as React from "react"
import styled from "styled-components"
import { FlexColumn, Center } from "./utils/common"
import { themeColor } from "./utils/theme"
import { H3, Label } from "./Typography"
import { AsyncFile, AvailableExtension, Upload, UploadingFile, UploadRestrictions } from "./Upload"
import { ButtonType } from "./Button"
import { F1 } from "@smartdevis/utils/src/types"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { i18n } from "@smartdevis/client/src/services/translations"

export type DropzoneProps = {
    title: string
    buttonType?: ButtonType | "icon-button"
    restrictions?: Partial<UploadRestrictions>
    files: AsyncFile[]
    onUpload: F1<UploadingFile[]>
    onRemove: (fileId: string) => Promise<void>
}

const DropzoneWrapper = styled(Center)<{ hasError: boolean; enteredDropzone: boolean }>`
    --border-width: 2px;
    --border-radius: 2px;
    --bg-color: ${themeColor("white")};

    position: relative;
    border: var(--border-width) solid transparent;
    border-radius: var(--border-radius);
    background-clip: padding-box;
    background-color: ${props => themeColor(props.enteredDropzone ? "grey40" : "white")};
    :before {
        content: "";
        position: absolute;
        top: calc(var(--border-width) * -1);
        right: calc(var(--border-width) * -1);
        bottom: calc(var(--border-width) * -1);
        left: calc(var(--border-width) * -1);
        z-index: -1;
        background-image: repeating-linear-gradient(135deg, transparent, 8px, var(--bg-color) 8px 16px);
        border-radius: var(--border-radius);
        background-color: ${props => themeColor(props.hasError ? "action" : "grey50")};
    }
`

const Container = styled(FlexColumn)`
    justify-content: center;
    align-items: center;
    height: 100%;
`

const Title = styled(H3)`
    color: ${themeColor("black")};
    max-width: 70%;
    text-align: center;
    margin-bottom: 24px;
`

export const fitsExtensions = (file: File, availableExtensions: AvailableExtension[] = []) =>
    availableExtensions.length ? availableExtensions.includes(`.${file.name.split(".").pop()}`) : true

export const Dropzone: React.FC<DropzoneProps> = p => {
    const [enteredDropzone, setEnteredDropzone] = React.useState(false)
    const [isInvalidUpload, setIsInvalidUpload] = React.useState(false)

    const resetDragFlags = () => {
        if (enteredDropzone) setEnteredDropzone(false)
        if (isInvalidUpload) setIsInvalidUpload(false)
    }

    const dropZoneRef = React.useRef<HTMLDivElement | null>(null)

    const onFileSizeRestricted = () => {
        setIsInvalidUpload(true)
        setTimeout(() => {
            setIsInvalidUpload(false)
        }, 500)
    }

    const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }

    const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setEnteredDropzone(true)
    }

    const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()

        const rect = dropZoneRef.current?.getBoundingClientRect()
        if (
            rect &&
            (e.clientY < rect.top || e.clientY >= rect.bottom || e.clientX < rect.left || e.clientX >= rect.right)
        ) {
            resetDragFlags()
        }
    }

    const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()

        const retrievedFiles = Array.from(e.dataTransfer.files)
        if (retrievedFiles && retrievedFiles.length > 0) {
            const filesToUpload: UploadingFile[] = retrievedFiles
                .filter(
                    file =>
                        fitsExtensions(file, p.restrictions?.acceptableExtensions) &&
                        (p.restrictions?.maxFileSize ? file.size <= p.restrictions.maxFileSize : true)
                )
                .map(file => ({
                    type: "uploading",
                    name: file.name,
                    file,
                    uid: genTemporaryId(),
                    createdTs: getTs()
                }))

            resetDragFlags()

            if (onFileSizeRestricted && retrievedFiles.length !== filesToUpload.length) {
                onFileSizeRestricted()
            }

            p.onUpload(filesToUpload)
        }
    }

    const uploadButtonTitle =
        p.restrictions?.maxFilesCount && p.restrictions.maxFilesCount <= 1 ? i18n("Select file") : i18n("Select files")

    return (
        <DropzoneWrapper
            fullHeight
            ref={dropZoneRef}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            hasError={isInvalidUpload}
            enteredDropzone={enteredDropzone}>
            <Container>
                <Title>{p.title}</Title>
                {p.restrictions && p.restrictions.acceptableExtensions?.length ? (
                    <Label style={{ marginBottom: "24px" }} color={isInvalidUpload ? "action" : "grey70"}>
                        {i18n("Supported files")}: {p.restrictions.acceptableExtensions.join(", ")}
                    </Label>
                ) : null}
                {p.restrictions && p.restrictions.forbiddenExtensions?.length ? (
                    <Label style={{ marginBottom: "24px" }} color={isInvalidUpload ? "action" : "grey70"}>
                        {i18n("Forbidden extensions")}: {p.restrictions.forbiddenExtensions.join(", ")}
                    </Label>
                ) : null}

                <Upload
                    restrictions={p.restrictions}
                    files={p.files}
                    isReadonly={false}
                    uploadButtonTitle={uploadButtonTitle}
                    onUpload={p.onUpload}
                    onRemove={p.onRemove}
                    buttonType="primary"
                    onFileSizeRestricted={onFileSizeRestricted}
                />
            </Container>
        </DropzoneWrapper>
    )
}
