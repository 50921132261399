import * as React from "react"
import styled from "styled-components"
import { Asset } from "@smartdevis/ui/src/Asset"
import { contractorOfferPaths, contractorPaths, materializePath, OfferPath, RouteParams } from "../../paths"
import { asyncConnect } from "../../resolvers"
import { Button } from "@smartdevis/ui/src/Button"
import { i18n } from "@smartdevis/client/src/services/translations"
import { H5, Label, Pre } from "@smartdevis/ui/src/Typography"
import { rem } from "@smartdevis/ui/src/utils"
import { Domain } from "@smartdevis/server/src/domain"
import { OfferConditions } from "./ContractorConditions"
import { OfferPositions } from "./ContractorPositions"
import { OfferPreview } from "./ContractorDeductions"
import { useCloudAction } from "../../hooks/useCloudAction"
import { useDebounce } from "@smartdevis/ui/src/hooks/useDebounce"
import { useItemsMap } from "../../hooks/utilityHooks"
import { Redirect } from "react-router-dom"
import { FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { InlineSpinner } from "@smartdevis/ui/src/Spinner"
import { BaseModalProps, ConfirmationCheckModal, ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"
import { sumPositionsPrices, calculateDevis } from "@smartdevis/server/src/utils/money"
import {
    mkDeductionsBySection,
    mkDeductionSections,
    mkPositions,
    mkRequestDeltasByRef
} from "@smartdevis/server/src/models/shared"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { PageHeader } from "../../components/layouts/Header"
import { SubContent } from "../../components/layouts/Content"
import { getEmptiesForOfferSubmission, isReadyToSubmit } from "@smartdevis/server/src/utils/deltas"
import {
    getRoundId,
    isOfferInState,
    mkLatestOfferInformation,
    mkLatestOfferValues
} from "@smartdevis/server/src/utils/offer"
import { ExportOfferPreview } from "../../components/export/ExportButtons"
import { Tag } from "@smartdevis/ui/src/Tag"
import { SMap, filterObject, TMap } from "@smartdevis/utils/src/map"
import { StateType, F0, F2, F1 } from "@smartdevis/utils/src/types"
import { isEqual } from "@smartdevis/utils/src/misc"
import { AsyncEvent } from "@smartdevis/utils/src/actions"
import { CHF } from "@smartdevis/utils/src/numbers"
import { isNumber } from "@smartdevis/utils/src/validators"
import { isEmpty } from "lodash"
import { useNotifications } from "../NotificationsProvider"

type StepConfig = {
    path: OfferPath
    key: "conditions" | "positions" | "preview" | "information"
    component: React.FunctionComponent<OfferStepProps>
    title: string
}

const getSchema = (): Array<StepConfig> => [
    {
        path: "contractorOfferConditions",
        component: OfferConditions,
        key: "conditions",
        title: i18n("General information")
    },
    { path: "contractorOfferPositions", component: OfferPositions, key: "positions", title: i18n("Specification") },
    { path: "contractorOfferPreview", component: OfferPreview, key: "preview", title: i18n("Deductions") }
]

const BottomNavButtons = styled(FlexRow)`
    width: 100%;
`

const Steps = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const StepWrapper = styled.div<{ active?: boolean }>`
    width: 312px;
    height: 60px;
    border-bottom: 1px solid ${p => themeColor(p.active ? "primary" : "blueGrey")};
    margin: 0 50px;
    cursor: pointer;
`
const StepNumber = styled.div<{ active?: boolean }>`
    color: ${p => themeColor(p.active ? "primary" : "blueGrey")};
    font-size: ${rem(11)};
`

const SavingIndicator: React.FC<{ state: StateType<AsyncEvent<any>> }> = p => (
    <FlexRow alignCenter spaceBetween style={{ width: "85px" }}>
        {p.state === "Processing" ? i18n("Saving") : p.state === "Err" ? i18n("Error") : i18n("Saved")}
        <HorizontalSpace base="10px" />
        {p.state === "Processing" ? (
            <InlineSpinner />
        ) : p.state === "Err" ? (
            <Asset name="Warning" size="mid-icon" />
        ) : (
            <Asset name="CheckGreen" size="mid-icon" />
        )}
    </FlexRow>
)

const Step: React.FC<{ current: boolean; onClick: F0; number: number }> = p => {
    return (
        <StepWrapper active={p.current} onClick={p.onClick}>
            <StepNumber active={p.current}>{i18n("STEP $1", p.number)}</StepNumber>
            <H5>{p.children}</H5>
        </StepWrapper>
    )
}
export type OfferRowMode = "basic" | "round" | "final"
export type OfferErrors = TMap<"positions" | "deductions" | "information", string[]> | null
export type OfferStepProps = {
    setValue: F2<string, number>
    setInformation: F2<string, string>
    setComment: F1<string>
    values: SMap<number>
    information: SMap<string>
    comment: string
    errorMap: OfferErrors
    mode: OfferRowMode
    readonly: boolean
} & RouteParams

export const getOfferRowMode = (c: Domain.ContractorOffer): OfferRowMode => {
    if (c.state.type === "final-proposal") return "final"
    if (c.state.type === "next-round" || c.state.type === "round-submitted") return "round"
    return "basic"
}

export const ContractorOfferSteps = asyncConnect({
    stateResolvers: ["contractorOffer", "results"],
    actions: ["navigate", "updateOfferValues", "submitOffer", "updateOfferState", "rejectFinalProposal"]
})<RouteParams>(p => {
    const currentStepKey = p.match.params.offerStep
    const {
        offerId,
        project: { projectId },
        devis: { devisId }
    } = p.contractorOffer
    const schema = getSchema()
    const step = schema.find(s => s.key === currentStepKey)
    if (!step) return <Redirect path="/" to={materializePath("contractorOffer", p.match.params)} />

    const StepComponent = step.component

    const { items, setItem } = useItemsMap<number>({})
    const { items: informationItems, setItem: setInformationItem } = useItemsMap<string>({})

    const initialCommentValue =
        p.contractorOffer.state.type === "next-round"
            ? (p.contractorOffer.roundComments || {})[p.contractorOffer.state.roundId] || ""
            : p.contractorOffer.state.type === "adopted"
            ? p.contractorOffer.comment || ""
            : ""

    const modalRef = React.useRef<HTMLDivElement | null>(null)
    React.useEffect(() => {
        // This is to ensure that modal's bottomEffect will fire correctly
        modalRef?.current?.scrollBy({ top: 1 })
    }, [currentStepKey])

    const { pushNotification } = useNotifications()
    const [comment, _setComment] = React.useState(initialCommentValue)
    const [submitModalVisible, setSubmitModalVisible] = React.useState(false)
    const [acceptOfferModalVisible, setAcceptOfferModalVisible] = React.useState(false)
    const [rejectChangesModalVisible, setRejectChangesModalVisible] = React.useState(false)
    const [secondRoundInformation, setSecondRoundInformation] = React.useState({ visible: false, displayed: false })
    const [errorMap, setErrorMap] = React.useState<OfferErrors>(null)
    const currentOfferValues = mkLatestOfferValues(p.contractorOffer)
    const currentOfferInformation = mkLatestOfferInformation(p.contractorOffer)
    const allItems = { ...currentOfferValues, ...items }
    const allInformationItems = { ...currentOfferInformation, ...informationItems }

    React.useEffect(() => {
        if (
            p.contractorOffer.state.type === "next-round" &&
            !secondRoundInformation.displayed &&
            p.contractorOffer.devis?.positionsFormat?.type !== "file-based"
        )
            setSecondRoundInformation({ visible: true, displayed: true })
    }, [p.contractorOffer.state])

    const roundId = getRoundId(p.contractorOffer)

    const deltas = mkRequestDeltasByRef(p.contractorOffer)

    const deductionsBySection = mkDeductionsBySection(p.contractorOffer, deltas, {
        keepDeleted: false
    })
    const deductionSections = mkDeductionSections(p.contractorOffer, deltas, {
        keepDeleted: false
    })
    const positions = mkPositions(p.contractorOffer, deltas, { keepDeleted: false })
    const positionsSum = sumPositionsPrices(positions, allItems)
    const calculation = calculateDevis(
        deductionSections,
        deductionsBySection,
        allItems,
        allInformationItems,
        positionsSum
    )
    const isContractable = p.contractorOffer.state.type === "final-proposal"
    const isContractSigned = p.contractorOffer.state.type === "contracted"

    const { onSubmit: onSubmitOffer, actionState: actionStateOffer } = useCloudAction(
        actionId => p.submitOffer({ offerId, actionId }),
        p.results,
        () => {
            setSubmitModalVisible(false)
            p.navigate(contractorPaths.contractorOffer, { offerId })
        }
    )

    const { onSubmit: onRejectContract, actionState: actionStateRejectContract } = useCloudAction(
        actionId => p.rejectFinalProposal({ offerId, actionId }),
        p.results,
        () => {
            setRejectChangesModalVisible(false)
            p.navigate(contractorPaths.contractorOffer, { offerId })
        }
    )
    const { onSubmit: onUpdateOfferState, actionState: actionStateOfferUpdate } = useCloudAction<Domain.OfferState>(
        (actionId, state) => {
            p.updateOfferState({ offerId, state, actionId })
        },
        p.results,
        () => {
            setSubmitModalVisible(false)
            p.navigate(contractorPaths.contractorOffer, { offerId })
        }
    )

    const [awaitingUpdate, setAwaitingUpdate] = React.useState(false)

    const { onSubmit: onSubmitValues, actionState: actionStateValues } = useCloudAction<{
        values: Domain.OfferValues
        additionalInformation: Domain.OfferInformation
        comment: string
    }>((actionId, v) => {
        p.updateOfferValues({
            offerId,
            values: filterObject(v.values, (_, val) => isNumber(val)),
            additionalInformation: filterObject(v.additionalInformation, (_, val) => Boolean(val)),
            actionId,
            comment: v.comment
        })
        setAwaitingUpdate(false)
    }, p.results)

    const { run } = useDebounce(onSubmitValues, 3000)

    const setValue = (key: string, value: number) => {
        setItem(value, key)
        run({ values: { ...allItems, [key]: value }, comment, additionalInformation: allInformationItems })
        setAwaitingUpdate(true)
    }

    const setInformation = (key: string, value: string) => {
        setInformationItem(value, key)
        run({
            values: allItems,
            comment,
            additionalInformation: { ...allInformationItems, [key]: value }
        })
        setAwaitingUpdate(true)
    }

    const setComment = (c: string) => {
        _setComment(c)
        run({ values: allItems, comment: c, additionalInformation: allInformationItems })
    }

    const stepIndex = schema.findIndex(s => s.key === step.key)

    const notSaved =
        !isEqual(
            currentOfferValues,
            filterObject(allItems, (_, v) => v !== null)
        ) || !isEqual(currentOfferInformation, allInformationItems)

    const nextPath = stepIndex < schema.length - 1 ? schema[stepIndex + 1].path : null
    const backPath = stepIndex > 0 ? schema[stepIndex - 1].path : null

    const isRound = isOfferInState(p.contractorOffer, ["next-round"])
    const readonly = !isOfferInState(p.contractorOffer, ["adopted", "next-round"])

    const trySubmitting = () => {
        const isReady = isReadyToSubmit(p.contractorOffer, currentOfferValues, currentOfferInformation, roundId)
        if (isReady) setSubmitModalVisible(true)
        else {
            const emptyIds = getEmptiesForOfferSubmission(
                p.contractorOffer,
                currentOfferValues,
                currentOfferInformation,
                roundId
            )
            if (!isEmpty(emptyIds.information))
                p.navigate(contractorOfferPaths.contractorOfferConditions, p.match.params)
            else if (!isEmpty(emptyIds.positions) && p.contractorOffer.devis.positionsFormat?.type !== "file-based")
                p.navigate(contractorOfferPaths.contractorOfferPositions, p.match.params)
            else if (!isEmpty(emptyIds.deductions)) modalRef.current?.scrollTo(0, 0)

            setErrorMap(emptyIds)

            pushNotification(i18n("Some fields have not been filled. Please fill them and try again"))
        }
    }

    return (
        <Modal
            visible
            size="xl"
            height="95vh"
            ref={modalRef}
            onClose={() => p.navigate(contractorPaths.contractorOffer, { offerId })}
            header={
                <Steps>
                    {schema.map((k, i) => (
                        <Step
                            key={k.path}
                            current={k.key === currentStepKey}
                            number={i + 1}
                            onClick={() => {
                                modalRef.current?.scrollTo(0, 0)
                                p.navigate(contractorOfferPaths[k.path], p.match.params)
                            }}>
                            {k.title}
                        </Step>
                    ))}
                </Steps>
            }
            footer={
                <BottomNavButtons alignCenter spaceBetween>
                    {backPath ? (
                        <Button
                            btnType="secondary"
                            onClick={() => {
                                modalRef.current?.scrollTo(0, 0)
                                p.navigate(contractorOfferPaths[backPath], { offerId })
                            }}>
                            {i18n("Back")}
                        </Button>
                    ) : (
                        <div />
                    )}
                    {nextPath && (
                        <Button
                            btnType="secondary"
                            onClick={() => {
                                modalRef.current?.scrollTo(0, 0)
                                p.navigate(contractorOfferPaths[nextPath], { offerId })
                            }}>
                            {i18n("Next")}
                        </Button>
                    )}
                    {step.key === "preview" && !isContractable && !isContractSigned && (
                        <FlexRow>
                            <ExportOfferPreview projectId={projectId} devisId={devisId} offerId={offerId} />
                            <Button
                                btnType="primary"
                                onClick={trySubmitting}
                                loading={actionStateValues.type === "Processing" || notSaved}
                                disabled={readonly || awaitingUpdate}>
                                {i18n("Submit")}
                            </Button>
                        </FlexRow>
                    )}
                    {step.key === "preview" && isContractable && (
                        <FlexRow>
                            <ExportOfferPreview projectId={projectId} devisId={devisId} offerId={offerId} />
                            <Button
                                btnType="secondary"
                                onClick={() => setRejectChangesModalVisible(true)}
                                loading={actionStateOfferUpdate.type === "Processing"}
                                disabled={actionStateOfferUpdate.type === "Processing" || awaitingUpdate}>
                                {i18n("Reject changes")}
                            </Button>
                            <Button
                                btnType="primary"
                                onClick={() => setAcceptOfferModalVisible(true)}
                                loading={actionStateOfferUpdate.type === "Processing"}
                                disabled={actionStateOfferUpdate.type === "Processing" || awaitingUpdate}>
                                {i18n("Accept offer")}
                            </Button>
                        </FlexRow>
                    )}
                </BottomNavButtons>
            }>
            <SubContent>
                <PageHeader
                    title={step.title}
                    actionButtons={[
                        <SavingIndicator key="saving" state={notSaved ? "Processing" : actionStateValues.type} />
                    ]}
                />
                <VerticalSpace h={16} />
                <StepComponent
                    {...p}
                    errorMap={errorMap}
                    mode={getOfferRowMode(p.contractorOffer)}
                    setValue={setValue}
                    setInformation={setInformation}
                    values={allItems}
                    information={allInformationItems}
                    setComment={setComment}
                    comment={comment}
                    readonly={readonly}
                />
            </SubContent>

            <ConfirmSubmitOfferModal
                visible={submitModalVisible}
                onSubmit={onSubmitOffer}
                isRound={isRound}
                onClose={() => setSubmitModalVisible(false)}
                loading={actionStateOffer.type === "Processing"}
                architectName={p.contractorOffer.architect.name + " " + p.contractorOffer.architect.surname}
                devisName={p.contractorOffer.devis.workCategory}
                total={calculation.netincltax}
            />
            <AcceptOfferModal
                visible={acceptOfferModalVisible}
                onSubmit={() => onUpdateOfferState({ type: "contracted" })}
                onClose={() => setAcceptOfferModalVisible(false)}
                loading={actionStateOfferUpdate.type === "Processing"}
                architectName={p.contractorOffer.architect.name + " " + p.contractorOffer.architect.surname}
                devisName={p.contractorOffer.devis.workCategory}
                total={calculation.netincltax}
            />
            <RejectChangesModal
                visible={rejectChangesModalVisible}
                onSubmit={onRejectContract}
                onClose={() => setRejectChangesModalVisible(false)}
                loading={actionStateRejectContract.type === "Processing"}
                architectName={p.contractorOffer.architect.name + " " + p.contractorOffer.architect.surname}
                devisName={p.contractorOffer.devis.workCategory}
                total={calculation.netincltax}
            />
            <SecondRoundInstructions
                visible={secondRoundInformation.visible}
                onClose={() => setSecondRoundInformation({ visible: false, displayed: true })}
                devisName={p.contractorOffer.devis.workCategory}
            />
        </Modal>
    )
})

const ConfirmSubmitOfferModal: React.FC<
    BaseModalProps & {
        onSubmit: F0
        loading: boolean
        architectName: string
        isRound?: boolean
        devisName: string
        total: number
    }
> = p => {
    const warning = p.isRound
        ? i18n("{second round submit} Once the offer is updated you won’t be able to change the prices.")
        : i18n("{offer submit} Once an offer is submitted you won’t be able to change the prices.")
    const confirmation = p.isRound
        ? i18n("I confirm, I want to submit my second round offer")
        : i18n("I confirm, I want to submit my offer")

    const title = p.isRound
        ? i18n("You're about to submit second round offer for:")
        : i18n("You're about to submit an offer for:")
    return (
        <ConfirmationCheckModal
            onSubmit={p.onSubmit}
            onClose={p.onClose}
            visible={p.visible}
            submitText={i18n("Accept")}
            cancelText={i18n("Cancel")}
            submitButtonProps={{ loading: p.loading }}
            confirmationText={confirmation}>
            <Label>{title}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.architectName}</Pre>
            <VerticalSpace base="16px" />
            <Label>{i18n("For a total price (Net + tax) of:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>
                <b>{CHF(p.total)}</b>
            </Pre>
            <VerticalSpace base="16px" />
            <InfoBox type="info">{warning}</InfoBox>
            <VerticalSpace base="16px" />
        </ConfirmationCheckModal>
    )
}

const AcceptOfferModal: React.FC<
    BaseModalProps & { onSubmit: F0; loading: boolean; architectName: string; devisName: string; total: number }
> = p => {
    return (
        <ConfirmationCheckModal
            onSubmit={p.onSubmit}
            onClose={p.onClose}
            visible={p.visible}
            submitText={i18n("Submit")}
            cancelText={i18n("Cancel")}
            submitButtonProps={{ loading: p.loading }}
            confirmationText={i18n("I confirm, I want to accept architect's offer")}>
            <Label>{i18n("You're about to accept an offer for:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.architectName}</Pre>
            <VerticalSpace base="16px" />
            <Label>{i18n("For a total price (Net + tax) of:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>
                <b>{CHF(p.total)}</b>
            </Pre>
            <VerticalSpace base="16px" />
        </ConfirmationCheckModal>
    )
}

const RejectChangesModal: React.FC<
    BaseModalProps & { onSubmit: F0; loading: boolean; architectName: string; devisName: string; total: number }
> = p => {
    return (
        <ConfirmationCheckModal
            onSubmit={p.onSubmit}
            onClose={p.onClose}
            visible={p.visible}
            submitText={i18n("Reject changes")}
            cancelText={i18n("Cancel")}
            submitButtonProps={{ loading: p.loading }}
            confirmationText={i18n("I confirm, I want architect the change the offer")}>
            <Label>{i18n("Reject changes and allow architect to adjust the offer one more time")}</Label>
        </ConfirmationCheckModal>
    )
}

const SecondRoundInstructions: React.FC<BaseModalProps & { onClose: F0; devisName: string }> = p => {
    const info = i18n("Work with positions and deductions the architect wants you to update.")
    return (
        <ConfirmationModal
            header={i18n("$1 - Round 2", p.devisName)}
            onSubmit={p.onClose}
            visible={p.visible}
            submitText={i18n("Got it")}>
            <Label>{info}</Label>
            <VerticalSpace base="8px" />
            <Label>{i18n("Positions and deductions can be:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>
                <Tag type="added">{i18n("Added")}</Tag>
                {"  "}
                <Tag type="edited">{i18n("Edited")}</Tag>
                {"  "}
                <Tag type="removed">{i18n("Removed")}</Tag>
            </Pre>
        </ConfirmationModal>
    )
}
