import * as React from "react"

export const ArrowRight = () => (
    <svg width={5} height={9} viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 7.872l3-3.436L1 1"
            stroke="#444850"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
