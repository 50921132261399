import * as React from "react"
import dayjs from "dayjs"
import { asyncConnect } from "../../resolvers"
import * as vservice from "../../version"
import { Center, VerticalSpace, FlexRow } from "@smartdevis/ui/src/utils/common"
import { dateFormat } from "@smartdevis/server/src/constants"
import { Button } from "@smartdevis/ui/src/Button"
import { i18n } from "../../services/translations"
import { ChangelogDisplay } from "../../views/admin/Changelog"
import { H4, P } from "@smartdevis/ui/src/Typography"
import { Modal } from "@smartdevis/ui/src/Modal"
import { versionToNumber } from "@smartdevis/server/src/models/changelog"
import { keys } from "@smartdevis/utils/src/map"
import { genTemporaryId } from "@smartdevis/utils/src/id"

export const NewVersionModal = asyncConnect({
    stateResolvers: ["changelog", "user", "results", "language"],
    actions: ["updateVisit"],
    renderError: () => <>{null}</>,
    renderLoading: () => <>{null}</>
})(p => {
    // const newVersionIsAvailable = vservice.version > (p.user.lastVisitVersion || 0)
    const availableChangelogs = keys(p.changelog).filter(
        v => v > (p.user.lastVisitVersion || 0) && v <= versionToNumber(vservice.version)
    )
    // const _wouldbevisibleif = newVersionIsAvailable && availableChangelogs.length > 1
    const onAcknowledged = () =>
        p.updateVisit({ lastVisitVersion: versionToNumber(vservice.version), actionId: genTemporaryId() })
    return (
        <Modal header={i18n("What's new?")} onClose={onAcknowledged} visible={false}>
            <ChangelogDisplay>
                {availableChangelogs
                    .sort()
                    .reverse()
                    .map(cv => (
                        <React.Fragment key={cv}>
                            <FlexRow alignCenter>
                                <H4>{cv}</H4>
                                {" - "}
                                <P>{dayjs(p.changelog[cv].versionTs).format(dateFormat)}</P>
                            </FlexRow>
                            <VerticalSpace base="8px" />
                            <P>{p.changelog[cv].contentMap[p.language]}</P>
                            <VerticalSpace base="32px" />
                        </React.Fragment>
                    ))}
            </ChangelogDisplay>
            <Center>
                <Button onClick={onAcknowledged}>{i18n("Acknowledged!")}</Button>
            </Center>
        </Modal>
    )
})
