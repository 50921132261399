import * as React from "react"
import dayjs from "dayjs"
import { getFullName } from "@smartdevis/server/src/models/user"
import {
    CalculatedDevis,
    displayTax,
    displaySubtotal,
    displayDeductionPriceFromCalculation
} from "@smartdevis/server/src/utils/money"
import { Row } from "../../../components/table/Table"
import { Cell, mkAssetCell, mkCell, mkCellEmpty, mkPopoverCell } from "../../../components/table/TableCell"
import { CellVisual } from "../../../components/table/TableCell.styles"
import { i18n } from "@smartdevis/client/src/services/translations"
import { GridContainer } from "@smartdevis/ui/src/utils/common"
import { Label, P } from "@smartdevis/ui/src/Typography"
import { RowVisual } from "../../../components/table/TableRow.styles"
import { SMap, keys, values } from "@smartdevis/utils/src/map"
import { State } from "@smartdevis/utils/src/types"
import { CHF, mulSafeNumbers, displayNumber } from "@smartdevis/utils/src/numbers"
import { Domain } from "@smartdevis/server/src/domain"
import { dateTimeFormat } from "@smartdevis/server/src/constants"

export const getComparisonGrid = (arr: any[]) => [24 - arr.length * 5, ...Array(arr.length).fill(5)]
export const getPosComparisonGrid = (arr: any[]) => [1.5, 3.5, 19 - arr.length * 5, ...arr.map(() => [2, 3]).flat()]

const topRow = (submitters: Domain.Contractor[], type: "positions" | "deductions" | "information"): Row<"top"> => ({
    type: "top",
    mode: "static",
    rowId: `top-${type}`,
    visuals: type === "deductions" ? ["noBorder", "bottomBorder"] : ["noBorder", "bottomBorder", "paddingTop"],
    cells: [
        type === "deductions"
            ? mkCellEmpty()
            : type === "positions"
            ? mkCell(i18n("Positions"), ["bold", "big"])
            : mkCell(i18n("Additional information"), ["bold", "big"]),
        ...submitters.map(s =>
            mkAssetCell({ content: s.companyName || getFullName(s) || s.email, asset: "Briefcase" }, [
                "alignRight",
                "borderRight"
            ])
        )
    ],
    grid: getComparisonGrid(submitters)
})

// POSITIONS

const positionsHeaderRow = (submitters: Domain.Contractor[]): Row<"positionsHeader"> => ({
    type: "positionsHeader",
    rowId: "positionsHeader",
    mode: "static",
    visuals: ["header"],
    grid: getPosComparisonGrid(submitters),
    cells: [
        mkCell(i18n("ID")),
        mkCell(i18n("Position")),
        mkCell(i18n("Quantity")),
        ...submitters
            .map(() => [
                mkCell(i18n("Unit price"), ["alignRight"]),
                mkCell(i18n("Total price"), ["alignRight", "borderRight"])
            ])
            .flat()
    ]
})

const positionSectionRow = (
    { sectionId, name, number }: Domain.Section,
    submitters: Domain.Contractor[],
    children: Row[]
): Row<"section" | "sublevel"> => ({
    type: "section",
    mode: "static",
    rowId: sectionId,
    children,
    grid: getPosComparisonGrid(submitters),
    visuals: ["noBorder", "bold"],
    cells: [mkCell(number), mkCell(name), mkCellEmpty(), ...submitters.map(() => [mkCellEmpty(), mkCellEmpty()]).flat()]
})

const displayOldPositions = (p: Domain.Position, calculationsByTime: SMap<CalculatedDevis>) => {
    const getUnit = (key: string) => calculationsByTime[key].offerValues[p.positionId]
    const timestamps = keys(calculationsByTime)
    const latestTs = timestamps[timestamps.length - 1]
    return (
        <GridContainer columnsGrid={[3, 2, 2]} gap="8px">
            <div />
            <Label>{i18n("Unit price")}</Label>
            <Label>{i18n("Total price")}</Label>
            {keys(calculationsByTime).map(ts => {
                const color = ts === latestTs ? "black" : "grey70"
                return (
                    <React.Fragment key={ts}>
                        <P small color={color}>
                            {dayjs(parseInt(ts, 10)).format(dateTimeFormat)}
                        </P>
                        <P small color={color}>
                            {CHF(getUnit(ts))}
                        </P>
                        <P small color={color}>
                            {CHF(mulSafeNumbers(p.amount, getUnit(ts)))}
                        </P>
                    </React.Fragment>
                )
            })}
        </GridContainer>
    )
}

const mkPositionPriceCells = (p: Domain.Position, cByTime: SMap<CalculatedDevis>, best?: boolean) => {
    const timestamps = keys(cByTime)
    const latest = cByTime[timestamps[timestamps.length - 1]]
    const latestUnit = latest.offerValues[p.positionId] ?? 0
    const latestTotal = mulSafeNumbers(p.amount, latestUnit)
    const visuals: CellVisual[] = best ? ["green", "alignRight"] : ["alignRight"]
    const oldDisplay = displayOldPositions(p, cByTime)
    return timestamps.length > 1 && values(cByTime).find(c => c.offerValues[p.positionId] !== latestUnit)
        ? [
              mkPopoverCell(displayNumber(latestUnit), oldDisplay, visuals),
              mkPopoverCell(displayNumber(latestTotal), oldDisplay, [...visuals, "borderRight"])
          ]
        : [mkCell(displayNumber(latestUnit), visuals), mkCell(displayNumber(latestTotal), [...visuals, "borderRight"])]
}

const positionsBuilder = (
    submitters: Domain.Contractor[],
    calculationsByTimeBySubmitter: SMap<SMap<CalculatedDevis>>
) => {
    return (p: Domain.Position): Row<"sublevel"> => {
        const latestCs = submitters
            .map(s => calculationsByTimeBySubmitter[s.contractorId])
            .map(cs => cs[keys(cs)[keys(cs).length - 1]].offerValues[p.positionId])
        return {
            type: "sublevel",
            mode: "static",
            rowId: p.positionId,
            grid: getPosComparisonGrid(submitters),
            visuals: ["noBorder"],
            cells: [
                mkCell(p.number),
                mkCell(p.name),
                p.type === "per"
                    ? mkCell(i18n("PER position"), ["italic"])
                    : mkCell(`${displayNumber(p.amount)} ${p.unit}`),
                ...submitters
                    .map(s => {
                        const cByTime = calculationsByTimeBySubmitter[s.contractorId]
                        const timestamps = keys(cByTime)
                        const latestPrice = cByTime[timestamps[timestamps.length - 1]].offerValues[p.positionId]

                        return mkPositionPriceCells(
                            p,
                            cByTime,
                            latestCs.every(v => v >= latestPrice)
                        )
                    })
                    .flat()
            ]
        }
    }
}

// ADDITIONAL INFO

const additionalInfoHeaderRow = (submitters: Domain.Contractor[]): Row<"additionalInformationHeader"> => ({
    type: "additionalInformationHeader",
    rowId: "additionalInformationHeader",
    mode: "static",
    visuals: ["header"],
    grid: getComparisonGrid(submitters),
    cells: [mkCell(i18n("Title")), ...submitters.map(() => mkCell(i18n("Content"), ["borderRight", "alignRight"]))]
})

const displayOldInformation = (ai: Domain.AdditionalInformation, calculationsByTime: SMap<CalculatedDevis>) => {
    const timestamps = keys(calculationsByTime)
    const latestTs = timestamps[timestamps.length - 1]
    return (
        <GridContainer columnsGrid={[3, 2]} gap="8px">
            {keys(calculationsByTime).map(ts => {
                const color = ts === latestTs ? "black" : "grey70"
                return (
                    <React.Fragment key={ts}>
                        <P small color={color}>
                            {dayjs(parseInt(ts, 10)).format(dateTimeFormat)}
                        </P>
                        <P small color={color}>
                            {ai.value ?? calculationsByTime[ts].offerInformation[ai.informationId]}
                        </P>
                    </React.Fragment>
                )
            })}
        </GridContainer>
    )
}

const mkAdditionalInfoCell = (ai: Domain.AdditionalInformation, cByTime: SMap<CalculatedDevis>) => {
    const timestamps = keys(cByTime)
    const latest = cByTime[timestamps[timestamps.length - 1]]
    const latestDisplay = ai.value ?? latest.offerInformation[ai.informationId]
    return timestamps.length > 1 &&
        values(cByTime).find(
            c => ai.value === null && c.offerInformation[ai.informationId] !== latest.offerInformation[ai.informationId]
        )
        ? mkPopoverCell(latestDisplay, displayOldInformation(ai, cByTime), ["alignRight", "borderRight"])
        : mkCell(latestDisplay, ["alignRight", "borderRight"])
}

const additionalInfoBuilder = (
    submitters: Domain.Contractor[],
    calculationsByTimeBySubmitter: SMap<SMap<CalculatedDevis>>
) => {
    return (ai: Domain.AdditionalInformation): Row<"additionalInformation"> => ({
        type: "additionalInformation",
        mode: "static",
        rowId: ai.informationId,
        grid: getComparisonGrid(submitters),
        visuals: ["noBorder"],
        cells: [
            mkCell(ai.title),
            ...submitters.map(s => mkAdditionalInfoCell(ai, calculationsByTimeBySubmitter[s.contractorId]))
        ]
    })
}

// DEDUCTIONS

const deductionSectionRow = (
    { sectionId, name }: Domain.Section,
    submitters: Domain.Contractor[],
    calculations: SMap<CalculatedDevis>
): Row<"deductionSection"> => ({
    type: "deductionSection",
    mode: "static",
    rowId: sectionId,
    grid: getComparisonGrid(submitters),
    visuals: ["noBorder"],
    cells: [
        mkCell(name),
        ...submitters.map(s =>
            mkCell(displaySubtotal(calculations[s.contractorId].pricesBySection[sectionId]), [
                "alignRight",
                "borderRight"
            ])
        )
    ]
})

const mkDeductionPriceCell = (d: Domain.Deduction, cByTime: SMap<CalculatedDevis>) => {
    const timestamps = keys(cByTime)
    const latest = cByTime[timestamps[timestamps.length - 1]]
    const latestDisplay = displayDeductionPriceFromCalculation(d, latest, true)
    return timestamps.length > 1 &&
        values(cByTime).find(c => c.pricesByDeduction[d.deductionId] !== latest.pricesByDeduction[d.deductionId])
        ? mkPopoverCell(latestDisplay, displayOldDeductions(d, cByTime), ["alignRight", "borderRight"])
        : mkCell(latestDisplay, ["alignRight", "borderRight"])
}

const displayOldDeductions = (d: Domain.Deduction, calculationsByTime: SMap<CalculatedDevis>) => {
    const timestamps = keys(calculationsByTime)
    const latestTs = timestamps[timestamps.length - 1]
    return (
        <GridContainer columnsGrid={[3, 2]} gap="8px">
            {keys(calculationsByTime).map(ts => {
                const color = ts === latestTs ? "black" : "grey70"
                return (
                    <React.Fragment key={ts}>
                        <P small color={color}>
                            {dayjs(parseInt(ts, 10)).format(dateTimeFormat)}
                        </P>
                        <P small color={color}>
                            {displayDeductionPriceFromCalculation(d, calculationsByTime[ts], true)}
                        </P>
                    </React.Fragment>
                )
            })}
        </GridContainer>
    )
}

const deductionsBuilder = (
    submitters: Domain.Contractor[],
    calculationsByTimeBySubmitter: SMap<SMap<CalculatedDevis>>
) => {
    return (d: Domain.Deduction): Row<"deduction"> => ({
        type: "deduction",
        mode: "static",
        rowId: d.deductionId,
        grid: getComparisonGrid(submitters),
        visuals: ["noBorder", "paddingTopSmall"],
        cells: [
            mkCell(d.name),
            ...submitters.map(s => mkDeductionPriceCell(d, calculationsByTimeBySubmitter[s.contractorId]))
        ]
    })
}

// SUMS

const displaySum = (p: SumsType, calculation: CalculatedDevis) => {
    switch (p.type) {
        case "gross":
        case "net":
        case "netincltax":
            return CHF(calculation[p.type])
        case "tax":
            return displayTax({}, calculation.tax)
        case "subtotal":
            return CHF(calculation.subTotalsBySection[p.sectionId])
    }
}

export const mkOfferSumCell = (calculationsByTime: SMap<CalculatedDevis>, p: SumsType, visuals?: CellVisual[]) => {
    const timestamps = keys(calculationsByTime)
    const latest = calculationsByTime[timestamps[timestamps.length - 1]]
    return timestamps.length > 1
        ? mkPopoverCell(displaySum(p, latest), displayOldSums(calculationsByTime, p), visuals)
        : mkCell(displaySum(p, latest), visuals)
}

const displayOldSums = (calculationsByTime: SMap<CalculatedDevis>, p: SumsType) => {
    const timestamps = keys(calculationsByTime)
    const latestTs = timestamps[timestamps.length - 1]
    return (
        <GridContainer columnsGrid={[3, 2]} gap="8px">
            {keys(calculationsByTime).map(ts => {
                const color = ts === latestTs ? "black" : "grey70"
                return (
                    <React.Fragment key={ts}>
                        <P small color={color}>
                            {dayjs(parseInt(ts, 10)).format(dateTimeFormat)}
                        </P>
                        <P small color={color}>
                            {displaySum(p, calculationsByTime[ts])}
                        </P>
                    </React.Fragment>
                )
            })}
        </GridContainer>
    )
}

type SumsType = State<"subtotal", { sectionId: string }> | State<"gross" | "tax" | "net" | "netincltax">
const sumsBuilder = (ss: Domain.Contractor[], calculationsByTimeBySubmitter: SMap<SMap<CalculatedDevis>>) => {
    const submitterIds = ss.map(s => s.contractorId)
    const finalPrices = ss
        .map(s => calculationsByTimeBySubmitter[s.contractorId])
        .map(cs => cs[keys(cs)[keys(cs).length - 1]].netincltax)
    return (p: SumsType): Row => {
        let delta: { rowId?: string; visuals: RowVisual[]; cells: Cell[] } = { visuals: [], cells: [] }
        const sumCells = submitterIds.map(sid =>
            mkOfferSumCell(calculationsByTimeBySubmitter[sid], p, ["alignRight", "borderRight"])
        )
        switch (p.type) {
            case "subtotal":
                delta = {
                    visuals: ["bold"],
                    cells: [mkCell(i18n("Subtotal")), ...sumCells],
                    rowId: p.type + p.sectionId
                }
                break
            case "gross":
                delta = { visuals: ["noBorder", "bold"], cells: [mkCell(i18n("Gross")), ...sumCells] }
                break

            case "tax":
                delta = { visuals: ["noBorder", "paddingTopSmall"], cells: [mkCell(i18n("Tax")), ...sumCells] }
                break

            case "netincltax": {
                delta = {
                    visuals: ["bottomMargin", "title"],
                    cells: [
                        mkCell(i18n("Net incl. tax")),
                        ...submitterIds.map(sid => {
                            const cs = calculationsByTimeBySubmitter[sid]
                            const submitterPrice = cs[keys(cs)[keys(cs).length - 1]].netincltax
                            const best = finalPrices.every(price => price >= submitterPrice)
                            return mkOfferSumCell(
                                calculationsByTimeBySubmitter[sid],
                                p,
                                best ? ["alignRight", "borderRight", "green"] : ["alignRight", "borderRight"]
                            )
                        })
                    ]
                }
                break
            }
            case "net":
                delta = { visuals: ["bold"], cells: [mkCell(i18n("Net")), ...sumCells] }
                break
        }

        return { type: p.type, mode: "static", rowId: p.type, grid: getComparisonGrid(ss), ...delta }
    }
}

export const mkComparisonRows = {
    topRow,
    deductionsBuilder,
    deductionSectionRow,
    positionsBuilder,
    positionSectionRow,
    additionalInfoBuilder,
    sumsBuilder,
    positionsHeaderRow,
    additionalInfoHeaderRow
}
