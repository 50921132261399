import * as React from "react"
import styled from "styled-components"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { PageHeader } from "../../../components/layouts/Header"
import { i18n } from "@smartdevis/client/src/services/translations"
import { Asset } from "@smartdevis/ui/src/Asset"
import { Table } from "../../../components/table/Table"
import { Domain } from "@smartdevis/server/src/domain"
import { CalculatedDevis } from "@smartdevis/server/src/utils/money"
import { mkComparisonRows } from "./DevisComparison.rows"
import { mkDevisPositionsRows } from "@smartdevis/client/src/utils/devisHelpers"
import { SMap } from "@smartdevis/utils/src/map"
import { F0 } from "@smartdevis/utils/src/types"

type DevisComparisonProps = {
    onClose: F0
    submitters: Domain.Contractor[]
    sublevels: SMap<Domain.Sublevel[]>
    deductionSections: Domain.Section[]
    positionSections: Domain.Section[]
    additionalInformation: Domain.AdditionalInformation[]
    deductionsBySections: SMap<Domain.Deduction[]>
    requestsBySubmitter: SMap<Domain.ArchitectOfferRequest>
    calculationsByTimeBySubmitter: SMap<SMap<CalculatedDevis>>
}

export const DevisComparison: React.FC<DevisComparisonProps> = p => {
    const mkDeductionRow = mkComparisonRows.deductionsBuilder(p.submitters, p.calculationsByTimeBySubmitter)
    const mkPositionRow = mkComparisonRows.positionsBuilder(p.submitters, p.calculationsByTimeBySubmitter)
    const mkInfoRow = mkComparisonRows.additionalInfoBuilder(p.submitters, p.calculationsByTimeBySubmitter)
    const mkSumRow = mkComparisonRows.sumsBuilder(p.submitters, p.calculationsByTimeBySubmitter)

    const deductionRows = p.deductionSections.map(({ sectionId }, i) => {
        const rows = p.deductionsBySections[sectionId]?.map(mkDeductionRow) ?? []
        if (rows.length === 0) return []
        const totalRow =
            i === p.deductionSections.length - 1 ? mkSumRow({ type: "net" }) : mkSumRow({ type: "subtotal", sectionId })
        return [...rows, totalRow]
    })

    const positionRows = mkDevisPositionsRows(
        p.positionSections,
        p.sublevels,
        (_, s, children) => mkComparisonRows.positionSectionRow(s, p.submitters, children),
        mkPositionRow
    )

    const additionalInformationRows = p.additionalInformation.filter(v => v.value === null).map(mkInfoRow)
    return (
        <Container>
            <PageHeader
                title={i18n("Comparison")}
                actionButtons={[<Asset key="close" name="Close" onClick={p.onClose} size="big-icon" />]}
            />
            <Table
                rows={[
                    mkComparisonRows.topRow(p.submitters, "deductions"),
                    mkSumRow({ type: "gross" }),
                    ...deductionRows.flat(),
                    mkSumRow({ type: "tax" }),
                    mkSumRow({ type: "netincltax" }),
                    mkComparisonRows.topRow(p.submitters, "positions"),
                    mkComparisonRows.positionsHeaderRow(p.submitters),
                    ...positionRows.flat(),
                    mkComparisonRows.topRow(p.submitters, "information"),
                    ...(additionalInformationRows.length > 0
                        ? [mkComparisonRows.additionalInfoHeaderRow(p.submitters), ...additionalInformationRows]
                        : [])
                ]}
            />
        </Container>
    )
}

const Container = styled.div`
    padding: 12px 24px 24px;
    width: 100%;
    background-color: ${themeColor("grey35")};
`
