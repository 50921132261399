import * as React from "react"
import { Label } from "@smartdevis/ui/src/Typography"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { SMap, values } from "@smartdevis/utils/src/map"
import { UploadingFile } from "@smartdevis/ui/src/Upload"
import { Optionalize } from "@smartdevis/utils/src/types"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect, AsyncConnectResults } from "../../resolvers"
import { getPastOfferAttachments } from "@smartdevis/client/src/utils/offer"
import { AttachmentsList } from "../architect/lists/AttachmentsList"
import { AttachmentsManagement, mkAsyncAttachment } from "../../components/Attachments"
import { Domain } from "@smartdevis/server/src/domain"

export const mkContractorOfferAttachmentsManagement = (
    p: Optionalize<
        AsyncConnectResults<"contractorOffer" | "uploadingFiles" | "user", "uploadFile" | "removeAttachment">,
        "uploadingFiles" | "uploadFile" | "removeAttachment"
    >,
    attachments: SMap<Domain.Attachment>
): AttachmentsManagement => {
    return {
        type: "offer",
        items: attachments || {},
        uploadingItems: p.uploadingFiles ?? {},
        getItemsForRef: (refId: string) => values(attachments).filter(a => a.refId === refId),
        onUpload: (f: UploadingFile, refId: string) =>
            p.uploadFile?.(mkAsyncAttachment(f, "offer", refId), p.user.userId, p.contractorOffer.offerId),
        onRemove: (id: string) =>
            p.removeAttachment?.(
                p.contractorOffer.state.type !== "next-round"
                    ? p.contractorOffer.offerAttachments![id]
                    : p.contractorOffer.roundOfferAttachments![p.contractorOffer.state.roundId][id],
                p.contractorOffer.offerId
            )
    }
}

export const ContractorAttachments = asyncConnect({
    stateResolvers: ["contractorOffer", "uploadingFiles", "user"],
    actions: ["uploadFile", "removeAttachment"]
})(p => {
    const [_attachmentsModalOpen, _setAttachmentsModalOpen] = React.useState(false)
    const attachments = getPastOfferAttachments(p.contractorOffer)
    const isUploadVisible = p.contractorOffer.state.type === "adopted" || p.contractorOffer.state.type === "next-round"
    const { state } = p.contractorOffer
    const currentAttachments =
        state.type === "next-round"
            ? (p.contractorOffer.roundOfferAttachments || {})[state.roundId]
            : p.contractorOffer.offerAttachments

    const generalAttachments = mkContractorOfferAttachmentsManagement(p, currentAttachments || {})

    return (
        <>
            {attachments.map(v => (
                <React.Fragment key={v.label}>
                    <Label>{v.label}</Label>
                    <VerticalSpace base="16px" />
                    <AttachmentsList
                        attachments={mkContractorOfferAttachmentsManagement(p, v.value)}
                        refId={p.contractorOffer.offerId}
                        isReadonly={true}
                    />
                    <VerticalSpace base="32px" />
                </React.Fragment>
            ))}
            {isUploadVisible && (
                <>
                    <Label>{i18n("Current round attachments")}</Label>
                    <VerticalSpace base="16px" />
                    <AttachmentsList
                        attachments={generalAttachments}
                        refId={p.contractorOffer.offerId}
                        isReadonly={false}
                    />
                </>
            )}
        </>
    )
})
