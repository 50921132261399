import * as React from "react"
import styled from "styled-components"
import { Link } from "../../components"
import { adminPaths } from "../../paths"
import { i18n } from "../../services/translations"
import { asyncConnect } from "../../resolvers"
import { ArchitectContent } from "../../components/layouts/Content"
import { PageHeader } from "../../components/layouts/Header"
import { useAuth } from "../AuthProvider"
import { ExternalLink } from "@smartdevis/ui/src/Typography"

export const AdminDashboard = asyncConnect({ actions: [], stateResolvers: [] })(_ => {
    const auth = useAuth()
    return (
        <AdminContent subTitle={i18n("Dashboard")}>
            <AdminSection name={i18n("Reports")}>
                <LinkWrapper>
                    <Link to={adminPaths.adminActivity}>{i18n("Activity")}</Link>
                </LinkWrapper>
                <LinkWrapper>
                    <Link to={adminPaths.devisOverview}>{i18n("Devis overview")}</Link>
                </LinkWrapper>
            </AdminSection>
            <AdminSection name={i18n("General")}>
                <LinkWrapper>
                    <Link to={adminPaths.copies}>{i18n("Copies")}</Link>
                </LinkWrapper>
                <LinkWrapper>
                    <Link to={adminPaths.changelog}>{i18n("Changelog")}</Link>
                </LinkWrapper>
                <LinkWrapper>
                    <Link to={adminPaths.managePartners}>{i18n("Manage partners")}</Link>
                </LinkWrapper>
            </AdminSection>
            <AdminSection name={i18n("Impersonation")}>
                <LinkWrapper>
                    <ExternalLink href={auth.getAdminUrl()} target={"_blank"}>
                        {i18n("Identity admin console")}
                    </ExternalLink>
                </LinkWrapper>
            </AdminSection>
        </AdminContent>
    )
})

const LinkWrapper = styled.div``

export const SectionWrapper = styled.div`
    border: 1px solid #d9d9d9;
    margin-bottom: 20px;
`

export const SectionTitle = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: 20px;
    border-bottom: 1px solid #d9d9d9;
`

const SectionBody = styled.div`
    padding: 20px 30px;
    background-color: white;
`

const AdminSection: React.FC<{ name: string }> = p => (
    <SectionWrapper>
        <SectionTitle>{p.name}</SectionTitle>
        <SectionBody>{p.children}</SectionBody>
    </SectionWrapper>
)

export const AdminContent: React.FC<{ subTitle: string; actionButtons?: React.ReactElement[] }> = p => (
    <ArchitectContent title="Admin" subTitle={p.subTitle}>
        <PageHeader title={p.subTitle} actionButtons={p.actionButtons} />
        {p.children}
    </ArchitectContent>
)
