import * as React from "react"
import { Domain } from "@smartdevis/server/src/domain"
import { asyncConnect } from "../../resolvers"
import { useCloudAction } from "../../hooks/useCloudAction"
import { FlexRow, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Modal } from "@smartdevis/ui/src/Modal"
import { Button } from "@smartdevis/ui/src/Button"
import { i18n } from "../../services/translations"
import { ExternalLink } from "@smartdevis/ui/src/Typography"
import { termsVersion } from "../../version"

export const UpdateTermsModal = asyncConnect({
    stateResolvers: ["user", "results"],
    actions: ["updateTerms"]
})(p => {
    const conditionsUpdateRequired = !!p.user.agreedOn && p.user.agreedOn !== termsVersion
    const { actionState, onSubmit } = useCloudAction<Pick<Domain.User, "agreedOn">>((actionId, res) => {
        p.updateTerms({ actionId, ...res })
    }, p.results)

    return (
        <Modal
            visible={conditionsUpdateRequired}
            header={i18n("Update to our Policy Privacy and Terms of Service")}
            cy="update-terms-modal">
            {/* eslint-disable-next-line prettier/prettier */}
            {i18n("At Smart Devis, we believe in protecting your privacy. As we continue to improve our product and features, we've also revised our")}{" "}
            <ExternalLink href="https://smartdevis.ch/allgemeine-geschaftsbedingungen/" target="_blank">
                {i18n("Terms of Service")}
            </ExternalLink>{" "}
            {i18n("and")}{" "}
            <ExternalLink href="https://smartdevis.ch/datenschutzerklarung/" target="_blank">
                {i18n("Privacy Policy")}
            </ExternalLink>
            {"."}
            <VerticalSpace h={16} />
            {i18n("Please review the documents, and")}{" "}
            <ExternalLink href="mailto:contact@smartdevis.ch">{i18n("email us")}</ExternalLink>{" "}
            {i18n("if you have any questions.")}
            <VerticalSpace h={16} />
            <FlexRow justifyCenter>
                <Button
                    btnType="primary"
                    data-cy="update-terms-modal-btn"
                    onClick={() => onSubmit({ agreedOn: termsVersion })}
                    loading={actionState.type !== "NotStarted"}>
                    {i18n("I understand")}
                </Button>
            </FlexRow>
        </Modal>
    )
})
