import * as React from "react"
import styled from "styled-components"
import { i18n } from "../services/translations"
import { Domain } from "@smartdevis/server/src/domain"
import { asyncConnect } from "../resolvers"
import { getDevisStatus, StatusComponent } from "../views/architect/DevisList"
import { H2, H4, H6, Label } from "@smartdevis/ui/src/Typography"
import { Center, FlexColumn, FlexRow, HorizontalSpace, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { keys, SMap, values } from "@smartdevis/utils/src/map"
import { Accordion, AccordionPanel, useAccordion } from "@smartdevis/ui/src/Accordion"
import { sortCreatable } from "@smartdevis/utils/src/comparable"
import { formatDateISO } from "@smartdevis/utils/src/date"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { Button } from "@smartdevis/ui/src/Button"
import { getOfferStatusProps } from "../views/architect/devis-offers/DevisOffers.helpers"
import { PureLink } from "@smartdevis/ui/src/Link"
import { materializePath } from "../paths"

export const getStatus = (requests: SMap<Domain.ArchitectOfferRequest>, rounds?: SMap<Domain.Round>) =>
    getDevisStatus(values(requests), values(rounds).sort(sortCreatable("asc"))[0])

const Header: React.FC<{ tender: Domain.DevisOverview; compact?: boolean }> = p => (
    <FlexRow alignCenter spaceBetween>
        <FlexRow spaceBetween style={{ flex: 3, alignItems: "baseline" }}>
            {p.compact ? (
                <FlexColumn>
                    <H4>{p.tender.devis.workCategory}</H4>
                    <VerticalSpace base="16px" />
                    <H6>
                        {i18n("in")} {p.tender.projectDetails.name}
                    </H6>
                </FlexColumn>
            ) : (
                <>
                    <H4>{p.tender.devis.workCategory}</H4>
                    <HorizontalSpace base="16px" />
                    <FlexRow justifyEnd>
                        <H6>
                            {i18n("Latest action:")}
                            {" " + formatDateISO(p.tender.lastActionTs)}
                        </H6>
                        <HorizontalSpace base="32px" />
                        <H6>
                            {i18n("Entry date:")} {formatDateISO(p.tender.devis.entryTs)}
                        </H6>
                    </FlexRow>
                </>
            )}
        </FlexRow>
        <HorizontalSpace base="32px" />
        <FlexRow alignCenter style={{ flex: 2 }}>
            <StatusComponent status={getStatus(p.tender.requests, p.tender.rounds)} />
        </FlexRow>
        <HorizontalSpace base="16px" />
    </FlexRow>
)

const ShowTenderButton: React.FC<{ linkTo: string }> = p => (
    <PureLink to={p.linkTo}>
        <Button btnType="secondary">{i18n("Show tender")}</Button>
    </PureLink>
)

const Body: React.FC<{ tender: Domain.DevisOverview; compact?: boolean; allowNavigationButton?: boolean }> = p => (
    <>
        <FlexRow spaceBetween style={{ padding: "10px 15px", minHeight: "70px" }}>
            <FlexColumn style={{ flex: 3 }}>
                <Label>{i18n("Offers:")}</Label>
                <FlexColumn>
                    {keys(p.tender.contractors).length > 0 ? (
                        <Join items={values(p.tender.contractors)}>
                            {({ email, phoneNumber, contractorId }) => (
                                <FlexRow spaceBetween>
                                    <FlexRow spaceBetween>
                                        <CustomLabel>{email}</CustomLabel>
                                        <HorizontalSpace base="16px" />
                                        <CustomLabel>{phoneNumber}</CustomLabel>
                                    </FlexRow>
                                    <CustomLabel>
                                        {
                                            getOfferStatusProps(
                                                values(p.tender.requests).find(req => req.submitterId === contractorId)
                                            ).text
                                        }
                                    </CustomLabel>
                                </FlexRow>
                            )}
                        </Join>
                    ) : (
                        <FlexRow spaceBetween alignCenter>
                            <CustomLabel>{i18n("No offers available")}</CustomLabel>
                            {p.allowNavigationButton && (
                                <ShowTenderButton
                                    linkTo={materializePath("submitters", {
                                        projectId: p.tender.devis.projectId,
                                        devisId: p.tender.devis.devisId
                                    })}
                                />
                            )}
                        </FlexRow>
                    )}
                </FlexColumn>
            </FlexColumn>
            {!p.compact && (
                <>
                    <HorizontalSpace base="32px" />
                    <FlexRow spaceBetween style={{ flex: 2 }}>
                        <FlexColumn>
                            <Label>{i18n("Architect details:")}</Label>
                            {p.tender.projectDetails.planner ? (
                                <FlexColumn>
                                    <CustomLabel>{p.tender.projectDetails.planner?.companyName}</CustomLabel>
                                    <CustomLabel>{p.tender.projectDetails.planner?.email}</CustomLabel>
                                    <CustomLabel>{p.tender.projectDetails.planner?.phoneNumber}</CustomLabel>
                                </FlexColumn>
                            ) : (
                                <CustomLabel>{i18n("Not specified")}</CustomLabel>
                            )}
                        </FlexColumn>
                        <FlexColumn>
                            <Label>{i18n("Project details:")}</Label>
                            <FlexColumn>
                                <CustomLabel>
                                    {p.tender.projectDetails.number} | {p.tender.projectDetails.abbreviation}
                                </CustomLabel>
                                <CustomLabel>
                                    {p.tender.projectDetails.projectType} | {p.tender.projectDetails.city}
                                </CustomLabel>
                                <CustomLabel>
                                    {p.tender.projectDetails.street} | {p.tender.projectDetails.postalCode}
                                </CustomLabel>
                            </FlexColumn>
                        </FlexColumn>
                    </FlexRow>
                </>
            )}
        </FlexRow>
        {p.compact && keys(p.tender.contractors).length > 0 && p.allowNavigationButton && (
            <FlexRow justifyEnd style={{ padding: "10px 15px 20px 15px" }}>
                <ShowTenderButton
                    linkTo={materializePath("submitters", {
                        projectId: p.tender.devis.projectId,
                        devisId: p.tender.devis.devisId
                    })}
                />
            </FlexRow>
        )}
    </>
)

export const TenderRow = { Header, Body }

const CustomLabel = styled(Label)`
    color: ${themeColor("grey70")};
    margin-bottom: 0;
`

export const RecentTenders = asyncConnect({
    stateResolvers: ["recentTenders"],
    actions: ["navigate"],
    renderLoading: () => (
        <Center fullHeight>
            <FlexColumn alignCenter>
                <H2>{"Loading recent devis"}</H2>
                <VerticalSpace base="32px" />
                <Spinner />
            </FlexColumn>
        </Center>
    )
})(p => {
    const [open, onToggle] = useAccordion([], { multiple: true })

    return (
        <Margin values="0 0 16px 0">
            <H2>{i18n("Recent tenders activity")}</H2>
            <VerticalSpace base="16px" />
            <AccordionContainer>
                <Accordion selected={open} onChange={onToggle} animationMs={0}>
                    {values(p.recentTenders).map(t => (
                        <AccordionPanel
                            key={t.devis.devisId}
                            header={<Header tender={t} compact />}
                            iconPosition="right"
                            openedIcon="ArrowUp"
                            closedIcon="ArrowDown"
                            styles={{
                                padding: "15px"
                            }}>
                            <Body tender={t} compact allowNavigationButton />
                        </AccordionPanel>
                    ))}
                </Accordion>
            </AccordionContainer>
        </Margin>
    )
})

const AccordionContainer = styled.div`
    border: 1px solid ${themeColor("grey60")};
    border-radius: 8px;
    > div > div {
        &:first-of-type > div {
            border-top: none;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &:nth-last-of-type(-n + 2) > div {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`
