import { creatableValidation, orderableValidation } from "./common"
import {
    validString,
    validateMemberOf,
    optionalString,
    mkMapValidator,
    nullableUFloat,
    optional,
    optionalId,
    ValidationMap,
    validId,
    validStringOrNull,
    validUFloat
} from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"

export const sectionValidation: ValidationMap<Required<Domain.Section>> = {
    ...creatableValidation,
    ...orderableValidation,
    type: [validateMemberOf<Domain.Section["type"]>(["deduction", "position"])],
    refId: validId,
    sectionId: validId,
    number: optionalString,
    parentId: optionalString,
    name: validString
}

export const positionValidation: ValidationMap<Required<Domain.Position>> = {
    ...creatableValidation,
    ...orderableValidation,
    positionId: validId,
    devisId: validId,
    sectionId: validId,
    name: validString,
    description: optionalString,
    number: optionalString,
    type: [optional<Domain.PositionType>("basic")(validateMemberOf(["basic", "per", "unforeseen"]))],
    amount: validUFloat,
    unit: validString
}

export const conditionValidation: ValidationMap<Required<Domain.Condition>> = {
    ...creatableValidation,
    ...orderableValidation,
    conditionId: validId,
    projectId: optionalId,
    devisId: optionalId,
    name: validString,
    description: optionalString
}

export const generalInformationValidation: ValidationMap<Required<Domain.GeneralInformation>> = {
    ...creatableValidation,
    ...orderableValidation,
    value: validString,
    projectId: optionalId,
    devisId: optionalId,
    informationId: validId
}

export const additionalInformationValidation: ValidationMap<Required<Domain.AdditionalInformation>> = {
    ...creatableValidation,
    ...orderableValidation,
    title: validString,
    description: optionalString,
    value: [validStringOrNull],
    projectId: optionalId,
    devisId: optionalId,
    informationId: validId
}

export const deductionValidation: ValidationMap<Domain.Deduction> = {
    ...creatableValidation,
    ...orderableValidation,
    deductionId: validId,
    sectionId: validId,
    projectId: optionalId,
    devisId: optionalId,
    name: validString,
    value: nullableUFloat,
    sign: [validateMemberOf<Domain.Deduction["sign"]>(["positive", "negative"])],
    valueType: [validateMemberOf<Domain.Deduction["valueType"]>(["number", "percent"])]
}

export const validateDeduction = mkMapValidator(deductionValidation)
export const validateGeneralInformation = mkMapValidator(generalInformationValidation)
export const validateAdditionalInformation = mkMapValidator(additionalInformationValidation)
export const validatePosition = mkMapValidator(positionValidation)
export const validateCondition = mkMapValidator(conditionValidation)
export const validateSection = mkMapValidator(sectionValidation)
