import { Domain } from "@smartdevis/server/src/domain"
import { additionalInformationValidation } from "@smartdevis/server/src/models/position"
import { Row } from "../../components/table/Table"
import { mkCell } from "../../components/table/TableCell"
import { mkFormSchema } from "../../components/forms/formSchemas"
import { mkTextareaSchema } from "../../components/forms/formSchemas"
import { i18n } from "@smartdevis/client/src/services/translations"
import { getRowVisualFromDelta } from "@smartdevis/client/src/utils/devisHelpers"
import { mkRequestDeltasByRef } from "@smartdevis/server/src/models/shared"
import { getOfferRowMode, OfferRowMode } from "./ContractorSteps"
import { pickObject } from "@smartdevis/utils/src/map"
import { isOk } from "@smartdevis/utils/src/result"
import { F2 } from "@smartdevis/utils/src/types"

type ValueForm = { value: string | null }
const grid = [4, 8, 12]

const mkInformationHeaderRow = (_mode: OfferRowMode) => (): Row<"header"> => ({
    mode: "static",
    type: "header",
    grid,
    rowId: "header",
    visuals: ["header"],
    noDrag: true,
    cells: [mkCell(i18n("Title")), mkCell(i18n("Description")), mkCell(i18n("Content"))]
})

const mkAdditionalInfoSchema = () =>
    mkFormSchema<ValueForm>(pickObject(additionalInformationValidation, ["value"]), {
        value: mkTextareaSchema(i18n("Content"))
    })

export const mkAdditionalInformationContractorRowBuilder = (
    offer: Domain.ContractorOffer,
    information: Domain.OfferInformation,
    readonly: boolean,
    onChange: F2<string, string>,
    errorIds: string[] = []
) => {
    const deltas = mkRequestDeltasByRef(offer)
    const mode = getOfferRowMode(offer)

    const mkInformationRow = (ai: Domain.AdditionalInformation): Row<"information", ValueForm> => {
        const delta = deltas?.[ai.informationId]
        const deleted = delta?.type === "remove"
        const inputCell = mkCell(
            information[ai.informationId] ?? ai.value ?? "",
            mode === "final" ? ["yellowBg"] : [],
            ai.value !== null || readonly || deleted || mode === "final"
                ? undefined
                : { editMode: "formless", field: "value" }
        )

        return {
            mode: "form",
            type: "information",
            formSchema: mkAdditionalInfoSchema(),
            formValue: { value: information[ai.informationId] },
            rowId: ai.informationId,
            actionOnBlur: "submit",
            grid,
            visuals: [
                "whiteBackground",
                ...(mode === "final" ? [] : getRowVisualFromDelta(delta)),
                ...(errorIds.includes(ai.informationId) ? (["error"] as const) : [])
            ],
            cells: [mkCell(ai.title), mkCell(ai.description), inputCell],
            onChange: r => (isOk(r) ? onChange(ai.informationId, r.value.value || "") : null)
        }
    }

    return { mkInformationRow, mkHeaderRow: mkInformationHeaderRow(mode) }
}
