import dayjs from "dayjs"
import { Domain } from "@smartdevis/server/src/domain"
import { dateTimeFormat } from "@smartdevis/server/src/constants"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import {
    PositionsDocData,
    OfferComparisonDocData,
    ContractDocData,
    SubmittersDocData,
    ConditionsDocData,
    DeductionsDocData,
    NegotiationDocData,
    ProjectSubmittersData
} from "../../resolvers/docResolvers"
import { doc, h2, br, lineSeparator, h2wSubtext, pageBreakBr, bold, contractDoc } from "../../utils/pdf"
import { mkFlatDevisPositionsRows } from "../../utils/devisHelpers"
import { i18n } from "../translations"
import { mkDocRawInfo } from "./docContractRaw"
import {
    mkDocComparisonAdditionalInfo,
    mkDocComparisonDeduction,
    mkDocComparisonGross,
    mkDocComparisonNet,
    mkDocComparisonNetInclTax,
    mkDocComparisonPosition,
    mkDocComparisonPositionHeader,
    mkDocComparisonSubtotal,
    mkDocComparisonTax,
    mkDocComparisonTitle,
    mkDocConditionHeader,
    mkDocConditionRow,
    mkDocContractAttachments,
    mkDocContractDeductionsRow,
    mkDocContractInformationRow,
    mkDocContractorPreviewRow,
    mkDocContractSignaturesRow,
    mkDocDeductionHeader,
    mkDocDeductionRow,
    mkDocDevisCostEstimate,
    mkDocPositionAttachment,
    mkDocPositionHeader,
    mkDocPositionRow,
    mkDocRecommendationComment,
    mkDocRecommendationOfferSummary,
    mkDocRecommendedContractor,
    mkDocRecommendedPrice,
    mkDocSectionRow,
    mkDocSubmitterRow
} from "./docRows"
import {
    getDocPhaseDescription,
    mkContractType,
    mkDocAddressInfoDetails,
    mkDocDevisDetails,
    mkDocHeader,
    mkDocProjectDetails,
    mkDocTitle
} from "./docUtils"
import { values } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { join } from "@smartdevis/utils/src/array"
import { getTs } from "@smartdevis/utils/src/id"

export const mkSubmittersDoc = (docData: SubmittersDocData | ProjectSubmittersData) => {
    return doc(
        mkDocHeader(
            i18n("Submitters list"),
            docData.projectDetails,
            docData.devis ? docData.devis : ({ number: "", workCategory: "" } as any)
        ),
        [
            mkDocTitle(i18n("Submitters list")),
            lineSeparator(1),
            mkDocProjectDetails(docData.projectDetails),
            lineSeparator(1),
            ...(docData.devis ? [mkDocDevisDetails(docData.devis)] : []),
            lineSeparator(1),
            mkDocAddressInfoDetails(bold(i18n("Client")), docData.projectDetails.client),
            mkDocAddressInfoDetails(bold(i18n("Planner/Architect")), docData.projectDetails.planner),
            br(),
            br("medium"),
            h2(i18n("Submitters")),
            lineSeparator(1),
            ...docData.submitters.map(s => [mkDocSubmitterRow(s), lineSeparator(1)]).flat()
        ],
        docData.architectLogo
    )
}

export const mkConditionsDoc = (docData: ConditionsDocData) => {
    return doc(
        mkDocHeader(i18n("Conditions"), docData.projectDetails, docData.devis),
        [
            mkDocTitle(i18n("Conditions")),
            lineSeparator(1),
            mkDocProjectDetails(docData.projectDetails),
            lineSeparator(1),
            mkDocDevisDetails(docData.devis),
            lineSeparator(1),
            mkDocAddressInfoDetails(bold(i18n("Client")), docData.projectDetails.client),
            mkDocAddressInfoDetails(bold(i18n("Planner/Architect")), docData.projectDetails.planner),
            br(),
            br("medium"),
            h2(i18n("Prefix")),
            lineSeparator(1, [0, 5, 0, 10]),
            ...docData.generalInformation.map(g => [g.value, lineSeparator(1, [0, 6, 0, 6])]).flat(),
            br(),
            br(),
            h2(i18n("Special provisions")),
            mkDocConditionHeader(),
            lineSeparator(1, [0, 0, 0, 6]),
            ...docData.conditions.map((c, i) => [mkDocConditionRow(c, i), lineSeparator(1)]).flat(),
            br(),
            br(),
            h2(i18n("Additional information")),
            ...docData.additionalInformation
                .map(ai => [bold(ai.title), ai.description || "", ai.value ?? i18n("Contractor fills"), br("medium")])
                .flat(),
            br(),
            br("medium"),
            h2(i18n("Attachments")),
            lineSeparator(1, [0, 0, 0, 10]),
            ...docData.attachments.map(a => [a.name, lineSeparator(1, [0, 6, 0, 6])]).flat()
        ],
        docData.architectLogo
    )
}

export const mkPositionsDoc = (docData: PositionsDocData) => {
    const flattenedPositions = values(docData.sublevels).reduce<Domain.Position[]>(
        (acc, sls) => [...acc, ...(sls.filter(sl => sl.basedOn === "position") as Domain.Position[])],
        []
    )
    return doc(
        mkDocHeader(i18n("Positions"), docData.projectDetails, docData.devis),
        [
            mkDocTitle(i18n("Positions")),
            lineSeparator(1),
            mkDocProjectDetails(docData.projectDetails),
            lineSeparator(1),
            mkDocDevisDetails(docData.devis),
            lineSeparator(1),
            mkDocAddressInfoDetails(bold(i18n("Client")), docData.projectDetails.client),
            mkDocAddressInfoDetails(bold(i18n("Planner/Architect")), docData.projectDetails.planner),
            br(),
            br("medium"),
            h2(i18n("Positions")),
            mkFlatDevisPositionsRows(
                docData.sections,
                docData.sublevels,
                section => [br(), mkDocSectionRow(section), lineSeparator(1), mkDocPositionHeader(), lineSeparator(1)],
                subsection => [mkDocSectionRow(subsection)],
                position => [mkDocPositionRow(position)]
            ).flat(),
            br(),
            br("medium"),
            h2(i18n("Attachments")),
            lineSeparator(1, [0, 0, 0, 10]),
            ...flattenedPositions
                .map(fp => {
                    const a = docData.attachments.find(at => at.refId === fp.positionId)
                    return a ? mkDocPositionAttachment(a, fp) : null
                })
                .filter(isDefined)
        ],
        docData.architectLogo
    )
}

export const mkDeductionsDoc = (docData: DeductionsDocData) =>
    doc(
        mkDocHeader(i18n("Deductions"), docData.projectDetails, docData.devis),
        [
            mkDocTitle(i18n("Deductions")),
            lineSeparator(1),
            mkDocProjectDetails(docData.projectDetails),
            lineSeparator(1),
            mkDocDevisDetails(docData.devis),
            lineSeparator(1),
            mkDocAddressInfoDetails(bold(i18n("Client")), docData.projectDetails.client),
            mkDocAddressInfoDetails(bold(i18n("Planner/Architect")), docData.projectDetails.planner),
            br(),
            br("medium"),
            h2(i18n("Deductions")),
            mkDocDeductionHeader(),
            lineSeparator(1),
            docData.sections
                .filter(section => !!docData.deductionsBySection[section.sectionId])
                .map((section, sectionIndex) => [
                    mkDocSectionRow(section, `${sectionIndex + 1}`),
                    docData.deductionsBySection[section.sectionId].map((deduction, deductionIndex) =>
                        mkDocDeductionRow(deduction, `${sectionIndex + 1}.${deductionIndex + 1}`)
                    ),
                    lineSeparator(1)
                ])
                .flat()
        ],
        docData.architectLogo
    )

export const mkOfferComparisonDoc = (docData: OfferComparisonDocData) => {
    const { calculations, deductionSections, deductionsBySection, submitters } = docData

    return doc(
        mkDocHeader(i18n("Offer comparison"), docData.projectDetails, docData.devis),
        [
            mkDocTitle(i18n("Offer comparison")),
            lineSeparator(1),
            mkDocProjectDetails(docData.projectDetails),
            lineSeparator(1),
            mkDocDevisDetails(docData.devis),
            lineSeparator(1),
            mkDocAddressInfoDetails(bold(i18n("Client")), docData.projectDetails.client),
            mkDocAddressInfoDetails(bold(i18n("Planner/Architect")), docData.projectDetails.planner),
            br(),
            br("medium"),
            mkDocComparisonTitle(submitters, i18n("Deductions")),
            lineSeparator(1, [0, 3, 0, 6]),
            mkDocComparisonGross(submitters, calculations),
            deductionSections
                .map((section, i) => [
                    ...(deductionsBySection[section.sectionId]?.map(
                        mkDocComparisonDeduction(submitters, calculations)
                    ) ?? []),
                    lineSeparator(1, [0, 3, 0, 6]),
                    i === deductionSections.length - 1
                        ? mkDocComparisonNet(submitters, calculations)
                        : mkDocComparisonSubtotal(submitters, calculations, section.sectionId)
                ])
                .flat(),
            mkDocComparisonTax(submitters, calculations),
            lineSeparator(1, [0, 3, 0, 6]),
            mkDocComparisonNetInclTax(submitters, calculations),
            lineSeparator(1, [0, 3, 0, 0]),
            br(),
            br("medium"),
            mkDocComparisonTitle(submitters, i18n("Positions")),
            lineSeparator(1, [0, 3, 0, 6]),
            mkFlatDevisPositionsRows(
                docData.positionSections,
                docData.sublevels,
                section => [
                    mkDocSectionRow(section, section.number, "7%"),
                    lineSeparator(1, [0, 3, 0, 6]),
                    mkDocComparisonPositionHeader(submitters),
                    lineSeparator(1, [0, 3, 0, 6])
                ],
                subsection => [mkDocSectionRow(subsection, subsection.number, "7%")],
                pos => [mkDocComparisonPosition(pos, submitters, calculations), lineSeparator(1, [0, 3, 0, 6])]
            ).flat(),
            br(),
            br("medium"),
            mkDocComparisonTitle(submitters, i18n("Additional information")),
            lineSeparator(1, [0, 3, 0, 6]),
            docData.additionalInformation
                .map(ai => [
                    mkDocComparisonAdditionalInfo(ai, submitters, calculations),
                    lineSeparator(1, [0, 3, 0, 6])
                ])
                .flat()
        ],
        docData.architectLogo
    )
}

const mkRecommendedContractorDetails = (
    docData: NegotiationDocData,
    contractor?: Domain.Contractor,
    comment?: string
) => {
    if (!contractor) return []
    const { dataBySubmitter, devis } = docData
    const costEstimateRow = devis.costEstimateRevised
        ? [mkDocDevisCostEstimate(devis), lineSeparator(1, [0, 3, 0, 6])]
        : []
    const commentRow = comment ? [mkDocRecommendationComment(comment), lineSeparator(1, [0, 3, 0, 6])] : []
    return [
        lineSeparator(1, [0, 0, 0, 6]),
        mkDocRecommendedContractor(contractor),
        lineSeparator(1, [0, 3, 0, 6]),
        mkDocRecommendedPrice(dataBySubmitter[contractor.contractorId].finalCalculations),
        lineSeparator(1, [0, 3, 0, 6]),
        ...costEstimateRow,
        ...commentRow
    ]
}

export const mkNegotiationDoc = (docData: NegotiationDocData, prefferedSubmitterId: string, comment: string) => {
    const chosen = docData.submitters.find(s => s.contractorId === prefferedSubmitterId)

    return doc(
        mkDocHeader(i18n("Contractor recommendation"), docData.projectDetails, docData.devis),
        [
            mkDocTitle(i18n("Contractor recommendation")),
            lineSeparator(1),
            mkDocProjectDetails(docData.projectDetails),
            lineSeparator(1),
            mkDocDevisDetails(docData.devis),
            lineSeparator(1),
            mkDocAddressInfoDetails(bold(i18n("Client")), docData.projectDetails.client),
            mkDocAddressInfoDetails(bold(i18n("Planner/Architect")), docData.projectDetails.planner),
            br(),
            br("medium"),
            h2(i18n("Recommended contractor")),
            mkRecommendedContractorDetails(docData, chosen, comment),
            br(),
            pageBreakBr("medium"),
            ...docData.submitters.map(s => {
                const { finalCalculations: calc, ...data } = docData.dataBySubmitter[s.contractorId]
                const negotiationPhase = data.request.statesHistory.find(h => isOfferInState(h, ["negotiation"]))
                const roundPhase = data.request.statesHistory.find(h => isOfferInState(h, ["round-submitted"]))
                const initialPhase = data.request.statesHistory.find(h => isOfferInState(h, ["submitted"]))
                const lastPhase = negotiationPhase ?? roundPhase ?? initialPhase
                return [
                    lineSeparator(1, [0, 3, 0, 6]),
                    mkDocContractorPreviewRow(s, data.deductionSections, data.deductionsBySection, calc),
                    mkDocRecommendationOfferSummary(getDocPhaseDescription(lastPhase), calc.netincltax),
                    lineSeparator(1, [0, 3, 0, 6]),
                    negotiationPhase
                        ? mkDocRecommendationOfferSummary(getDocPhaseDescription(roundPhase), data.roundNet)
                        : [],
                    roundPhase
                        ? mkDocRecommendationOfferSummary(getDocPhaseDescription(initialPhase), data.initialNet)
                        : [],
                    negotiationPhase || roundPhase ? lineSeparator(1, [0, 3, 0, 30]) : []
                ]
            })
        ],
        docData.architectLogo
    )
}

export const mkContractDoc = async (docData: ContractDocData, name: string) =>
    contractDoc(
        mkDocHeader(name, docData.projectDetails, docData.devis),
        [
            mkDocTitle(name),
            lineSeparator(1),
            mkDocProjectDetails(docData.projectDetails),
            lineSeparator(1),
            mkDocDevisDetails(docData.devis),
            lineSeparator(1),
            br(),
            br("medium"),
            lineSeparator(1),
            mkDocAddressInfoDetails(
                ["Werkvertrag zwischen", bold("der Bauherrschaft"), bold("als Auftraggeber")],
                docData.projectDetails.client
            ),
            mkDocAddressInfoDetails(
                ["vertreten durch", bold("die Bauleitung")],
                docData.projectDetails[docData.meta?.clientsRepresentative ?? "planner"]
            ),
            mkDocAddressInfoDetails(["und", bold("dem Unternehmer"), bold("als Auftragnehmer")], docData.contractor),
            docData.projectDetails.specialistPlanner
                ? mkDocAddressInfoDetails(i18n("Specialist planner"), docData.projectDetails.specialistPlanner)
                : "",
            br("large"),
            br("medium"),
            h2wSubtext(
                i18n("{contract doc}Contract amount"),
                `Als Grundlage gilt dieser Vertrag und das Angebot vom ${
                    docData.submittedTs ? dayjs(docData.submittedTs).format(dateTimeFormat) : ""
                }`
            ),
            mkContractType(docData.meta),
            lineSeparator(1, [0, 0, 0, 6]),
            mkDocContractDeductionsRow(docData.deductionSections, docData.deductionsBySection, docData.calculation),
            lineSeparator(1),
            mkDocContractSignaturesRow(
                docData.projectDetails.client,
                docData.projectDetails.planner,
                docData.contractor
            ),
            pageBreakBr("medium"),
            mkDocRawInfo(docData.devis),
            br("large"),
            ...join(
                docData.additionalInformation.map(ai => mkDocContractInformationRow(ai, docData.calculation)),
                br("medium")
            ),
            pageBreakBr("medium"),
            h2(i18n("Conditions")),
            bold(i18n("Prefix")),
            br("medium"),
            lineSeparator(1, [0, 5, 0, 10]),
            ...docData.generalInformation.map(g => [g.value, lineSeparator(1, [0, 6, 0, 6])]).flat(),
            br(),
            br(),
            bold(i18n("Special provisions")),
            br("medium"),
            mkDocConditionHeader(),
            lineSeparator(1, [0, 0, 0, 6]),
            ...docData.conditions.map((c, i) => [mkDocConditionRow(c, i), lineSeparator(1)]).flat(),
            pageBreakBr("medium"),
            h2(i18n("Positions")),
            mkFlatDevisPositionsRows(
                docData.positionSections,
                docData.sublevels,
                section => [br(), mkDocSectionRow(section), lineSeparator(1), mkDocPositionHeader(), lineSeparator(1)],
                subsection => [mkDocSectionRow(subsection)],
                position => [mkDocPositionRow(position, docData.calculation)]
            ).flat(),
            mkDocContractAttachments(docData.attachments, docData.sublevels, docData.conditions)
        ],
        docData.architectLogo
    )

export const mkOfferPreviewDoc = async (docData: ContractDocData) =>
    contractDoc(mkDocHeader("Angebot", docData.projectDetails, docData.devis), [
        mkDocTitle(i18n("Angebot")),
        lineSeparator(1),
        mkDocProjectDetails(docData.projectDetails),
        lineSeparator(1),
        mkDocDevisDetails(docData.devis),
        lineSeparator(1),
        br(),
        br("medium"),
        lineSeparator(1),
        mkDocAddressInfoDetails([bold("Bauherrschaft"), bold("Auftraggeber")], docData.projectDetails.client),
        mkDocAddressInfoDetails(
            ["vertreten durch", bold("die Bauleitung")],
            docData.projectDetails[docData.meta?.clientsRepresentative ?? "planner"]
        ),
        br("large"),
        br("medium"),
        h2wSubtext("Angebot", `Angebot vom ${dayjs(docData.submittedTs || getTs()).format(dateTimeFormat)}`),
        mkContractType(docData.meta),
        lineSeparator(1, [0, 0, 0, 6]),
        mkDocContractDeductionsRow(docData.deductionSections, docData.deductionsBySection, docData.calculation),
        lineSeparator(1),
        pageBreakBr("medium"),
        mkDocRawInfo(docData.devis),
        br("large"),
        ...join(
            docData.additionalInformation.map(ai => mkDocContractInformationRow(ai, docData.calculation)),
            br("medium")
        ),
        pageBreakBr("medium"),
        h2(i18n("Conditions")),
        bold(i18n("Prefix")),
        br("medium"),
        lineSeparator(1, [0, 5, 0, 10]),
        ...docData.generalInformation.map(g => [g.value, lineSeparator(1, [0, 6, 0, 6])]).flat(),
        br(),
        br(),
        bold(i18n("Special provisions")),
        br("medium"),
        mkDocConditionHeader(),
        lineSeparator(1, [0, 0, 0, 6]),
        ...docData.conditions.map((c, i) => [mkDocConditionRow(c, i), lineSeparator(1)]).flat(),
        pageBreakBr("medium"),
        h2(i18n("Positions")),
        mkFlatDevisPositionsRows(
            docData.positionSections,
            docData.sublevels,
            section => [br(), mkDocSectionRow(section), lineSeparator(1), mkDocPositionHeader(), lineSeparator(1)],
            subsection => [mkDocSectionRow(subsection)],
            position => [mkDocPositionRow(position, docData.calculation)]
        ).flat(),
        mkDocContractAttachments(docData.attachments, docData.sublevels, docData.conditions)
    ])
