import { Creatable } from "./comparable"
import { Result, ErrType } from "./result"
import { EmptyObject, State, ValueState } from "./types"
import { getTs } from "./id"

export type Action<T = string> = { type: T }
export interface AnyAction extends Action {
    [extraProps: string]: any
}

export type PayloadAction<A = string, P = any, M = any> = { payload: P; meta: M } & Action<A>
export function createAction<A extends string>(type: A): PayloadAction<A, EmptyObject>
export function createAction<A extends string, P, Meta extends EmptyObject = EmptyObject>(
    type: A,
    payload: P,
    meta?: Meta
): PayloadAction<A, P, Meta>

export function createAction(type: any, payload?: any, meta?: any) {
    return payload !== undefined ? (meta !== undefined ? { type, payload, meta } : { type, payload }) : { type }
}

export type Reducer<T, A extends Action = Action> = (state: T, action: A) => T

export type EventActionMeta = { actionId: string; lang?: string }
export type EventActionParams = { senderId: string }

export type EventAction<A = string, P = any, M extends EventActionMeta = EventActionMeta> = PayloadAction<A, P, M>

export type EventResult = Result<any> & { meta: Creatable & EventActionMeta }

export type AsyncEvent<T = EventResult> =
    | State<"NotStarted" | "Processing">
    | ValueState<"Done", T>
    | ValueState<ErrType, string>

export const createEventAction =
    <A extends string, P>(type: A, payload: P) =>
    (actionId: string) =>
        createAction<A, P, EventActionMeta>(type, payload, { actionId, lang: "en" })

export const mkEventResult = (
    result: Result<any>,
    { actionId, lang }: EventActionMeta,
    createdTs = 0
): EventResult => ({
    ...result,
    meta: {
        actionId,
        lang,
        createdTs: createdTs || getTs()
    }
})
