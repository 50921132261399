import * as React from "react"
import styled from "styled-components"
import { themeColor } from "./utils/theme"
import { HorizontalSpace } from "./utils/common"
import { Asset } from "./Asset"
import { InlineSpinner } from "./Spinner"
import { ExternalLink } from "./Typography"
import { ButtonType, Button, IconButton } from "./Button"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { sortCreatable } from "@smartdevis/utils/src/comparable"
import { F0, F1 } from "@smartdevis/utils/src/types"

const DEFAULT_MAX_FILE_SIZE = 50_000_000
const PLACEHOLDER_THUMB =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2P4////fwAJ+wP9BUNFygAAAABJRU5ErkJggg=="

const isImage = (file: Pick<File, "name">) => ["jpg", "jpeg", "png", "gif"].includes(file.name.split(".").pop() || "")

const FilesWrapper = styled.div`
    overflow: auto;
    padding: 16px 0;
`

const StatusIcon = styled(Asset)`
    margin-right: 0.5em;
`

const FileThumb = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 1em;
    border-radius: 4px;
    object-fit: cover;
`

const FileName = styled.span`
    min-width: 0;
    margin-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${themeColor("primaryGrey")};
`

const FileWrapper = styled(ExternalLink)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    border-radius: 4px;
    text-decoration: none;
    flex-direction: row;
    transition: background-color 0.1s ease;

    &:hover {
        background-color: ${themeColor("grey35")};
    }
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

type AsyncFileCommon = { name: string; uid: string; createdTs: number }
export type UploadingFile = { type: "uploading"; file: File } & AsyncFileCommon
export type UploadedFile = {
    type: "uploaded"
    url: string
    contentType: string
    fileSize: number
} & AsyncFileCommon
export type AsyncFile = UploadingFile | UploadedFile

export type AvailableExtension =
    | ".png"
    | ".jpg"
    | ".jpeg"
    | ".gif"
    | ".doc"
    | "image/jpeg"
    | ".pdf"
    | ".xlsx"
    | ".e1s"
    | ".csv"
    | string

export type UploadRestrictions = {
    maxFileSize: number
    maxFilesCount: number
    disallowMultiple: boolean
    acceptableExtensions: AvailableExtension[]
    forbiddenExtensions: AvailableExtension[]
}

export type UploadProps = {
    restrictions?: Partial<UploadRestrictions>
    onUpload: F1<UploadingFile[]>
    onRemove?: (fileId: string) => void
    isReadonly?: boolean
    files: AsyncFile[]
    uploadButtonTitle: string
    buttonType?: ButtonType | "icon-button"
    onFileSizeRestricted?: F0
}

export type FileListProps = Pick<UploadProps, "onRemove" | "files">

export const FileList: React.FC<FileListProps> = ({ onRemove, files }) => {
    const handleFileRemoval = async (e: React.MouseEvent<HTMLSpanElement>, file: any) => {
        e.preventDefault()
        onRemove?.(file.uid)
    }
    const renderThumbnail = (file: AsyncFile) => (
        <FileThumb
            alt={file.name}
            src={
                file.type === "uploading"
                    ? PLACEHOLDER_THUMB
                    : isImage({ name: file.name })
                    ? file.url || PLACEHOLDER_THUMB
                    : PLACEHOLDER_THUMB
            }
        />
    )

    return (
        <FilesWrapper>
            {files.map(file => (
                <FileWrapper
                    key={file.type + file.uid}
                    download
                    target="_blank"
                    href={file.type === "uploaded" ? file.url : ""}>
                    <TitleWrapper>
                        {renderThumbnail(file)}
                        <FileName>{file.name}</FileName>
                        {file.type === "uploaded" ? (
                            <StatusIcon name="Download" size="icon" color="action" />
                        ) : (
                            <InlineSpinner color="action" />
                        )}
                    </TitleWrapper>
                    <HorizontalSpace base="8px" />
                    {onRemove ? <IconButton icon="Delete" onClick={e => handleFileRemoval(e, file)} /> : null}
                </FileWrapper>
            ))}
        </FilesWrapper>
    )
}

export type UploadButtonProps = Pick<
    UploadProps,
    "restrictions" | "onUpload" | "uploadButtonTitle" | "onFileSizeRestricted" | "buttonType"
>

const UploadButton: React.FC<UploadButtonProps> = ({
    restrictions,
    onUpload,
    uploadButtonTitle,
    onFileSizeRestricted,
    buttonType
}) => {
    const fileInput = React.useRef<HTMLInputElement | null>(null)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target
        if (files && files.length > 0) {
            const retrievedFiles: File[] = Array.from(files)
            const uploadingFiles: UploadingFile[] = retrievedFiles
                .filter(file => (restrictions?.maxFileSize ? file.size <= restrictions.maxFileSize : true))
                .map(file => ({
                    type: "uploading",
                    name: file.name,
                    file,
                    uid: genTemporaryId(),
                    createdTs: getTs()
                }))

            if (onFileSizeRestricted && retrievedFiles.length !== uploadingFiles.length) {
                onFileSizeRestricted()
            }

            onUpload(uploadingFiles)
        }
    }

    return (
        <>
            {buttonType === "icon-button" ? (
                <IconButton icon="CrossWhite" onClick={() => fileInput.current?.click()}>
                    {uploadButtonTitle}
                </IconButton>
            ) : (
                <Button btnType={buttonType} onClick={() => fileInput.current?.click()}>
                    {uploadButtonTitle}
                </Button>
            )}
            <input
                type="file"
                ref={fileInput}
                value={""}
                onChange={handleFileChange}
                multiple={!restrictions?.disallowMultiple}
                accept={restrictions?.acceptableExtensions?.join(",")}
                style={{ display: "none" }}
            />
        </>
    )
}

export const Upload: React.FC<UploadProps> = ({
    onUpload,
    onRemove,
    isReadonly,
    restrictions = { maxFileSize: DEFAULT_MAX_FILE_SIZE },
    files,
    uploadButtonTitle,
    buttonType = "icon-button",
    onFileSizeRestricted
}) => {
    const { maxFilesCount } = restrictions
    return (
        <>
            <FileList onRemove={onRemove} files={files.sort(sortCreatable("desc"))} />
            {!isReadonly && (!maxFilesCount || files.length < maxFilesCount) && (
                <UploadButton
                    onUpload={onUpload}
                    restrictions={restrictions}
                    uploadButtonTitle={uploadButtonTitle}
                    buttonType={buttonType}
                    onFileSizeRestricted={onFileSizeRestricted}
                />
            )}
        </>
    )
}
