import * as React from "react"
import styled from "styled-components"
import { i18n } from "@smartdevis/client/src/services/translations"
import { Domain } from "@smartdevis/server/src/domain"
import { Asset, ExternalAsset } from "@smartdevis/ui/src/Asset"
import { Button } from "@smartdevis/ui/src/Button"
import { Dropdown, mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { ConfirmationModal } from "@smartdevis/ui/src/Modal"
import { ExternalLink, P, Pre } from "@smartdevis/ui/src/Typography"
import { Flex, FlexColumn, FlexRow, GridContainer, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { values } from "@smartdevis/utils/src/map"
import { F1 } from "@smartdevis/utils/src/types"
import { ArchitectContent, SubContent } from "../../components/layouts/Content"
import { PageHeader } from "../../components/layouts/Header"
import { RouteParams } from "../../paths"
import { asyncConnect } from "../../resolvers"
import { ContractorOperationModal } from "../../components/modals/ContractorOperationModal"

export type ContractorOperation = {
    type: "add" | "delete" | "update"
    id: string | undefined
    contractor: Domain.DirectoryContractor | undefined
}
export const DirectoryView = asyncConnect({
    stateResolvers: ["api", "auth", "contractorsDirectory", "language"],
    actions: ["addContractor", "deleteContractor", "refetchContractorsDirectory"]
})<RouteParams>(p => {
    const [isContractorAddEditModalVisible, setContractorAddEditModalVisible] = React.useState(false)
    const [isContractorDeleteModalVisible, setContractorDeleteModalVisible] = React.useState(false)
    const [contractorOperation, setContractorOperation] = React.useState<ContractorOperation>({
        type: "add",
        id: undefined,
        contractor: undefined
    })

    React.useEffect(() => {
        if (p.api.status === "contractorsDirectoryFetched") {
            p.refetchContractorsDirectory()
            setContractorAddEditModalVisible(false)
        }
    }, [p.api.status])

    const setContractorOperationModalVisible = (actionType: "add-edit" | "delete") => {
        if (actionType === "add-edit") {
            setContractorAddEditModalVisible(true)
        } else {
            setContractorDeleteModalVisible(true)
        }
    }

    const isCDAdmin = p.auth.type === "Authenticated" && p.auth.value.type === "CD_MODERATOR"

    return (
        <>
            <ArchitectContent>
                <SubContent>
                    <FlexRow spaceBetween>
                        <PageHeader
                            title={i18n("Contractors Directory")}
                            subTitle={i18n("All the Contractors from our Partner Network can be seen here!")}
                        />
                        {isCDAdmin && (
                            <ButtonWrapper>
                                <Button
                                    onClick={() => {
                                        setContractorOperation({ type: "add", id: undefined, contractor: undefined })
                                        setContractorOperationModalVisible("add-edit")
                                    }}>
                                    {i18n("Add contractor")}
                                </Button>
                            </ButtonWrapper>
                        )}
                    </FlexRow>
                    <FlexRow>
                        <Right80>
                            {values(p.contractorsDirectory).length ? (
                                Object.entries(p.contractorsDirectory).map(([key, contractor]) => (
                                    <Contractor
                                        id={key}
                                        isEditable={isCDAdmin}
                                        key={key}
                                        language={p.language}
                                        onDropdownButtonAction={setContractorOperation}
                                        onDropdownButtonClick={setContractorOperationModalVisible}
                                        value={contractor}
                                    />
                                ))
                            ) : (
                                <P small>{i18n("Contractors Directory is empty!")}</P>
                            )}
                        </Right80>
                    </FlexRow>
                </SubContent>
            </ArchitectContent>
            {isContractorAddEditModalVisible && (
                <ContractorOperationModal
                    contractorOperation={contractorOperation}
                    header={contractorOperation.type === "add" ? i18n("Add contractor") : i18n("Edit contractor")}
                    onModalClose={setContractorAddEditModalVisible}
                    visible={isContractorAddEditModalVisible}
                />
            )}
            <ConfirmationModal
                cancelText={i18n("Cancel")}
                contentText={i18n("Are you sure you want to delete this contractor?")}
                header={i18n("Remove contractor")}
                loading={p.api.status === "fetching"}
                onClose={() => setContractorDeleteModalVisible(false)}
                onSubmit={() => {
                    p.deleteContractor(contractorOperation.id)
                    setContractorDeleteModalVisible(false)
                }}
                submitText={i18n("Confirm")}
                visible={isContractorDeleteModalVisible}>
                <VerticalSpace base="8px" />
                <Pre>{contractorOperation?.contractor?.companyName}</Pre>
            </ConfirmationModal>
        </>
    )
})

const Contractor: React.FC<{
    id: string
    isEditable: boolean
    language: string
    onDropdownButtonAction: F1<any>
    onDropdownButtonClick: F1<"add-edit" | "delete">
    value: Domain.DirectoryContractor
}> = p => {
    const workCategories: string[] =
        p.language === "en" ? p.value.workCategoriesEn.split(",") : p.value.workCategoriesDe.split(",")

    return (
        <PartnerContainer columnsGrid={[0.5, 1, 1, 0.8, 0.1]} gap="36px">
            {p.value.logoLink ? (
                <ExternalAsset size="huge-icon" src={p.value.logoLink} />
            ) : (
                <InitialsContainer>{p.value.companyInitials}</InitialsContainer>
            )}
            <FlexColumn>
                <P>{p.value.companyName}</P>
                <VerticalSpace base="8px" />
                <P small>
                    {p.value.street} {p.value.streetNumber}
                </P>
                <P small>
                    {p.value.companyPostcode} {p.value.companyCity}
                </P>
                <P small>{p.value.companyCountry}</P>
            </FlexColumn>
            <FlexColumn>
                <P small style={{ fontWeight: "bold" }}>
                    {i18n("Contact")}
                </P>
                <P small>{`${p.value.contactFirstName} ${p.value.contactLastName}`}</P>
                <P small>{p.value.contactPhone}</P>
                <ExternalLink href={`mailto:${p.value.contactEmail}`}>{p.value.contactEmail}</ExternalLink>
            </FlexColumn>
            <FlexColumn>
                <P small style={{ fontWeight: "bold" }}>
                    {i18n("Work Categories")}
                </P>
                {workCategories.map((k, i) => (
                    <P key={i} small>
                        {k}
                    </P>
                ))}
            </FlexColumn>
            <FlexColumn>
                {p.isEditable && (
                    <Dropdown
                        options={[
                            mkDropdownOption(i18n("Edit"), "edit", () => {
                                p.onDropdownButtonAction({
                                    type: "update",
                                    id: p.id,
                                    contractor: p.value
                                })
                                p.onDropdownButtonClick("add-edit")
                            }),
                            mkDropdownOption(i18n("Remove"), "remove", () => {
                                p.onDropdownButtonAction({
                                    type: "delete",
                                    id: p.id,
                                    contractor: p.value
                                })
                                p.onDropdownButtonClick("delete")
                            })
                        ]}>
                        <FlexRow alignCenter>
                            <Asset name="DotsFilled" size="icon" />
                        </FlexRow>
                    </Dropdown>
                )}
            </FlexColumn>
        </PartnerContainer>
    )
}

const ButtonWrapper = styled.div`
    margin: 20px 0 0;
`

const InitialsContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    width: 6rem;
    height: 6rem;
    transition: color 0.3s;
    color: #ffffff;
    pointer-events: none;
    background-color: #485156;
    text-transform: uppercase;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
`

const PartnerContainer = styled(GridContainer)`
    border: 1px solid ${themeColor("grey60")};
    border-radius: 2px;
    padding: 18px 24px;
    padding: 18px 24px 24px;
    width: 100%;
    margin: 8px 0;
`

const Right80 = styled(Flex)`
    width: 100%;
    flex-direction: column;
    padding: 24px 0 0 0;
    flex: 1;
`
