export { default as DotsFilled } from "../assets/dots-filled.svg"
export { default as Dots } from "../assets/dots.svg"
export { default as Draggable } from "../assets/draggable.svg"
export { default as CrossFilledBordered } from "../assets/cross-filled-bordered.svg"
export { default as CrossFilledDark } from "../assets/cross-filled-dark.svg"
export { default as DashFilledWhite } from "../assets/dash-filled-white.svg"
export { default as CrossFilledRed } from "../assets/cross-filled-red.svg"
export { default as IconChangeRequested } from "../assets/icon-change-requested.svg"
export { default as IconCheck } from "../assets/icon-check.svg"
export { default as IconCreated } from "../assets/icon-created.svg"
export { default as IconFile } from "../assets/icon-file.svg"
export { default as IconExternal } from "../assets/icon-external.svg"
export { default as IconProgress } from "../assets/icon-progress.svg"
export { default as IconReceived } from "../assets/icon-received.svg"
export { default as IconRejected } from "../assets/icon-rejected.svg"
export { default as IconRefresh } from "../assets/icon-refresh.svg"
export { default as IconSubmitted } from "../assets/icon-submitted.svg"
export { default as Stretchable } from "../assets/stretchable.svg"
export { default as Delete } from "../assets/delete.svg"
export { default as DeleteWhite } from "../assets/delete-white.svg"
export { default as FilePdf } from "../assets/file-pdf.svg"
export { default as ArrowLeft } from "../assets/arrow-left.svg"
export { default as Check } from "../assets/check.svg"
export { default as CheckBadge } from "../assets/check-badge.svg"
export { default as ArrowDownFilled } from "../assets/arrow-down-filled.svg"
export { default as Message } from "../assets/message.svg"
export { default as Edit } from "../assets/edit.svg"
export { default as EditWhite } from "../assets/edit-white.svg"
export { default as SmartdevisLogo } from "../assets/sd-logo.svg"
export { default as SubItemLine } from "../assets/sub-item-line.svg"
export { default as EmptyPlaceholder } from "../assets/lines.svg"
export { default as FolderFile } from "../assets/folder-file.edit.svg"
export { default as SendEmail } from "../assets/send-email.svg"
export { default as Close } from "../assets/close.svg"
export { default as EyeCrossed } from "../assets/eye-crossed.svg"
export { default as Search } from "../assets/search.svg"
export { default as CrossWhite } from "../assets/cross-white.svg"
export { default as DashWhite } from "../assets/dash-white.svg"
export { default as IconFolder } from "../assets/icon-folder.svg"
export { default as IconPerson } from "../assets/icon-person.svg"
export { default as IconCalendar } from "../assets/icon-calendar.svg"
export { default as IconHash } from "../assets/icon-hash.svg"
export { default as CheckGreen } from "../assets/check-green.svg"
export { default as ContractOk } from "../assets/contract-ok.svg"
export { default as Copy } from "../assets/copy.svg"
export { default as CopyWhite } from "../assets/copy-white.svg"
export { default as Briefcase } from "../assets/briefcase.svg"
export { default as Comment } from "../assets/comment.svg"
export { default as IconPendingGrey } from "../assets/icon-pending-grey.svg"
export { default as IconArrowGrey } from "../assets/icon-arrow-grey.svg"
export { default as IconReviewGrey } from "../assets/icon-review-grey.svg"
export { default as IconCheckGrey } from "../assets/icon-check-grey.svg"
export { default as Dashboard } from "../assets/dashboard.svg"
export { default as DashboardRed } from "../assets/dashboard-red.svg"
export { default as FileTextNew } from "../assets/icon-file-text-new.svg"
export { default as IconDevisFreetext } from "../assets/icon-devis-freetext.svg"
export { default as IconDevisTemplate } from "../assets/icon-devis-template.svg"
export { default as IconDevisImport } from "../assets/icon-devis-import.svg"
export { default as IconDevisUpload } from "../assets/icon-devis-upload.svg"
