import { isEqual } from "./misc"
import { F1 } from "./types"
import _intersection from "lodash/intersection"

const isArray = <T>(v: any | T[]): v is T[] => Array.isArray(v)

export const toggleArray = <T>(ts: T[], t: T): T[] => ((ts || []).includes(t) ? ts.filter(e => e !== t) : ts.concat(t))

export const replaceInArray = <T>(ts: T[], newT: T, find: F1<T, boolean>): T[] => {
    if (!ts.find(find)) return ts
    const indexOfReplacedItem = ts.findIndex(find)
    ts.splice(indexOfReplacedItem, 1)
    return [newT, ...ts]
}

export const chunkArr = <T>(array: T[], count = 500): T[][] => {
    if (count == null || count < 1) return []
    const result = []
    let i = 0
    const length = array.length
    while (i < length) {
        result.push(Array.prototype.slice.call(array, i, (i += count)))
    }
    return result
}

export const inArray = <T, T2 extends T>(singleValue: T, array: readonly T2[]) => array.includes(singleValue as any)

export const intersection = _intersection

export const join = <T>(array: T[], joining: T): T[] =>
    array.flatMap((v, i) => (i === array.length - 1 ? [v] : [v, joining]))

export const arrify = <T>(ts: T[] | T): T[] => (Array.isArray(ts) ? ts : [ts])

export const joinArrays = <T>(arr1: T[], arr2: T[], compare: (a: T, b: T) => boolean = (a, b) => a === b): T[] => {
    const res: T[] = [...arr1]
    arr2.forEach(a2 => {
        if (!arr1.find(a1 => compare(a1, a2))) res.push(a2)
    })
    return res
}
export const flatten = <T>(vs: Array<T | T[]>, depth = 2, filter: (v: T | T[]) => boolean = _ => true): T[] =>
    vs.reduce(
        (acc: any, toFlatten) =>
            isArray(toFlatten) && depth - 1 > 0
                ? acc.concat(flatten(toFlatten, depth - 1, filter))
                : acc.concat(isArray(toFlatten) ? toFlatten : filter(toFlatten) ? [toFlatten] : []),
        [] as T[]
    )

export const replace = <T>(vs: T[], index: number, v: T): T[] => {
    if (!vs || index >= vs.length || index < 0) return vs
    const cp = [...vs]
    cp[index] = v
    return cp
}

export const last = <T>(vs: T[] = []) => (vs.length > 0 ? vs[vs.length - 1] : undefined)

export const unique = <T>(arr: T[]) =>
    arr.reduce<T[]>((acc, v) => (acc.find(f => isEqual(f, v)) ? acc : acc.concat(v)), [])
