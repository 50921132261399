import { Domain } from "./domain"
import { sharedPaths as paths } from "./constants"
const cleanUp = (url: string) => (url.endsWith("/") ? url.slice(0, -1) : url)

export const mkAppUrls = (baseUrl: string) => {
    const mk = (vs: string[]) => vs.map(cleanUp).join("/")
    const loginUrl = mk([baseUrl, paths.login])

    type DevisParams = Pick<Domain.ProjectDetails, "projectId"> & Pick<Domain.Devis, "devisId">
    const getDevisUrl = (p: DevisParams) => mk([baseUrl, paths.projects, p.projectId, paths.devis, p.devisId])
    const mkDevisReviewUrl = (p: DevisParams) => mk([getDevisUrl(p), paths.offers])
    const mkDevisOfferRequestsUrl = (p: DevisParams) => mk([getDevisUrl(p), paths.requests])
    const mkContractorOfferRequestUrl = (p: Pick<Domain.ArchitectOfferRequest, "offerId">) =>
        mk([baseUrl, paths.offer, p.offerId])
    const mkContractorConditionsUrl = (p: Pick<Domain.ArchitectOfferRequest, "offerId">) =>
        mk([baseUrl, paths.offer, p.offerId, paths.conditions])
    const mkVerificationUrl = (token: Domain.Token) => mk([baseUrl, paths.verify, token.userId, token.code])
    const mkEmailVerificationUrl = (token: Domain.Token) => mk([baseUrl, paths.verifyEmail, token.tokenId])
    const mkPasswordResetUrl = (token: Domain.Token) => mk([baseUrl, paths.reset, token.tokenId])

    return {
        loginUrl,
        mkDevisReviewUrl,
        mkDevisOfferRequestsUrl,
        mkVerificationUrl,
        mkContractorOfferRequestUrl,
        mkContractorConditionsUrl,
        mkEmailVerificationUrl,
        mkPasswordResetUrl
    }
}
