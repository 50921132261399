import * as React from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import dayjs from "dayjs"
import { i18n } from "../../services/translations"
import { dataCy } from "../../utils"
import { Button, RadioButton, SwitchButton } from "@smartdevis/ui/src/Button"
import { Input, TextareaInput } from "@smartdevis/ui/src/Inputs"
import { dateFormat } from "@smartdevis/server/src/constants"
import { ErrorLabel, H4, Label, P } from "@smartdevis/ui/src/Typography"
import { Select } from "@smartdevis/ui/src/Selects"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { CustomBoxSubtype, CustomOptionSubtype } from "./formSchemas"
import {
    ElementsRenderMap,
    InputOptionRenderFn,
    InputBoxRenderFn,
    InputMultiselectRenderFn,
    RenderParams,
    InputRenderMap,
    InputState
} from "@smartdevis/forms/src"
import { plainHtmlRenderMap } from "@smartdevis/forms/src/components/PlainHtmlRenderMap"
import { getInputProps, getExtInputProps } from "@smartdevis/forms/src/formUtils"
import { arrify } from "@smartdevis/utils/src/array"
import { pickObject } from "@smartdevis/utils/src/map"
import { isArray, isEmpty } from "@smartdevis/utils/src/validators"
import { capitalize } from "@smartdevis/utils/src/text"
import { DatePicker } from "@smartdevis/ui/src/DatePicker"
import { toSafeNumber, fromSafeNumber, CHF } from "@smartdevis/utils/src/numbers"

export const ItemWrapper = styled.div`
    padding: 0;
    margin: 0;
`

export const InputWrapper = styled.div<{ hasErrors?: boolean }>`
    min-width: 300px;
`

export const SelectWrapper = styled.div<{ hasErrors?: boolean }>``

const Error: React.FC<InputState<any>> = ({ validationResult, visited }) => (
    <ErrorLabel className="error">
        {validationResult && visited && validationResult.type === "Err" ? i18n(validationResult.value) : ""}
    </ErrorLabel>
)

type LabelProps = { text?: string } & Pick<React.LabelHTMLAttributes<HTMLLabelElement>, "htmlFor">
export const elementsRenderMap: ElementsRenderMap = {
    ItemWrapper,
    Button: Button,
    ItemChildrenWrapper: React.Fragment,
    DefaultFormItem: () => <h1>Not supported</h1>,
    Title: p => (isEmpty(p.text) ? null : <H4>{p.text}</H4>),
    Label: (p: LabelProps) => (isEmpty(p.text) ? null : <Label htmlFor={p.htmlFor}>{p.text}</Label>),
    Error
}

export const RadioInput: InputOptionRenderFn = p => {
    const { value, onChange } = getInputProps<HTMLSelectElement>(p)
    return (
        <>
            {p.schema.values.map(([name, v]) => (
                <RadioButton
                    label={name}
                    key={v}
                    checked={value === v}
                    onChange={checked => (checked ? onChange?.({ target: { value: v } } as any) : null)}
                />
            ))}
        </>
    )
}

export const SwitchInput: InputBoxRenderFn = p => {
    const { value, onChange } = getInputProps(p)
    return (
        <SwitchButton
            data-cy={dataCy("switch-input-$1", p.schema.name)}
            checked={Boolean(value)}
            onChange={switched => onChange?.({ target: { value: switched ? 1 : 0 } } as any)}
        />
    )
}

export const NumberInput: InputBoxRenderFn = p => {
    const inputProps = getInputProps(p)
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = parseFloat(e.target.value)
        if (!inputProps.onChange || v === undefined) return
        inputProps.onChange({ target: { value: isEmpty(v) ? NaN : `${toSafeNumber(v)}` } } as any)
    }
    return (
        <Input
            {...inputProps}
            data-cy={dataCy("forms-number-input-$1", p.schema.name)}
            value={isEmpty(inputProps.value) ? "" : fromSafeNumber(inputProps.value as number)}
            type="number"
            onChange={onChangeHandler}
            placeholder={inputProps.placeholder || i18n("Enter $1", inputProps.name && i18n(inputProps.name))}
        />
    )
}

export const TextInput: InputBoxRenderFn = p => {
    const inputProps = getInputProps(p)
    const type = p.schema.type === "number" ? "number" : p.schema.type === "password" ? "password" : "text"
    return type === "password" ? (
        <Input type="password" data-cy="forms-password-input" {...inputProps} />
    ) : (
        <Input
            {...inputProps}
            type={type}
            data-cy={dataCy("forms-text-input-$1", p.schema.name)}
            placeholder={inputProps.placeholder || i18n("Enter $1", inputProps.name && i18n(inputProps.name))}
        />
    )
}

export const MultiselectInput: InputMultiselectRenderFn = p => {
    const { value, onChange } = getExtInputProps(p)
    const handleChange = (newValue: string[]) => onChange(newValue)
    const mode = p.schema.creatable ? "combobox" : "multiselect"
    return (
        <Select
            fullWidth
            value={value?.filter(Boolean) || []}
            placeholder={p.schema.placeholder}
            onChange={handleChange}
            mode={mode}
            options={arrify(p.schema.values).map(d => mkDropdownOption(d[0], d[1]))}
        />
    )
}

export const ContractorChipsInput: InputOptionRenderFn = p => {
    const { onChange, value, placeholder } = getInputProps(p)
    const handleChange = (v: string[]) => {
        if (!onChange || v === undefined) return
        onChange({ target: { value: v } } as any)
    }
    return (
        <Select
            mode="multiselect"
            searchable
            placeholder={placeholder}
            value={(value as string[]) || []}
            onChange={handleChange}
            options={p.schema.values.map(([name, v]) => mkDropdownOption(name, v))}
        />
    )
}

export const SelectInput: InputOptionRenderFn = p => {
    const { onChange, value } = getInputProps(p)
    const handleChange = (v: string) => {
        if (!onChange || v === undefined) return
        onChange({ target: { value: v } } as any)
    }

    return (
        <Select
            mode="select"
            data-cy="forms-select-input"
            value={(value as string) || undefined} // to get rid of ""
            placeholder={p.schema.placeholder}
            onChange={handleChange}
            options={p.schema.values.map(([name, v]) => mkDropdownOption(name, v))}
        />
    )
}

export const TextAreaInput: InputBoxRenderFn = p => {
    const inputProps = getInputProps<HTMLTextAreaElement>(p)
    return (
        <TextareaInput
            {...inputProps}
            data-cy={dataCy("forms-textarea-input-$1", p.schema.name)}
            placeholder={
                inputProps.placeholder ||
                i18n("Enter $1. Press Shift + Enter for newline", inputProps.name && i18n(inputProps.name))
            }
        />
    )
}

export const TaxInput: InputBoxRenderFn = p => {
    const inputProps = getInputProps<HTMLInputElement>(p)
    const value = isArray(inputProps.value) ? "" : (inputProps.value as string)
    return (
        <NumberFormat
            format="CHE-###.###.###"
            mask="_"
            allowEmptyFormatting
            customInput={Input}
            {...inputProps}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputProps.onChange && inputProps.onChange(e)}
        />
    )
}

export const NumberFormattedInput: InputBoxRenderFn = p => {
    const inputProps = getInputProps<HTMLInputElement>(p)
    const props = {
        ...inputProps,
        value: isArray(inputProps.value) ? "" : inputProps.value
    }
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
        if (!inputProps.onChange) return
        if (v === null) return NaN
        const numVal = parseFloat(v.replace(/`/g, ""))
        inputProps.onChange({
            target: { value: isEmpty(numVal) ? NaN : `${toSafeNumber(numVal)}` }
        } as any)
    }
    return (
        <NumberFormat
            customInput={Input}
            thousandSeparator="`"
            {...props}
            data-cy={dataCy("forms-number-input-$1", p.schema.name)}
            value={isEmpty(inputProps.value) ? "" : fromSafeNumber(inputProps.value as number)}
            onChange={onChangeHandler}
            placeholder={inputProps.placeholder || i18n("Enter $1", inputProps.name && i18n(inputProps.name))}
        />
    )
}

export const BoxInputWrapper: InputBoxRenderFn = p => {
    const r = elementsRenderMap
    const inputProps = getInputProps(p)
    const { validationResult, visited } = p.state
    const hasErrors = validationResult && visited && validationResult.type === "Err"
    return (
        <InputWrapper hasErrors={hasErrors} className="input-wrapper">
            <r.Title text={p.schema.sectionTitle} />
            <r.Label text={p.schema.name && i18n(p.schema.name)} htmlFor={inputProps.id} />
            {p.children}
            <r.Error {...p.state} />
        </InputWrapper>
    )
}

export const OptionInputWrapper: React.FC<RenderParams<InputOptionRenderFn | InputMultiselectRenderFn>> = p => {
    const r = elementsRenderMap
    const { validationResult, visited } = p.state
    const hasErrors = validationResult && visited && validationResult.type === "Err"
    return (
        <SelectWrapper hasErrors={hasErrors}>
            <r.Label text={p.schema.name && i18n(p.schema.name)} />
            {p.children}
            <r.Error {...p.state} />
        </SelectWrapper>
    )
}

export const StaticTextRenderer: InputBoxRenderFn = p => (
    <BoxInputWrapper {...p}>
        <P>{p.state.value || "-"}</P>
    </BoxInputWrapper>
)

export const StaticDateRenderer: InputBoxRenderFn = p => (
    <BoxInputWrapper {...p}>
        <P>{dayjs(p.state.value).format(dateFormat)}</P>
    </BoxInputWrapper>
)

export const StaticOptionRenderer: InputOptionRenderFn = p => (
    <BoxInputWrapper schema={p.schema as any} state={p.state} setDelta={p.setDelta} renderOptions={p.renderOptions}>
        <P>{p.schema.values.find(v => v[1] === p.state.value)?.[0] ?? (capitalize(p.state.value) || "-")}</P>
    </BoxInputWrapper>
)

export const StaticNumberRenderer: InputBoxRenderFn = p => (
    <BoxInputWrapper {...p}>
        <P>{CHF(p.state.value)}</P>
    </BoxInputWrapper>
)
const TextInputRenderer: InputBoxRenderFn = p => (
    <BoxInputWrapper {...p}>
        <TextInput {...p} />
    </BoxInputWrapper>
)
const NumberInputRenderer: InputBoxRenderFn = p => (
    <BoxInputWrapper {...p}>
        <NumberInput {...p} />
    </BoxInputWrapper>
)
const TextAreaInputRenderer: InputBoxRenderFn = p => (
    <BoxInputWrapper {...p}>
        <TextAreaInput {...p} />
    </BoxInputWrapper>
)

const DatePickerInput: InputBoxRenderFn = p => {
    const inputProps = getInputProps(p)
    const onChangeHandler = (v: number) => {
        if (!inputProps.onChange) return
        inputProps.onChange({ target: { value: v } } as any)
    }
    return (
        <DatePicker
            {...inputProps}
            id="devis-realization"
            data-cy="devis-date-picker"
            value={inputProps.value ? inputProps.value : undefined}
            placeholder={i18n("Select date")}
            onChange={onChangeHandler}
        />
    )
}
const CustomBoxInput: InputBoxRenderFn = p => {
    if (p.schema.type !== "customBox") return null
    switch (p.schema.subtype as CustomBoxSubtype) {
        case "date":
            return <DatePickerInput {...p} />
        case "formatted-number":
            return <NumberFormattedInput {...p} />
        case "tax":
            return <TaxInput {...p} />
        case "switch":
            return <SwitchInput {...p} />
        default:
            return plainHtmlRenderMap.customBox(p)
    }
}

const CustomOptionInput: InputOptionRenderFn = p => {
    if (p.schema.type !== "customOption") return null
    switch (p.schema.subtype as CustomOptionSubtype) {
        case "contractor":
            return <ContractorChipsInput {...p} />
        default:
            return plainHtmlRenderMap.customOption(p)
    }
}

const CustomBoxRenderer: InputBoxRenderFn = p => (
    <BoxInputWrapper {...p}>
        <CustomBoxInput {...p} />
    </BoxInputWrapper>
)

const CustomOptionRenderer: InputOptionRenderFn = p => (
    <OptionInputWrapper {...p}>
        <CustomOptionInput {...p} />
    </OptionInputWrapper>
)

const SelectInputRenderer: InputOptionRenderFn = p => (
    <OptionInputWrapper {...p}>
        <SelectInput {...p} />
    </OptionInputWrapper>
)

const MultiselectInputRenderer: InputMultiselectRenderFn = p => (
    <OptionInputWrapper {...p}>
        <MultiselectInput {...p} />
    </OptionInputWrapper>
)

const RadioInputRenderer: InputOptionRenderFn = p => (
    <OptionInputWrapper {...p}>
        <RadioInput {...p} />
    </OptionInputWrapper>
)

export const inputsRenderMap: InputRenderMap = {
    ...pickObject(plainHtmlRenderMap, ["list", "collection"]),
    customOption: CustomOptionRenderer,
    customBox: CustomBoxRenderer,
    text: TextInputRenderer,
    email: TextInputRenderer,
    password: TextInputRenderer,
    textarea: TextAreaInputRenderer,
    number: NumberInputRenderer,
    hidden: () => null,
    multiselect: MultiselectInputRenderer,
    radio: RadioInputRenderer,
    select: SelectInputRenderer
}

export const renderMaps = { inputsRenderMap, elementsRenderMap }
