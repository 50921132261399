import * as React from "react"
import styled from "styled-components"
import { FlexColumn, FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { i18n } from "@smartdevis/client/src/services/translations"
import { Modal } from "@smartdevis/ui/src/Modal"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { PageHeader } from "../../components/layouts/Header"
import { SubContent } from "../../components/layouts/Content"
import { Button } from "@smartdevis/ui/src/Button"
import { Input } from "@smartdevis/ui/src/Inputs"
import { H5, Label } from "@smartdevis/ui/src/Typography"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { toSafeNumber, fromSafeNumber } from "@smartdevis/utils/src/numbers"

export const ContractorDeductionsPriceModal: React.FC<{
    value: number
    onSubmit: F1<number>
    onClose: F0
}> = p => {
    const subTitleContent = [
        i18n("Since you submitted a file-based devis, we’re not able to read the price directly from the document."),
        i18n("Please provide the price in input below.")
    ].join(" ")
    const [grossPrice, setGrossPrice] = React.useState<number>(p.value)
    return (
        <Modal
            visible
            size="s"
            onClose={p.onClose}
            footer={
                <>
                    <Button onClick={p.onClose} btnType="secondary">
                        {i18n("Cancel")}
                    </Button>
                    <Button disabled={!grossPrice} onClick={() => p.onSubmit(grossPrice)}>
                        {i18n("Save and close")}
                    </Button>
                </>
            }>
            <SubContent>
                <PageHeader title={i18n("Submit a gross price")} subTitle={subTitleContent} noTopMargin />
                <FlexColumn>
                    <Label color="grey70" style={{ marginBottom: "8px" }}>
                        {i18n("Gross price")}
                    </Label>
                    <FlexRow alignCenter>
                        <StyledInput
                            type="number"
                            value={grossPrice ? fromSafeNumber(grossPrice) : ""}
                            onChange={e => setGrossPrice(toSafeNumber(+e.target.value))}
                            placeholder={i18n("Provide gross price")}
                        />
                        <HorizontalSpace base="32px" />
                        <CurrencyText>{i18n("CHF")}</CurrencyText>
                    </FlexRow>
                </FlexColumn>
            </SubContent>
        </Modal>
    )
}

const StyledInput = styled(Input)`
    max-width: 240px;
`

const CurrencyText = styled(H5)`
    color: ${themeColor("grey70")};
`
