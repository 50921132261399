import { Domain } from "@smartdevis/server/src/domain"
import {
    mkConditions,
    mkPositionSections,
    mkDeductionsBySection,
    mkDeductionSections,
    mkPositions,
    mkContractors,
    mkCalculations,
    mkGeneralInformation,
    mkDeltasByRef,
    mkRequestsBySubmitter,
    mkDevisAdditionalInformation,
    mkRequestDeltasByRef,
    mkSublevels,
    mkAllDevisAttachments
} from "@smartdevis/server/src/models/shared"
import { CalculatedDevis, calculateDevis, sumPositionsPrices } from "@smartdevis/server/src/utils/money"
import { isOfferInState, mkLatestOfferInformation, mkLatestOfferValues } from "@smartdevis/server/src/utils/offer"
import { mkNotFetched, mkFetched, Async } from "@smartdevis/utils/src/async"
import { SMap, toMap, keys, values } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { resolveArchitectContractors, resolveContractorOffer } from "./contractorResolvers"
import { resolveDevis, resolveDevisCollections } from "./devisResolver"
import { resolveProjectAttachments, resolveProjectDetails } from "./projectResolvers"
import {
    combineResolversWithProps,
    mkResolverWithProps,
    mkResolver,
    combineResolvers,
    resolveAppUser
} from "./resolverUtils"

type DevisProps = { projectId: string; devisId: string }

export type ContractDocData = {
    projectDetails: Omit<Domain.ProjectDetails, "createdTs" | "version">
    contractor?: Domain.User
    devis: Pick<Domain.Devis, "workCategory" | "number" | "workEndTs" | "workStartTs">
    calculation: CalculatedDevis
    conditions: Domain.Condition[]
    additionalInformation: Domain.AdditionalInformation[]
    generalInformation: Domain.GeneralInformation[]
    positionSections: Domain.Section[]
    deductionSections: Domain.Section[]
    sublevels: SMap<Domain.Sublevel[]>
    deductionsBySection: SMap<Domain.Deduction[]>
    attachments: Domain.Attachment[]
    meta?: Partial<Domain.OfferMeta>
    submittedTs?: number
    architectLogo?: string
}

export const resolveContractDoc = combineResolversWithProps<DevisProps & { offerId?: string }>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveDevis,
    resolveProjectAttachments,
    resolveAppUser,
    (details, data, devis, attachments, user) =>
        mkResolverWithProps<DevisProps & { offerId?: string }>()((_, op) => {
            if (!op.offerId) return mkNotFetched()
            const request = data.requests[op.offerId]

            const roundSubmittedState = request.statesHistory.find(s => s.state.type === "round-submitted")
            const submittedState = request.statesHistory.find(s => s.state.type === "submitted")
            const submittedTs = roundSubmittedState ? roundSubmittedState.createdTs : submittedState?.createdTs

            if (!request.contractorUser) return mkNotFetched()
            const deltas = mkDeltasByRef(data)
            const deductionsBySection = mkDeductionsBySection(data, deltas, { keepDeleted: false })
            const deductionSections = mkDeductionSections(data, deltas, { keepDeleted: false })
            const offerValues = mkLatestOfferValues(request)
            const offerInformation = mkLatestOfferInformation(request)
            return mkFetched<ContractDocData>({
                devis,
                meta: request.offerMeta,
                projectDetails: details,
                contractor: request.contractorUser,
                calculation: calculateDevis(
                    deductionSections,
                    deductionsBySection,
                    offerValues,
                    offerInformation,
                    sumPositionsPrices(mkPositions(data, deltas, { keepDeleted: false }), offerValues)
                ),
                additionalInformation: mkDevisAdditionalInformation(data, deltas, {
                    keepDeleted: false
                }),
                attachments: mkAllDevisAttachments(attachments, data, request, op.devisId, op.offerId),
                generalInformation: mkGeneralInformation(data),
                conditions: mkConditions(data, deltas, { keepDeleted: false }),
                positionSections: mkPositionSections(data, deltas, { keepDeleted: false }),
                sublevels: mkSublevels(data, deltas, { keepDeleted: false }),
                deductionsBySection,
                deductionSections,
                submittedTs,
                architectLogo: user.logoUrl
            })
        })
)

export const resolveContractPreviewDoc = combineResolversWithProps<DevisProps>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveDevis,
    resolveProjectAttachments,
    resolveAppUser,
    (details, data, devis, attachments, user) =>
        mkResolverWithProps<DevisProps>()(() => {
            const deltas = mkDeltasByRef(data)
            const deductionsBySection = mkDeductionsBySection(data, deltas, { keepDeleted: false })
            const deductionSections = mkDeductionSections(data, deltas, { keepDeleted: false })
            const offerValues = {}
            const offerInformation = {}
            return mkFetched<ContractDocData>({
                devis,
                meta: {},
                projectDetails: details,
                calculation: calculateDevis(
                    deductionSections,
                    deductionsBySection,
                    offerValues,
                    offerInformation,
                    sumPositionsPrices(mkPositions(data, deltas, { keepDeleted: false }), offerValues)
                ),
                attachments: mkAllDevisAttachments(attachments, data, {}, devis.devisId),
                additionalInformation: mkDevisAdditionalInformation(data, deltas, {
                    keepDeleted: false
                }),
                generalInformation: mkGeneralInformation(data),
                conditions: mkConditions(data, deltas, { keepDeleted: false }),
                positionSections: mkPositionSections(data, deltas, { keepDeleted: false }),
                sublevels: mkSublevels(data, deltas, { keepDeleted: false }),
                deductionsBySection,
                deductionSections,
                architectLogo: user.logoUrl
            })
        })
)

export const resolveContractorContractDoc = combineResolvers(
    resolveContractorOffer,
    resolveAppUser,
    (contractorOffer, user) =>
        mkResolver(() => {
            const { devis, project, offerId } = contractorOffer
            const deltas = mkRequestDeltasByRef(contractorOffer)
            const deductionsBySection = mkDeductionsBySection(contractorOffer, deltas, {
                keepDeleted: false
            })
            const deductionSections = mkDeductionSections(contractorOffer, deltas, {
                keepDeleted: false
            })
            const offerValues = mkLatestOfferValues(contractorOffer)

            const offerInformation = mkLatestOfferInformation(contractorOffer)

            return mkFetched<ContractDocData>({
                devis,
                meta: contractorOffer.offerMeta,
                projectDetails: project,
                contractor: user,
                calculation: calculateDevis(
                    deductionSections,
                    deductionsBySection,
                    offerValues,
                    offerInformation,
                    sumPositionsPrices(mkPositions(contractorOffer, deltas, { keepDeleted: false }), offerValues)
                ),
                attachments: mkAllDevisAttachments({}, contractorOffer, contractorOffer, devis.devisId, offerId),
                additionalInformation: mkDevisAdditionalInformation(contractorOffer, deltas, {
                    keepDeleted: false
                }),
                generalInformation: mkGeneralInformation(contractorOffer),
                conditions: mkConditions(contractorOffer, deltas, { keepDeleted: false }),
                positionSections: mkPositionSections(contractorOffer, deltas, { keepDeleted: false }),
                sublevels: mkSublevels(contractorOffer, deltas, {
                    keepDeleted: false
                }),
                deductionsBySection,
                deductionSections
            })
        })
)

export type OfferComparisonDocData = {
    projectDetails: Domain.ProjectDetails
    devis: Domain.Devis
    positionSections: Domain.Section[]
    deductionSections: Domain.Section[]
    additionalInformation: Domain.AdditionalInformation[]
    deductionsBySection: SMap<Domain.Deduction[]>
    sublevels: SMap<Domain.Sublevel[]>
    calculations: SMap<CalculatedDevis>
    submitters: Domain.Contractor[]
    architectLogo?: string
}

export const resolveOfferComparisonDoc = combineResolversWithProps<DevisProps & { offersIds: string[] }>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveDevis,
    resolveArchitectContractors,
    resolveAppUser,
    (details, data, devis, contractors, user) =>
        mkResolverWithProps<DevisProps & { offersIds: string[] }>()((_, op): Async<OfferComparisonDocData> => {
            const submitters = mkContractors(data, contractors, op.offersIds)
            const deltas = mkDeltasByRef(data)

            return mkFetched({
                devis,
                submitters,
                projectDetails: details,
                calculations: mkCalculations(data, submitters, op.offersIds),
                additionalInformation: mkDevisAdditionalInformation(data, deltas, {
                    keepDeleted: false
                }).filter(v => v.value === null),
                deductionSections: mkDeductionSections(data, deltas, { keepDeleted: false }),
                positionSections: mkPositionSections(data, deltas, { keepDeleted: false }),
                deductionsBySection: mkDeductionsBySection(data, deltas, { keepDeleted: false }),
                sublevels: mkSublevels(data, deltas, { keepDeleted: false }),
                architectLogo: user.logoUrl
            })
        })
)

export type NegotiationDocData = {
    projectDetails: Domain.ProjectDetails
    devis: Domain.Devis
    dataBySubmitter: SMap<{
        deductionSections: Domain.Section[]
        deductionsBySection: SMap<Domain.Deduction[]>
        request: Domain.ArchitectOfferRequest
        finalCalculations: CalculatedDevis
        roundNet: number
        initialNet: number
    }>
    submitters: Domain.Contractor[]
    architectLogo?: string
}

export const resolveNegotiationDoc = combineResolversWithProps<DevisProps>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveDevis,
    resolveArchitectContractors,
    resolveAppUser,
    (details, data, devis, contractors, user) =>
        mkResolverWithProps<DevisProps>()(() => {
            const deltas = mkDeltasByRef(data)
            const rbs = mkRequestsBySubmitter(data)
            const submitters = devis.contractorIds.map(c => contractors[c]).filter(c => isDefined(rbs[c.contractorId]))
            const rds = mkDeductionSections(data)
            const rdbs = mkDeductionsBySection(data)
            const rp = mkPositions(data)
            const ds = mkDeductionSections(data, deltas, { keepDeleted: false })
            const dbs = mkDeductionsBySection(data, deltas, { keepDeleted: false })
            const p = mkPositions(data, deltas, { keepDeleted: false })

            const calculatePhase = (
                request: Domain.ArchitectOfferRequest,
                positions: SMap<Domain.Position>,
                sections: Domain.Section[],
                deductionsBySection: SMap<Domain.Deduction[]>,
                rdelta: Partial<Domain.ArchitectOfferRequest> = {}
            ) => {
                const ov = mkLatestOfferValues({ ...request, ...rdelta })
                const oi = mkLatestOfferInformation({ ...request, ...rdelta })
                return calculateDevis(sections, deductionsBySection, ov, oi, sumPositionsPrices(positions, ov))
            }
            const finalDelta = { finalOffer: {}, finalInformation: {} }
            const roundDelta = { ...finalDelta, roundOffers: {}, roundInformation: {} }

            const dataBySubmitter = toMap(
                submitters,
                s => s.contractorId,
                s => {
                    const request = rbs[s.contractorId]
                    if (request.statesHistory.some(h => isOfferInState(h, ["negotiation", "round-submitted"]))) {
                        const finalCalc = calculatePhase(request, p, ds, dbs)
                        const roundCalc = calculatePhase(request, p, ds, dbs, finalDelta)
                        const initialCalc = calculatePhase(request, rp, rds, rdbs, roundDelta)
                        return {
                            deductionSections: ds,
                            deductionsBySection: dbs,
                            request,
                            finalCalculations: finalCalc,
                            roundNet: roundCalc.netincltax,
                            initialNet: initialCalc.netincltax
                        }
                    }
                    const calc = calculatePhase(request, rp, rds, rdbs)
                    return {
                        deductionSections: rds,
                        deductionsBySection: rdbs,
                        request,
                        finalCalculations: calc,
                        roundNet: calc.netincltax,
                        initialNet: calc.netincltax
                    }
                }
            )

            return mkFetched<NegotiationDocData>({
                devis,
                dataBySubmitter,
                submitters,
                projectDetails: details,
                architectLogo: user.logoUrl
            })
        })
)

export type PositionsDocData = {
    projectDetails: Domain.ProjectDetails
    devis: Domain.Devis
    sections: Domain.Section[]
    attachments: Domain.Attachment[]
    sublevels: SMap<Domain.Sublevel[]>
    architectLogo?: string
}

export const resolvePositionsDoc = combineResolversWithProps<DevisProps>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveProjectAttachments,
    resolveDevis,
    resolveAppUser,
    (details, data, attachments, devis, user) =>
        mkResolver(() => {
            const deltas = mkDeltasByRef(data)
            const positions = mkPositions(data, deltas, { keepDeleted: false })
            const positionsIds = keys(positions)
            return mkFetched<PositionsDocData>({
                projectDetails: details,
                devis,
                sections: mkPositionSections(data, deltas, { keepDeleted: false }),
                sublevels: mkSublevels(data, deltas, { keepDeleted: false }),
                attachments: values(attachments).filter(a => a.type === "position" && positionsIds.includes(a.refId)),
                architectLogo: user.logoUrl
            })
        })
)

export type SubmittersDocData = {
    projectDetails: Domain.ProjectDetails
    devis: Domain.Devis
    submitters: Domain.Contractor[]
    architectLogo?: string
}

export const resolveSubmittersDoc = combineResolversWithProps<DevisProps>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveDevis,
    resolveArchitectContractors,
    resolveAppUser,
    (details, data, devis, contractors, user) =>
        mkResolver(() =>
            mkFetched<SubmittersDocData>({
                projectDetails: details,
                devis,
                submitters: values(contractors).filter(c => devis.contractorIds.includes(c.contractorId)),
                architectLogo: user.logoUrl
            })
        )
)

export type ProjectSubmittersData = {
    projectDetails: Domain.ProjectDetails
    submitters: Domain.Contractor[]
    architectLogo?: string
    devis?: undefined
}

export const resolveProjectSubmittersDoc = combineResolversWithProps<DevisProps>()(
    resolveProjectDetails,
    resolveArchitectContractors,
    resolveAppUser,
    (details, contractors, user) =>
        mkResolver(() =>
            mkFetched<ProjectSubmittersData>({
                projectDetails: details,
                submitters: values(contractors).filter(c => (details.contractorIds || []).includes(c.contractorId)),
                architectLogo: user.logoUrl
            })
        )
)

export type ConditionsDocData = {
    projectDetails: Domain.ProjectDetails
    devis: Domain.Devis
    conditions: Domain.Condition[]
    generalInformation: Domain.GeneralInformation[]
    additionalInformation: Domain.AdditionalInformation[]
    attachments: Domain.Attachment[]
    architectLogo?: string
}

export const resolveConditionsDoc = combineResolversWithProps<DevisProps>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveProjectAttachments,
    resolveDevis,
    resolveAppUser,
    (details, data, attachments, devis, user) =>
        mkResolver(() => {
            return mkFetched<ConditionsDocData>({
                projectDetails: details,
                devis,
                conditions: mkConditions(data),
                generalInformation: mkGeneralInformation(data),
                additionalInformation: mkDevisAdditionalInformation(data),
                attachments: values(attachments).filter(a => a.type === "general" && a.refId === devis.devisId),
                architectLogo: user.logoUrl
            })
        })
)

export type DeductionsDocData = {
    projectDetails: Domain.ProjectDetails
    devis: Domain.Devis
    sections: Domain.Section[]
    deductionsBySection: SMap<Domain.Deduction[]>
    architectLogo?: string
}

export const resolveDeductionsDoc = combineResolversWithProps<DevisProps>()(
    resolveProjectDetails,
    resolveDevisCollections,
    resolveDevis,
    resolveAppUser,
    (details, data, devis, user) =>
        mkResolver(() => {
            const deltas = mkDeltasByRef(data)

            return mkFetched<DeductionsDocData>({
                projectDetails: details,
                devis,
                deductionsBySection: mkDeductionsBySection(data, deltas, { keepDeleted: false }),
                sections: mkDeductionSections(data, deltas, { keepDeleted: false }),
                architectLogo: user.logoUrl
            })
        })
)
