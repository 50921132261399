import * as React from "react"

export const useAccordion = (initSelected: string[] = [], { multiple = false } = {}) => {
    const [selected, setSelected] = React.useState(initSelected)

    const onChange = React.useCallback(
        (key: string) => {
            setSelected(s =>
                multiple
                    ? s.includes(key)
                        ? [...s.slice(0, s.indexOf(key)), ...s.slice(s.indexOf(key) + 1)]
                        : [...s, key]
                    : s.includes(key)
                    ? []
                    : [key]
            )
        },
        [setSelected, multiple]
    )

    return [selected, onChange] as const
}
