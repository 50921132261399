import * as React from "react"
import styled from "styled-components"
import { UploadingFile } from "@smartdevis/ui/src/Upload"
import { FlexRow, LeftCol, Margin, RightCol, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { getStorage } from "@smartdevis/client/src/services/firebase"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import { _noop } from "@smartdevis/utils/src/misc"
import { Button } from "@smartdevis/ui/src/Button"
import {
    mkPositions,
    mkPositionSections,
    mkRequestDeltasByRef,
    mkSublevels
} from "@smartdevis/server/src/models/shared"
import { mkOfferRowBuilder } from "./ContractorPositions.rows"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect, AsyncConnectResults } from "../../resolvers"
import { AttachmentsModal, mkAttachmentCell, useContractorAttachmentsManagement } from "../../components/Attachments"
import { mkDevisPositionsRows } from "@smartdevis/client/src/utils/devisHelpers"
import { usePositionComments } from "../architect/devis-positions/DevisPosition.helpers"
import { PositionComments } from "./ContractorComments"
import { DevisTile } from "../../components/tiles/DevisTile"
import { FilesUploadModal } from "../../components/modals/FilesUploadModal"
import { useCloudAction } from "../../hooks/useCloudAction"
import { mkSectionRow } from "../architect/DevisSection"
import { Table } from "../../components/table/Table"
import { OfferStepProps } from "./ContractorSteps"
import { H4 } from "@smartdevis/ui/src/Typography"
import { devisHelpers, DEVIS_FILE_EXTENSIONS } from "../architect/devis-positions/DevisPositions"
import { themeMedia } from "@smartdevis/ui/src/utils/theme"
import { getAttachmentExtension } from "@smartdevis/server/src/models/attachment"
import { Domain } from "@smartdevis/server/src/domain"

export const OfferPositions = asyncConnect({
    stateResolvers: ["contractorOffer", "results", "user"],
    actions: ["updateOfferValues", "removePositionsFormat", "mutateOfferWithUpload", "removeOfferProposal"]
})<OfferStepProps>(p => {
    return (
        <>
            {p.contractorOffer.devis.positionsFormat?.type === "file-based" ? (
                <FileBasedPositions {...p} positionsFormat={p.contractorOffer.devis.positionsFormat} />
            ) : p.contractorOffer.devis.positionsFormat?.type === "positions-based" ? (
                <FreeTextPositions {...p} />
            ) : null}
        </>
    )
})

type ContractorPositionsComponentProps = OfferStepProps &
    AsyncConnectResults<"contractorOffer" | "results" | "user", "removeOfferProposal" | "mutateOfferWithUpload">

const FreeTextPositions: React.FC<ContractorPositionsComponentProps> = p => {
    const comments = usePositionComments(p.contractorOffer)
    const deltas = mkRequestDeltasByRef(p.contractorOffer)
    const allPositions = mkPositions(p.contractorOffer, deltas, { keepDeleted: p.mode !== "final" })
    const sublevels = mkSublevels(p.contractorOffer, deltas, { keepDeleted: p.mode !== "final" })
    const rowBuilder = mkOfferRowBuilder(
        p.contractorOffer,
        p.values,
        p.readonly,
        p.setValue,
        comments,
        p.errorMap?.positions
    )
    const [attachmentModalPositionId, setAttachmentPositionId] = React.useState<string | undefined>(undefined)
    const positionAttachments = useContractorAttachmentsManagement("position", p)

    const rows = mkDevisPositionsRows(
        mkPositionSections(p.contractorOffer, deltas, { keepDeleted: p.mode !== "final" }),
        sublevels,
        (type, s, children) =>
            mkSectionRow(
                s,
                { type, readonly: true, children },
                {
                    withNumber: true,
                    noExplanation: true,
                    withPaddingTop: true,
                    hideDeltaVisuals: p.contractorOffer.state.type === "final-proposal"
                },
                deltas?.[s.sectionId]
            ),
        pos =>
            rowBuilder.mkPosition(
                pos,
                mkAttachmentCell(
                    positionAttachments.getItemsForRef(pos.positionId).length,
                    () => setAttachmentPositionId(pos.positionId),
                    true
                ),
                deltas?.[pos.sectionId]
            )
    )
    return (
        <>
            <Table<"section" | "sublevel" | "lastlevel" | "header" | "sum">
                rows={[rowBuilder.mkHeader(), ...rows, rowBuilder.mkSum(allPositions)]}
                rowHierarchy={["section", "sublevel", "lastlevel"]}
            />
            <AttachmentsModal
                isReadonly
                visible={!!attachmentModalPositionId}
                onClose={() => setAttachmentPositionId(undefined)}
                refId={attachmentModalPositionId}
                {...positionAttachments}
            />
            {comments.positionModalProps.visible && (
                <PositionComments
                    contractorOffer={p.contractorOffer}
                    header={i18n("Comments")}
                    {...comments.positionModalProps}
                    onClose={comments.positionModalProps.onClose}
                    size="s"
                />
            )}
        </>
    )
}

const FileBasedPositions: React.FC<
    ContractorPositionsComponentProps & { positionsFormat: Domain.FileBasedPositionFormat }
> = p => {
    const [uploadDevisModalVisible, setUploadDevisModalVisible] = React.useState(false)

    const { onSubmit: onFileUpload, actionState: uploadActionState } = useCloudAction<UploadingFile>(
        (actionId, file) => {
            p.mutateOfferWithUpload({
                file,
                offerId: p.contractorOffer.offerId,
                actionId,
                userId: p.user.userId
            })
        },
        p.results,
        () => setUploadDevisModalVisible(false)
    )

    const { onSubmit: onFileRemove } = useCloudAction(
        actionId => {
            p.removeOfferProposal({
                offerId: p.contractorOffer.offerId,
                actionId
            })
        },
        p.results,
        () => setUploadDevisModalVisible(false)
    )

    const { positionsFormat, contractorOffer } = p
    const currentOfferProposal =
        p.contractorOffer.state.type === "next-round"
            ? (p.contractorOffer.roundOfferProposals || {})[p.contractorOffer.state.roundId]
            : p.contractorOffer.offerProposal

    const readonly = !isOfferInState(p.contractorOffer, ["adopted", "next-round"])

    const onUploadedFileClick = () => window.open(getStorage().getFileUrl(currentOfferProposal?.path || ""), "_blank")

    const openUrlForAttachmentData = (a: Domain.AttachmentData) => () =>
        window.open(getStorage().getFileUrl(a.path), "_blank")

    const firstRoundFile = positionsFormat.fileAttachment
    const secondRoundFile = devisHelpers(p.contractorOffer.devis).getSecondRoundFile(p.contractorOffer?.deltas || {})

    const currentExtension = `.${getAttachmentExtension(secondRoundFile || firstRoundFile)}`
    const isRestrictedExtension = DEVIS_FILE_EXTENSIONS.includes(currentExtension)

    const architectFiles = [
        { label: i18n("Architect initial round devis"), value: firstRoundFile },
        { label: i18n("Architect negotiations round devis"), value: secondRoundFile }
    ]

    const contractorFiles = [
        {
            label: i18n("Initial round offer"),
            value: devisHelpers(p.contractorOffer.devis).getPastFirstRoundContractorFile(contractorOffer)
        },
        {
            label: i18n("Negotiations round offer"),
            value: devisHelpers(p.contractorOffer.devis).getPastSecondRoundContractorFile(contractorOffer)
        }
    ]

    const isUploadable = p.contractorOffer.state.type === "next-round" || p.contractorOffer.state.type === "adopted"
    const placeholderText = i18n("Your offer will be placed here. Click 'Upload file' button to upload an offer.")

    return (
        <>
            <FlexRow justifyEnd>
                <Button btnType="secondary" onClick={() => setUploadDevisModalVisible(true)} disabled={readonly}>
                    {currentOfferProposal ? i18n("Re-upload file") : i18n("Upload file")}
                </Button>
            </FlexRow>
            <Margin values="36px 0 0 0">
                <ColumnsWrapper>
                    <LeftCol dynamicHeight={true}>
                        {architectFiles.map(
                            v =>
                                v.value && (
                                    <React.Fragment key={v.label}>
                                        <Margin values="0 0 16px 0">
                                            <H4>{v.label}</H4>
                                        </Margin>
                                        <DevisTile
                                            tileId={v.value.attachmentId}
                                            format={{ type: "file-based", fileAttachment: v.value }}
                                            loading={uploadActionState.type === "Processing"}
                                            createdTs={v.value.createdTs}
                                            onClick={openUrlForAttachmentData(v.value)}
                                        />
                                        <VerticalSpace h={32} />
                                    </React.Fragment>
                                )
                        )}
                    </LeftCol>
                    <RightCol dynamicHeight={true}>
                        {contractorFiles.map(
                            v =>
                                v.value && (
                                    <React.Fragment key={v.label}>
                                        <Margin values="0 0 16px 0">
                                            <H4>{v.label}</H4>
                                        </Margin>
                                        <DevisTile
                                            tileId={v.value.attachmentId}
                                            format={{ type: "file-based", fileAttachment: v.value }}
                                            loading={uploadActionState.type === "Processing"}
                                            createdTs={v.value.createdTs}
                                            onClick={openUrlForAttachmentData(v.value)}
                                        />
                                        <VerticalSpace h={32} />
                                    </React.Fragment>
                                )
                        )}
                        {isUploadable && (
                            <>
                                <Margin values="0 0 16px 0">
                                    <H4>{i18n("Uploaded devis")}</H4>
                                </Margin>
                                {currentOfferProposal ? (
                                    <>
                                        <DevisTile
                                            tileId={p.contractorOffer.offerId}
                                            format={{
                                                type: "file-based",
                                                fileAttachment: currentOfferProposal
                                            }}
                                            loading={uploadActionState.type === "Processing"}
                                            createdTs={currentOfferProposal.createdTs}
                                            onClick={onUploadedFileClick}
                                            titleLabel={i18n("Uploaded file")}
                                            devisId={p.contractorOffer.devis.devisId}
                                            projectId={p.contractorOffer.project.projectId}
                                            onRemovePositionsFormat={!readonly ? onFileRemove : undefined}
                                        />
                                    </>
                                ) : (
                                    <PlaceholderTile text={placeholderText} />
                                )}
                                <VerticalSpace h={32} />
                            </>
                        )}
                    </RightCol>
                </ColumnsWrapper>
            </Margin>

            {uploadDevisModalVisible && (
                <FilesUploadModal
                    visible={uploadDevisModalVisible}
                    onFileUpload={onFileUpload}
                    title={i18n("Drop your devis file here")}
                    onClose={() => setUploadDevisModalVisible(false)}
                    uploadStatus={uploadActionState.type}
                    acceptableExtensions={isRestrictedExtension ? [currentExtension] : undefined}
                />
            )}
        </>
    )
}

const PlaceholderTileWrapper = styled.div`
    min-width: 520px;
    height: 200px;
    border: 1px solid #ededed;
    color: ${p => p.theme.colors.grey70};
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PlaceholderTile: React.FC<{ text: string }> = p => <PlaceholderTileWrapper>{p.text}</PlaceholderTileWrapper>

const ColumnsWrapper = styled(FlexRow)`
    ${themeMedia("max", "xl")} {
        flex-direction: column;
        > div {
            margin: 0;
            padding: 0;
        }
    }
`
