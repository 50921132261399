import * as React from "react"
import styled, { css } from "styled-components"
import { InlineSpinner } from "./Spinner"
import { themeColor } from "./utils/theme"
import { rem } from "./utils"
import { Center, FlexRow, HorizontalSpace, Margin, styleIfProp } from "./utils/common"
import { Label } from "./Typography"
import { Asset, ImageAsset } from "./Asset"
import { useHovered } from "./hooks/common"
import { omitObject } from "@smartdevis/utils/src/map"
import { TypedOmit, F1 } from "@smartdevis/utils/src/types"

export type ButtonType = "primary" | "action" | "secondary" | "link" | "header"
type IconButtonType = "primary" | "secondary" | "icon-only"

type ButtonBaseProps = {
    loading?: boolean
    disabled?: boolean
    icon?: ImageAsset
}

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    ButtonBaseProps & { icon: ImageAsset; btnType?: IconButtonType; iconOnHover?: ImageAsset }
export const IconButton: React.FC<IconButtonProps> = p => {
    const { hovered, hoverListeners } = useHovered()
    const type: IconButtonType = p.btnType || (!p.children ? "icon-only" : "primary")
    const icon = (
        <Asset
            name={hovered && p.iconOnHover ? p.iconOnHover : p.icon}
            color={type === "icon-only" ? "black" : "white"}
            size="small-icon"
        />
    )
    return (
        <IconButtonWrapper {...hoverListeners} {...omitObject(p, ["loading"])} btnType={type}>
            {p.loading ? <InlineSpinner color="white" /> : icon}
            <Margin values={!p.children ? "0" : "0 6px 0 4px"}>{p.children}</Margin>
        </IconButtonWrapper>
    )
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonBaseProps & { btnType?: ButtonType }
export const Button: React.FC<ButtonProps> = p => {
    const icon = p.icon ? <Asset name={p.icon} size="small-icon" /> : null
    return (
        <ButtonWrapper {...omitObject(p, ["loading"])}>
            {p.loading && (
                <>
                    <InlineSpinner color="white" />
                    <HorizontalSpace base="8px" />
                </>
            )}
            <Margin values="0 6px 0 -4px">
                <Center>{icon}</Center>
            </Margin>
            {p.children}
        </ButtonWrapper>
    )
}
const getBtnStyle = (t?: ButtonType) => {
    switch (t) {
        case "primary":
            return css`
                color: ${themeColor("white")};
                background-color: ${themeColor("primaryGrey")};
            `
        case "action":
            return css`
                color: ${themeColor("primaryGrey")};
                background-color: ${themeColor("white")};
                border: 1px solid ${themeColor("secondaryGrey")};
                &:hover {
                    background-color: ${themeColor("primary08")};
                }
            `
        case "secondary":
            return css`
                color: ${themeColor("primaryGrey")};
                background-color: ${themeColor("white")};
                border: 1px solid ${themeColor("secondaryGrey")};
            `
        case "link":
            return css`
                color: ${themeColor("primary")};
                background-color: transparent;
                border: none;
                &:hover {
                    color: ${themeColor("primary50")};
                }
            `
        case "header":
            return css`
                color: ${themeColor("primaryGrey")};
                background-color: ${themeColor("grey40")};
                border: none;
                min-height: ${rem(20)};
                font-weight: 500;
                &:hover {
                    color: ${themeColor("primary")};
                }
            `
        default:
            return css`
                color: ${themeColor("white")};
                background-color: ${themeColor("primaryGrey")};
            `
    }
}

const getIconBtnStyle = (t?: IconButtonType) => {
    switch (t) {
        case "primary":
            return css`
                color: ${themeColor("white")};
                background-color: ${themeColor("blueGrey")};
                padding-left: 6px;
                &:hover {
                    background-color: ${themeColor("primaryGrey")};
                }
            `
        case "secondary":
            return css`
                color: ${themeColor("white")};
                background-color: ${themeColor("primaryGrey")};
                &:hover {
                    background-color: ${themeColor("black")};
                }
            `
        case "icon-only":
            return css`
                padding: 0 4px;
                border: 1px solid ${themeColor("secondaryGrey")};
                background-color: ${themeColor("white")};
                &:hover {
                    border: 1px solid ${themeColor("primaryGrey")};
                }
            `
    }
}

const getDisabledBtnStyle = (t?: ButtonType | IconButtonType) => {
    switch (t) {
        case "action":
            return css`
                opacity: 0.5;
                border: 1px solid ${themeColor("secondaryGrey")};
                pointer-events: none;
                cursor: not-allowed;
            `
        default:
            return css`
                opacity: 0.5;
                pointer-events: none;
                cursor: not-allowed;
            `
    }
}

export const RadioButton: React.FC<TypedOmit<ToggleButtonProps, "type">> = p => <ToggleButton {...p} type="radio" />
export const SwitchButton: React.FC<TypedOmit<ToggleButtonProps, "type">> = p => <ToggleButton {...p} type="switch" />
export const CheckboxButton: React.FC<TypedOmit<ToggleButtonProps, "type">> = p => (
    <ToggleButton {...p} type="checkbox" />
)
export const TOGGLE_BUTTON_CONTAINER_CLASS = "toggle-button-container"
type ToggleButtonType = "radio" | "checkbox" | "switch"
type ToggleButtonProps = {
    disabled?: boolean
    checked?: boolean
    onChange: F1<boolean>
    label?: string | React.ReactNode
    type: ToggleButtonType
}
const ToggleButton: React.FC<ToggleButtonProps> = p => {
    const [checked, setChecked] = React.useState(p.checked || false)

    const check = () => {
        if (p.disabled) return
        p.onChange(!checked)
        typeof p.checked === "undefined" && setChecked(!checked)
    }
    React.useEffect(() => {
        setChecked(p.checked || false)
    }, [p.checked, setChecked])
    const getContent = () => {
        switch (p.type) {
            case "radio":
                return <RadioButtonCircle type="button" disabled={p.disabled} checked={checked} />
            case "checkbox":
                return <CheckboxRect type="button" disabled={p.disabled} checked={checked} />
            case "switch":
                return <SwitchSlider type="button" disabled={p.disabled} checked={checked} />
        }
    }
    // TODO add htmlFor to label when we switch to inputs
    return (
        <FlexRow alignCenter className={TOGGLE_BUTTON_CONTAINER_CLASS}>
            <ToggleWrapper type={p.type} disabled={p.disabled} onClick={check}>
                {getContent()}
            </ToggleWrapper>
            {p.label && (
                <>
                    <HorizontalSpace base="8px" />
                    <Label onClick={check}>{p.label}</Label>
                </>
            )}
        </FlexRow>
    )
}

const ToggleWrapper = styled(Center)<Pick<ToggleButtonProps, "disabled" | "type">>`
    width: ${p => rem(p.type === "switch" ? 36 : 20)};
    height: ${rem(20)};
    cursor: pointer;
    ${styleIfProp("disabled", "cursor: default;")}
`

const ToggleButtonBaseStyles = css<Pick<ToggleButtonProps, "disabled">>`
    outline: none;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    ${styleIfProp("disabled", "cursor: default;")}
    &::after {
        box-sizing: border-box;
    }
`

const SwitchSlider = styled.button<Pick<ToggleButtonProps, "checked" | "disabled">>`
    ${ToggleButtonBaseStyles}
    width: ${rem(32)};
    height: ${rem(20)};
    border: 2px solid ${p => themeColor(p.checked ? "primary15" : "white")};
    border-radius: ${rem(10)};
    background-color: ${p => themeColor(p.checked ? "primary" : "grey70")};
    transition: background-color 0.1s ease-in border 0.1s ease-in;
    &::after {
        content: " ";
        position: absolute;
        width: ${rem(12)};
        height: ${rem(12)};
        top: ${rem(2)};
        left: ${p => (p.checked ? rem(14) : rem(2))};
        border-radius: 50%;
        background-color: ${themeColor("white")};
        transition: left 0.1s ease-in;
    }
`

const CheckboxRect = styled.button<Pick<ToggleButtonProps, "checked" | "disabled">>`
    ${ToggleButtonBaseStyles}
    width: ${rem(16)};
    height: ${rem(16)};
    border: 1px solid ${p => themeColor(p.disabled ? "grey70" : p.checked ? "primary" : "grey60")};
    border-radius: ${rem(3)};
    background-color: ${p => themeColor(p.disabled ? "grey70" : p.checked ? "primary" : "white")};
    transition: background-color 0.1s ease-in border 0.1s ease-in;

    &::after {
        content: " ";
        position: absolute;
        width: ${rem(5)};
        height: ${rem(10)};
        top: ${rem(1)};
        left: ${rem(4)};
        display: inline-block;
        transform: rotate(45deg);
        border-bottom: 2px solid ${themeColor("white")};
        border-right: 2px solid ${themeColor("white")};
        ${styleIfProp("checked", "opacity: 1", "opacity: 0")};
        transition: opacity 0.1s ease-in;
    }
`

// TODO: change to input type
const RadioButtonCircle = styled.button<Pick<ToggleButtonProps, "checked" | "disabled">>`
    ${ToggleButtonBaseStyles}
    width: ${rem(14)};
    height: ${rem(14)};
    padding: ${rem(6)};
    border: 1px solid ${themeColor("grey60")};
    border-radius: 50%;
    background-color: ${themeColor("white")};
    &::after {
        content: " ";
        position: absolute;
        width: ${rem(6)};
        height: ${rem(6)};
        top: ${rem(3)};
        left: ${rem(3)};
        border-radius: 50%;
        background-color: ${themeColor("primary")};
        ${styleIfProp("checked", "opacity: 1", "opacity: 0")};
        transition: opacity 0.1s ease-in;
    }
`

const ButtonStyles = css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "IBM Plex Sans", sans-serif;
    outline: none;
    border: none;
    border-radius: 4px;
`

const ButtonWrapper = styled.button<ButtonBaseProps & { btnType?: ButtonType }>`
    ${ButtonStyles}
    height: ${rem(40)};
    padding: 0 18px;
    ${p => getBtnStyle(p.btnType)}
    ${p => p.disabled && getDisabledBtnStyle(p.btnType)}
`

const IconButtonWrapper = styled.button<ButtonBaseProps & { btnType?: IconButtonType }>`
    ${ButtonStyles}
    border: none;
    flex-shrink: 0;
    height: ${rem(24)};
    border-radius: ${rem(100)};
    padding: 0 6px;
    ${p => getIconBtnStyle(p.btnType)}
    ${p => p.disabled && getDisabledBtnStyle(p.btnType)}
`
