import styled, { css, keyframes } from "styled-components"
import { themeColor, themeConfig, themeMedia } from "@smartdevis/ui/src/utils/theme"
import { styleIfProp } from "@smartdevis/ui/src/utils/common"
import { TableCellEditableWrapper } from "./TableCell.styles"
import { TMap } from "@smartdevis/utils/src/map"
import { F0 } from "@smartdevis/utils/src/types"

export const GRID_COLUMNS_SUM = 24
export const getGridColumns = (cols: number[] = [24]): string => {
    if (cols.reduce((a, c) => a + c) !== GRID_COLUMNS_SUM)
        // eslint-disable-next-line no-console
        console.error("Incorrect grid columns sum. Should be: ", GRID_COLUMNS_SUM)
    return cols.map(c => `${c}fr`).join(" ")
}

const HideAnimation = keyframes`
from { visiblity: hidden; }
to { visibility: visible; }
`
const FontSizeAnimation = keyframes`
0% { transform: scale(1); }
33% { transform: scale(1.5); }
100% { transform: scale(1); }
`

const TableFormPanel = styled.div<{ show: boolean }>`
    transition: opacity 0.2s;
    position: absolute;
    top: 0;
    z-index: 5;
    padding: 0 4px;
    background-color: transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    opacity: ${p => (p.show ? 0.9 : 0)};
    animation: ${HideAnimation} 0.1s ${p => (p.show ? 0 : "0.2s")};
`

export const TableFormInfoPanel = styled(TableFormPanel)<{ animate: boolean }>`
    right: 100%;
    z-index: 4;
    font-size: 18px;
    ${styleIfProp(
        "animate",
        css`
            animation: ${FontSizeAnimation} 0.4s;
        `
    )}
`

export const TableFormActionPanel = styled(TableFormPanel)`
    left: 100%;
`

export type RowVisual =
    | "header"
    | "noBorder"
    | "clickable"
    | "sticky"
    | "title"
    | "subtitle"
    | "bottomBorder"
    | "bottomMargin"
    | "bold"
    | "paddingTop"
    | "paddingTopSmall"
    | "parent"
    | "greyText"
    | "hoverable"
    | "editable"
    | "error"
    | "loading"
    | "whiteBackground"
    | "added"
    | "edited"
    | "removed"

export const InheritableVisuals: RowVisual[] = ["removed"]
export const getInheritableVisuals = (vs: RowVisual[] = []) => vs.filter(v => InheritableVisuals.includes(v))

// Important! Adding a new row visual also requires using applyRowVisual() in styled components below!
const rowVisualsMap: TMap<RowVisual, ReturnType<typeof css> | string> = {
    parent: css``, //its a flag visual no notify whether to display TAG
    added: css`
        background-color: ${themeColor("rank1ColorLight")};
    `,
    edited: css`
        background-color: ${themeColor("rank3ColorLight")};
    `,
    removed: css`
        background-color: ${themeColor("rank4ColorLight")};
    `,
    error: css`
        background-color: ${themeColor("rank4ColorLight")};
        border-bottom: 1px solid ${themeColor("rank5ColorLight")};
    `,
    bold: css`
        font-weight: 500;
    `,
    bottomBorder: css`
        border-bottom: 1px solid ${themeColor("grey50")};
    `,
    bottomMargin: css`
        margin-bottom: 16px;
    `,
    clickable: css`
        cursor: pointer;
        ${TableCellEditableWrapper} {
            &:hover {
                background-color: ${themeColor("grey40")};
            }
        }
    `,
    editable: css`
        background-color: ${themeColor("grey35")};
    `,
    whiteBackground: css`
        background-color: ${themeColor("white")};
    `,
    greyText: css`
        color: ${themeColor("grey70")};
    `,
    paddingTop: css`
        padding-top: 16px;
    `,
    paddingTopSmall: css`
        padding-top: 8px;
    `,
    header: css`
        border: 0;
        padding: 0 1px;
        border-radius: 4px 4px 0 0;
        font-size: ${themeConfig("fontSizeSmall")};
        color: ${themeColor("grey70")};
    `,
    hoverable: css`
        &:hover {
            background-color: ${themeColor("grey35")};
        }
    `,
    noBorder: css`
        border: 0;
    `,
    sticky: css`
        position: sticky;
        top: 0;
        z-index: 4;
        background-color: white;
        border-bottom: 1px solid ${themeColor("grey50")};
    `,
    title: css`
        font-size: 1.2rem;
        font-weight: 500;
        box-shadow: 0 2px 5px 2px rgba(60, 64, 67, 0.01);
    `,
    subtitle: css`
        font-weight: 500;
        font-size: 1.05rem;
    `,
    loading: ""
}

export const applyRowVisual = (visual: RowVisual) => (p: { visuals?: RowVisual[] }) =>
    p.visuals?.includes(visual) ? rowVisualsMap[visual] : ""

type TableRowProps = { grid: number[]; onClick?: F0; visuals?: RowVisual[] }

export const StyledTableRow = styled.div<TableRowProps>`
    display: grid;
    grid-template-columns: ${p => getGridColumns(p.grid)};
    border-top: 1px solid ${themeColor("grey50")};
    border-bottom: none;
    transition: ease-in-out 0.1s all;
    ${p =>
        p.onClick &&
        css`
            &:hover {
                background-color: ${themeColor("grey30")};
            }
        `}
    ${themeMedia("max", "sm")} {
        grid-template-columns: 1fr;
    }

    ${applyRowVisual("title")}
    ${applyRowVisual("subtitle")}
    ${applyRowVisual("header")}
    ${applyRowVisual("noBorder")}
    ${applyRowVisual("clickable")}
    ${applyRowVisual("editable")}
    ${applyRowVisual("error")}
    ${applyRowVisual("bold")}
    ${applyRowVisual("greyText")}
    ${applyRowVisual("paddingTop")}
    ${applyRowVisual("paddingTopSmall")}
`

export const TableRowContainer = styled.div<{ visuals?: RowVisual[] }>`
    position: relative;

    ${applyRowVisual("sticky")}
    ${applyRowVisual("bottomBorder")}
    ${applyRowVisual("bottomMargin")}
    ${applyRowVisual("hoverable")}
    ${applyRowVisual("editable")}
    ${applyRowVisual("whiteBackground")}
    ${applyRowVisual("added")}
    ${applyRowVisual("edited")}
    ${applyRowVisual("removed")}
`
