import * as React from "react"
import { ArchitectContent, SubContent } from "../../components/layouts/Content"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect } from "../../resolvers"
import { PageHeader } from "../../components/layouts/Header"
import { Domain } from "@smartdevis/server/src/domain"
import { Asset } from "@smartdevis/ui/src/Asset"
import { DropdownMenuOption, Dropdown, mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Row, Table } from "../../components/table/Table"
import { mkCell, mkCellEmpty } from "../../components/table/TableCell"
import { values } from "@smartdevis/utils/src/map"
import { getFullName } from "@smartdevis/server/src/models/user"
import { projectPaths } from "../../paths"
import { F0 } from "@smartdevis/utils/src/types"
import { IconButton } from "@smartdevis/ui/src/Button"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import {
    ProjectDeleteModal,
    ProjectDuplicateConfirmModal,
    ProjectTemplateModal,
    useProjectActions
} from "./ProjectsModals"
import { useCloudAction } from "../../hooks/useCloudAction"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { sortCreatable } from "@smartdevis/utils/src/comparable"

const mkProjectRow = (p: Domain.ProjectDetails, onClick: F0, options: DropdownMenuOption[]): Row<"project"> => ({
    type: "project",
    grid: [6, 6, 11, 1],
    mode: "static",
    rowId: p.projectId,
    onClick,
    visuals: ["clickable"],
    cells: [
        mkCell(p.name),
        mkCell(`${p.street} ${p.streetNumber} ${p.city}`),
        mkCell(getFullName(p.client)),
        mkCell(
            <Dropdown options={options}>
                <Asset name="DotsFilled" size="icon" />
            </Dropdown>
        )
    ]
})

const headerRow = (): Row<"header"> => ({
    type: "header",
    grid: [6, 6, 11, 1],
    mode: "static",
    rowId: "header",
    visuals: ["header"],
    cells: [mkCell(i18n("Name")), mkCell(i18n("Address")), mkCell(i18n("Client")), mkCellEmpty()]
})

export const ProjectsView = asyncConnect({
    stateResolvers: ["results", "projectsDetails"],
    actions: ["duplicateProject", "saveAsTemplate", "mutate", "navigate"]
})(p => {
    const { state, setState, setDelta, newId, systemTemplates, isSystemTemplate } = useProjectActions()
    const { remove } = prepareMutation("projects", p.mutate)
    const onEdit = (pr: Domain.ProjectDetails) =>
        pr && p.navigate(projectPaths.editProject, { projectId: pr.projectId })

    const { onSubmit: duplicateProject, actionState: duplicateActionState } = useCloudAction<string, string>(
        (actionId, name, projectId) =>
            p.duplicateProject({
                actionId,
                name,
                projectId,
                newProjectId: newId,
                systemTemplate: systemTemplates.findIndex(v => v.projectId === projectId) !== -1
            }),
        p.results,
        () => setState("duplicate")(false)
    )

    const { onSubmit: saveAsTemplate } = useCloudAction<{ name: string; projectId: string; clearValues: boolean }>(
        (actionId, { name, projectId, clearValues }) =>
            p.saveAsTemplate({ actionId, name, projectId, newProjectId: genTemporaryId(), clearValues }),
        p.results
    )

    return (
        <>
            <ArchitectContent>
                <SubContent>
                    <PageHeader title={i18n("Projects")} />
                    <Table<"project" | "header">
                        rows={[
                            headerRow(),
                            ...values(p.projectsDetails)
                                .sort(sortCreatable("desc"))
                                .map(pr =>
                                    mkProjectRow(
                                        pr,
                                        () => p.navigate(projectPaths.overview, { projectId: pr.projectId }),
                                        [
                                            mkDropdownOption(i18n("Edit"), "edit", () => onEdit(pr)),
                                            mkDropdownOption(i18n("Duplicate"), "duplicate", () =>
                                                setDelta({ project: pr, duplicate: true })
                                            ),
                                            mkDropdownOption(i18n("Save as template"), "template", () =>
                                                setDelta({ project: pr, template: true })
                                            ),
                                            mkDropdownOption(i18n("Remove"), "remove", () =>
                                                setDelta({ project: pr, delete: true })
                                            )
                                        ]
                                    )
                                )
                        ]}
                        rowHierarchy={["project"]}
                    />
                    <VerticalSpace base="8px" />

                    <IconButton icon="CrossWhite" onClick={() => p.navigate(projectPaths.newProject)}>
                        {i18n("Add new project")}
                    </IconButton>
                </SubContent>
            </ArchitectContent>
            <ProjectDeleteModal
                isTemplate={state.project?.isTemplate}
                setShow={setState("delete")}
                onConfirm={remove(state.project as any)}
                show={state.delete}
            />
            <ProjectDuplicateConfirmModal
                show={state.duplicate}
                setShow={setState("duplicate")}
                onConfirm={() => duplicateProject(state.projectName, state.project?.projectId || "")}
                name={state.projectName}
                setName={setState("projectName")}
                processing={duplicateActionState.type === "Processing"}
                systemTemplate={isSystemTemplate}
            />
            <ProjectTemplateModal
                show={state.template}
                setShow={setState("template")}
                onConfirm={() =>
                    saveAsTemplate({
                        name: state.projectName,
                        projectId: state.project?.projectId || "",
                        clearValues: state.clearValuesOption
                    })
                }
                name={state.projectName}
                setName={setState("projectName")}
                check={{ checked: state.clearValuesOption, setChecked: setState("clearValuesOption") }}
            />
        </>
    )
})
