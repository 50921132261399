import { SMap, values } from "@smartdevis/utils/src/map"
import { DisplayNumberOptions, displayNumber, NumberType, mulSafeNumbers } from "@smartdevis/utils/src/numbers"
import { Domain } from "../domain"

export const TAX = 0.081

export const displayTax = (delta: DisplayNumberOptions = {}, price?: number, noCurrency?: boolean) => {
    const taxDisplay = displayNumber(TAX * 100, {
        isSafe: false,
        fractionDigits: 2,
        type: NumberType.Percent,
        showPositiveSign: true,
        ...delta
    })
    if (price === undefined) return taxDisplay
    return `${taxDisplay} = ${displayNumber(price, {
        type: noCurrency ? NumberType.Number : NumberType.Currency,
        showPositiveSign: true
    })}`
}

export const displayDeductionPriceFromCalculation = (
    d: Domain.Deduction,
    calculation: CalculatedDevis,
    withCHF?: boolean
) =>
    displayDeductionPrice(
        d,
        calculation.offerValues[d.deductionId],
        calculation.pricesByDeduction[d.deductionId],
        withCHF
    )

export const displayDeductionPercentage = (d: Domain.Deduction, calculation: CalculatedDevis) =>
    d.valueType === "percent"
        ? displayNumber(calculation.offerValues[d.deductionId] ?? d.value ?? 0, {
              type: NumberType.Percent,
              isSafe: true,
              sign: d.sign,
              showPositiveSign: true,
              fractionDigits: 2
          })
        : ""

export const displayDeductionPrice = (
    deduction: Domain.Deduction,
    offerValue: number,
    price: number,
    withCHF?: boolean
) => {
    const rawValue = offerValue ?? deduction.value ?? 0
    const priceToDisplay = displayNumber(price, {
        type: withCHF ? NumberType.Currency : NumberType.Number,
        sign: deduction.sign,
        showPositiveSign: true
    })
    if (deduction.valueType === "number") return priceToDisplay
    return `${displayNumber(rawValue, {
        type: NumberType.Percent,
        isSafe: true,
        sign: deduction.sign,
        showPositiveSign: true,
        fractionDigits: 2
    })} = ${priceToDisplay}`
}

export const displaySubtotal = (price: number) =>
    displayNumber(price, { fractionDigits: 2, showPositiveSign: true, type: NumberType.Number })

export const sumPositionsPrices = (positions: SMap<Domain.Position>, offerValues: SMap<number>): number =>
    values(positions).reduce((price, p) => (price += mulSafeNumbers(offerValues[p.positionId] ?? 0, p.amount)), 0)

export const calculateSubTotal = (
    deductions: Domain.Deduction[] = [],
    offerValues: Domain.OfferValues,
    startingPrice: number
) => {
    let total = 0
    const pricesByDeduction: SMap<number> = {}
    deductions.forEach(d => {
        const rawValue = d.value ?? offerValues[d.deductionId] ?? 0
        const value =
            (d.sign === "positive" ? 1 : -1) *
            (d.valueType === "number" ? rawValue : mulSafeNumbers(startingPrice, rawValue / 100))
        pricesByDeduction[d.deductionId] = value
        total += value
    })
    return { total, calculatedPrice: startingPrice + total, deductionPrices: pricesByDeduction }
}

export type CalculatedDevis = {
    gross: number
    net: number
    netincltax: number
    tax: number
    pricesBySection: SMap<number>
    pricesByDeduction: SMap<number>
    subTotalsBySection: SMap<number>
    offerValues: Domain.OfferValues
    offerInformation: Domain.OfferInformation
}
export const calculateDevis = (
    sections: Domain.Section[],
    deductionsBySection: SMap<Domain.Deduction[]>,
    offerValues: Domain.OfferValues,
    offerInformation: Domain.OfferInformation,
    gross: number
): CalculatedDevis => {
    let totalPrice = gross
    const pricesBySection: SMap<number> = {}
    const subTotalsBySection: SMap<number> = {}
    let pricesByDeduction: SMap<number> = {}
    sections.forEach(({ sectionId }) => {
        const subtotal = calculateSubTotal(deductionsBySection[sectionId], offerValues, totalPrice)
        totalPrice = subtotal.calculatedPrice
        subTotalsBySection[sectionId] = subtotal.calculatedPrice
        pricesBySection[sectionId] = subtotal.total
        pricesByDeduction = { ...pricesByDeduction, ...subtotal.deductionPrices }
    })
    const tax = totalPrice * TAX
    return {
        gross,
        netincltax: totalPrice + tax,
        net: totalPrice,
        tax,
        subTotalsBySection,
        pricesBySection,
        pricesByDeduction,
        offerInformation,
        offerValues
    }
}
