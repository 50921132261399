import { mkResolver } from "./resolverUtils"
import { getTranslationsService } from "../services/translations"
import { isNotFetched } from "@smartdevis/utils/src/async"
import { clientActions } from "../store"

export const resolveTranslations = mkResolver(() => getTranslationsService().getTranslationsMap())
export const resolveAdminDevisOverview = mkResolver(
    s => s.data.adminDevisOverview,
    s => (isNotFetched(s.data.adminDevisOverview) ? [clientActions.fetchAdminDevisOverview()] : [])
)
