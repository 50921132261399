import * as React from "react"
import dayjs from "dayjs"
import { i18n } from "@smartdevis/client/src/services/translations"
import { useCloudAction } from "../../../hooks/useCloudAction"
import { useNotifications } from "../../NotificationsProvider"
import { AsyncConnectResults } from "../../../resolvers"
import {
    mkDeductions,
    mkDeductionsBySection,
    mkDeductionSections,
    mkDeltasByRef,
    mkDevisAdditionalInformation,
    mkLastRound,
    mkPositions,
    mkPositionSections,
    mkRequestsBySubmitter,
    mkSublevels
} from "@smartdevis/server/src/models/shared"
import { calculateDevis, sumPositionsPrices } from "@smartdevis/server/src/utils/money"
import { Status } from "@smartdevis/ui/src/Status"
import { useArrayState } from "@smartdevis/ui/src/hooks/common"
import { Button } from "@smartdevis/ui/src/Button"
import { FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { isOfferInState, mkOfferInformationByTime, mkOfferValuesByTime } from "@smartdevis/server/src/utils/offer"
import { Popover } from "@smartdevis/ui/src/Popover"
import { F0 } from "@smartdevis/utils/src/types"
import { values, remap, TMap } from "@smartdevis/utils/src/map"
import { isDefined, identity, matchPattern } from "@smartdevis/utils/src/misc"
import { getTs, genTemporaryId } from "@smartdevis/utils/src/id"
import { Domain } from "@smartdevis/server/src/domain"
import { dateTimeFormat } from "@smartdevis/server/src/constants"

const getNotificationMessage = (offerState: Domain.OfferState, multiple = false) => {
    switch (offerState.type) {
        case "contracted":
            return i18n("Contract prepared, you can view it in the finalization step")
        case "cancelled":
            return multiple ? i18n("Offers cancelled") : i18n("Offer cancelled")
        case "next-round":
            return i18n("Next round created")
        case "negotiation":
            return i18n("Final round created")
        default:
            return ""
    }
}

export const useOfferStateUpdate = (
    p: AsyncConnectResults<"projectDetails" | "devis" | "results", "updateRequestsState">,
    onDone: F0
) => {
    const [notificationMessage, setNotificationMessage] = React.useState<string>("")
    const { pushNotification } = useNotifications()

    const { onSubmit, actionState } = useCloudAction<{
        offersIds: string[]
        state: Domain.OfferState
    }>(
        (actionId, payload) => {
            setNotificationMessage(getNotificationMessage(payload.state, payload.offersIds.length > 1))
            p.updateRequestsState({
                actionId,
                devisId: p.devis.devisId,
                projectId: p.projectDetails.projectId,
                ...payload
            })
        },
        p.results,
        () => {
            onDone()
            // setProposedContractor(null)
            if (notificationMessage) pushNotification(notificationMessage)
        }
    )
    return { onUpdateOfferState: onSubmit, updateActionState: actionState }
}

export const useContractorInvitations = (
    p: AsyncConnectResults<"projectDetails" | "devis" | "results", "createRequests">,
    onDone: F0
) => {
    const { pushNotification } = useNotifications()
    const { onSubmit, actionState } = useCloudAction<{ submittersIds: string[]; message: string; entryTs?: number }>(
        (actionId, payload) =>
            p.createRequests({
                actionId,
                createdTs: getTs(),
                devisId: p.devis.devisId,
                projectId: p.projectDetails.projectId,
                ...payload
            }),
        p.results,
        () => {
            onDone()
            pushNotification(i18n("Contractors invited"))
        }
    )
    return { onInvite: onSubmit, invitationState: actionState }
}

export const useOfferReminders = (
    p: AsyncConnectResults<"projectDetails" | "devis" | "results", "sendReminders">,
    onDone: F0
) => {
    const { pushNotification } = useNotifications()
    const { onSubmit, actionState } = useCloudAction<string[], string>(
        (actionId, offersIds, message) =>
            p.sendReminders({
                actionId,
                message,
                createdTs: getTs(),
                devisId: p.devis.devisId,
                projectId: p.projectDetails.projectId,
                offersIds
            }),
        p.results,
        () => {
            onDone()
            pushNotification(i18n("Reminders sent"))
        }
    )
    return { onSendReminders: onSubmit, remindersState: actionState }
}

export const useRoundSender = (
    p: AsyncConnectResults<"projectDetails" | "devisCollections" | "devis" | "results", "sendRound">,
    onDone: F0
) => {
    const round = mkLastRound(p.devisCollections)
    const { onSubmit, actionState } = useCloudAction<string, number>(
        (actionId, message = "", ts) => {
            if (!round) return
            p.sendRound({
                actionId,
                devisId: p.devis.devisId,
                projectId: p.projectDetails.projectId,
                roundId: round.roundId,
                message,
                ts
            })
        },
        p.results,
        onDone
    )
    return { onSendRound: onSubmit, roundSendState: actionState }
}
export const useRoundCreator = (
    p: AsyncConnectResults<"projectDetails" | "devis" | "results", "createRound">,
    onDone: F0
) => {
    const { onSubmit, actionState } = useCloudAction<string[]>(
        (actionId, offersIds) =>
            p.createRound({
                actionId,
                projectId: p.projectDetails.projectId,
                round: {
                    devisId: p.devis.devisId,
                    createdTs: getTs(),
                    offersIds,
                    roundId: genTemporaryId()
                }
            }),
        p.results,
        onDone
    )
    return { onCreateRound: onSubmit, roundCreationState: actionState }
}

export const usePreparedOffersData = (p: AsyncConnectResults<"devisCollections" | "devis" | "contractors">) => {
    const round = mkLastRound(p.devisCollections)
    const deltasByRef = mkDeltasByRef(p.devisCollections)

    const requestsBySubmitter = mkRequestsBySubmitter(p.devisCollections, r =>
        round ? round.offersIds.includes(r.offerId) : true
    )

    const submitters = values(p.contractors).filter(c =>
        round ? isDefined(requestsBySubmitter[c.contractorId]) : p.devis.contractorIds.includes(c.contractorId)
    )

    const positions = mkPositions(p.devisCollections, deltasByRef, { keepDeleted: false })
    const deductions = mkDeductions(p.devisCollections, deltasByRef, { keepDeleted: false })
    const deductionsBySections = mkDeductionsBySection(p.devisCollections, deltasByRef, { keepDeleted: false })
    const deductionSections = mkDeductionSections(p.devisCollections, deltasByRef, { keepDeleted: false })
    const sublevels = mkSublevels(p.devisCollections, deltasByRef, { keepDeleted: false })

    const calculationsByTimeBySubmitter = React.useMemo(
        () =>
            remap(requestsBySubmitter, identity, r => {
                const offersByTime = mkOfferValuesByTime(p.devisCollections.rounds, r)
                const informationByTime = mkOfferInformationByTime(p.devisCollections.rounds, r)
                return remap(offersByTime, identity, (offers, time) =>
                    calculateDevis(
                        deductionSections,
                        deductionsBySections,
                        offers,
                        informationByTime[time] ?? {}, // values and information are added in the same time
                        sumPositionsPrices(positions, offers)
                    )
                )
            }),
        [requestsBySubmitter]
    )

    return {
        round,
        requestsBySubmitter,
        submitters,
        positions,
        deductions,
        deductionSections: mkDeductionSections(p.devisCollections, deltasByRef, { keepDeleted: false }),
        positionSections: mkPositionSections(p.devisCollections, deltasByRef, { keepDeleted: false }),
        additionalInformation: mkDevisAdditionalInformation(p.devisCollections, deltasByRef, {
            keepDeleted: false
        }),
        deductionsBySections,
        sublevels,
        calculationsByTimeBySubmitter
    }
}

export const getOfferStatusProps = (
    request?: Pick<Domain.ArchitectOfferRequest, "statesHistory">
): React.ComponentProps<typeof Status> => {
    if (!request || !request.statesHistory.length) return { icon: "IconChangeRequested", text: i18n("Not yet invited") }
    const lastState = request.statesHistory[request.statesHistory.length - 1]
    const lastStateTs = lastState.createdTs ?? 0
    const dateDisplay = `\n${dayjs(lastStateTs).format(dateTimeFormat).toString()}`
    switch (lastState.state.type) {
        case "requested":
            return { icon: "IconProgress", text: i18n("Requested $1", dateDisplay) }
        case "submitted":
            return { icon: "IconReceived", text: i18n("Offer received $1", dateDisplay) }
        case "round-submitted":
            return { icon: "IconReceived", text: i18n("Second offer received $1", dateDisplay) }
        case "contracted":
            return { icon: "ContractOk", text: i18n("Contract created $1", dateDisplay) }
        case "adopted":
            return { icon: "IconProgress", text: i18n("Offer is being created $1", dateDisplay) }
        case "cancelled":
            return { icon: "IconRejected", text: i18n("Request cancelled $1", dateDisplay) }
        case "rejected":
            return { icon: "IconRejected", text: i18n("Request rejected $1", dateDisplay) }
        case "next-round":
            return { icon: "IconProgress", text: i18n("Next round requested $1", dateDisplay) }
        case "negotiation":
            return { icon: "IconProgress", text: i18n("In negotiation from $1", dateDisplay) }
        case "final-proposal":
            return { icon: "IconProgress", text: i18n("Final prices are in review $1", dateDisplay) }
        default: {
            return { icon: "IconRejected", text: i18n("No data") }
        }
    }
}

export type OfferHeaderButton = {
    text: string
    onClick: F0
    disabledReason?: OfferButtonRequirement
}
const mkOfferButton = (text: string, onClick: F0, disabledReason?: OfferButtonRequirement) => ({
    text,
    onClick,
    disabledReason
})

export type OfferButtonRequirement =
    | "userValidated"
    | "noContract"
    | "atLeastOne"
    | "atMostThree"
    | "requestsExist"
    | "requestsDontExist"
    | "submittedOffer"
    | "notAllPending"
    | "firstRoundSubmitted"
    | "compareSameStatusExceptContract"
    | "compareSameStatus"
    | "secondRoundSameStatus"
    | "negotiationSameStatus"
    | "notRejected"
    | "sentOrBeforeRound"
    | "noRound"
    | "inRequestState"
    | "notSubmittedOffer"

export const getRequirementHint = (requirementNotMet?: OfferButtonRequirement): string | undefined => {
    if (!requirementNotMet) return undefined
    const hints: TMap<OfferButtonRequirement, string | undefined> = {
        userValidated: i18n("Your user account hasn't been filled with all necessary data"),

        noContract: undefined,
        atLeastOne: undefined,
        atMostThree: undefined,
        requestsExist: undefined,
        requestsDontExist: undefined,
        submittedOffer: undefined,
        notAllPending: undefined,
        firstRoundSubmitted: undefined,
        inRequestState: undefined,
        compareSameStatusExceptContract: undefined,

        compareSameStatus: i18n("You can only compare contractors with the same status"),
        secondRoundSameStatus: i18n("You can start the second round only with contractors with the same status"),
        negotiationSameStatus: i18n("You can start the negotiation round only with contractors with the same status"),

        sentOrBeforeRound: undefined,
        notRejected: undefined,
        noRound: undefined
    }
    return hints[requirementNotMet]
}

export const getOfferHeaderButtons = (buttons: OfferHeaderButton[]) => {
    return (
        <FlexRow alignCenter>
            <Join items={buttons} renderJoining={() => <HorizontalSpace base="8px" />}>
                {b => {
                    const requirementHint = getRequirementHint(b.disabledReason)
                    return requirementHint ? (
                        <Popover direction="bottom" content={requirementHint}>
                            <Button btnType="header" onClick={b.onClick} disabled>
                                {b.text}
                            </Button>
                        </Popover>
                    ) : (
                        <Button btnType="header" onClick={b.onClick} disabled={isDefined(b.disabledReason)}>
                            {b.text}
                        </Button>
                    )
                }}
            </Join>
        </FlexRow>
    )
}

export function findFailedRequirementBuilder(
    data: Pick<ReturnType<typeof usePreparedOffersData>, "round" | "submitters" | "requestsBySubmitter">,
    checked: string[],
    userValidated: boolean
) {
    return function checkRequirement(requirements: OfferButtonRequirement[]) {
        return requirements.find(requirement => {
            const getR = (submitterId: string) => data.requestsBySubmitter[submitterId]
            return !matchPattern(requirement)({
                noContract: _ =>
                    data.submitters.every(s => !isOfferInState(getR(s.contractorId), ["contracted", "final-proposal"])),
                atMostThree: _ => checked.length <= 3,
                atLeastOne: _ => checked.length >= 1,
                userValidated: _ => userValidated,
                notRejected: _ => checked.every(s => !isOfferInState(getR(s), ["rejected", "cancelled"])),
                inRequestState: _ => checked.every(s => isOfferInState(getR(s), ["requested"])),
                requestsExist: _ => checked.every(s => isDefined(data.requestsBySubmitter[s])),
                requestsDontExist: _ => checked.every(s => !data.requestsBySubmitter[s]),
                notAllPending: _ => !checked.every(s => isOfferInState(getR(s), ["requested", "adopted"])),
                compareSameStatusExceptContract: _ =>
                    checked.some(s => getR(s).state.type === "contracted")
                        ? true
                        : checked.every(s => getR(s).state.type === getR(checked[0]).state.type),
                compareSameStatus: _ => checked.every(s => getR(s).state.type === getR(checked[0]).state.type),
                negotiationSameStatus: _ => checked.every(s => getR(s).state.type === getR(checked[0]).state.type),
                submittedOffer: _ =>
                    checked.every(s => isOfferInState(getR(s), ["submitted", "next-round", "round-submitted"])),
                notSubmittedOffer: _ =>
                    checked.every(s => isOfferInState(getR(s), ["requested", "next-round", "final-proposal"])),
                secondRoundSameStatus: _ =>
                    checked.every(s => isOfferInState(getR(s), ["submitted"])) ||
                    checked.every(s => !isOfferInState(getR(s), ["submitted"])),
                firstRoundSubmitted: _ => checked.every(s => isOfferInState(getR(s), ["submitted"])),
                sentOrBeforeRound: _ =>
                    !isDefined(data.round) ||
                    checked.every(s => isOfferInState(getR(s), ["next-round", "round-submitted", "negotiation"])),
                noRound: _ => !isDefined(data.round)
            })
        })
    }
}

export const useOffersSubmitters = (
    data: Pick<ReturnType<typeof usePreparedOffersData>, "round" | "submitters" | "requestsBySubmitter">,
    userValidated: boolean
) => {
    const getR = (submitterId: string) => data.requestsBySubmitter[submitterId]
    const initialIds = data.submitters.map(s => s.contractorId)
    const initialChecked = initialIds.filter(
        cid => !getR(cid) || (isDefined(data.round) && isOfferInState(getR(cid), ["submitted"]))
    )
    const { items: checked, toggle, setItems } = useArrayState<string>(initialChecked)
    const [invitationModalOpen, setInvitationModalOpen] = React.useState(false)
    const [submittersToCompare, setSubmittersToCompare] = React.useState<string[] | null>(null)
    const [secondRoundModalOpen, setSecondRoundModalOpen] = React.useState(false)
    const [cancelModalOpen, setCancelModalOpen] = React.useState(false)
    const [reminderModalOpen, setReminderModalOpen] = React.useState(false)
    const [negotiateModalOpen, setNegotiateModalOpen] = React.useState(false)
    const [sendRoundModalOpen, setSendRoundModalOpen] = React.useState(false)
    const findFailedRequirement = findFailedRequirementBuilder(data, checked, userValidated)

    const contractExists = data.submitters.some(s =>
        isOfferInState(getR(s.contractorId), ["contracted", "final-proposal"])
    )
    const roundToSend = !contractExists && data.round && checked.every(s => isOfferInState(getR(s), ["submitted"]))

    const buttons = [
        mkOfferButton(
            i18n("Invite selected"),
            () => setInvitationModalOpen(true),
            findFailedRequirement(["userValidated", "requestsDontExist", "noContract", "notRejected", "atLeastOne"])
        ),
        mkOfferButton(
            i18n("Details / Compare (Max 3)"),
            () => setSubmittersToCompare(checked),
            findFailedRequirement([
                "requestsExist",
                "notAllPending",
                // "compareSameStatus", commented as architect can compare offers of different statuses after finalization
                "compareSameStatusExceptContract",
                // "sentOrBeforeRound", TODO: commented as architect cannot see the offer after contractor submit
                "atMostThree",
                "atLeastOne"
            ])
        ),
        roundToSend
            ? mkOfferButton(
                  i18n("Invite for Second Round"),
                  () => setSendRoundModalOpen(true),
                  findFailedRequirement(["requestsExist", "firstRoundSubmitted", "notRejected", "atLeastOne"])
              )
            : mkOfferButton(
                  i18n("Start Second Round"),
                  () => setSecondRoundModalOpen(true),
                  findFailedRequirement([
                      "requestsExist",
                      "noRound",
                      "notAllPending",
                      "secondRoundSameStatus",
                      "firstRoundSubmitted",
                      "noContract",
                      "atLeastOne"
                  ])
              ),
        mkOfferButton(
            i18n("Negotiate (Max 3)"),
            () => setNegotiateModalOpen(true),
            findFailedRequirement([
                "requestsExist",
                "notAllPending",
                "negotiationSameStatus",
                "sentOrBeforeRound",
                "submittedOffer",
                "noContract",
                "atMostThree",
                "atLeastOne"
            ])
        ),
        mkOfferButton(
            i18n("Reject"),
            () => setCancelModalOpen(true),
            findFailedRequirement(["requestsExist", "notRejected", "atLeastOne"])
        ),
        mkOfferButton(
            i18n("Send reminder"),
            () => setReminderModalOpen(true),
            findFailedRequirement(["atLeastOne", "notSubmittedOffer"])
        )
    ]

    return {
        buttons,
        checked,
        toggle,
        toCompare: submittersToCompare,
        cancelCompare: () => setSubmittersToCompare(null),
        set: setItems,
        isOpen: {
            invitationModal: invitationModalOpen,
            cancelModal: cancelModalOpen,
            secondRoundModal: secondRoundModalOpen,
            negotiateModal: negotiateModalOpen,
            sendRoundModal: sendRoundModalOpen,
            reminderModal: reminderModalOpen
        },
        close: {
            invitationModal: () => setInvitationModalOpen(false),
            secondRoundModal: () => setSecondRoundModalOpen(false),
            cancelModal: () => setCancelModalOpen(false),
            negotiateModal: () => setNegotiateModalOpen(false),
            sendRoundModal: () => setSendRoundModalOpen(false),
            reminderModal: () => setReminderModalOpen(false)
        }
    }
}
