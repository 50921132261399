import { Domain, Utils } from "@smartdevis/server/src/domain"
import { units } from "@smartdevis/server/src/constants"
import { en } from "./i18n"
import { getStore } from "../store"
import { getRawTranslations, getTranslations, setTranslations } from "./translationsStore"
import { Async, mkFetching, mkFetched } from "@smartdevis/utils/src/async"
import { TMap, remap, SMap, values, keys } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { mkOk } from "@smartdevis/utils/src/result"
import { Casted } from "@smartdevis/utils/src/types"
import { format } from "@smartdevis/utils/src/text"
import { requestData } from "./server"
import { translationsTs } from "../translations"

export const encodeTranslationKey = (key: string) => key.replace(/\//g, "&slash&").replace(/\./g, "&dot&")
export const decodeTranslationKey = (key: string) => key.replace(/&slash&/g, "/").replace(/&dot&/g, ".")

const init = async () => {
    setTranslations({ type: "Fetching" })
    const result = await requestData<Domain.Translations>("public", {
        path: "/translations/:timestamp",
        validators: [mkOk],
        urlParams: { timestamp: translationsTs.toString() }
    })
    setTranslations(result)
}

const detectLanguage = (): Utils.Language => {
    const browserLang = navigator.languages ? navigator.languages[0] : navigator.language
    return browserLang.startsWith("de") ? "de" : "en"
}

export const mkTranslation = (lang: Utils.Language, text: string) => ({ lang, text })
export type Translation = ReturnType<typeof mkTranslation>

export type TranslationsMap = Casted<typeof en, TMap<Utils.Language, string>>

const getTranslationsMap = (): Async<TranslationsMap> => {
    const translations = getRawTranslations()
    if (translations.type !== "Fetched") return mkFetching()
    const ts = translations.value
    return mkFetched(remap(en, identity, (_, k) => ts[k]) as TranslationsMap)
}

const _i18n = (text: string, l: Utils.Language = "en", ...args: string[]) => {
    const fallback = (en as SMap<string>)[text] ?? text
    const t = getTranslations()[text]?.[l] || fallback
    return format(t, ...args)
}

export const getStoreLanguage = () => getStore().getState().meta.language
export const i18n = (text: string, ...args: any[]) => _i18n(text, getStoreLanguage(), ...args)

const langs: TMap<Utils.Language, string> = { en: "English", de: "German" }

const getLanguagesNames = () => values(langs)
const getLanguages = () => keys(langs)

const mkTranslationService = () => ({
    init,
    getTranslationsMap,
    i18n: _i18n,
    getLanguages,
    getLanguagesNames,
    detectLanguage
})

const _instance = mkTranslationService()
export const getTranslationsService = () => _instance

//TODO: temporary solution for translating units
export type Unit = keyof typeof units
export const getUnitTranslation = (unit: string): string => {
    const translations: SMap<string> = {
        m: i18n("m"),
        m2: i18n("m²"),
        m3: i18n("m³"),
        st: i18n("St"),
        pl: i18n("pl"),
        gl: i18n("gl"),
        h: i18n("h"),
        d: i18n("d"),
        wo: i18n("Wo"),
        mt: i18n("Mt"),
        kg: i18n("kg"),
        t: i18n("t"),
        l: i18n("l"),
        hl: i18n("hl"),
        le: i18n("LE"),
        kj: i18n("kJ")
    }
    return translations[unit] || unit
}

export const getProjectTypeTranslation = (type: Domain.ProjectType): string => {
    const translations: TMap<Domain.ProjectType, string> = {
        new: i18n("new"),
        renovation: i18n("renovation"),
        restoration: i18n("restoration")
    }
    return translations[type]
}

export const getCustomTranslations = () => ({
    notFetched: i18n("Not fetched"),
    notFound: i18n("Not found"),
    notStringType: i18n("Not a string type"),
    notObjectType: i18n("Not an object type"),
    notNumberType: i18n("Not a number type"),
    notArrayType: i18n("Not an array type"),
    notBooleanType: i18n("Not an boolean type"),
    cannotBeEmpty: i18n("Cannot be empty"),
    shouldBeEmpty: i18n("Should be empty"),
    notAllowed: i18n("Value not allowed"),
    invalidDocument: i18n("Invalid document"),
    invalidObject: i18n("Invalid object"),
    duplicated: i18n("Duplicated"),
    notValidEmail: i18n("Not a valid email"),
    minLenString: i18n("Value is too short"),
    notMatchingValues: i18n("Values are not the same"),
    noUppercasePresent: i18n("No uppercase character present"),
    noLowercasePresent: i18n("No lowercase character present"),
    noDigitPresent: i18n("No digit character present"),
    notHexColor: i18n("Invalid hex color"),
    notEncryptionKey: i18n("Invalid encryption key type"),
    valueTooShort6: i18n("Value is too short (6)"),
    valueGreaterThan0: i18n("Value must be equal or greater than 0"),

    // our validators
    invalidDecimal: i18n("Invalid decimal number"),

    // field names
    Name: i18n("Name"),
    Description: i18n("Description"),
    Amount: i18n("Amount"),
    Unit: i18n("Unit"),

    //projectType
    new: i18n("new"),
    renovation: i18n("renovation"),
    restoration: i18n("restoration"),

    // firebase errors
    emailAlreadyInUse: i18n("The email address is already in use by another account.(auth/email-already-exists)")
})
