import { EventPayloads } from "@smartdevis/server/src/cloudEvents"
import { projectCollectionsKeys } from "@smartdevis/server/src/constants"
import { Domain } from "@smartdevis/server/src/domain"
import {
    DevisLevelMutation,
    Mutation,
    MutationSource,
    ProjectLevelMutation,
    TemplateLevelMutation,
    TopLevelMutation
} from "@smartdevis/server/src/models/mutation"
import { mkLatestOfferValues } from "@smartdevis/server/src/utils/offer"
import { EventActionMeta } from "@smartdevis/utils/src/actions"
import { mkFetched, isFetched, AsyncValue } from "@smartdevis/utils/src/async"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { values, omitObject, keys, SMap, filterObject } from "@smartdevis/utils/src/map"
import { F1, F0, ValueOf } from "@smartdevis/utils/src/types"
import { DataState } from "../store"

export type MutationCreators<T> = {
    create: (item: T, actionId?: string) => void
    update: (item: T, actionId?: string) => (delta: Partial<T>) => void
    remove: (item: T, actionId?: string) => F0
}
type MutationPayload = Mutation & EventActionMeta

const reduceSubstate = <
    K extends "devisCollections" | "projectPredefinedCollections" | "projectAttachments" | "devisTemplateCollections"
>(
    state: DataState,
    key: K,
    id: string,
    nextValue: AsyncValue<ValueOf<DataState[K]>>
): Partial<DataState> => ({ [key]: { ...state[key], [id]: mkFetched(nextValue) } })

export function prepareMutation<
    C extends keyof Domain.BaseCollections,
    I extends Domain.CollectionItem<Domain.BaseCollections[C]>
>(collection: C, dispatchAction: F1<MutationPayload>): MutationCreators<I>
export function prepareMutation<C extends Domain.ProjectCollection, I extends Domain.ProjectCollectionItem<C>>(
    collection: C,
    dispatchAction: F1<MutationPayload>,
    source: MutationSource
): MutationCreators<I>
export function prepareMutation<
    C extends keyof Domain.AllCollections,
    I extends Domain.CollectionItem<Domain.AllCollections[C]>
>(collection: C, dispatchAction: F1<MutationPayload>, source?: MutationSource): MutationCreators<I> {
    const mkPayload = (item: I, mutationType: Domain.MutationType, actionId = genTemporaryId()) =>
        ({ item, mutationType, collection, actionId, source } as MutationPayload)

    return {
        create: (item: I, actionId?: string) => dispatchAction(mkPayload(item, "create", actionId)),
        remove: (item: I, actionId?: string) => () => dispatchAction(mkPayload(item, "remove", actionId)),
        update: (item: I, actionId?: string) => (delta: Partial<I>) =>
            dispatchAction(mkPayload({ ...item, ...delta }, "update", actionId))
    }
}

export const reduceSubmitRoundDelta = (
    state: DataState,
    payload: EventPayloads["submitRoundDelta"]
): Partial<DataState> => {
    const ps = state.devisCollections[payload.devisId]
    if (!ps || !isFetched(ps)) return {}
    const pc = ps.value
    const collider = values(pc.deltas).find(v => v.roundId === payload.delta.roundId && v.refId === payload.delta.refId)
    if (collider) {
        const deltas = { ...pc.deltas, [collider.deltaId]: { ...payload.delta, deltaId: collider.deltaId } }
        return reduceSubstate(state, "devisCollections", payload.devisId, { ...pc, deltas })
    }
    const deltas = { ...pc.deltas, [payload.delta.deltaId]: payload.delta }
    return reduceSubstate(state, "devisCollections", payload.devisId, { ...pc, deltas })
}

export const reduceRevertRoundDelta = (
    state: DataState,
    payload: EventPayloads["revertRoundDelta"]
): Partial<DataState> => {
    const ps = state.devisCollections[payload.devisId]
    if (!ps || !isFetched(ps)) return {}
    const pc = ps.value
    return reduceSubstate(state, "devisCollections", payload.devisId, {
        ...pc,
        deltas: omitObject(pc.deltas, [payload.deltaId])
    })
}

export const reduceUpdateFinalOffer = (
    state: DataState,
    { devisId, valuesByOffer }: EventPayloads["updateFinalOffer"]
): Partial<DataState> => {
    const ps = state.devisCollections[devisId]
    if (!ps || !isFetched(ps)) return {}
    const pc = ps.value
    const rs = pc.requests
    keys(valuesByOffer).forEach(offerId => {
        const newFinalOffer = { ...mkLatestOfferValues(rs[offerId]), ...valuesByOffer[offerId] }
        rs[offerId] = { ...rs[offerId], finalOffer: newFinalOffer }
    })
    return reduceSubstate(state, "devisCollections", devisId, { ...pc, requests: rs })
}

export const reduceMutation = (state: DataState, { mutationType, ...payload }: Mutation): Partial<DataState> => {
    switch (payload.collection) {
        case "projects":
        case "contractors":
        case "devisTemplates":
            return reduceTopLevelMutation(state, mutationType, payload)
        default: {
            if (payload.source.type === "template")
                return reduceTemplateLevelMutation(state, mutationType, payload as TemplateLevelMutation)
            if (payload.source.type === "project")
                return reduceProjectLevelMutation(state, mutationType, payload as ProjectLevelMutation)
            if (payload.source.type === "devis")
                return reduceDevisLevelMutation(state, mutationType, payload as DevisLevelMutation)
            return {}
        }
    }
}

export const reducePartnersMutation = (
    state: DataState,
    { mutationType: _notUsed, ...partner }: EventPayloads["mutatePartners"]
): Partial<DataState> => {
    const partnersCollection = state.partners
    if (!partnersCollection || !isFetched(partnersCollection)) return {}
    const collection = partnersCollection.value
    return { partners: mkFetched({ ...collection, [partner.partnerId]: partner }) }
}

const reduceDevisLevelMutation = (
    state: DataState,
    mutationType: Domain.MutationType,
    mutation: DevisLevelMutation
): Partial<DataState> => {
    switch (mutation.collection) {
        case "devis": {
            if (!isFetched(state.devis)) return {}
            const id = mutation.item.devisId
            const devis = state.devis.value
            const nextCollection = mkFetched(
                mutationType === "remove" ? omitObject(devis, [id]) : { ...devis, [id]: mutation.item }
            )
            const emptyDevisCollections = reduceSubstate(state, "devisCollections", mutation.source.devisId, {})
            return mutationType === "create"
                ? { devis: nextCollection, ...emptyDevisCollections }
                : { devis: nextCollection }
        }
        case "attachments": {
            // Note: new attachments should not be reduced with optimisticui - time to upload a file can take long enough that we need to show the process
            const c = state.projectAttachments[mutation.source.projectId]
            if (!isFetched(c) || mutationType !== "remove") return {}
            const id = mutation.item.attachmentId
            return reduceSubstate(state, "projectAttachments", mutation.source.projectId, omitObject(c.value, [id]))
        }
        default: {
            const collectionState = state.devisCollections[mutation.source.devisId]
            if (!collectionState || !isFetched(collectionState)) return {}
            const id = mutation.item[
                projectCollectionsKeys[mutation.collection] as keyof typeof mutation.item
            ] as any as string
            const pc = collectionState.value
            const collection = pc[mutation.collection]
            const nextCollection =
                mutationType === "remove" ? omitObject(collection, [id]) : { ...collection, [id]: mutation.item }
            const nextState = { ...pc, [mutation.collection]: nextCollection }
            return reduceSubstate(state, "devisCollections", mutation.source.devisId, nextState)
        }
    }
}

const reduceTemplateLevelMutation = (
    state: DataState,
    mutationType: Domain.MutationType,
    mutation: TemplateLevelMutation
): Partial<DataState> => {
    switch (mutation.collection) {
        case "attachments": {
            // Note: attachments should not be reduced with optimisticui - time to upload a file can take long enough that we need to show the process
            const c = state.devisTemplateCollections[mutation.source.templateId]
            if (!isFetched(c) || mutationType !== "remove") return {}
            const id = mutation.item.attachmentId
            return reduceSubstate(state, "devisTemplateCollections", mutation.source.templateId, {
                ...c.value,
                attachments: omitObject(c.value.attachments, [id])
            })
        }
        default: {
            const id = mutation.item[projectCollectionsKeys[mutation.collection] as keyof typeof mutation.item] as any
            const collectionState = state.devisTemplateCollections[mutation.source.templateId]
            if (!collectionState || !isFetched(collectionState)) return {}
            const pc = collectionState.value
            const collection = pc[mutation.collection]
            const nextCollection =
                mutationType === "remove" ? omitObject(collection, [id]) : { ...collection, [id]: mutation.item }
            const nextState = { ...pc, [mutation.collection]: nextCollection }
            return reduceSubstate(state, "devisTemplateCollections", mutation.source.templateId, nextState)
        }
    }
}

const reduceProjectLevelMutation = (
    state: DataState,
    mutationType: Domain.MutationType,
    mutation: ProjectLevelMutation
): Partial<DataState> => {
    switch (mutation.collection) {
        case "attachments": {
            // Note: attachments should not be reduced with optimisticui - time to upload a file can take long enough that we need to show the process
            const c = state.projectAttachments[mutation.source.projectId]
            if (!isFetched(c) || mutationType !== "remove") return {}
            const id = mutation.item.attachmentId
            return reduceSubstate(state, "projectAttachments", mutation.source.projectId, omitObject(c.value, [id]))
        }
        default: {
            const collectionState = state.projectPredefinedCollections[mutation.source.projectId]
            if (!collectionState || !isFetched(collectionState)) return {}
            const id = (mutation.item as any)[projectCollectionsKeys[mutation.collection]] as string
            const pc = collectionState.value
            const collection = pc[mutation.collection]
            const nextCollection =
                mutationType === "remove" ? omitObject(collection, [id]) : { ...collection, [id]: mutation.item }
            const nextState = { ...pc, [mutation.collection]: nextCollection }
            return reduceSubstate(state, "projectPredefinedCollections", mutation.source.projectId, nextState)
        }
    }
}

const createProjectMutation = (pd: Domain.ProjectDetails): Domain.ProjectDetails => {
    let retPd = { ...pd }

    if (retPd?.streetNumber?.length) {
        retPd.street = retPd.street.replace(retPd.streetNumber as string, "")
    }
    if (retPd?.billing?.streetNumber?.length)
        retPd.billing = {
            ...retPd.billing,
            street: retPd.billing.street.replace(retPd.billing.streetNumber as string, "")
        }
    if (retPd?.client?.streetNumber?.length)
        retPd.client = {
            ...retPd.client,
            street: retPd.client.street.replace(retPd.client.streetNumber as string, "")
        }
    if (retPd?.clientsRepresentative?.streetNumber?.length)
        retPd.clientsRepresentative = {
            ...retPd.clientsRepresentative,
            street: retPd.clientsRepresentative.street.replace(retPd.clientsRepresentative.streetNumber as string, "")
        }
    if (retPd?.constructionManagement?.streetNumber?.length)
        retPd.constructionManagement = {
            ...retPd.constructionManagement,
            street: retPd.constructionManagement.street.replace(retPd.constructionManagement.streetNumber as string, "")
        }
    if (retPd?.other?.streetNumber?.length)
        retPd.other = {
            ...retPd.other,
            street: retPd.other.street.replace(retPd.other.streetNumber as string, "")
        }
    if (retPd?.owner?.streetNumber?.length)
        retPd.owner = {
            ...retPd.owner,
            street: retPd.owner.street.replace(retPd.owner.streetNumber as string, "")
        }
    if (retPd?.ownersRepresentative?.streetNumber?.length)
        retPd.ownersRepresentative = {
            ...retPd.ownersRepresentative,
            street: retPd.ownersRepresentative.street.replace(retPd.ownersRepresentative.streetNumber as string, "")
        }
    if (retPd?.planner?.streetNumber?.length)
        retPd.planner = {
            ...retPd.planner,
            street: retPd.planner.street.replace(retPd.planner.streetNumber as string, "")
        }
    if (retPd?.specialistPlanner?.streetNumber?.length)
        retPd.specialistPlanner = {
            ...retPd.specialistPlanner,
            street: retPd.specialistPlanner.street.replace(retPd.specialistPlanner.streetNumber as string, "")
        }
    if (retPd?.specialistPlanner2?.streetNumber?.length)
        retPd.specialistPlanner2 = {
            ...retPd.specialistPlanner2,
            street: retPd.specialistPlanner2.street.replace(retPd.specialistPlanner2.streetNumber as string, "")
        }
    if (retPd?.specialistPlanner3?.streetNumber?.length)
        retPd.specialistPlanner3 = {
            ...retPd.specialistPlanner3,
            street: retPd.specialistPlanner3.street.replace(retPd.specialistPlanner3.streetNumber as string, "")
        }
    if (retPd?.specialistPlanner4?.streetNumber?.length)
        retPd.specialistPlanner4 = {
            ...retPd.specialistPlanner4,
            street: retPd.specialistPlanner4.street.replace(retPd.specialistPlanner4.streetNumber as string, "")
        }
    if (retPd?.specialistPlanner5?.streetNumber?.length)
        retPd.specialistPlanner5 = {
            ...retPd.specialistPlanner5,
            street: retPd.specialistPlanner5.street.replace(retPd.specialistPlanner5.streetNumber as string, "")
        }

    return retPd
}

export const reduceTopLevelMutation = (
    state: DataState,
    mutationType: Domain.MutationType,
    mutation: TopLevelMutation
): Partial<DataState> => {
    switch (mutation.collection) {
        case "projects": {
            if (!isFetched(state.projectsDetails)) return {}
            const stateValue = state.projectsDetails.value
            const id = mutation.item.projectId
            const nextState =
                mutationType === "remove"
                    ? omitObject(stateValue, [id])
                    : {
                          ...stateValue,
                          [id]: createProjectMutation(mutation.item)
                      }
            return { projectsDetails: mkFetched(nextState) }
        }
        case "contractors": {
            if (!isFetched(state.contractors)) return {}
            const stateValue = state.contractors.value
            const id = mutation.item.contractorId
            const nextState =
                mutationType === "remove" ? omitObject(stateValue, [id]) : { ...stateValue, [id]: mutation.item }
            return { contractors: mkFetched(nextState) }
        }
        case "devisTemplates": {
            if (!isFetched(state.devisTemplates)) return {}
            const stateValue = state.devisTemplates.value
            const id = mutation.item.templateId
            const nextState =
                mutationType === "remove" ? omitObject(stateValue, [id]) : { ...stateValue, [id]: mutation.item }
            return { devisTemplates: mkFetched(nextState) }
        }
    }
}

export const reduceCreateDevisPositions = (
    state: DataState,
    payload: EventPayloads["setFileForDevis"] | EventPayloads["createPositionsFromTemplate"]
): Partial<DataState> => {
    const { devis } = state
    if (!devis || !isFetched(devis)) return {}
    const devisCollections = state.devisCollections[payload.devisId]
    if (!devisCollections || !isFetched(devisCollections)) return {}
    const rounds = devisCollections.value.rounds

    const currentRoundId = keys(rounds)[0]

    if (currentRoundId && "file" in payload)
        return reduceSubmitRoundDelta(state, {
            devisId: payload.devisId,
            projectId: payload.projectId,
            delta: {
                type: "edit",
                deltaId: genTemporaryId(),
                roundId: currentRoundId,
                refId: payload.devisId,
                parent: "fileAttachment",
                value: payload.file
            }
        })

    const fetchedDevis = devis.value

    const nextDevisState: SMap<Domain.Devis> = {
        ...fetchedDevis,
        [payload.devisId]: {
            ...fetchedDevis[payload.devisId],
            positionsFormat:
                "file" in payload ? { type: "file-based", fileAttachment: payload.file } : { type: "positions-based" }
        }
    }

    return { devis: mkFetched(nextDevisState) }
}

export const reduceRemoveDevisPositionsTile = (
    state: DataState,
    payload: EventPayloads["removePositionsFormat"]
): Partial<DataState> => {
    const { devis } = state
    if (!devis || !isFetched(devis)) return {}
    const devisCollections = state.devisCollections[payload.devisId]
    if (!devisCollections || !isFetched(devisCollections)) return {}

    const fetchedDevis = devis.value
    const fetchedDevisCollections = devisCollections.value

    const nextDevisState: SMap<Domain.Devis> = {
        ...fetchedDevis,
        [payload.devisId]: { ...fetchedDevis[payload.devisId], positionsFormat: { type: "not-selected" } }
    }

    const nextDevisCollectionsState: Domain.DevisCollections = {
        ...fetchedDevisCollections,
        sections: { ...filterObject(fetchedDevisCollections.sections, (_, v) => v.type !== "position") },
        positions: {}
    }

    return {
        ...reduceSubstate(state, "devisCollections", payload.devisId, nextDevisCollectionsState),
        devis: mkFetched(nextDevisState)
    }
}

export const reduceCreateOfferProposal = (
    state: DataState,
    payload: EventPayloads["updateOfferValues"]
): Partial<DataState> => {
    const { offerId, offerProposal } = payload
    const contractorOffers = state.contractorOffers
    if (!contractorOffers || !isFetched(contractorOffers)) return {}
    const fetchedContractorOffer = contractorOffers.value[offerId]

    const currentRoundId =
        fetchedContractorOffer.state.type === "next-round" ? fetchedContractorOffer.state.roundId : null

    const nextState: SMap<Domain.ContractorOffer> = {
        ...contractorOffers.value,
        [payload.offerId]: currentRoundId
            ? {
                  ...fetchedContractorOffer,
                  roundOfferProposals: {
                      ...fetchedContractorOffer.roundOfferProposals,
                      [currentRoundId]: offerProposal ? offerProposal : null
                  }
              }
            : {
                  ...fetchedContractorOffer,
                  offerProposal
              }
    }

    return {
        contractorOffers: mkFetched(nextState)
    }
}

export const reduceRemoveOfferProposal = (
    state: DataState,
    payload: EventPayloads["removeOfferProposal"]
): Partial<DataState> => {
    const contractorOffers = state.contractorOffers
    if (!contractorOffers || !isFetched(contractorOffers)) return {}
    const fetchedContractorOffer = contractorOffers.value[payload.offerId]

    const currentRoundId =
        fetchedContractorOffer.state.type === "next-round" ? fetchedContractorOffer.state.roundId : null

    const nextState: SMap<Domain.ContractorOffer> = {
        ...contractorOffers.value,
        [payload.offerId]: {
            ...fetchedContractorOffer,
            offerProposal: currentRoundId ? fetchedContractorOffer.offerProposal : null,
            roundOfferProposals: currentRoundId
                ? { ...fetchedContractorOffer.roundOfferProposals, [currentRoundId]: null }
                : fetchedContractorOffer.roundOfferProposals
        }
    }

    return {
        contractorOffers: mkFetched(nextState)
    }
}
