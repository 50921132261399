import { Cmd } from "redux-loop"
import { Domain } from "@smartdevis/server/src/domain"
import { mkFetching, mkFetchError } from "@smartdevis/utils/src/async"
import { SMap } from "@smartdevis/utils/src/map"
import { Authentication } from "../authState"
import { clientActions } from "../"
import { FetchOptions, mkFetchCmd } from "../dataQueries"
import { requestData } from "../../services/server"

export const fetchContractorsDirectory = (auth: Authentication, options: FetchOptions = {}) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setApp("contractorsDirectory", mkFetching())),
            core: Cmd.run(
                async () =>
                    requestData<SMap<Domain.DirectoryContractor>>("authorized", {
                        path: "/contractors-directory",
                        auth,
                        validators: [],
                        isV2: true
                    }),
                {
                    successActionCreator: res => clientActions._setApp("contractorsDirectory", res),
                    failActionCreator: (error: Error) =>
                        clientActions._setApp("contractorsDirectory", mkFetchError(error.message))
                }
            )
        },
        options
    )

export const addContractor = (auth: Authentication, body: Domain.DirectoryContractor) =>
    mkFetchCmd({
        pre: Cmd.action(clientActions._setApiStatus("fetching")),
        core: Cmd.run(
            async () =>
                requestData<any>("authorized", {
                    path: "/contractors-directory",
                    auth,
                    validators: [],
                    isV2: true,
                    reqMethod: "POST",
                    body
                }),
            {
                successActionCreator: res => clientActions._setApiStatus("contractorsDirectoryFetched"),
                failActionCreator: (error: Error) =>
                    clientActions._setApp("contractorsDirectory", mkFetchError(error.message))
            }
        )
    })

export const editContractor = (auth: Authentication, body: Domain.DirectoryContractor & { id: string | undefined }) => {
    const { id, ...operationBody } = body
    return mkFetchCmd({
        pre: Cmd.action(clientActions._setApiStatus("fetching")),
        core: Cmd.run(
            async () =>
                requestData<any>("authorized", {
                    path: `/contractors-directory/${id}`,
                    auth,
                    validators: [],
                    isV2: true,
                    reqMethod: "PUT",
                    body: operationBody
                }),
            {
                successActionCreator: res => clientActions._setApiStatus("contractorsDirectoryFetched"),
                failActionCreator: (error: Error) =>
                    clientActions._setApp("contractorsDirectory", mkFetchError(error.message))
            }
        )
    })
}

export const deleteContractor = (auth: Authentication, id: string | undefined) =>
    mkFetchCmd({
        pre: Cmd.action(clientActions._setApiStatus("fetching")),
        core: Cmd.run(
            async () =>
                requestData<any>("authorized", {
                    path: `/contractors-directory/${id}`,
                    auth,
                    validators: [],
                    isV2: true,
                    reqMethod: "DELETE"
                }),
            {
                successActionCreator: res => clientActions._setApiStatus("contractorsDirectoryFetched"),
                failActionCreator: (error: Error) =>
                    clientActions._setApp("contractorsDirectory", mkFetchError(error.message))
            }
        )
    })
