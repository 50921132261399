import {
    InputSchema,
    FormSchema,
    InputBoxType,
    InputBoxSchema,
    InputOptionType,
    Tuples,
    InputOptionSchema,
    CustomInputBoxSchema,
    CustomInputOptionSchema,
    MultiselectInputSchema,
    StyledCustom
} from "@smartdevis/forms/src"
import { mapObject } from "@smartdevis/utils/src/map"
import { ValidationMap } from "@smartdevis/utils/src/validators"

export type CustomBoxSubtype = "date" | "formatted-number" | "switch" | "tax"
export type CustomOptionSubtype = "contractor"

const emptyTaxInputMask = "CHE-___.___.___"

export const mkFormSchema = <T>(
    vmap: ValidationMap<T>,
    subschemas: { [P in keyof T]: InputSchema<T[P]> }
): FormSchema<T> =>
    mapObject(subschemas, (key, schema: InputSchema<any>) => {
        switch (schema.type) {
            case "list":
            case "collection":
                return schema
            default:
                if (!schema.validators) return { ...schema, validators: vmap[key] || [] }
                return schema
        }
    }) as FormSchema<T>

export const mkInputBoxSchema =
    (type: Exclude<InputBoxType, "customBox">) =>
    (name: string, delta: Partial<InputBoxSchema<any>> = {}): InputBoxSchema<any> => ({ name, type, ...delta })

export const mkInputOptionSchema =
    (type: Exclude<InputOptionType, "customOption">) =>
    <T>(name: string, values: Tuples<T>, delta: Partial<InputOptionSchema<T>> = {}): InputOptionSchema<T> => ({
        name,
        type,
        values,
        ...delta
    })

export const mkCustomBoxSchema =
    (subtype: CustomBoxSubtype, subdelta: Partial<CustomInputBoxSchema<any>> = {}) =>
    (name: string, delta: Partial<CustomInputBoxSchema<any>> = {}): CustomInputBoxSchema<any> => ({
        type: "customBox",
        name,
        subtype,
        ...subdelta,
        ...delta
    })

export const mkCustomOptionSchema =
    (subtype: CustomOptionSubtype, subdelta: Partial<CustomInputOptionSchema<any>> = {}) =>
    <T>(
        name: string,
        values: Tuples<T>,
        delta: Partial<CustomInputOptionSchema<T>> = {}
    ): CustomInputOptionSchema<any> => ({
        type: "customOption",
        name,
        values,
        subtype,
        ...subdelta,
        ...delta
    })

export const mkMultiselectSchema = <T>(
    name: string,
    values: Tuples<T>,
    delta: Partial<MultiselectInputSchema<T>> = {}
): MultiselectInputSchema<any> => ({ name, type: "multiselect", values, ...delta })

export const mkTextSchema = mkInputBoxSchema("text")
export const mkEmailSchema = mkInputBoxSchema("email")
export const mkPasswordSchema = mkInputBoxSchema("password")
export const mkHiddenSchema = mkInputBoxSchema("hidden")
export const mkNumberSchema = mkInputBoxSchema("number")
export const mkTextareaSchema = mkInputBoxSchema("textarea")

export const mkSelectSchema = mkInputOptionSchema("select")
export const mkRadioSchema = mkInputOptionSchema("radio")

export const mkDatePickerSchema = mkCustomBoxSchema("date")
export const mkSwitchSchema = mkCustomBoxSchema("switch", { toValue: Boolean })
export const mkTaxSchema = mkCustomBoxSchema("tax", { toValue: v => (v === emptyTaxInputMask ? "" : v) })
export const mkFormattedNumberSchema = mkCustomBoxSchema("formatted-number", {
    fromValue: v => {
        if (v === "NaN" || Number.isNaN(v)) return undefined
        return v
    },
    toValue: v => {
        const numberValue = parseFloat(v as any)
        return Number.isNaN(numberValue) ? v : numberValue
    }
})
export const mkContractorPickerSchema = mkCustomOptionSchema("contractor")

export const mkNullableFormattedToValue = (v: string) =>
    v === "NaN" || v === undefined ? null : typeof v === "number" ? v : Number.parseFloat(v)

export const mkStyledCustom = <T extends any>(value: T): StyledCustom<T> => ({ type: "Custom", value })
