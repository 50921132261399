import * as React from "react"
import { i18n } from "../services/translations"
import { Domain } from "@smartdevis/server/src/domain"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Select } from "@smartdevis/ui/src/Selects"
import { F2 } from "@smartdevis/utils/src/types"
import { TMap } from "@smartdevis/utils/src/map"

type SortingKey = keyof Pick<Domain.Contractor, "companyName" | "postalCode" | "workCategory" | "number">

const getTranslations = (): TMap<SortingKey, string> => ({
    companyName: i18n("Company name"),
    number: i18n("Number"),
    postalCode: i18n("Postal code"),
    workCategory: i18n("Work category")
})

export const useContractorSorting = (contractors: Domain.Contractor[]) => {
    const [state, setState] = React.useState<{ key: SortingKey; dir: "asc" | "desc" }>({
        key: "companyName",
        dir: "asc"
    })
    const sortedContractors = contractors.sort((a, b) =>
        (
            state.dir === "asc"
                ? (a[state.key]?.toLowerCase() || "") > (b[state.key]?.toLowerCase() || "")
                : (a[state.key]?.toLowerCase() || "") < (b[state.key]?.toLowerCase() || "")
        )
            ? 1
            : -1
    )
    return {
        current: state.key,
        dir: state.dir,
        onSelect: (key: SortingKey, dir: "asc" | "desc") => {
            setState({ key, dir })
        },
        sortedContractors
    }
}

export const ContactorSortingDropdown: React.FC<{
    onSelect: F2<SortingKey, "asc" | "desc">
    current: SortingKey
    dir: "asc" | "desc"
}> = p => {
    return (
        <Select
            mode="select"
            fullHeight
            value={`${p.current} ${p.dir}`}
            options={(
                [
                    "companyName",
                    "companyName",
                    "postalCode",
                    "postalCode",
                    "workCategory",
                    "workCategory",
                    "number",
                    "number"
                ] as SortingKey[]
            ).map((key, index) =>
                mkDropdownOption(
                    index % 2 === 0
                        ? i18n("$1 ascending", getTranslations()[key])
                        : i18n("$1 descending", getTranslations()[key]),
                    `${key} ${index % 2 === 0 ? "asc" : "desc"}`,
                    () => p.onSelect(key, index % 2 === 0 ? "asc" : "desc")
                )
            )}
        />
    )
}
