import * as React from "react"
import { F1, F0 } from "@smartdevis/utils/src/types"
import { i18n } from "@smartdevis/client/src/services/translations"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { ConfirmationModal } from "@smartdevis/ui/src/Modal"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"

export const RejectOfferConfirmModal: React.FC<{
    show: boolean
    setShow: F1<boolean>
    onConfirm: F0
    processing: boolean
}> = p => (
    <ConfirmationModal
        header={i18n("Are you sure you want to reject offer?")}
        visible={p.show}
        submitText={i18n("Reject")}
        cancelText={i18n("Cancel")}
        onSubmit={() => {
            p.onConfirm()
        }}
        onClose={p.processing ? undefined : () => p.setShow(false)}
        loading={p.processing}>
        <VerticalSpace h={32} />
        <InfoBox type="warning">{i18n("Submitting offer will be no longer available after rejection.")}</InfoBox>
        <VerticalSpace h={16} />
    </ConfirmationModal>
)
