import * as React from "react"
import imageCompression from "browser-image-compression"
import styled from "styled-components"
import { i18n } from "@smartdevis/client/src/services/translations"
import { IconButton } from "@smartdevis/ui/src/Button"
import { FlexColumn, FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { F0, F1, State } from "@smartdevis/utils/src/types"
import { useNotifications } from "../views/NotificationsProvider"

export type LogoState = State<"file", { file: File }> | State<"url", { url: string }> | State<"none">

const getLogoUrl = (file: File): Promise<string | null> =>
    new Promise(res => {
        const reader = new FileReader()

        reader.onload = () => (reader.result ? res(reader.result.toString()) : res(null))
        reader.onerror = () => res(null)
        reader.readAsDataURL(file)
    })

export const UploadLogo = (p: { onChange: F1<File>; logoState: LogoState; onDelete: F0 }) => {
    const hiddenFileInput = React.useRef(null)
    const { pushNotification } = useNotifications()
    const [logoUrl, setLogoUrl] = React.useState<string | null>(null)

    React.useEffect(() => {
        if (p.logoState.type === "url") setLogoUrl(p.logoState.url)
        else if (p.logoState.type === "file") getLogoUrl(p.logoState.file).then(setLogoUrl)
        else setLogoUrl(null)
    }, [p.logoState])

    const handleClick = () => {
        ;(hiddenFileInput.current as any).click()
    }
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = e.target?.files?.[0]
        if (!fileUploaded) return
        try {
            const image = await imageCompression(fileUploaded, { maxWidthOrHeight: 256 })
            p.onChange(image)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err)
            pushNotification(i18n("Something went wrong uploading the picture. Try again"))
        }
    }

    const ctaBtn = (
        <>
            <IconButton icon="EditWhite" onClick={handleClick}>
                {p.logoState.type === "none" ? i18n("Upload logo") : i18n("Change logo")}
            </IconButton>
            <input
                accept="image/*"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
                type="file"
            />
        </>
    )

    if (!logoUrl) return ctaBtn

    return (
        <LogoContainer>
            <FlexRow justifyCenter>
                <LogoImg src={logoUrl} />
            </FlexRow>
            <VerticalSpace h={16} />
            <FlexRow spaceBetween>
                {ctaBtn}
                <HorizontalSpace base="16px" />
                <IconButton icon="DeleteWhite" onClick={p.onDelete}>
                    {i18n("Remove")}
                </IconButton>
            </FlexRow>
        </LogoContainer>
    )
}

const LogoContainer = styled(FlexColumn)`
    background-color: ${themeColor("navBackground")};
    border: 1px solid ${themeColor("grey50")};
    border-radius: 12px;
    padding: 16px;
`

const LogoImg = styled.img`
    max-height: 100px;
    max-width: 100px;
`
