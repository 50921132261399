import * as React from "react"
import styled, { css } from "styled-components"
import { ButtonProps, Button } from "./Button"
import { themeColor, themeConfig } from "./utils/theme"
import { HorizontalSpace, styleIfProp } from "./utils/common"
import { Asset, ExternalAsset } from "./Asset"
import { Link } from "./Link"
import { rem } from "./utils"
import { Portal } from "./Portal"
import { TypographyBase } from "./Typography"
import { useAnimationToggle, useHostEvents, useNonInputScrollEvent } from "./hooks/common"
import {
    getOppositeHorizontalDirection,
    hasSpaceHorizontally,
    hasSpaceVertically,
    getOppositeVerticalDirection,
    noPropagationCallback,
    getOnScreenPosition,
    getClientWidth,
    getClientHeight
} from "./utils/dom"
import { EnterDown, ExitUp } from "./utils/animations"
import { pickObject } from "@smartdevis/utils/src/map"
import { Serializable, serialize } from "@smartdevis/utils/src/misc"
import { State, F0, F1 } from "@smartdevis/utils/src/types"
import { isEmpty } from "@smartdevis/utils/src/validators"

const MAX_DROPDOWN_HEIGHT = 250
const MAX_DROPDOWN_WIDTH = 600

const Trigger = styled.div<{ disabled?: boolean }>`
    cursor: ${styleIfProp("disabled", "auto", "pointer")};
    display: flex;
`

const Container = styled.div<Pick<DropdownProps, "fullWidth">>`
    position: relative;
    ${styleIfProp(
        "fullWidth",
        css`
            width: 100%;
            max-width: 800px;
        `
    )}
`

const OPTIONS_MIN_WIDTH_IN_PX = 100
const OptionsBase = css`
    position: fixed;
    background: ${themeColor("white")};
    border: 1px solid ${themeColor("grey50")};
    border-radius: 4px;
    padding: ${rem(3)} 0;
    min-width: ${rem(OPTIONS_MIN_WIDTH_IN_PX)};
    z-index: 12;
`

type OptionsContainerProps = Pick<DropdownProps, "xDirection" | "yDirection"> & {
    rect: DOMRect
    open: boolean
    closed: boolean
}

const getStylesForOptionsContainer = (p: OptionsContainerProps) => {
    const styles: React.CSSProperties = {}
    if (p.yDirection === "up") styles.bottom = `${getClientHeight() - p.rect.top + 4}px`
    else styles.top = `${p.rect.bottom + 4}px`
    if (p.xDirection === "left") styles.right = `${getClientWidth() - p.rect.right}px`
    else styles.left = `${p.rect.left}px`
    styles.transformOrigin = p.yDirection === "up" ? "bottom center" : "top center"
    return styles
}

const getStylesForSubOptionsContainer = (p: OptionsContainerProps) => {
    const styles: React.CSSProperties = {}
    if (p.yDirection === "up") styles.bottom = `${getClientHeight() - p.rect.bottom + 4}px`
    else styles.top = `${p.rect.top + 4}px`
    if (p.xDirection === "left") styles.right = `${getClientWidth() - p.rect.left - 2}px`
    else styles.left = `${p.rect.right + 2}px`
    return styles
}

const OptionsContainer = styled.ul.attrs<OptionsContainerProps>(p => ({
    style: getStylesForOptionsContainer(p)
}))<OptionsContainerProps>`
    ${OptionsBase}
    display: flex;
    flex-direction: column;
    margin: 0;
    white-space: wrap;
    max-height: ${MAX_DROPDOWN_HEIGHT}px;
    overflow: scroll;
    max-width: ${MAX_DROPDOWN_WIDTH}px;

    z-index: ${themeConfig("zIndexDropdown")};

    ${p => (p.open ? "transform: scaleY(1);" : "transform: scaleY(0);")}
    ${styleIfProp(
        "open",
        css`
            animation: ${EnterDown} 100ms ease-out;
        `
    )}
    ${styleIfProp(
        "closed",
        css`
            animation: ${ExitUp} 100ms ease-out;
        `
    )}
`

const OptionBase = css`
    ${TypographyBase(0.88)}
    color: ${themeColor("primaryGrey")};
    background-color: ${themeColor("white")};
    padding: ${rem(7)} 1rem;
    outline: none;
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    transition: color 0.3s fill 0.3s;
    &:hover {
        color: ${themeColor("primary")};
        path {
            stroke: ${themeColor("primary")};
        }
    }
`

const SubOptionsContainer = styled.ul.attrs<OptionsContainerProps>(p => ({
    style: getStylesForSubOptionsContainer(p)
}))<OptionsContainerProps>`
    ${OptionsBase}
    ${p => (p.open ? "transform: scaleY(1);" : "transform: scaleY(0);")}
`

const ButtonOption = styled.li<{ disabled?: boolean; selected?: boolean; empty?: boolean }>`
    ${OptionBase}
    ${styleIfProp(
        "disabled",
        css`
            color: ${themeColor("grey70")};
            path {
                stroke: ${themeColor("grey70")};
            }
            cursor: default;
            &:hover {
                color: ${themeColor("grey70")};
                path {
                    stroke: ${themeColor("grey70")};
                }
            }
        `
    )}
    ${styleIfProp(
        "empty",
        css`
            &:hover {
                background-color: ${themeColor("grey35")};
            }
        `
    )}
    ${styleIfProp(
        "selected",
        css`
            background-color: ${themeColor("primary08")};
        `
    )}
`

const LinkOption = styled(Link)`
    ${OptionBase}
`

export type DropdownMenuOption<T = string> = {
    label: string
    image?: string
    disabled?: boolean
    value: T
    selected?: boolean
} & (
    | State<"link", { to: string }>
    | State<"basic", { action?: F0 }>
    | State<"submenu", { subItems: DropdownMenuOption<T>[] }>
)

const calcOptionsWidth = <T extends Serializable = string>(opts: DropdownMenuOption<T>[]) =>
    opts.reduce((acc, opt) => {
        if (!opt.label.length && opt.image) return 128 + 32 // Max asset length + padding
        const optLength = opt.label.length * 7.5 + 32 // text length + padding
        const res = acc > optLength ? acc : optLength
        return res > MAX_DROPDOWN_WIDTH ? MAX_DROPDOWN_WIDTH : res
    }, OPTIONS_MIN_WIDTH_IN_PX)

const calcOptionsHeight = <T extends Serializable = string>(opts: DropdownMenuOption<T>[]) => {
    const calculatedHeight = opts.reduce((acc, opt) => (opt.image ? acc + 40 : acc + 34), 0) //Heuristic
    return calculatedHeight + 10 > MAX_DROPDOWN_HEIGHT ? MAX_DROPDOWN_HEIGHT : calculatedHeight + 10
}
export const mkDropdownOption = <T extends Serializable = string>(
    label: string,
    value: T,
    action?: F0,
    others: Pick<DropdownMenuOption<T>, "disabled"> = {}
): DropdownMenuOption<T> => ({
    type: "basic",
    label,
    value,
    action,
    ...others
})

export const mkDropdownImageOption = (
    imageSrc: string,
    value: string,
    others: Pick<DropdownMenuOption, "disabled"> = {}
): DropdownMenuOption => ({
    type: "basic",
    label: "",
    image: imageSrc,
    value,
    ...others
})

export const mkLinkDropdownOption = <T extends Serializable = string>(
    label: string,
    value: T,
    to: string,
    others: Pick<DropdownMenuOption<T>, "disabled"> = {}
): DropdownMenuOption<T> => ({
    type: "link",
    label,
    to,
    value,
    ...others
})
export const mkSubmenuDropdownOption = <T extends Serializable = string>(
    label: string,
    value: T,
    subItems: DropdownMenuOption<T>[],
    others: Pick<DropdownMenuOption, "disabled"> = {}
): DropdownMenuOption<T> => ({ type: "submenu", value, label, subItems, ...others })

type CloseCb = (source: "scroll" | "blur" | "trigger" | "submit" | "other") => void
export type DropdownProps<T = string> = {
    options: DropdownMenuOption<T>[]
    onClose?: CloseCb
    fullWidth?: boolean
    onOptionClick?: F1<T>
    disabled?: boolean
    open?: boolean
    noTrigger?: boolean
    xDirection?: "left" | "right"
    yDirection?: "down" | "up"
}

export const Dropdown = <T extends Serializable = string>(p: React.PropsWithChildren<DropdownProps<T>>) => {
    const [open, setOpen] = React.useState(false)
    const { animated: closed, animate: animateClose } = useAnimationToggle(100)
    const ref = React.useRef(null)
    const onClose = React.useCallback<CloseCb>(
        src => {
            setOpen(false)
            if (src !== "scroll") animateClose()
            p.onClose?.(src)
        },
        [setOpen, animateClose, p.onClose]
    )

    React.useEffect(() => {
        if (p.open && !open) setOpen(true)
        else if (!p.open && open) onClose("other")
    }, [p.open, setOpen, onClose])

    React.useEffect(() => {
        const body = document.querySelector("body")
        if (open && body) {
            const handler = (e: Event) => {
                const target = e.target as Element
                if (target?.closest(".options-wrapper") === null && target?.closest(".dropdown") !== ref.current)
                    onClose("blur")
            }
            body.addEventListener("click", handler)
            return () => body.removeEventListener("click", handler)
        }
    }, [open, onClose])
    const [, setRerender] = React.useState(false)
    useHostEvents(() => setRerender(v => !v))

    return (
        <Container ref={ref} className="dropdown" fullWidth={p.fullWidth}>
            {p.noTrigger ? (
                p.children
            ) : (
                <Trigger
                    disabled={p.disabled}
                    onClick={noPropagationCallback(() => (open ? onClose("trigger") : setOpen(true)))}>
                    {p.children}
                </Trigger>
            )}
            <OptionsRenderer
                rect={getOnScreenPosition(ref.current)}
                onClose={onClose}
                open={open && !p.disabled}
                closed={closed && !p.disabled}
                {...pickObject(p, ["options", "onOptionClick", "yDirection", "xDirection"])}
                options={p.options}
                onOptionClick={p.onOptionClick}
            />
        </Container>
    )
}

const OptionsRenderer = <T extends Serializable = string>(
    p: React.PropsWithChildren<
        {
            rect?: DOMRect
            open: boolean
            closed: boolean
            onClose: CloseCb
            isSubContainer?: boolean
        } & Pick<DropdownProps<T>, "onOptionClick" | "options" | "xDirection" | "yDirection">
    >
) => {
    const height = React.useMemo(() => calcOptionsHeight(p.options), [p.options])
    const [subMenuOpen, setSubMenuOpen] = React.useState<T | null>(null)
    React.useEffect(() => {
        if (p.closed) setSubMenuOpen(null)
    }, [p.closed])
    const focusedFlag = React.useRef(false)

    const hide = React.useCallback(
        () => (!focusedFlag.current && p.open ? p.onClose("scroll") : null),
        [p.onClose, p.open]
    )
    useNonInputScrollEvent(hide)

    if (isEmpty(p.options) || !p.rect) return null
    const y = p.yDirection ?? "down"
    const x = p.xDirection ?? "right"

    const xDir = hasSpaceHorizontally(x, p.rect, calcOptionsWidth(p.options)) ? x : getOppositeHorizontalDirection(x)
    const yDir = hasSpaceVertically(y, p.rect, height) ? y : getOppositeVerticalDirection(y)
    const Wrapper = p.isSubContainer ? SubOptionsContainer : OptionsContainer

    return (
        <Portal>
            <Wrapper
                rect={p.rect}
                className="options-wrapper"
                xDirection={xDir}
                yDirection={yDir}
                onMouseEnter={() => (focusedFlag.current = true)}
                onMouseLeave={() => (focusedFlag.current = false)}
                open={p.open}
                closed={p.closed}>
                {p.options.map(option => (
                    <DropdownOption
                        key={serialize(option.value)}
                        {...option}
                        xDirection={xDir}
                        yDirection={yDir}
                        subMenuOpen={subMenuOpen === option.value && p.open}
                        setSubMenuOpen={setSubMenuOpen}
                        onClose={p.onClose}
                        onClick={p.onOptionClick}
                    />
                ))}
            </Wrapper>
        </Portal>
    )
}

const DropdownOption = <T extends Serializable = string>(
    p: React.PropsWithChildren<
        DropdownMenuOption<T> &
            Required<Pick<DropdownProps<T>, "yDirection" | "xDirection">> & {
                onClose: CloseCb
                onClick?: F1<T>
                subMenuOpen: boolean
                setSubMenuOpen: F1<T>
            }
    >
) => {
    const ref = React.useRef(null)
    const rect = getOnScreenPosition(ref.current)

    switch (p.type) {
        case "link":
            return p.disabled ? (
                <ButtonOption disabled>{p.label}</ButtonOption>
            ) : (
                <LinkOption onClick={() => p.onClick?.(p.value)} to={p.to}>
                    {p.label}
                </LinkOption>
            )
        case "basic":
            return (
                <ButtonOption
                    disabled={p.disabled}
                    selected={p.selected}
                    empty={!p.label}
                    onClick={noPropagationCallback(() => {
                        if (p.disabled) return
                        p.action?.()
                        p.onClick?.(p.value)
                        p.onClose("submit")
                    })}>
                    {p.image && <ExternalAsset src={p.image} size="dropdown" />}
                    {p.label}
                    {p.selected && <Asset name="Check" size="small-icon" color="primaryGrey" />}
                </ButtonOption>
            )
        case "submenu": {
            return (
                <Container ref={ref} onMouseEnter={() => p.setSubMenuOpen(p.value)}>
                    <ButtonOption disabled={p.disabled}>
                        {p.label}
                        <HorizontalSpace base="8px" />
                        <Asset name="ArrowRight" size="icon" />
                    </ButtonOption>
                    {!p.disabled && (
                        <OptionsRenderer
                            options={p.subItems}
                            onClose={p.onClose}
                            rect={rect}
                            closed={!p.subMenuOpen}
                            onOptionClick={p.onClick}
                            isSubContainer
                            open={p.subMenuOpen}
                            xDirection={p.xDirection}
                            yDirection={p.yDirection}
                        />
                    )}
                </Container>
            )
        }
    }
}

export const DropdownButton: React.FC<ButtonProps & DropdownProps> = p => {
    return (
        <Dropdown {...p}>
            <Button btnType={p.btnType || "primary"} loading={p.loading}>
                {p.children}
            </Button>
        </Dropdown>
    )
}
