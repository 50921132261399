import { omitObject, pickObject } from "@smartdevis/utils/src/map"
import { ValidationMap, mkMapValidator, optionalMap } from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"
import { validateAttachment } from "./attachment"
import { validateDevis } from "./devis"
import { validateArchitectOfferRequest, validateRound, validateRoundDelta } from "./offers"
import {
    validateAdditionalInformation,
    validateCondition,
    validateDeduction,
    validateGeneralInformation,
    validatePosition,
    validateSection
} from "./position"
import { projectDetailsServerValidation } from "./projectDetails"

const projectCollectionsValidation: ValidationMap<Domain.ProjectCollections> = {
    sections: optionalMap([validateSection]),
    attachments: optionalMap([validateAttachment]),
    conditions: optionalMap([validateCondition]),
    deductions: optionalMap([validateDeduction]),
    devis: optionalMap([validateDevis]),
    additionalInformation: optionalMap([validateAdditionalInformation]),
    generalInformation: optionalMap([validateGeneralInformation]),
    deltas: optionalMap([validateRoundDelta]),
    positions: optionalMap([validatePosition]),
    requests: optionalMap([validateArchitectOfferRequest]),
    rounds: optionalMap([validateRound])
}
export const validateDetails = mkMapValidator(projectDetailsServerValidation)

export const projectValidation: ValidationMap<Domain.Project> = {
    ...projectCollectionsValidation,
    ...projectDetailsServerValidation
}

export const projectServerValidation: ValidationMap<Domain.Project> = {
    ...projectCollectionsValidation,
    ...projectDetailsServerValidation
}

export const validateProjectCollections = mkMapValidator(projectCollectionsValidation)
export const validateDevisCollections = mkMapValidator<Domain.DevisCollections>(
    omitObject(projectCollectionsValidation, ["devis"])
)
export const validateProjectPredefinedCollections = mkMapValidator<Domain.ProjectPredefinedCollections>(
    pickObject(projectCollectionsValidation, [
        "sections",
        "deductions",
        "conditions",
        "attachments",
        "additionalInformation",
        "generalInformation"
    ])
)

export const validateProject = mkMapValidator(projectValidation)
export const validateProjectServer = mkMapValidator(projectServerValidation)
