import * as React from "react"
import styled from "styled-components"
import { F0 } from "@smartdevis/utils/src/types"
import { VerticalSpace, FlexColumn, FlexItem } from "@smartdevis/ui/src/utils/common"
import { Button } from "@smartdevis/ui/src/Button"
import { i18n } from "../services/translations"
import { isProd } from "../utils/envHelpers"
import { H3, P } from "@smartdevis/ui/src/Typography"
import { Warning } from "@smartdevis/ui/src/Warning"

type AppErrorType = "not-found" | "total" | "access-denied"
const StyledWarning = styled(Warning)`
    padding: 0 32px 16px;
`

type ErrorViewProps = { type?: AppErrorType; renderAction?: F0<React.ReactElement>; subText?: string; text?: string }
export const ErrorView: React.FC<ErrorViewProps> = p => {
    const line1 = i18n("We track our errors automatically, but if the problem persists,")
    const line2 = i18n("feel free to contact our customer support.")
    let action = i18n("In the meanwhile, you can try refreshing the page")

    let content = null
    switch (p.type) {
        case "not-found":
            action = i18n("Please check that you've put correct data")
            content = <StyledWarning title={p.text || i18n("Item not found")} />
            break
        case "access-denied":
            action = i18n("You don't have sufficient permissions to view this page")
            content = <StyledWarning title={p.text || i18n("Access denied")} />
            break
        default:
            content = (
                <>
                    <StyledWarning title={i18n("We encountered a problem")} />
                    {p.text && !isProd() ? <H3>{p.text}</H3> : null}
                    {p.text && p.text === "Contractors Directory is unreachable" && isProd() ? (
                        <H3>{i18n("Contractors Directory is unreachable")}</H3>
                    ) : null}
                    <VerticalSpace base="32px" />
                    <P small>{line1}</P>
                    <P small>{line2}</P>
                    <VerticalSpace base="32px" />
                </>
            )
    }
    return (
        <FlexItem direction="column" style={{ margin: "64px 0", minHeight: "70vh" }} xAlign="center" yAlign="center">
            <FlexColumn style={{ maxWidth: "600px" }} alignCenter>
                {content}
                <P textAlign="center">{p.subText || action}</P>
                <VerticalSpace base="16px" />
                {p.renderAction ? (
                    p.renderAction()
                ) : (
                    <Button onClick={() => window.location.reload()} btnType="action">
                        {i18n("Refresh the page")}
                    </Button>
                )}
            </FlexColumn>
        </FlexItem>
    )
}
