import * as React from "react"
import { Domain } from "@smartdevis/server/src/domain"

import { i18n } from "../../services/translations"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { asyncConnect } from "../../resolvers"
import { Button, CheckboxButton } from "@smartdevis/ui/src/Button"
import { projectPaths } from "../../paths"
import { useCloudAction } from "../../hooks/useCloudAction"

import { DevisForm, DevisFormPayload } from "./DevisForm"
import { Select } from "@smartdevis/ui/src/Selects"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Label } from "@smartdevis/ui/src/Typography"
import { useMapState } from "../../hooks/useMapState"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { keys } from "@smartdevis/utils/src/map"
import { Result, mkOk, isErr } from "@smartdevis/utils/src/result"

const getTranslations = (k: keyof Domain.DevisDuplicationMap) =>
    ({
        additionalInformation: i18n("Additional information"),
        conditions: i18n("Conditions"),
        deductions: i18n("Deductions"),
        generalInformation: i18n("General information"),
        positions: i18n("Positions")
    }[k])

export const DuplicateDevisModal = asyncConnect({
    stateResolvers: ["results", "projectsDetails"],
    actions: ["duplicateDevis", "navigate"]
})<BaseModalProps & { baseDevis: Domain.Devis }>(p => {
    const [devisState, setDevisState] = React.useState<Result<DevisFormPayload>>(mkOk(p.baseDevis))
    const [copyDetails, setCopyDetails] = React.useState(true)
    const [newProjectId, setNewProjectId] = React.useState(p.baseDevis.projectId)
    const [duplicationMap, setDuplicationFlag] = useMapState<Domain.DevisDuplicationMap>({
        additionalInformation: true,
        conditions: true,
        deductions: true,
        generalInformation: true,
        positions: true
    })

    const { actionState, onSubmit } = useCloudAction(
        actionId => {
            if (isErr(devisState)) return
            p.duplicateDevis({
                devisId: p.baseDevis.devisId,
                details: { ...p.baseDevis, ...devisState.value },
                projectId: p.baseDevis.projectId,
                newProjectId,
                actionId,
                duplicationMap
            })
        },
        p.results,
        () => {
            if (newProjectId !== p.baseDevis.projectId) p.navigate(projectPaths.overview, { projectId: newProjectId })
            p.onClose?.()
        }
    )

    const onClose = actionState.type === "Processing" ? undefined : p.onClose
    return (
        <Modal
            size="m"
            header={i18n("Duplicate devis")}
            onClose={onClose}
            visible={!!p.visible}
            footer={
                <>
                    {onClose && (
                        <Button data-cy="devis-cancel" btnType="secondary" onClick={p.onClose}>
                            {i18n("cancel")}
                        </Button>
                    )}

                    <Button
                        type="submit"
                        onClick={onSubmit}
                        loading={actionState.type === "Processing"}
                        disabled={isErr(devisState) || actionState.type === "Processing"}>
                        {i18n("Duplicate")}
                    </Button>
                </>
            }>
            <Label>{i18n("Choose project for the duplicated devis")}</Label>
            <VerticalSpace base="8px" />
            <Select
                options={keys(p.projectsDetails).map(k => {
                    const proj = p.projectsDetails[k]
                    return mkDropdownOption(proj.name, k)
                })}
                mode="select"
                value={newProjectId}
                onChange={setNewProjectId}
            />
            <VerticalSpace base="16px" />
            <CheckboxButton label={i18n("Copy details")} checked={copyDetails} onChange={setCopyDetails} />
            {!copyDetails && <DevisForm devis={p.baseDevis} onResultChange={setDevisState} isMutable />}
            <VerticalSpace base="8px" />
            <Join items={keys(duplicationMap)} renderJoining={() => <VerticalSpace base="8px" />}>
                {key => (
                    <CheckboxButton
                        label={i18n("Copy $1", getTranslations(key))}
                        onChange={setDuplicationFlag(key)}
                        key={key}
                        checked={duplicationMap[key]}
                    />
                )}
            </Join>
        </Modal>
    )
})
