import * as React from "react"
import "core-js"
import "dayjs/locale/de-ch"
import dayjs from "dayjs"
import { render } from "react-dom"
import { Provider as StoreProvider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { Switch } from "react-router-dom"
import { getStore, getHistory } from "./store"
import { keys } from "@smartdevis/utils/src/map"
import { Casted } from "@smartdevis/utils/src/types"
import { ViewProvider } from "./views/ViewProvider"
import { allPaths, Paths } from "./paths"
import { Translations } from "./views/admin/Translations"
import { AdminDashboard } from "./views/admin/Dashboard"
import { Activity } from "./views/admin/Activity"
import { DashboardView } from "./views/architect/Dashboard"
import { DemoView } from "./views/demo/DemoView"
import { DevisPositionsView } from "./views/architect/devis-positions/DevisPositions"
import { DevisDeductions } from "./views/architect/DevisDeductions"
import { DevisListView } from "./views/architect/DevisList"
import { EditProjectView } from "./views/architect/ProjectEdit"
import { OfferRequestsView } from "./views/contractor/ContractorOffers"
import { ChangelogDashboard } from "./views/admin/Changelog"

import { selectCreateDiv } from "@smartdevis/ui/src/utils/dom"
import { ProjectView } from "./views/architect/Project"
import { DevisSubmittersView } from "./views/architect/DevisSubmitters"
import { OfferAdoptableView } from "./views/contractor/ContractorOffer"
import { DevisOffers } from "./views/architect/devis-offers/DevisOffers"
import { DevisFinalization } from "./views/architect/devis-finalization/DevisFinalization"
import { DevisNegotiation } from "./views/architect/devis-negotiation/DevisNegotiation"
import { DevisConditions } from "./views/architect/DevisConditions"
import { ListDevisTemplateView } from "./views/architect/Templates"
import { ContactsView } from "./views/architect/Contacts"
import { ProjectsView } from "./views/architect/ProjectsList"
import { Overview } from "./views/admin/Overview"
import { ManagePartners } from "./views/admin/ManagePartners"
import { PartnersView } from "./views/architect/Partners"
import { DirectoryView } from "./views/architect/Directory"
import { DefaultRoute, SecureRoute } from "./views/SecureRoute"

dayjs.locale("de-ch")

const allComponents: Casted<Paths, React.FC<any>> = {
    adminDashboard: AdminDashboard,
    changelog: ChangelogDashboard,
    adminActivity: Activity,
    demo: DemoView,
    copies: Translations,
    home: DashboardView,
    projects: ProjectsView,
    newProject: EditProjectView,
    editProject: EditProjectView,
    newTemplate: EditProjectView,
    overview: ProjectView,
    devis: DevisListView,
    templates: ListDevisTemplateView,
    template: ListDevisTemplateView,
    submitters: DevisSubmittersView,
    conditions: DevisConditions,
    positions: DevisPositionsView,
    deductions: DevisDeductions,
    offers: DevisOffers,
    negotiation: DevisNegotiation,
    finalization: DevisFinalization,
    prefinalization: DevisFinalization,
    contractorOffer: OfferAdoptableView,
    contractorOfferStep: OfferAdoptableView,
    contractorOffers: OfferRequestsView,
    contacts: ContactsView,
    devisOverview: Overview,
    managePartners: ManagePartners,
    partners: PartnersView,
    directory: DirectoryView
}

const Router: React.FC = () => (
    <ConnectedRouter history={getHistory()}>
        <Switch>
            {keys(allPaths).map(k => (
                <SecureRoute key={k} View={allComponents[k]} pathMeta={allPaths[k]} path={allPaths[k].path} exact />
            ))}
            <DefaultRoute />
        </Switch>
    </ConnectedRouter>
)

render(
    <StoreProvider store={getStore()}>
        <ViewProvider>
            <Router />
        </ViewProvider>
    </StoreProvider>,
    selectCreateDiv("app")
)
