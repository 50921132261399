import * as React from "react"
import { F1 } from "@smartdevis/utils/src/types"

export const keycodes = {
    enter: "Enter",
    escape: "Escape",
    tab: "Tab",
    arrowUp: "ArrowUp",
    arrowRight: "ArrowRight",
    arrowLeft: "ArrowLeft",
    arrowDown: "ArrowDown",
    backspace: "Backspace",
    delete: "Delete"
}

type Key = keyof typeof keycodes

export const useKeyPress = <T extends Key>(
    cb: F1<T>,
    keysToCover: T[],
    options: {
        allowShift?: boolean
    } = {}
) => {
    React.useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            if (!options.allowShift && e.shiftKey) return
            const key = keysToCover.find(k => keycodes[k] === e.key)
            if (key) cb(key)
        }
        window.addEventListener("keyup", handleKeyUp, false)
        return () => window.removeEventListener("keyup", handleKeyUp, false)
    }, [cb, keysToCover])
}
