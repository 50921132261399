import * as React from "react"
import dayjs from "dayjs"
import styled from "styled-components"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { FlexColumn, FlexRow } from "@smartdevis/ui/src/utils/common"
import { getFullName } from "@smartdevis/server/src/models/user"
import { Asset, ImageAsset } from "@smartdevis/ui/src/Asset"
import { i18n } from "@smartdevis/client/src/services/translations"
import { displayCompanyName } from "@smartdevis/server/src/models/projectDetails"
import { F2 } from "@smartdevis/utils/src/types"
import { Domain } from "@smartdevis/server/src/domain"
import { dateFormat } from "@smartdevis/server/src/constants"

const fromatDateTime = (ts: number) => dayjs(ts).format(`${dateFormat} HH:mm`)

const notEmpty = <T extends any>(value: T | null | undefined): value is T => value !== null && value !== undefined

type OfferEvent = { icon: ImageAsset; description: React.ReactElement | string; key: string; ts?: number }
type GetEventPayload = F2<Domain.ContractorOffer, Domain.OfferStateChange, OfferEvent>

export const getOpenOfferCopy = (o: Domain.ContractorOffer) => {
    switch (o.state.type) {
        case "cancelled":
        case "rejected":
            return i18n("Show Devis")
        case "contracted":
            return i18n("Show details")
        case "final-proposal":
            return i18n("Check contract proposal")
        case "negotiation":
            return i18n("Show conditions")
        case "next-round":
            return i18n("Submit 2nd round offer")
        case "round-submitted":
            return i18n("Show 2nd round offer")
        case "submitted":
            return i18n("Show offer")
        case "adopted":
        case "requested":
        default:
            return i18n("Open Devis")
    }
}

const getFullArchitectName = (o: Domain.ContractorOffer) =>
    i18n("{a of company b}$1 of $2", getFullName(o.architect), displayCompanyName(o.project.planner))

const mkInitialEvent: GetEventPayload = (o, s) => ({
    icon: "IconPendingGrey",
    key: `initial-${s.createdTs}`,
    description: i18n("$1 would like to ask you for an offer.", getFullArchitectName(o)),
    ts: s.createdTs
})

const mkAcceptedEvent: GetEventPayload = (o, s) => ({
    icon: "IconCheckGrey",
    key: `accepted-${s.createdTs}`,
    description: i18n(`You have accepted the invitation. Click on "Open Devis" to enter your conditions.`),
    ts: s.createdTs
})

const mkSubmittedEvent: GetEventPayload = (o, s) => ({
    icon: "IconArrowGrey",
    key: `submitted-${s.createdTs}`,
    description: i18n("Your offer has been successfully submitted."),
    ts: s.createdTs
})

const mkRoundSubmittedEvent: GetEventPayload = (o, s) => ({
    icon: "IconArrowGrey",
    key: `submitted-${s.createdTs}`,
    description: i18n("Your offer for the second round has been successfully submitted."),
    ts: s.createdTs
})

const mkSecondRoundEvent: GetEventPayload = (o, s) => ({
    icon: "IconPendingGrey",
    key: `second-round-${s.createdTs}`,
    // eslint-disable-next-line prettier/prettier
    description: i18n(`$1 invites you to submit an offer for the second round. Click on "Submit 2nd Round Offer" to enter your conditions.`, getFullArchitectName(o)),
    ts: s.createdTs
})

const mkNegotiationEvent: GetEventPayload = (o, s) => ({
    icon: "IconPendingGrey",
    key: `second-round-${s.createdTs}`,
    // eslint-disable-next-line prettier/prettier
    description: i18n(`$1 has gone to the negotiations phase, where he has the possibility to update the positions and deductions again.`, getFullArchitectName(o)),
    ts: s.createdTs
})

const mkFinishedEvent: GetEventPayload = (o, s) => ({
    icon: "IconCheck",
    key: `finished-${s.createdTs}`,
    // eslint-disable-next-line prettier/prettier
    description: i18n("Congratulations! The submission is closed. $1 will contact you directly.", getFullArchitectName(o)),
    ts: s.createdTs
})

const mkRejectedEvent: GetEventPayload = (o, s) => ({
    icon: "IconRejected",
    key: `rejected-${s.createdTs}`,
    description: i18n("You have declined the invitation to submit an offer."),
    ts: s.createdTs
})

const mkCancelledEvent: GetEventPayload = (o, s) => ({
    icon: "IconRejected",
    key: `cancelled-${s.createdTs}`,
    // eslint-disable-next-line prettier/prettier
    description: i18n("$1 has cancelled this offer request. Thank you very much for your efforts", getFullArchitectName(o)),
    ts: s.createdTs
})

const mkFinalPropsalEvent: GetEventPayload = (o, s) => ({
    icon: "IconSubmitted",
    key: `${s.createdTs}`,
    // eslint-disable-next-line prettier/prettier
    description: i18n(`$1 has sent you the final contract proposal. Click on "Check contract proposal" to accept the contract.`, getFullArchitectName(o)),
    ts: s.createdTs
})

const materializeEvent = (offer: Domain.ContractorOffer, stateChange: Domain.OfferStateChange): OfferEvent[] | null => {
    switch (stateChange.state.type) {
        case "requested":
            return [mkInitialEvent(offer, stateChange)]
        case "adopted":
            return [mkAcceptedEvent(offer, stateChange)]
        case "next-round":
            return [mkSecondRoundEvent(offer, stateChange)]
        case "submitted":
            return [mkSubmittedEvent(offer, stateChange)]
        case "round-submitted":
            return [mkRoundSubmittedEvent(offer, stateChange)]
        case "cancelled":
            return [mkCancelledEvent(offer, stateChange)]
        case "rejected":
            return [mkRejectedEvent(offer, stateChange)]
        case "contracted":
            return [mkFinishedEvent(offer, stateChange)]
        case "final-proposal":
            return [mkFinalPropsalEvent(offer, stateChange)]
        case "negotiation":
            return [mkNegotiationEvent(offer, stateChange)]
        default:
            return null
    }
}

const getProjectHistory = (o: Domain.ContractorOffer): OfferEvent[] => {
    const states: OfferEvent[] = []
    o.statesHistory.forEach(s => {
        const events = materializeEvent(o, s)
        states.push(...(events || []))
    })
    return states.filter(notEmpty).reverse()
}

const WhiteBackgroundMarginWrapper = styled.div`
    margin-bottom: 20px;
    z-index: 1;
    background-color: white;
    &:last-child {
        margin-bottom: 0;
    }
`

const EventWrapper = styled.div<{ active?: boolean }>`
    display: flex;
    background: ${p => (p.active ? "#f8f8f8" : "white")};
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    button {
        margin-left: 20px;
        display: inline;
    }
`
const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1.5px solid #f8f8f8;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
`

const Timestamp = styled.div`
    width: 150px;
    display: flex;
    justify-content: flex-end;
    color: ${themeColor("grey70")};
    margin-top: 6px;
    margin-right: 16px;
`

const Description = styled.div`
    margin-top: 6px;
    flex: 1;
`

const Event: React.FC<{ event: OfferEvent; active?: boolean }> = p => (
    <WhiteBackgroundMarginWrapper>
        <EventWrapper active={p.active}>
            <IconWrapper>
                <Asset name={p.event.icon} size="big-icon" />
            </IconWrapper>
            <Description>{p.event.description}</Description>
            <Timestamp>{p.event.ts && fromatDateTime(p.event.ts)}</Timestamp>
        </EventWrapper>
    </WhiteBackgroundMarginWrapper>
)

const HistoryLine = styled.div`
    width: 2px;
    background-color: #e6e0e0;
    position: relative;
    left: 26px;
`

export const OfferHistory: React.FC<{ offer: Domain.ContractorOffer }> = p => (
    <FlexRow>
        <HistoryLine />
        <FlexColumn>
            {getProjectHistory(p.offer).map((e, i) => (
                <Event event={e} key={e.key} active={i === 0} />
            ))}
        </FlexColumn>
    </FlexRow>
)
