import * as React from "react"
import styled from "styled-components"
import { Center, styleIfProp } from "./utils/common"
import { themeColor, ThemeColors } from "./utils/theme"

export const InlineSpinner = styled.div<{ color?: keyof ThemeColors }>`
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    &:after {
        box-sizing: content-box;
        content: " ";
        display: block;
        position: absolute;
        width: 14px;
        height: 14px;
        top: -3px;
        left: -3px;
        border-radius: 50%;
        border: 3px solid ${p => themeColor(p.color || "black")};
        border-color: ${p => themeColor(p.color || "black")} transparent ${p => themeColor(p.color || "black")}
            transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const SpinnerContainer = styled(Center)<{ fullscreen?: boolean }>`
    height: ${styleIfProp("fullscreen", "100vh", "100%")};
`

export const Spinner: React.FC<{ color?: keyof ThemeColors; fullscreen?: boolean }> = p => (
    <SpinnerContainer {...p}>
        <InlineSpinner {...p} />
    </SpinnerContainer>
)
