import * as React from "react"
import { Domain } from "@smartdevis/server/src/domain"
import { mkFormSchema, mkTextareaSchema, mkTextSchema } from "../../components/forms/formSchemas"
import { sectionValidation } from "@smartdevis/server/src/models/position"
import { i18n } from "@smartdevis/client/src/services/translations"
import { Row } from "../../components/table/Table"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { CellEditMode, mkCell, mkDragHandleCell } from "../../components/table/TableCell"
import { getRowVisualFromDelta } from "@smartdevis/client/src/utils/devisHelpers"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { BaseModalProps, ConfirmationModal } from "@smartdevis/ui/src/Modal"

export type SectionPayload = Pick<Domain.Section, "name" | "number">
export const mkSectionSchema = (withNumber?: boolean) =>
    mkFormSchema<SectionPayload>(
        sectionValidation,
        withNumber
            ? { number: mkTextSchema("{Number}No"), name: mkTextareaSchema("Section name") }
            : { name: mkTextareaSchema("Section name") }
    )

export const mkSectionRow = <T extends string = "section">(
    s: Domain.Section,
    rowDelta: {
        type?: T
        readonly?: boolean
        predefinedReadonly?: boolean
        onSubmit?: F1<SectionPayload>
        onDelete?: F0
        onRevert?: F0
        children?: Row[]
    },
    options?: {
        index?: number
        withNumber?: boolean
        noExplanation?: boolean
        withDragIcon?: boolean
        withPaddingTop?: boolean
        hideDeltaVisuals?: boolean
    },
    delta?: Domain.RoundDelta
): Row<"section" | T, SectionPayload> => {
    const mkFirstCell = <T2 extends any>(content: string, editMode?: CellEditMode<T2>) =>
        options?.withDragIcon && !rowDelta.readonly
            ? mkDragHandleCell(content, [], editMode)
            : mkCell(content, [], editMode)
    const numberCell = mkFirstCell(s.number ?? "", { editMode: "formless", field: "number" })
    const nameCell = mkCell(s.name, [], { editMode: "formless", field: "name" })
    return {
        mode: "editable",
        formSchema: mkSectionSchema(options?.withNumber),
        formValue: s,
        rowId: s.sectionId,
        readonlyClickMessage: options?.noExplanation
            ? undefined
            : getDevisReadonlyRowExplanationText(rowDelta?.predefinedReadonly),
        actionOnEnter: "submit",
        actionOnBlur: "submit",
        visuals: [
            s.parentId ? "subtitle" : "title",
            "noBorder",
            ...(options?.withPaddingTop ? (["paddingTopSmall"] as const) : []),
            ...(options?.hideDeltaVisuals ? [] : getRowVisualFromDelta(delta))
        ],
        grid: options?.index || options?.withNumber ? [2, 22] : [24],
        cells: options?.index
            ? [mkFirstCell(`${options.index}`), nameCell]
            : options?.withNumber
            ? [numberCell, nameCell]
            : [mkFirstCell(s.name, { editMode: "formless", field: "name" })],
        ...rowDelta,
        type: s.parentId ? rowDelta.type || "section" : "section"
    }
}

export const mkNewSection = (
    refId: string,
    type: Domain.SectionType,
    order: number,
    parentId?: string
): Domain.Section => {
    const sectionId = genTemporaryId()
    const createdTs = getTs()
    return { name: "", createdTs, refId, sectionId, type, order, parentId }
}

export const getDevisReadonlyRowExplanationText = (predefinedReadonly: boolean = false) =>
    !predefinedReadonly
        ? i18n("You have pending offers. This view is now read only")
        : i18n("This is a Smart Devis Template, it is read only!")

export const SectionDeleteConfirmationModal = (p: BaseModalProps & { onConfirm: F0 }) => {
    return (
        <ConfirmationModal
            {...p}
            onSubmit={() => {
                p.onConfirm()
                p.onClose?.()
            }}
            header={i18n("Are you sure you want to proceed?")}
            contentText={i18n("Removing this section will also remove all the positions inside.")}
            submitText={i18n("Delete")}
            cancelText={i18n("Cancel")}
        />
    )
}
