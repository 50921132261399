import "firebase/analytics"
import "firebase/auth"
import "firebase/storage"

import firebase from "firebase/app"
import { StorageService, initStorageService } from "./storage"
import { getProcessEnv } from "../utils/envHelpers"

export type FirebaseServices = { storage: StorageService }

let _firebase: FirebaseServices | null = null
let _analytics: firebase.analytics.Analytics | null = null

export const getAnalytics = () => _analytics

export const getStorage = (): StorageService => getFirebase().storage

export const getFirebase = (): FirebaseServices => {
    if (!_firebase) {
        const config = getConfig()
        const app = firebase.initializeApp(config)
        if (config.measurementId) {
            _analytics = app.analytics()
        }
        _firebase = {
            storage: initStorageService()
        }
    }
    return _firebase
}

export const getConfig = () => ({
    apiKey: getProcessEnv().api_key,
    authDomain: getProcessEnv().auth_domain,
    databaseURL: getProcessEnv().database_url,
    projectId: getProcessEnv().project_id,
    storageBucket: getProcessEnv().storage_bucket,
    messagingSenderId: getProcessEnv().messaging_sender_id,
    measurementId: getProcessEnv().measurement_id,
    appId: getProcessEnv().app_id
})

export type FirebaseConfig = ReturnType<typeof getConfig>
