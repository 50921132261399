import * as React from "react"
import styled from "styled-components"

import { useCloudAction } from "../../hooks/useCloudAction"
import { TranslationsMap, i18n, encodeTranslationKey } from "../../services/translations"
import { AdminContent } from "./Dashboard"
import { SearchInput } from "@smartdevis/ui/src/Inputs"
import { Row, getCoreRow } from "../../components/table/Table"
import { mkCell } from "../../components/table/TableCell"
import { Domain } from "@smartdevis/server/src/domain"
import { mkFormSchema, mkTextareaSchema } from "../../components/forms/formSchemas"
import { asyncConnect } from "../../resolvers"
import { translationValidation } from "@smartdevis/server/src/models/common"
import { setTranslations } from "../../services/translationsStore"
import { List, ListRowRenderer, WindowScroller } from "react-virtualized"
import { H2 } from "@smartdevis/ui/src/Typography"
import { useDeepEffect } from "@smartdevis/ui/src/hooks/common"
import { mkFetched } from "@smartdevis/utils/src/async"
import { keys } from "@smartdevis/utils/src/map"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.config.maxWidth};

    &:not(:last-child) {
        margin-bottom: 6rem;
    }
`

const SearchWrapper = styled.div`
    padding: 1rem 0;
`
const mkHeaderRow = (): Row<"header"> => ({
    type: "header",
    mode: "static",
    rowId: "header",
    grid: [8, 8, 8],
    cells: [mkCell("Copy"), mkCell("EN"), mkCell("DE")]
})

const mkCopyRow = (p: {
    name: keyof TranslationsMap
    translations: Domain.TranslationCopy
    changed: boolean
    loading: boolean
    onChange: (name: keyof TranslationsMap, translations: Domain.TranslationCopy) => void
}): Row<"copy", Domain.TranslationCopy> => ({
    type: "copy",
    mode: "editable",
    rowId: p.name,
    grid: [8, 8, 8],
    visuals: p.loading ? ["loading"] : [],
    formSchema: mkFormSchema(translationValidation, {
        de: mkTextareaSchema("DE"),
        en: mkTextareaSchema("EN")
    }),
    formValue: p.translations,
    actionOnBlur: "submit",
    actionOnEnter: "submit",
    onSubmit: vs => p.onChange(p.name, vs),
    cells: [
        mkCell(p.name, ["fixedHeight100"]),
        mkCell(p.translations.en, ["fixedHeight100"], { editMode: "formless", field: "en" }),
        mkCell(p.translations.de, ["fixedHeight100"], { editMode: "formless", field: "de" })
    ]
})

export const Translations = asyncConnect({
    stateResolvers: ["translations", "results"],
    actions: "updateTranslations"
})(p => {
    const [filter, setFilter] = React.useState("")
    const [updateTrigger, trigger] = React.useState(false)
    const [sent, setSent] = React.useState<string | null>(null)

    const { actionState, onSubmit } = useCloudAction<keyof TranslationsMap, Domain.TranslationCopy>(
        (actionId, name, copy) => {
            setSent(name)
            p.updateTranslations({ actionId, values: { [encodeTranslationKey(name)]: copy } })
            setTranslations(mkFetched({ ...p.translations, [encodeTranslationKey(name)]: copy }))
        },
        p.results
    )

    const updateFilter = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(`${e.target.value}`.toLocaleLowerCase())

    useDeepEffect(p.translations, () => trigger(s => !s))
    const displayedKeys = React.useMemo(
        () =>
            (filter
                ? keys(p.translations).filter(
                      k =>
                          k.toLocaleLowerCase().includes(filter) ||
                          [p.translations[k]?.en, p.translations[k]?.de].some(t =>
                              t?.toLocaleLowerCase().includes(filter)
                          )
                  )
                : keys(p.translations)
            ).sort((a, b) => (!p.translations[a]?.de ? -1 : !p.translations[b]?.de ? 1 : 0)),
        [p.data, filter]
    )

    const rowRenderer = React.useCallback<ListRowRenderer>(
        ({ key, index, style }) => (
            <div key={key} style={{ ...style, width: "calc(100% - 32px)" }}>
                {getCoreRow(
                    mkCopyRow({
                        name: displayedKeys[index],
                        translations: p.translations[displayedKeys[index]] ?? { en: "", de: "" },
                        changed: false,
                        loading: actionState.type === "Processing" && sent === displayedKeys[index],
                        onChange: onSubmit
                    })
                )}
            </div>
        ),
        [displayedKeys, updateTrigger]
    )
    return (
        <AdminContent subTitle={i18n("Translations")}>
            <Wrapper>
                <H2>Translations manager</H2>
                <p>
                    Be aware that special text <b>$1</b> in copy means <i>dynamic argument</i> (e.g email) and it should
                    be kept in the translation. Any text{" "}
                    <b>
                        {"{"}in curly brackets{"}"}
                    </b>{" "}
                    is a comment and can be omitted with no consequences
                </p>
                <SearchWrapper>
                    <SearchInput placeholder="Filter" value={filter} onChange={updateFilter} />
                </SearchWrapper>
                {getCoreRow(mkHeaderRow())}
                <WindowScroller>
                    {({ width, height, isScrolling, onChildScroll, scrollTop }) => (
                        <List
                            autoHeight
                            autoWidth
                            width={width}
                            height={height}
                            rowHeight={100}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            scrollTop={scrollTop}
                            rowCount={displayedKeys.length}
                            rowRenderer={rowRenderer}
                        />
                    )}
                </WindowScroller>
            </Wrapper>
        </AdminContent>
    )
})
