// eslint-disable-next-line no-shadow
export enum NumberType {
    Currency = "currency",
    Number = "number",
    Percent = "percent"
}

// 17 -> 17%
export const mkRawPercent = (v: number) => displayNumber(v, { type: NumberType.Percent, isSafe: false })

// 17 * 10k -> 17%
export const mkSafePercent = (v: SafeNumber) => displayNumber(v, { type: NumberType.Percent })

export const SAFE_NUMBER_FACTOR = 10000
export type SafeNumber = number
export const toSafeNumber = (v: number): SafeNumber => Math.round(v * SAFE_NUMBER_FACTOR)
export const fromSafeNumber = (v: SafeNumber) => v / SAFE_NUMBER_FACTOR
export const mulSafeNumbers = (l: SafeNumber, r: SafeNumber): SafeNumber => (l * r) / SAFE_NUMBER_FACTOR || 0

export type DisplayNumberOptions = {
    sign?: "positive" | "negative" | "infer"
    showPositiveSign?: boolean
    isSafe?: boolean
    fractionDigits?: number
    swissFormat?: boolean
    type?: NumberType
}

export const displayNumber = (value: number, options: DisplayNumberOptions = {}): string => {
    const {
        type = "number",
        sign = "infer",
        showPositiveSign = false,
        isSafe = true,
        fractionDigits = 2,
        swissFormat = true
    } = options

    const absoluteValue = isSafe ? fromSafeNumber(Math.abs(value)) : Math.abs(value)
    const roundedValue = absoluteValue.toFixed(fractionDigits)
    const displayedValue = swissFormat ? roundedValue.replace(/\d(?=(\d{3})+\.)/g, "$&'") : roundedValue

    const inferredSign = value >= 0 ? (showPositiveSign ? "+" : "") : "-"
    const displayedSign = sign === "positive" ? "+" : sign === "negative" ? "-" : inferredSign
    switch (type) {
        case "percent":
            return [displayedSign, displayedValue, "%"].join("")
        case "currency":
            return [displayedSign, displayedValue, " CHF"].join("")
        default:
        case "number":
            return [displayedSign, displayedValue].join("")
    }
}

export const mkNumberDisplayer =
    (predefinedOptions: DisplayNumberOptions = {}) =>
    (value: number, options: DisplayNumberOptions = {}) =>
        displayNumber(value, { ...predefinedOptions, ...options })

export const CHF = (c?: SafeNumber, withSign?: boolean) =>
    displayNumber(c || 0, { type: NumberType.Currency, showPositiveSign: withSign })

export const formatSwiss = (v: number | null) => (v !== undefined && v !== null ? displayNumber(v) : "0")

export const isOdd = (number: number) => number % 2 === 1

export const isStringNumber = (str: string) => !isNaN(str as any) && !isNaN(parseFloat(str))
