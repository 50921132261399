import * as React from "react"
import styled from "styled-components"
import { Domain } from "@smartdevis/server/src/domain"
import { Link } from "../../components"
import { ArchitectContent } from "../../components/layouts/Content"
import { i18n } from "@smartdevis/client/src/services/translations"
import { ErrorView } from "../Error"
import { appPaths } from "../../paths"
import { errors } from "@smartdevis/utils/src/validators"
import { asyncConnect } from "../../resolvers"
import { Button } from "@smartdevis/ui/src/Button"
import { Flex, LeftCol, RightCol } from "@smartdevis/ui/src/utils/common"
import { DevisListView } from "./DevisList"
import { OverviewProjectView } from "./ProjectOverview"

export const getProjectContentProps = (p: Domain.ProjectDetails) => ({
    title: `${p.number} ${p.abbreviation}`,
    preTitle: p.isTemplate ? i18n("Template") : undefined
})

export const TagWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
`

const NoSuchProjectView = () => (
    <ErrorView
        type="not-found"
        text="No such project found"
        subText="Go to the projects list in order to view all available projects:"
        renderAction={() => (
            <Link to={appPaths.home}>
                <Button>{i18n("View projects")}</Button>
            </Link>
        )}
    />
)

export const ProjectView = asyncConnect({
    stateResolvers: "projectDetails",
    renderError: err => (err === errors.notFound ? <NoSuchProjectView /> : <ErrorView />)
})(p => {
    return (
        <ArchitectContent {...getProjectContentProps(p.projectDetails)}>
            <Flex>
                <LeftCol>
                    <OverviewProjectView />
                </LeftCol>
                <RightCol>
                    <DevisListView />
                </RightCol>
            </Flex>
        </ArchitectContent>
    )
})
