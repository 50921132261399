import * as React from "react"
import { useState } from "react"
import styled, { css } from "styled-components"
import { RouteParams } from "../../../paths"
import { DevisWrapper } from "../Devis"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect } from "../../../resolvers"
import { SubContent } from "../../../components/layouts/Content"
import { DevisTile } from "../../../components/tiles/DevisTile"
import { ExistingDevisModal } from "../../../components/modals/ExistingDevisModal"
import {
    FlexColumn,
    FlexRow,
    HorizontalSpace,
    Left,
    Margin,
    Right,
    VerticalSpace
} from "@smartdevis/ui/src/utils/common"
import { H2, P, Label, H4 } from "@smartdevis/ui/src/Typography"
import { DevisPositionsModal } from "./DevisPositionsModal"
import { F0 } from "@smartdevis/utils/src/types"
import { Asset, ImageAsset } from "@smartdevis/ui/src/Asset"
import { Domain } from "@smartdevis/server/src/domain"
import { SMap, values } from "@smartdevis/utils/src/map"
import { isDefined, _noop } from "@smartdevis/utils/src/misc"
import { useCloudAction } from "../../../hooks/useCloudAction"
import { UploadingFile } from "@smartdevis/ui/src/Upload"
import { FilesUploadModal } from "../../../components/modals/FilesUploadModal"
import { getStorage } from "@smartdevis/client/src/services/firebase"
import { themeColor, themeMedia } from "@smartdevis/ui/src/utils/theme"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"
import { sortCreatable } from "@smartdevis/utils/src/comparable"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import { AttachmentsModal, useAttachmentsManagement } from "../../../components/Attachments"
import { hasSecondRound } from "@smartdevis/client/src/utils/devisHelpers"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { inArray } from "@smartdevis/utils/src/array"

export const DEVIS_FILE_EXTENSIONS = [".01s", ".crbx"]

export const devisHelpers = (devis: Pick<Domain.Devis, "devisId" | "positionsFormat">) => {
    const getCurrentDelta = (deltas: SMap<Domain.RoundDelta>) => values(deltas).find(d => d.refId === devis.devisId)

    const getSecondRoundFile = (deltas: SMap<Domain.RoundDelta>) => {
        const currentDelta = values(deltas).find(d => d.refId === devis.devisId)
        return currentDelta && currentDelta.type === "edit" && currentDelta.parent === "fileAttachment"
            ? currentDelta.value
            : undefined
    }

    const getPastFirstRoundContractorFile = (offer: Domain.ContractorOffer) =>
        offer.offerProposal && offer.state.type !== "adopted" ? offer.offerProposal : null

    const getPastSecondRoundContractorFile = (offer: Domain.ContractorOffer) => {
        const pastRound = offer.statesHistory.find(s => s.state.type === "next-round")
        return pastRound?.state.type === "next-round" && offer.state.type !== "next-round"
            ? (offer.roundOfferProposals || {})[pastRound.state.roundId]
            : null
    }

    return {
        getSecondRoundFile,
        getCurrentDelta,
        getPastFirstRoundContractorFile,
        getPastSecondRoundContractorFile
    }
}

const isDevisEmpty = (devis: Domain.Devis) => !devis.positionsFormat || devis.positionsFormat.type === "not-selected"

type InformationType = "freetext" | "existing" | "import" | "upload"
const useTypeInformation = () => {
    const [typeInformation, _setTypeInformation] = React.useState<string>("")

    const setTypeInformation = (type: InformationType) => () => {
        switch (type) {
            case "freetext":
                return _setTypeInformation(
                    i18n(
                        "Create a new devis from free text positions. The contractor fills out the offer directly in the application. Thanks to the standardized format, you can compare the offers point by point."
                    )
                )
            case "existing":
                return _setTypeInformation(
                    i18n(
                        "Create a new devis from one of your templates. The devis in the project and the template can be edited independently of each other. The contractor fills out the offer directly in the application. Thanks to the standardized format, you can compare the offers point by point."
                    )
                )
            case "import":
                return _setTypeInformation(
                    i18n(
                        "Make your Devis available to the contractor in CRBx or .01s format. The contractor downloads this, fills it out and uploads the offer again in CRBx or .01s format. The contractor enters the total price and the discounts to enable you to compare the contractors on the platform."
                    )
                )
            case "upload":
                return _setTypeInformation(
                    i18n(
                        "Make your Devis available to the contractor in your own format. The contractor downloads this, creates his offer and uploads the offer. The contractor enters the total price and the discounts in Smart Devis to enable you to compare the contractors on the platform."
                    )
                )
        }
    }

    const clearInformation = () => _setTypeInformation("")

    return {
        typeInformation,
        setTypeInformation,
        clearInformation,
        getInfoPropsForType: (t: InformationType) => ({ onLeave: clearInformation, onEnter: setTypeInformation(t) })
    }
}

export const DevisPositionsView = asyncConnect({
    stateResolvers: [
        "results",
        "projectDetails",
        "devisCollections",
        "devis",
        "isDevisReadonly",
        "isDevisInSecondRound",
        "projectAttachments",
        "uploadingFiles",
        "allDevis",
        "devisTemplates",
        "user"
    ],
    actions: [
        "mutate",
        "submitRoundDelta",
        "revertRoundDelta",
        "uploadFile",
        "removeAttachment",
        "createPositionsFromTemplate",
        "mutateDevisWithUpload",
        "removePositionsFormat"
    ]
})<RouteParams>(p => {
    const [positionsModalVisible, setPositionsModalVisible] = useState(false)
    const [existingDevisModalVisible, setExistingDevisModalVisible] = useState(false)
    const [restrictedUploadDevisModalVisible, setRestrictedUploadDevisModalVisible] = useState(false)
    const [uploadDevisModalVisible, setUploadDevisModalVisible] = useState(false)
    const [attachmentsModalOpen, setAttachmentsModalOpen] = React.useState(false)

    const info = useTypeInformation()

    const { positionsFormat } = p.devis

    const { isDevisReadonly, isDevisInSecondRound } = p
    const isInSecondRound = hasSecondRound(p.devisCollections, p.devis.devisId)
    const isReadonly = p.isDevisReadonly || isInSecondRound

    const isDevisNew = isDevisEmpty(p.devis)

    const { onSubmit, actionState } = useCloudAction<string>(
        (actionId, templateId) =>
            p.createPositionsFromTemplate({
                actionId,
                templateId,
                devisId: p.devis.devisId,
                projectId: p.devis.projectId
            }),
        p.results,
        () => {
            setExistingDevisModalVisible(false)
        }
    )

    const { update: updateDevis } = prepareMutation("devis", p.mutate, {
        type: "devis",
        projectId: p.devis.projectId,
        devisId: p.devis.devisId
    })

    const { onSubmit: onFreeTextDevisClick } = useCloudAction(
        actionId => updateDevis(p.devis, actionId)({ positionsFormat: { type: "positions-based" } }),
        p.results,
        () => {
            setPositionsModalVisible(true)
        }
    )

    const { onSubmit: onFileUpload, actionState: uploadActionState } = useCloudAction<UploadingFile>(
        (actionId, file) =>
            p.mutateDevisWithUpload({
                file,
                devisId: p.devis.devisId,
                projectId: p.devis.projectId,
                userId: p.user.userId,
                actionId
            }),
        p.results,
        () => {
            setUploadDevisModalVisible(false)
        }
    )

    const { onSubmit: onRemovePositionsFormat } = useCloudAction(
        actionId =>
            p.removePositionsFormat({
                devisId: p.devis.devisId,
                projectId: p.devis.projectId,
                actionId
            }),
        p.results
    )

    const onDevisTileClick = () => {
        switch (positionsFormat?.type) {
            case "file-based":
                return window.open(getStorage().getFileUrl(positionsFormat?.fileAttachment.path), "_blank")
            case "positions-based": {
                return setPositionsModalVisible(true)
            }
            default:
                return
        }
    }

    const { requests } = p.devisCollections
    const recentRequest = values(requests)
        .sort(sortCreatable("desc"))
        .find(req => req.devisId == p.devis.devisId)

    const currentDelta = devisHelpers(p.devis).getCurrentDelta(p.devisCollections.deltas)
    const secondRoundFile = devisHelpers(p.devis).getSecondRoundFile(p.devisCollections.deltas)

    const { onSubmit: onRemoveDeltaFileAttachment } = useCloudAction(
        actionId =>
            currentDelta
                ? p.revertRoundDelta({
                      devisId: p.devis.devisId,
                      projectId: p.devis.projectId,
                      deltaId: currentDelta?.deltaId,
                      roundId: currentDelta?.roundId,
                      actionId
                  })
                : undefined,
        p.results
    )
    const onUploadedFileClick = () =>
        secondRoundFile ? window.open(getStorage().getFileUrl(secondRoundFile.path), "_blank") : undefined

    const isDevisRemovable = !(isDevisReadonly || isDevisInSecondRound)
    const isSecondRoundDevisRemovable = !isDevisReadonly && isDevisInSecondRound

    // const positions = values(p.devisCollections.positions).filter(pos => pos.devisId === p.devis.devisId)
    // const sections = values(p.devisCollections.sections).filter(
    //     s => s.refId === p.devis.devisId && s.type === "position"
    // )

    // const hasPositionsOrSections = !![...positions, ...sections].length

    const devisAttachments = useAttachmentsManagement("devis", p)

    return (
        <>
            <DevisWrapper current="positions" {...p}>
                <SubContent>
                    <ActionButtonsWrapper>
                        <Left>
                            <FlexColumn>
                                <H2>{i18n("Positions")}</H2>
                                <VerticalSpace base="16px" />
                                <P color="grey70">{info.typeInformation}</P>
                            </FlexColumn>
                        </Left>
                        <HorizontalSpace base="32px" />
                        <Right>
                            {!isOfferInState(recentRequest, ["contracted"]) && (
                                <>
                                    <ActionButtonsGrid>
                                        <BigButton
                                            name={i18n("New Freetext devis")}
                                            onClick={onFreeTextDevisClick}
                                            icon="IconDevisFreetext"
                                            disabled={
                                                // hasPositionsOrSections ||
                                                // positionsFormat?.type === "file-based" ||
                                                // isDevisReadonly ||
                                                // isDevisInSecondRound
                                                positionsFormat?.type !== "not-selected"
                                            }
                                            {...info.getInfoPropsForType("freetext")}
                                        />
                                        <BigButton
                                            name={i18n("Use existing")}
                                            icon="IconDevisTemplate"
                                            onClick={() => setExistingDevisModalVisible(true)}
                                            disabled={
                                                // positionsFormat?.type === "file-based" ||
                                                // hasPositionsOrSections ||
                                                // isDevisReadonly ||
                                                // isDevisInSecondRound
                                                positionsFormat?.type !== "not-selected"
                                            }
                                            {...info.getInfoPropsForType("existing")}
                                        />
                                        <BigButton
                                            name={i18n("Import devis")}
                                            onClick={() => setRestrictedUploadDevisModalVisible(true)}
                                            icon="IconDevisImport"
                                            description={i18n(".01s and .crbx")}
                                            disabled={
                                                // isDevisReadonly ||
                                                // (hasPositionsOrSections && !isInSecondRound) ||
                                                // (isDevisInSecondRound &&
                                                //     (positionsFormat?.type === "positions-based" || !positionsFormat))
                                                (isDevisInSecondRound && positionsFormat?.type === "positions-based") ||
                                                isDefined(secondRoundFile) ||
                                                (!isDevisInSecondRound && positionsFormat?.type !== "not-selected")
                                            }
                                            {...info.getInfoPropsForType("import")}
                                        />
                                        <BigButton
                                            name={i18n("Upload devis")}
                                            onClick={() => setUploadDevisModalVisible(true)}
                                            icon="IconDevisUpload"
                                            description={i18n("Other formats")}
                                            disabled={
                                                // isDevisReadonly ||
                                                // (hasPositionsOrSections && !isInSecondRound) ||
                                                // (isDevisInSecondRound &&
                                                //     (positionsFormat?.type === "positions-based" || !positionsFormat))
                                                (isDevisInSecondRound && positionsFormat?.type === "positions-based") ||
                                                isDefined(secondRoundFile) ||
                                                (!isDevisInSecondRound && positionsFormat?.type !== "not-selected")
                                            }
                                            {...info.getInfoPropsForType("upload")}
                                        />
                                    </ActionButtonsGrid>
                                </>
                            )}
                        </Right>
                    </ActionButtonsWrapper>
                    <LineSeparator />
                    <FlexColumn alignCenter spaceBetween>
                        {isDevisNew ? (
                            <DevisNotConnected />
                        ) : (
                            <>
                                {!isDevisReadonly && inArray(positionsFormat?.type, ["file-based", "positions-based"]) && (
                                    <FlexRow>
                                        <InfoBox type="warning">
                                            {isDevisInSecondRound
                                                ? positionsFormat?.type === "file-based"
                                                    ? i18n("In this round you can upload an updated devis file")
                                                    : i18n("To change round values, open current devis")
                                                : i18n("To change devis type, detach current devis first")}
                                        </InfoBox>
                                    </FlexRow>
                                )}
                                <VerticalSpace base="18px" />
                                <TileWrapper>
                                    <Margin values="0 0 16px 0">
                                        <H4>{i18n("Original devis")}</H4>
                                    </Margin>
                                    <DevisTile
                                        tileId={p.devis.devisId}
                                        format={positionsFormat ?? { type: "positions-based" }}
                                        loading={actionState.type === "Processing"}
                                        createdTs={p.devis.entryTs}
                                        devisId={p.devis.devisId}
                                        projectId={p.devis.projectId}
                                        onRemovePositionsFormat={isDevisRemovable ? onRemovePositionsFormat : null}
                                        onClick={onDevisTileClick}
                                        onOpenAttachments={
                                            positionsFormat?.type === "file-based"
                                                ? () => setAttachmentsModalOpen(true)
                                                : undefined
                                        }
                                    />
                                </TileWrapper>
                            </>
                        )}
                        {positionsFormat?.type === "file-based" && isDefined(secondRoundFile) && (
                            <>
                                <VerticalSpace base="32px" />
                                <TileWrapper>
                                    <Margin values="0 0 16px 0">
                                        <H4>{i18n("Updated devis")}</H4>
                                    </Margin>
                                    <DevisTile
                                        tileId={secondRoundFile.attachmentId}
                                        format={{ type: "file-based", fileAttachment: secondRoundFile }}
                                        loading={actionState.type === "Processing"}
                                        createdTs={secondRoundFile.createdTs}
                                        onRemovePositionsFormat={
                                            isSecondRoundDevisRemovable ? onRemoveDeltaFileAttachment : null
                                        }
                                        onClick={onUploadedFileClick}
                                        onOpenAttachments={() => setAttachmentsModalOpen(true)}
                                    />
                                </TileWrapper>
                            </>
                        )}
                    </FlexColumn>
                </SubContent>
            </DevisWrapper>
            {positionsModalVisible && (
                <DevisPositionsModal
                    visible={positionsModalVisible}
                    {...p}
                    onClose={() => setPositionsModalVisible(false)}
                />
            )}
            {existingDevisModalVisible && (
                <ExistingDevisModal
                    visible={existingDevisModalVisible}
                    items={values(p.devisTemplates)}
                    loading={actionState.type === "Processing"}
                    onSubmit={onSubmit}
                    disabled={!isDevisNew}
                    onClose={() => setExistingDevisModalVisible(false)}
                />
            )}
            {uploadDevisModalVisible && (
                <FilesUploadModal
                    visible={uploadDevisModalVisible}
                    onFileUpload={onFileUpload}
                    title={i18n("Drop your devis file here")}
                    onClose={() => (uploadActionState.type === "Processing" ? null : setUploadDevisModalVisible(false))}
                    uploadStatus={uploadActionState.type}
                    forbiddenExtensions={DEVIS_FILE_EXTENSIONS}
                />
            )}
            {restrictedUploadDevisModalVisible && (
                <FilesUploadModal
                    visible={restrictedUploadDevisModalVisible}
                    onFileUpload={onFileUpload}
                    title={i18n("Drop your devis file here")}
                    onClose={() => setRestrictedUploadDevisModalVisible(false)}
                    uploadStatus={uploadActionState.type}
                    acceptableExtensions={DEVIS_FILE_EXTENSIONS}
                />
            )}
            <AttachmentsModal
                visible={attachmentsModalOpen}
                isReadonly={isReadonly}
                onClose={() => setAttachmentsModalOpen(false)}
                refId={p.devis.devisId}
                {...devisAttachments}
            />
        </>
    )
})

const LineSeparator = styled.div`
    height: 2px;
    width: 100%;
    background-color: ${p => p.theme.colors.grey50};
    margin: 24px 0;
`

const TileWrapper = styled.div`
    width: 100%;
    max-width: 720px;
`

export const BigButton: React.FC<{
    name: string
    icon: ImageAsset
    disabled?: boolean
    description?: string
    onClick?: F0
    onEnter?: F0
    onLeave?: F0
}> = p => (
    <BigButtonWrapper onClick={p.onClick} disabled={p.disabled} onMouseEnter={p.onEnter} onMouseLeave={p.onLeave}>
        <FlexColumn>
            <FlexRow justifyCenter>
                <Asset name={p.icon} size="big-icon" />
            </FlexRow>
            <FlexColumn>
                <Label style={{ cursor: p.disabled ? "not-allowed" : "pointer" }}>{p.name}</Label>
                {p.description && (
                    <Label style={{ cursor: p.disabled ? "not-allowed" : "pointer", color: "grey" }}>
                        {p.description}
                    </Label>
                )}
            </FlexColumn>
        </FlexColumn>
    </BigButtonWrapper>
)

const customButtonStyles = css<{ disabled?: boolean }>`
    border: 1px solid ${p => p.theme.colors.grey50};
    border-radius: 8px;
    width: 150px;
    background: none;
    cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
    background-color: ${p => (p.disabled ? themeColor("grey40") : "transparent")};
    padding: 16px;
`

const BigButtonWrapper = styled.button`
    ${customButtonStyles}
    min-height: 96px;
    max-height: 128px;
`

const DevisNotConnected = () => (
    <FlexColumn alignCenter>
        <H2>{i18n("No devis added yet")}</H2>
        <VerticalSpace h={8} />
        <P color="grey70">{i18n("All devis created, inserted or uploaded to the project will be listed here")}</P>
    </FlexColumn>
)

const ActionButtonsWrapper = styled(FlexRow)`
    margin-top: 24px;
`

const ActionButtonsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 16px;

    ${themeMedia("max", "lg")} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 2fr);
        row-gap: 16px;
    }
`
