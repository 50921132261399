import * as React from "react"

export const Info: React.FC = p => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...p}>
        <path
            d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0V0ZM18.5 15.5C18.5 15.8978 18.342 16.2794 18.0607 16.5607C17.7794 16.842 17.3978 17 17 17H7.00001C6.60218 17 6.22065 16.842 5.93934 16.5607C5.65804 16.2794 5.50001 15.8978 5.50001 15.5V10.761C5.49974 10.7163 5.51149 10.6724 5.53404 10.6338C5.55659 10.5953 5.58911 10.5635 5.62817 10.5418C5.66724 10.5201 5.71142 10.5093 5.75608 10.5106C5.80074 10.5119 5.84423 10.5251 5.88201 10.549L10.807 13.627C11.165 13.8493 11.5781 13.967 11.9995 13.967C12.4209 13.967 12.834 13.8493 13.192 13.627L18.117 10.549C18.1548 10.5251 18.1984 10.5119 18.243 10.5107C18.2877 10.5094 18.3319 10.5203 18.371 10.542C18.4101 10.5637 18.4426 10.5955 18.4653 10.634C18.488 10.6725 18.4999 10.7163 18.5 10.761V15.5ZM18.465 8.563L12.4 12.355C12.2806 12.4291 12.143 12.4683 12.0025 12.4683C11.862 12.4683 11.7244 12.4291 11.605 12.355L5.53901 8.563C5.52747 8.55693 5.51778 8.54789 5.51092 8.53682C5.50406 8.52574 5.50029 8.51303 5.50001 8.5C5.50001 8.10218 5.65804 7.72064 5.93934 7.43934C6.22065 7.15804 6.60218 7 7.00001 7H17C17.3978 7 17.7794 7.15804 18.0607 7.43934C18.342 7.72064 18.5 8.10218 18.5 8.5C18.5002 8.51221 18.4975 8.52429 18.4921 8.53524C18.4867 8.54619 18.4788 8.55571 18.469 8.563H18.465Z"
            fill="currentColor"
        />
    </svg>
)
