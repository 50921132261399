import { RootState, clientActions } from "../store"
import { PathParams, getParamsFromPathname } from "../paths"
import { RouterState } from "connected-react-router"
import { Resolver } from "."
import {
    Async,
    AsyncFetched,
    WrappedAsync,
    mkFetched,
    isFetchError,
    isFetching,
    isNotFetched,
    mkFetchError
} from "@smartdevis/utils/src/async"
import { SMap, values, keys } from "@smartdevis/utils/src/map"
import { EmptyObject, F2, F3, StateType, F1 } from "@smartdevis/utils/src/types"
import { errors } from "@smartdevis/utils/src/validators"
import { Action } from "@smartdevis/utils/src/actions"

export const mkResolverWithProps =
    <OwnProps extends any = EmptyObject>() =>
    <Res>(
        resolveCb: F2<RootState, OwnProps, Async<Res>>,
        actionsCb?: F3<RootState, OwnProps, Async<Res>, Action[]>
    ): Resolver<OwnProps, Res> =>
    (s: RootState, op: OwnProps) => {
        const resolved = resolveCb(s, op)
        return { result: resolved, actions: actionsCb?.(s, op, resolved) ?? [] }
    }

export const mkResolver = mkResolverWithProps()

export const isAsync = (v: any): v is Async<any> => {
    const types: Array<StateType<Async<any>>> = ["FetchError", "Fetched", "Fetching", "NotFetched"]
    if (!v || typeof v !== "object") return false
    const { type } = v as Async<any>
    const { value } = v as AsyncFetched<any>
    if (types.indexOf(type) === -1) return false
    if (type === "Fetched" && value === undefined) return false
    return true
}

export const wrapAsync = <D extends SMap<any>>(data: D): Async<WrappedAsync<D>> => {
    const result: Partial<WrappedAsync<D>> = {}
    const notReady = values(data).find((v: any) => isAsync(v) && v.type !== "Fetched")
    if (notReady) return notReady

    keys(data).forEach(k => {
        const record = data[k]
        if (!isAsync(record)) result[k] = record
        else result[k] = (record as AsyncFetched<any>).value
    })
    return mkFetched(result as WrappedAsync<D>)
}

interface CombineResolversFn<Op = EmptyObject> {
    <R1, T>(r1: Resolver<Op, R1>, combiner: F1<R1, Resolver<Op, T>>): Resolver<Op, T>
    <R1, R2, T>(r1: Resolver<Op, R1>, r2: Resolver<Op, R2>, combiner: F2<R1, R2, Resolver<Op, T>>): Resolver<Op, T>
    <R1, R2, R3, T>(
        r1: Resolver<Op, R1>,
        r2: Resolver<Op, R2>,
        r3: Resolver<Op, R3>,
        combiner: F3<R1, R2, R3, Resolver<Op, T>>
    ): Resolver<Op, T>
    <R1, R2, R3, R4, T>(
        r1: Resolver<Op, R1>,
        r2: Resolver<Op, R2>,
        r3: Resolver<Op, R3>,
        r4: Resolver<Op, R4>,
        combiner: (...args: [R1, R2, R3, R4]) => Resolver<Op, T> // TODO: F4?
    ): Resolver<Op, T>
    <R1, R2, R3, R4, R5, T>(
        r1: Resolver<Op, R1>,
        r2: Resolver<Op, R2>,
        r3: Resolver<Op, R3>,
        r4: Resolver<Op, R4>,
        r5: Resolver<Op, R5>,
        combiner: (...args: [R1, R2, R3, R4, R5]) => Resolver<Op, T>
    ): Resolver<Op, T>
}

export const combineResolversWithProps =
    <Op = EmptyObject>(): CombineResolversFn<Op> =>
    <T>(...args: any[]): Resolver<Op, T> =>
    (s, op) => {
        const resolvers = [...args]
        const combiner = resolvers.pop()
        const results = (resolvers as Resolver<Op, any>[]).map(resolve => resolve(s, op))
        const vs: Async<any>[] = results.map(v => v.result)

        const resActions = results.reduce<Action[]>((acc, r) => (r.actions ? [...acc, ...r.actions] : acc), [])
        const blocker = vs.find(isFetchError) || (vs.find(v => [isFetching, isNotFetched].find(c => c(v))) as Async<T>)
        if (blocker) return { result: blocker, actions: resActions }

        const cr = (combiner(...vs.map(v => (v as AsyncFetched<any>).value)) as Resolver<Op, T>)(s, op)
        return { result: cr.result, actions: resActions.concat(cr.actions) }
    }

export const combineResolvers: CombineResolversFn = combineResolversWithProps()

export const resolveFromMap = <T>(map: SMap<T | Async<T>>, id?: string, onNotFound?: () => Async<T>): Async<T> => {
    if (!id) return mkFetchError(errors.notFound)
    if (!map[id]) {
        if (!onNotFound) return mkFetchError(errors.notFound)
        return onNotFound()
    }

    const item = map[id]
    return isAsync(item) ? item : mkFetched(item)
}

export const resolvePathParam = <T extends keyof PathParams>(param: T) =>
    mkResolverWithProps<{ [key in T]?: string }>()((s, op) => {
        const value = op[param] || getParamsFromPathname((s.router as RouterState).location.pathname)[param] || ""
        return value ? mkFetched(value as string) : mkFetchError(`Invalid param ${param}`)
    })

export const resolveOptionalPathParam = <T extends keyof PathParams>(param: T) =>
    mkResolverWithProps<{ [key in T]?: string }>()((s, op) => {
        const value = op[param] || getParamsFromPathname((s.router as RouterState).location.pathname)[param]
        return mkFetched(value)
    })

export const resolveAppUser = mkResolver(
    s => s.data.user,
    (_, _2, res) => (isNotFetched(res) ? [clientActions.fetchUserData()] : [])
)

export const resolveAsyncAppUser = mkResolver(s => mkFetched(s.data.user))
