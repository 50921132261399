import * as React from "react"
import { F0 } from "@smartdevis/utils/src/types"
import styled, { css } from "styled-components"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { sticky } from "@smartdevis/ui/src/utils/common"
import { Join } from "./utils/Join"

export type StepData = {
    onClick: F0
    title: string
    disabled?: boolean
    current?: boolean
}

export type ButtonProps = Pick<StepData, "current" | "disabled">

export const StickyStepsContainer = styled.div`
    ${sticky};
    padding: 24px 0 36px 0;
    z-index: 4;
    border-bottom: 1px solid #ececec;
    background: white;
    margin-bottom: -1px;
`

const Arrow = styled.span`
    display: flex;
    justify-content: center;
    &::before {
        flex: 1;
        content: "\u203A";
        position: relative;
        top: -1px;
        font-size: 2em;
        font-weight: 300;
        text-align: center;
        pointer-events: none;
        color: ${themeColor("grey70")};
    }
`

const StepWrapper = styled.li.attrs({
    role: "radiogroup"
})`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Button = styled.span.attrs<ButtonProps>(props => ({
    role: "radio",
    "aria-disabled": props.disabled ? "true" : "false",
    "aria-checked": props.current ? "true" : "false",
    tabIndex: props.current ? "0" : "-1"
}))<ButtonProps>`
    padding: 0.25em 0.5em;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: ${({ current }) => (current ? themeColor("action") : "transparent")};
    color: ${({ current, disabled }) => themeColor(disabled ? "grey70" : current ? "black" : "default")};
    cursor: ${({ disabled, current }) => (disabled ? "not-allowed" : current ? "default" : "pointer")};
    user-select: none;

    ${({ disabled, current }) =>
        !disabled && !current
            ? css`
                  transition: color 0.2s ease-out;

                  &:hover {
                      color: ${themeColor("action")};
                  }
              `
            : ""}
`

export const Step: React.FC<StepData> = p => (
    <StepWrapper>
        <Button {...p}>{p.title}</Button>
    </StepWrapper>
)

export const StepsContiner = styled.ol<{ count: number }>`
    display: grid;
    grid-template-columns: ${p => Array(p.count).fill("1fr").join(" 24px ")};
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
`

export const Steps: React.FC<{ items: StepData[] }> = p => (
    <StepsContiner count={p.items.length}>
        <Join items={p.items} renderJoining={() => <Arrow />}>
            {step => <Step {...step} />}
        </Join>
    </StepsContiner>
)
