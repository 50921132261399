import * as React from "react"
import { getOfferRowMode, OfferStepProps } from "./ContractorSteps"
import { Table } from "../../components/table/Table"
import { asyncConnect } from "../../resolvers"

import {
    mkDeductionsBySection,
    mkDeductionSections,
    mkPositions,
    mkRequestDeltasByRef
} from "@smartdevis/server/src/models/shared"
import { calculateDevis, sumPositionsPrices } from "@smartdevis/server/src/utils/money"
import { mkPreviewRows } from "./ContractorDeductions.rows"
import { H2, H4, Label } from "@smartdevis/ui/src/Typography"
import { FlexColumn, FlexRow, HorizontalSpace, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { i18n } from "@smartdevis/client/src/services/translations"
import { Comments } from "./ContractorComments"
import { ContractorAttachments } from "./ContractorAttachments"
import { Button, IconButton } from "@smartdevis/ui/src/Button"
import { ContractorDeductionsPriceModal } from "./ContractorDeductionsPriceModal"
import { _noop } from "@smartdevis/utils/src/misc"
import { CHF } from "@smartdevis/utils/src/numbers"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import styled from "styled-components"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import { useNotifications } from "../NotificationsProvider"
import { values } from "@smartdevis/utils/src/map"

export const OfferPreview = asyncConnect({
    stateResolvers: ["contractorOffer", "results"],
    actions: ["updateOfferValues"]
})<OfferStepProps>(p => {
    const [priceModalVisible, setPriceModalVisible] = React.useState(false)
    const deltas = mkRequestDeltasByRef(p.contractorOffer)
    const { pushNotification } = useNotifications()

    const deductionsBySection = mkDeductionsBySection(p.contractorOffer, deltas, { keepDeleted: false })
    const deductionsBySectionWithRemoved = mkDeductionsBySection(p.contractorOffer, deltas, {
        keepDeleted: p.mode !== "final"
    })
    const sections = mkDeductionSections(p.contractorOffer, deltas, {
        keepDeleted: false
    })
    const sectionsWithRemoved = mkDeductionSections(p.contractorOffer, deltas, { keepDeleted: p.mode !== "final" })
    const positions = mkPositions(p.contractorOffer, deltas, { keepDeleted: false })

    const positionsSum = sumPositionsPrices(positions, p.values)
    const calculation = calculateDevis(sections, deductionsBySection, p.values, p.information, positionsSum)

    const mkDeductionRow = mkPreviewRows.deductionBuilder(
        p.contractorOffer,
        calculation,
        p.setValue,
        p.readonly,
        p.errorMap?.deductions
    )

    const rows = sectionsWithRemoved
        .map((section, i) => {
            const drows =
                deductionsBySectionWithRemoved[section.sectionId]?.map(deduction =>
                    mkDeductionRow(
                        deduction,
                        p.contractorOffer.deductions[deduction.deductionId]?.value ?? null,
                        deltas?.[section.sectionId]
                    )
                ) ?? []
            if (drows.length === 0) return []

            return [
                ...drows,
                i === sections.length - 1
                    ? mkPreviewRows.net(calculation.net)
                    : mkPreviewRows.subtotal(calculation.subTotalsBySection[section.sectionId], i)
            ]
        })
        .flat()

    const readonly = !isOfferInState(p.contractorOffer, ["adopted", "next-round"])
    const { positionsFormat } = p.contractorOffer.devis

    const onSubmit = (grossPrice: number) => {
        const position =
            positionsFormat?.type === "file-based"
                ? values(positions).find(pos => pos.devisId === p.contractorOffer.devis.devisId)
                : null
        if (!position) return pushNotification(i18n("Something went wrong, try again later"))
        p.setValue(position.positionId, grossPrice)
        setPriceModalVisible(false)
    }

    return (
        <>
            {positionsFormat?.type === "file-based" && !readonly && (
                <Margin values="0 0 32px 0">
                    <FlexRow justifyEnd>
                        <PriceSubmissionContainer highlighted={calculation.gross === 0} spaceBetween alignCenter>
                            <Label
                                color={calculation.gross !== 0 ? "grey70" : "primary"}
                                style={{ maxWidth: "180px", margin: 0 }}>
                                {i18n("File based devis require price to be provided manually")}
                            </Label>
                            <HorizontalSpace base="48px" />
                            {calculation.gross !== 0 ? (
                                <FlexColumn>
                                    <H2>{CHF(calculation.gross)}</H2>
                                    <RefreshIconButton icon="IconRefresh" onClick={() => setPriceModalVisible(true)}>
                                        {i18n("Re-submit")}
                                    </RefreshIconButton>
                                </FlexColumn>
                            ) : (
                                <Button btnType="secondary" onClick={() => setPriceModalVisible(true)}>
                                    {i18n("Submit a gross price")}
                                </Button>
                            )}
                        </PriceSubmissionContainer>
                    </FlexRow>
                </Margin>
            )}
            <Table
                rows={[
                    mkPreviewRows.gross(positionsSum, getOfferRowMode(p.contractorOffer)),
                    ...rows,
                    mkPreviewRows.tax(calculation.tax),
                    mkPreviewRows.nettax(calculation.netincltax)
                ]}
                rowHierarchy={["section", "deduction"]}
            />
            <VerticalSpace h={16} />
            <FlexRow spaceBetween>
                <div>
                    <Margin values="16px 0">
                        <H4>{i18n("Comments")}</H4>
                    </Margin>
                    <Comments {...p} />
                </div>
                <div>
                    <Margin values="16px 0">
                        <H4>{i18n("Attachments")}</H4>
                    </Margin>
                    <ContractorAttachments />
                </div>
            </FlexRow>
            {priceModalVisible && (
                <ContractorDeductionsPriceModal
                    value={calculation.gross}
                    onSubmit={onSubmit}
                    onClose={() => setPriceModalVisible(false)}
                />
            )}
        </>
    )
})

const RefreshIconButton = styled(IconButton)`
    background-color: ${themeColor("white")};
    color: ${themeColor("action")};
    padding: 0;
    justify-content: flex-end;
    &:hover {
        background-color: transparent;
    }
    > * {
        margin-right: 0;
    }
`

const PriceSubmissionContainer = styled(FlexRow)<{ highlighted?: boolean }>`
    padding: 10px;
    border: 1px solid ${p => themeColor(p.highlighted ? "primary" : "grey70")};
    border-radius: 5px;
`
