import {
    mkMapValidator,
    optionalMap,
    validateMap,
    validateNumber,
    ValidationMap
} from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"
import { validateContractor } from "./contractor"
import { validateDevis } from "./devis"
import { validateArchitectOfferRequest, validateRound } from "./offers"
import { validateProjectDetailsServer } from "./projectDetails"

export const devisOverviewValidation: ValidationMap<Domain.DevisOverview> = {
    devis: [validateDevis],
    requests: [validateMap([validateArchitectOfferRequest])],
    rounds: optionalMap([validateRound]),
    projectDetails: [validateProjectDetailsServer],
    contractors: optionalMap([validateContractor]),
    lastActionTs: [validateNumber]
}

export const validateDevisOverview = mkMapValidator(devisOverviewValidation)
