import { Domain } from "@smartdevis/server/src/domain"
import { CalculatedDevis, displayDeductionPriceFromCalculation, displayTax } from "@smartdevis/server/src/utils/money"
import { mkLatestOfferValues } from "@smartdevis/server/src/utils/offer"
import { mkFormattedNumberSchema, mkNullableFormattedToValue } from "../../components/forms/formSchemas"
import { Row } from "../../components/table/Table"
import { mkCell, mkCellEmpty } from "../../components/table/TableCell"
import { mkRequestDeltasByRef } from "@smartdevis/server/src/models/shared"
import { i18n } from "@smartdevis/client/src/services/translations"
import { getRowVisualFromDelta } from "@smartdevis/client/src/utils/devisHelpers"
import { SectionPayload } from "../architect/DevisSection"
import { getOfferRowMode, OfferRowMode } from "./ContractorSteps"
import { TMap } from "@smartdevis/utils/src/map"
import { isOk } from "@smartdevis/utils/src/result"
import { F2 } from "@smartdevis/utils/src/types"
import { CHF, displayNumber } from "@smartdevis/utils/src/numbers"
import { nullableUFloat } from "@smartdevis/utils/src/validators"

const getForMode = <T extends any>(mode: OfferRowMode, options: TMap<OfferRowMode, T>): T => options[mode]

const subtotal = (v: number, index: number): Row<"subtotal", SectionPayload> => ({
    type: "subtotal",
    mode: "static",
    rowId: "subtotal" + index,
    visuals: ["title", "greyText", "bottomMargin"],
    grid: [20, 4],
    cells: [mkCell(i18n("Subtotal")), mkCell(CHF(v), ["alignRight"])]
})

const deductionBuilder = (
    offer: Domain.ContractorOffer,
    calculation: CalculatedDevis,
    onChange: F2<string, number>,
    readonly: boolean,
    errorIds: string[] = []
) => {
    const deltas = mkRequestDeltasByRef(offer)
    const mode = getOfferRowMode(offer)

    return (d: Domain.Deduction, oldValue: number | null, parentDelta?: Domain.RoundDelta): Row<"deduction"> => {
        const delta = deltas?.[d.deductionId]
        const deleted = parentDelta?.type === "remove" || delta?.type === "remove"

        const inputCell = mkCell(
            deleted ? "-" : displayNumber(calculation.offerValues[d.deductionId] ?? d.value ?? 0),
            mode === "final" ? ["alignRight", "yellowBg"] : ["alignRight"],
            d.value !== null || readonly || mode === "final" ? undefined : { editMode: "formless", field: "value" }
        )
        const unitCell = mkCell(d.valueType === "percent" ? "%" : i18n("CHF"))
        const initialValue = offer.initialOffer?.[d.deductionId] ?? oldValue ?? 0
        const roundValue = mkLatestOfferValues({ ...offer, finalOffer: {} })?.[d.deductionId] ?? oldValue ?? 0
        return {
            type: "deduction",
            mode: "form",
            formSchema: {
                value: mkFormattedNumberSchema(i18n("Value"), {
                    validators: nullableUFloat,
                    toValue: mkNullableFormattedToValue
                })
            },
            actionOnEnter: "submit",
            onChange: r => (isOk(r) ? onChange(d.deductionId, r.value.value) : null),
            actionOnBlur: "submit",
            formValue: { value: calculation.offerValues[d.deductionId] },
            rowId: d.deductionId,
            grid: getForMode(mode, {
                basic: [6, 6, 4, 2, 6],
                round: [6, 4, 2, 2, 2, 2, 6],
                final: [6, 2, 2, 2, 2, 2, 2, 2, 4]
            }),
            visuals: [
                "whiteBackground",
                "greyText",
                ...(mode === "final" ? [] : getRowVisualFromDelta(delta)),
                ...(deleted && mode !== "final" ? ["removed" as const, "parent" as const] : []),
                ...(errorIds.includes(d.deductionId) ? (["error"] as const) : [])
            ],
            cells: [
                mkCell(d.name),
                mkCell(i18n("Deduction")),
                ...getForMode(mode, {
                    basic: [inputCell, unitCell],
                    round: [
                        mkCell(displayNumber(initialValue), ["alignRight"]),
                        unitCell,
                        deleted ? mkCellEmpty() : inputCell,
                        unitCell
                    ],
                    final: [
                        mkCell(displayNumber(initialValue), ["alignRight"]),
                        unitCell,
                        deleted ? mkCellEmpty() : mkCell(displayNumber(roundValue), ["alignRight"]),
                        unitCell,
                        inputCell,
                        mkCell(d.valueType === "percent" ? "%" : i18n("CHF"), mode === "final" ? ["yellowBg"] : [])
                    ]
                }),
                deleted
                    ? mkCellEmpty()
                    : mkCell(displayDeductionPriceFromCalculation(d, calculation, true), ["alignRight"])
            ]
        }
    }
}
const gross = (v: number, mode: OfferRowMode): Row<"gross"> => ({
    type: "gross",
    mode: "static",
    rowId: "gross",
    visuals: ["title", "noBorder", "bottomMargin"],
    grid: getForMode(mode, { round: [10, 4, 4, 6], basic: [10, 6, 8], final: [8, 4, 4, 4, 4] }),
    cells: [
        mkCell(i18n("Gross")),
        ...(mode === "basic"
            ? [mkCell(i18n("Value"), ["header", "alignRight"])]
            : [
                  mkCell(i18n("Round 1 Value"), ["header", "centerHorizontally"]),
                  mkCell(i18n("Round 2 Value"), ["header", "centerHorizontally"]),
                  ...(mode === "final" ? [mkCell(i18n("Negotiation Value"), ["header", "centerHorizontally"])] : [])
              ]),
        mkCell(CHF(v), ["alignRight"])
    ]
})

const net = (v: number): Row<"net"> => ({
    type: "net",
    mode: "static",
    rowId: "net",
    visuals: ["title"],
    grid: [10, 4, 10],
    cells: [mkCell(i18n("Net")), mkCellEmpty(), mkCell(CHF(v), ["alignRight"])]
})

const tax = (v: number): Row<"tax"> => ({
    type: "tax",
    mode: "static",
    rowId: "tax",
    visuals: ["whiteBackground", "greyText", "noBorder"],
    grid: [12, 12],
    cells: [mkCell(i18n("Tax")), mkCell(displayTax({}, v), ["alignRight"])]
})

const nettax = (v: number): Row<"nettax"> => ({
    type: "nettax",
    mode: "static",
    rowId: "nettax",
    visuals: ["title"],
    grid: [10, 4, 10],
    cells: [mkCell(i18n("Net incl. tax")), mkCellEmpty(), mkCell(CHF(v), ["alignRight"])]
})

export const mkPreviewRows = {
    subtotal,
    gross,
    deductionBuilder,
    net,
    tax,
    nettax
}
