import { SMap, TMap, OMap, keys } from "@smartdevis/utils/src/map"

const getUrlParamsUtils = (pattern: string, exact = true) => {
    const mapping: string[] = []
    const regexp = new RegExp(
        "^" +
            pattern
                .replace(/[.[\]()^$\\]/g, m => `\\${m}`)
                .replace(/:(\w+)/g, (_, n) => {
                    mapping.push(n)
                    return "([^/]+)"
                }) +
            "/?" +
            (exact ? "$" : "")
    )
    return { mapping, regexp }
}

export const matchPathParams = (pattern: string, uri: string, exact = true): SMap<string> | null => {
    const { mapping, regexp } = getUrlParamsUtils(pattern, exact)
    const match = regexp.exec(uri)
    if (!match) return null
    const res: TMap<string, string> = {}
    for (let i = 0; i < mapping.length; ++i) res[mapping[i]] = match[i + 1]
    return res
}

export const matchesPath = (pattern: string, uri: string, exact = true): boolean =>
    Boolean(matchPathParams(pattern, uri, exact))

export const materialize = (pattern: string, params: OMap<string, string> = {}): string => {
    let url = pattern
    keys(params).forEach(key => {
        url = url.replace(`:${key}`, params[key] || "")
    })
    return url
}

export const materializeWithOptional = (pattern: string, params: OMap<string, string> = {}): string => {
    const { mapping } = getUrlParamsUtils(pattern, true)
    const url = mapping.reduce(
        (acc, mapped) => acc.replace(`/:${mapped}`, params[mapped] ? `/${params[mapped]}` : ""),
        pattern
    )
    return url
}

export const parseHash = (hash: string) => {
    const res: SMap<string> = {}
    hash.replace("#", "")
        .split("&")
        .map(v => v.split("="))
        .forEach(v => {
            if (v.length !== 2) return
            res[v[0]] = v[1]
        })
    return res
}
