import { CmdType, loop, Loop, Cmd, ListCmd } from "redux-loop"

import { ContextReducer } from "../store/contextReducers"
import { RootState } from "../store"
import { arrify } from "@smartdevis/utils/src/array"
import { extend, SMap, pickObject, mapObject } from "@smartdevis/utils/src/map"
import { EmptyObject, F0, F1, FArgs, FMapped } from "@smartdevis/utils/src/types"
import { isArray, isFunction } from "@smartdevis/utils/src/validators"
import { Action, AnyAction, Reducer, PayloadAction } from "@smartdevis/utils/src/actions"

export type LoopReducer<S, A extends Action> = (state: S, action: AnyAction, ...args: any[]) => S | Loop<S, A>

export type Ext<A extends Action<any> | PayloadAction<any, any> = any, TState = RootState> = (
    delta: Partial<TState>,
    cmd?: CmdType<A>
) => Loop<TState, any> | TState

export const loopExtend =
    <A extends Action<A> | PayloadAction<A, any>, TState>(state: TState): Ext<A, TState> =>
    (delta, c) =>
        c ? (loop(extend(state)(delta), c) as Loop<TState, any>) : (extend(state)(delta) as TState)
export const isCmd = (a: any): a is CmdType<any> =>
    (a.type && a.type === "RUN") || a.type === "LIST" || a.type === "ACTION" || a.type === "MAP"

export type MapState<TS, TO = EmptyObject> = MapStateToProps<TS, TO, RootState>

type CmdArg = CmdType<any> | Action | null | undefined
export const cmdList = (conditions: Array<F0<CmdArg> | CmdArg> | F0<CmdArg> | CmdArg): ListCmd<any> =>
    Cmd.list(
        arrify(conditions)
            .map(f => (isFunction(f) ? f() : f))
            .reduce((acc: any, cmd) => {
                if (!cmd) return acc
                if (isCmd(cmd)) return [...acc, cmd]
                return [...acc, Cmd.action(cmd)]
            }, [])
    )

export const cmdListExt = <T>(
    state: T,
    conditions: Array<F1<T, CmdType<any> | Action | null | undefined>>
): ListCmd<any> => cmdList(conditions.map(f => () => f(state)))

export type ReducerDelta<TState, TActions extends Action, TContext> = (
    state: TState,
    action: TActions,
    context: TContext
) => Partial<TState> | CmdType<Action<any>> | [Partial<TState>, CmdType<Action<any>>] | undefined

export const getReducer: <TState, TActions extends Action, TContext = EmptyObject>(
    reducerBase: ReducerDelta<TState, TActions, TContext>
) => ContextReducer<TContext, TState, TActions> = getDelta => (state, action, context) => {
    const delta = getDelta(state, action, context)
    if (!delta) return state
    const ext = loopExtend(state)
    if (isArray(delta)) return ext(delta[0], delta[1])
    if (isCmd(delta)) return ext({}, delta)
    return ext(delta)
}

type Actions = SMap<(...a: any) => any>
// eslint-disable-next-line @typescript-eslint/ban-types
type FResult<T extends Function> = T extends (...args: any) => infer R ? R : never

// eslint-disable-next-line @typescript-eslint/ban-types
export type FMapped2<T extends (...a: any) => any, TReturnValue> = FResult<T> extends Function
    ? (...a: FArgs<T>) => FMapped2<FResult<T>, TReturnValue>
    : (...a: FArgs<T>) => TReturnValue

type ActionsProps<T extends Actions> = { [P in keyof T]: FMapped<T[P], void> }

export const getMapStateWithOwnProps =
    <TOwnProps>() =>
    <T extends keyof RootState, T2 extends keyof RootState[T]>(branch: T, keys: Array<T2> | T2) =>
    (rootState: RootState, op: TOwnProps) => ({
        ...pickObject(rootState[branch], arrify(keys)),
        ...((op || {}) as TOwnProps)
    })

export const getMapState =
    <T extends keyof RootState, T2 extends keyof RootState[T]>(branch: T, keys: Array<T2> | T2) =>
    (rootState: RootState) =>
        pickObject(rootState[branch], arrify(keys))

export const getMapDispatch =
    <T extends Actions, K extends keyof T>(actions: T, keys: K[] | K): MapDispatch<ActionsProps<Pick<T, K>>> =>
    dispatch =>
        mapObject(
            pickObject(actions, arrify(keys)),
            (_, value) =>
                (...args: any) =>
                    dispatch(value(...args))
        )

type MapStateToProps<TStateProps, TOwnProps, State> = (state: State, ownProps: TOwnProps) => TStateProps

// those 3 types below were taken from redux/index.d.ts (v 4.0.1)
// `Middleware type` is changed, so the `action param` type extends `Action type`
export type Middleware<_DispatchExt = EmptyObject, S = any, D extends Dispatch = Dispatch> = {
    (api: MiddlewareAPI<D, S>): (next: Dispatch<AnyAction>) => (action: Action) => any
}
export type Dispatch<A extends Action = AnyAction> = { <T extends A>(action: T): T }
type MiddlewareAPI<D extends Dispatch = Dispatch, S = any> = { dispatch: D; getState(): S }

export type MapDispatchToPropsFunction<TDispatchProps, TOwnProps> = (
    dispatch: Dispatch<Action>,
    ownProps: TOwnProps
) => TDispatchProps

export type MapDispatch<TA, TO = EmptyObject> = MapDispatchToPropsFunction<TA, TO>

export interface Unsubscribe {
    (): void
}

export interface Store<S = any, A extends Action = AnyAction> {
    dispatch: Dispatch<A>
    getState(): S
    subscribe(listener: () => void): Unsubscribe
    replaceReducer(nextReducer: Reducer<S, A>): void
}

export const getCmd = <T>(cmds: SMap<any>, a: PayloadAction, c?: T): CmdType<Action> =>
    cmds[a.type] ? (cmds[a.type] as any)(a.payload, c) : undefined
