import { Domain } from "@smartdevis/server/src/domain"
import { mkReducer } from "./utils"
import {
    reduceMutation,
    reduceSubmitRoundDelta,
    reduceRevertRoundDelta,
    reduceUpdateFinalOffer,
    reduceRemoveDevisPositionsTile,
    reduceRemoveOfferProposal,
    reduceCreateOfferProposal,
    reduceCreateDevisPositions,
    reduceTopLevelMutation,
    reducePartnersMutation
} from "../utils/mutations"
import { Async, mkNotFetched, mkFetched, isFetched } from "@smartdevis/utils/src/async"
import { SMap } from "@smartdevis/utils/src/map"
import { createAction } from "@smartdevis/utils/src/actions"
import * as queries from "./dataQueries"
import { projectDetailsEmptyFixture } from "@smartdevis/server/src/models/fixtures"

export type DataState = {
    user: Async<Domain.User>
    projectsDetails: Async<SMap<Domain.ProjectDetails>>
    devis: Async<SMap<Domain.Devis>>
    devisCollections: SMap<Async<Domain.DevisCollections>>
    devisTemplateCollections: SMap<Async<Domain.DevisTemplateCollections>>
    projectPredefinedCollections: SMap<Async<Domain.ProjectPredefinedCollections>>
    projectAttachments: SMap<Async<SMap<Domain.Attachment>>>
    contractors: Async<SMap<Domain.Contractor>>
    partners: Async<SMap<Domain.Partner>>
    devisTemplates: Async<SMap<Domain.DevisTemplate>>
    userBasedWorkDescriptionTemplates: Async<SMap<Domain.WorkDescriptionTemplate>>
    predefinedWorkDescriptionTemplates: Async<SMap<Domain.WorkDescriptionTemplate>>
    // contractor
    contractorOffers: Async<SMap<Domain.ContractorOffer>>
    contractorPublicOffer: Async<Domain.PublicOffer>
    adminDevisOverview: Async<SMap<Domain.DevisOverview>>
    userDevisOverview: Async<SMap<Domain.DevisOverview>>
    contractorsDirectory: Async<SMap<Domain.DirectoryContractor>>
}

export const initialState: DataState = {
    contractors: mkNotFetched(),
    partners: mkNotFetched(),
    user: mkNotFetched(),
    projectsDetails: mkNotFetched(),
    devisCollections: {},
    devisTemplateCollections: {},
    projectPredefinedCollections: {},
    projectAttachments: {},
    devis: mkNotFetched(),
    devisTemplates: mkNotFetched(),
    userBasedWorkDescriptionTemplates: mkNotFetched(),
    predefinedWorkDescriptionTemplates: mkNotFetched(),
    // contractor
    contractorOffers: mkNotFetched(),
    contractorPublicOffer: mkNotFetched(),
    adminDevisOverview: mkNotFetched(),
    userDevisOverview: mkNotFetched(),
    contractorsDirectory: mkNotFetched()
}

export const actions = {
    _flushState: () => createAction("_flushState"),

    _setApp: <T extends keyof DataState>(field: T, v: DataState[T]) => createAction("_setApp", { [field]: v }),
    _setDevisCollections: (devisId: string, collections: Async<Domain.DevisCollections>) =>
        createAction("_setDevisCollections", { devisId, collections }),
    _setDevisTemplateCollections: (templateId: string, collections: Async<Domain.DevisTemplateCollections>) =>
        createAction("_setDevisTemplateCollections", { templateId, collections }),
    _setProjectPredefinedCollections: (projectId: string, collections: Async<Domain.ProjectPredefinedCollections>) =>
        createAction("_setProjectPredefinedCollections", { projectId, collections }),
    _setProjectAttachments: (projectId: string, attachments: Async<SMap<Domain.Attachment>>) =>
        createAction("_setProjectAttachments", { projectId, attachments }),
    _setPartialDevis: (devis: SMap<Domain.Devis>) => createAction("_setPartialDevis", { devis }),

    fetchPublicOffer: (offerId: string) => createAction("fetchPublicOffer", { offerId }),
    refetchPublicOffer: (offerId: string) => createAction("refetchPublicOffer", { offerId }),

    fetchDevisCollections: (projectId: string, devisId: string) =>
        createAction("fetchDevisCollections", { projectId, devisId }),
    refetchDevisCollections: (projectId: string, devisId: string) =>
        createAction("refetchDevisCollections", { projectId, devisId }),

    fetchDevisTemplateCollections: (templateId: string) =>
        createAction("fetchDevisTemplateCollections", { templateId }),
    refetchDevisTemplateCollections: (templateId: string, uploadedIds?: string[]) =>
        createAction("refetchDevisTemplateCollections", { templateId, uploadedIds }),

    fetchProjectPredefinedCollections: (projectId: string) =>
        createAction("fetchProjectPredefinedCollections", { projectId }),
    refetchProjectPredefinedCollections: (projectId: string) =>
        createAction("refetchProjectPredefinedCollections", { projectId }),

    fetchProjectAttachments: (projectId: string) => createAction("fetchProjectAttachments", { projectId }),
    refetchProjectAttachments: (projectId: string, uploadedIds?: string[]) =>
        createAction("refetchProjectAttachments", { projectId, uploadedIds }),

    fetchAllDevis: () => createAction("fetchAllDevis"),
    refetchAllDevis: () => createAction("refetchAllDevis"),

    fetchPartners: () => createAction("fetchPartners"),
    refetchPartners: () => createAction("refetchPartners"),

    fetchAppChangelog: () => createAction("fetchAppChangelog"),
    refetchAppChangelog: () => createAction("refetchAppChangelog"),

    fetchContractorOffers: () => createAction("fetchContractorOffers"),
    refetchContractorOffers: (uploadedIds?: string[]) => createAction("refetchContractorOffers", { uploadedIds }),

    fetchUserData: () => createAction("fetchUserData"),
    refetchUserData: () => createAction("refetchUserData"),

    fetchProjectsDetails: () => createAction("fetchProjectsDetails"),
    refetchProjectsDetails: () => createAction("refetchProjectsDetails"),

    fetchDevisTemplates: () => createAction("fetchDevisTemplates"),
    refetchDevisTemplates: () => createAction("refetchDevisTemplates"),

    fetchDummyUserBasedWorkDescriptionTemplates: () => createAction("fetchDummyUserBasedWorkDescriptionTemplates"),
    fetchUserBasedWorkDescriptionTemplates: () => createAction("fetchUserBasedWorkDescriptionTemplates"),
    refetchUserBasedWorkDescriptionTemplates: () => createAction("refetchUserBasedWorkDescriptionTemplates"),

    fetchPredefinedWorkDescriptionTemplates: () => createAction("fetchPredefinedWorkDescriptionTemplates"),
    refetchPredefinedWorkDescriptionTemplates: () => createAction("refetchPredefinedWorkDescriptionTemplates"),

    fetchContractors: () => createAction("fetchContractors"),
    refetchContractors: () => createAction("refetchContractors"),

    fetchAdminDevisOverview: () => createAction("fetchAdminDevisOverview"),

    fetchUserDevisOverview: () => createAction("fetchUserDevisOverview"),
    refetchUserDevisOverview: () => createAction("refetchUserDevisOverview"),

    setupSignupRole: () => createAction("setupSignupRole"),

    fetchContractorsDirectory: () => createAction("fetchContractorsDirectory"),
    refetchContractorsDirectory: () => createAction("refetchContractorsDirectory"),
    addContractor: (payload: Domain.DirectoryContractor) => createAction("addContractor", payload),
    editContractor: (payload: Domain.DirectoryContractor & { id: string | undefined }) =>
        createAction("editContractor", payload),
    deleteContractor: (payload: string | undefined) => createAction("deleteContractor", payload)
}

const extendSubstate = <K extends keyof DataState>(
    state: DataState,
    key: K,
    delta: Partial<DataState[K]>
): Partial<DataState> => ({
    [key]: { ...state[key], ...delta }
})

export const reducer = mkReducer<DataState>((state, action, ctx) => {
    switch (action.type) {
        case "_setApp":
            return action.payload

        case "_setDevisCollections":
            return extendSubstate(state, "devisCollections", { [action.payload.devisId]: action.payload.collections })

        case "_setDevisTemplateCollections":
            return extendSubstate(state, "devisTemplateCollections", {
                [action.payload.templateId]: action.payload.collections
            })

        case "_setProjectPredefinedCollections":
            return extendSubstate(state, "projectPredefinedCollections", {
                [action.payload.projectId]: action.payload.collections
            })

        case "_setProjectAttachments":
            return extendSubstate(state, "projectAttachments", {
                [action.payload.projectId]: action.payload.attachments
            })

        case "_flushState":
            return initialState

        case "fetchPublicOffer":
            return queries.fetchPublicOffer(action.payload.offerId)
        case "refetchPublicOffer":
            return queries.fetchPublicOffer(action.payload.offerId, { skipPreAction: true })

        case "createPositionsFromTemplate":
        case "setFileForDevis":
            return reduceCreateDevisPositions(state, action.payload)

        case "removePositionsFormat":
            return reduceRemoveDevisPositionsTile(state, action.payload)

        case "updateOfferValues":
            return reduceCreateOfferProposal(state, action.payload)

        case "removeOfferProposal":
            return reduceRemoveOfferProposal(state, action.payload)

        case "duplicateProject":
            return reduceTopLevelMutation(state, "create", {
                collection: "projects",
                item: projectDetailsEmptyFixture({ projectId: action.payload.newProjectId, name: action.payload.name })
            })

        case "mutate":
            return reduceMutation(state, action.payload)
        case "mutatePartners":
            return reducePartnersMutation(state, action.payload)

        case "submitRoundDelta":
            return reduceSubmitRoundDelta(state, action.payload)
        case "revertRoundDelta":
            return reduceRevertRoundDelta(state, action.payload)
        case "updateFinalOffer":
            return reduceUpdateFinalOffer(state, action.payload)

        case "updateUser": {
            const { user } = state
            return isFetched(user) ? { user: mkFetched({ ...user.value, ...(action.payload as Domain.User) }) } : {}
        }
        case "updateVisit":
            const { user } = state
            return isFetched(user)
                ? { user: mkFetched({ ...user.value, lastVisitVersion: action.payload.lastVisitVersion }) }
                : {}

        case "fetchAppChangelog":
            return queries.fetchAppChangelog(ctx.auth)
        case "refetchAppChangelog":
            return queries.fetchAppChangelog(ctx.auth, { skipPreAction: true })

        case "fetchContractorOffers":
            return queries.fetchContractorOffers(ctx.auth)
        case "refetchContractorOffers":
            return queries.fetchContractorOffers(ctx.auth, {
                skipPreAction: true,
                params: action.payload.uploadedIds ? { uploadedIds: action.payload.uploadedIds } : undefined
            })

        case "fetchUserData":
            return queries.fetchUserData(ctx.auth)
        case "refetchUserData":
            return queries.fetchUserData(ctx.auth, { skipPreAction: true })

        case "fetchProjectsDetails":
            return queries.fetchProjectsDetails(ctx.auth)
        case "refetchProjectsDetails":
            return queries.fetchProjectsDetails(ctx.auth, { skipPreAction: true })

        case "fetchAllDevis":
            return queries.fetchAllDevis(ctx.auth)
        case "refetchAllDevis":
            return queries.fetchAllDevis(ctx.auth, { skipPreAction: true })

        case "fetchDevisCollections":
            return queries.fetchDevisCollections(ctx.auth, action.payload.projectId, action.payload.devisId)
        case "refetchDevisCollections":
            return queries.fetchDevisCollections(ctx.auth, action.payload.projectId, action.payload.devisId, {
                skipPreAction: true
            })

        case "fetchDevisTemplates":
            return queries.fetchDevisTemplates(ctx.auth)
        case "refetchDevisTemplates":
            return queries.fetchDevisTemplates(ctx.auth, { skipPreAction: true })

        case "fetchDummyUserBasedWorkDescriptionTemplates":
            return queries.fetchDummyUserBasedWorkDescriptionTemplates()
        case "fetchUserBasedWorkDescriptionTemplates":
            return queries.fetchUserBasedWorkDescriptionTemplates(ctx.auth)
        case "refetchUserBasedWorkDescriptionTemplates":
            return queries.fetchUserBasedWorkDescriptionTemplates(ctx.auth, { skipPreAction: true })

        case "fetchPredefinedWorkDescriptionTemplates":
            return queries.fetchPredefinedWorkDescriptionTemplates(ctx.auth)
        case "refetchPredefinedWorkDescriptionTemplates":
            return queries.fetchPredefinedWorkDescriptionTemplates(ctx.auth, { skipPreAction: true })

        case "fetchPartners":
            return queries.fetchPartners(ctx.auth)
        case "refetchPartners":
            return queries.fetchPartners(ctx.auth, { skipPreAction: true })

        case "fetchDevisTemplateCollections":
            return queries.fetchDevisTemplateCollections(ctx.auth, action.payload.templateId)
        case "refetchDevisTemplateCollections":
            return queries.fetchDevisTemplateCollections(ctx.auth, action.payload.templateId, {
                skipPreAction: true,
                params: action.payload.uploadedIds ? { uploadedIds: action.payload.uploadedIds } : undefined
            })

        case "fetchProjectPredefinedCollections":
            return queries.fetchProjectPredefinedCollections(ctx.auth, action.payload.projectId)
        case "refetchProjectPredefinedCollections":
            return queries.fetchProjectPredefinedCollections(ctx.auth, action.payload.projectId, {
                skipPreAction: true
            })

        case "fetchProjectAttachments":
            return queries.fetchProjectAttachments(ctx.auth, action.payload.projectId)
        case "refetchProjectAttachments":
            return queries.fetchProjectAttachments(ctx.auth, action.payload.projectId, {
                skipPreAction: true,
                params: action.payload.uploadedIds ? { uploadedIds: action.payload.uploadedIds } : undefined
            })

        case "fetchContractors":
            return queries.fetchContractors(ctx.auth)
        case "refetchContractors":
            return queries.fetchContractors(ctx.auth, { skipPreAction: true })

        case "fetchAdminDevisOverview":
            return queries.fetchAdminDevisOverview(ctx.auth)

        case "fetchUserDevisOverview":
            return queries.fetchUserDevisOverview(ctx.auth)
        case "refetchUserDevisOverview":
            return queries.fetchUserDevisOverview(ctx.auth, { skipPreAction: true })

        case "setupSignupRole":
            return queries.setupSignupRole(ctx.auth)

        case "fetchContractorsDirectory":
            return queries.fetchContractorsDirectory(ctx.auth)
        case "refetchContractorsDirectory":
            return queries.fetchContractorsDirectory(ctx.auth, { skipPreAction: true })
        case "addContractor":
            return queries.addContractor(ctx.auth, action.payload)
        case "editContractor":
            return queries.editContractor(ctx.auth, action.payload)
        case "deleteContractor":
            return queries.deleteContractor(ctx.auth, action.payload)
    }
})
