import { format } from "@smartdevis/utils/src/text"
import { Brand } from "@smartdevis/utils/src/types"

export const positiveOrZero = (num: number) => (num > 0 ? num : 0)

export const dataCy = (label: string, arg1 = "") => format(label, arg1)

export type PropsOf<T extends any> = T extends React.FC<infer P> ? P : never

export type DataUrl = Brand<string, "dataurl">

export const toDataURL = async (url: string) => {
    const res = await fetch(url)
    const blob = await res.blob()
    const dataUrl = await new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.addEventListener("loadend", () => resolve(reader.result))
        reader.addEventListener("error", reject)
        reader.readAsDataURL(blob)
    })

    return dataUrl as DataUrl
}
