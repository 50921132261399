import { F0, F2 } from "@smartdevis/utils/src/types"
import React from "react"

type JoinProps<T> = {
    items: T[]
    renderJoining?: F0<React.ReactElement>
    children: F2<T, number, React.ReactElement | null>
}

export const Join: <T, _>(p: JoinProps<T>) => React.ReactElement<JoinProps<T>> = p => (
    <>
        {p.items.map((item, index) => {
            const toRender = p.children(item, index)
            const joining = toRender && p.renderJoining !== undefined && index !== 0 ? p.renderJoining() : null
            return (
                <React.Fragment key={index}>
                    {joining}
                    {toRender}
                </React.Fragment>
            )
        })}
    </>
)
