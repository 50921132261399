import * as React from "react"
import styled from "styled-components"
import { asyncConnect } from "../../resolvers"
import { mkTile } from "@smartdevis/ui/src/Tiles"
import { ContractorContent } from "../../components/layouts/Content"
import { PageHeader } from "../../components/layouts/Header"
import { materializePath } from "../../paths"
import { i18n } from "@smartdevis/client/src/services/translations"
import { TileList } from "@smartdevis/ui/src/TileList"
import { FlexColumn, FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { Domain } from "@smartdevis/server/src/domain"
import { getFullName } from "@smartdevis/server/src/models/user"
import { formatDateISO } from "@smartdevis/utils/src/date"
import { values } from "@smartdevis/utils/src/map"
import { StateType } from "@smartdevis/utils/src/types"
import { sortCreatable } from "@smartdevis/utils/src/comparable"

const StatusField: React.FC<{ label: string; value: string }> = ({ label, value }) => (
    <StatusWrapper>
        <FlexRow alignCenter>
            <Label>{label}:</Label>
            <HorizontalSpace base="16px" />
            {value}
        </FlexRow>
    </StatusWrapper>
)

const Label = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100px;
`

const StatusWrapper = styled.div`
    margin-left: 80px;
    width: 300px;
    color: ${themeColor("grey70")};
`

const TabButton = styled.div<{ active?: boolean }>`
    border: 0;
    height: 40px;
    background: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 2px solid ${p => (p.active ? themeColor("primary") : "white")};
`

const getStatusTranslation = (state: StateType<Domain.OfferState>) => {
    switch (state) {
        case "adopted":
            return i18n("Offer connected")
        case "cancelled":
            return i18n("Cancelled by architect")
        case "rejected":
            return i18n("Rejected")
        case "next-round":
            return i18n("Second round")
        case "submitted":
        case "round-submitted":
            return i18n("Offer submitted")
        case "negotiation":
            return i18n("In negotiation")
        case "final-proposal":
            return i18n("Approval requested")
        case "contracted":
            return i18n("Contract created")
        default:
            return state
    }
}

export const OfferRequestsView = asyncConnect({
    stateResolvers: ["contractorOffers"]
})(p => {
    const [state, setState] = React.useState<"active" | "archived">("active")
    const isArchived = (o: Domain.ContractorOffer) => ["canceled", "rejected"].includes(o.state.type)
    const activeOffers = values(p.contractorOffers).filter(o => !isArchived(o))
    const archivedOffers = values(p.contractorOffers).filter(o => isArchived(o))

    return (
        <ContractorContent title={i18n("Offer Requests")}>
            <PageHeader
                title={i18n("Offers")}
                actionButtons={[
                    <TabButton key="active" active={state === "active"} onClick={() => setState("active")}>
                        {i18n("Active")} ({activeOffers.length})
                    </TabButton>,
                    <TabButton key="archived" active={state === "archived"} onClick={() => setState("archived")}>
                        {i18n("Archived")} ({archivedOffers.length})
                    </TabButton>
                ]}
            />
            <TileList
                items={(state === "active" ? activeOffers : archivedOffers).sort(sortCreatable("desc")).map(o => {
                    const statusComponent = (
                        <FlexRow justifyEnd style={{ width: "100%", marginRight: "50px" }}>
                            <FlexColumn>
                                <StatusField label={i18n("Architect")} value={`${getFullName(o.architect)}`} />
                                <VerticalSpace h={4} />
                                <StatusField label={i18n("Entry date")} value={formatDateISO(o.devis.entryTs || 0)} />
                            </FlexColumn>
                            <FlexColumn>
                                <StatusField label={i18n("Devis number")} value={o.devis.number} />
                                <VerticalSpace h={4} />
                                <StatusField label={i18n("Status")} value={getStatusTranslation(o.state.type)} />
                            </FlexColumn>
                        </FlexRow>
                    )
                    return mkTile(o.offerId, `${o.devis.workCategory}`, {
                        linkTo: materializePath("contractorOffer", { offerId: o.offerId }),
                        status: { type: "component", value: statusComponent }
                    })
                })}
            />
        </ContractorContent>
    )
})
