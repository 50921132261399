import firebase from "firebase/app"
import { getConfig } from "./firebase"
import { F1 } from "@smartdevis/utils/src/types"
import { Domain, Utils } from "@smartdevis/server/src/domain"
import { genTemporaryId } from "@smartdevis/utils/src/id"

type RefPath = firebase.storage.Reference & { fullPath: Utils.FilePath }
type CollectionKey = keyof (Domain.ProjectCollections & Domain.BaseCollections & Domain.ContractorCollections)

type UploadFileArgs = {
    file: File
    ref: RefPath
    onProgress: (progress: number) => void
}

export const calcProgress = (onProgress: F1<number>) => (s: { bytesTransferred: number; totalBytes: number }) =>
    onProgress(Math.floor((s.bytesTransferred / s.totalBytes) * 100))

export const initStorageService = () => {
    const instance = firebase.storage()

    const ref = (userId: string, collection: CollectionKey, collectionId: string, name: string): RefPath =>
        instance.ref().child(userId).child(collection).child(collectionId).child(name) as RefPath

    const publicRef = (name: string): RefPath =>
        instance.ref().child("public").child(genTemporaryId()).child(name) as RefPath

    const uploadFile = (p: UploadFileArgs) =>
        new Promise<{ progress: number; url: string }>(async (response, reject) => {
            const uploadTask = p.ref.put(p.file)
            uploadTask.on(
                "state_changed",
                snapshot => calcProgress(p.onProgress)(snapshot),
                reject,
                () => uploadTask.snapshot.ref.getDownloadURL().then(url => response({ progress: 100, url }))
            )
        })

    const deleteFile = (path: string) =>
        new Promise(async (response, reject) =>
            instance
                .ref(path)
                .delete()
                .then(function () {
                    response(undefined)
                })
                .catch(reject)
        )

    const getFileUrl = (path: string) =>
        "https://firebasestorage.googleapis.com/v0/b/" +
        getConfig().storageBucket +
        "/o/" +
        encodeURIComponent(path) +
        "?alt=media"

    return {
        ref,
        publicRef,
        uploadFile,
        deleteFile,
        getFileUrl
    }
}

export type StorageService = ReturnType<typeof initStorageService>
