import { omitObject, pickObject } from "@smartdevis/utils/src/map"
import { _deprecatedDatabase, Domain } from "./domain"

type FieldMap<T> = { [K in keyof T]: keyof Domain.CollectionItem<T[K]> }

export const publicSandbox: keyof _deprecatedDatabase.PublicSandbox = "public"

export const collectionKeys: FieldMap<Domain.BaseCollections> = {
    contractors: "contractorId",
    devisTemplates: "templateId",
    projects: "projectId"
}

export const projectCollectionsKeys: FieldMap<Domain.ProjectCollections> = {
    devis: "devisId",
    sections: "sectionId",
    positions: "positionId",
    conditions: "conditionId",
    generalInformation: "informationId",
    additionalInformation: "informationId",
    attachments: "attachmentId",
    deductions: "deductionId",
    requests: "offerId",
    rounds: "roundId",
    deltas: "deltaId"
}

export const devisCollectionsKeys: FieldMap<Domain.DevisCollections> = omitObject(projectCollectionsKeys, [
    "devis",
    "attachments"
])
export const projectPredefinedCollectionsKeys: FieldMap<Domain.ProjectPredefinedCollections> = pickObject(
    projectCollectionsKeys,
    ["conditions", "deductions", "sections", "additionalInformation", "generalInformation"]
)

export const allCollectionKeys: FieldMap<Domain.AllCollections> = {
    ...collectionKeys,
    ...projectCollectionsKeys
}

export const devisStatus = ["created", "offer requested", "offer selected"] as const

export const units = {
    m: "m",
    m2: "m²",
    m3: "m³",
    st: "St",
    pl: "pl",
    gl: "gl",
    h: "h",
    d: "d",
    wo: "Wo",
    mt: "Mt",
    kg: "kg",
    t: "t",
    l: "l",
    hl: "hl",
    le: "LE",
    kj: "kJ"
}

export const sharedPaths = {
    offerRequest: "offer-request",
    assigned: "assigned",
    offerRequests: "offer-requests",
    offerRequestKey: "orkey",
    login: "login",
    register: "register",
    projects: "projects",
    devis: "devis",
    requests: "requests",
    review: "review",
    new: "new",
    edit: "edit",
    verify: "verify",
    offer: "offer",
    offers: "offers",
    conditions: "conditions",
    verifyEmail: "verify-email",
    changePassword: "change-password",
    reset: "reset"
}

export const dateFormat = "DD.MM.YYYY"
export const dateTimeFormat = "DD.MM.YYYY HH:mm"
