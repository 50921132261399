import * as React from "react"
import styled, { css } from "styled-components"
import { rem } from "./utils"
import { H4 } from "./Typography"
import { themeColor } from "./utils/theme"
import { cutString } from "@smartdevis/utils/src/text"
import { Asset, AssetContainer } from "./Asset"
import { Dropdown } from "./Dropdown"
import { FlexColumn, VerticalSpace } from "./utils/common"
import { InlineSpinner } from "./Spinner"
import { useAnimationToggle } from "./hooks/common"
import { PureLink } from "./Link"
import {
    CreateTileProps,
    CrossContainer,
    TileActionsContainer,
    TileContainerStyles,
    TileDescription,
    TileItem,
    TilesContainer,
    TileSubText,
    TileTitle
} from "./Tiles"
import { omitObject } from "@smartdevis/utils/src/map"

const TileLinkWrapper: React.FC<Pick<TileItem, "linkTo">> = p =>
    p.linkTo ? (
        <TileLinkContainer to={p.linkTo} clickable>
            {p.children}
        </TileLinkContainer>
    ) : (
        <TileContainer>{p.children}</TileContainer>
    )

export const Tile: React.FC<TileItem> = p => (
    <TileLinkWrapper linkTo={p.linkTo}>
        <TileTitle>{cutString(p.title, 40)}</TileTitle>
        <TileActionsWrapper>
            {p.description && <TileListDescription>{cutString(p.description, 88)}</TileListDescription>}
            {p.status && p.status.type === "text" ? (
                <TileListSubText>{cutString(p.status.value, 30)}</TileListSubText>
            ) : (
                p.status?.value || null
            )}
        </TileActionsWrapper>
        {p.options ? (
            <TileActionsContainer>
                <Dropdown options={p.options || []}>
                    <Asset name="DotsFilled" size="icon" />
                </Dropdown>
            </TileActionsContainer>
        ) : null}
    </TileLinkWrapper>
)

export const CreateTileList: React.FC<CreateTileProps & { fullHeight?: boolean }> = p => {
    const { animated: open, animate: setOpen } = useAnimationToggle(0)
    const core = (
        <FlexColumn alignCenter>
            {p.loading ? (
                <AssetContainer size="big-icon">
                    <InlineSpinner color="primary" />
                </AssetContainer>
            ) : (
                <Asset name="CrossFilledRed" size="mid-icon" />
            )}
            <VerticalSpace base="8px" />
            <H4>{p.title}</H4>
        </FlexColumn>
    )
    return (
        <TileContainer
            fullHeight={p.fullHeight}
            onClick={p.withDropdown ? setOpen : p.onClick}
            clickable
            filled
            separated
            rounded>
            <CrossContainer>
                {p.withDropdown ? (
                    <Dropdown open={open} options={p.options}>
                        {core}
                    </Dropdown>
                ) : (
                    core
                )}
            </CrossContainer>
        </TileContainer>
    )
}

export const TileList: React.FC<{
    items: TileItem[]
    createProps?: CreateTileProps
}> = p => (
    <TilesContainer listMode>
        {p.createProps ? <CreateTileList {...p.createProps} fullHeight={!p.items.length} /> : null}
        <BorderWrapper>
            {p.items.map(item => (
                <Tile key={item.id} {...item} />
            ))}
        </BorderWrapper>
    </TilesContainer>
)

const BorderWrapper = styled.div`
    & > *:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    & > *:first-child {
        border-top: 1px solid ${themeColor("grey50")};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
`

const TileListContainerStyles = css<{ filled?: boolean; clickable?: boolean; rounded?: boolean; fullHeight?: boolean }>`
    ${TileContainerStyles}
    height: ${p => (p.fullHeight ? "calc(100vh - 135px)" : rem(120))};
    width: 100%;
    min-width: 500px;
    border-top: none;
    border-radius: initial;
    border-radius: ${p => (p.rounded ? "12px" : "none")};
`

const TileLinkContainer = styled((p: React.ComponentProps<typeof PureLink> & { clickable?: boolean }) => (
    <PureLink {...omitObject(p, ["clickable"])} />
))<{ filled?: boolean; clickable?: boolean }>`
    ${TileListContainerStyles}
`

type TileContainerProps = {
    filled?: boolean
    clickable?: boolean
    separated?: boolean
    rounded?: boolean
    fullHeight?: boolean
}
const TileContainer = styled(p => (
    <div {...omitObject(p, ["clickable", "rounded", "separated", "filled", "fullHeight"])} />
))<TileContainerProps>`
    ${TileListContainerStyles}
    margin-bottom: ${p => (p.separated ? "12px" : "none")};
`

const TileListDescription = styled(TileDescription)`
    position: relative;
    left: 0px;
    margin-right: 80px;
`

const TileListSubText = styled(TileSubText)`
    position: relative;
    left: 0px;
    bottom: 0px;
`

const TileActionsWrapper = styled.div`
    display: flex;
    position: absolute;
    left: 24px;
    bottom: 20px;
    width: calc(100% - 40px);
    color: ${themeColor("grey70")};

    /* & > {
        margin-right: 80px;
    } */
`
