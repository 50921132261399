import { createGlobalStyle } from "styled-components"
import { themeColor, themeConfig, themeMedia } from "./theme"

export const GlobalStyle = createGlobalStyle`
*::selection {
  color: rgb(38,38,38);
  background-color: rgb(192,211,250)
}
li.drag-over > span[draggable], li.drag-over > * {
  color: ${themeColor("black")};
  background: rgba(255,0,0, 0.1) !important;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "${themeConfig("fontFamily")}", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-variant: tabular-nums;
  color: ${themeColor("primaryGrey")};
  overflow: scroll;

  ${themeMedia("max", "xl")} {
    font-size: 11px;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}

*::-webkit-scrollbar-track {
  box-shadow: none !important;
  box-sizing: border-box !important;
  background-color: transparent !important;
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 0 !important;
  box-sizing: border-box !important;
  background-color: transparent !important;
}
*::-webkit-scrollbar-thumb {
  background-color: ${themeColor("grey50")} !important;
  border-radius: 8px !important;
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  font-size: 10px;
}

h1,h2,h3,h4,h5,h6, blockquote {
  text-align: left;
  * > span {
    text-align: left;
  }
}

label {
  font-size: .8rem;
}

a {
  text-decoration: none;
  color: ${themeColor("primary")}
}

input:focus {
  border-color: ${themeColor("primary")} !important;
}

input {
  box-shadow: none !important;
  color: ${themeColor("primaryGrey")}
}

button,
input {
  transition: all .3s ease-in-out !important;
}

#portal-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/* flat picker */
.styled-date-picker {
  width: 100%;
  border: 1px solid #ededed;
  height: 32px;
  border-radius: 4px;
  padding: 4px 11px 4px;
}
.flatpickr-current-month span.cur-month {
  font-weight: 500;
  margin-right: 4px;
  user-select: text;
  &:hover {
      background: transparent;
  }
}
.flatpickr-current-month input.cur-year {
  padding: 0;
}
.flatpickr-current-month span.arrowUp,
.flatpickr-current-month span.arrowDown {
  display: none;
}
.flatpickr-current-month .numInputWrapper {
  width: calc(4ch + 2px);
}

`
