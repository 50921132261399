import * as React from "react"
import styled from "styled-components"
import { F1 } from "@smartdevis/utils/src/types"
import { Domain } from "@smartdevis/server/src/domain"
import { TextareaInput } from "@smartdevis/ui/src/Inputs"
import { Label, Pre } from "@smartdevis/ui/src/Typography"
import { FlexRow, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { i18n } from "@smartdevis/client/src/services/translations"
import { getStaticComments, getStaticPositionComments } from "@smartdevis/client/src/utils/offer"
import { asyncConnect } from "../../resolvers"
import { useCloudAction } from "../../hooks/useCloudAction"
import { Button } from "@smartdevis/ui/src/Button"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"

export const Comments: React.FunctionComponent<{
    contractorOffer: Domain.ContractorOffer
    comment: string
    setComment: F1<string>
}> = p => {
    const comments = getStaticComments(p.contractorOffer)
    const isCommentInputVisible =
        p.contractorOffer.state.type === "adopted" || p.contractorOffer.state.type === "next-round"

    return (
        <>
            {comments.map(c => (
                <React.Fragment key={c.label}>
                    <CommentWrapper>
                        <Label>{c.label}</Label>
                        <Pre>{c.value}</Pre>
                    </CommentWrapper>
                    <VerticalSpace h={32} />
                </React.Fragment>
            ))}
            {isCommentInputVisible && (
                <CommentWrapper>
                    <TextareaInput
                        minRows={3}
                        allowEnter
                        value={p.comment}
                        onChange={e => p.setComment(e.target.value)}
                        placeholder={i18n("e.g missing information")}
                    />
                </CommentWrapper>
            )}
        </>
    )
}

const CommentWrapper = styled.div`
    width: 500px;
`

export const PositionComments = asyncConnect({ stateResolvers: ["results"], actions: ["updateOfferValues"] })<
    {
        contractorOffer: Domain.ContractorOffer
        positionId: string | null
    } & BaseModalProps
>(p => {
    if (!p.positionId) return <></>
    const { offerId } = p.contractorOffer
    const positionComments = (p.contractorOffer.positionComments || {})[p.positionId]
    const [comment, setComment] = React.useState(
        (positionComments || {})[
            p.contractorOffer.state.type === "next-round" ? p.contractorOffer.state.roundId : "initial"
        ] || ""
    )

    const { onSubmit, actionState } = useCloudAction(
        actionId => {
            p.updateOfferValues({
                actionId,
                offerId,
                positionComments: { comment, positionId: p.positionId || "" }
            })
        },
        p.results,
        () => p.onClose && p.onClose()
    )

    const comments = getStaticPositionComments(p.contractorOffer, p.positionId)
    const isEditable = p.contractorOffer.state.type === "adopted" || p.contractorOffer.state.type === "next-round"

    return (
        <Modal header={i18n("Comments")} onClose={p.onClose} size={p.size} visible={p.visible}>
            {comments.map(c => (
                <React.Fragment key={c.label}>
                    <CommentWrapper>
                        <Label>{c.label}</Label>
                        <Pre>{c.value}</Pre>
                    </CommentWrapper>
                    <VerticalSpace h={32} />
                </React.Fragment>
            ))}
            {isEditable && (
                <CommentWrapper>
                    <TextareaInput
                        minRows={3}
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        placeholder={i18n("e.g missing information")}
                    />
                </CommentWrapper>
            )}
            <VerticalSpace h={32} />
            <FlexRow justifyEnd>
                <Button onClick={onSubmit} disabled={!isEditable} loading={actionState.type === "Processing"}>
                    {i18n("Save comments and close")}
                </Button>
            </FlexRow>
        </Modal>
    )
})
