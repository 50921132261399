import * as React from "react"
import styled from "styled-components"
import { identity } from "@smartdevis/utils/src/misc"
import { cutString } from "@smartdevis/utils/src/text"
import { F0 } from "@smartdevis/utils/src/types"
import { Asset, ImageAsset } from "./Asset"
import { TileItem, TileLinkWrapper } from "./Tiles"
import { FlexColumn, FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { Button } from "./Button"
import { Dropdown } from "./Dropdown"
import { H4, Label } from "./Typography"
import { themeColor } from "@smartdevis/ui/src/utils/theme"

export type LongTileProps = Omit<TileItem, "description" | "status"> & {
    loading: boolean
    icon: React.ReactNode | ImageAsset
    descriptionItems?: { key: string; value: string }[]
    buttonTitle: string
    titleLabel: string
    onClick?: F0
}

export const LongTile: React.FC<LongTileProps> = p => {
    const items = p.descriptionItems
        ? p.descriptionItems.map(item => (
              <FlexColumn key={item.key}>
                  <CustomLabel>{item.key}</CustomLabel>
                  <CustomLabel>{item.value}</CustomLabel>
              </FlexColumn>
          ))
        : []

    return (
        <TileLinkWrapper linkTo={p.linkTo} style={{ width: "auto", minWidth: "500px" }}>
            <FlexRow justifyCenter style={{ height: "100%", width: "100%" }}>
                <FlexColumn style={{ justifyContent: "center", width: "100%" }}>
                    <FlexRow spaceBetween alignCenter>
                        <FlexRow alignCenter>
                            {typeof p.icon === "string" ? (
                                <Asset name={p.icon as ImageAsset} size="mid-icon" color="blueGrey" />
                            ) : (
                                p.icon
                            )}
                            <HorizontalSpace base="24px" />
                            <FlexColumn>
                                <CustomLabel>{p.titleLabel}</CustomLabel>
                                <LimitedH4 title={p.title}>{cutString(p.title, 40)}</LimitedH4>
                            </FlexColumn>
                        </FlexRow>
                        {p.onClick && (
                            <Button onClick={p.onClick} disabled={p.loading} btnType="secondary">
                                {p.buttonTitle}
                            </Button>
                        )}
                    </FlexRow>
                    <VerticalSpace base="16px" />
                    <FlexRow spaceBetween>
                        <FlexRow>
                            <FlexRow>
                                <HorizontalSpace base="54px" />
                                {p.descriptionItems && p.descriptionItems?.length > 0 && (
                                    <FlexRow style={{ margin: "0 32px 0 0" }}>
                                        <Join items={items} renderJoining={() => <HorizontalSpace base="32px" />}>
                                            {identity}
                                        </Join>
                                    </FlexRow>
                                )}
                            </FlexRow>
                        </FlexRow>
                        {p.options ? (
                            <FlexRow style={{ alignItems: "flex-end" }}>
                                <Dropdown options={p.options || []}>
                                    <Asset name="DotsFilled" size="icon" />
                                </Dropdown>
                            </FlexRow>
                        ) : null}
                    </FlexRow>
                </FlexColumn>
            </FlexRow>
        </TileLinkWrapper>
    )
}

const LimitedH4 = styled(H4)`
    max-width: 296px;
    text-overflow: ellipsis;
    overflow: hidden;
`

const CustomLabel = styled(Label)`
    color: ${themeColor("grey70")};
`
