import * as React from "react"
import { useDebounce } from "@smartdevis/ui/src/hooks/useDebounce"
import { SMap, omitObject } from "@smartdevis/utils/src/map"
import { F1, F0 } from "@smartdevis/utils/src/types"

export const useNullableState = <T extends any>(v: T | null = null) => React.useState<T | null>(v)

export const useStateExt = <T extends any>(initial: T) => {
    const [value, setValueRaw] = React.useState<T>(initial)
    const setValue = (v: T): T => {
        setValueRaw(v)
        return v
    }
    return [value, setValue] as [T, F1<T, T>]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const useRefFunction = <F extends Function>(renderFunction: F, dependencies: any[] = []) => {
    const renderer = React.useRef(renderFunction)
    const isFirstRender = React.useRef(true)
    const [, triggerRerender] = React.useState(false)
    React.useEffect(() => {
        if (isFirstRender.current) return
        renderer.current = renderFunction
        triggerRerender(v => !v)
    }, dependencies)
    React.useEffect(() => {
        isFirstRender.current = false
    })
    return renderer
}

export const useItemsMap = <T extends any>(initial: SMap<T> = {}, idKey?: keyof T) => {
    const [items, _setItems] = React.useState<SMap<T>>(initial)
    const removeItem = (id: string) => _setItems(is => omitObject(is, [id]))
    const setItem = (item: T, id: string) => _setItems(is => ({ ...is, [id]: item }))
    const setItemByKey = (item: T) => _setItems(is => ({ ...is, [(item as any)[idKey]]: item }))
    const setDelta = (delta: SMap<T>) => _setItems(is => ({ ...is, ...delta }))
    const clear = () => _setItems({})

    return { items, removeItem, setItem, setItemByKey, setDelta, clear }
}

export const useOutsideClick = (className: string, ref: Element | null, onClick: F0) => {
    React.useEffect(() => {
        const body = document.querySelector("body")
        if (!body) return
        const handler = ({ target }: Event) => {
            if ((target as Element).closest(`.${className}`) !== ref) {
                onClick()
            }
        }
        body.addEventListener("click", handler)
        return () => body.removeEventListener("click", handler)
    }, [className, ref, onClick])
}

export const useClientWidth = () => {
    const [res, setRes] = React.useState(window.innerWidth)

    const handleResize = useDebounce((): void => setRes(window.innerWidth), 100)

    React.useEffect(() => {
        window.addEventListener("resize", () => handleResize.run())
        return () => window.removeEventListener("resize", () => handleResize.cancel())
    }, [handleResize])

    return res
}
