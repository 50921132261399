import * as React from "react"
import { SMap, values } from "@smartdevis/utils/src/map"
import { _anything } from "@smartdevis/utils/src/misc"

export const UnsavedStatusContext = React.createContext({
    children: {} as SMap<boolean>,
    clearChildren: () => _anything,
    setChild: (_value: boolean, _id: string) => _anything
})

export const useUnsavedStatusAsParent = (resetDependencies: any[] = []) => {
    const ctx = React.useContext(UnsavedStatusContext)
    React.useEffect(() => {
        ctx.clearChildren()
    }, resetDependencies)
    return { check: () => values(ctx.children).some(v => !!v) }
}

export const useUnsavedStatusAsChild = (id: string) => {
    const ctx = React.useContext(UnsavedStatusContext)
    return { setUnsaved: (value: boolean) => ctx.setChild(value, id) }
}

export const UnsavedStatusProvider: React.FC = p => {
    const items = React.useRef<SMap<boolean>>({})

    const [, forceRerender] = React.useState(false)

    return (
        <UnsavedStatusContext.Provider
            value={{
                children: items.current,
                clearChildren: () => {
                    for (const key in items.current) {
                        delete items.current[key]
                    }
                },
                setChild: (value: boolean, id: string) => {
                    if (!values(items.current).some(v => !!v) && value) {
                        items.current[id] = value
                        forceRerender(v => !v)
                    } else items.current[id] = value
                }
            }}>
            {p.children}
        </UnsavedStatusContext.Provider>
    )
}
