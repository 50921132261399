import * as React from "react"

export const Contract = () => {
    return (
        <svg width={60} height={60} viewBox="0 0 60 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g
                clipPath="url(#prefix__clip0)"
                stroke="currentColor"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M1.875 5.543l3.75 4.73v11.168c-.1 4.22 4.793 8 9.373 11.88M13.077 18.395l4.422 4.53a3.046 3.046 0 004.363-4.25l-6.878-7.018V8a15.716 15.716 0 00-2.445-6.125" />
                <path d="M15 20.344v30.28a3.75 3.75 0 003.75 3.75h16.875M58.125 54.455l-3.75-4.73V38.56c.087-3.648-3.553-6.965-7.5-10.3" />
                <path d="M24.375 9.375h18.75a3.75 3.75 0 013.75 3.75V41.55L42.5 37.075a3.046 3.046 0 00-4.362 4.25l6.887 7.02V52a15.733 15.733 0 002.445 6.115" />
            </g>
        </svg>
    )
}
