import * as React from "react"
import styled from "styled-components"
import { StyledFormSchema, useFormHook } from "@smartdevis/forms/src"
import { Domain } from "@smartdevis/server/src/domain"
import { devisValidation } from "@smartdevis/server/src/models/devis"
import { i18n } from "../../services/translations"
import {
    mkFormSchema,
    mkTextSchema,
    mkDatePickerSchema,
    mkHiddenSchema,
    mkFormattedNumberSchema,
    mkNumberSchema
} from "../forms/formSchemas"
import { Label, P } from "@smartdevis/ui/src/Typography"
import { FlexRow, VerticalSpace, FlexColumn } from "@smartdevis/ui/src/utils/common"

import { mkMandatory, markMandatoryFields } from "../../views/architect/ProjectEdit.helpers"
import { DatePicker } from "@smartdevis/ui/src/DatePicker"
import { StyledForm } from "../forms"
import {
    inputsRenderMap,
    StaticTextRenderer,
    StaticNumberRenderer,
    StaticOptionRenderer,
    StaticDateRenderer
} from "../forms/formRenderers"
import { useDeepEffect } from "@smartdevis/ui/src/hooks/common"
import { TMap } from "@smartdevis/utils/src/map"
import { Result, mkOk, mkErr, isErr } from "@smartdevis/utils/src/result"
import { F1 } from "@smartdevis/utils/src/types"
import { optionalNumber } from "@smartdevis/utils/src/validators"
import { RadioButton } from "@smartdevis/ui/src/Button"
import { formatDate } from "@smartdevis/utils/src/date"
import dayjs from "dayjs"

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 0.25rem;
`
export type DevisFormPayload = Pick<
    Domain.Devis,
    | "number"
    | "workCategory"
    | "entryTs"
    | "workStartTs"
    | "workEndTs"
    | "costEstimateOriginal"
    | "costEstimateRevised"
    | "includeVat"
>

const mkNumberOrEmpty = (v: string) => {
    const numberValue = parseFloat(v as any)
    return Number.isNaN(numberValue) ? "" : numberValue
}

const getTranslations = () => ({
    number: i18n("Number"),
    workCategory: i18n("Work category"),
    entryTs: i18n("Entry date"),
    workStartTs: i18n("Work start"),
    workEndTs: i18n("Work end"),
    costEstimateOriginal: i18n("Cost estimate (original)"),
    costEstimateRevised: i18n("Cost estimate (revised)"),
    includeVat: i18n("Cost estimate VAT")
})

export const getDevisFormSchema = (t: TMap<keyof DevisFormPayload, string>, isMutable: boolean) =>
    mkFormSchema<DevisFormPayload>(
        { ...devisValidation, workStartTs: optionalNumber, workEndTs: optionalNumber },
        {
            number: mkTextSchema(t["number"]),
            workCategory: mkTextSchema(t["workCategory"]),
            entryTs: mkDatePickerSchema(t["entryTs"]),
            workStartTs: isMutable ? mkHiddenSchema(t["workStartTs"]) : mkDatePickerSchema(t["workStartTs"]),
            workEndTs: isMutable ? mkHiddenSchema(t["workEndTs"]) : mkDatePickerSchema(t["workEndTs"]),
            includeVat: mkHiddenSchema(t["includeVat"]),
            costEstimateOriginal: isMutable
                ? mkFormattedNumberSchema(t["costEstimateOriginal"], {
                      toValue: mkNumberOrEmpty
                  })
                : mkNumberSchema(t["costEstimateOriginal"], {
                      toValue: mkNumberOrEmpty
                  }),
            costEstimateRevised: isMutable
                ? mkFormattedNumberSchema(t["costEstimateRevised"], {
                      toValue: mkNumberOrEmpty
                  })
                : mkNumberSchema(t["costEstimateRevised"], {
                      toValue: mkNumberOrEmpty
                  })
        }
    )

const getStyledSchema = (isMutable: boolean): StyledFormSchema<DevisFormPayload> =>
    isMutable
        ? [
              { type: "Row", value: ["number", "workCategory"] },
              { type: "Custom", value: "date" },
              { type: "Row", value: ["costEstimateOriginal", "costEstimateRevised"] },
              "includeVat"
          ]
        : [
              { type: "Row", value: ["number", "workCategory"] },
              "entryTs",
              { type: "Row", value: ["workStartTs", "workEndTs"] },
              { type: "Row", value: ["costEstimateOriginal", "costEstimateRevised"] },
              "includeVat"
          ]

const addOneDay = (n: number) => n + 86400000

const useWorkDateFields = (devis: Partial<DevisFormPayload>) => {
    const [entryTs, setEntryTs] = React.useState<number | null>(devis?.entryTs ?? null)
    const [workStartTs, setWorkStartTs] = React.useState<number | null>(devis?.workStartTs ?? null)
    const [workEndTs, setWorkEndTs] = React.useState<number | null>(devis?.workEndTs ?? null)

    if (workStartTs && workEndTs && workEndTs < workStartTs) setWorkEndTs(null)

    const workDateResult: Result<{ entryTs: number; workStartTs: number; workEndTs: number }> =
        entryTs && workStartTs && workEndTs ? mkOk({ entryTs, workStartTs, workEndTs }) : mkErr("")

    const WorkDateFields = () => (
        <>
            <FlexRow style={{ margin: "0 -0.25rem" }}>
                <FieldWrapper>
                    <Label>{mkMandatory(getTranslations().entryTs)}</Label>
                    <DatePicker
                        value={entryTs ? entryTs : undefined}
                        placeholder={i18n("Select date")}
                        onChange={setEntryTs}
                    />
                </FieldWrapper>
            </FlexRow>
            <VerticalSpace h={24} />
            <FlexRow style={{ margin: "0 -0.25rem" }}>
                <FieldWrapper>
                    <Label>{mkMandatory(getTranslations().workStartTs)}</Label>
                    <DatePicker
                        value={workStartTs ? workStartTs : undefined}
                        placeholder={i18n("Select date")}
                        onChange={setWorkStartTs}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>{mkMandatory(getTranslations().workEndTs)}</Label>
                    <DatePicker
                        value={workEndTs ? workEndTs : undefined}
                        placeholder={i18n("Select date")}
                        onChange={setWorkEndTs}
                        disabled={!workStartTs}
                        options={{ minDate: formatDate(addOneDay(workStartTs || 0)) }}
                    />
                </FieldWrapper>
            </FlexRow>
            <VerticalSpace h={24} />
        </>
    )
    return { WorkDateFields, entryTs, workStartTs, workEndTs, workDateResult }
}

const IncludeVatField: React.FC<{ value: boolean; setValue: F1<boolean>; mutable: boolean; visible: boolean }> = p => (
    <FlexColumn style={{ visibility: p.visible ? "visible" : "hidden" }}>
        <Label>{getTranslations().includeVat}</Label>
        {p.mutable ? (
            <>
                <RadioButton label={i18n("Include VAT")} onChange={v => p.setValue(v)} checked={p.value} />
                <RadioButton label={i18n("Exclude VAT")} onChange={v => p.setValue(!v)} checked={!p.value} />
            </>
        ) : (
            <P small>{p.value ? i18n("Include VAT") : i18n("Exclude VAT")}</P>
        )}
    </FlexColumn>
)

export const DevisForm: React.FC<{
    devis: Partial<DevisFormPayload>
    isMutable: boolean
    onResultChange: F1<Result<DevisFormPayload, any>>
}> = p => {
    const [includeVat, setIncludeVat] = React.useState<boolean>(p.devis?.includeVat ?? false)
    const { WorkDateFields, workDateResult } = useWorkDateFields(p.devis)

    const devisSchema = getDevisFormSchema(
        markMandatoryFields(getTranslations(), ["number", "workCategory", "entryTs", "workStartTs", "workEndTs"]),
        p.isMutable
    )

    const { formViewProps, result } = useFormHook({
        schema: devisSchema,
        initialValue: p.devis.entryTs ? p.devis : { ...p.devis, entryTs: dayjs().add(21, "day").valueOf() }
    })

    useDeepEffect({ workDateResult, result, includeVat }, () => {
        if (isErr(workDateResult)) p.onResultChange(workDateResult)
        else if (isErr(result)) p.onResultChange(result)
        else p.onResultChange(mkOk({ ...result.value, ...workDateResult.value, includeVat }))
    })

    const isVatPickerVisible = Boolean(
        formViewProps.state.costEstimateOriginal.value || formViewProps.state.costEstimateRevised.value
    )
    return (
        <>
            <VerticalSpace h={32} />
            <StyledForm
                {...formViewProps}
                styledSchema={getStyledSchema(p.isMutable)}
                styledInputsRenderMap={{ Custom: WorkDateFields }}
                inputsRenderMap={
                    p.isMutable
                        ? { ...inputsRenderMap }
                        : {
                              text: StaticTextRenderer,
                              number: StaticNumberRenderer,
                              select: StaticOptionRenderer,
                              customBox: StaticDateRenderer
                          }
                }
            />
            <IncludeVatField
                mutable={p.isMutable}
                visible={isVatPickerVisible}
                value={includeVat}
                setValue={setIncludeVat}
            />
            <VerticalSpace h={32} />
        </>
    )
}
