import * as React from "react"
import styled from "styled-components"
import { i18n } from "../../services/translations"
import { Button, ButtonProps } from "@smartdevis/ui/src/Button"
import { Popover } from "@smartdevis/ui/src/Popover"
import { F0 } from "@smartdevis/utils/src/types"
import { Popconfirm } from "@smartdevis/ui/src/Popconfirm"

export type ExportBtnProps = {
    handleClick: F0
    confirm?: string
    disabled?: string
    text?: string
    btnType?: ButtonProps["btnType"]
}

const BtnWraper = styled.span`
    display: inline-block;
`

export const ExportBtn: React.FC<ExportBtnProps> = React.memo(({ handleClick, confirm, disabled, btnType, text }) => {
    const type = btnType ?? "action"
    const btn = (
        <Button data-cy="export-btn" icon={type === "action" ? "FilePdf" : undefined} btnType={type}>
            {text ?? i18n("Export")}
        </Button>
    )

    return disabled ? (
        <Popover direction="top" content={disabled}>
            <BtnWraper>{React.cloneElement<ButtonProps>(btn, { disabled: true })}</BtnWraper>
        </Popover>
    ) : confirm ? (
        <Popconfirm
            onConfirm={handleClick}
            title={<span data-cy="export-confirm">{confirm}</span>}
            direction="bottom-right">
            {btn}
        </Popconfirm>
    ) : (
        React.cloneElement<ButtonProps>(btn, { onClick: handleClick })
    )
})
