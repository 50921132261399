import { sortCreatable } from "@smartdevis/utils/src/comparable"
import { SMap, pickObject, omitObject } from "@smartdevis/utils/src/map"
import { mkOk } from "@smartdevis/utils/src/result"
import { StateType } from "@smartdevis/utils/src/types"
import {
    validNumber,
    validateMemberOf,
    Validators,
    validateMap,
    validString,
    validateArray,
    mkMapValidator,
    optional,
    optionalMap,
    optionalString,
    patternMatchValidator,
    ValidationMap,
    validId,
    validIds,
    mkPartialMapValidator,
    validBoolean,
    optionalIfFalsy
} from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"
import { validateAttachmentData } from "./attachment"
import { creatableValidation } from "./common"
import { contractorValidation } from "./contractor"
import { devisValidation } from "./devis"
import {
    validateSection,
    validateAdditionalInformation,
    validateCondition,
    validateDeduction,
    validatePosition
} from "./position"
import { projectDetailsValidation, projectDetailsServerValidation, projectPersonas } from "./projectDetails"
import { userValidation, _deprecatedValidateContractorUser, validateUser } from "./user"

export const roundValidation: ValidationMap<Domain.Round> = {
    createdTs: validNumber,
    offersIds: validIds,
    devisId: validId,
    roundId: validId
}
export const validateRound = mkMapValidator(roundValidation)

export const validateOfferState = patternMatchValidator<"type", Domain.OfferState>("type", {
    requested: mkMapValidator({}),
    adopted: mkMapValidator({}),
    contracted: mkMapValidator({}),
    submitted: mkMapValidator({}),
    negotiation: mkMapValidator({}),
    "final-proposal": mkMapValidator({}),
    "next-round": mkMapValidator({ roundId: validId }),
    "round-submitted": mkMapValidator({ roundId: validId }),
    cancelled: mkMapValidator({ reason: optionalString }),
    rejected: mkMapValidator({ reason: optionalString })
})

const offerMetaValidation: ValidationMap<Domain.OfferMeta> = {
    autoRejectOffers: validBoolean,
    clientsRepresentative: [validateMemberOf(projectPersonas)],
    contractType: [validateMemberOf<Domain.OfferMeta["contractType"]>(["flat-rate", "unit-prices"])]
}
export const validateOfferMeta = optional({})(mkPartialMapValidator<Domain.OfferMeta>(offerMetaValidation))

const roundDeltaMetaValidation: ValidationMap<Domain.RoundDeltaMeta> = {
    deltaId: validId,
    refId: validId,
    roundId: validId
}
const validateDeltaType = validateMemberOf<StateType<Domain.RoundDelta>>(["add", "edit", "remove"])
const mkRoundDeltaValidator = <T extends Domain.RoundEdition["value"]>(value: Validators<T>) =>
    mkMapValidator({
        ...roundDeltaMetaValidation,
        type: [validateDeltaType],
        value
    })
export const validateRoundDelta = patternMatchValidator<"type", Domain.RoundDelta>("type", {
    add: patternMatchValidator<"parent", Domain.RoundAddition & Domain.RoundDeltaMeta>("parent", {
        conditions: mkRoundDeltaValidator([validateCondition]),
        deductions: mkRoundDeltaValidator([validateDeduction]),
        positions: mkRoundDeltaValidator([validatePosition]),
        sections: mkRoundDeltaValidator([validateSection]),
        additionalInformation: mkRoundDeltaValidator([validateAdditionalInformation])
    }),
    edit: patternMatchValidator<"parent", Domain.RoundEdition & Domain.RoundDeltaMeta>("parent", {
        conditions: mkRoundDeltaValidator([validateCondition]),
        deductions: mkRoundDeltaValidator([validateDeduction]),
        positions: mkRoundDeltaValidator([validatePosition]),
        sections: mkRoundDeltaValidator([validateSection]),
        additionalInformation: mkRoundDeltaValidator([validateAdditionalInformation]),
        fileAttachment: mkRoundDeltaValidator([validateAttachmentData])
    }),
    remove: patternMatchValidator<"parent", Domain.RoundDeletion & Domain.RoundDeltaMeta>("parent", {
        conditions: mkMapValidator({ ...roundDeltaMetaValidation, type: [validateDeltaType] }),
        deductions: mkMapValidator({ ...roundDeltaMetaValidation, type: [validateDeltaType] }),
        positions: mkMapValidator({ ...roundDeltaMetaValidation, type: [validateDeltaType] }),
        sections: mkMapValidator({ ...roundDeltaMetaValidation, type: [validateDeltaType] }),
        additionalInformation: mkMapValidator({ ...roundDeltaMetaValidation, type: [validateDeltaType] })
    })
})

export const stateHistoryValidation: ValidationMap<Domain.OfferStateChange> = {
    createdTs: creatableValidation.createdTs,
    state: [validateOfferState]
}
export const validateOfferStateChange = mkMapValidator(stateHistoryValidation)
export const nullableOfferValuesValidator: Validators<Domain.OfferValues> = [
    optionalIfFalsy(undefined as any)(validateMap(validNumber) as any)
]
export const offerValuesValidator: Validators<Domain.OfferValues> = optionalMap(validNumber)
export const offerInformationValidator: Validators<Domain.OfferInformation> = optionalMap(optionalString)
export const roundOfferValuesValidator: Validators<SMap<Domain.OfferValues>> = optionalMap([validateMap(validNumber)])
export const roundOfferInformationValidator: Validators<SMap<Domain.OfferInformation>> = optionalMap([
    validateMap(validString)
])

const offerProposalValidator = [optional<Domain.AttachmentData>(undefined as any)(validateAttachmentData)]

export const _deprecatedArchitectOfferRequestValidation: ValidationMap<Required<Domain.ArchitectOfferRequest>> = {
    ...creatableValidation,
    state: [validateOfferState],
    statesHistory: [validateArray<Domain.OfferStateChange>([validateOfferStateChange])],
    contractorUser: [optional<Domain.User>(undefined as any)(_deprecatedValidateContractorUser)],
    projectId: validId,
    devisId: validId,
    offerId: validId,
    submitterId: validId,
    initialOffer: offerValuesValidator,
    finalOffer: offerValuesValidator,
    roundOffers: roundOfferValuesValidator,
    offerProposal: offerProposalValidator,
    roundOfferProposals: optionalMap(offerProposalValidator),
    initialInformation: offerInformationValidator,
    roundInformation: roundOfferInformationValidator,
    finalInformation: offerInformationValidator,
    roundComments: optionalMap(optionalString),
    comment: optionalString,
    offerAttachments: [mkOk],
    roundOfferAttachments: [mkOk],
    offerMeta: [validateOfferMeta],
    positionComments: [mkOk]
}

export const architectOfferRequestValidation: ValidationMap<Required<Domain.ArchitectOfferRequest>> = {
    ...creatableValidation,
    state: [validateOfferState],
    statesHistory: [validateArray<Domain.OfferStateChange>([validateOfferStateChange])],
    contractorUser: [optional<Domain.User>(undefined as any)(validateUser)],
    projectId: validId,
    devisId: validId,
    offerId: validId,
    submitterId: validId,
    initialOffer: offerValuesValidator,
    finalOffer: offerValuesValidator,
    roundOffers: roundOfferValuesValidator,
    offerProposal: offerProposalValidator,
    roundOfferProposals: optionalMap(offerProposalValidator),
    initialInformation: offerInformationValidator,
    roundInformation: roundOfferInformationValidator,
    finalInformation: offerInformationValidator,
    roundComments: optionalMap(optionalString),
    comment: optionalString,
    offerAttachments: [mkOk],
    roundOfferAttachments: [mkOk],
    offerMeta: [validateOfferMeta],
    positionComments: [mkOk]
}

export const validateArchitectOfferRequest = mkMapValidator(architectOfferRequestValidation)
export const _deprecatedValidateArchitectOfferRequest = mkMapValidator(_deprecatedArchitectOfferRequestValidation)

export const publicOfferValidation: ValidationMap<Domain.PublicOffer> = {
    offerId: validId,
    state: [validateOfferState],
    architect: [mkMapValidator(pickObject(userValidation, ["name", "email", "surname"]))],
    contractor: [
        mkMapValidator(
            pickObject(contractorValidation, [
                "city",
                "postalCode",
                "street",
                "phoneNumber",
                "companyName",
                "email",
                "name",
                "surname"
            ])
        )
    ]
}
export const validatePublicOffer = mkMapValidator(publicOfferValidation)

export const contractorOfferClientValidation: ValidationMap<Required<Domain.ContractorOffer>> = {
    ...creatableValidation,
    ...pickObject(architectOfferRequestValidation, [
        "initialInformation",
        "finalInformation",
        "roundInformation",
        "initialOffer",
        "roundOffers",
        "finalOffer",
        "offerProposal",
        "roundOfferProposals",
        "roundComments",
        "state",
        "statesHistory",
        "offerId",
        "comment",
        "offerMeta",
        "positionComments"
    ]),
    devis: [
        mkMapValidator(
            pickObject(devisValidation, [
                "number",
                "devisId",
                "workCategory",
                "entryTs",
                "workStartTs",
                "workEndTs",
                "positionsFormat"
            ])
        )
    ],
    architect: [mkMapValidator(pickObject(userValidation, ["userId", "name", "email", "surname"]))],
    deltas: null, //TODO:
    conditions: null,
    deductions: null,
    positions: null,
    additionalInformation: null,
    generalInformation: null,
    attachments: null,
    sections: null,
    project: [mkMapValidator(omitObject(projectDetailsValidation, ["createdTs", "isTemplate", "version"]))],
    offerAttachments: [mkOk],
    roundOfferAttachments: [mkOk]
}

export const contractorOfferValidation: ValidationMap<Required<Domain.ContractorOffer>> = {
    ...creatableValidation,
    ...pickObject(architectOfferRequestValidation, [
        "initialInformation",
        "finalInformation",
        "roundInformation",
        "initialOffer",
        "roundOffers",
        "finalOffer",
        "offerProposal",
        "roundOfferProposals",
        "roundComments",
        "state",
        "statesHistory",
        "offerId",
        "comment",
        "offerMeta",
        "positionComments"
    ]),
    devis: [
        mkMapValidator(
            pickObject(devisValidation, [
                "number",
                "devisId",
                "workCategory",
                "entryTs",
                "workStartTs",
                "workEndTs",
                "positionsFormat"
            ])
        )
    ],
    architect: [mkMapValidator(pickObject(userValidation, ["userId", "name", "email", "surname"]))],
    deltas: null, //TODO:
    conditions: null,
    deductions: null,
    positions: null,
    additionalInformation: null,
    generalInformation: null,
    attachments: null,
    sections: null,
    project: [mkMapValidator(omitObject(projectDetailsServerValidation, ["createdTs", "isTemplate", "version"]))],
    offerAttachments: [mkOk],
    roundOfferAttachments: [mkOk]
}

export const validateContractorOfferClient = mkMapValidator(contractorOfferClientValidation)
export const validateContractorOffer = mkMapValidator(contractorOfferValidation)

export const pendingOfferRequestValidation: ValidationMap<Domain.PendingOfferRequest> = {
    ...creatableValidation,
    projectId: validId,
    userId: validString
}

export const validatePendingOfferRequest = mkMapValidator(pendingOfferRequestValidation)

export const getLatestActionTsForOffers = (requests: Domain.ArchitectOfferRequest[]) => {
    const sortedRequests = requests.flatMap(r => r.statesHistory).sort(sortCreatable("desc"))
    return sortedRequests.length > 0 ? sortedRequests[0].createdTs : 0
}
