import * as React from "react"

export const File: React.FC = p => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
        <path
            d="M6.6538 3.98204L4.3598 6.27704C4.23568 6.40103 4.13721 6.54826 4.07003 6.71033C4.00285 6.87239 3.96827 7.04611 3.96827 7.22154C3.96827 7.39698 4.00285 7.5707 4.07003 7.73276C4.13721 7.89482 4.23568 8.04206 4.3598 8.16604V8.16604C4.48378 8.29016 4.63102 8.38863 4.79308 8.45581C4.95514 8.52299 5.12886 8.55757 5.3043 8.55757C5.47973 8.55757 5.65345 8.52299 5.81551 8.45581C5.97758 8.38863 6.12481 8.29016 6.2488 8.16604L9.3488 5.06604C9.84972 4.56481 10.1311 3.88517 10.1311 3.17654C10.1311 2.46791 9.84972 1.78828 9.3488 1.28704C9.10074 1.03877 8.80619 0.84182 8.48198 0.707445C8.15777 0.57307 7.81025 0.503906 7.4593 0.503906C7.10834 0.503906 6.76083 0.57307 6.43661 0.707445C6.1124 0.84182 5.81785 1.03877 5.5698 1.28704L2.1998 4.65704C1.44814 5.40885 1.02588 6.42843 1.02588 7.49154C1.02588 8.55465 1.44814 9.57423 2.1998 10.326V10.326C2.95161 11.0777 3.97119 11.5 5.0343 11.5C6.09741 11.5 7.11699 11.0777 7.8688 10.326L10.9688 7.22604"
            stroke="currentColor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
