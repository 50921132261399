import * as React from "react"
import { isDefined, _noop } from "@smartdevis/utils/src/misc"
import styled, { css } from "styled-components"
import { useGlobalClick, useHovered, useNonInputScrollEvent } from "./hooks/common"
import { Portal } from "./Portal"
import { EnterDown, ExitUp } from "./utils/animations"
import { styleIfProp } from "./utils/common"
import { getClientHeight, getClientWidth, getOnScreenPosition } from "./utils/dom"
import { themeColor, themeConfig } from "./utils/theme"

type PopoverDirection = "left" | "right" | "top" | "bottom" | "bottom-left"

type PopoverProps = {
    content: React.ReactNode
    direction: PopoverDirection
    children: React.ReactElement
    trigger?: "hover" | "click"
}

export const Popover: React.FC<PopoverProps> = ({ trigger = "hover", ...p }) => {
    const triggerRef = React.useRef<HTMLDivElement | null>(null)
    const closeTimeoutRef = React.useRef<number | undefined>(undefined)
    const openTimeoutRef = React.useRef<number | undefined>(undefined)

    const { hoverListeners, hovered } = useHovered()
    const { hoverListeners: phl, hovered: phovered } = useHovered()
    const [visible, setVisible] = React.useState(false)
    const [closing, setClosing] = React.useState(false)

    const portal = visible ? (
        <Portal>
            <PopoverContainer
                closing={closing}
                hoverListeners={phl}
                rect={getOnScreenPosition(triggerRef.current)}
                direction={p.direction}
                content={p.content}
            />
        </Portal>
    ) : null

    const show = () => {
        setClosing(false)
        openTimeoutRef.current = setTimeout(() => setVisible(true), 200) as any
    }

    const hide = (delay: number) => {
        closeTimeoutRef.current = setTimeout(() => {
            setClosing(true)
            setTimeout(() => setVisible(false), 100) //animation duration
        }, delay) as any
    }
    React.useEffect(() => {
        if (hovered || phovered) clearTimeout(closeTimeoutRef.current)
        if ((hovered || phovered) && trigger === "hover" && !visible) show()
        if (!hovered && !phovered) clearTimeout(openTimeoutRef.current)
        if (!hovered && !phovered && visible) hide(50) //heuristic
    }, [hovered, visible, phovered])

    useGlobalClick(() => (trigger === "click" && visible ? hide(0) : null))
    useNonInputScrollEvent(() => hide(0))

    return (
        <>
            <TriggerContainer onClick={trigger === "click" ? show : _noop} ref={triggerRef} {...hoverListeners}>
                {p.children}
            </TriggerContainer>
            {portal}
        </>
    )
}

type PopoverContainerProps = {
    rect?: DOMRect
    closing: boolean
    content: React.ReactNode
    direction: PopoverDirection
    hoverListeners: ReturnType<typeof useHovered>["hoverListeners"]
}
const PopoverContainer: React.FC<PopoverContainerProps> = p => {
    const popoverRef = React.useRef<HTMLDivElement | null>(null)
    const [rect, setRect] = React.useState<DOMRect | undefined>(undefined)

    React.useLayoutEffect(() => {
        let shouldUpdate = true
        const checkSizes = () => {
            if (popoverRef.current && !rect) {
                const newRect = getOnScreenPosition(popoverRef.current)
                if (newRect?.width ?? 0 > 0) {
                    setRect(newRect)
                    shouldUpdate = false
                }
            }
            if (shouldUpdate) window.requestAnimationFrame(checkSizes)
        }
        checkSizes()
        return () => {
            shouldUpdate = false
        }
    }, [])
    return (
        <PopoverContainerBase
            ref={popoverRef}
            rect={p.rect}
            closing={p.closing}
            ownRect={rect}
            open={isDefined(rect)}
            direction={p.direction}
            {...p.hoverListeners}>
            {p.content}
        </PopoverContainerBase>
    )
}

type PopoverBaseProps = {
    rect?: DOMRect
    ownRect?: DOMRect
    direction: PopoverDirection
    open: boolean
    closing: boolean
}
const stylesForContainer = (p: PopoverBaseProps) => {
    if (!p.rect || !p.ownRect)
        return css`
            opacity: 0;
        `

    const ch = getClientHeight()
    const cw = getClientWidth()
    const spacer = 4
    const bottom = ch - p.rect.bottom - spacer - p.ownRect.height
    const top = p.rect.top - spacer - p.ownRect.height
    const vmidt = p.rect.top + p.rect.height / 2 - p.ownRect.height / 2 // vertical middle from top
    const vmidb = ch - p.rect.bottom + p.rect.height / 2 - p.ownRect.height / 2 // vertical middle from bottom

    const left = p.rect.left - spacer - p.ownRect.width
    const right = cw - p.rect.right - 2 * spacer - p.ownRect.width
    const hmidl = p.rect.left + p.rect.width / 2 - p.ownRect.width / 2 //horizontal middle from left
    const hmidr = cw - p.rect.right + p.rect.width / 2 - p.ownRect.width / 2 // horizontal middle from right
    const hlmidl = p.rect.left - p.ownRect.width / 2 // horizontal left middle from left (bottom left & top left placement)
    const hlmidr = cw - p.rect.right + p.rect.width - p.ownRect.width / 2 // horizontal left middle from right (bottom left & top left placement)

    switch (p.direction) {
        case "bottom-left": {
            const vertical = bottom < 0 ? `top: ${top}px` : `bottom: ${bottom}px`
            const horizontal = hlmidl < 0 ? `left: 0` : hlmidr < 0 ? `right: 0` : `left: ${hlmidl}px`
            return `${vertical};${horizontal};`
        }
        case "bottom": {
            const vertical = bottom < 0 ? `top: ${top}px` : `bottom: ${bottom}px`
            const horizontal = hmidl < 0 ? `left: 0` : hmidr < 0 ? `right: 0` : `left: ${hmidl}px`
            return `${vertical};${horizontal};`
        }
        case "top": {
            const vertical = top < 0 ? `bottom: ${bottom}px` : `top: ${top}px`
            const horizontal = hmidl < 0 ? `left: 0` : hmidr < 0 ? `right: 0` : `left: ${hmidl}px`
            return `${vertical};${horizontal};`
        }
        case "right": {
            const horizontal = right < 0 ? `left: ${left}px` : `right: ${right}px`
            const vertical = vmidt < 0 ? `top: 0` : vmidb < 0 ? `bottom: 0` : `top: ${vmidt}px`
            return `${vertical};${horizontal};`
        }
        case "left": {
            const horizontal = left < 0 ? `right: ${right}px` : `left: ${left}px`
            const vertical = vmidt < 0 ? `top: 0` : vmidb < 0 ? `bottom: 0` : `top: ${vmidt}px`
            return `${vertical};${horizontal};`
        }
    }
}

const TriggerContainer = styled.div``

const PopoverContainerBase = styled.div<PopoverBaseProps>`
    position: fixed;
    background: ${themeColor("white")};
    border: 1px solid ${themeColor("grey50")};
    border-radius: 4px;
    white-space: pre-wrap;
    padding: 12px;
    z-index: ${themeConfig("zIndexDropdown")};

    ${styleIfProp(
        "open",
        css`
            animation: ${EnterDown} 100ms ease-out;
        `
    )}
    ${styleIfProp(
        "closing",
        css`
            animation: ${ExitUp} 100ms ease-out;
        `
    )}
    ${stylesForContainer}
`
