import { isLoop, loop, getCmd, getModel, Cmd, CmdType, Loop } from "redux-loop"
import { AnyAction } from "redux"
import { keys } from "@smartdevis/utils/src/map"
import { EmptyObject, F1 } from "@smartdevis/utils/src/types"
import { Action } from "@smartdevis/utils/src/actions"

export type ContextReducer<TContext, TState, A extends Action = Action> = (
    state: TState,
    action: A,
    context: TContext
) => TState | Loop<TState, A>

export type ContextReducers<TContext, TState> = {
    [TReducer in keyof TState]: ContextReducer<TContext, TState[TReducer]>
}

const batchCmds = <A extends Action>(cmds: Array<CmdType<A>>) =>
    cmds.length === 0 ? Cmd.none : cmds.length === 1 ? cmds[0] : Cmd.list(cmds)

const joinCmds = <T>(acc: Array<CmdType<any>>, reduced: T | Loop<T, any>): Array<CmdType<any>> => {
    const nextCmds = getCmd(reduced)
    return isLoop(reduced) && nextCmds ? [...acc, nextCmds] : acc
}

const joinState = <T, TK extends keyof T>(acc: T, key: keyof T, reduced: T[TK] | Loop<T[TK], any>): T => ({
    ...acc,
    [key]: getModel(reduced)
})

export const combineContextReducers = <TState, TContext = EmptyObject, A extends Action = AnyAction>(
    reducers: ContextReducers<TContext, TState>,
    getContext: F1<TState, TContext>
): ((state: TState, action: A) => TState | Loop<TState, A>) => {
    return (state, action) => {
        const context = getContext(getModel(state))
        type Acc = [TState, Array<CmdType<A>>]
        const [nextState, nextCmds] = keys(reducers).reduce(
            ([s, cmds]: Acc, key: keyof TState): Acc => {
                const reduced = reducers[key](state[key], action, context)
                return [joinState(s, key, reduced), joinCmds(cmds, reduced)]
            },
            [state, []]
        )
        if (!nextCmds.length) return nextState
        return loop(nextState, batchCmds(nextCmds))
    }
}
