import { filterObject, SMap, values } from "@smartdevis/utils/src/map"
import { Domain } from "@smartdevis/server/src/domain"
import { i18n } from "../services/translations"

type Comment = {
    value: string
    label: string
}

export const getStaticComments = (
    offer: Pick<Domain.ContractorOffer, "roundComments" | "state" | "comment">
): Comment[] => {
    const comments = []
    const { state } = offer

    const roundComments = values(
        filterObject(offer.roundComments || {}, (k, v) => !(state.type === "next-round" && k === state.roundId) && !!v)
    )

    if (offer.comment && state.type !== "adopted")
        comments.push({ label: i18n("First round comment"), value: offer.comment })
    if (roundComments)
        comments.push(...roundComments.map(r => ({ label: i18n("Negotations round comment"), value: r })))
    return comments
}

export const getStaticPositionComments = (
    offer: Pick<Domain.ContractorOffer, "positionComments" | "state">,
    positionId: string
): Comment[] => {
    const comments = []
    const positionComments = (offer.positionComments || {})[positionId]
    const { state } = offer

    const initialComment = (positionComments || {})["initial"]
    const roundComments = filterObject(positionComments || {}, k => k !== "initial")

    if (initialComment && state.type !== "adopted")
        comments.push({ label: i18n("First round comment"), value: initialComment })
    if (roundComments)
        comments.push(...values(roundComments).map(r => ({ label: i18n("Negotations round comment"), value: r })))
    return comments
}

type OfferAttachment = {
    value: SMap<Domain.Attachment>
    label: string
}

export const getPastOfferAttachments = (
    offer: Pick<Domain.ContractorOffer, "state" | "offerAttachments" | "roundOfferAttachments">
): OfferAttachment[] => {
    const attachments: OfferAttachment[] = []

    const { state } = offer

    const roundOfferAttachments = values(
        filterObject(offer.roundOfferAttachments || {}, k => !(state.type === "next-round" && k === state.roundId))
    )

    if (offer.offerAttachments && values(offer.offerAttachments).length && state.type !== "adopted")
        attachments.push({
            label: i18n("First round offer attachments"),
            value: offer.offerAttachments
        })

    if (roundOfferAttachments)
        attachments.push(
            ...roundOfferAttachments.map(r => ({ label: i18n("Negotations round attachments"), value: r }))
        )

    return attachments
}
