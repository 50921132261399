import * as React from "react"
import { calculateDevis, sumPositionsPrices } from "@smartdevis/server/src/utils/money"
import { SubContent } from "../../../components/layouts/Content"
import { PageHeader } from "../../../components/layouts/Header"
import { Table } from "../../../components/table/Table"
import { devisPaths, RouteParams } from "../../../paths"
import { asyncConnect } from "../../../resolvers"
import {
    mkDeductionsBySection,
    mkDeductionSections,
    mkDeltasByRef,
    mkPositions,
    mkPositionSections,
    mkRequestsBySubmitter,
    mkSublevels
} from "@smartdevis/server/src/models/shared"
import { i18n } from "@smartdevis/client/src/services/translations"
import { DevisWrapper } from "../Devis"
import { mkNegotiationRows, useUpdateFinalOffer } from "./DevisNegotiation.helpers"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import {
    getChangedOffersInNegotiation,
    isOfferInState,
    mkLatestOfferInformation,
    mkLatestOfferValues
} from "@smartdevis/server/src/utils/offer"
import { Button } from "@smartdevis/ui/src/Button"
import { NegotiationModals } from "./DevisNegotiation.modals"
import { Domain } from "@smartdevis/server/src/domain"
import { isInNegotiations, mkDevisPositionsRows } from "@smartdevis/client/src/utils/devisHelpers"
import { values, remap } from "@smartdevis/utils/src/map"
import { isDefined, identity } from "@smartdevis/utils/src/misc"
import { getTs } from "@smartdevis/utils/src/id"

export const DevisNegotiation = asyncConnect({
    stateResolvers: ["projectDetails", "devisCollections", "devis", "contractors", "results"],
    actions: ["updateFinalOffer", "updateRequestsState", "navigate"]
})<RouteParams>(p => {
    const { devisId } = p.devis
    const { projectId } = p.projectDetails
    const [exportModalOpen, setExportModalOpen] = React.useState(false)
    const deltasByRef = mkDeltasByRef(p.devisCollections)
    const isEditable = isInNegotiations(p.devisCollections, devisId)

    const requestsBySubmitter = mkRequestsBySubmitter(p.devisCollections, r =>
        isOfferInState(r, ["negotiation", "final-proposal", "contracted"])
    )

    const submitters = values(p.contractors).filter(c => isDefined(requestsBySubmitter[c.contractorId]))
    const submittersIds = submitters.map(s => s.contractorId)

    const positions = mkPositions(p.devisCollections, deltasByRef, { keepDeleted: false })
    const positionSections = mkPositionSections(p.devisCollections, deltasByRef, { keepDeleted: false })
    // const positionsBySections = mkPositionsBySection(p.project, devisId, deltasByRef, { keepDeleted: false })
    const sublevels = mkSublevels(p.devisCollections, deltasByRef, { keepDeleted: false })
    const deductionSections = mkDeductionSections(p.devisCollections, deltasByRef, { keepDeleted: false })
    const deductionsBySections = mkDeductionsBySection(p.devisCollections, deltasByRef, { keepDeleted: false })

    const { sendUpdate, revertUpdate } = useUpdateFinalOffer(p, requestsBySubmitter)

    const calculationsBySubmitter = React.useMemo(
        () =>
            remap(requestsBySubmitter, identity, r => {
                const offerValues = mkLatestOfferValues(r)
                const offerInformation = mkLatestOfferInformation(r)
                return calculateDevis(
                    deductionSections,
                    deductionsBySections,
                    offerValues,
                    offerInformation,
                    sumPositionsPrices(positions, offerValues)
                )
            }),
        [requestsBySubmitter]
    )

    const changedIdsBySubmitter = remap(requestsBySubmitter, identity, (_, sid) =>
        getChangedOffersInNegotiation(requestsBySubmitter[sid])
    )

    const calculationsByTimeBySubmitter = remap(calculationsBySubmitter, identity, r => ({ [getTs()]: r }))
    const mkSumRow = mkNegotiationRows.sumsBuilder(submitters, calculationsByTimeBySubmitter)
    const mkPositionRow = mkNegotiationRows.positionsRowsBuilder(
        calculationsBySubmitter,
        submitters,
        changedIdsBySubmitter,
        sendUpdate,
        revertUpdate(submittersIds),
        !isEditable
    )
    const mkDeductionRow = mkNegotiationRows.deductionsRowsBuilder(
        submitters,
        calculationsBySubmitter,
        changedIdsBySubmitter,
        sendUpdate,
        revertUpdate(submittersIds),
        !isEditable
    )

    const positionRows = mkDevisPositionsRows(
        positionSections,
        sublevels,
        (_, s, children) => mkNegotiationRows.positionSectionRow(s, submitters, children),
        mkPositionRow
    )

    const deductionRows = deductionSections.map(({ sectionId }, i) => {
        const rows = deductionsBySections[sectionId]?.map(mkDeductionRow) ?? []
        if (rows.length === 0) return []
        const totalRow =
            i === deductionSections.length - 1 ? mkSumRow({ type: "net" }) : mkSumRow({ type: "subtotal", sectionId })
        return [...rows, totalRow]
    })

    const onContractSelect = (submitter: Domain.Contractor) => {
        if (submitters.some(s => isOfferInState(requestsBySubmitter[s.contractorId], ["final-proposal", "contracted"])))
            p.navigate(devisPaths.finalization, { projectId, devisId })
        else
            p.navigate(devisPaths.prefinalization, {
                projectId,
                devisId,
                offerId: requestsBySubmitter[submitter.contractorId].offerId
            })
    }
    return (
        <DevisWrapper current="negotiation" {...p}>
            <SubContent>
                <PageHeader
                    title={i18n("Negotiations")}
                    actionButtons={[
                        <Button btnType="action" key="export" onClick={() => setExportModalOpen(true)}>
                            {i18n("Export recommendation")}
                        </Button>
                    ]}
                />
                <Table
                    rows={[
                        mkNegotiationRows.topRow(submitters, "p", requestsBySubmitter, onContractSelect),
                        mkNegotiationRows.positionsHeaderRow(submitters),
                        ...positionRows.flat()
                    ]}
                />
                <VerticalSpace base="16px" />
                <PageHeader title={i18n("Deductions")} noBottomMargin />
                <Table
                    rows={[
                        mkNegotiationRows.topRow(submitters, "d", requestsBySubmitter),
                        mkSumRow({ type: "gross" }),
                        ...deductionRows.flat(),
                        mkSumRow({ type: "tax" }),
                        mkSumRow({ type: "netincltax" }),
                        mkNegotiationRows.bottomRow(submitters, "d", requestsBySubmitter, onContractSelect)
                    ]}
                />
            </SubContent>
            <NegotiationModals.ExportComment
                submitters={submitters}
                offersIds={submitters.map(s => requestsBySubmitter[s.contractorId].offerId)}
                visible={exportModalOpen}
                onClose={() => setExportModalOpen(false)}
            />
        </DevisWrapper>
    )
})
