import { ThemeProps } from "styled-components"
import { MediaQuery, MediaCSS, media } from "./media"

declare module "styled-components" {
    export interface DefaultTheme extends DT {}
}
export type DT = typeof defaultTheme

export const defaultColors = {
    transparent: "transparent",
    primary: "#FF3C00",
    primary08: "#ffefeb",
    primary15: "#ffe2d9",
    primary50: "#ff9e80",

    error: "#bd0000",
    action: "#ff3C00",

    white: "#fff",
    black: "#252525",

    default: "#485156",

    primaryGrey: "#485156",
    secondaryGrey: "#a1a3a7",
    borderGrey: "rgba(0,0,0,0.07)", // header border
    blueGrey: "#919b9f",

    grey70: "#a0a0a0",
    grey60: "#d9d9d9",
    grey50: "#ededed",
    grey40: "#f1f1f1",
    grey35: "#fafafa",
    grey30: "#fdfdfd",

    infoboxInfoIconColor: "#4b6fee",
    infoboxInfoBgColor: "#eff3ff",
    infoboxWarningBgColor: "rgba(145, 155, 159, 0.1)",

    info: "#00bfff",
    info10: "rgba(0, 191, 255, 0.05)",

    rank1Color: "#1CBB82",
    rank1ColorLight: "rgba(230, 241, 231, 0.5)",
    rank2Color: "#EDE574",
    rank3Color: "rgba(255, 193, 7)",
    rank3ColorLight: "#FEFBEF",
    rank4Color: "rgb(253, 200, 158)",
    rank4ColorLight: "rgba(255, 236, 231, 0.5)",
    rank5Color: "#FF4E50",
    rank5ColorLight: "rgba(255, 205, 205, 0.5)",

    shadowGrey: "rgba(0, 0, 0, 0.03)",
    strongShadowGrey: "rgba(0, 0, 0, 0.15)",
    textBlack: "#384044",
    tagRemoved: "#EE4D1C", // <Tag>
    tagEdited: "#9F9574",
    tagAdded: "#58785B",
    navBackground: "#F8F8F8",
    navText: "#444850"
}

const headerHeight = 60
const devisStepsHeight = 106
const devisStepsWithHeaderHeight = headerHeight + devisStepsHeight

const defaultConfig = {
    fontFamily: "IBM Plex Sans",
    fontSizeBase: "14px",
    fontSizeSmall: "13px",
    maxWidth: "1280px",
    headerHeight: `${headerHeight}px`,
    devisStepsHeight: `${devisStepsHeight}px`,
    devisStepsWithHeaderHeight: `${devisStepsWithHeaderHeight}px`,
    zIndexMax: "1000",
    zIndexDropdown: "900",
    zIndexModal: "800",
    zIndexHeader: "700",
    panelWidth: "15%",
    contentPadding: "32px"
}

export type ThemeColors = typeof defaultColors
export type ThemeConfig = typeof defaultConfig

export const defaultTheme = {
    colors: defaultColors,
    config: defaultConfig,
    media
}

export const themeColor = (color: keyof ThemeColors) => (p: ThemeProps<DT>) => p.theme.colors?.[color]
export const themeConfig = (config: keyof ThemeConfig) => (p: ThemeProps<DT>) => p.theme.config?.[config]

export const themeMedia = (operator: keyof MediaQuery, breakpoint: keyof MediaCSS) => (p: ThemeProps<DT>) =>
    p.theme.media?.[operator]?.[breakpoint]
