import { RouteComponentProps } from "react-router"
import { Domain } from "@smartdevis/server/src/domain"
import { sharedPaths } from "@smartdevis/server/src/constants"
import { matchPathParams, materialize } from "./utils/router"
import { arrify } from "@smartdevis/utils/src/array"
import { values } from "@smartdevis/utils/src/map"
import { Arrayable } from "@smartdevis/utils/src/types"

export type ValueOf<T> = T extends { value: infer E } ? E : T
export type AccessLevel = "public" | Domain.UserType
const types: AccessLevel[] = ["public", "contractor", "architect", "admin", "wd_moderator", "cd_moderator"]
export const isAccessible = (pathAcccess: AccessLevel) => (current: AccessLevel) =>
    types.indexOf(current) - types.indexOf(pathAcccess) >= 0

export const isParam = (p: string): p is Param => (allParams as any)[p] !== undefined

export const allParams = {
    projectId: ":projectId",
    devisId: ":devisId",
    offerRequestId: ":offerRequestId", // TODO: deprecated
    token: ":token",
    userId: ":userId",
    offerId: ":offerId",
    offerStep: ":offerStep",
    templateId: ":templateId"
}

export const allPathParts = {
    admin: "admin",
    changelog: "changelog",
    users: "users",
    activity: "activity",
    demo: "demo",
    copies: "copies",
    account: "account",
    user: "user",
    overview: "overview",
    estimation: "estimation",
    positions: "positions",
    deductions: "deductions",
    contract: "contract",
    summary: "summary",
    finalization: "finalization",
    negotiation: "negotiation",
    submit: "submit",
    shelf: "shelf",
    submitters: "submitters",
    preview: "preview",
    information: "information",
    templates: "templates",
    contacts: "contacts",
    home: "",
    partners: "partners",
    directory: "directory",
    ...sharedPaths
}

export type Path = {
    name: string
    path: string
    pathParts: (PathPart | keyof PathParams)[]
    accessLevel: AccessLevel
    params?: Partial<PathParams>
}

export type PathPart = keyof typeof allPathParts
export type PathParams = typeof allParams
export type RouteParams = RouteComponentProps<PathParams>

export type NavigationPayload = { to: Path; params?: Partial<PathParams>; fragment?: string }

export const getPathParams = (path: Path, url: string): Partial<PathParams> =>
    (matchPathParams(path.path, url, true) || {}) as Partial<PathParams>

export const getParamsFromPathname = (url: string): Partial<PathParams> => {
    const path = values(allPaths).find(p => matchPathParams(p.path, url, true))
    if (!path) return {}
    return getPathParams(path, url)
}

export type Param = keyof typeof allParams

export const mkPath =
    (accessLevel: AccessLevel, prefix: Arrayable<PathPart | Param> = []) =>
    (name: string, ps: Arrayable<PathPart | Param> = []): Path => {
        const all = { ...allPathParts, ...allParams }
        const pathParts = [...arrify(prefix), ...arrify(ps)]
        const path = pathParts.reduce((acc, k) => `${acc}/${all[k]}`, "")
        return { path, pathParts, name, accessLevel }
    }

const publicPath = mkPath("public")
const contractorPath = mkPath("contractor")
const architectPath = mkPath("architect")
const adminPath = mkPath("admin", "admin")

const projectsPath = mkPath("architect", ["projects", "projectId"])
const devisPath = mkPath("architect", ["projects", "projectId", "devis", "devisId"])

export const demoPaths = {
    demo: architectPath("Demo view", "demo")
}

export const contractorOfferPaths = {
    contractorOfferConditions: contractorPath("Offer Conditions", ["offer", "offerId", "conditions"]),
    contractorOfferPositions: contractorPath("Offer Positions", ["offer", "offerId", "positions"]),
    contractorOfferPreview: contractorPath("Offer Submit", ["offer", "offerId", "preview"])
}

export const contractorPaths = {
    contractorOffer: publicPath("Offer", ["offer", "offerId"]),
    contractorOffers: contractorPath("Offers", "offers"),
    contractorOfferStep: contractorPath("Offer Step", ["offer", "offerId", "offerStep"])
    // ...contractorOfferPaths
}

export type OfferPath = keyof typeof contractorOfferPaths

export const projectPaths = {
    newProject: architectPath("New project", ["projects", "new"]),
    editProject: projectsPath("Edit project", ["edit"]),
    newTemplate: architectPath("New template", ["templates", "new"]),
    overview: projectsPath("Project Details"),
    devis: projectsPath("Devis", "devis")
}

export type ProjectPath = keyof typeof projectPaths

export const devisPaths = {
    submitters: devisPath("Submitters", "submitters"),
    conditions: devisPath("Conditions", "conditions"),
    positions: devisPath("Positions", "positions"),
    deductions: devisPath("Deductions", "deductions"),
    offers: devisPath("Offers", "offers"),
    negotiation: devisPath("Negotiation", "negotiation"),
    finalization: devisPath("Finalization", "finalization"),
    prefinalization: devisPath("Finalization", ["finalization", "offerId"])
}
export type DevisPath = keyof typeof devisPaths
export const appPaths = {
    ...demoPaths,
    //TODO: make proper admin path eventualy
    projects: architectPath("Projects", "projects"),
    templates: architectPath("Devis templates", ["templates"]),
    template: architectPath("Devis template", ["templates", "templateId"]),
    contacts: architectPath("Contacts", ["contacts"]),
    home: architectPath("Dashboard", ["home"]),
    partners: architectPath("Partners", ["partners"]),
    directory: architectPath("Contractors directory", ["directory"])
}

export const adminPaths = {
    copies: adminPath("Copies", "copies"),
    changelog: adminPath("Changelog", "changelog"),
    adminDashboard: adminPath("Admin dashboard"),
    adminActivity: adminPath("Admin activity", "activity"),
    devisOverview: adminPath("Devis overview", "overview"),
    managePartners: adminPath("Partners", "partners")
}

export const allPaths = {
    ...projectPaths,
    ...devisPaths,
    ...appPaths,
    ...adminPaths,
    ...contractorPaths
}

export const materializePath = (path: keyof Paths, params: Partial<PathParams>) =>
    materialize(allPaths[path].path, params)

const isPath =
    (accessLevel: AccessLevel) =>
    (url: string): boolean =>
        values(allPaths).find(p => p.accessLevel === accessLevel && p.path === url) !== undefined

export const isContractorPath = isPath("contractor")
export const isPublicPath = isPath("public")

export type Paths = typeof allPaths
