import * as React from "react"
import styled from "styled-components"
import { keys } from "@smartdevis/utils/src/map"
import { BasicTable, EditableTables, ManyTables } from "./DemoTables"
import { VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Button } from "@smartdevis/ui/src/Button"
import { H3 } from "@smartdevis/ui/src/Typography"

const PageLayout = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 140px);
    overflow: hidden;
`

const PageContent = styled.div`
    display: flex;
    height: 100%;
    overflow: scroll;
    flex-direction: column;
    padding: 0 0 0 40px;
    width: 100%;
`

const demos = {
    "Basic tables": BasicTable,
    "Editable tables": EditableTables,
    "Long tables": ManyTables
}

export const DemoView: React.FC = () => {
    const [chosenDemo, setDemo] = React.useState<keyof typeof demos>(keys(demos)[0])
    const Component = demos[chosenDemo]
    return (
        <PageLayout>
            <div>
                {keys(demos).map(key => (
                    <Button
                        btnType={chosenDemo === key ? "primary" : "action"}
                        onClick={() => setDemo(key)}
                        key={key}>
                        {key}
                    </Button>
                ))}
            </div>
            <PageContent>
                <VerticalSpace base="60px" />
                <Component />
            </PageContent>
        </PageLayout>
    )
}

export const DemoSection: React.FC<{ title: string }> = p => (
    <>
        <VerticalSpace base="20px" />
        <H3>{p.title}</H3>
        <VerticalSpace base="10px" />
        {p.children}
    </>
)
