import * as React from "react"
import { RouteParams } from "../../paths"
import { i18n } from "@smartdevis/client/src/services/translations"
import { mkNewSection } from "./DevisSection"
import { asyncConnect } from "../../resolvers"
import { DevisWrapper } from "./Devis"
import { PageHeader } from "../../components/layouts/Header"
import { SubContent } from "../../components/layouts/Content"
import { getStepExitWarningText, useMutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import { mkDeductions, mkDeltasByRef } from "@smartdevis/server/src/models/shared"
import { ExportDeductions } from "../../components/export/ExportButtons"
import { filterObject } from "@smartdevis/utils/src/map"
import { DeductionsList } from "./lists/DeductionsList"
import { Domain } from "@smartdevis/server/src/domain"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { Spacer, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { useUnsavedStatusAsParent } from "../UnsavedStatusProvider"
import { Prompt } from "react-router-dom"

export const DevisDeductions = asyncConnect({
    stateResolvers: ["projectDetails", "devisCollections", "devis", "isDevisReadonly"],
    actions: ["mutate", "submitRoundDelta", "revertRoundDelta"]
})<RouteParams>(p => {
    const { devisId } = p.match.params
    const deltas = mkDeltasByRef(p.devisCollections)
    const sections = filterObject(p.devisCollections.sections, (_, s) => s.type === "deduction")
    const deductions = mkDeductions(p.devisCollections, deltas)

    const sectionMutations = useMutationManagement("sections", sections, p)
    const deductionMutations = useMutationManagement("deductions", deductions, p)

    const unsaved = useUnsavedStatusAsParent()
    return (
        <DevisWrapper current="deductions" {...p}>
            <Prompt when={unsaved.check()} message={getStepExitWarningText()} />
            <SubContent>
                <PageHeader
                    title={i18n("Deductions")}
                    subTitle="Erfassen Sie die Abzüge in Ihrem Devis und definieren Sie, ob sie vom Unternehmer (z.B. Rabatte) oder von Ihnen (z.B. Bauabzüge) bestimmt werden."
                    actionButtons={[
                        <ExportDeductions
                            key="export"
                            projectId={p.projectDetails.projectId}
                            devisId={p.devis.devisId}
                        />
                    ]}
                />
                <Spacer color="grey50" type="horizontal" />
                <VerticalSpace base="16px" />
                <DeductionsList
                    isReadonly={p.isDevisReadonly}
                    itemMutations={deductionMutations}
                    items={deductions}
                    sectionMutations={sectionMutations}
                    sections={sections}
                    deltas={deltas}
                    mkItem={(sectionId, order) => mkDeduction(devisId, sectionId, order)}
                    mkSection={order => mkNewSection(devisId, "deduction", order)}
                />
            </SubContent>
        </DevisWrapper>
    )
})

const mkDeduction = (devisId: string, sectionId: string, order: number): Domain.Deduction => {
    const deductionId = genTemporaryId()
    const createdTs = getTs()
    const sign = "negative"
    return { name: "", deductionId, createdTs, devisId, sectionId, sign, valueType: "percent", value: 0, order }
}
