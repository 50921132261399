import * as React from "react"
import { values } from "@smartdevis/utils/src/map"

import { asyncConnect } from "../../../resolvers"
import { i18n } from "@smartdevis/client/src/services/translations"
import { ErrorView } from "../../Error"
import { DevisWrapper } from "../Devis"
import { devisPaths, RouteParams } from "../../../paths"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import {
    FinalContractView,
    FinalProposalView,
    PrefinalContractView,
    SendContractRequestModal,
    useContract
} from "./DevisFinalization.helpers"
import { Domain } from "@smartdevis/server/src/domain"
import { useAttachmentsManagement } from "../../../components/Attachments"
import { Spinner } from "@smartdevis/ui/src/Spinner"

export const DevisFinalization = asyncConnect({
    stateResolvers: [
        "results",
        "projectDetails",
        "devisCollections",
        "user",
        "devis",
        "contractors",
        "uploadingFiles",
        "projectAttachments"
    ],
    actions: ["updateFinalOffer", "updateRequestsState", "navigate", "uploadFile", "removeAttachment"]
})<RouteParams>(p => {
    const { devisId } = p.devis
    const { projectId } = p.projectDetails
    const request = values(p.devisCollections.requests).find(
        r =>
            r.devisId === devisId &&
            (isOfferInState(r, ["contracted", "final-proposal"]) || r.offerId === p.match.params.offerId)
    )

    const attachments = useAttachmentsManagement("final", p)

    const [showConfirmation, setShowConfirmation] = React.useState(false)
    const [offerMeta, setOfferMeta] = React.useState<Domain.OfferMeta>({
        autoRejectOffers: false,
        clientsRepresentative: "clientsRepresentative",
        contractType: "unit-prices"
    })
    const { contractingState, onContract } = useContract({ ...p, offerMeta }, () =>
        p.navigate(devisPaths.finalization, { projectId, devisId })
    )

    const submitter = request ? p.contractors[request.submitterId] : undefined

    const [checkedRequest, setCheckRequest] = React.useState(false)
    React.useEffect(() => {
        if (!checkedRequest) setTimeout(() => setCheckRequest(true), 5000) // TODO change to something more sophisticated
    }, [checkedRequest])
    if (!request || !submitter) {
        if (!checkedRequest) return <Spinner fullscreen />
        return <ErrorView type="access-denied" text={i18n("No request is yet in the contract phase")} />
    }

    const renderContent = () => {
        if (!request.contractorUser) return null
        switch (request.state.type) {
            case "final-proposal":
                return <FinalProposalView request={request} submitter={submitter} />
            case "contracted":
                return <FinalContractView request={request} submitter={submitter} />
            default:
                return (
                    <PrefinalContractView
                        request={request}
                        attachments={attachments}
                        project={p.projectDetails}
                        submitter={submitter}
                        onContract={(c: Domain.OfferMeta) => {
                            setShowConfirmation(true)
                            setOfferMeta(c)
                        }}
                    />
                )
        }
    }
    return (
        <DevisWrapper current="finalization" {...p}>
            {renderContent()}
            <SendContractRequestModal
                visible={showConfirmation}
                onSubmit={() => onContract(request.offerId)}
                onClose={() => setShowConfirmation(false)}
                submitter={submitter}
                loading={contractingState.type === "Processing"}
            />
        </DevisWrapper>
    )
})
