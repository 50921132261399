import * as React from "react"
import styled from "styled-components"
import { asyncConnect } from "../../resolvers"
import { i18n } from "../../services/translations"
import { AdminContent } from "./Dashboard"
import { Button, IconButton } from "@smartdevis/ui/src/Button"
import { useCloudAction } from "../../hooks/useCloudAction"
import { Domain } from "@smartdevis/server/src/domain"
import { BaseModalProps, ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"
import {
    mkEmailSchema,
    mkFormSchema,
    mkHiddenSchema,
    mkMultiselectSchema,
    mkTextSchema
} from "../../components/forms/formSchemas"
import { F0, State, TypedOmit } from "@smartdevis/utils/src/types"
import { actionValidations } from "@smartdevis/server/src/validations"
import { SubmitButton } from "../../components"
import { StyledForm } from "../../components/forms"
import { StyledFormSchema, useFormHook } from "@smartdevis/forms/src"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { UserLogo } from "../../components/modals/EditUser"
import { filterObject, keys, values } from "@smartdevis/utils/src/map"
import { validateArray, validString } from "@smartdevis/utils/src/validators"
import { FlexRow } from "@smartdevis/ui/src/utils/common"
import { getFullName } from "@smartdevis/server/src/models/user"
import { Table, Row } from "../../components/table/Table"
import { displayCompanyName } from "@smartdevis/server/src/models/projectDetails"
import { mkCell } from "../../components/table/TableCell"
import { calculateOrder, sortSortables } from "@smartdevis/utils/src/comparable"

type PartnerFormPayload = TypedOmit<Domain.Partner, "partnerId" | "order">

export const ChangelogDisplay = styled.div`
    white-space: pre-wrap;
`

const mkPartnerRow = (
    partner: Domain.Partner,
    actions: {
        manageProducts: F0
        edit: F0
        remove: F0
    }
): Row<"partner", Domain.Partner> => ({
    mode: "static",
    type: "partner",
    rowId: partner.partnerId,
    grid: [3, 3, 3, 4, 3, 4, 1.5, 2.5],
    cells: [
        mkCell(displayCompanyName(partner)),
        mkCell(getFullName(partner)),
        mkCell(`Groups: ${partner.groups.length ? partner.groups.join(", ") : "No groups"}`),
        mkCell(`Categories: ${partner.categories.length ? partner.categories.join(", ") : "No categories"}`),
        mkCell(<img style={{ maxHeight: "48px" }} src={partner.logoUrl} />, ["noPadding"]),
        mkCell(
            <IconButton icon="IconFolder" onClick={actions.manageProducts}>
                {i18n("Manage products")}
            </IconButton>
        ),
        mkCell(
            <IconButton icon="EditWhite" onClick={actions.edit}>
                {i18n("Edit")}
            </IconButton>
        ),
        mkCell(
            <IconButton icon="Minus" onClick={actions.remove}>
                {" "}
                {i18n("Remove")}{" "}
            </IconButton>
        )
    ]
})

export const ManagePartners = asyncConnect({
    stateResolvers: ["results", "partners"],
    actions: ["mutatePartners"]
})(p => {
    const [modalVisible, setModalVisible] = React.useState(false)
    const [removeModalVisible, setRemoveModalVisible] = React.useState(false)
    const [manageProductsModalVisible, setManageProductsModalVisible] = React.useState(false)
    const [managedPartner, setManagedPartner] = React.useState<Domain.Partner | undefined>()
    const [editPartnerId, setEditPartnerId] = React.useState("")

    const editPartner = editPartnerId ? p.partners[editPartnerId] : null

    const { actionState, onSubmit } = useCloudAction(
        actionId => {
            if (managedPartner)
                p.mutatePartners({
                    ...managedPartner,
                    actionId,
                    mutationType: "remove"
                })
        },
        p.results,
        () => {
            setRemoveModalVisible(false)
        }
    )

    const sortedPartners = sortSortables(values(p.partners))

    return (
        <>
            <AdminContent
                subTitle={i18n("Manage partners")}
                actionButtons={[
                    <IconButton key="create" icon="IconCreated" onClick={() => setModalVisible(true)}>
                        {i18n("Create")}
                    </IconButton>
                ]}>
                <Table
                    draggable
                    rows={sortedPartners.map(partner =>
                        mkPartnerRow(partner, {
                            manageProducts: () => {
                                setEditPartnerId(partner.partnerId)
                                setManageProductsModalVisible(true)
                            },
                            edit: () => {
                                setManagedPartner(partner)
                                setModalVisible(true)
                            },
                            remove: () => {
                                setManagedPartner(partner)
                                setRemoveModalVisible(true)
                            }
                        })
                    )}
                    onDrag={(_type, index, ids) => {
                        const id = ids.draggedId
                        const draggedPartner = p.partners[id]
                        const order = calculateOrder(sortedPartners, sortedPartners.indexOf(draggedPartner), index)

                        p.mutatePartners({
                            ...draggedPartner,
                            order,
                            actionId: genTemporaryId(),
                            mutationType: "update"
                        })
                    }}
                />
            </AdminContent>
            {modalVisible && (
                <CreatePartnerModal
                    visible={modalVisible}
                    partner={managedPartner}
                    onClose={() => {
                        setModalVisible(false)
                        setManagedPartner(undefined)
                    }}
                />
            )}
            {removeModalVisible && (
                <ConfirmationModal
                    onSubmit={onSubmit}
                    cancelText={i18n("Cancel")}
                    header={i18n("Remove partner")}
                    loading={actionState.type === "Processing"}
                    contentText={i18n("Are you sure you want to delete this partner?")}
                    onClose={() => setRemoveModalVisible(false)}
                    submitText={i18n("Confirm")}
                    visible={removeModalVisible}
                />
            )}
            {manageProductsModalVisible && editPartner && (
                <ManageProductsModal
                    partner={editPartner}
                    onClose={() => {
                        setManageProductsModalVisible(false)
                        setEditPartnerId("")
                    }}
                    visible={manageProductsModalVisible}
                />
            )}
        </>
    )
})

const Row = styled(FlexRow)`
    align-items: center;
    border-bottom: 1px solid grey;
`

const Field = styled.div`
    flex: 1;
`

const getSchema = () =>
    mkFormSchema<PartnerFormPayload>(actionValidations.mutatePartners, {
        logoUrl: mkHiddenSchema("Logo"),
        companyName: mkTextSchema("Company Name"),
        companyName2: mkTextSchema("Company Name 2"),
        name: mkTextSchema("Name"),
        surname: mkTextSchema("Surname"),
        email: mkEmailSchema("Email"),
        phoneNumber: mkTextSchema("Phone Number"),
        street: mkTextSchema("Street"),
        postalCode: mkTextSchema("Postal Code"),
        city: mkTextSchema("city"),
        createdTs: mkHiddenSchema(""),
        products: mkHiddenSchema(""),
        categories: mkMultiselectSchema("Categories", [], {
            validators: [validateArray<string>(validString)],
            placeholder: i18n("Type to add options"),
            creatable: true
        }),
        groups: mkMultiselectSchema("Groups", [], {
            validators: [validateArray<string>(validString)],
            placeholder: i18n("Type to add options"),
            creatable: true
        })
    })

type LogoState = State<"file", { file: File }> | State<"url", { url: string }> | State<"none">

export const CreatePartnerModal = asyncConnect({
    stateResolvers: ["results", "partners"],
    actions: ["mutatePartnerWithUpload"],
    renderLoading: () => <>{null}</>
})<BaseModalProps & { partner?: Domain.Partner }>(p => {
    const [logo, setLogo] = React.useState<LogoState>(
        p.partner && p.partner.logoUrl ? { type: "url", url: p.partner.logoUrl } : { type: "none" }
    )

    const { actionState, onSubmit } = useCloudAction<PartnerFormPayload>(
        (actionId, res) => {
            if (p.partner)
                p.mutatePartnerWithUpload({
                    ...res,
                    partnerId: p.partner.partnerId,
                    actionId,
                    order: p.partner.order,
                    mutationType: "update",
                    logo: logo.type === "file" ? logo.file : null,
                    logoUrl: logo.type === "url" ? logo.url : ""
                })
            else
                p.mutatePartnerWithUpload({
                    ...res,
                    partnerId: genTemporaryId(),
                    actionId,
                    order: values(p.partners).length,
                    mutationType: "create",
                    logo: logo.type === "file" ? logo.file : null,
                    logoUrl: logo.type === "url" ? logo.url : ""
                })
        },
        p.results,
        p.onClose
    )

    const { formViewProps, handleSubmit, submitted, result } = useFormHook<PartnerFormPayload>({
        schema: getSchema(),
        onSubmit,
        initialValue: p.partner || { logoUrl: "" }
    })

    const copy = i18n(actionState.type !== "Processing" ? "Save" : "Saving")

    const styledSchema: StyledFormSchema<PartnerFormPayload> = [
        "logoUrl",
        "companyName",
        "companyName2",
        "name",
        "surname",
        "email",
        "phoneNumber",
        "street",
        "postalCode",
        "city",
        "categories",
        "groups"
    ]
    return (
        <>
            <Modal
                size="m"
                header={i18n("Create partner")}
                visible={!!p.visible}
                onClose={p.onClose}
                footer={
                    <>
                        <Button onClick={p.onClose} btnType="secondary">
                            {i18n("cancel")}
                        </Button>
                        <SubmitButton
                            disabled={(submitted && result.type === "Err") || actionState.type === "Processing"}
                            loading={actionState.type === "Processing"}
                            onClick={handleSubmit}>
                            {i18n(copy)}
                        </SubmitButton>
                    </>
                }>
                <StyledForm {...formViewProps} styledSchema={styledSchema} />
                <UserLogo
                    onChange={file => setLogo({ type: "file", file })}
                    logoState={logo}
                    onDelete={() => setLogo({ type: "none" })}
                />
            </Modal>
        </>
    )
})
type FormPayload = { name: string; url: string }

export const ManageProductsModal = asyncConnect({
    stateResolvers: ["results"],
    actions: ["mutatePartnerWithUpload"],
    renderLoading: () => <>{null}</>
})<BaseModalProps & { partner: Domain.Partner }>(p => {
    const { actionState, onSubmit } = useCloudAction<FormPayload>((actionId, res) => {
        p.mutatePartnerWithUpload({
            ...p.partner,
            actionId,
            mutationType: "update",
            logo: null,
            products: { ...p.partner.products, [res.name]: res.url }
        })
    }, p.results)

    const { onSubmit: onRemove } = useCloudAction<string>((actionId, name) => {
        p.mutatePartnerWithUpload({
            ...p.partner,
            actionId,
            mutationType: "update",
            logo: null,
            products: filterObject(p.partner.products, k => k !== name)
        })
    }, p.results)

    const getFormSchema = () => ({ name: mkTextSchema("Name"), url: mkTextSchema("Url") })

    const { formViewProps, handleSubmit, submitted, result, resetState } = useFormHook<FormPayload>({
        schema: getFormSchema(),
        onSubmit,
        initialValue: {}
    })

    const copy = i18n(actionState.type !== "Processing" ? "Add" : "Processing")

    const styledSchema: StyledFormSchema<{ name: string; url: string }> = ["name", "url"]
    const { products } = p.partner
    return (
        <Modal
            size="m"
            header={i18n("Manage products")}
            visible={!!p.visible}
            onClose={p.onClose}
            footer={
                <>
                    <Button onClick={p.onClose} btnType="secondary">
                        {i18n("Close")}
                    </Button>
                    <SubmitButton
                        disabled={(submitted && result.type === "Err") || actionState.type === "Processing"}
                        loading={actionState.type === "Processing"}
                        onClick={e => {
                            handleSubmit(e)
                            resetState()
                        }}>
                        {i18n(copy)}
                    </SubmitButton>
                </>
            }>
            <ProductsWrapper>
                {keys(p.partner.products).map(k => (
                    <Product key={k} name={k} url={products[k]} onRemove={() => onRemove(k)} />
                ))}
            </ProductsWrapper>
            <StyledForm {...formViewProps} styledSchema={styledSchema} />
        </Modal>
    )
})

const ProductsWrapper = styled.div``

const Product: React.FC<{ name: string; url: string; onRemove: F0 }> = p => (
    <FlexRow style={{ height: "60px" }}>
        <Field>{p.name}</Field>
        <Field>{p.url}</Field>
        <Field>
            <IconButton icon="Minus" onClick={p.onRemove}>
                {i18n("Remove")}
            </IconButton>
        </Field>
    </FlexRow>
)
