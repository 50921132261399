import * as React from "react"
import styled from "styled-components"
import { Margin } from "@smartdevis/ui/src/utils/common"
import { themeConfig } from "@smartdevis/ui/src/utils/theme"
import { i18n } from "../services/translations"
import { H2, H4 } from "@smartdevis/ui/src/Typography"
import { useClientWidth } from "../hooks/utilityHooks"
import { Logo } from "../components/layouts/Header"

const MIN_WIDTH = 980

export const ResolutionGuard: React.FC = p => {
    const clientWidth = useClientWidth()
    return (
        <>
            <Wrapper show={clientWidth <= MIN_WIDTH}>
                <H2>{i18n("Sorry, we're working on it.")}</H2>
                <Margin values="-10px 30px 0">
                    <H4>{i18n("Small screen support is coming soon.")}</H4>
                    <Margin values="30px 0">
                        <Logo bigSize prefix="dev" />
                    </Margin>
                </Margin>
            </Wrapper>
            {p.children}
        </>
    )
}

const Wrapper = styled.div<{ show?: boolean }>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: ${p => (p.show ? "flex" : "none")};
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    z-index: ${themeConfig("zIndexMax")};
    background-color: #fafafa;
    h2 {
        text-align: left;
        margin-bottom: 30px;
        padding: 0 30px;
    }
`
