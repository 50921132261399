import * as React from "react"
import { getFullName } from "@smartdevis/server/src/models/user"
import { BaseModalProps, ConfirmationCheckModal, ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"
import { i18n } from "@smartdevis/client/src/services/translations"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"
import { TextareaInput } from "@smartdevis/ui/src/Inputs"
import { H5, Label, Pre } from "@smartdevis/ui/src/Typography"
import { FlexColumn, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Tag } from "@smartdevis/ui/src/Tag"
import {
    getPastOfferAttachments,
    getStaticComments,
    getStaticPositionComments
} from "@smartdevis/client/src/utils/offer"
import { DatePicker } from "@smartdevis/ui/src/DatePicker"
import { values } from "@smartdevis/utils/src/map"
import { F1, F0, F2 } from "@smartdevis/utils/src/types"
import { Button } from "@smartdevis/ui/src/Button"
import { AttachmentsPreviewList } from "../../../components/Attachments"
import { getStorage } from "@smartdevis/client/src/services/firebase"
import { LongTile } from "@smartdevis/ui/src/LongTile"
import { formatDateISO } from "@smartdevis/utils/src/date"
import { Domain } from "@smartdevis/server/src/domain"

const ConfirmInvitation: React.FC<
    BaseModalProps & {
        submitters: Domain.Contractor[]
        devis: Domain.Devis
        onSubmit: F2<string, number | undefined>
        loading: boolean
        hasOffers: boolean
    }
> = p => {
    const [message, setMessage] = React.useState("")
    const [entryDate, setEntryDate] = React.useState(p.devis.entryTs)
    const info = i18n(
        "Invited contractors will recieve an email, with the link to the devis, project specification, and an optional message."
    )
    const warning = i18n(
        "Once contractors are invited, parts of the system like possitions, deductions, and conditions will become readonly."
    )
    return (
        <ConfirmationCheckModal
            onSubmit={() => p.onSubmit(message, entryDate)}
            onClose={p.onClose}
            visible={p.visible}
            submitButtonProps={{ loading: p.loading }}
            submitText={i18n("Invite")}
            cancelText={i18n("Cancel")}
            confirmationText={i18n("I confirm that I want to invite these contractors")}>
            <Label>{i18n("You are about to invite selected contractors:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.submitters.map(s => s.companyName || getFullName(s) || s.email).join("\n")}</Pre>
            <VerticalSpace base="8px" />
            <Label>{i18n("To submit an offer for:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.devis.workCategory}</Pre>
            <VerticalSpace base="8px" />
            <InfoBox type="info">{info}</InfoBox>
            {!p.hasOffers && (
                <>
                    <VerticalSpace base="8px" />
                    <Label>{i18n("Entry date:")}</Label>
                    <DatePicker
                        value={entryDate || undefined}
                        placeholder={i18n("Select date")}
                        onChange={setEntryDate}
                    />
                </>
            )}
            <VerticalSpace base="8px" />
            <Label htmlFor="message">{i18n("Message to contractors (optional):")}</Label>
            <VerticalSpace base="8px" />
            <TextareaInput id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} />
            <VerticalSpace base="8px" />
            <InfoBox type="warning">{warning}</InfoBox>
        </ConfirmationCheckModal>
    )
}

const ConfirmReminder: React.FC<
    BaseModalProps & { submitters: Domain.Contractor[]; devis: Domain.Devis; onSubmit: F1<string>; loading: boolean }
> = p => {
    const [message, setMessage] = React.useState("")
    const info = i18n(
        "Selected contractors will recieve an email, with the link to the devis, project specification, and an optional message."
    )
    return (
        <Modal
            footer={
                <>
                    <Button btnType="secondary" onClick={p.onClose}>
                        {i18n("Cancel")}
                    </Button>
                    <Button btnType="primary" onClick={() => p.onSubmit(message)} loading={p.loading}>
                        {i18n("Send message")}
                    </Button>
                </>
            }
            onClose={p.onClose}
            visible={p.visible}>
            <Label>{i18n("You are about to send reminder to selected contractors:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.submitters.map(s => s.companyName || getFullName(s) || s.email).join("\n")}</Pre>
            <VerticalSpace base="8px" />
            <InfoBox type="info">{info}</InfoBox>
            <VerticalSpace base="8px" />
            <Label htmlFor="message">{i18n("Message to contractors (optional):")}</Label>
            <VerticalSpace base="8px" />
            <TextareaInput id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} />
            <VerticalSpace base="8px" />
        </Modal>
    )
}

const CancelOfferRequest: React.FC<
    BaseModalProps & { submitters: Domain.Contractor[]; onSubmit: F1<string>; loading: boolean }
> = p => {
    const [message, setMessage] = React.useState("")
    const warning = i18n(
        "If you cancel this offer, you will not be able to invite this contractor again. Instead, you can start the second round in order to request a better offer."
    )

    return (
        <ConfirmationCheckModal
            onSubmit={() => p.onSubmit(message)}
            onClose={p.onClose}
            visible={p.visible}
            submitButtonProps={{ loading: p.loading }}
            submitText={i18n("Proceed")}
            cancelText={i18n("Cancel")}
            confirmationText={i18n("I confirm that I want to cancel an offer request")}>
            <Label>{i18n("You are about to cancel the request for offer from:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.submitters.map(s => s.companyName || getFullName(s) || s.email).join("\n")}</Pre>
            <VerticalSpace base="8px" />
            <InfoBox type="warning">{warning}</InfoBox>
            <VerticalSpace base="8px" />
            <Label htmlFor="message">{i18n("Message to contractors (optional):")}</Label>
            <VerticalSpace base="8px" />
            <TextareaInput id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} />
        </ConfirmationCheckModal>
    )
}

const DisplayComment: React.FC<
    BaseModalProps & {
        request?: Domain.ArchitectOfferRequest
        submitter?: Domain.Contractor
        devisCollections: Domain.DevisCollections
    }
> = p => {
    if (!p.request || !p.submitter) return null
    const comments = getStaticComments(p.request)
    const usr = p.request.contractorUser ?? p.submitter
    const positionComments = p.request
        ? values(p.devisCollections.positions)
              .map(pos => {
                  const posComments = getStaticPositionComments(
                      p.request as Domain.ArchitectOfferRequest,
                      pos.positionId
                  )
                  return {
                      label: pos.name,
                      comments: posComments,
                      number: pos.number
                  }
              })
              .filter(v => !!v.comments.length)
        : []
    return (
        <Modal
            onClose={p.onClose}
            visible={p.visible}
            header={i18n("Comment to the offer created by $1", usr.companyName || getFullName(usr))}>
            <VerticalSpace base="8px" />
            <H5>{i18n("General offer comments")}</H5>
            <VerticalSpace base="8px" />
            {comments.map(c => (
                <React.Fragment key={c.label}>
                    <Label>{c.label}</Label>
                    <Pre>{c.value}</Pre>
                    <VerticalSpace base="16px" />
                </React.Fragment>
            ))}
            {positionComments.map(pos => (
                <React.Fragment key={pos.label}>
                    <H5>
                        {pos.number} {pos.label}
                    </H5>
                    <VerticalSpace base="8px" />
                    {pos.comments.map(c => (
                        <>
                            <Label>{c.label}</Label>
                            <Pre key={c.label}>{c.value}</Pre>
                        </>
                    ))}
                    <VerticalSpace base="16px" />
                </React.Fragment>
            ))}
        </Modal>
    )
}

const DisplayOfferAttachments: React.FC<
    BaseModalProps & { request?: Domain.ArchitectOfferRequest; submitter?: Domain.Contractor }
> = p => {
    if (!p.request || !p.submitter) return null
    const attachments = getPastOfferAttachments(p.request)
    const usr = p.request.contractorUser ?? p.submitter
    return (
        <Modal
            onClose={p.onClose}
            visible={p.visible}
            header={i18n("Contractor attachments to the offer created by $1", usr.companyName || getFullName(usr))}>
            <VerticalSpace base="8px" />
            {attachments.map(a => (
                <React.Fragment key={a.label}>
                    <Label>{a.label}</Label>
                    <AttachmentsPreviewList attachments={values(a.value)} />
                    <VerticalSpace base="16px" />
                </React.Fragment>
            ))}
        </Modal>
    )
}

const DisplayDevisPositionsFiles: React.FC<
    BaseModalProps & { request?: Domain.ArchitectOfferRequest; submitter?: Domain.Contractor }
> = p => {
    const attachments = []
    if (!p.request || !p.submitter) return null
    if (p.request.offerProposal)
        attachments.push({ label: i18n("First round offer proposal"), value: p.request.offerProposal })
    if (p.request.roundOfferProposals && values(p.request.roundOfferProposals).length) {
        attachments.push({
            label: i18n("Negotiations round offer proposal"),
            value: values(p.request.roundOfferProposals)[0] as Domain.AttachmentData
        })
    }
    const download = (path: string) => () => window.open(getStorage().getFileUrl(path), "_blank")

    return (
        <Modal onClose={p.onClose} visible={p.visible} header={i18n("Contractor offer proposals")}>
            <VerticalSpace base="8px" />
            {attachments.map(a => (
                <FlexColumn key={a.label}>
                    <LongTile
                        id={a.value.attachmentId}
                        title={a.label}
                        titleLabel={i18n("File name")}
                        descriptionItems={[
                            { key: i18n("Type"), value: i18n("File") },
                            { key: i18n("Creation date"), value: formatDateISO(a.value.createdTs) }
                        ]}
                        loading={false}
                        icon="FileTextPdf"
                        buttonTitle={i18n("Download")}
                        onClick={download(a.value.path)}
                    />
                    <VerticalSpace base="16px" />
                </FlexColumn>
            ))}
        </Modal>
    )
}

const StartSecondRound: React.FC<BaseModalProps & { submitters: Domain.Contractor[]; onSubmit: F0; loading: boolean }> =
    p => {
        const info1 = i18n("Once the second round has been initiated only selected contractors can submit offers,")
        const info2 = i18n("those not selected to the second round are being rejected from the offering.")
        return (
            <ConfirmationModal
                onSubmit={p.onSubmit}
                onClose={p.onClose}
                visible={p.visible}
                loading={p.loading}
                submitText={i18n("Start second round")}
                cancelText={i18n("Cancel")}>
                <Label>{i18n("Your are about to start a second round with selected contractors:")}</Label>
                <VerticalSpace base="8px" />
                <Pre>{p.submitters.map(s => s.companyName || getFullName(s) || s.email).join("\n")}</Pre>
                <VerticalSpace base="8px" />
                <InfoBox type="warning">
                    {info1} {info2}
                </InfoBox>
            </ConfirmationModal>
        )
    }

const SecondRoundInstructions: React.FC<BaseModalProps & { onClose: F0; devisName: string }> = p => {
    const info1 = i18n("The second round is a flexible way to adjust the offer.")
    const info2 = i18n("Work with positions and deductions you want to be updated by the contractor.")
    return (
        <ConfirmationModal
            header={i18n("$1 - Round 2", p.devisName)}
            onSubmit={p.onClose}
            visible={p.visible}
            submitText={i18n("Got it")}>
            <Label>
                {info1} {info2}
            </Label>
            <VerticalSpace base="8px" />
            <Label>{i18n("Conditions, positions and deductions can be:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>
                <Tag type="added">{i18n("Added")}</Tag>
                {"  "}
                <Tag type="edited">{i18n("Edited")}</Tag>
                {"  "}
                <Tag type="removed">{i18n("Removed")}</Tag>
            </Pre>
        </ConfirmationModal>
    )
}

const ConfirmSendingRound: React.FC<
    BaseModalProps & {
        submitters: Domain.Contractor[]
        onSubmit: F2<string, number>
        loading: boolean
        devisEntryDate: number
    }
> = p => {
    const warning = i18n(
        "If you send the revised offer, conditions, positions and deductions will once again become readonly."
    )
    const [entryDate, setEntryDate] = React.useState(p.devisEntryDate)
    const [message, setMessage] = React.useState("")
    return (
        <ConfirmationCheckModal
            onSubmit={() => p.onSubmit(message, entryDate || p.devisEntryDate)}
            onClose={p.onClose}
            visible={p.visible}
            submitButtonProps={{ loading: p.loading }}
            submitText={i18n("Send")}
            cancelText={i18n("Cancel")}
            confirmationText={i18n("I confirm that I want to send revised offer")}>
            <Label>{i18n("You are about to send second round offer to:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.submitters.map(s => s.companyName || getFullName(s) || s.email).join("\n")}</Pre>
            <VerticalSpace base="8px" />
            <InfoBox type="warning">{warning}</InfoBox>
            <VerticalSpace base="8px" />
            <Label>{i18n("Updated entry date:")}</Label>
            <VerticalSpace base="8px" />
            <DatePicker
                value={entryDate ? entryDate : undefined} // Zero is undefined but will be defaultized to p.devisEntryDate
                placeholder={i18n("Select date")}
                onChange={setEntryDate}
            />
            <VerticalSpace base="8px" />
            <Label>{i18n("Message to contractors (optional):")}</Label>
            <VerticalSpace base="8px" />
            <TextareaInput id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} />
            <VerticalSpace base="8px" />
        </ConfirmationCheckModal>
    )
}

const ConfirmNegotiation: React.FC<
    BaseModalProps & { submitters: Domain.Contractor[]; onSubmit: F0; loading: boolean }
> = p => {
    const warning = i18n(`Negotiation is the last moment for changes.
        In the negotitation, you can:
        - adjust prices provided by the contractor
        - export final offers to the pdf to share it with the client
        `)

    return (
        <ConfirmationModal
            onSubmit={p.onSubmit}
            onClose={p.onClose}
            visible={p.visible}
            submitButtonProps={{ loading: p.loading }}
            submitText={i18n("Start negotiation")}
            cancelText={i18n("Cancel")}>
            <Label>{i18n("Your are about to start final round with:")}</Label>
            <VerticalSpace base="8px" />
            <Pre>{p.submitters.map(s => s.companyName || getFullName(s) || s.email).join("\n")}</Pre>
            <VerticalSpace base="8px" />
            <InfoBox type="warning">{warning}</InfoBox>
            <VerticalSpace base="8px" />
        </ConfirmationModal>
    )
}

export const DevisOffersModals = {
    StartSecondRound,
    DisplayComment,
    CancelOfferRequest,
    ConfirmInvitation,
    ConfirmReminder,
    ConfirmSendingRound,
    ConfirmNegotiation,
    SecondRoundInstructions,
    DisplayOfferAttachments,
    DisplayDevisPositionsFiles
}
