import * as React from "react"
import { EditUserModal } from "../components/modals/EditUser"
import { asyncConnect } from "../resolvers"
import { validateUserWithBusiness } from "@smartdevis/server/src/models/user"
import { isFetched } from "@smartdevis/utils/src/async"
import { _nocall } from "@smartdevis/utils/src/misc"
import { isErr } from "@smartdevis/utils/src/result"
import { F0 } from "@smartdevis/utils/src/types"

type EditUserState = {
    isModalOpen: boolean
    openEditUserModal: F0
}
const EditUserContext = React.createContext<EditUserState>({
    openEditUserModal: _nocall,
    isModalOpen: false
})

export const useUserEdit = () => {
    const ctx = React.useContext(EditUserContext)
    return { openEditUserModal: ctx.openEditUserModal }
}

export const EditUserProvider: React.FC = asyncConnect({ stateResolvers: ["auth", "asyncUser"] })(p => {
    const [open, setOpen] = React.useState(false)

    const openEditUserModal = () => setOpen(true)
    const isAfterLoggingIn = React.useRef(false)

    React.useEffect(() => {
        if (
            isAfterLoggingIn.current &&
            p.auth.type === "Authenticated" &&
            isFetched(p.asyncUser) &&
            isErr(validateUserWithBusiness(p.asyncUser.value))
        ) {
            setOpen(true)
            isAfterLoggingIn.current = false
        }
        if (p.asyncUser.type === "Fetching") isAfterLoggingIn.current = true
    }, [p.asyncUser.type])

    return (
        <EditUserContext.Provider value={{ openEditUserModal, isModalOpen: open }}>
            {p.children}
            {isFetched(p.asyncUser) && <EditUserModal visible={open} onClose={() => setOpen(false)} />}
        </EditUserContext.Provider>
    )
})
