import { LocationChangeAction } from "connected-react-router"
import { ReducerDelta, getReducer } from "../utils/store"
import { AuthState } from "./authState"
import { ClientActions } from "."
import { Action } from "@smartdevis/utils/src/actions"

export const mkReducer = <TState, TActions extends Action = ClientActions | LocationChangeAction>(
    reducerBase: ReducerDelta<TState, TActions, AuthState>
) => getReducer(reducerBase)
// DISCLAIMER: mkReducer cannot be placed in ./index.tsx -
// otherwise tsnode (used by jest) causes following error:
// TypeError: utils_2.mkReducer is not a function
