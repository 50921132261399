import {
    combineResolvers,
    combineResolversWithProps,
    mkResolver,
    resolveFromMap,
    resolveOptionalPathParam,
    resolvePathParam
} from "./resolverUtils"
import { isFetched, isNotFetched, mkFetched, mkFetching } from "@smartdevis/utils/src/async"
import { clientActions } from "../store"
import { PathParams } from "../paths"

type ContractorOfferProps = Partial<Pick<PathParams, "offerId">>

export const resolveArchitectContractors = mkResolver(
    s => s.data.contractors,
    s => (isNotFetched(s.data.contractors) ? [clientActions.fetchContractors()] : [])
)

export const resolveContractorOffers = mkResolver(
    s => s.data.contractorOffers,
    s => (isNotFetched(s.data.contractorOffers) ? [clientActions.fetchContractorOffers()] : [])
)

export const resolveContractorOffer = combineResolversWithProps<ContractorOfferProps>()(
    resolveContractorOffers,
    resolvePathParam("offerId"),
    (offers, offerId) => mkResolver(() => resolveFromMap(offers, offerId, mkFetching))
)

export const resolvePublicOffer = combineResolversWithProps<ContractorOfferProps>()(
    resolvePathParam("offerId"),
    offerId => {
        return mkResolver(
            s => s.data.contractorPublicOffer,
            s => (isNotFetched(s.data.contractorPublicOffer) ? [clientActions.fetchPublicOffer(offerId)] : [])
        )
    }
)

export const resolveOptionalPublicOffer = combineResolvers(resolveOptionalPathParam("offerId"), offerId => {
    return mkResolver(
        s => (offerId ? s.data.contractorPublicOffer : mkFetched(undefined)),
        s =>
            offerId &&
            (isNotFetched(s.data.contractorPublicOffer) ||
                (isFetched(s.data.contractorPublicOffer) && s.data.contractorPublicOffer.value.offerId !== offerId))
                ? [clientActions.fetchPublicOffer(offerId)]
                : []
    )
})
