import * as React from "react"
import styled from "styled-components"
import { withRouter } from "react-router"

import { RouteParams, projectPaths, devisPaths, adminPaths, contractorPaths } from "../../paths"
import { matchesPath } from "../../utils/router"
import { AppHeader } from "./Header"
import { themeMedia, themeConfig } from "@smartdevis/ui/src/utils/theme"
import { UpdateReloadBanner } from "./UpdateReloadBanner"
import { values, omitObject, pickObject } from "@smartdevis/utils/src/map"

const PageBase = styled.div`
    margin-top: ${themeConfig("headerHeight")};
    min-height: calc(100vh - ${themeConfig("headerHeight")});
`

const FullPage = styled(PageBase)`
    width: 100%;
    padding: 0 ${themeConfig("contentPadding")} ${themeConfig("contentPadding")};
`

const ContentWrapper = styled.div`
    padding: 0;

    ${themeMedia("max", "xs")} {
        margin: 0;
    }
`

export const SubContent = styled.div`
    max-width: ${themeConfig("maxWidth")};
    margin: auto;
`

type ContentProps = {
    preTitle?: string
    title?: string
    subTitle?: string
}

export const ArchitectContentView: React.FC<ContentProps & RouteParams> = p => {
    const isProjectPath = values({ ...projectPaths, ...devisPaths }).some(path =>
        matchesPath(path.path, p.location.pathname, true)
    )
    const isDevisPath = values(devisPaths).some(path => matchesPath(path.path, p.location.pathname, true))

    const isAdminPath = values(omitObject(adminPaths, ["adminDashboard"])).some(path =>
        matchesPath(path.path, p.location.pathname, true)
    )

    return (
        <>
            <AppHeader
                backTo={isAdminPath ? "admin" : isDevisPath ? "project" : isProjectPath ? "projects" : "logo"}
                {...pickObject(p, ["title", "subTitle", "preTitle"])}
                devisId={p.match.params.devisId}
                projectId={p.match.params.projectId}
                path={p.location.pathname}
            />

            <FullPage>
                <UpdateReloadBanner />
                <ContentWrapper>{p.children}</ContentWrapper>
            </FullPage>
        </>
    )
}

export const EmptyContentView: React.FC<ContentProps> = p => {
    return (
        <>
            <AppHeader
                backTo="none"
                {...pickObject(p, ["title", "subTitle", "preTitle"])}
                path={window.location.pathname}
            />
            <FullPage>
                <UpdateReloadBanner />
                <ContentWrapper>{p.children}</ContentWrapper>
            </FullPage>
        </>
    )
}

export const ContractorContentView: React.FC<ContentProps & RouteParams> = p => {
    const isDashboard = matchesPath(p.location.pathname, contractorPaths.contractorOffers.path, true)
    return (
        <>
            <AppHeader
                backTo={isDashboard ? "logo" : "offers"}
                {...pickObject(p, ["title", "subTitle", "preTitle"])}
                path={p.location.pathname}
            />

            <FullPage>
                <UpdateReloadBanner />
                <ContentWrapper>{p.children}</ContentWrapper>
            </FullPage>
        </>
    )
}

export const ContractorContent = withRouter(ContractorContentView)
export const ArchitectContent = withRouter(ArchitectContentView)
export const EmptyContent = EmptyContentView
