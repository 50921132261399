import * as React from "react"
import styled from "styled-components"
import { Prompt } from "react-router-dom"
import { appPaths, RouteParams } from "../../paths"
import { ArchitectContent, SubContent } from "../../components/layouts/Content"
import { i18n } from "@smartdevis/client/src/services/translations"
import { NewDevisTemplateModal } from "./ProjectsModals"
import { asyncConnect } from "../../resolvers"
import { useCloudAction } from "../../hooks/useCloudAction"
import { Domain } from "@smartdevis/server/src/domain"
import { PageHeader } from "../../components/layouts/Header"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { values } from "@smartdevis/utils/src/map"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { Asset } from "@smartdevis/ui/src/Asset"
import { DropdownMenuOption, Dropdown, mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Row, Table } from "../../components/table/Table"
import { mkCell, mkCellEmpty } from "../../components/table/TableCell"
import { F0, ValueState } from "@smartdevis/utils/src/types"
import { Button } from "@smartdevis/ui/src/Button"
import { FlexRow, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { isOk } from "@smartdevis/utils/src/result"
import { ConfirmationModal, Modal } from "@smartdevis/ui/src/Modal"
import { isDefined } from "@smartdevis/utils/src/misc"
import { Pre } from "@smartdevis/ui/src/Typography"
import { DevisTemplateView } from "./DevisTemplate"
import { useUnsavedStatusAsParent } from "../UnsavedStatusProvider"
import { getStepExitWarningText } from "@smartdevis/client/src/utils/devisHelpers"
import { usleep } from "@smartdevis/utils/src/async"
import { P } from "@smartdevis/ui/src/Typography"

const mkDevisTeplateRow = (
    t: Domain.WorkDescriptionTemplate,
    onClick: F0,
    options: DropdownMenuOption[] | null
): Row<"template"> => ({
    type: "template",
    grid: [22, 2],
    mode: "static",
    rowId: t.id.toString(),
    onClick,
    visuals: ["clickable"],
    cells: options
        ? [
              mkCell(t.workCategory),
              mkCell(
                  <Dropdown options={options}>
                      <Asset name="DotsFilled" size="icon" />
                  </Dropdown>
              )
          ]
        : [mkCell(t.workCategory)]
})

const headerRow = (): Row<"header"> => ({
    type: "header",
    grid: [22, 2],
    mode: "static",
    rowId: "header",
    visuals: ["header"],
    cells: [mkCell(i18n("Name")), mkCellEmpty()]
})

export const ListDevisTemplateView = asyncConnect({
    stateResolvers: [
        "results",
        "devisTemplates",
        "auth",
        "userBasedWorkDescriptionTemplates",
        "predefinedWorkDescriptionTemplates"
    ],
    actions: ["mutate", "navigate"]
})<RouteParams>(p => {
    const { templateId } = p.match.params
    const [devisTemplateModalVisible, setDevisTemplateModalVisible] = React.useState(false)
    const [templateToRemove, setTemplateToRemove] = React.useState<Domain.DevisTemplate | null>(null)
    const [newTemplateId, setTemplateId] = React.useState<ValueState<"create" | "update", string>>({
        type: "create",
        value: genTemporaryId()
    })

    const { create, update, remove } = prepareMutation("devisTemplates", p.mutate)

    const { onSubmit: createDevisTemplate, actionState: devisTemplateActionState } = useCloudAction<string>(
        (actionId, workCategory) =>
            newTemplateId.type === "create"
                ? create(
                      {
                          templateId: newTemplateId.value,
                          workCategory,
                          positions: {},
                          sections: {},
                          attachments: {},
                          createdTs: getTs()
                      },
                      actionId
                  )
                : update(p.devisTemplates[newTemplateId.value], actionId)({ workCategory }),
        p.results,
        result =>
            isOk(result) && newTemplateId.type === "create"
                ? p.navigate(appPaths.template, { templateId: result.value })
                : null
    )

    const unsaved = useUnsavedStatusAsParent()

    const isWDTemplateAdmin = p.auth.type === "Authenticated" && p.auth.value.type === "WD_MODERATOR"
    const isPWDTModal = values(p.predefinedWorkDescriptionTemplates).find(pt => pt.id.toString() === templateId)

    return (
        <>
            <ArchitectContent>
                <Prompt when={unsaved.check()} message={getStepExitWarningText()} />

                <SubContent>
                    {!isWDTemplateAdmin && (
                        <>
                            <FlexRow spaceBetween>
                                <PageHeader title={i18n("Devis Templates")} />
                                <ButtonWrapper>
                                    <Button
                                        onClick={() => {
                                            setDevisTemplateModalVisible(true)
                                            setTemplateId({ type: "create", value: genTemporaryId() })
                                        }}>
                                        {i18n("Add devis template")}
                                    </Button>
                                </ButtonWrapper>
                            </FlexRow>
                            {!values(p.userBasedWorkDescriptionTemplates).length ? (
                                <P small>{i18n("There is no saved Work Description Templates")}</P>
                            ) : (
                                <Table<"template" | "header">
                                    rows={[
                                        headerRow(),
                                        ...values(p.userBasedWorkDescriptionTemplates)
                                            .sort((a: any, b: any) => a.workCategory.localeCompare(b.workCategory))
                                            .map(v =>
                                                mkDevisTeplateRow(
                                                    v,
                                                    () =>
                                                        p.navigate(appPaths.template, { templateId: v.id.toString() }),
                                                    [
                                                        mkDropdownOption(i18n("Edit"), "edit", () =>
                                                            p.navigate(appPaths.template, {
                                                                templateId: v.id.toString()
                                                            })
                                                        ),
                                                        mkDropdownOption(i18n("Rename"), "rename", () => {
                                                            setTemplateId({ type: "update", value: v.id.toString() })
                                                            setDevisTemplateModalVisible(true)
                                                        }),
                                                        mkDropdownOption(i18n("Remove"), "remove", () =>
                                                            setTemplateToRemove(
                                                                values(p.devisTemplates).find(
                                                                    dt => dt.templateId === v.id.toString()
                                                                ) as Domain.DevisTemplate
                                                            )
                                                        )
                                                    ]
                                                )
                                            )
                                    ]}
                                    rowHierarchy={["template"]}
                                />
                            )}
                            <VerticalSpace base="48px" />
                        </>
                    )}

                    <FlexRow spaceBetween>
                        <PageHeader title={i18n("Smart Devis Templates")} />
                        {isWDTemplateAdmin && (
                            <ButtonWrapper>
                                <Button
                                    onClick={() => {
                                        setDevisTemplateModalVisible(true)
                                        setTemplateId({ type: "create", value: genTemporaryId() })
                                    }}>
                                    {isWDTemplateAdmin ? i18n("Add Smart Devis template") : i18n("Add devis template")}
                                </Button>
                            </ButtonWrapper>
                        )}
                    </FlexRow>
                    {!values(p.predefinedWorkDescriptionTemplates).length ? (
                        <P small>{i18n("There is no saved Smart Devis Templates")}</P>
                    ) : (
                        <Table<"template" | "header">
                            rows={[
                                headerRow(),
                                ...values(p.predefinedWorkDescriptionTemplates)
                                    .sort((a: any, b: any) => a.workCategory.localeCompare(b.workCategory))
                                    .map(v =>
                                        mkDevisTeplateRow(
                                            v,
                                            () => {
                                                p.navigate(appPaths.template, { templateId: v.id.toString() })
                                                setDevisTemplateModalVisible(true)
                                            },
                                            isWDTemplateAdmin
                                                ? [
                                                      mkDropdownOption(i18n("Edit"), "edit", () =>
                                                          p.navigate(appPaths.template, { templateId: v.id.toString() })
                                                      ),
                                                      mkDropdownOption(i18n("Rename"), "rename", () => {
                                                          setTemplateId({ type: "update", value: v.id.toString() })
                                                          setDevisTemplateModalVisible(true)
                                                      }),
                                                      mkDropdownOption(i18n("Remove"), "remove", () =>
                                                          setTemplateToRemove(
                                                              values(p.devisTemplates).find(
                                                                  dt => dt.templateId === v.id.toString()
                                                              ) as Domain.DevisTemplate
                                                          )
                                                      )
                                                  ]
                                                : null
                                        )
                                    )
                            ]}
                            rowHierarchy={["template"]}
                        />
                    )}
                </SubContent>
            </ArchitectContent>
            <NewDevisTemplateModal
                visible={devisTemplateModalVisible}
                setVisible={setDevisTemplateModalVisible}
                initialName={newTemplateId.type === "create" ? "" : p.devisTemplates[newTemplateId.value]?.workCategory}
                onConfirm={(name: string) => {
                    createDevisTemplate(name)
                    setDevisTemplateModalVisible(false)
                }}
                processing={devisTemplateActionState.type === "Processing"}
                isWDTemplateAdmin={isWDTemplateAdmin}
            />
            {templateId && (
                <Modal
                    visible={!!templateId}
                    size="xl"
                    height="95vh"
                    headless
                    footer={
                        <FlexRow justifyEnd>
                            {!isWDTemplateAdmin && isPWDTModal ? (
                                <Button onClick={() => usleep(300).then(() => p.navigate(appPaths.templates))}>
                                    {i18n("Close")}
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => usleep(300).then(() => p.navigate(appPaths.templates))}
                                    key="save">
                                    {i18n("Save and close")}
                                </Button>
                            )}
                        </FlexRow>
                    }>
                    <DevisTemplateView {...p} />
                </Modal>
            )}
            {templateToRemove && (
                <ConfirmationModal
                    onSubmit={() => {
                        remove(templateToRemove)()
                        setTemplateToRemove(null)
                    }}
                    cancelText={i18n("Cancel")}
                    header={isWDTemplateAdmin ? i18n("Remove Smart Devis Template") : i18n("Remove template")}
                    contentText={
                        isWDTemplateAdmin
                            ? i18n("Are you sure you want to delete this Smart Devis Template?")
                            : i18n("Are you sure you want to delete this template?")
                    }
                    onClose={() => setTemplateToRemove(null)}
                    submitText={i18n("Confirm")}
                    visible={isDefined(templateToRemove)}>
                    <VerticalSpace base="8px" />
                    <Pre>{templateToRemove.workCategory}</Pre>
                </ConfirmationModal>
            )}
        </>
    )
})

const ButtonWrapper = styled.div`
    margin: 20px 0 0;
`
