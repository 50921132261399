import { Cmd } from "redux-loop"
import { Domain } from "@smartdevis/server/src/domain"
import { getStorage } from "../services/firebase"
import { actions, UpdateDevisPayload, UpdateOfferPayload, UploadSource } from "./attachmentsState"
import { actions as cloudActions } from "./cloudState"
import { omitObject, pickObject } from "@smartdevis/utils/src/map"
import { _noop } from "@smartdevis/utils/src/misc"
import { F1 } from "@smartdevis/utils/src/types"
import { Action } from "@smartdevis/utils/src/actions"
import { genTemporaryId, getTs } from "@smartdevis/utils/src/id"
import { EventPayloads } from "@smartdevis/server/src/cloudEvents"
import { AsyncFile, UploadingFile } from "@smartdevis/ui/src/Upload"
import { getCollectionForAttachmentType } from "@smartdevis/server/src/models/attachment"

import { clientActions } from "."
import { ContractorOperationPayload } from "../components/modals/ContractorOperationModal"

export type AsyncAttachment = AsyncFile & { attachment: Pick<Domain.Attachment, "attachmentId" | "type" | "refId"> }
export type AsyncNewAttachment = UploadingFile & {
    attachment: Pick<Domain.Attachment, "attachmentId" | "type" | "refId">
}

export const getName = (u: AsyncAttachment) => `${u.attachment.attachmentId}.${u.name.split(".").pop()}`

export const createAttachmentCmd = (u: AsyncNewAttachment, projectId: string, userId: string, source: UploadSource) => {
    const storage = getStorage()

    const ref = storage.ref(userId, getCollectionForAttachmentType(u.attachment), u.attachment.refId, getName(u))

    const attachment: Domain.Attachment = {
        ...pickObject(u.attachment, ["attachmentId", "refId", "type"]),
        ...pickObject(u, ["name"]),
        contentType: u.file.type,
        size: u.file.size,
        path: ref.fullPath,
        createdTs: u.createdTs
    }

    const uploadFile = () => {
        const onProgress = _noop
        return storage.uploadFile({ file: u.file, ref, onProgress })
    }

    const createAttachment = (dispatch: F1<Action>) => {
        const mutationType = "create" as const
        const actionId = genTemporaryId()
        const collection = "attachments"
        switch (u.attachment.type) {
            case "position":
                if (source === "template")
                    return dispatch(
                        cloudActions.mutate({
                            mutationType,
                            source: { type: "template", templateId: projectId },
                            collection,
                            item: attachment,
                            actionId
                        })
                    )
                return dispatch(
                    cloudActions.mutate({
                        mutationType,
                        source: { type: "project", projectId },
                        collection,
                        item: attachment,
                        actionId
                    })
                )

            case "offer":
                return dispatch(
                    cloudActions.mutateOfferAttachment({
                        mutationType,
                        attachment,
                        actionId,
                        offerId: u.attachment.refId
                    })
                )
            default:
                return dispatch(
                    cloudActions.mutate({
                        mutationType,
                        source: { type: "project", projectId },
                        collection,
                        item: attachment,
                        actionId
                    })
                )
        }
    }
    return Cmd.list(
        [
            Cmd.run(uploadFile, {
                successActionCreator: r => actions._setUploadingFiles({ ...u, ...r }),
                args: [Cmd.dispatch]
            }),
            Cmd.run(createAttachment, {
                args: [Cmd.dispatch]
            })
        ],
        { sequence: true }
    )
}

export const removeAttachmentCmd = (item: Domain.Attachment, projectId: string, source: UploadSource) =>
    // Cmd.run(() => getStorage().deleteFile(item.path), {
    Cmd.run(
        () => {
            return Promise.resolve()
        },
        {
            successActionCreator: () => {
                const mutationType = "remove" as const
                const actionId = genTemporaryId()
                const collection = "attachments"
                switch (item.type) {
                    case "position":
                        if (source === "template")
                            return cloudActions.mutate({
                                mutationType,
                                source: { type: "template", templateId: projectId },
                                collection,
                                item,
                                actionId
                            })
                        else
                            return cloudActions.mutate({
                                mutationType,
                                source: { type: "project", projectId },
                                collection,
                                item,
                                actionId
                            })

                    case "offer":
                        return cloudActions.mutateOfferAttachment({
                            mutationType,
                            attachment: item,
                            actionId,
                            offerId: item.refId
                        })

                    default:
                        return cloudActions.mutate({
                            mutationType,
                            source: { type: "project", projectId },
                            collection,
                            item,
                            actionId
                        })
                }
            }
        }
    )

const uploadFile = async (file: File) => {
    const storage = getStorage()
    ///TODO: change user file name to v4
    const ref = storage.publicRef(file.name)
    return (await storage.uploadFile({ file, ref, onProgress: _noop })).url
}

export const updateOfferFileCmd = (p: UpdateOfferPayload) =>
    Cmd.run(
        async () => {
            const storage = getStorage()
            const ref = storage.ref(p.userId, "offers", p.offerId, p.file.name)

            await storage.uploadFile({ file: p.file.file, ref, onProgress: _noop })

            const offerProposal: Domain.AttachmentData = {
                attachmentId: genTemporaryId(),
                refId: p.offerId,
                name: p.file.name,
                path: ref.fullPath,
                size: p.file.file.size,
                contentType: p.file.file.type,
                createdTs: getTs()
            }

            return { offerProposal, actionId: p.actionId, offerId: p.offerId }
        },

        {
            successActionCreator: cloudActions.updateOfferValues
        }
    )

export const updateDevisCmd = (p: UpdateDevisPayload) =>
    Cmd.run(
        async () => {
            const storage = getStorage()
            const ref = storage.ref(p.userId, "devis", p.devisId, p.file.name)

            await storage.uploadFile({ file: p.file.file, ref, onProgress: _noop })

            const attachment: Domain.AttachmentData = {
                attachmentId: genTemporaryId(),
                refId: p.devisId,
                name: p.file.name,
                path: ref.fullPath,
                size: p.file.file.size,
                contentType: p.file.file.type,
                createdTs: getTs()
            }
            return attachment
        },
        {
            successActionCreator: file =>
                cloudActions.setFileForDevis({
                    file,
                    devisId: p.devisId,
                    projectId: p.projectId,
                    actionId: p.actionId
                })
        }
    )

export const updateUserCmd = (p: EventPayloads["updateUser"] & { logo: File | null; actionId: string }) =>
    Cmd.run(
        async () => {
            if (p.logo) {
                const logoUrl = await uploadFile(p.logo)
                return { ...omitObject(p, ["logo"]), logoUrl }
            }
            return p
        },

        {
            successActionCreator: r => cloudActions.updateUser({ ...r, actionId: p.actionId })
        }
    )

export const updatePartnerCmd = (p: EventPayloads["mutatePartners"] & { logo: File | null; actionId: string }) =>
    Cmd.run(
        async () => {
            if (p.logo) {
                const logoUrl = await uploadFile(p.logo)
                return { ...omitObject(p, ["logo"]), logoUrl }
            }
            return p
        },
        {
            successActionCreator: r => cloudActions.mutatePartners({ ...r, actionId: p.actionId })
        }
    )

export const addContractorCmd = (p: ContractorOperationPayload & { logoUrl: File | null; logoLink: string }) =>
    Cmd.run(
        async () => {
            if (p.logoUrl) {
                const logoUrl = await uploadFile(p.logoUrl)
                return { ...omitObject(p, ["logoUrl"]), logoUrl }
            }
            return p
        },

        {
            successActionCreator: r => {
                const { logoUrl, ...payload } = p
                return clientActions.addContractor({ ...payload, logoLink: r.logoUrl as string })
            }
        }
    )

export const editContractorCmd = (
    p: ContractorOperationPayload & { logoUrl: File | null; logoLink: string } & { id: string | undefined }
) =>
    Cmd.run(
        async () => {
            if (p.logoUrl) {
                const logoUrl = await uploadFile(p.logoUrl)
                return { ...omitObject(p, ["logoUrl"]), logoUrl }
            }
            return p
        },

        {
            successActionCreator: r => {
                const { logoUrl, ...payload } = p
                return clientActions.editContractor({ ...payload, logoLink: r.logoUrl as string })
            }
        }
    )
