import styled from "styled-components"
import { Link as RouterLink } from "react-router-dom"
import { TypographyBase } from "./Typography"

const StyledLink = styled(RouterLink)`
    ${TypographyBase(1)}
    text-decoration: none;
`

export const PureLink = RouterLink
export const Link = StyledLink
