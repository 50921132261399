import { SMap, keys, remap, filterObject } from "@smartdevis/utils/src/map"
import { identity, isDefined } from "@smartdevis/utils/src/misc"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"

export type ApplyDeltasOptions = { keepDeleted?: boolean }
export const applyDeltas = <
    T extends Domain.Condition | Domain.Position | Domain.Section | Domain.Deduction | Domain.AdditionalInformation
>(
    type: Domain.RoundDelta["parent"],
    map: SMap<T>,
    deltasByRef: SMap<Domain.RoundDelta>,
    options?: { keepDeleted?: boolean }
): SMap<T> => {
    const result = { ...map }
    keys(deltasByRef).forEach(id => {
        const delta = deltasByRef[id]
        if (delta.parent !== type) return
        switch (delta.type) {
            case "add":
            case "edit":
                return (result[id] = delta.value as T)
            case "remove":
                if (!options?.keepDeleted) delete result[id]
        }
    })
    return result
}

// Initially the logic was in isReadyToSubmit fn, and thats why it is tested. Later we needed the raw empty values
export const getEmptiesForOfferSubmission = (
    data: Pick<Domain.ContractorOffer, "positions" | "sections" | "deductions" | "deltas" | "additionalInformation">,
    offer: Domain.OfferValues,
    information: Domain.OfferInformation,
    roundId?: string
) => {
    const deltas = remap(
        filterObject(data.deltas ?? {}, (_, d) => d.roundId === roundId),
        (_, d) => d.refId,
        identity
    )

    const sections = applyDeltas("sections", data.sections, deltas, { keepDeleted: false })

    const positions = applyDeltas("positions", data.positions, deltas, { keepDeleted: false })

    const noValuePositions = filterItemsBySectionsExistence(positions, sections).filter(pid => isEmpty(offer[pid]))

    const deductions = applyDeltas("deductions", data.deductions, deltas, { keepDeleted: false })

    const noValueDeductions = filterItemsBySectionsExistence(deductions, sections).filter(
        did => deductions[did].value === null && isEmpty(offer[did])
    )

    const ais = applyDeltas("additionalInformation", data.additionalInformation, deltas, { keepDeleted: false })
    const noValueInformation = keys(ais).filter(aid => ais[aid].value === null && isEmpty(information[aid]))

    return {
        positions: noValuePositions,
        deductions: noValueDeductions,
        information: noValueInformation
    }
}

export const isReadyToSubmit = (
    data: Pick<Domain.ContractorOffer, "positions" | "sections" | "deductions" | "deltas" | "additionalInformation">,
    offer: Domain.OfferValues,
    information: Domain.OfferInformation,
    roundId?: string
) => {
    const emptyValues = getEmptiesForOfferSubmission(data, offer, information, roundId)
    return isEmpty([...emptyValues.positions, ...emptyValues.deductions, ...emptyValues.information])
}

const isSectionRemoved = (sectionId: string, sections: SMap<Domain.Section>): boolean => {
    const s = sections[sectionId]
    return !isDefined(s) || (s.parentId ? isSectionRemoved(s.parentId, sections) : false)
}

export const filterItemsBySectionsExistence = <T extends { sectionId: string }>(
    items: SMap<T>,
    sections: SMap<Domain.Section>
): string[] => {
    return keys(items).filter(pid => !isSectionRemoved(items[pid].sectionId, sections))
}
