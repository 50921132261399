import styled, { css } from "styled-components"
import { rem } from "./utils"
import { styleIfProp } from "./utils/common"
import { themeColor, ThemeColors, themeConfig, themeMedia } from "./utils/theme"

export const TypographyBase = (n: number) => css`
    padding: 0;
    margin: 0;
    font-family: ${themeConfig("fontFamily")};
    font-style: normal;
    font-weight: normal;
    color: ${themeColor("primaryGrey")};
    font-size: ${n}rem;
    line-height: ${n * 1.45}rem;
    ${themeMedia("max", "xs")} {
        font-size: ${n * 0.8}rem;
        line-height: ${n * 1.1}rem;
    }
`

export const H1 = styled.h1`
    ${TypographyBase(2)};
    font-weight: 300;
    color: ${themeColor("black")};
`

export const H2 = styled.h2`
    ${TypographyBase(1)}
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${themeColor("primaryGrey")};
`

export const H3 = styled.h3`
    ${TypographyBase(1.6)}
    font-weight: 400;
    color: ${themeColor("black")};
`

export const H4 = styled.h4`
    ${TypographyBase(1.2)}
    font-weight: 400;
    color: ${themeColor("primaryGrey")};
`

export const H5 = styled.h5`
    ${TypographyBase(1)}
    font-weight: 400;
    color: ${themeColor("black")};
`

export const H6 = styled.h6`
    ${TypographyBase(0.88)}
    color: ${themeColor("grey70")};
`

export const P = styled.p<{ small?: boolean; color?: keyof ThemeColors; textAlign?: "center" | "right" | "left" }>`
    ${p => TypographyBase(p.small ? 0.88 : 1)}
    color: ${p => themeColor(p.color ?? "primaryGrey")(p)};
    ${styleIfProp("textAlign", prop => `text-align: ${prop};`)}
`

export const ExternalLink = styled.a`
    ${TypographyBase(0.88)}
    color: ${themeColor("primaryGrey")};
    text-decoration: underline;
`

export const Label = styled.label<{ color?: keyof ThemeColors }>`
    ${TypographyBase(0.8)}
    margin: 4px 0;
    line-height: normal;
    color: ${p => themeColor(p.color ?? "primaryGrey")(p)};
`

export const ErrorLabel = styled.p`
    ${TypographyBase(0.8)}
    color: ${themeColor("error")};
    padding: 2px 0;
    margin: 0;
    min-height: 1.2rem;
    padding-left: 0px;
`

export const Pre = styled.pre`
    ${TypographyBase(0.88)}
    color: ${themeColor("black")};
    padding: 16px;
    line-height: ${rem(20)};
    border-radius: 3px;
    background-color: ${themeColor("grey35")};
    white-space: break-spaces;
`

export const NavP = styled.p`
    ${TypographyBase(1)}
    margin: 4px 0;
    line-height: normal;
    color: ${themeColor("navText")};
    letter-spacing: 0.01em;
    line-height: ${rem(22)};
`
