import { keyframes } from "styled-components"

export const ExitUp = keyframes`
  from {
      transform: scaleY(1);
      opacity: 1;
  }
  to {
      transform: scaleY(0.9);
      opacity: 0;
  }
`

export const EnterDown = keyframes`
  from {
      transform: scaleY(0.9);
      opacity: 0;
  }
  to {
      transform: scaleY(1);
      opacity: 1;
  }
`
