import * as React from "react"
import { F1, F0 } from "@smartdevis/utils/src/types"
import { i18n } from "@smartdevis/client/src/services/translations"
import { FlexRow, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { ConfirmationModal } from "@smartdevis/ui/src/Modal"
import { CheckboxButton } from "@smartdevis/ui/src/Button"
import { Input } from "@smartdevis/ui/src/Inputs"
import { InfoBox } from "@smartdevis/ui/src/InfoBox"
import { Row } from "@smartdevis/forms/src/components/PlainHtmlRenderMap"
import { useMapState } from "../../hooks/useMapState"
import { Domain } from "@smartdevis/server/src/domain"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { getSystemTemplateDetails } from "@smartdevis/server/src/models/template"

export const useProjectActions = () => {
    const [state, setState, setDelta] = useMapState({
        delete: false,
        duplicate: false,
        template: false,
        projectName: "",
        project: null as Domain.ProjectDetails | null,
        clearValuesOption: false
    })
    const [newId] = React.useState(genTemporaryId())

    const systemTemplates = getSystemTemplateDetails()
    const isSystemTemplate = state.project
        ? systemTemplates.findIndex(v => v.projectId === state.project?.projectId) !== -1
        : false

    return { state, setState, setDelta, newId, systemTemplates, isSystemTemplate }
}

export const ProjectDeleteModal: React.FC<{
    onConfirm: F0
    isTemplate?: boolean
    show: boolean
    setShow: F1<boolean>
}> = p => (
    <ConfirmationModal
        header={
            p.isTemplate
                ? i18n("Are you sure you want to delete template?")
                : i18n("Are you sure you want to delete project?")
        }
        contentText={i18n("Warning: all project data will be lost.")}
        submitText={i18n("Delete")}
        cancelText={i18n("Cancel")}
        onClose={() => p.setShow(false)}
        onSubmit={() => {
            p.setShow(false)
            p.onConfirm()
        }}
        visible={p.show}
    />
)

export const ProjectSubformRemoveModal: React.FC<{
    onConfirm: F0
    show: boolean
    setShow: F1<null>
}> = p => (
    <ConfirmationModal
        header={i18n("Are you sure you want to remove that form?")}
        contentText={i18n("All data in fields will be lost.")}
        submitText={i18n("Delete")}
        cancelText={i18n("Cancel")}
        onClose={() => p.setShow(null)}
        onSubmit={() => {
            p.setShow(null)
            p.onConfirm()
        }}
        visible={p.show}
    />
)

export const DevisDeleteModal: React.FC<{
    onConfirm: F0
    show: boolean
    setShow: F1<boolean>
    isDeletable: boolean
}> = p => (
    <ConfirmationModal
        header={i18n("Are you sure you want to delete devis?")}
        contentText={p.isDeletable ? i18n("All devis data will be lost.") : ""}
        submitText={i18n("Delete")}
        submitButtonProps={{ disabled: !p.isDeletable }}
        cancelText={i18n("Cancel")}
        onClose={() => p.setShow(false)}
        onSubmit={() => {
            p.setShow(false)
            p.onConfirm()
        }}
        visible={p.show}>
        {!p.isDeletable && (
            <InfoBox type="warning">
                {i18n("You are not allowed to delete the devis after a contractor has been invited.")}
            </InfoBox>
        )}
    </ConfirmationModal>
)

export const ProjectDuplicateConfirmModal: React.FC<{
    show: boolean
    setShow: F1<boolean>
    onConfirm: F0
    name: string
    setName: F1<string>
    processing: boolean
    systemTemplate: boolean
}> = p => (
    <ConfirmationModal
        header={
            p.systemTemplate
                ? i18n("You are about to create a demo project")
                : i18n("Are you sure you want to create copy of that project?")
        }
        visible={p.show}
        submitText={i18n("Create")}
        cancelText={i18n("Cancel")}
        onSubmit={() => {
            p.onConfirm()
        }}
        submitButtonProps={{ disabled: !p.name.length }}
        onClose={p.processing ? undefined : () => p.setShow(false)}
        loading={p.processing}>
        <Row>
            {p.systemTemplate
                ? i18n("This project exists for demonstrating how a project works.")
                : i18n("All project details, estimates, devis and positions will be copied to new project.")}
        </Row>
        <VerticalSpace base="16px" />
        <Input
            onChange={e => p.setName(e.target.value)}
            placeholder={i18n("New Project Name")}
            data-cy="projects-card-duplicate-name"
        />
    </ConfirmationModal>
)

export const ProjectTemplateModal: React.FC<{
    show: boolean
    setShow: F1<boolean>
    onConfirm: F0
    name: string
    setName: F1<string>
    check?: {
        checked: boolean
        setChecked: F1<boolean>
    }
}> = p => (
    <ConfirmationModal
        header={i18n("Are you sure you want to create template?")}
        visible={p.show}
        submitText={i18n("Create")}
        cancelText={i18n("Cancel")}
        onSubmit={() => {
            p.setShow(false)
            p.onConfirm()
        }}
        submitButtonProps={{ disabled: !p.name.length }}
        onClose={() => p.setShow(false)}>
        <Row>
            {i18n("All project details, estimates, devis and positions will be copied to template.")}{" "}
            {i18n("If you don't want to copy values (prices / amount) you can check clear value")}
        </Row>
        <VerticalSpace base="16px" />
        <Input
            onChange={e => p.setName(e.target.value)}
            placeholder={i18n("New Template Name")}
            data-cy="projects-card-duplicate-name"
        />
        {p.check ? (
            <FlexRow>
                <Margin values="8px">
                    <CheckboxButton
                        checked={p.check.checked}
                        label={i18n("Clear all values")}
                        data-cy="projects-card-delete-confirm"
                        onChange={() => p.check?.setChecked(!p.check?.checked)}
                    />
                </Margin>
            </FlexRow>
        ) : null}
    </ConfirmationModal>
)

export const NewDevisTemplateModal: React.FC<{
    visible: boolean
    setVisible: F1<boolean>
    onConfirm: F1<string>
    initialName?: string
    processing: boolean
    isWDTemplateAdmin: boolean
}> = ({ initialName = "", ...p }) => {
    const [name, setName] = React.useState(initialName)
    React.useEffect(() => {
        if (name !== initialName) setName(initialName)
    }, [initialName])
    return (
        <ConfirmationModal
            header={
                initialName
                    ? p.isWDTemplateAdmin
                        ? i18n("You are about to rename a Smart Devis Template")
                        : i18n("You are about to rename a devis template")
                    : p.isWDTemplateAdmin
                    ? i18n("You are about to create a Smart Devis Template")
                    : i18n("You are about to create a devis template")
            }
            visible={p.visible}
            submitText={initialName ? i18n("Update") : i18n("Create")}
            cancelText={i18n("Cancel")}
            onSubmit={() => {
                p.onConfirm(name)
            }}
            submitButtonProps={{ disabled: !name.length }}
            onClose={p.processing ? undefined : () => p.setVisible(false)}
            loading={p.processing}>
            <Input
                onChange={e => setName(e.target.value)}
                placeholder={
                    p.isWDTemplateAdmin ? i18n("New Smart Devis Template Name") : i18n("New Devis Template Name")
                }
                value={name}
            />
        </ConfirmationModal>
    )
}
