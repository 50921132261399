import * as React from "react"
import styled from "styled-components"
import { ignorePatches, versionToNumber } from "@smartdevis/server/src/models/changelog"
import { Button } from "@smartdevis/ui/src/Button"
import { P } from "@smartdevis/ui/src/Typography"
import { Center, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { asyncConnect } from "../../resolvers"
import { i18n } from "../../services/translations"
import * as vservice from "../../version"

const BannerContainer = styled(Center)`
    flex-direction: row;
    height: 60px;
`

export const UpdateReloadBanner = asyncConnect({
    stateResolvers: [],
    actions: [],
    renderLoading: () => <>{null}</>,
    renderError: () => <>{null}</>
})(_p => {
    const appVersion = versionToNumber(ignorePatches(vservice.version))
    const officialLiveVersion = versionToNumber(ignorePatches(vservice.version)) // TODO: it was in the config, but we nuked the config
    if (appVersion >= officialLiveVersion) return null
    return (
        <BannerContainer>
            <P color="primary">
                <b>{i18n("New app version is available, please reload:")}</b>
            </P>
            <HorizontalSpace base="16px" />
            <Button onClick={() => window.location.reload()} btnType="action">
                {i18n("Reload")}
            </Button>
        </BannerContainer>
    )
})
