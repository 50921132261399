import * as React from "react"
import { F1 } from "@smartdevis/utils/src/types"
import styled, { css, keyframes } from "styled-components"
import { Portal } from "./Portal"
import { rem } from "./utils"
import { FlexItem } from "./utils/common"
import { themeColor, themeConfig } from "./utils/theme"
import { usleep } from "@smartdevis/utils/src/async"

export const NOTIFICATION_SHOW_DURATION = 4000
const HIDE_ANIMATION_DURATION = 300
const SHOW_ANIMATION_DURATION = 500
const BASE_NOTIFICATION_HEIGHT = 29

export type Notification = {
    value: string
    id: string
}
export type NotificationProps = {
    value: Notification
    onClose: F1<string>
    closeText: string
}
const Notification: React.FC<NotificationProps> = p => {
    const [closing, setClosing] = React.useState(false)
    const startClosing = React.useCallback(async () => {
        setClosing(true)
        await usleep(300)
        p.onClose(p.value.id)
    }, [p.onClose])
    React.useEffect(() => {
        const to = setTimeout(() => {
            startClosing()
        }, NOTIFICATION_SHOW_DURATION - HIDE_ANIMATION_DURATION)
        return () => clearTimeout(to)
    }, [])
    return (
        <NotificationContainer closing={closing}>
            <NotificationContent direction="row" yAlign="center" xAlign="center" flex="1">
                {p.value.value}
            </NotificationContent>
            <FlexItem direction="row" yAlign="center" xAlign="flex-end" flex="0">
                <Spacer />
                <CloseNotificationButton onClick={startClosing}>{p.closeText}</CloseNotificationButton>
            </FlexItem>
        </NotificationContainer>
    )
}

export const NotificationsRenderer: React.FC<{
    values: Notification[]
    onClose: F1<string>
    closeText: string
}> = p => (
    <Portal>
        <NotificationsContainer>
            {p.values.map(v => (
                <Notification key={v.id} value={v} onClose={p.onClose} closeText={p.closeText} />
            ))}
        </NotificationsContainer>
    </Portal>
)

const show = keyframes`
  from {
    opacity: 0.5;
    margin-bottom: -${rem(BASE_NOTIFICATION_HEIGHT)};
  }
  to {
    opacity: 1;
    margin-bottom: 6px;
  }
`

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const NotificationsContainer = styled.div`
    position: fixed;
    bottom: 30px;
    left: 50%;
    z-index: ${themeConfig("zIndexMax")};
    transform: translate(-50%, 0);
`

const NotificationContainer = styled.div<{ closing?: boolean }>`
    background-color: ${themeColor("primaryGrey")};
    color: ${themeColor("white")};
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: ${rem(29)};
    margin: 6px 0;
    animation: ${p =>
        p.closing
            ? css`
                  ${hide} ${HIDE_ANIMATION_DURATION / 1000}s ease-in
              `
            : css`
                  ${show} ${SHOW_ANIMATION_DURATION / 1000}s ease-out
              `};
`

const NotificationContent = styled(FlexItem)`
    padding: 4px 12px;
    font-size: ${rem(14)};
    max-width: 600px;
    flex-wrap: wrap;
`

const Spacer = styled.span`
    width: 1px;
    height: ${rem(14)};
    background-color: ${themeColor("grey70")};
    margin-top: -1px;
`

const CloseNotificationButton = styled.button`
    background-color: transparent;
    color: inherit;
    &:hover {
        color: ${themeColor("grey60")};
    }
    transition: color 0.1s;
    font-size: ${rem(12)};
    font-weight: 600;
    outline: none;
    cursor: pointer;
    border: none;
    padding: 4px 12px;
    text-transform: uppercase;
`
