import * as React from "react"
import { positionValidation } from "@smartdevis/server/src/models/position"
import { getUnitTranslation, i18n } from "@smartdevis/client/src/services/translations"
import {
    mkFormSchema,
    mkTextareaSchema,
    mkFormattedNumberSchema,
    mkSelectSchema,
    mkHiddenSchema,
    mkTextSchema
} from "../../../components/forms/formSchemas"
import {
    Cell,
    CellEditMode,
    mkAssetPlaceholderCell,
    mkCell,
    mkDragHandleCell
} from "../../../components/table/TableCell"
import { Row } from "../../../components/table/Table"
import { getRowVisualFromDelta } from "@smartdevis/client/src/utils/devisHelpers"
import { getDevisReadonlyRowExplanationText } from "../DevisSection"
import { Orderable } from "@smartdevis/utils/src/comparable"
import { pickObject, keys, values } from "@smartdevis/utils/src/map"
import { TypedOmit, Optionalize, F0, F1 } from "@smartdevis/utils/src/types"
import { getTs, genTemporaryId } from "@smartdevis/utils/src/id"
import { formatSwiss, isStringNumber } from "@smartdevis/utils/src/numbers"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { identity, isDefined } from "@smartdevis/utils/src/misc"
import { BaseModalProps } from "@smartdevis/ui/src/Modal"
import { Domain } from "@smartdevis/server/src/domain"
import { units } from "@smartdevis/server/src/constants"
import { Spinner } from "@smartdevis/ui/src/Spinner"

export type PositionPayload = TypedOmit<
    Domain.Position,
    "sectionId" | "createdTs" | keyof Orderable | "updatedTs" | "devisId"
>

export type PositionEditableFields = Pick<Domain.Position, "name" | "description" | "amount" | "unit">
export interface IncompletePosition extends Optionalize<Domain.Position, keyof PositionEditableFields> {}
export const mkIncompletePosition = (
    sectionId: string,
    devisId: string,
    order: number,
    posId?: string
): IncompletePosition => ({
    createdTs: getTs(),
    positionId: posId ?? genTemporaryId(),
    devisId,
    order,
    sectionId
})

export const mkPositionSchema = (type?: "weak" | "full") =>
    mkFormSchema<PositionPayload>(
        type === "weak" ? (pickObject(positionValidation, ["name"]) as any) : { ...positionValidation },
        {
            number: mkTextSchema("{Number}No"),
            name: mkTextareaSchema("Name"),
            description: mkTextareaSchema("Description"),
            amount: mkFormattedNumberSchema("Amount"),
            unit: mkSelectSchema<string>(
                i18n("Unit"),
                keys(units).map(k => [getUnitTranslation(k), k]),
                { placeholder: i18n("Enter unit") }
            ),
            positionId: mkHiddenSchema("PositionId")
        }
    )

const positionsGrid = [2, 5, 9, 3.5, 2.5, 2]

export const mkPostionHeaderRow = (readonly: boolean): Row<"header", PositionPayload> => ({
    mode: "static",
    type: "header",
    grid: positionsGrid,
    rowId: "header",
    visuals: ["header"],
    noDrag: true,
    cells: [
        readonly ? mkCell(i18n("{Number}No")) : mkAssetPlaceholderCell(i18n("{Number}No")),
        mkCell(i18n("Name")),
        mkCell(i18n("Description")),
        mkCell(i18n("Amount")),
        mkCell(i18n("Unit")),
        mkCell(i18n("Attach.{attachments on positions view}"))
    ]
})

export const mkPositionRowBuilder =
    (
        attachmentsCell: F1<Domain.Position, Cell<PositionPayload>>,
        options: { readonly: boolean; predefinedReadonly?: boolean; isUnsaved: F1<string, boolean> },
        actions: {
            onPositionSubmit: F1<Domain.Position, F1<Partial<Domain.Position>>>
            onPositionRemove: F1<Domain.Position, F0>
            onDeltaRevert?: F1<string | undefined, F0 | undefined>
        }
    ) =>
    (position: Domain.Position, delta?: Domain.RoundDelta): Row<"sublevel", PositionPayload> => {
        const { positionId } = position

        const formSchema = mkPositionSchema()

        const mkEditMode = (flag: boolean, field: keyof PositionPayload): CellEditMode<PositionPayload> =>
            flag ? { editMode: "static" } : { editMode: "formless", field }

        const cells: Cell<PositionPayload>[] = [
            options.readonly
                ? mkCell(position.number ?? "", [], mkEditMode(options.readonly, "number"))
                : mkDragHandleCell(position.number ?? "", [], mkEditMode(options.readonly, "number")),
            mkCell(position.name, [], mkEditMode(Boolean(options.readonly), "name")),
            mkCell(position.description, [], mkEditMode(options.readonly, "description")),
            position.type === "per"
                ? mkCell(i18n("PER position"), ["italic"])
                : mkCell(formatSwiss(position.amount), [], mkEditMode(options.readonly, "amount")),
            mkCell(getUnitTranslation(position.unit), [], mkEditMode(options.readonly, "unit")),
            options.isUnsaved(positionId)
                ? mkCell("")
                : isStringNumber(position.positionId) //its for the fake ids (genTemporaryId)
                ? attachmentsCell(position)
                : mkCell(<Spinner />)
        ]
        return {
            mode: "editable",
            type: "sublevel",
            formSchema,
            formValue: position,
            rowId: positionId,
            visuals: getRowVisualFromDelta(delta).concat("noBorder"),
            actionOnEnter: "submit",
            actionOnBlur: "submit",
            resetOnUpdateInitial: true,
            getRowOptions: pos =>
                [
                    position.type && position.type !== "basic"
                        ? mkDropdownOption(i18n("Convert to basic position"), "basic", () =>
                              actions.onPositionSubmit(position)({ ...pos, type: "basic" })
                          )
                        : null,
                    position.type !== "per"
                        ? mkDropdownOption(i18n("Convert to PER position"), "per", () =>
                              actions.onPositionSubmit(position)({ ...pos, type: "per", amount: 0 })
                          )
                        : null
                ].filter(isDefined),
            grid: positionsGrid,
            readonlyClickMessage: getDevisReadonlyRowExplanationText(options?.predefinedReadonly),
            cells,
            onRevert: actions.onDeltaRevert?.(delta?.deltaId),
            isEdited: options.isUnsaved(positionId),
            onDelete: options.readonly ? undefined : actions.onPositionRemove(position),
            onSubmit: v => actions.onPositionSubmit(position)(v),
            readonly: options.readonly
        }
    }

export const usePositionComments = (p: Pick<Domain.ArchitectOfferRequest, "positionComments">) => {
    const [visible, setVisible] = React.useState(false)
    const [attachmentModalPositionId, setPositionId] = React.useState<string | null>(null)
    const openAttachmentModal = (positionId: string) => {
        setPositionId(positionId)
        setVisible(true)
    }
    const positionModalProps: BaseModalProps & { positionId: string | null } = {
        visible,
        onClose: () => setVisible(false),
        positionId: attachmentModalPositionId
    }
    const getPositionComments = (positionId: string) => values((p.positionComments || {})[positionId]).filter(identity)

    return {
        openAttachmentModal,
        getPositionComments,
        positionModalProps
    }
}
