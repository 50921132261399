import { NavigationPayload, Path, PathParams } from "../paths"
import { mkReducer } from "./utils"
import { Err } from "@smartdevis/utils/src/result"
import { createAction } from "@smartdevis/utils/src/actions"
import { Cmd } from "redux-loop"
import { getHistory } from "."
import { materialize } from "../utils/router"

export type KeycloakUser = {
    email: string
    type: "ARCHITECT" | "CONTRACTOR" | "ADMIN" | "WD_MODERATOR" | "CD_MODERATOR" | "architect" | "contractor" | "admin"
}

// Impersonation feature is for admins - they can login "as" someone else, in order to view their data
// The appropriate checks will be performed on backend
export type Authentication =
    | { type: "Authenticated"; value: KeycloakUser & { token: string } }
    | { type: "Processing" | "NotAuthenticated" }
    | Err<string>

export type AuthState = {
    auth: Authentication
}

export const initialState: AuthState = {
    auth: { type: "Processing" }
}

export const actions = {
    navigate: (to: Path, params?: Partial<PathParams>, fragment?: string) =>
        createAction("navigate", { to, params, fragment }),
    _setAuth: (p: Authentication) => createAction("_setUser", p)
}

export const reducer = mkReducer<AuthState>((state, action) => {
    switch (action.type) {
        case "init":
            return {}

        case "_setUser":
            return { auth: action.payload }

        case "navigate":
            return navigate(action.payload)
    }
})

export const navigate = (p: NavigationPayload) =>
    Cmd.run(() => getHistory().push(materialize(p.to.path, p.params) + (p.fragment ? `#${p.fragment}` : "")))
