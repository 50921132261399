import {
    ValidationMap,
    optionalNumber,
    optionalString,
    mkMapValidator,
    optionalStringArray,
    optionalMap,
    validString
} from "@smartdevis/utils/src/validators"
import { Domain } from "../domain"
import { addressInfoValidation } from "./projectDetails"

export const partnerValidation: ValidationMap<Domain.Partner> = {
    ...addressInfoValidation,
    products: optionalMap(validString),
    categories: optionalStringArray,
    groups: optionalStringArray,
    order: optionalNumber,
    partnerId: optionalString,
    logoUrl: optionalString
}

export const validatePartner = mkMapValidator(partnerValidation)
