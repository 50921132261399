import * as React from "react"
import { asyncConnect } from "../../resolvers"
import * as docCreators from "../../services/doc/docCreators"
import { downloadBlob } from "../../services/downloader"
import { ExportBtn } from "./ExportBtn"
import { i18n } from "../../services/translations"

type ExportContractProps = { projectId: string; devisId: string; offerId: string; disabled?: string }
export const ExportContract = asyncConnect({ stateResolvers: ["contractDoc"] })<ExportContractProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(await docCreators.mkContractDoc(p.contractDoc, "Werkvertrag"))

        downloadBlob(i18n("Contract"), "pdf")(blob)
    }, [p.contractDoc])

    return <ExportBtn handleClick={handleExport} btnType="primary" disabled={p.disabled} text={i18n("Download")} />
})

export const ExportContractorContract = asyncConnect({
    stateResolvers: ["contractorContractDoc"]
})(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(await docCreators.mkContractDoc(p.contractorContractDoc, "Werkvertrag"))

        downloadBlob(i18n("Contract"), "pdf")(blob)
    }, [p.contractorContractDoc])

    return <ExportBtn handleClick={handleExport} btnType="secondary" text={i18n("Download contract")} />
})

export const ExportOfferPreview = asyncConnect({ stateResolvers: ["contractorContractDoc"] })<ExportContractProps>(
    p => {
        const handleExport = React.useCallback(async () => {
            const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

            const blob = await generatePdf(await docCreators.mkOfferPreviewDoc(p.contractorContractDoc))

            downloadBlob("Angebot", "pdf")(blob)
        }, [p.contractorContractDoc])

        return (
            <ExportBtn
                handleClick={handleExport}
                btnType="secondary"
                disabled={p.disabled}
                text={i18n("Export offer")}
            />
        )
    }
)

type ExportContractPreviewProps = { projectId: string; devisId: string }
export const ExportContractPreview = asyncConnect({
    stateResolvers: ["contractPreviewDoc"]
})<ExportContractPreviewProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(await docCreators.mkContractDoc(p.contractPreviewDoc, "Ausschreibung"))

        downloadBlob(i18n("Contract preview"), "pdf")(blob)
    }, [p.contractPreviewDoc])

    return <ExportBtn handleClick={handleExport} btnType="action" text={i18n("Export preview")} />
})

type ExportOfferComparisonProps = { projectId: string; devisId: string; offersIds: string[]; disabled?: string }
export const ExportOfferComparison = asyncConnect({
    stateResolvers: ["offerComparisonDoc"]
})<ExportOfferComparisonProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(docCreators.mkOfferComparisonDoc(p.offerComparisonDoc))

        downloadBlob(i18n("Offers comparison"), "pdf")(blob)
    }, [])

    return <ExportBtn handleClick={handleExport} disabled={p.disabled} text={i18n("Export comparison")} />
})

type ExportPositionsProps = { projectId: string; devisId: string; confirm?: string }
export const ExportPositions = asyncConnect({ stateResolvers: ["positionsDoc"] })<ExportPositionsProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(docCreators.mkPositionsDoc(p.positionsDoc))

        downloadBlob(i18n("Positions"), "pdf")(blob)
    }, [p.positionsDoc])

    return <ExportBtn handleClick={handleExport} confirm={p.confirm} />
})

type ExportSubmittersProps = { projectId: string; devisId: string; disabled?: string }
export const ExportSubmitters = asyncConnect({ stateResolvers: ["submittersDoc"] })<ExportSubmittersProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(docCreators.mkSubmittersDoc(p.submittersDoc))

        downloadBlob(i18n("Submitters"), "pdf")(blob)
    }, [p.submittersDoc])

    return <ExportBtn handleClick={handleExport} btnType="action" disabled={p.disabled} text={i18n("Export")} />
})

type ExportProjectSubmittersProps = { projectId: string; disabled?: string }
export const ExportProjectSubmitters = asyncConnect({
    stateResolvers: ["projectSubmittersDoc"]
})<ExportProjectSubmittersProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(docCreators.mkSubmittersDoc(p.projectSubmittersDoc))

        downloadBlob(i18n("Submitters"), "pdf")(blob)
    }, [p.projectSubmittersDoc])

    return (
        <ExportBtn
            handleClick={handleExport}
            btnType="action"
            disabled={p.disabled}
            text={i18n("Export project submitters")}
        />
    )
})

type ExportConditionsProps = { projectId: string; devisId: string; disabled?: string }
export const ExportConditions = asyncConnect({ stateResolvers: ["conditionsDoc"] })<ExportConditionsProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(docCreators.mkConditionsDoc(p.conditionsDoc))

        downloadBlob(i18n("Conditions"), "pdf")(blob)
    }, [p.conditionsDoc])

    return <ExportBtn handleClick={handleExport} btnType="action" disabled={p.disabled} text={i18n("Export")} />
})

type ExportDeductionsProps = { projectId: string; devisId: string; disabled?: string }
export const ExportDeductions = asyncConnect({ stateResolvers: ["deductionsDoc"] })<ExportDeductionsProps>(p => {
    const handleExport = React.useCallback(async () => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "../../services/pdf")

        const blob = await generatePdf(docCreators.mkDeductionsDoc(p.deductionsDoc))

        downloadBlob(i18n("Deductions"), "pdf")(blob)
    }, [p.deductionsDoc])

    return <ExportBtn handleClick={handleExport} btnType="action" disabled={p.disabled} text={i18n("Export")} />
})
