import { identity } from "lodash"
import { v4 } from "uuid"
import { toMap, TMap } from "./map"
import { Brand } from "./types"
import { Validators, validString } from "./validators"

export const HEX = "0123456789abcdef"
export const flickrBase58 = "0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ"

let _hexToFilckr: ReturnType<typeof convert>
const hexToFilckr = () => {
    if (!_hexToFilckr) {
        _hexToFilckr = convert(HEX, flickrBase58)
    }
    return _hexToFilckr
}

export const sv4 = (i?: string) => hexToFilckr()((i || v4()).toLowerCase().replace(/-/g, ""))
export const getTs = () => new Date().getTime()
export const ts = (t?: number) => `${Math.floor(10090000000000 - (t !== undefined ? t : getTs()))}`
export const genTemporaryId = (t?: number, i?: string): string => {
    const res = `${ts(t)}${sv4(i)}`
    return i === undefined && t === undefined ? res.padEnd(32, "0") : res
}

export const getTsFromId = (identifier: string) => {
    return 10090000000000 - parseInt(identifier.slice(0, 13), 10)
}

const isValid = (srcAlphabet: string, v: string) => {
    const keysMap = toMap(srcAlphabet.split(""), identity, () => true)
    return v.split("").find(d => !keysMap[d]) === undefined
}

export const convert = (srcAlphabet: string, dstAlphabet: string) => (text: string) => {
    const numberMap: TMap<number, number> = {}
    const fromBase = srcAlphabet.length
    const toBase = dstAlphabet.length
    let length = text.length
    let result = ""

    if (!isValid(srcAlphabet, text)) throw new Error("Invalid input")
    if (srcAlphabet === dstAlphabet) return text

    for (let i = 0; i < length; i++) numberMap[i] = srcAlphabet.indexOf(text[i])
    let newlen = 0
    do {
        let divide = 0
        newlen = 0
        for (let i = 0; i < length; i++) {
            divide = divide * fromBase + numberMap[i]
            if (divide >= toBase) {
                numberMap[newlen++] = parseInt(`${divide / toBase}`, 10)
                divide = divide % toBase
            } else if (newlen > 0) numberMap[newlen++] = 0
        }
        length = newlen
        result = dstAlphabet.slice(divide, divide + 1).concat(result)
    } while (newlen !== 0)

    return result
}

export type IdLite = Brand<string, "IdLite">
export const SD_ZERO_HOUR = new Date("2020-01-01").getTime()
export const idLite = () => `${getTs() - SD_ZERO_HOUR}`.padStart(16, "0") as IdLite
export const validIdLite: Validators<IdLite> = validString as any
