import { F1 } from "./types"

export type Updatable = { updatedTs: number }
export type Creatable = { createdTs: number } & Partial<Updatable>
export type Orderable = { order: number }
export type Sortable = Creatable & Orderable
export const compareTs = (l: Creatable | null, r: Creatable | null) => (l && r ? l.createdTs - r.createdTs : 1)

export const calculateOrder = <T extends Orderable>(
    items: T[] = [],
    oldIndex: number,
    newIndex: number,
    getOrder: F1<T, number> = i => i.order
) => {
    const collider = items[newIndex]
    if (!collider) return newIndex
    if (oldIndex === -1) {
        if (newIndex === 0) return getOrder(collider) - 0.5
        const neighbour = items[newIndex - 1]
        const diff = getOrder(collider) - getOrder(neighbour)
        return getOrder(collider) - diff / 2
    }
    if (oldIndex < newIndex) {
        const neighbour = items[newIndex + 1]
        if (!neighbour) return getOrder(collider) >= newIndex ? getOrder(collider) + 0.5 : newIndex
        if (getOrder(collider) < newIndex && getOrder(neighbour) > newIndex) return newIndex
        if (getOrder(neighbour) === getOrder(collider)) return getOrder(collider) + 0.5
        return getOrder(collider) + (getOrder(neighbour) - getOrder(collider)) / 2
    }

    if (oldIndex > newIndex) {
        const neighbour = items[newIndex - 1]
        if (!neighbour) return getOrder(collider) <= newIndex ? getOrder(collider) - 0.5 : newIndex

        if (getOrder(collider) > newIndex && getOrder(neighbour) < newIndex) return newIndex
        if (getOrder(neighbour) === getOrder(collider)) return getOrder(collider) - 0.5
        return getOrder(collider) - (getOrder(collider) - getOrder(neighbour)) / 2
    }

    return newIndex
}

export const sortCreatable =
    (direction: "asc" | "desc" = "asc") =>
    (c1: Creatable, c2: Creatable) =>
        direction === "asc" ? c1.createdTs - c2.createdTs : c2.createdTs - c1.createdTs

export const sortUpdatable =
    (direction: "asc" | "desc" = "asc") =>
    (c1: Creatable, c2: Creatable) =>
        direction === "asc"
            ? (c1.updatedTs || c1.createdTs) - (c2.updatedTs || c2.createdTs)
            : (c2.updatedTs || c2.createdTs) - (c1.updatedTs || c1.createdTs)

export const sortSortables = <T extends Sortable>(s: T[], getOrder: F1<T, number> = i => i.order): T[] =>
    s.sort(sortCreatable("asc")).sort((a, b) => getOrder(a) - getOrder(b))
