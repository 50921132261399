import * as React from "react"

export const useDebounce = <F extends (...args: any[]) => void>(f: F, waitMs = 50) => {
    const id = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

    const run = (...args: Parameters<F>) => {
        cancel()
        id.current = setTimeout(() => {
            id.current = undefined
            f(...args)
        }, waitMs)
    }

    const cancel = () => {
        if (!id.current) return
        clearTimeout(id.current)
        id.current = undefined
    }

    const forceRun = (...args: Parameters<F>) => {
        cancel()
        f(...args)
    }

    return { run, cancel, forceRun }
}
