import {
    validString,
    mkMapValidator,
    optionalString,
    ValidationMap,
    validEmail,
    validId
} from "@smartdevis/utils/src/validators"
import { creatableValidation } from "./common"
import { Domain } from "../domain"

export const contractorValidation: ValidationMap<Required<Domain.Contractor>> = {
    ...creatableValidation,
    contractorId: validId,
    name: optionalString,
    email: validEmail,
    surname: optionalString,
    phoneNumber: optionalString,
    city: optionalString,
    street: optionalString,
    postalCode: optionalString,
    companyName: validString,
    workCategory: optionalString,
    number: optionalString
}

export const validateContractor = mkMapValidator(contractorValidation)
