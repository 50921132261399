import { EventResult, createAction } from "@smartdevis/utils/src/actions"
import { SMap } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { F2 } from "@smartdevis/utils/src/types"
import { clientActions, ClientActions } from "."
import { pickCloudActions } from "../utils/cloudActions"
import { cloudCmd } from "../utils/commands"
import { mkReducer } from "./utils"
import { getStore } from "../store"

export type CloudState = {
    results: SMap<EventResult>
}

export const initialState: CloudState = {
    results: {}
}
const clientCloudActions = pickCloudActions([
    "register",
    "mutate",
    "bulkMutate",
    "updateUser",
    "updateVisit",
    "updateTerms",
    "updateTranslations",
    "grantAdminAccess",
    "generateToken",
    "duplicateProject",
    "createRequests",
    "sendReminders",
    "updateRequestsState",
    "updateFinalOffer",
    "saveAsTemplate",
    "adoptOffer",
    "updateOfferValues",
    "submitOffer",
    "createRound",
    "sendRound",
    "rejectOffer",
    "submitRoundDelta",
    "duplicateDevis",
    "revertRoundDelta",
    "updateFinalOffer",
    "updateOfferState",
    "rejectFinalProposal",
    "initializeEmailUpdate",
    "confirmEmailUpdate",
    "updateUserPassword",
    "mutateOfferAttachment",
    "generateActivity",
    "createPositionsFromTemplate",
    "removePositionsFormat",
    "removeOfferProposal",
    "setFileForDevis",
    "resendToken",
    "mutatePartners"
])

export const actions = { ...clientCloudActions, _setResult: (p: EventResult) => createAction("_setResult", p) }

type ClientCloudActions = typeof clientCloudActions
type ClientCloudAction<N extends keyof ClientCloudActions = keyof ClientCloudActions> = ReturnType<
    ClientCloudActions[N]
>

const refetchMap: {
    [key in keyof ClientCloudActions]?: F2<ReturnType<ClientCloudActions[key]>, EventResult, ClientActions[]>
} = {
    updateUser: (_action, _result) => [clientActions.refetchUserData()],
    updateTerms: (_action, _result) => [clientActions.refetchUserData()],
    updateVisit: (_action, _result) => [clientActions.refetchUserData()],
    updateTranslations: () => [],
    grantAdminAccess: () => [],
    generateToken: () => [],
    duplicateDevis: action => [
        clientActions.refetchAllDevis(),
        clientActions.refetchProjectAttachments(action.payload.projectId)
    ],
    duplicateProject: () => [clientActions.refetchProjectsDetails(), clientActions.refetchAllDevis()],
    createRequests: action => [clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId)],
    updateRequestsState: action => [
        action.payload.state.type === "final-proposal"
            ? clientActions.fetchDevisCollections(action.payload.projectId, action.payload.devisId)
            : clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId)
    ],
    updateFinalOffer: action => [
        clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId)
    ],
    mutateOfferAttachment: action => [clientActions.refetchContractorOffers([action.payload.attachment.attachmentId])],
    adoptOffer: (_action, result) =>
        result.type === "Err" ? [clientActions.refetchContractorOffers()] : [clientActions.fetchContractorOffers()], // fetch is required to trigger loading indicator
    confirmEmailUpdate: () => [],
    createRound: action => [
        clientActions.refetchDevisCollections(action.payload.projectId, action.payload.round.devisId)
    ],
    mutatePartners: () => [clientActions.refetchPartners()],
    generateActivity: () => [],
    initializeEmailUpdate: () => [],
    rejectFinalProposal: () => [clientActions.refetchContractorOffers()],
    rejectOffer: () => [clientActions.refetchContractorOffers()],
    revertRoundDelta: action => [
        clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId)
    ],
    sendRound: action => [clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId)],
    submitOffer: () => [clientActions.refetchContractorOffers()],
    submitRoundDelta: action => [
        clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId)
    ],
    updateOfferState: () => [clientActions.refetchContractorOffers()],
    updateOfferValues: () => [clientActions.refetchContractorOffers()],
    updateUserPassword: () => [],
    saveAsTemplate: () => [clientActions.refetchProjectsDetails()],
    createPositionsFromTemplate: action => [
        clientActions.refetchAllDevis(),
        clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId),
        clientActions.refetchProjectAttachments(action.payload.projectId)
    ],
    removePositionsFormat: action => [
        clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId),
        clientActions.refetchAllDevis()
    ],
    removeOfferProposal: () => [clientActions.refetchContractorOffers()],
    setFileForDevis: action => [
        clientActions.refetchAllDevis(),
        clientActions.refetchDevisCollections(action.payload.projectId, action.payload.devisId)
    ],
    bulkMutate: () => [clientActions.refetchContractors()],
    register: () => [clientActions.fetchUserData()],
    mutate: ({ payload }, _result) => {
        const reactions: ClientActions[] = []
        switch (payload.collection) {
            case "devis":
                reactions.push(
                    payload.mutationType === "create" ? clientActions.fetchAllDevis() : clientActions.refetchAllDevis()
                )
                reactions.push(clientActions.refetchUserDevisOverview())
                reactions.push(clientActions.refetchDevisCollections(payload.item.projectId, payload.item.devisId))
                if (payload.mutationType === "create")
                    reactions.push(clientActions.refetchProjectAttachments(payload.item.projectId))

                break
            case "contractors":
                reactions.push(clientActions.refetchContractors())
                reactions.push(clientActions.refetchUserDevisOverview())
                break
            case "projects":
                reactions.push(
                    payload.mutationType === "create"
                        ? clientActions.fetchProjectsDetails()
                        : clientActions.refetchProjectsDetails()
                )
                reactions.push(clientActions.refetchProjectPredefinedCollections(payload.item.projectId))
                break
            case "devisTemplates":
                reactions.push(clientActions.refetchDevisTemplates())
                const authState = getStore().getState().auth.auth
                if (authState.type === "Authenticated" && authState.value.type !== "WD_MODERATOR")
                    reactions.push(clientActions.refetchUserBasedWorkDescriptionTemplates())
                else reactions.push(clientActions.refetchPredefinedWorkDescriptionTemplates())
                break
            case "attachments":
                if (payload.source.type === "project")
                    reactions.push(
                        clientActions.refetchProjectAttachments(payload.source.projectId, [payload.item.attachmentId])
                    )
                if (payload.source.type === "template") {
                    reactions.push(
                        clientActions.refetchDevisTemplateCollections(payload.source.templateId, [
                            payload.item.attachmentId
                        ])
                    )
                    break
                }
            case "positions":
            case "sections":
                if (payload.source.type === "template") {
                    reactions.push(clientActions.refetchDevisTemplateCollections(payload.source.templateId))
                    break
                }
            default:
                if (payload.source.type === "project")
                    reactions.push(clientActions.refetchProjectPredefinedCollections(payload.source.projectId))
                else if (payload.source.type === "devis")
                    reactions.push(
                        clientActions.refetchDevisCollections(payload.source.projectId, payload.source.devisId)
                    )
        }
        return reactions
    }
}

const nonDebouncableActions: ClientActions["type"][] = ["fetchAllDevis", "fetchProjectsDetails"]

export const reducer = mkReducer<CloudState>((state, action, ctx) => {
    if (action.type === "_setResult")
        return { results: { ...state.results, [action.payload.meta.actionId]: action.payload } }
    const cloudAction = action as ClientCloudAction
    if (isDefined(actions[(cloudAction as ClientCloudAction).type]) && ctx.auth.type === "Authenticated")
        return cloudCmd(ctx.auth.value.token, cloudAction, refetchMap[cloudAction.type] as any, nonDebouncableActions)
    return {}
})
