import * as React from "react"
import styled from "styled-components"
import { Button } from "@smartdevis/ui/src/Button"
import { i18n } from "@smartdevis/client/src/services/translations"
import { useCloudAction } from "../../hooks/useCloudAction"
import { SubmitButton } from "../../components"
import { FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { StyledForm } from "../../components/forms"
import { getStyledProjectSchema, mkProjectSchema, ProjectFormProps, ProjectPayload } from "./ProjectEdit.helpers"
import { themeColor } from "@smartdevis/ui/src/utils/theme"

import { useFormHook } from "@smartdevis/forms/src"
import { Domain } from "@smartdevis/server/src/domain"
import { isOk } from "@smartdevis/utils/src/result"

const PROJECT_VERSION = 1

const Form = styled.form`
    max-width: 800px;
`

const AdditionalForm = styled.div<{ active?: boolean }>`
    padding: 16px;
    background-color: ${p => (p.active ? themeColor("grey35") : "white")};
    margin-bottom: 16px;
`

const emptyAddressInfo: Domain.AddressInfo = {
    companyName: "-",
    name: "-",
    surname: "-",
    email: "noreply@smartdevis.ch",
    phoneNumber: "-",
    street: "-",
    postalCode: "-",
    city: "-",
    createdTs: 0
}

export const TemplateForm: React.FC<ProjectFormProps> = p => {
    const { actionState, onSubmit } = useCloudAction<ProjectPayload>(
        (actionId, res) => {
            p.onSubmit({
                ...res,
                client: emptyAddressInfo,
                billing: emptyAddressInfo,
                planner: emptyAddressInfo,
                actionId
            })
        },
        p.results,
        r => (isOk(r) ? p.onDone(p.projectDetails?.projectId ? p.projectDetails.projectId : r.value) : null)
    )
    const { formViewProps, handleSubmit, result } = useFormHook({
        schema: mkProjectSchema(),
        onSubmit: v => {
            if (result.type === "Ok") onSubmit(v)
        },
        initialValue: p.projectDetails
            ? p.projectDetails
            : { projectType: "new", version: PROJECT_VERSION, isTemplate: true }
    })

    return (
        <FlexRow justifyCenter>
            <Form onSubmit={handleSubmit}>
                <AdditionalForm>
                    <StyledForm {...formViewProps} styledSchema={getStyledProjectSchema()} />
                </AdditionalForm>
                <FlexRow alignCenter justifyEnd>
                    <Button type="button" btnType="action" onClick={p.onCancel} data-cy="projects-cancel">
                        {i18n("Cancel")}
                    </Button>

                    <HorizontalSpace base="16px" />
                    <SubmitButton
                        data-cy="projects-submit"
                        loading={actionState.type === "Processing"}
                        disabled={actionState.type === "Processing"}
                        type="submit">
                        {i18n(p.submitText)}
                    </SubmitButton>
                </FlexRow>
            </Form>
        </FlexRow>
    )
}
