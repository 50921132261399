import * as React from "react"
import { EmptyObject } from "@smartdevis/utils/src/types"

type SetStateFieldFn<T extends EmptyObject> = <K extends keyof T>(name: K) => (v: T[K]) => void
type SetStateFieldsFns<T extends EmptyObject> = (v: Partial<T>) => void

export const useMapState = <T extends EmptyObject>(initialMap: T): [T, SetStateFieldFn<T>, SetStateFieldsFns<T>] => {
    const [map, setMap] = React.useState(initialMap)
    return [map, name => v => setMap({ ...map, [name]: v }), v => setMap({ ...map, ...v })]
}
