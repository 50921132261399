import { Domain } from "./domain"
import { CloudEventName, CloudEventPayload, DeprecatedEvents } from "./cloudEvents"
import { userValidation } from "./models/user"

import { validateAttachment, validateAttachmentData } from "./models/attachment"
import {
    validateDevis,
    validateDevisDuplicationMap,
    validateDevisTemplate,
    validateDevisWithoutId
} from "./models/devis"
import {
    validateSection,
    validateGeneralInformation,
    validatePosition,
    validateCondition,
    validateDeduction,
    validateAdditionalInformation
} from "./models/position"
import { mutable, validateTranslation, validMutationType } from "./models/common"
import { projectValidation } from "./models/project"
import { validateContractor } from "./models/contractor"
import { changelogValidation } from "./models/changelog"
import {
    architectOfferRequestValidation,
    validateArchitectOfferRequest,
    _deprecatedArchitectOfferRequestValidation,
    validateRound,
    validateRoundDelta,
    offerInformationValidator,
    validateOfferMeta,
    nullableOfferValuesValidator
} from "./models/offers"
import { pickObject, omitObject } from "@smartdevis/utils/src/map"
import { mkOk } from "@smartdevis/utils/src/result"
import {
    validString,
    validateMap,
    validBoolean,
    validArrayString,
    Validators,
    validNumber,
    optionalBoolean,
    optionalId,
    validId,
    validIds,
    ValidationMap,
    Validator,
    optional,
    optionalString,
    optionalNumber
} from "@smartdevis/utils/src/validators"
import { validateProjectDetailsServer } from "./models/projectDetails"
import { partnerValidation } from "./models/partner"

export const collectionValidators: {
    [K in keyof Domain.AllCollections]: Validator<Domain.CollectionItem<Domain.AllCollections[K]>>
} = {
    sections: validateSection,
    attachments: validateAttachment,
    devis: validateDevis,
    positions: validatePosition,
    generalInformation: validateGeneralInformation,
    additionalInformation: validateAdditionalInformation,
    conditions: validateCondition,
    deductions: validateDeduction,
    deltas: validateRoundDelta,
    requests: validateArchitectOfferRequest,
    rounds: validateRound,
    projects: validateProjectDetailsServer,
    devisTemplates: validateDevisTemplate,
    contractors: validateContractor
}

// Mutate and BulkMutate actions are Unions, therefore cannot have ValidationMaps. Validation for those action happens in
export const actionValidations: {
    [P in Exclude<CloudEventName, "mutate" | "bulkMutate" | DeprecatedEvents>]: ValidationMap<CloudEventPayload<P>>
} = {
    updateTranslations: { values: [validateMap<Domain.TranslationCopy>([validateTranslation])] },
    register: omitObject(userValidation, ["logoUrl", "taxNumber", "userId"]),
    updateUser: omitObject(userValidation, ["userId", "agreedOn", "lastVisitVersion", "type"]),
    updateTerms: pickObject(userValidation, ["agreedOn"]),
    updateVisit: pickObject(userValidation, ["lastVisitVersion"]),
    createRequests: {
        ...pickObject(architectOfferRequestValidation, ["devisId", "projectId", "createdTs"]),
        message: optionalString,
        submittersIds: validArrayString as Validators<string[]>,
        entryTs: optionalNumber
    },
    sendReminders: {
        ...pickObject(architectOfferRequestValidation, ["devisId", "projectId", "createdTs"]),
        message: optionalString,
        offersIds: validIds
    },
    updateRequestsState: {
        ...pickObject(architectOfferRequestValidation, ["projectId", "state", "devisId"]),
        offersIds: validIds,
        offerMeta: [validateOfferMeta]
    },
    updateFinalOffer: {
        ...pickObject(architectOfferRequestValidation, ["projectId", "devisId"]),
        valuesByOffer: [validateMap([validateMap<number>(validNumber)])]
    },
    createRound: { ...pickObject(architectOfferRequestValidation, ["projectId"]), round: [validateRound] },
    sendRound: {
        ...pickObject(architectOfferRequestValidation, ["projectId", "devisId"]),
        roundId: validId,
        message: optionalString,
        ts: validNumber
    },

    submitRoundDelta: { projectId: validId, devisId: validId, delta: [validateRoundDelta] },
    revertRoundDelta: { projectId: validId, devisId: validId, deltaId: validId, roundId: validId },

    adoptOffer: { offerId: validId },
    updateOfferValues: {
        offerId: validId,
        values: nullableOfferValuesValidator,
        comment: optionalString,
        additionalInformation: offerInformationValidator,
        positionComments: [mkOk],
        offerProposal: [optional<Domain.AttachmentData>(undefined as any)(validateAttachmentData)]
    },
    mutateOfferAttachment: {
        offerId: validId,
        attachment: [validateAttachment],
        mutationType: validMutationType
    },
    submitOffer: { offerId: validId },
    rejectOffer: { offerId: validId },
    rejectFinalProposal: { offerId: validId },
    updateOfferState: pickObject(architectOfferRequestValidation, ["offerId", "state"]),

    duplicateProject: {
        ...pickObject(projectValidation, ["name", "projectId"]),
        newProjectId: validString,
        systemTemplate: optionalBoolean
    },
    duplicateDevis: {
        duplicationMap: [validateDevisDuplicationMap],
        details: [validateDevisWithoutId],
        devisId: validId,
        newProjectId: optionalId,
        projectId: validId
    },
    saveAsTemplate: {
        ...pickObject(projectValidation, ["name", "projectId"]),
        newProjectId: validString,
        clearValues: validBoolean
    },
    mutateChangelog: mutable(changelogValidation),
    mutatePartners: mutable(partnerValidation),
    createPositionsFromTemplate: { templateId: validId, devisId: validId, projectId: validId },
    removePositionsFormat: { projectId: validId, devisId: validId },
    removeOfferProposal: { offerId: validId },
    setFileForDevis: { projectId: validId, devisId: validId, file: [validateAttachmentData] }
}
