import * as React from "react"
import styled, { css } from "styled-components"
import { rem } from "./utils"
import { H3, H4, H6 } from "./Typography"
import { themeColor } from "./utils/theme"
import { cutString } from "@smartdevis/utils/src/text"
import { Asset, AssetContainer } from "./Asset"
import { DropdownMenuOption, Dropdown } from "./Dropdown"
import { Center, FlexColumn, styleIfProp, VerticalSpace } from "./utils/common"
import { InlineSpinner } from "./Spinner"
import { PureLink } from "./Link"
import { omitObject } from "../../utils/src/map"
import { _noop } from "../../utils/src/misc"
import { F0, ValueState, TypedOmit } from "../../utils/src/types"

export const TileLinkWrapper: React.FC<
    Pick<TileItem, "linkTo"> & { style?: React.CSSProperties; clickable?: boolean; onClick?: F0 }
> = p =>
    p.linkTo ? (
        <TileLinkContainer style={p.style} to={p.linkTo} clickable>
            {p.children}
        </TileLinkContainer>
    ) : (
        <TileContainer style={p.style} clickable={p.clickable} onClick={p.onClick}>
            {p.children}
        </TileContainer>
    )

export const Tile: React.FC<TileItem> = p => (
    <TileLinkWrapper linkTo={p.linkTo}>
        <TileTitle>{cutString(p.title, 40)}</TileTitle>
        {p.description && <TileDescription>{cutString(p.description, 88)}</TileDescription>}
        {p.status && p.status.type === "text" ? (
            <TileSubText>{cutString(p.status.value, 30)}</TileSubText>
        ) : (
            p.status?.value || null
        )}
        {p.options ? (
            <TileActionsContainer>
                <Dropdown options={p.options || []}>
                    <Asset name="DotsFilled" size="icon" />
                </Dropdown>
            </TileActionsContainer>
        ) : null}
    </TileLinkWrapper>
)

export type CreateTileProps = { title: string; loading?: boolean } & (
    | { withDropdown: false; onClick: F0 }
    | { withDropdown: true; options: TileOption[] }
)
export const CreateTile: React.FC<CreateTileProps> = p => {
    const core = (
        <TileContainer clickable filled onClick={p.withDropdown ? _noop : p.onClick}>
            <CrossContainer>
                <FlexColumn alignCenter>
                    {p.loading ? (
                        <AssetContainer size="big-icon">
                            <InlineSpinner color="primary" />
                        </AssetContainer>
                    ) : (
                        <Asset name="CrossFilledRed" size="big-icon" />
                    )}
                    <VerticalSpace base="8px" />
                    <H4>{p.title}</H4>
                </FlexColumn>
            </CrossContainer>
        </TileContainer>
    )
    return p.withDropdown ? <Dropdown options={p.options}>{core}</Dropdown> : core
}

export type TileItem = {
    id: string
    title: string
    description?: string
    linkTo?: string
    status?: ValueState<"text", string> | ValueState<"component", React.ReactNode>
    options?: TileOption[]
}

export type TileOption = DropdownMenuOption
export const mkTile = (id: string, title: string, rest: TypedOmit<TileItem, "id" | "title"> = {}): TileItem => ({
    id,
    title,
    ...rest
})

export const Tiles: React.FC<{
    items: TileItem[]
    createProps?: CreateTileProps
}> = p => (
    <TilesContainer>
        {p.createProps ? <CreateTile {...p.createProps} /> : null}
        {p.items.map(item => (
            <Tile key={item.id} {...item} />
        ))}
    </TilesContainer>
)

export const TilesContainer = styled.div<{ listMode?: boolean }>`
    display: flex;
    flex-direction: ${p => (p.listMode ? "column" : "row")};
    flex-wrap: wrap;
    margin: ${p => (p.listMode ? "0px" : "0 -8px")};
    & > * {
        margin: ${p => (p.listMode ? "none" : "10px 8px")};
    }
`

export const CrossContainer = styled(Center)`
    height: 100%;
`

export const TileContainerStyles = css<{ filled?: boolean; clickable?: boolean }>`
    height: ${rem(200)};
    width: ${rem(308)};
    display: flex;
    flex-direction: column;
    position: relative;

    border: 1px solid ${themeColor("grey50")};
    box-sizing: border-box;
    box-shadow: 0px 1px 5px ${themeColor("shadowGrey")};
    border-radius: 12px;
    transition: background-color 0.3s;
    ${styleIfProp(
        "clickable",
        css`
            cursor: pointer;
            &:hover {
                box-shadow: 0px 1px 5px 3px ${themeColor("shadowGrey")};
            }
        `
    )}
    ${styleIfProp(
        "filled",
        css`
            background-color: ${themeColor("grey40")};
            border: 1px solid ${themeColor("grey40")};
            &:hover {
                background-color: ${themeColor("grey50")};
            }
        `
    )}
    padding: 24px;
`

const TileLinkContainer = styled(
    (p: React.ComponentProps<typeof PureLink> & { filled?: boolean; clickable?: boolean }) => (
        <PureLink {...omitObject(p, ["clickable", "filled"])} />
    )
)<{ filled?: boolean; clickable?: boolean }>`
    ${TileContainerStyles}
`

const TileContainer = styled(p => <div {...omitObject(p, ["clickable", "filled"])} />)<{
    clickable?: boolean
    filled?: boolean
}>`
    ${TileContainerStyles}
`

export const TileTitle = styled(H3)`
    max-height: 2.6em;
    overflow: hidden;
    position: absolute;
    left: 24px;
    top: 24px;
    right: 24px;
    line-height: 1.3;
    word-wrap: break-word;
`

export const TileDescription = styled(H6)`
    position: absolute;
    left: 24px;
    right: 24px;
    white-space: pre-wrap;
    color: ${themeColor("grey70")};
    top: 50%;
`

export const TileSubText = styled(H6)`
    position: absolute;
    left: 24px;
    color: ${themeColor("black")};
    bottom: 24px;
`

export const TileActionsContainer = styled.div`
    position: absolute;
    right: 24px;
    bottom: 24px;
`
