import * as React from "react"
import styled from "styled-components"
import { Asset, ExternalAsset } from "./Asset"
import { DropdownMenuOption, DropdownProps, Dropdown, mkDropdownOption } from "./Dropdown"
import { themeColor, themeConfig } from "./utils/theme"
import { useArrayState, useDeepEffect, useRenderedEffect } from "./hooks/common"
import { HorizontalSpace, styleIfProp } from "./utils/common"
import { noPropagationCallback } from "./utils/dom"
import { rem } from "./utils"
import { BaseInput, Input } from "./Inputs"
import { useKeyPress } from "./hooks/useKeyPress"
import { arrify } from "@smartdevis/utils/src/array"
import { usleep } from "@smartdevis/utils/src/async"
import { pickObject, toMap } from "@smartdevis/utils/src/map"
import { Serializable, serialize, identity, isEqual, _noop } from "@smartdevis/utils/src/misc"
import { F1, F0 } from "@smartdevis/utils/src/types"
import { isEmpty } from "@smartdevis/utils/src/validators"

export type SelectProps<T = string> = {
    disabled?: boolean
    fullWidth?: boolean
    fullHeight?: boolean
    placeholder?: string
} & Pick<DropdownProps<T>, "options"> &
    (
        | { mode: "select"; onChange?: F1<T>; value?: T }
        | { mode: "multiselect" | "combobox"; onChange?: F1<T[]>; value?: T[]; searchable?: boolean }
    )

const ValueTag: React.FC<{ label: string; onRemove: F0; onClick?: F0 }> = p => (
    <ValueTagContainer onClick={p.onClick ? noPropagationCallback(p.onClick) : undefined}>
        {p.label}
        <ValueTagDeleteContainer onClick={noPropagationCallback(p.onRemove)}>
            <Asset size="micro" name="Close" color="black" />
        </ValueTagDeleteContainer>
    </ValueTagContainer>
)

type SelectStateHandlers<T extends Serializable> = {
    open: boolean
    setOpen: F1<boolean>
    selectedOptions: DropdownMenuOption<T>[]
    toggle: F1<T>
    setSelectedValues: F1<T[] | F1<T[], T[]>>
}
const Combobox = <T extends Serializable = string>({
    selectedOptions,
    toggle,
    open,
    setOpen,
    setSelectedValues,
    ...p
}: React.PropsWithChildren<SelectProps<T> & SelectStateHandlers<T>>) => {
    if (p.mode === "select") throw Error("Combobox cannot work with single-select option type") // type guard workaround
    const [inputValue, setInputValue] = React.useState("")
    const [bkspcFlag, setBkspcFlag] = React.useState(true) // when flag == true, pressing backspace will pop selected item
    const focusFlag = React.useRef(false)

    const inputRef = React.useRef<HTMLInputElement>(null)

    const saveValue = () => {
        if (!inputValue || p.searchable) return
        if (!selectedOptions.find(o => o.value === inputValue)) {
            setOpen(false)
            setTimeout(() => {
                setInputValue("")
                toggle(inputValue as T)
            }, 200)
        }
    }
    const removeValue = (index: number) => setSelectedValues(vs => vs.filter((_, i) => i !== index))

    useKeyPress(
        key => {
            switch (key) {
                case "enter":
                    return saveValue()
                case "backspace":
                    if (!bkspcFlag || !focusFlag.current) return
                    removeValue(selectedOptions.length - 1)
            }
        },
        ["enter", "backspace"]
    )

    React.useEffect(() => {
        if (inputValue) setOpen(true)
        if (!inputValue) usleep(100).then(() => setBkspcFlag(true)) // 100ms heuristic (if we not wait, line 47 will pass despite the input being not empty
        if (inputValue.length >= 1) setBkspcFlag(false)
    }, [inputValue, setOpen])

    const options = p.options
        .filter(o => !inputValue || o.label.includes(inputValue))
        .concat(selectedOptions.filter(so => !p.options.find(o => o.value === so.value)))
        .map(o => (selectedOptions.find(so => so.value === o.value) ? { ...o, selected: true } : o))

    return (
        <Dropdown
            fullWidth
            options={options}
            open={open}
            disabled={p.disabled}
            onClose={src => {
                if (src === "blur") saveValue()
                setOpen(false)
            }}
            onOptionClick={async o => {
                await usleep(100) // 100ms is the time it takes to animate dropdown closing
                toggle(o)
                setInputValue("")
            }}>
            <SelectContainer
                fullWidth={p.fullWidth}
                open={open}
                onClick={() => {
                    inputRef.current?.focus()
                    setOpen(true)
                }}
                {...pickObject(p, ["disabled", "mode"])}>
                <MultipleValuesContainer applyMargin={!!selectedOptions.length}>
                    {selectedOptions.map((v, i) => (
                        <ValueTag
                            key={serialize(v.value)}
                            label={v.label}
                            onClick={() => {
                                removeValue(i)
                                setInputValue(v.label)
                                inputRef.current?.focus()
                            }}
                            onRemove={() => toggle(v.value)}
                        />
                    ))}
                    {selectedOptions.length > 0 && <HorizontalSpace base="4px" />}
                    <Input
                        type="text"
                        ref={inputRef}
                        value={inputValue}
                        onFocus={() => (focusFlag.current = true)}
                        onBlur={() => (focusFlag.current = false)}
                        onChange={e => setInputValue(e.target.value)}
                        placeholder={isEmpty(selectedOptions) ? p.placeholder : undefined}
                    />
                </MultipleValuesContainer>

                {options.length > 0 && (
                    <IconContainer>
                        <Asset size="small-icon" name="ArrowDown" color="grey60" />
                    </IconContainer>
                )}
            </SelectContainer>
        </Dropdown>
    )
}

const SelectValues = <T extends Serializable = string>({
    mode = "select",
    ...p
}: Pick<SelectProps<T>, "mode" | "placeholder" | "options"> & {
    selectedOptions: DropdownMenuOption<T>[]
    onRemove?: F1<T>
}) => {
    if (isEmpty(p.selectedOptions)) return <Placeholder>{p.placeholder || ""}</Placeholder>
    switch (mode) {
        case "combobox":
            // eslint-disable-next-line no-console
            console.error("Combobox should not be displayed through traditional select")
            return null
        case "multiselect":
            return (
                <MultipleValuesContainer applyMargin>
                    {p.selectedOptions.map(v => (
                        <ValueTag key={serialize(v.value)} label={v.label} onRemove={() => p.onRemove?.(v.value)} />
                    ))}
                </MultipleValuesContainer>
            )
        case "select": {
            const selected = [...p.selectedOptions].pop()
            if (!selected) return <></>
            if (!selected.label && selected.image) return <ExternalAsset size="dropdown" src={selected.image} />
            return <>{selected.label}</>
        }
    }
}

export const Select = <T extends Serializable = string>(p: React.PropsWithChildren<SelectProps<T>>) => {
    const [open, setOpen] = React.useState(false)
    const [focused, setFocused] = React.useState(false)
    const inputRef = React.useRef<HTMLInputElement | null>(null)
    const {
        items: selectedValues,
        setItems: setSelectedValues,
        toggle
    } = useArrayState<T>(p.value !== undefined ? arrify(p.value) : [])

    const optionsMapByValue = React.useMemo(() => toMap(p.options, tk => serialize(tk.value), identity), [p.options])

    const show = () => {
        setOpen(true)
        inputRef.current?.focus()
    }

    const hide = () => {
        setOpen(false)
        inputRef.current?.blur()
    }

    useKeyPress(() => {
        if (open) setOpen(false)
        if (focused) setOpen(true)
    }, ["tab"])

    useRenderedEffect(() => {
        switch (p.mode) {
            case "combobox":
            case "multiselect":
                p.onChange?.(selectedValues)
                break
            case "select":
                p.onChange?.(selectedValues[0])
        }
    }, [selectedValues])

    useDeepEffect(p.value, () => {
        if (!p.value) return
        if (!isEqual(arrify(p.value), selectedValues)) setSelectedValues(arrify(p.value))
    })

    const selectedOptions = selectedValues.map(i => optionsMapByValue[serialize(i)] ?? mkDropdownOption(i as string, i))

    if (p.mode === "combobox" || (p.mode === "multiselect" && p.searchable))
        return (
            <Combobox
                {...p}
                open={open}
                setOpen={setOpen}
                selectedOptions={selectedOptions}
                setSelectedValues={setSelectedValues}
                toggle={toggle}
            />
        )

    const multiMode = p.mode === "multiselect"

    return (
        <Dropdown
            fullWidth
            noTrigger
            open={open}
            disabled={p.disabled}
            onClose={hide}
            options={p.options.map(o => (selectedValues.includes(o.value) && multiMode ? { ...o, selected: true } : o))}
            onOptionClick={clickedItem => (multiMode ? toggle(clickedItem) : setSelectedValues([clickedItem]))}>
            <SelectContainer open={open || focused} {...pickObject(p, ["disabled", "mode", "fullWidth", "fullHeight"])}>
                <InputContainer>
                    <SelectInput
                        type="search"
                        value=""
                        readOnly
                        ref={inputRef}
                        onClick={() => (open ? hide() : show())}
                        onChange={_noop}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                    />
                </InputContainer>
                <SelectValues
                    selectedOptions={selectedOptions}
                    {...pickObject(p, ["placeholder", "mode", "options"])}
                    onRemove={toggle}
                />
                {!p.disabled && (
                    <IconContainer>
                        <Asset size="small-icon" name="ArrowDown" color="grey60" />
                    </IconContainer>
                )}
            </SelectContainer>
        </Dropdown>
    )
}
const FAKE_INPUT_ZINDEX = 5

const ValueTagContainer = styled.span`
    border: 1px solid ${themeColor("grey50")};
    background-color: ${themeColor("grey50")};
    border-radius: 2px;
    display: flex;
    white-space: pre-wrap;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    z-index: ${FAKE_INPUT_ZINDEX + 1};
    font-size: 0.88rem;
    padding-left: 6px;
    flex-shrink: 0;
    cursor: pointer;
    margin: 2px;
`

const ValueTagDeleteContainer = styled.span`
    padding: 0 4px;
`

const MultipleValuesContainer = styled.div<{ applyMargin: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: ${styleIfProp("applyMargin", "-2px", "0")};
`

const IconContainer = styled.span`
    position: absolute;
    z-index: ${FAKE_INPUT_ZINDEX - 1};
    pointer-events: none;
    right: 6px;
    top: calc(50% - 7px);
`

const Placeholder = styled.span`
    color: ${themeColor("primaryGrey")};
    pointer-events: none;
    opacity: 0.8;
`

// eslint-disable-next-line prettier/prettier
export const SelectContainer = styled.div<Pick<SelectProps, "disabled" | "mode" | "fullWidth" | "fullHeight"> & { open: boolean }>`
    font-family: "${themeConfig("fontFamily")}", sans-serif;
    color: ${themeColor("textBlack")};
    background: ${p => (p.disabled ? themeColor("grey40") : themeColor("white"))};
    border: 1px solid ${themeColor("grey50")};
    box-sizing: border-box;
    border-radius: 4px;
    ${styleIfProp("fullWidth", "width: calc(100% - 36px);")}
    ${styleIfProp("fullHeight", "height: calc(100% - 14px);")}
    color: ${themeColor("primaryGrey")};
    transition: all 0.3s ease-in-out;
    font-size: 0.88rem;
    box-sizing: content-box;
    padding: 6px 24px 6px 12px;
    min-width: 50px;
    max-width: 800px;
    display: flex;
    align-items: center;
    min-height: ${rem(20)};
    position: relative;
    cursor: ${p => (p.disabled ? "auto" : p.mode === "combobox" ? "text" : "pointer")};
    ${p => (p.open ? `border-color: ${themeColor("primary")(p)}` : "")};

    ${BaseInput} {
        padding: 0;
        margin: 2px;
        flex: 1;
        min-width: 200px;
        border: none;
    }
`

const InputContainer = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

const SelectInput = styled.input`
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    z-index: ${FAKE_INPUT_ZINDEX};
    appearance: none;
    user-select: none;
    border: none;
`
