import * as React from "react"
import {
    TableBaseCell,
    CellVisual,
    TableInputCell,
    TableCellStaticWrapper,
    CellOpenIndicatorWrapper,
    TableCellTagWrapper,
    TableCellEditableWrapper
} from "./TableCell.styles"

import { NumberInput, TextInput, TextAreaInput, SelectInput, NumberFormattedInput } from "../forms/formRenderers"
import { CheckboxButton, RadioButton, SwitchButton } from "@smartdevis/ui/src/Button"
import { Asset, ImageAsset } from "@smartdevis/ui/src/Asset"
import { FlexRow, HorizontalSpace, Margin } from "@smartdevis/ui/src/utils/common"
import { Popover } from "@smartdevis/ui/src/Popover"
import { InputBoxRenderFn, InputOptionRenderFn } from "@smartdevis/forms/src"
import { range } from "@smartdevis/utils/src/misc"
import { F0, F1 } from "@smartdevis/utils/src/types"

export type CellEditMode<T> =
    | { editMode?: "static" }
    | { editMode: "formless"; field: keyof T }
    | { editMode: "custom"; editValue: Cell<T> }

export type Cell<T = any> = {
    visuals?: CellVisual[]
    value: React.ReactNode
} & CellEditMode<T>

export const fillEmpty = (n: number, vs?: Cell[]): Cell[] => range(n).map(i => (vs?.[i] ? vs[i] : mkCellEmpty()))

export const mkCell = <T extends any = any>(
    value: React.ReactNode,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> => ({ ...editMode, value, visuals })

export const mkAssetPlaceholderCell = <T extends any = any>(
    content: React.ReactNode,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> => mkCell(<Margin values="0 0 0 28px">{content}</Margin>, visuals, editMode)

export const mkDragHandleCell = <T extends any = any>(
    content: React.ReactNode,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> =>
    mkCell(
        <FlexRow alignCenter>
            <Asset name="Draggable" cursor="move" size="icon" />
            <HorizontalSpace base="8px" />
            {content}
        </FlexRow>,
        visuals,
        editMode
    )

export const mkAssetCell = <T extends any = any>(
    p: {
        content: React.ReactNode
        asset: ImageAsset
        onClick?: F0
    },
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> =>
    mkCell(
        <FlexRow
            alignCenter
            justifyEnd={visuals?.includes("alignRight")}
            justifyCenter={visuals?.includes("centerHorizontally")}>
            <Asset name={p.asset} onClick={p.onClick} size="icon" />
            <HorizontalSpace base="8px" />
            {p.content}
        </FlexRow>,
        visuals,
        editMode
    )

export const mkPopoverCell = <T extends any>(
    content: React.ReactNode,
    popoverContent?: React.ReactNode,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
) =>
    mkCell<T>(
        popoverContent ? (
            <Popover direction="bottom-left" content={popoverContent}>
                <FlexRow alignCenter justifyEnd={visuals?.includes("alignRight") ?? false}>
                    <Asset name="Eye" size="small-icon" />
                    <HorizontalSpace base="8px" />
                    {content}
                </FlexRow>
            </Popover>
        ) : (
            content
        ),
        visuals,
        editMode
    )

export const mkSwitchCell = <T extends any = any>(
    isChecked: boolean,
    onCheck: F1<boolean>,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> =>
    mkCell(
        <SwitchButton data-cy="switch-cell" checked={isChecked} onChange={onCheck} />,
        (visuals || []).concat("centerHorizontally"),
        editMode
    )

export const mkCheckboxCell = <T extends any = any>(
    isChecked: boolean,
    onCheck: F1<boolean>,
    disabled?: boolean,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> =>
    mkCell(
        <CheckboxButton disabled={disabled} checked={isChecked} onChange={onCheck} />,
        (visuals || []).concat("centerHorizontally"),
        editMode
    )

export const mkRadioCell = <T extends any = any>(
    isChecked: boolean,
    onCheck: F1<boolean>,
    disabled?: boolean,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> =>
    mkCell(
        <RadioButton disabled={disabled} checked={isChecked} onChange={onCheck} />,
        (visuals || []).concat("centerHorizontally"),
        editMode
    )

export const mkCellTag = <T extends any = any>(
    text: string,
    icon?: ImageAsset,
    visuals?: CellVisual[],
    editMode?: CellEditMode<T>
): Cell<T> =>
    mkCell(
        <TableCellTagWrapper>
            {icon && <Asset name={icon} size="icon" />}
            {text}
        </TableCellTagWrapper>,
        visuals,
        editMode
    )

export const mkCellEmpty = <T extends any = any>(visuals?: CellVisual[], editMode?: CellEditMode<T>): Cell<T> =>
    mkCell("", visuals, editMode)

export const TableCell: React.FC<{ value: Cell; onClick?: F1<React.MouseEvent> }> = p => {
    const cell = p.value
    const visuals = cell.visuals ?? []
    const Wrapper =
        cell.editMode === "formless" || visuals.includes("openIndicator")
            ? TableCellEditableWrapper
            : TableCellStaticWrapper

    return (
        <TableBaseCell onClick={p.onClick} visuals={visuals}>
            <Wrapper>
                {visuals.includes("openIndicator") ? (
                    <CellOpenIndicatorWrapper>
                        <Asset name="Stretchable" size="icon" />
                    </CellOpenIndicatorWrapper>
                ) : null}
                {cell.value}
            </Wrapper>
        </TableBaseCell>
    )
}

export const TableNumberInput: InputBoxRenderFn = p => (
    <TableInputCell>
        <NumberInput {...p} />
    </TableInputCell>
)
export const TableCustomBoxInput: InputBoxRenderFn = p => (
    <TableInputCell>
        <NumberFormattedInput {...p} />
    </TableInputCell>
)

export const TableTextInput: InputBoxRenderFn = p => (
    <TableInputCell>
        <TextInput {...p} />
    </TableInputCell>
)
export const TableTextAreaInput: InputBoxRenderFn = p => (
    <TableInputCell>
        <TextAreaInput {...p} />
    </TableInputCell>
)

export const TableSelectInput: InputOptionRenderFn = p => (
    <TableInputCell>
        <SelectInput {...p} />
    </TableInputCell>
)
