import * as React from "react"
import { Button } from "@smartdevis/ui/src/Button"
import { useCloudAction } from "../../hooks/useCloudAction"
import { i18n } from "../../services/translations"
import { asyncConnect } from "../../resolvers"
import { ArchitectContent } from "../../components/layouts/Content"
import { PageHeader } from "../../components/layouts/Header"

export const Activity = asyncConnect({
    stateResolvers: ["results"],
    actions: "generateActivity"
})(p => {
    const { actionState, onSubmit } = useCloudAction(actionId => {
        p.generateActivity({ actionId })
    }, p.results)

    const busy = React.useMemo(() => actionState.type === "Processing", [actionState.type])

    return (
        <ArchitectContent title="Admin" subTitle={i18n("Activity")}>
            <PageHeader
                title={i18n("Activity")}
                actionButtons={[
                    <Button key="generate" onClick={onSubmit} loading={busy} disabled={busy}>
                        {i18n("Generate activity")}
                    </Button>
                ]}
            />
        </ArchitectContent>
    )
})
