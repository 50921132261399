import * as React from "react"
import styled from "styled-components"
import { Version } from "./Version"
import { ResolutionGuard } from "../../views/ResolutionGuard"
import { themeColor } from "@smartdevis/ui/src/utils/theme"
import { asyncConnect } from "../../resolvers"
import { UpdateTermsModal } from "../modals/UpdateTerms"
import { NewVersionModal } from "../modals/NewVersion"
import { isFetched } from "@smartdevis/utils/src/async"

export const AppLayoutContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
`

const VersionWrapper = styled.div`
    position: fixed;
    bottom: 20px;
    left: 20px;
    margin-top: 6px;
    margin-left: 8px;
    z-index: 9;
    color: ${themeColor("blueGrey")};
    text-transform: uppercase;
    padding-left: 8px;
`

export const AppLayout = asyncConnect({ stateResolvers: ["auth", "asyncUser"] })(p => (
    <>
        <AppLayoutContainer>
            <ResolutionGuard>{p.children}</ResolutionGuard>
            <VersionWrapper>
                <Version />
            </VersionWrapper>
        </AppLayoutContainer>
        {isFetched(p.asyncUser) && <UpdateTermsModal />}
        {isFetched(p.asyncUser) && <NewVersionModal />}
    </>
))
