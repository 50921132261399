import * as React from "react"
import { useItemsMap } from "../../../hooks/utilityHooks"
import { Margin, Padding, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { i18n } from "@smartdevis/client/src/services/translations"
import { getDevisReadonlyRowExplanationText, mkSectionRow, SectionDeleteConfirmationModal } from "../DevisSection"
import { IconButton } from "@smartdevis/ui/src/Button"
import { Row, Table } from "../../../components/table/Table"
import { getRowVisualFromDelta, MutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import { mkDeductionsBySection, mkDeductionSections } from "@smartdevis/server/src/models/shared"
import { Domain } from "@smartdevis/server/src/domain"
import { calculateOrder, Orderable } from "@smartdevis/utils/src/comparable"
import { remap, SMap, values } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { deductionValidation } from "@smartdevis/server/src/models/position"
import { displayTax } from "@smartdevis/server/src/utils/money"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Select } from "@smartdevis/ui/src/Selects"
import { CHF, mkSafePercent } from "@smartdevis/utils/src/numbers"
import { TypedOmit, F0, F2, F1 } from "@smartdevis/utils/src/types"
import { isEmpty, nullableUFloat, validUFloat } from "@smartdevis/utils/src/validators"
import {
    mkFormSchema,
    mkTextareaSchema,
    mkSelectSchema,
    mkFormattedNumberSchema,
    mkNullableFormattedToValue
} from "../../../components/forms/formSchemas"
import { mkCell, mkAssetPlaceholderCell, mkDragHandleCell, fillEmpty } from "../../../components/table/TableCell"
import { HWFillsSelectContainer } from "./ListUtils"

type DeductionsListProps = {
    isReadonly: boolean
    items: SMap<Domain.Deduction>
    sections: SMap<Domain.Section>
    mkItem: (sectionId: string, order: number) => Domain.Deduction
    mkSection: (order: number) => Domain.Section
    itemMutations: MutationManagement<"deductions", Domain.Deduction>
    sectionMutations: MutationManagement<"sections", Domain.Section>
    deltas?: SMap<Domain.RoundDelta>
}
export const DeductionsList = (p: DeductionsListProps) => {
    const { items: hwFillsMap, setItem: setHwFills } = useItemsMap<boolean>(
        remap(p.items, identity, d => (d.value === null ? true : false))
    )
    const [sectionToRemove, setSectionToRemove] = React.useState<Domain.Section | null>(null)

    const deductionsBySection = mkDeductionsBySection(
        { sections: p.sectionMutations.allItems, deductions: p.itemMutations.allItems },
        p.deltas
    )

    const sortedSections = mkDeductionSections({ sections: p.sectionMutations.allItems }, p.deltas)
    const rows = sortedSections.map((section, si) =>
        mkSectionRow(
            section,
            {
                readonly: p.isReadonly,
                onDelete: isEmpty(deductionsBySection[section.sectionId])
                    ? p.sectionMutations.onItemRemove(section)
                    : () => setSectionToRemove(section),
                onSubmit: p.sectionMutations.onItemSubmit(section),
                children: deductionsBySection[section.sectionId]?.map((d, di) =>
                    mkDeductionRow(
                        d,
                        {
                            isEdited: p.itemMutations.isUnsaved(d.deductionId),
                            readonly: p.isReadonly,
                            sectionIndex: si + 1,
                            deductionIndex: di + 1,
                            onDelete: p.itemMutations.onItemRemove,
                            onSubmit: p.itemMutations.onItemSubmit,
                            onRevert: p.itemMutations.onRevertDelta?.(p.deltas?.[d.deductionId]?.deltaId),
                            onHwFills: setHwFills,
                            hwFills: hwFillsMap[d.deductionId]
                        },
                        p.deltas?.[d.deductionId]
                    )
                )
            },
            { index: si + 1, withDragIcon: true },
            p.deltas?.[section.sectionId]
        )
    )

    const onAddSection =
        (delta: Partial<Domain.Section> = {}) =>
        () =>
            p.sectionMutations.onItemSubmit(p.mkSection(values(p.itemMutations.allItems).length))(delta)
    return (
        <>
            <Table<"deduction" | "section" | "subsection" | "header">
                rows={[mkHeaderRow(p.isReadonly), ...rows, mkTaxRow(sortedSections.length)]}
                rowHierarchy={["section", "deduction"]}
                draggable={!p.isReadonly}
                onDrag={(type, index, ids) => {
                    switch (type) {
                        case "deduction": {
                            if (!ids.parentId) return
                            const deds = deductionsBySection[ids.parentId]
                            const order = calculateOrder(
                                deds,
                                deds?.indexOf(p.itemMutations.allItems[ids.draggedId]) ?? -1,
                                index
                            )
                            p.itemMutations.onItemSubmit(p.itemMutations.allItems[ids.draggedId])({
                                sectionId: ids.parentId,
                                order
                            })
                            break
                        }
                        case "section": {
                            const order = calculateOrder(
                                sortedSections,
                                sortedSections.indexOf(p.sectionMutations.allItems[ids.draggedId]),
                                index
                            )
                            p.sectionMutations.onItemSubmit(p.sectionMutations.allItems[ids.draggedId])({ order })
                        }
                    }
                }}
                renderAfterRowMap={{
                    section: ({ rowId, hovered }) => {
                        if (!hovered || p.isReadonly || p.sectionMutations.isUnsaved(rowId))
                            return <VerticalSpace base="36px" />
                        return (
                            <Padding values="0 16px 12px">
                                <IconButton
                                    icon="CrossWhite"
                                    btnType="primary"
                                    onClick={() =>
                                        p.itemMutations.setUnsavedItem(
                                            p.mkItem(rowId, deductionsBySection[rowId]?.length || 0)
                                        )
                                    }>
                                    {i18n("Add deduction")}
                                </IconButton>
                            </Padding>
                        )
                    }
                }}
            />
            <VerticalSpace base="16px" />

            {!p.isReadonly && (
                <IconButton
                    icon="CrossWhite"
                    btnType="secondary"
                    onClick={onAddSection({
                        name: i18n("Sub-total $1", values(p.itemMutations.allItems).length + 1)
                    })}>
                    {i18n("Add sub-total")}
                </IconButton>
            )}
            <SectionDeleteConfirmationModal
                visible={!!sectionToRemove}
                onConfirm={() => (sectionToRemove ? p.sectionMutations.onItemRemove(sectionToRemove)() : null)}
                onClose={() => setSectionToRemove(null)}
            />
            <VerticalSpace base="16px" />
        </>
    )
}

type DeductionFormData = TypedOmit<DeductionPayload, "deductionId" | keyof Orderable | "updatedTs">

const mkDedcutionSchema = (allowEmptyValue?: boolean) =>
    mkFormSchema<DeductionFormData>(deductionValidation, {
        name: mkTextareaSchema(i18n("Name")),
        valueType: mkSelectSchema("Type", [
            [i18n("number"), "number"],
            [i18n("percent"), "percent"]
        ]),
        sign: mkSelectSchema("Sign", [
            [i18n("positive"), "positive"],
            [i18n("negative"), "negative"]
        ]),

        value: mkFormattedNumberSchema(i18n("Amount"), {
            validators: allowEmptyValue ? nullableUFloat : validUFloat,
            toValue: mkNullableFormattedToValue
        })
    })

type DeductionPayload = TypedOmit<Domain.Deduction, "devisId" | "sectionId" | "createdTs">

const mkHeaderRow = (readonly: boolean): Row<"header", DeductionPayload> => ({
    mode: "static",
    type: "header",
    rowId: "header",
    visuals: ["header"],
    noDrag: true,
    grid: [2, 6, 4, 3, 4, 5],
    cells: [
        readonly ? mkCell(i18n("ID")) : mkAssetPlaceholderCell(i18n("ID")),
        mkCell(i18n("Name")),
        mkCell(i18n("Type")),
        mkCell(i18n("Sign")),
        mkCell(i18n("Filler")),
        mkCell(i18n("Value"), ["alignRight"])
    ]
})

const mkDeductionRow = (
    d: Domain.Deduction,
    options: {
        isEdited?: boolean
        readonly: boolean
        sectionIndex: number
        deductionIndex: number
        hwFills?: boolean
        onRevert?: F0
        onHwFills: F2<boolean, string>
        onSubmit: F1<Domain.Deduction, F1<DeductionFormData>>
        onDelete: F1<Domain.Deduction, F0>
    },
    delta?: Domain.RoundDelta
): Row<"deduction", DeductionFormData> => ({
    type: "deduction",
    mode: "editable",
    cells: [
        options.readonly
            ? mkCell(`${options.sectionIndex}.${options.deductionIndex}`)
            : mkDragHandleCell(`${options.sectionIndex}.${options.deductionIndex}`),
        mkCell(d.name, [], { editMode: "formless", field: "name" }),
        mkCell(d.sign === "positive" ? i18n("positive") : i18n("negative"), [], {
            editMode: "formless",
            field: "sign"
        }),
        mkCell(d.valueType === "number" ? i18n("number") : i18n("percent"), [], {
            editMode: "formless",
            field: "valueType"
        }),
        mkCell(options.hwFills ?? d.value === null ? i18n("Contractor") : i18n("Architect"), [], {
            editMode: "custom",
            editValue: mkCell(
                <HWFillsSelectContainer>
                    <Select
                        mode="select"
                        options={[
                            mkDropdownOption(i18n("Contractor"), true),
                            mkDropdownOption(i18n("Architect"), false)
                        ]}
                        value={options.hwFills ?? d.value === null}
                        onChange={v => options.onHwFills(v, d.deductionId)}
                    />
                </HWFillsSelectContainer>
            )
        }),
        mkCell(
            options.hwFills ?? d.value === null ? i18n("Contractor fills") : formatDeductionValue(d),
            options.hwFills ?? d.value === null ? ["alignRight", "italic"] : ["alignRight"],
            options.hwFills
                ? { editMode: "custom", editValue: mkCell(i18n("Contractor fills")) }
                : { editMode: "formless", field: "value" }
        )
    ],
    formSchema: mkDedcutionSchema(options.hwFills),
    actionOnEnter: "submit",
    formValue: d,
    rowId: d.deductionId,
    readonlyClickMessage: getDevisReadonlyRowExplanationText(),
    actionOnBlur: "submit",
    visuals: getRowVisualFromDelta(delta).concat(["noBorder"]),
    onDelete: options.onDelete(d),
    onSubmit: v => options.onSubmit(d)({ ...v, value: options.hwFills ? null : v.value }),
    grid: [2, 6, 4, 3, 4, 5],
    readonly: options.readonly,
    isEdited: options.isEdited
})

export const formatDeductionValue = (d: Pick<Domain.Deduction, "value" | "valueType" | "sign">) =>
    d.valueType === "number"
        ? CHF(d.sign === "negative" && d.value ? -d.value : d.value || 0)
        : mkSafePercent(d.sign === "negative" && d.value ? -d.value : d.value || 0)

const mkTaxRow = (sectionsCount: number): Row<"deduction"> => ({
    type: "deduction",
    mode: "static",
    rowId: "tax",
    visuals: ["noBorder", "title"],
    noDrag: true,
    grid: [2, 6, 4, 3, 4, 5],
    cells: [
        mkCell(<Margin values="0 0 0 28px">{sectionsCount + 1}</Margin>),
        mkCell(i18n("Tax")),
        ...fillEmpty(3),
        mkCell(displayTax(), ["alignRight"])
    ]
})
