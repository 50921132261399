import { arrify } from "@smartdevis/utils/src/array"
import { SMap, mapObject, pickObject } from "@smartdevis/utils/src/map"
import { FMapped, Arrayable } from "@smartdevis/utils/src/types"
import { useDispatch } from "react-redux"
import { clientActions, ClientActionsMap } from "../store"

// eslint-disable-next-line @typescript-eslint/ban-types
type ActionsMap<T extends SMap<Function>> = { [P in keyof T]: FMapped<T[P], void> }

export type StateActionsProps<K extends keyof ClientActionsMap> = ActionsMap<Pick<ClientActionsMap, K>>
export const useStateActions = <K extends keyof ClientActionsMap>(ks: Arrayable<K>): StateActionsProps<K> => {
    const dispatch = useDispatch()
    // eslint-disable-next-line @typescript-eslint/ban-types
    return mapObject(
        pickObject(clientActions, arrify(ks)),
        // eslint-disable-next-line @typescript-eslint/ban-types
        (_, value: Function) =>
            (...args: any) =>
                dispatch(value(...args))
    )
}
