import * as React from "react"
import { getFullName } from "@smartdevis/server/src/models/user"
import { FlexColumn, FlexRow, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Row } from "../../../components/table/Table"
import { mkCell, mkCellEmpty } from "../../../components/table/TableCell"
import { i18n } from "@smartdevis/client/src/services/translations"
import { getComparisonGrid, getPosComparisonGrid, mkComparisonRows } from "../devis-comparison/DevisComparison.rows"
import { Asset } from "@smartdevis/ui/src/Asset"
import { IconButton } from "@smartdevis/ui/src/Button"
import { CalculatedDevis, displayDeductionPriceFromCalculation } from "@smartdevis/server/src/utils/money"
import { mkFormattedNumberSchema, mkNullableFormattedToValue } from "../../../components/forms/formSchemas"
import { AsyncConnectResults } from "../../../resolvers"
import { useCloudAction } from "../../../hooks/useCloudAction"
import { isOfferInState, mkLatestOfferValues } from "@smartdevis/server/src/utils/offer"
import { downloadBlob } from "@smartdevis/client/src/services/downloader"
import { mkNegotiationDoc } from "@smartdevis/client/src/services/doc/docCreators"
import { FormSchema } from "@smartdevis/forms/src"
import { SMap, toMap, pickObject, keys } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { F0, F1, F2 } from "@smartdevis/utils/src/types"
import { displayNumber, mulSafeNumbers } from "@smartdevis/utils/src/numbers"
import { nullableUFloat } from "@smartdevis/utils/src/validators"
import { Domain } from "@smartdevis/server/src/domain"

const mkContractCell = (name: string, onContract?: F0, disabled?: boolean) =>
    mkCell(
        <FlexColumn alignCenter>
            {name && (
                <FlexRow alignCenter justifyCenter>
                    <Asset name="Briefcase" size="icon" />
                    <HorizontalSpace base="8px" />
                    {name}
                </FlexRow>
            )}
            <VerticalSpace base="8px" />
            {onContract && (
                <IconButton icon="Contract" disabled={disabled} onClick={onContract}>
                    {i18n("Contract")}
                </IconButton>
            )}
        </FlexColumn>
    )

const topRow = (
    submitters: Domain.Contractor[],
    type: "p" | "d",
    requestsBySubmitter: SMap<Domain.ArchitectOfferRequest>,
    onContract?: F1<Domain.Contractor>
): Row<"top"> => {
    const finalProposalExists = submitters.some(s =>
        isOfferInState(requestsBySubmitter[s.contractorId], ["final-proposal", "contracted"])
    )
    return {
        type: "top",
        mode: "static",
        rowId: `top-${type}`,
        visuals: ["noBorder", "bottomBorder"],
        cells: [
            mkCellEmpty(),
            ...submitters.map(s =>
                mkContractCell(
                    s.companyName || getFullName(s) || s.email,
                    onContract ? () => onContract(s) : undefined,
                    finalProposalExists &&
                        isOfferInState(requestsBySubmitter[s.contractorId], ["final-proposal", "contracted"])
                )
            )
        ],

        grid: getComparisonGrid(submitters)
    }
}

const bottomRow = (
    submitters: Domain.Contractor[],
    type: "p" | "d",
    requestsBySubmitter: SMap<Domain.ArchitectOfferRequest>,
    onContract?: F1<Domain.Contractor>
): Row<"bottom"> => {
    const finalProposalExists = submitters.some(s =>
        isOfferInState(requestsBySubmitter[s.contractorId], ["final-proposal", "contracted"])
    )
    return {
        type: "bottom",
        mode: "static",
        rowId: `bottom-${type}`,
        visuals: ["noBorder"],
        cells: [
            mkCellEmpty(),
            ...submitters.map(s =>
                mkContractCell(
                    "",
                    onContract ? () => onContract(s) : undefined,
                    finalProposalExists &&
                        isOfferInState(requestsBySubmitter[s.contractorId], ["final-proposal", "contracted"])
                )
            )
        ],

        grid: getComparisonGrid(submitters)
    }
}

const mkPosSchema = (ids: string[]): FormSchema<SMap<number>> =>
    toMap(ids, identity, () =>
        mkFormattedNumberSchema(i18n("Price"), {
            validators: nullableUFloat,
            toValue: mkNullableFormattedToValue
        })
    )

const positionsRowsBuilder = (
    calculations: SMap<CalculatedDevis>,
    submitters: Domain.Contractor[],
    changedIds: SMap<string[]>,
    onSubmit: F2<string, SMap<number>>,
    onCancel: F1<string>,
    isReadonly: boolean
) => {
    const contractorIds = submitters.map(s => s.contractorId)
    const base = { grid: getPosComparisonGrid(submitters), formSchema: mkPosSchema(contractorIds) }
    return (p: Domain.Position): Row<"sublevel"> => {
        const formValues = toMap(contractorIds, identity, id => calculations[id].offerValues[p.positionId])
        return {
            type: "sublevel",
            mode: isReadonly ? "static" : "editable",
            rowId: p.positionId,
            ...base,
            formValue: formValues,
            visuals: ["noBorder"],
            onSubmit: vs => onSubmit(p.positionId, vs),
            onDelete: () => onCancel(p.positionId),
            actionOnBlur: "submit",
            actionOnEnter: "submit",
            cells: [
                mkCell(p.number),
                mkCell(p.name),
                mkCell(`${displayNumber(p.amount)} ${p.unit}`),
                ...contractorIds
                    .map(id => {
                        const unitPrice = formValues[id]
                        const totalPrice = mulSafeNumbers(p.amount, unitPrice)
                        const edited = changedIds[id].includes(p.positionId)
                        return [
                            mkCell(displayNumber(unitPrice), edited ? ["alignRight", "yellowBg"] : ["alignRight"], {
                                editMode: "formless",
                                field: id
                            }),
                            mkCell(displayNumber(totalPrice), ["alignRight", "borderRight"])
                        ]
                    })
                    .flat()
            ]
        }
    }
}

const deductionsRowsBuilder = (
    submitters: Domain.Contractor[],
    calculations: SMap<CalculatedDevis>,
    changedIds: SMap<string[]>,
    onSubmit: F2<string, SMap<number>>,
    onCancel: F1<string>,
    isReadonly: boolean
) => {
    const contractorIds = submitters.map(s => s.contractorId)
    const base = { grid: getComparisonGrid(submitters), formSchema: mkPosSchema(contractorIds) }

    return (d: Domain.Deduction): Row<"deduction"> => {
        const formValues = toMap(
            contractorIds,
            identity,
            id => d.value ?? calculations[id].offerValues[d.deductionId] ?? 0
        )

        return {
            type: "deduction",
            mode: isReadonly ? "static" : "editable",
            rowId: d.deductionId,
            onSubmit: vs => onSubmit(d.deductionId, vs),
            onDelete: () => onCancel(d.deductionId),
            ...base,
            formValue: formValues,
            visuals: ["noBorder"],
            actionOnBlur: "submit",
            actionOnEnter: "submit",
            cells: [
                mkCell(d.name),
                ...contractorIds.map(id => {
                    const edited = changedIds[id].includes(d.deductionId)
                    return mkCell(
                        displayDeductionPriceFromCalculation(d, calculations[id], true),
                        edited ? ["alignRight", "borderRight", "yellowBg"] : ["alignRight", "borderRight"],
                        { editMode: "formless", field: id }
                    )
                })
            ]
        }
    }
}

export const mkNegotiationRows = {
    topRow,
    bottomRow,
    ...pickObject(mkComparisonRows, ["positionsHeaderRow", "positionSectionRow", "sumsBuilder"]),
    positionsRowsBuilder,
    deductionsRowsBuilder
}

export const useUpdateFinalOffer = (
    p: AsyncConnectResults<"projectDetails" | "devis" | "results", "updateFinalOffer">,
    requestsBySubmitter: SMap<Domain.ArchitectOfferRequest>
) => {
    const { onSubmit } = useCloudAction<SMap<SMap<number>>>((actionId, valuesByOffer) => {
        p.updateFinalOffer({ actionId, projectId: p.projectDetails.projectId, devisId: p.devis.devisId, valuesByOffer })
    }, p.results)

    const sendUpdate = (refId: string, valuesBySubmitter: SMap<number>) => {
        const valuesByOffer: SMap<SMap<number>> = {}
        keys(valuesBySubmitter).forEach(submitterId => {
            const r = requestsBySubmitter[submitterId]
            const latestValue = mkLatestOfferValues({ ...r, finalOffer: undefined })[refId]
            if (r.finalOffer?.[refId] !== undefined || latestValue !== valuesBySubmitter[submitterId])
                valuesByOffer[r.offerId] = { [refId]: valuesBySubmitter[submitterId] }
        })
        onSubmit(valuesByOffer)
    }
    const revertUpdate = (submitterIds: string[]) => (refId: string) => {
        const valuesByOffer: SMap<SMap<number>> = {}
        submitterIds.forEach(submitterId => {
            const r = requestsBySubmitter[submitterId]
            valuesByOffer[r.offerId] = { [refId]: mkLatestOfferValues({ ...r, finalOffer: undefined })[refId] }
            // basically here we are updating the offer with "old"(=== previous round) values
            // since we cannot really delete them - we can only revert them
        })
        onSubmit(valuesByOffer)
    }
    return { sendUpdate, revertUpdate }
}

export const useExportNegotiation = (p: AsyncConnectResults<"negotiationDoc">) => {
    const handleExport = React.useCallback(async (prefferredSubmitterId: string, comment: string) => {
        const { generatePdf } = await import(/* webpackChunkName: "pdf" */ "@smartdevis/client/src/services/pdf")

        const blob = await generatePdf(mkNegotiationDoc(p.negotiationDoc, prefferredSubmitterId, comment))

        downloadBlob(i18n("Contractor recommendation"), "pdf")(blob)
    }, [])
    return { handleExport }
}
