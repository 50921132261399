import { createAction } from "@smartdevis/utils/src/actions"
import { mkReducer } from "./utils"

type ApiStatus = "idle" | "fetching" | "contractorsDirectoryFetched"
export type ApiState = {
    status: ApiStatus
}

export const initialState: ApiState = {
    status: "idle"
}

export const actions = {
    _setApiStatus: (p: ApiStatus) => createAction("_setApiStatus", p)
}

export const reducer = mkReducer<ApiState>((state, action) => {
    switch (action.type) {
        case "init":
            return {}

        case "_setApiStatus":
            return { status: action.payload }
    }
})
