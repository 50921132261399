import * as React from "react"
import { i18n } from "@smartdevis/client/src/services/translations"
import { StyledFormSchema, useFormHook } from "@smartdevis/forms/src"
import { Button } from "@smartdevis/ui/src/Button"
import { BaseModalProps, Modal } from "@smartdevis/ui/src/Modal"
import { FlexRow, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { F1 } from "@smartdevis/utils/src/types"
import {
    optional,
    optionalString,
    validateMaxLength,
    validCompanyName,
    validEmail,
    validStreet
} from "@smartdevis/utils/src/validators"
import { StyledForm } from "../forms"
import { mkTextSchema, mkFormSchema } from "../forms/formSchemas"
import { LogoState, UploadLogo } from "../UploadLogo"
import { SubmitButton } from "../../components"
import { asyncConnect } from "../../resolvers"
import { ContractorOperation } from "../../views/architect/Directory"

export type ContractorOperationPayload = {
    companyCity: string
    companyCountry: string
    companyName: string
    companyInitials: string
    street: string
    streetNumber: string
    companyPostcode: string
    contactEmail: string
    contactFirstName: string
    contactLastName: string
    contactPhone: string
    workCategoriesDe: string
    workCategoriesEn: string
}

const contractorOperationValidation = {
    companyName: [...validCompanyName, validateMaxLength(250)],
    companyInitials: [optional("")(validateMaxLength(3))],
    street: [...validStreet, validateMaxLength(250)],
    streetNumber: [optional("")(validateMaxLength(50))],
    companyPostcode: [optional("")(validateMaxLength(10))],
    companyCity: [optional("")(validateMaxLength(50))],
    companyCountry: [optional("")(validateMaxLength(50))],
    contactFirstName: [optional("")(validateMaxLength(250))],
    contactLastName: [optional("")(validateMaxLength(250))],
    contactEmail: [...validEmail, validateMaxLength(250)],
    contactPhone: [optional("")(validateMaxLength(50))],
    workCategoriesDe: [optional("")(validateMaxLength(250))],
    workCategoriesEn: [optional("")(validateMaxLength(250))]
}

const getStyledSchema = (): StyledFormSchema<ContractorOperationPayload> => [
    { type: "Title", value: i18n("Company Details") },
    { type: "Row", value: ["companyName", "companyInitials"] },
    { type: "Row", value: ["street", "streetNumber"] },
    { type: "Row", value: ["companyPostcode", "companyCity"] },
    "companyCountry",
    { type: "Title", value: i18n("Contact Info") },
    { type: "Row", value: ["contactFirstName", "contactLastName"] },
    { type: "Row", value: ["contactEmail", "contactPhone"] },
    "workCategoriesDe",
    "workCategoriesEn"
]

const getSchema = () =>
    mkFormSchema<ContractorOperationPayload>(contractorOperationValidation, {
        companyCity: mkTextSchema("City"),
        companyCountry: mkTextSchema("Country"),
        companyInitials: mkTextSchema("Initials"),
        companyName: mkTextSchema("Name"),
        companyPostcode: mkTextSchema("Postal Code"),
        street: mkTextSchema("Street"),
        streetNumber: mkTextSchema("Street Number"),
        contactEmail: mkTextSchema("Email"),
        contactFirstName: mkTextSchema("Name"),
        contactLastName: mkTextSchema("Surname"),
        contactPhone: mkTextSchema("Phone number"),
        workCategoriesDe: mkTextSchema("Work Categories De (Work categories must be separated with a comma)"),
        workCategoriesEn: mkTextSchema("Work Categories En (Work categories must be separated with a comma)")
    })

type ContractorOperationModalProps = { contractorOperation: ContractorOperation; onModalClose: F1<boolean> }
export const ContractorOperationModal = asyncConnect({
    stateResolvers: ["api"],
    actions: ["addContractorWithUpload", "editContractorWithUpload"]
})<ContractorOperationModalProps & BaseModalProps>(p => {
    const [companyLogo, setCompanyLogo] = React.useState<LogoState>({ type: "none" })

    React.useEffect(() => {
        if (p.contractorOperation?.contractor?.logoLink) {
            setCompanyLogo({ type: "url", url: p.contractorOperation.contractor.logoLink })
        } else {
            setCompanyLogo({ type: "none" })
        }
    }, [p.contractorOperation.contractor])

    const onSubmit = (v: ContractorOperationPayload): any => {
        if (p.contractorOperation.type === "add") {
            p.addContractorWithUpload({
                ...v,
                logoUrl: companyLogo.type === "file" ? companyLogo.file : null,
                logoLink: companyLogo.type === "url" ? companyLogo.url : ""
            })
        } else {
            p.editContractorWithUpload({
                ...v,
                id: p.contractorOperation.id,
                logoUrl: companyLogo.type === "file" ? companyLogo.file : null,
                logoLink: companyLogo.type === "url" ? companyLogo.url : ""
            })
        }
    }

    const { formViewProps, handleSubmit, result } = useFormHook({
        schema: getSchema(),
        onSubmit: onSubmit,
        initialValue: p.contractorOperation.contractor
    })

    const copy = i18n(p.api.status !== "fetching" ? "Save" : "Saving")

    const scrollToFirstError = () => {
        const errors = Array.from(document.getElementsByClassName("error")).filter(v => v.textContent)
        if (errors && errors.length)
            errors[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    }

    return (
        <Modal
            header={p.header}
            onClose={() => p.onModalClose(false)}
            size="m"
            visible={p.visible}
            footer={
                <>
                    <Button onClick={() => p.onModalClose(false)} btnType="secondary">
                        {i18n("cancel")}
                    </Button>
                    <SubmitButton
                        disabled={p.api.status === "fetching"}
                        loading={p.api.status === "fetching"}
                        onClick={e => {
                            handleSubmit(e)
                            if (result.type === "Err") {
                                return setTimeout(scrollToFirstError, 100)
                            }
                        }}>
                        {i18n(copy)}
                    </SubmitButton>
                </>
            }>
            <StyledForm {...formViewProps} styledSchema={getStyledSchema()} />
            <VerticalSpace h={16} />
            <FlexRow justifyEnd>
                <UploadLogo
                    onChange={file => setCompanyLogo({ type: "file", file })}
                    onDelete={() => setCompanyLogo({ type: "none" })}
                    logoState={companyLogo}
                />
            </FlexRow>
        </Modal>
    )
})
