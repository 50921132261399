import * as React from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { H4 } from "./Typography"
import { themeColor, defaultTheme } from "./utils/theme"
import { Button, CheckboxButton } from "./Button"
import { Portal } from "./Portal"
import { Margin, FlexRow, styleIfProp } from "./utils/common"
import { Close } from "./AssetsRaw"
import { useNonInputScrollEvent } from "./hooks/common"
import { _noop } from "@smartdevis/utils/src/misc"
import { genTemporaryId } from "@smartdevis/utils/src/id"

const BASE_Z_INDEX = Number(defaultTheme.config.zIndexModal)

export type ModalSize = "xs" | "s" | "m" | "l" | "xl"

export type BaseModalProps = {
    id?: string
    visible?: boolean
    size?: ModalSize
    header?: string | React.ReactElement
    footer?: React.ReactElement
    headless?: boolean
    height?: string
    onClose?: F0
    style?: React.CSSProperties
    cy?: string
}

export const getModalStyles = (s: ModalSize) => {
    switch (s) {
        case "xl":
            return css`
                max-height: 95vh;
                width: 95vw;
            `
        case "l":
            return css`
                width: 80vw;
            `
        case "m":
            return css`
                width: 80vw;
                max-width: 860px;
            `
        case "s":
            return css`
                width: 80vw;
                max-width: 680px;
            `
        case "xs":
            return css`
                width: 60vw;
                max-width: 600px;
            `
        default:
            return css``
    }
}

const Overlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${BASE_Z_INDEX};
    background-color: rgba(241, 241, 241, 0.6);
`

const ModalMask = styled.div<{ zIndex: number }>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    z-index: ${({ zIndex }) => BASE_Z_INDEX + zIndex};
`

const ModalWrapper = styled.div<{ size: ModalSize; height?: string; zIndex: number }>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: ${({ zIndex }) => BASE_Z_INDEX + zIndex};
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    ${p => getModalStyles(p.size)};
    ${styleIfProp("height", h => `height: ${h};`)}
`

const CloseWrapper = styled.div`
    flex: none;
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    padding-bottom: 5px;
`

const CloseIcon = styled.img`
    cursor: pointer;
    height: 19px;
    width: 19px;
`

const HeaderWrapper = styled.div`
    flex: none;
    padding: 0 48px 12px;
    border-bottom: 1px solid ${themeColor("grey50")};
`

const ContentWrapper = styled.div<Pick<BaseModalProps, "height">>`
    flex: 1;
    padding: 24px 36px;
    overflow: scroll;
`

const FooterWrapper = styled.div<{ bottom?: boolean }>`
    position: relative;
    display: flex;
    flex: none;
    justify-content: flex-end;
    padding: 24px;
    border-top: 1px solid ${themeColor("grey50")};
    ${p => (!p.bottom ? `box-shadow: 0px -2px 25px 0px ${themeColor("strongShadowGrey")(p)}` : "")};
    button {
        margin-left: 16px;
    }
`

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const PIXEL_TRESHOLD = 12

export const Modal = React.memo(
    React.forwardRef<HTMLDivElement | null, React.PropsWithChildren<BaseModalProps>>((p, ref) => {
        const { add, remove, getStackIndex } = useModals()
        const altContentRef = React.useRef<HTMLDivElement | null>(null)
        const contentRef = (ref as React.MutableRefObject<HTMLDivElement | null>) || altContentRef

        const [reachedBottom, setReachedBottom] = React.useState(false)
        const id = React.useMemo(() => p.id ?? genTemporaryId(), [p.id])
        const stackIndex = getStackIndex(id)
        const baseZIndex = 2 * stackIndex

        React.useLayoutEffect(() => {
            ;(p.visible ? add : remove)(id)
            return () => remove(id)
        }, [p.visible, id, add, remove])

        const updateBottomEffect = () => {
            if (!contentRef.current) return
            const { scrollHeight, scrollTop, clientHeight } = contentRef.current
            if (Math.abs(clientHeight + scrollTop - scrollHeight) < PIXEL_TRESHOLD) setReachedBottom(true)
            else if (reachedBottom) setReachedBottom(false)
        }
        React.useEffect(() => {
            updateBottomEffect()
        }, [p.visible])

        useNonInputScrollEvent(updateBottomEffect)
        return p.visible ? (
            <Portal>
                {stackIndex < 1 && (
                    <>
                        <GlobalStyle />
                        <Overlay />
                    </>
                )}
                <ModalMask onClick={p.onClose ? p.onClose : _noop} zIndex={baseZIndex + 1} />
                <ModalWrapper size={p.size || "s"} data-cy={p.cy} height={p.height} zIndex={baseZIndex + 2}>
                    {!p.headless && (
                        <CloseWrapper>{p.onClose && <CloseIcon onClick={p.onClose} src={"/" + Close} />}</CloseWrapper>
                    )}
                    {p.header && (
                        <HeaderWrapper>{typeof p.header === "string" ? <H4>{p.header}</H4> : p.header}</HeaderWrapper>
                    )}
                    <ContentWrapper ref={contentRef} style={p.style}>
                        {p.children}
                    </ContentWrapper>
                    {p.footer && <FooterWrapper bottom={reachedBottom}>{p.footer}</FooterWrapper>}
                </ModalWrapper>
            </Portal>
        ) : null
    })
)

type ConfirmationModalV2Props = Pick<BaseModalProps, "onClose" | "header" | "visible"> & {
    onSubmit: F0
    contentText?: string
    submitText?: string | React.ReactElement
    cancelText?: string | React.ReactElement
    submitButtonProps?: Partial<React.ComponentProps<typeof Button>>
    loading?: boolean
}
export const ConfirmationModal: React.FC<ConfirmationModalV2Props> = p => {
    return (
        <Modal
            {...p}
            size="s"
            footer={
                <>
                    {p.onClose && (
                        <Button btnType="secondary" onClick={p.onClose}>
                            {p.cancelText}
                        </Button>
                    )}
                    <Button btnType="primary" loading={p.loading} onClick={p.onSubmit} {...p.submitButtonProps}>
                        {p.submitText}
                    </Button>
                </>
            }>
            {p.contentText}
            {p.children}
        </Modal>
    )
}

type ConfirmationCheckModalV2Props = Pick<BaseModalProps, "onClose" | "header" | "visible"> & {
    onSubmit: F0
    confirmationText: string
    contentText?: string | React.ReactElement
    submitText?: string | React.ReactElement
    cancelText?: string | React.ReactElement
    submitButtonProps?: Partial<React.ComponentProps<typeof Button>>
}
export const ConfirmationCheckModal: React.FC<ConfirmationCheckModalV2Props> = p => {
    const [checked, setChecked] = React.useState(false)
    return (
        <Modal
            {...p}
            size="s"
            footer={
                <>
                    <Button btnType="secondary" onClick={p.onClose}>
                        {p.cancelText}
                    </Button>
                    <Button btnType="primary" onClick={p.onSubmit} {...p.submitButtonProps} disabled={!checked}>
                        {p.submitText}
                    </Button>
                </>
            }>
            {p.contentText}
            {p.children}
            <FlexRow>
                <Margin values="8px">
                    <CheckboxButton
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        data-cy="confirmation-modal-checkbox"
                        label={p.confirmationText}
                    />
                </Margin>
            </FlexRow>
        </Modal>
    )
}

const ModalContext = React.createContext<{
    add: F1<string>
    remove: F1<string>
    getStackIndex: F1<string, number>
} | null>(null)

export const ModalProvider: React.FC = ({ children }) => {
    const [modals, setModals] = React.useState<string[]>([])

    const add = React.useCallback((id: string) => {
        setModals(m => (m.includes(id) ? m : [...m, id]))
    }, [])

    const remove = React.useCallback((id: string) => {
        setModals(m => (m.includes(id) ? [...m.slice(0, m.indexOf(id)), ...m.slice(m.indexOf(id) + 1)] : m))
    }, [])

    const getStackIndex = React.useCallback((id: string) => modals.indexOf(id), [modals])

    return <ModalContext.Provider value={{ add, remove, getStackIndex }}>{children}</ModalContext.Provider>
}

export const useModals = () => {
    const ctx = React.useContext(ModalContext)

    if (!ctx) throw new Error("ModalProvider not found")

    return ctx
}
